import React from 'react'
import CalendarIcon from 'theorem-lib/src/assets/icons/calendar.svg'
import CheckIcon from 'theorem-lib/src/assets/icons/check.svg'
import ChevronRightIcon from 'theorem-lib/src/assets/icons/chevron-right.svg'
import CommentsIcon from 'theorem-lib/src/assets/icons/speech-bubble.svg'
import TrashIcon from 'theorem-lib/src/assets/icons/trash.svg'
import { taskOverdue } from 'theorem-lib/src/helpers/overdue'
import { Task } from '../../../generated/api'
import { useActions } from '../../../presenter'
import { WorkbookFieldGroup } from './WorkbookFieldGroup'

type WorkbookTaskProps = {
  task: Task
}

export function WorkbookTask(props: WorkbookTaskProps) {
  const { displayModalAction, displaySlideOutAction, updateTaskAction, updateTaskEditAction } = useActions()

  const handleToggleComplete = (task: Task) => {
    const isCompleted = !!task.completedDate
    if (!isCompleted) {
      const now = new Date().toString()
      updateTaskAction({ ...task, completedDate: now })
    } else {
      updateTaskAction({ ...task, completedDate: null })
    }
  }

  const handleEditTask = () => {
    updateTaskEditAction(props.task)
    displaySlideOutAction('ManageTaskSlideOut')
  }

  const handleDeleteTask = () => {
    updateTaskEditAction(props.task)
    displayModalAction('DeleteTaskModal')
  }

  return (
    <div className='flex flex-row'>
      <div
        className={`flex border border-solid ${
          taskOverdue(props.task.dueDate, props.task.completedDate)
            ? 'border-error'
            : 'border-gray-300'
        } rounded-lg p-4 bg-white mb-2 relative overflow-auto w-[95%]`}
      >
        <div className='absolute left-0 top-0 right-0 bottom-0 cursor-pointer z-0' onClick={handleEditTask}></div>
        <div className='mr-4 align items-center flex z-1 relative'>
          <button
            onClick={() => handleToggleComplete(props.task)}
            className={`w-6 h-6 text-center rounded-full ${
              props.task.completedDate
                ? 'bg-teal-500 text-white'
                : 'border border-[#d9c3c1] text-[#f0e7e6]'
            }`}
          >
            <CheckIcon className='inline -mt-1' />
          </button>
        </div>
        <div className='flex-grow items-center flex w-[43%] mr-4 cursor-pointer text-black' onClick={handleEditTask}>
          {props.task.name}
        </div>
        <div className='flex-grow items-center flex w-[40%] mr-4 z-1 relative'>
          {props.task.fields
            ? <WorkbookFieldGroup task={props.task} />
            : ''}
        </div>
        <div className='flex items-center w-[17%] justify-end'>
          {props.task.numComments > 0 && <CommentsIcon className='mx-2 text-black' />}
          {props.task.dueDate && (
            <div
              className={`rounded w-6 h-6 text-center mx-2 ${
                taskOverdue(props.task.dueDate, props.task.completedDate)
                  ? 'bg-error text-white'
                  : 'text-black bg-transparent'
              }`}
            >
              <CalendarIcon className='scale-50' />
            </div>
          )}
          {props.task.assignedUser && (
            <div
              className='rounded-full bg-no-repeat bg-cover bg-center bg-primary-300 w-6 h-6 text-center text-xs leading-6 text-white mx-2'
              style={{ backgroundImage: `url(${props.task.assignedUser.avatarUrl})` }}
            >
              {!props.task.assignedUser.avatarUrl && (
                <span>
                  {props.task.assignedUser.firstName?.charAt(0)}
                  {props.task.assignedUser.lastName?.charAt(0)}
                </span>
              )}
            </div>
          )}
          <ChevronRightIcon className='text-primary-200 cursor-pointer' onClick={handleEditTask} />
        </div>
      </div>
      <div
        className='self-center pl-4 cursor-pointer fill-[#2b5b74]'
        onClick={handleDeleteTask}
      >
        <TrashIcon />
      </div>
    </div>
  )
}
