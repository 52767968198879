import { isEqual } from 'lodash'
import { toast } from 'react-toastify'
import { NEWLY_ADDED_CLIENT_ID } from 'theorem-lib/src/common/constants'
import { setActiveScreen } from 'theorem-lib/src/helpers/setActiveScreen'
import { Client, ClientInput } from '../../generated/api'
import { IprojectStateMachine, newProjectStateEnum } from '../../types/project'
import { validateClient } from '../../validators/validateClient'
import { validateClientInput } from '../../validators/validateClientInput'
import { Context } from '..'
import { defaultErrorHandler } from './actions'
import { projectCreationStateMachine } from './projects'

// helper function that gets Clients and Projects with filters
export async function getClientsWithFilters(context: Context) {
  context.state.clients = await context.effects.getClients(
    context.state.filters.industries.map(filterItem => filterItem.id),
    context.state.filters.members.map(filterItem => filterItem.id),
    context.state.filters.moveInDate.from,
    context.state.filters.moveInDate.to,
  )
}

export async function displayClientHomeAction(context: Context, id: string) {
  try {
    context.state.currentPage = 'Loading'
    context.state.industries = await context.effects.getIndustries()
    context.state.clientEdit = await context.effects.getClient(id)
    context.state.currentPage = 'ClientHome'
    setActiveScreen(context, 'Projects')
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function uploadClientImageAction(
  context: Context,
  value: { avatarFile: File; client: Client | ClientInput },
) {
  try {
    const imageUrl = await context.effects.uploadAvatarImage(value.avatarFile)
    value.client.logoUrl = imageUrl
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function cleanNewClientStateAction(context: Context) {
  context.state.clientNew = {
    clientId: '',
    industriesIds: [],
    logoUrl: '',
    name: '',
  }
}

export async function submitClientNewAction(context: Context) {
  const isProjectCreation = context.state.newProjectCreation?.currentState === newProjectStateEnum.ClientCreation
  context.state.validationErrors = validateClientInput(context.state.clientNew)
  if (context.state.validationErrors.size === 0) {
    try {
      const client = await context.effects.addClient(context.state.clientNew)
      context.state.clientNew = {
        clientId: client.id,
        industriesIds: [],
        logoUrl: '',
        name: client.name,
      }
      context.state.validationErrors.clear()
      if (context.state.isMember) await getFolioClientsAction(context)
      else await getClientsWithFilters(context)
      context.state.currentSlideOut = 'None'
      if (isProjectCreation && context.state.newProjectCreation?.project) {
        const project: IprojectStateMachine = {
          currentState: newProjectStateEnum.ManageProjectSlideOut,
          project: {
            ...context.state.newProjectCreation?.project,
            clientId: client.id,
          },
        }
        projectCreationStateMachine(context, project)
      }
      toast('Client created successfully!')
      // cleanNewClientStateAction(context)
      return client
    } catch (err) {
      defaultErrorHandler(context, err)
    }
  }
}

export async function getClientAction(context: Context, id: string) {
  try {
    await context.effects.getClient(id)
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function getFolioClientsAction(context: Context) {
  try {
    const folioClients = await context.effects.getFolioClients()
    context.state.allClients = folioClients.filter(client => NEWLY_ADDED_CLIENT_ID != client.id)
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function updateClientNewAction(context: Context, value: { client: ClientInput }) {
  context.state.clientNew = value.client
  context.state.validationErrors = validateClientInput(context.state.clientNew)
}

export async function submitUpdateClientAction(context: Context, client: Client) {
  try {
    await context.effects.updateClient(client)
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function deleteClientAction(context: Context) {
  try {
    await context.effects.deleteClient(context.state.clientEdit.id)
    context.state.currentModal = 'None'
    context.state.currentSlideOut = 'None'
    toast('Client deleted successfully!')
    context.effects.redirect('/projects')
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function closeClientNewAction(context: Context) {
  if (context.state.wizardProjectSelectedIndustry) {
    context.state.currentModal = 'GeneralProjectDetailsWizard'
  }
  if (context.state.newProjectCreation) {
    context.state.clientNew = {
      clientId: '',
      industriesIds: [],
      logoUrl: '',
      name: '',
    }
    context.state.currentSlideOut = 'ManageProjectSlideOut'
  } else {
    if (
      isEqual(context.state.clientNew, {
        industriesIds: [],
        logoUrl: '',
        name: '',
      })
    ) {
      context.state.currentSlideOut = 'None'
      context.state.validationErrors.clear()
    } else {
      context.state.currentModal = 'CancelClientNewModal'
    }
  }
}

export async function showWelcomeToFolioAction(context: Context) {
  const tenant = await context.effects.getTenantInfoByCode()

  if (context.state.userInvitations) {
    const projectId = new URLSearchParams(window.location.search).get(
      'projectId',
    )
    if (projectId) {
      await context.effects.acceptInvitation(projectId, tenant)
      await context.effects.sendWelcomeToFolioEmail(context.state.authenticatedUser.id, projectId)
      context.state.userInvitations = context.state.userInvitations.filter(i => i.id !== projectId)
      context.state.currentModal = 'WelcomeToFolioModal'
    }
  }
}

export async function closeClientEditAction(context: Context) {
  const client = await context.effects.getClient(context.state.clientEdit.id)
  if (
    isEqual(context.state.clientEdit, client)
  ) {
    context.state.currentSlideOut = 'None'
  } else {
    context.state.currentModal = 'CancelClientEditModal'
  }
}

export async function discardClientEditsAction(context: Context) {
  context.state.clientEdit = await context.effects.getClient(context.state.clientEdit.id)
  context.state.validationErrors.clear()
  context.actions.displayModalAction('None')
  context.actions.displaySlideOutAction('None')
}

export async function discardClientNewAction(context: Context) {
  context.state.validationErrors.clear()
  context.actions.displayModalAction('None')
  context.actions.displaySlideOutAction('None')
}

export async function submitClientEditAction(context: Context) {
  context.state.validationErrors = validateClient(context.state.clientEdit)
  if (context.state.validationErrors.size === 0) {
    try {
      await context.effects.updateClient(context.state.clientEdit)
      context.state.currentSlideOut = 'None'
      toast('Client saved successfully!')
    } catch (err) {
      defaultErrorHandler(context, err)
    }
  }
}

export async function updateClientEditAction(context: Context, value: { client: Client }) {
  context.state.clientEdit = value.client
  context.state.validationErrors = validateClient(context.state.clientEdit)
}
