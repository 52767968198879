import { XIcon } from '@heroicons/react/outline'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { OtherBusinessGoalsEnum } from '../../../generated/api'
import { getBusinessEnumByIndustry } from '../../../helpers/industryHelper'
import { getNameWithoutUnderScores } from '../../../helpers/projectHelper'
import { useActions, useAppState } from '../../../presenter'
import { Checkbox } from '../../atoms/Checkbox/Checkbox'
import { SlideIndicators } from '../../atoms/SlideIndicators/SlideIndicators'
import TextInput from '../../atoms/TextInput/TextInput'
import { Wizard } from '../../atoms/Wizard/Wizard'
import { IndustriesChip } from '../../molecules/IndustriesChip/IndustriesChip'
import { IndustriesEnum } from '../../molecules/NewProjectCreation/IndustriesSelect'
import { CancelProjectWizardModal } from '../Modals/CancelProjectWizardModal'

export const BusinessGoalsWizard = () => {
  const { currentModal, wizardProjectCreation, wizardProjectSelectedIndustry } = useAppState()
  const { cleanProjectCreation, displayModalAction, setProjectCreationBusinessGoals } = useActions()
  const [industry] = useState<IndustriesEnum | undefined>(wizardProjectSelectedIndustry)
  const [businessGoals, setBusinessGoals] = useState<OtherBusinessGoalsEnum[]>(
    wizardProjectCreation.industryDetails?.otherBusinessGoals?.values ?? [],
  )
  const [showOtherbg, setShowOtherbg] = useState<boolean>(
    wizardProjectCreation.industryDetails?.otherBusinessGoals?.other ? true : false,
  )
  const [otherbg, setOtherbg] = useState<string>(wizardProjectCreation.industryDetails?.otherBusinessGoals?.other ?? '')
  const selectedGoals = wizardProjectCreation.industryDetails?.otherBusinessGoals
  const [showCancelProjectWizardModal, setShowCancelProjectWizardModal] = useState<boolean>(false)

  const goBack = () => {
    SaveProject()
    displayModalAction('IndustrySelectorWizard')
  }

  const onClickNext = () => {
    if (businessGoals.length < 1) {
      toast('Select an option to continue', { type: 'error' })
      return
    }

    SaveProject()
    displayModalAction('SpacesWizard')
  }

  const SaveProject = () => {
    setProjectCreationBusinessGoals({
      other: otherbg,
      values: businessGoals,
    })
  }

  const onClose = () => cleanProjectCreation()

  const onClickExit = () => {
    setShowCancelProjectWizardModal(true)
  }

  const onConfirmLeaveWizardModal = () => {
    cleanProjectCreation()
    displayModalAction('None')
  }

  const onKeepEditingWizardModal = () => {
    setShowCancelProjectWizardModal(false)
  }

  const Header = () => {
    return (
      <>
        <button
          type='button'
          className='rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none'
          onClick={onClickExit}
        >
          <XIcon className='h-6 w-6' aria-hidden='true' />
        </button>
      </>
    )
  }

  const Footer = () => {
    return (
      <>
        <button
          className='bg-[#F1F1F1] items-center text-[#35697D] px-3 rounded-lg font-semibold mr-2 h-10'
          onClick={() => goBack()}
        >
          {'<-'} Back
        </button>
        <button
          className='bg-cta-200 items-center text-white px-3 rounded-lg font-semibold mr-2 h-10'
          onClick={onClickNext}
        >
          Next
        </button>
      </>
    )
  }

  const handleOnChecked = (checked: boolean, value: OtherBusinessGoalsEnum) => {
    if (!checked) {
      setBusinessGoals(prevState => [...prevState, value])
    } else {
      const newGoals = businessGoals.filter(f => f !== value)
      setBusinessGoals(newGoals)
    }

    if (value === OtherBusinessGoalsEnum.Other && !checked) {
      setShowOtherbg(true)
    } else if (value === OtherBusinessGoalsEnum.Other && checked) {
      setShowOtherbg(false)
    }
  }

  return (
    <Wizard
      isOpen={currentModal === 'BusinessGoalsWizard'}
      onClose={onClose}
      header={<Header />}
      footer={<Footer />}
      exitModal={
        <CancelProjectWizardModal
          showCancelModal={showCancelProjectWizardModal}
          onConfirmLeave={onConfirmLeaveWizardModal}
          onKeepEditing={onKeepEditingWizardModal}
        />
      }
    >
      {industry
        && (
          <>
            <div className='flex flex-col justify-center items-center w-full sm:w-full'>
              <div className='text-primary-100 text-xl font-bold mb-2 text-center'>
                What are your primary business goals for this project?
              </div>
              <div className='text-primary-200 text-base font-semibold text-center mb-6'>
                Select all that apply
              </div>
              <div className='text-primary-200 text-base font-semibold text-center mb-6'>
                <IndustriesChip industry={industry} />
              </div>
              <div className='overflow-x-visible grid grid-cols-2 gap-4 scroll-smooth'>
                {(getBusinessEnumByIndustry(industry)).map((goal, index) => (
                  <Checkbox
                    key={index}
                    checked={selectedGoals?.values.find(f => f === goal) ? true : false}
                    text={getNameWithoutUnderScores(goal)}
                    value={goal}
                    onChange={(checked) => handleOnChecked(checked, goal)}
                  />
                ))}
              </div>
              {showOtherbg
                && (
                  <div className='my-6 w-18'>
                    <div className='text-primary-100 text-lg font-bold mb-2 text-center'>
                      What are your other primary business goals?
                    </div>
                    <TextInput
                      label='Other Business Goals*'
                      hasAutoFocus
                      maxLength={52}
                      defaultValue={selectedGoals?.other ?? ''}
                      onChange={(e) => {
                        setOtherbg(e.target.value)
                      }}
                    />
                    <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                    </span>
                  </div>
                )}
            </div>
          </>
        )}
      <SlideIndicators />
    </Wizard>
  )
}
