import { InformationCircleIcon } from '@heroicons/react/outline'
import React from 'react'
import bulb from 'theorem-lib/src/assets/icons/lightbulb.png'

export interface TipProps {
  text: string
  icon?: 'bulb' | 'info'
  backgroundColor?: string
  link?: boolean
  linkText?: string
  onLinkClick?: () => void
}

export const Tip = ({
  backgroundColor = 'bg-[#ecf1f3]',
  icon = 'bulb',
  ...props
}: TipProps) => {
  return (
    <div id='alert-1' className={`flex justify-between py-3 px-4 mb-4 ${backgroundColor} rounded-lg`} role='alert'>
      <div className='inline-flex'>
        {icon === 'bulb'
          ? <img src={bulb} alt='lightbulb' className='h-5' />
          : <InformationCircleIcon className='w-6 h-6 text-primary-200' />}
        <span className='sr-only'>Info</span>
        <div className='ml-3 text-primary-200 text-base font-normal'>
          {props.text}
        </div>
      </div>
      {props.link
        && (
          <a
            className='text-[#c04184] text-base font-bold hover:text-pink-500 '
            aria-label='Close'
            onClick={props.onLinkClick}
          >
            {props.linkText}
          </a>
        )}
    </div>
  )
}
