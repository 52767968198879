import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid'
import React, { CSSProperties } from 'react'
import Burger from 'theorem-lib/src/assets/icons/burger.svg'

type CardProps = {
  children?: React.ReactNode
  className?: string
  id?: string
  onClick?: () => void
  onExpand?: () => void
  onMouseEnter?: () => void
  style?: CSSProperties
  isExpanded?: boolean
  isSpaceCard?: boolean
}

export const Card = (
  { children, className, isExpanded, isSpaceCard, onClick, onExpand, onMouseEnter, style }: CardProps,
): React.ReactElement => (
  <div
    className={`flex flex-row shadow-md rounded-lg bg-white ${className}`}
    onClick={onClick}
    onMouseEnter={onMouseEnter}
    style={style}
  >
    {!!onExpand && (
      <>
        <div className={`mr-4 ${isSpaceCard ? `pt-2` : `pt-6`} text-primary-200 flex flex-col`}>
          {isSpaceCard && (
            <div className='mb-4 pl-1'>
              <Burger />
            </div>
          )}
          {isExpanded
            ? <ChevronUpIcon className=' w-6 h-6 cursor-pointer' onClick={onExpand} />
            : <ChevronDownIcon className='w-6 h-6 cursor-pointer' onClick={onExpand} />}
        </div>
      </>
    )}
    <div className='w-full'>
      {children}
    </div>
  </div>
)

export default Card
