import { HeartIcon, XIcon } from '@heroicons/react/outline'
import React from 'react'

type LikeDislikeProps = {
  likeStyle?: string
  dislikeStyle?: string
  liked?: boolean
  disliked?: boolean
  onLikeClick?: () => void
  onDislikeClick?: () => void
  paddingTop?: string
}

export const LikeDislike = ({
  dislikeStyle = 'bg-[#C04184] text-white',
  likeStyle = 'bg-[#00a7ad] text-white',
  paddingTop = 'pt-20',
  ...props
}: LikeDislikeProps): React.ReactElement => {
  return (
    <div className={`flex flex-row-reverse h-full justify-start -mb-3 pr-3  ${paddingTop}`}>
      <div
        className={`${
          props.liked
            ? likeStyle
            : 'bg-[#CFDBE0] hover:bg-[#7E9FAC] text-[#35697D] hover:text-white'
        } 
            h-full rounded-full flex justify-center items-center`}
        style={{ height: 2.25 + 'rem', width: 2.25 + 'rem' }}
        onClick={props.onLikeClick}
      >
        <HeartIcon className='w-6 me-1' />
      </div>
      <div
        className={`${
          props.disliked
            ? dislikeStyle
            : 'bg-[#CFDBE0] hover:bg-[#7E9FAC] text-[#35697D] hover:text-white'
        } 
          h-full rounded-full flex justify-center items-center`}
        style={{ height: 2.25 + 'rem', width: 2.25 + 'rem' }}
        onClick={props.onDislikeClick}
      >
        <XIcon className='w-6 me-1' />
      </div>
    </div>
  )
}
