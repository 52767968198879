import React from 'react'
import CaseIcon from 'theorem-lib/src/assets/icons/case.svg'
import { useActions, useAppState } from '../../../presenter'

export function UserProjectsInvitations() {
  const { userInvitations } = useAppState()
  const { acceptInvitationAction, denyInvitationAction } = useActions()

  return (
    <div>
      <div className='mt-2 border-2 border-primary-200 rounded-lg inline-block px-3 pt-3 pb-5 w-max'>
        <div className='inline-block border-2 rounded-full border-primary-200 p-3 mr-2'>
          <CaseIcon className='fill-primary-200' />
        </div>

        <div className='inline-block'>
          <h2 className='text-xl font-bold text-primary-200'>
            Project Invitations
          </h2>
          <h4 className='text-xs font-semibold text-primary-200'>
            &nbsp;
          </h4>
        </div>
        {userInvitations.length > 0 && (
          <div className='pt-2 ml-4'>
            <h3 className='text-sm font-bold text-primary-100 py-3'>PROJECT</h3>

            {userInvitations.map((invitation) => (
              <div
                key={invitation.id}
                className='grid grid-cols-3 gap-4 mt-2 mr-2'
              >
                <div className='col-span-2'>
                  <span className='inline-block align-middle text-base font-normal text-primary-100 truncate max-w-sm'>
                    {invitation.name}
                  </span>
                </div>

                <div className='flex justify-center'>
                  <button
                    className='bg-cta-200 text-white font-semibold py-1 px-3 rounded mr-3'
                    onClick={() => acceptInvitationAction(invitation.id)}
                  >
                    Accept
                  </button>
                  <button
                    className='bg-gray-300 text-[#448394] font-semibold py-1 px-3 rounded'
                    onClick={() => denyInvitationAction(invitation.id)}
                  >
                    Deny
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
        {userInvitations.length === 0 && <p>You have no pending project invitations.</p>}
      </div>
    </div>
  )
}
