import React from 'react'
import CaseIcon from 'theorem-lib/src/assets/icons/case.svg'
import { Client } from '../../../generated/api'

type ProjectEmptyStateProps = {
  client: Client
  onAction: () => void
}

export const ProjectEmptyState = (
  props: ProjectEmptyStateProps,
): React.ReactElement => (
  <div className='flex flex-col justify-center items-center w-full mt-48'>
    <div className='rounded-full border-primary-100 border-2 border-solid p-3 mb-4'>
      <CaseIcon />
    </div>
    <div className='text-primary-100 text-xl font-semibold mb-1'>
      You don&apos;t have any projects yet
    </div>
    <div className='text-primary-300 text-base font-normal'>
      All projects for {props.client.name} you create will show up here.
    </div>
    <button type='button' className='button cta mt-7' onClick={props.onAction}>
      Create New Project
    </button>
  </div>
)
