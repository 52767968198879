export enum FolioSecurityRoles {
  Admin = 'Admin',
  Member = 'Member',
  Participant = 'Participant',
}

export enum ProjectSecurityRoles {
  Lead = 'Lead',
  Editor = 'Editor',
  Collaborator = 'Collaborator',
}
