import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { PalletesImagesPool } from '../../../../assets/Industries/imagesPool'
import { ProjectInput, VisualPreferencesInput } from '../../../generated/api'
import { useActions, useAppState } from '../../../presenter'
import { newProjectStateEnum } from '../../../types/project'
import { LikeDislike } from '../../atoms/LikeDislike/LikeDislike'
import { PulseAnimation } from '../../atoms/Loading/Loading'
import { LoadingSpinner } from '../../atoms/Loading/LoadingSpinner'
import { SlideOut } from '../../atoms/SlideOut/SlideOut'
import { SlideOutFooter } from '../../atoms/SlideOutFooter/SlideoutFooter'

export const VisualPreferencesSlideOut = () => {
  const {
    displayModalAction,
    projectCreationStateMachine,
  } = useActions()
  const { industries, isParticipant, newProjectCreation } = useAppState()
  const [visualPreferences, setVisualPreferences] = useState<VisualPreferencesInput[]>()
  const [loading, setLoading] = useState<boolean>(true)
  const [submit, setSubmit] = useState<boolean>(false)

  useEffect(() => {
    setVisualPreferences(PalletesImagesPool)
  }, [newProjectCreation?.project?.industryId, industries])

  setTimeout(() => {
    setLoading(false)
  }, 600)

  const saveVisualPreferences = async () => {
    if (newProjectCreation?.project) {
      const sProject: ProjectInput = {
        ...newProjectCreation.project,
        industryDetails: {
          ...newProjectCreation.project.industryDetails,
          visualPreferences: visualPreferences,
        },
      }
      await projectCreationStateMachine({
        currentState: newProjectStateEnum.ManageProjectSlideOut,
        project: sProject,
      })
    }
  }

  const onSubmit = async () => {
    setSubmit(true)
    if (isParticipant && !visualPreferences?.find(f => f.like === true)) {
      toast('Indicate your visual preferences to continue', { type: 'error' })
    } else {
      if (newProjectCreation?.project) {
        const sProject: ProjectInput = {
          ...newProjectCreation.project,
          industryDetails: {
            ...newProjectCreation.project.industryDetails,
            visualPreferences: visualPreferences,
          },
        }
        await projectCreationStateMachine({
          currentState: newProjectStateEnum.SubmitProject,
          project: sProject,
        })
        await projectCreationStateMachine({ currentState: newProjectStateEnum.CleanProject, project: sProject })
      }
    }
    setSubmit(false)
  }

  const onLikeClick = (cover: VisualPreferencesInput) => {
    const updatedList = visualPreferences?.map(item => {
      if (item.id == cover.id) {
        return { ...item, dislike: false, like: true }
      }
      return item
    })

    setVisualPreferences(updatedList)
  }
  const onDislikeClick = (cover: VisualPreferencesInput) => {
    const updatedList = visualPreferences?.map(item => {
      if (item.id == cover.id) {
        return { ...item, dislike: true, like: false }
      }
      return item
    })

    setVisualPreferences(updatedList)
  }

  const Thumbnail = (cover: VisualPreferencesInput) => (
    <div
      key={cover.id}
      className={`min-w-[128px] h-24 bg-primary-200 mr-2 rounded-md bg-cover bg-no-repeat bg-center cursor-pointer flex flex-col`}
      style={{ backgroundImage: `url('${cover.url}')` }}
    >
      <LikeDislike
        liked={cover.like}
        disliked={cover.dislike}
        onLikeClick={() => onLikeClick(cover)}
        onDislikeClick={() => onDislikeClick(cover)}
      />
    </div>
  )

  return (
    <SlideOut
      title='New Project'
      onClose={() => displayModalAction('CancelProjectEditModal')}
    >
      {submit
        ? <LoadingSpinner title='Creating your project' subtitle='Don&lsquo;t navigate away from this page' />
        : (
          <>
            <p className='text-base font-bold text-gray-900'>
              Visual Preferences
            </p>
            <p className='mb-6 text-primary-200 font-normal'>
              What are some styles you find appealing?
            </p>
            <div className='flex flex-wrap'>
              {(loading || submit)
                ? <PulseAnimation />
                : visualPreferences && (
                  <>
                    {visualPreferences.map((image, index) => (
                      <div key={index} className='w-1/2 mb-10'>
                        <Thumbnail key={index} {...image} dislike={image.dislike} />
                      </div>
                    ))}
                  </>
                )}
            </div>
            <SlideOutFooter justify='between'>
              <button
                className='bg-[#F1F1F1] items-center text-[#35697D] px-3 rounded-lg font-semibold mr-2 h-10'
                onClick={() => saveVisualPreferences()}
              >
                {'<-'} Back to Details
              </button>

              <button
                className='bg-cta-200 items-center text-white px-3 rounded-lg font-semibold mr-2 h-10'
                type='submit'
                onClick={onSubmit}
              >
                Create Project
              </button>
            </SlideOutFooter>
          </>
        )}
    </SlideOut>
  )
}
