import { clone, difference, filter, find, findIndex, pick } from 'lodash'
import { toast } from 'react-toastify'
import {
  CreateCommentInput,
  CreateTasklistInput,
  Member,
  Task,
  TaskAnswer,
  Tasklist,
  UpdateTaskInput,
  UpdateTasklistInput,
} from '../../generated/api'
import { Context } from '..'
import { defaultErrorHandler, displaySlideOutAction } from './actions'
import { displayProjectTasklistsAction } from './projects'

export async function updateTaskAction(context: Context, task: Task) {
  const t: UpdateTaskInput = pick(task, ['assignedUserId', 'completedDate', 'dueDate', 'id', 'name', 'sectionId'])
  if (t.completedDate) {
    t.completedByUserId = context.state.authenticatedUser.id
  } else {
    t.completedDate = ''
  }
  try {
    const updatedTask = await context.effects.updateTask(context.state.selectedProject.id, t as UpdateTaskInput)
    if (updatedTask) {
      updateTaskState(context, updatedTask)
    }
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

function updateTaskState(context: Context, updated: Task) {
  if (context.state.taskEdit.task) {
    context.state.taskEdit.task = Object.assign({ ...context.state.taskEdit.task }, updated)
  }
  context.state.selectedProjectTaskLists = context.state.selectedProjectTaskLists.sort((a, b) =>
    (+a.isArchived) - (+b.isArchived) || a.name.localeCompare(b.name)
  ).map(list => {
    list.sections.map(section => {
      section.tasks.map(task => {
        if (task.id === updated.id) {
          return Object.assign(task, updated)
        }
        return task
      })
      return section
    })
    return list
  })
}

export async function addTasklistToProjectAction(context: Context, templateId: string) {
  try {
    const projectId = context.state.selectedProject.id
    await context.effects.addTasklistToProject(projectId, templateId)
    context.actions.displayProjectWorkbookAction(projectId)
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function completeSectionAction(context: Context, sectionId: string) {
  try {
    const projectId = context.state.selectedProject.id
    await context.effects.completeSection(projectId, sectionId)
    completeSectionTasks(context, sectionId)
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

function completeSectionTasks(context: Context, sectionId: string) {
  context.state.selectedProjectTaskLists = context.state.selectedProjectTaskLists.sort((a, b) =>
    (+a.isArchived) - (+b.isArchived) || a.name.localeCompare(b.name)
  ).map(list => {
    list.sections.map(section => {
      if (section.id === sectionId) {
        const now = new Date().toString()
        const completedTasks = section.tasks.map(task => {
          task.completedDate = now
          return task
        })
        return Object.assign(section, { tasks: completedTasks })
      }
    })
    return list
  })
}

export async function createTaskAction(context: Context, value: { taskName: string; sectionId: string }) {
  try {
    const projectId = context.state.selectedProject.id
    const newTask = await context.effects.createTask(value.taskName, projectId, value.sectionId)
    updateCreateTaskState(context, newTask.createTask)
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

function updateCreateTaskState(context: Context, task: Task) {
  context.state.selectedProjectTaskLists = context.state.selectedProjectTaskLists.sort((a, b) =>
    (+a.isArchived) - (+b.isArchived) || a.name.localeCompare(b.name)
  ).map(list => {
    list.sections.map(section => {
      if (section.id === task.sectionId) {
        const newSection = { ...section }
        newSection.tasks.push(task)
        return newSection
      }
      return section
    })
    return list
  })
}

export async function createOrUpdateTaskAnswerAction(
  context: Context,
  value: { taskId: string; values: Record<string, unknown> },
) {
  try {
    const projectId = context.state.selectedProject.id
    const newAnswers = await context.effects.createorUpdateTaskAnswer(projectId, value)
    if (newAnswers && newAnswers.data) {
      updateCreateAnswerState(context, value.taskId, newAnswers.data.upsertTaskAnswer)
    }
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

function updateCreateAnswerState(context: Context, taskId: string, newAnswer: TaskAnswer) {
  if (context.state.taskEdit.task) {
    const newTask = { ...context.state.taskEdit.task }
    newTask.taskAnswer = newAnswer
    context.state.taskEdit.task = newTask
  }
  const newList = context.state.selectedProjectTaskLists.sort((a, b) =>
    (+a.isArchived) - (+b.isArchived) || a.name.localeCompare(b.name)
  ).map(list => {
    list.sections = list.sections.map(section => {
      section.tasks = section.tasks.map(task => {
        if (task.id === taskId) {
          const newTask = { ...task }
          if (!newTask?.taskAnswer) {
            newTask.taskAnswer = {} as TaskAnswer
          }
          newTask.taskAnswer = newAnswer
          return newTask
        }
        return task
      })
      return section
    })
    return list
  })
  context.state.selectedProjectTaskLists = newList
}

export async function displayUpdateTaskListSlideOut(context: Context, id?: string) {
  if (!id) return
  const list = context.state.selectedProjectTaskLists.find(list => list.id === id)
  if (list) {
    context.state.selectedTasklist = { ...list }
    context.state.currentSlideOut = 'ManageTasklistSlideOut'
  }
}

function updateProjectTasklistState(
  members: Member[],
  selectedProjectTaskLists: Tasklist[],
  updated: UpdateTasklistInput,
) {
  selectedProjectTaskLists = selectedProjectTaskLists.map(task => {
    if (task.id === updated.id) {
      const assignedUserIds = clone(updated.assignedUserIds)
      const filteredTasklistUsers = filter(members, function(member) {
        return find(assignedUserIds, function(id) {
          return id === member.id
        })
      })
      return Object.assign(task, {
        description: updated.description,
        dueDate: (updated.dueDate === '-' || updated.dueDate === '') ? undefined : updated.dueDate,
        name: updated.name,
        tasklistUsers: filteredTasklistUsers,
      })
    }
    return task
  })
}

function getRemovedUserIds(selectedProjectTaskLists: Tasklist[], tasklist: UpdateTasklistInput) {
  const taskListIndex = findIndex(selectedProjectTaskLists, function(o) {
    return o.id == tasklist.id
  })
  const assignedUserIds = tasklist?.assignedUserIds?.map((id) => id) || []
  const taskListUsers = selectedProjectTaskLists[taskListIndex].tasklistUsers?.map((obj) => obj.id)
  return { assignedUserIds, removedUserIds: difference(taskListUsers, assignedUserIds) }
}

function getNewAssignedAndUnassignedUsers(selectedProjectTaskLists: Tasklist[], tasklist: UpdateTasklistInput) {
  const taskListIndex = findIndex(selectedProjectTaskLists, function(o) {
    return o.id == tasklist.id
  })
  const savedTaskListAssignedUsersId = selectedProjectTaskLists[taskListIndex].tasklistUsers?.map((obj) => obj.id) || []
  const changedTasklistAssignedUserIds = tasklist?.assignedUserIds?.map((id) => id) || []
  const assignedUserIds = difference(changedTasklistAssignedUserIds, savedTaskListAssignedUsersId)
  const removedUserIds = difference(savedTaskListAssignedUsersId, changedTasklistAssignedUserIds)
  return { assignedUserIds, removedUserIds }
}

async function sendNotifications(context: Context, tasklist: UpdateTasklistInput) {
  const { assignedUserIds, removedUserIds } = getNewAssignedAndUnassignedUsers(
    context.state.selectedProjectTaskLists,
    tasklist,
  )
  await context.actions.sendAssignedToTaskListNotificationAction({ ...tasklist, assignedUserIds, removedUserIds })
  await context.actions.sendUnassignedToTaskListNotificationAction({ ...tasklist, assignedUserIds, removedUserIds })
}

export async function submitUpdateTasklistAction(
  context: Context,
  { tasklist }: { tasklist: UpdateTasklistInput },
) {
  try {
    const projectId = context.state.selectedProject.id
    const { assignedUserIds, removedUserIds } = getRemovedUserIds(context.state.selectedProjectTaskLists, tasklist)
    await context.effects.updateTasklist(projectId, { ...tasklist, assignedUserIds, removedUserIds })
    await sendNotifications(context, tasklist)
    updateProjectTasklistState(context.state.selectedProject.members, context.state.selectedProjectTaskLists, tasklist)
    context.state.currentSlideOut = 'None'
    toast('Tasklist updated successfully!')
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function createTasklistAction(context: Context, input: CreateTasklistInput) {
  try {
    context.state.currentSlideOut = 'None'
    await context.effects.createTasklist(input)
    await context.actions.displayProjectWorkbookAction(input.associatedObjectId)
    toast('List created successfully')
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export function setDeleteTasklistIdAction(context: Context, id: string) {
  context.state.deleteTasklistId = id
}

export async function deleteTasklistAction(context: Context) {
  try {
    const projectId = context.state.selectedProject.id
    const tasklistId = context.state.deleteTasklistId
    await context.effects.deleteTasklist(projectId, tasklistId)
    await context.actions.displayProjectWorkbookAction(projectId)
    context.state.currentModal = 'None'
    toast('List deleted successfully')
  } catch (err) {
    context.state.currentModal = 'None'
    defaultErrorHandler(context, err)
  }
}

export function archivedSort(a: Tasklist, b: Tasklist) {
  // return (a.isArchived === b.isArchived)? 0 : a? -1 : 1
}

export async function archiveTasklistAction(context: Context, id: string) {
  try {
    const projectId = context.state.selectedProject.id

    await context.effects.archiveTasklist(projectId, id)
    context.state.selectedProjectTaskLists = (await context.effects.getTasklists(projectId))
      .sort((a, b) => (+a.isArchived) - (+b.isArchived) || a.name.localeCompare(b.name))

    const list = context.state.selectedProjectTaskLists.find(list => list.id === id)
    if (list) {
      context.state.selectedTasklist = { ...list }
    }
    if (list?.isArchived) {
      toast('List archived successfully')
    } else {
      toast('List no longer archived')
    }
  } catch (err) {
    context.state.currentModal = 'None'
    defaultErrorHandler(context, err)
  }
}

export async function updateTaskEditAction(context: Context, task: Task) {
  const projectId = context.state.selectedProject.id
  context.state.taskEdit.task = { ...task }
  const comments = await context.effects.getTaskComments(projectId, task.id)
  context.state.taskEdit.comments = comments
}

export async function deleteTaskAction(context: Context) {
  const projectId = context.state.selectedProject.id
  const taskId = context.state.taskEdit.task?.id
  if (taskId) {
    await context.effects.deleteTask(projectId, taskId)
    updateDeleteTaskState(context, taskId)
  }
  context.actions.displaySlideOutAction('None')
}

function updateDeleteTaskState(context: Context, deletedTaskId: string) {
  context.state.selectedProjectTaskLists = context.state.selectedProjectTaskLists.sort((a, b) =>
    (+a.isArchived) - (+b.isArchived) || a.name.localeCompare(b.name)
  ).map(list => {
    list.sections.map(section => {
      const newTasks = section.tasks.filter(task => task.id !== deletedTaskId)
      section.tasks = newTasks
      return section
    })
    return list
  })
}

export async function closeManageTaskAction(context: Context) {
  context.state.taskEdit = { comments: [], task: undefined }
}

export async function addTaskCommentAction(context: Context, input: CreateCommentInput) {
  const newComment = await context.effects.addTaskComment(input)
  const newComments = [newComment.data.addTaskComment].concat(context.state.taskEdit.comments)
  context.state.taskEdit.comments = newComments
}

export async function displayProjectTaskAction(
  context: Context,
  params: { projectId: string; tasklistId: string; taskId: string },
) {
  try {
    await displayProjectTasklistsAction(context, { projectId: params.projectId, tasklistId: params.tasklistId })

    if (context.state.selectedProjectTaskListIndex != undefined) {
      const tasklist = context.state.selectedProjectTaskLists[context.state.selectedProjectTaskListIndex]
      for (const section of tasklist.sections) {
        for (const task of section.tasks) {
          if (params.taskId === task.id) {
            await updateTaskEditAction(context, task)
            await displaySlideOutAction(context, 'ManageTaskSlideOut')
          }
        }
      }
    }
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}
