import { ChevronLeftIcon } from '@heroicons/react/solid'
import React, { useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import CaseIcon from 'theorem-lib/src/assets/icons/case.svg'
import CogIcon from 'theorem-lib/src/assets/icons/cog.svg'
import PlusIcon from 'theorem-lib/src/assets/icons/plus.svg'
import { Member, Project, SecurityRole, User } from '../../../generated/api'
import { useActions, useAppState } from '../../../presenter'
import { ProjectSecurityRoles } from '../../../types/gql_enums'
import { ProjectEmptyState } from '../../atoms/EmptyStates/ProjectEmptyState'
import ProjectCard from '../../molecules/ProjectCard/ProjectCard'

export function ClientHome() {
  const { authenticatedUser, clientEdit } = useAppState()
  const { displayNewProjectSlideOut, displaySlideOutAction, getProjectById, setNewProjectCreationClientId } =
    useActions()
  const projectList: Project[] = clientEdit.projects ?? []
  const shouldShowEmptyState = clientEdit.projects?.length === 0
  const showClientEdit = authenticatedUser.securityRoles.includes(SecurityRole.Admin)
  const [allMembers, setAllMembers] = useState<Member[]>([])
  const [userProjects, setUserProjects] = useState<Project[]>([])

  let isAuthorized = false

  useEffect(() => {
    getAllMembers(projectList)
  }, [projectList])

  const onNewProjectClick = (clientId: string) => {
    displayNewProjectSlideOut()
    setNewProjectCreationClientId(clientId)
  }

  const getAllMembers = async (list: Array<Project>) => {
    for (let i = 0; i < list.length; i++) {
      const newProject = await getProjectById(list[i].id).then((content) => {
        return content
      })
      if (newProject !== undefined) {
        setUserProjects(prevState => [...prevState, newProject])
        for (let j = 0; j < newProject.members.length; j++) {
          setAllMembers(prevState => [...prevState, newProject.members[j]])
        }
      }
    }
  }

  const checkMemberPermissions = (selectedMembers: Array<Member>) => {
    selectedMembers.forEach(member => {
      if (member.id === authenticatedUser.id) {
        checkProjectLeadOrEditor(member)
      }
    })
  }

  const checkProjectLeadOrEditor = (selectedMember: Member) => {
    if (
      selectedMember.role.name === ProjectSecurityRoles.Lead
      || selectedMember.role.name === ProjectSecurityRoles.Editor
    ) {
      return isAuthorized = true
    }
  }

  const checkForCollaborator = (authenticatedUser: User) => {
    userProjects.forEach((project) => {
      for (let i = 0; i < project.members.length; i++) {
        if (
          project.members[i].id === authenticatedUser.id
          && project.members[i].role.name === ProjectSecurityRoles.Collaborator
        ) {
          const projectToBeRemoved = project
          setUserProjects(prev => prev.filter(projects => projects !== projectToBeRemoved))
        }
      }
    })
  }

  checkMemberPermissions(allMembers)
  checkForCollaborator(authenticatedUser)

  return (
    <>
      <ReactTooltip />
      <section className='w-80 bg-primary-500 fixed top-0 bottom-0 left-24 p-6 flex flex-col'>
        <nav className='flex justify-between mb-10'>
          <a
            className='flex flex-row cursor-pointer text-primary-100'
            href='/projects'
          >
            <ChevronLeftIcon className='w-5 mr-2' />
            <span className='font-semibold'>Back</span>
          </a>
          {(showClientEdit || isAuthorized) && (
            <div
              className='cursor-pointer'
              data-tip='Manage Client'
              data-effect='solid'
              onClick={() => displaySlideOutAction('ClientEditSlideOut')}
            >
              <CogIcon />
            </div>
          )}
        </nav>
        <div
          className='w-[120px] h-[120px] bg-primary-200 rounded-full bg-cover bg-center text-center inline-block'
          style={{ backgroundImage: `url(${clientEdit.logoUrl})` }}
        >
          {!clientEdit.logoUrl && <CaseIcon className='inline text-primary-400 scale-150 mt-12' />}
        </div>
        <h1 className='mt-6 text-primary-100 font-semibold text-xl'>
          {clientEdit?.name}
        </h1>
        <h2 className='mt-1'>
          {clientEdit.industries?.map((industry) => industry?.name).join(', ')}
        </h2>
        <div
          className='mt-10 h-[152px] rounded-md p-4 flex flex-col justify-between'
          style={{ backgroundColor: 'rgba(216, 159, 155, 0.12)' }}
        >
          <div
            className='h-14 w-14 rounded-full bg-white flex justify-center items-center'
            style={{ color: '#D89F9B' }}
          >
            <CaseIcon />
          </div>
          <div className='text-primary-100'>
            <div className='text-xl font-semibold'>
              {clientEdit.projects?.length}
            </div>
            <div className='text-xs font-normal mt-1'>Projects</div>
          </div>
        </div>
      </section>
      <section className='ml-80 flex flex-col'>
        <div className='flex flex-row justify-between'>
          <h1 className='text-lg font-medium mb-5'>Projects</h1>
          <div
            className='bg-cta-200 rounded-lg flex items-center justify-center w-10 h-10 text-white cursor-pointer'
            onClick={() => onNewProjectClick(clientEdit.id)}
          >
            <PlusIcon />
          </div>
        </div>
        {shouldShowEmptyState && <ProjectEmptyState client={clientEdit} onAction={displayNewProjectSlideOut} />}
        {!shouldShowEmptyState && (
          <div className='flex flex-row flex-wrap'>
            {userProjects.map((project) => (
              <div key={project?.id} className='mb-4'>
                {project && <ProjectCard project={project} />}
              </div>
            ))}
          </div>
        )}
      </section>
    </>
  )
}
