import { XIcon } from '@heroicons/react/outline'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { OtherFacilityTypesEnum } from '../../../generated/api'
import { getNameWithoutUnderScores } from '../../../helpers/projectHelper'
import { useActions, useAppState } from '../../../presenter'
import { Checkbox } from '../../atoms/Checkbox/Checkbox'
import { SlideIndicators } from '../../atoms/SlideIndicators/SlideIndicators'
import { Wizard } from '../../atoms/Wizard/Wizard'
import { IndustriesChip } from '../../molecules/IndustriesChip/IndustriesChip'
import { IndustriesEnum } from '../../molecules/NewProjectCreation/IndustriesSelect'
import { CancelProjectWizardModal } from '../Modals/CancelProjectWizardModal'

export const FacilityTypesWizard = () => {
  const { currentModal, wizardProjectCreation, wizardProjectSelectedIndustry } = useAppState()
  const { cleanProjectCreation, displayModalAction, setProjectCreationFacilities } = useActions()
  const [industry] = useState<IndustriesEnum | undefined>(wizardProjectSelectedIndustry)
  const [facility, setFacility] = useState<OtherFacilityTypesEnum[]>(
    wizardProjectCreation.industryDetails?.otherFacilityTypes?.values ?? [],
  )
  const [other] = useState<string>(wizardProjectCreation.industryDetails?.otherFacilityTypes?.other ?? '')
  const selectedFacilities = wizardProjectCreation.industryDetails?.otherFacilityTypes
  const [showCancelProjectWizardModal, setShowCancelProjectWizardModal] = useState<boolean>(false)

  const goBack = () => {
    SaveProject()
    displayModalAction('IndustrySelectorWizard')
  }

  const onClose = () => cleanProjectCreation()

  const onClickNext = () => {
    if (facility.length < 1) {
      toast('Select an option to continue', { type: 'error' })
      return
    }

    SaveProject()
    displayModalAction('AdministrativeGoalsWizard')
  }

  const SaveProject = () => {
    setProjectCreationFacilities({
      other: other,
      values: facility,
    })
  }

  const onClickExit = () => {
    setShowCancelProjectWizardModal(true)
  }

  const onConfirmLeaveWizardModal = () => {
    cleanProjectCreation()
    displayModalAction('None')
  }

  const onKeepEditingWizardModal = () => {
    setShowCancelProjectWizardModal(false)
  }

  const Header = () => {
    return (
      <>
        <button
          type='button'
          className='rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none'
          onClick={onClickExit}
        >
          <XIcon className='h-6 w-6' aria-hidden='true' />
        </button>
      </>
    )
  }

  const Footer = () => {
    return (
      <>
        <button
          className='bg-[#F1F1F1] items-center text-[#35697D] px-3 rounded-lg font-semibold mr-2 h-10'
          onClick={() => goBack()}
        >
          {'<-'} Back
        </button>
        <button
          className='bg-cta-200 items-center text-white px-3 rounded-lg font-semibold mr-2 h-10'
          onClick={onClickNext}
        >
          Next
        </button>
      </>
    )
  }

  const handleOnChecked = (checked: boolean, value: OtherFacilityTypesEnum) => {
    if (!checked) {
      setFacility(prevState => [...prevState, value])
    } else {
      const newGoals = facility.filter(f => f !== value)
      setFacility(newGoals)
    }
  }

  return (
    <Wizard
      isOpen={currentModal === 'FacilityTypesWizard'}
      onClose={onClose}
      header={<Header />}
      footer={<Footer />}
      exitModal={
        <CancelProjectWizardModal
          showCancelModal={showCancelProjectWizardModal}
          onConfirmLeave={onConfirmLeaveWizardModal}
          onKeepEditing={onKeepEditingWizardModal}
        />
      }
    >
      {industry
        && (
          <>
            <div className='flex flex-col justify-center items-center w-full sm:w-full'>
              <div className='text-primary-100 text-xl font-bold mb-2 text-center'>
                What facility types are included in this project?
              </div>
              <div className='text-primary-200 text-base font-semibold text-center mb-6'>
                Select all that apply
              </div>
              <div className='text-primary-200 text-base font-semibold text-center mb-6'>
                <IndustriesChip industry={industry} />
              </div>
              <div className='overflow-x-visible grid grid-cols-1 gap-4 scroll-smooth'>
                {(Object.values(OtherFacilityTypesEnum) as Array<OtherFacilityTypesEnum>).map((facility, index) => (
                  <Checkbox
                    key={index}
                    checked={selectedFacilities?.values.find(f => f === facility) ? true : false}
                    text={getNameWithoutUnderScores(facility)}
                    value={facility}
                    onChange={(checked) => handleOnChecked(checked, facility)}
                  />
                ))}
              </div>
            </div>
          </>
        )}
      <SlideIndicators />
    </Wizard>
  )
}
