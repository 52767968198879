import { toast } from 'react-toastify'
import { setActiveScreen } from 'theorem-lib/src/helpers/setActiveScreen'

import { InviteUser } from '../../components/organisms/SlideOuts/AddUserSlideOut'
import {
  CollaboratorInformation,
  InviteProjectCollaboratorsListInput,
  Member,
  ProjectMemberInvite,
  ProjectMemberStatusEnum,
  ProjectRole,
  Status,
  User,
} from '../../generated/api'
import { Context } from '..'
import { defaultErrorHandler } from './actions'

export async function displayUserManagementAction(context: Context) {
  context.state.currentPage = 'Loading'
  try {
    context.state.users = (await context.effects.getUsers()).filter(u => !u.isTheoremAdmin)
    context.state.tenantInfo = await context.effects.getTenantInfoByCode()
    context.state.userSearchString = ''
    context.state.currentPage = 'UserManagement'
    setActiveScreen(context, 'Admin')
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function updateUserStatusAction(context: Context, value: { user: User; status: Status }) {
  try {
    value.user.status = value.status
    await context.effects.adminUpdateUser(value.user)
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function updateUserBusinessRoleAction(
  context: Context,
  value: { user: User; businessRole: string },
) {
  try {
    value.user.businessRole = value.businessRole
    await context.effects.adminUpdateUser(value.user)
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function submitInviteNewUserAction(context: Context, values: InviteUser) {
  try {
    await context.effects.inviteUser(values.email, values.firstName, values.securityRoles)
    context.state.users = await context.effects.getUsers()
    toast('Invitation has been sent successfully')
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function reinviteUserAction(context: Context, value: { user: User }) {
  try {
    await context.effects.reInviteUser(value.user)
    toast('Invitation has been re-sent successfully.')
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function updateUserSecurityRoleAction(context: Context, value: { user: User; securityRole: string }) {
  try {
    await context.effects.removeRoleFromUser(value.user, value.user.securityRoles[0])
    await context.effects.addRoleToUser(value.user, value.securityRole)
    context.state.users = (await context.effects.getUsers()).filter(u => !u.isTheoremAdmin)
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function searchUser(context: Context, value: string) {
  context.state.userSearchString = value
}

export async function updateUserEditAction(context: Context, value: { user: User }) {
  context.state.userEdit = value.user
}

export async function submitMemberNewAction(
  context: Context,
  value: { projectId: string; memberList: { user: User | undefined; role: ProjectRole | undefined }[] },
) {
  const Ids: ProjectMemberInvite[] = []
  value.memberList.forEach(member => {
    if (member?.role?.id && member?.user?.id) {
      Ids.push({
        roleId: member.role.id,
        userId: member.user.id,
      })
    }
  })
  const tenant = await context.effects.getTenantInfoByCode()
  const { __typename, ...restOfTenantDetails } = tenant
  await context.effects.inviteMembersToProject(value.projectId, Ids, restOfTenantDetails)

  value.memberList.forEach(member => {
    if (member?.role?.id && member?.user?.id) {
      context.state?.selectedProject?.members?.push({
        avatarUrl: member.user.avatarUrl,
        email: member.user.email,
        firstName: member.user.firstName,
        id: member.user.id,
        lastName: member.user.lastName,
        role: member.role,
        status: ProjectMemberStatusEnum.Pending,
      } as Member)
    }
  })

  if (Ids.length >= 0) toast('Invites Sent')
  else toast('Invalid entries!')
  context.state.currentSlideOut = 'None'
}

export async function submitCollaboratorInviteNewUserAction(
  context: Context,
  values: InviteProjectCollaboratorsListInput,
) {
  try {
    const tenant = await context.effects.getTenantInfoByCode()
    const { __typename, ...restOfTenantDetails } = tenant
    await context.effects.inviteCollaborators({ ...values, tenant: restOfTenantDetails })
    const selectedProject = await context.effects.getProject(context.state.selectedProject?.id)
    context.state.selectedProject = { ...context.state.selectedProject, ...selectedProject }
    toast('Invitation has been sent successfully')
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function submitCollaboratorInvitesToFolioAction(
  context: Context,
  collaborators: CollaboratorInformation[],
) {
  try {
    const tenantDetails = await context.effects.getTenantInfoByCode()
    await context.effects.inviteCollaboratorsToFolio(collaborators, { ...tenantDetails, __typename: undefined })
    toast('Invitation has been sent successfully')
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}
