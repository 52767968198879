import { yupResolver } from '@hookform/resolvers/yup'
import React, { useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import PlusIcon from 'theorem-lib/src/assets/icons/plus.svg'
import TrashIcon from 'theorem-lib/src/assets/icons/trash.svg'
import { InviteCollaboratorToFolioFormValidator } from 'theorem-lib/src/validators/inviteUserFormValidator'
import { CollaboratorInformation } from '../../../generated/api'
import { useActions } from '../../../presenter'
import { SlideOut } from '../../atoms/SlideOut/SlideOut'
import { SlideOutFooter } from '../../atoms/SlideOutFooter/SlideoutFooter'
import TextInput from '../../atoms/TextInput/TextInput'

interface CollaboratorInvitationSlideOutProps {
  subtitle: string
}

export const CollaboratorInvitationSlideOutTemplate = (props: CollaboratorInvitationSlideOutProps) => {
  const {
    displayModalAction,
    displaySlideOutAction,
    submitCollaboratorInvitesToFolioAction,
  } = useActions()

  const [isDirty, setIsDirty] = useState<boolean>(false)
  const { control, formState: { errors }, handleSubmit, register } = useForm({
    defaultValues: {
      values: [{
        email: '',
        firstName: '',
      }],
    },
    resolver: yupResolver(InviteCollaboratorToFolioFormValidator),
  })

  const { append, fields, remove } = useFieldArray(
    {
      control,
      name: 'values',
    },
  )
  const onSubmit = async (data: SubmitFormType) => {
    if (!data.values.length) {
      toast.error('Please add at least one collaborator.')
      return
    }

    displaySlideOutAction('None')

    const collaborators: CollaboratorInformation[] = data.values.map(collaborator => ({
      email: collaborator.email,
      firstName: collaborator.firstName,
    }))
    await submitCollaboratorInvitesToFolioAction(collaborators)
  }

  type InviteUserFormType = {
    email: string
    firstName: string
  }

  type SubmitFormType = {
    values: InviteUserFormType[]
  }

  return (
    <SlideOut
      title='Add Collaborators'
      onClose={() => isDirty ? displayModalAction('CancelProjectEditModal') : displaySlideOutAction('None')}
    >
      <h2 className='mb-10 text-primary-200 font-normal'>
        {props.subtitle}
      </h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ul>
          {fields?.map((field, index) => {
            return (
              <li key={field.id} className='block mb-3 border-l-4 border-l-pink-700'>
                <div className='inline-block w-25 mt-3 mb-4 ml-2 pr-2 align-top'>
                  <TextInput
                    placeholder='First Name'
                    label='First Name'
                    type='text'
                    {...register(`values.${index}.firstName`, {
                      minLength: 1,
                      onChange: (() => {
                        setIsDirty(true)
                      }),
                      required: true,
                    })}
                  />
                  <span className='flex flex-grow text-error font-medium text-xs'>
                    {errors.values?.[index]?.firstName !== undefined && 'Please enter a valid First Name'}
                  </span>
                </div>
                <div className='inline-block w-25 mt-3 mb-4 pr-2 align-top'>
                  <TextInput
                    placeholder='Email'
                    label='email'
                    {...register(`values.${index}.email`, {
                      onChange: (() => {
                        setIsDirty(true)
                      }),
                      pattern: {
                        message: 'Please enter a valid email address',
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      },
                      required: 'This field is required.',
                    })}
                  />
                  <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                    {errors.values?.[index]?.email?.message}
                  </span>
                </div>
                <div className='mt-4 inline-block align-text-baseline'>
                  <button
                    className=' inline-block ml-1 cursor-pointer bg-[#FFFFFF] text-[#7E9FAC] hover:text-[#7E9FAC] rounded-lg px-2 py-2 bg-blue-100'
                    type='button'
                    onClick={() => remove(index)}
                  >
                    <TrashIcon />
                  </button>
                </div>
              </li>
            )
          })}
        </ul>
        <div>
          <button
            onClick={() => {
              append({
                email: '',
                firstName: '',
              })
            }}
            type='button'
            className='mt-3 inline-flex items-center font-bold text-pink-700'
          >
            <PlusIcon />
            Add another collaborator
          </button>
        </div>

        <SlideOutFooter>
          <button
            className={`bg-cta-200 hover:bg-cta-300 text-white p-3 rounded-lg font-semibold mr-2`}
            type='submit'
          >
            Send Invitation
          </button>
        </SlideOutFooter>
      </form>
    </SlideOut>
  )
}
