import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid'
import React, { useState } from 'react'
import Dropdown from '../../atoms/Dropdown/Dropdown'

export type FilterItem = {
  imageUrl?: string
  isSelected?: boolean
  id: string
  value: string
  enum?: any
}

type ListFilterProps = {
  label: string
  items: FilterItem[] | any[]
  isOpen: boolean
  hasBorder?: boolean
  isTypeAhead?: boolean
  hasImages?: boolean
  onClearAll?: () => void
  onClick: () => void
  onClose: () => void
  onChange: (items: FilterItem[]) => void
  className?: string
}

export function ListFilter(
  {
    className,
    hasBorder = true,
    hasImages = false,
    isOpen,
    isTypeAhead = false,
    items,
    label,
    onChange,
    onClearAll,
    onClick,
    onClose,
  }: ListFilterProps,
) {
  const [searchText, setSearchText] = useState<string>('')

  const handleSelection = (selectedItem: FilterItem) => {
    const index = items.filter(item => item.isSelected).findIndex(item => item.id === selectedItem.id)
    if (index >= 0) {
      const itemsCopy = [...items.filter(item => item.isSelected)]
      itemsCopy.splice(index, 1)
      onChange(itemsCopy)
    } else {
      onChange([...items.filter(item => item.isSelected), { ...selectedItem, isSelected: true }])
    }
    // stops dropdown from closing after list re-renders
    setTimeout(onClick, 0)
  }

  const getFieldDisplayValue = (): string => {
    const values = items.filter(i => i.isSelected).map(i => i.value)
    if (values.length <= 1) {
      return values.toString()
    } else {
      return `${values[0]}, +${values.length - 1}`
    }
  }

  const Field = () => (
    <div
      className={`flex flex-row min-w-[257px] h-14 px-4 py-2 rounded-lg box-border border-primary-500 cursor-pointer justify-between ${
        hasBorder && 'border'
      } ${className}`}
      onClick={onClick}
    >
      <div className='flex flex-col'>
        <div className='text-primary-400 text-xs'>{label}</div>
        {!isTypeAhead && (
          <div className='text-primary-100 text-base'>
            {getFieldDisplayValue()}
          </div>
        )}
      </div>
      {isOpen
        ? <ChevronUpIcon className='w-5 text-primary-300' />
        : <ChevronDownIcon className='w-5 text-primary-300' />}
    </div>
  )

  const Item = (item: FilterItem) => (
    <div className='flex flex-row items-center mb-2 cursor-default'>
      <input
        className='rounded-sm'
        type='checkbox'
        checked={item.isSelected}
        onChange={() => handleSelection(item)}
      />
      {item.imageUrl && hasImages && <img className='ml-4 w-6 h-6 rounded-full' src={item.imageUrl} alt='item-img' />}
      <div className='ml-3 text-base text-primary-200'>{item.value}</div>
    </div>
  )

  const getItemList = (): FilterItem[] => {
    return isTypeAhead ? items.filter(item => item.value.toLowerCase().includes(searchText.toLowerCase())) : items
  }

  const onSearch = (e: React.FormEvent<HTMLInputElement>) => {
    setSearchText(e.currentTarget.value)
    onClick()
  }

  return (
    <div className='relative'>
      {isTypeAhead && (
        <input
          type='text'
          className='border-none h-6 bg-transparent absolute top-7 w-[85%] z-10'
          value={searchText}
          onChange={onSearch}
        />
      )}
      <Dropdown isOpen={isOpen} onClose={onClose} openButton={<Field />}>
        {!!onClearAll && items.filter(i => i.isSelected).length > 0 && (
          <div className='pb-4 mb-4 text-cta-200 font-semibold text-base border-b-primary-500 border-b'>
            <span className='cursor-pointer' onClick={() => !!onClearAll && onClearAll()}>Select None</span>
          </div>
        )}
        <div className='w-56 box-border'>
          {getItemList().map(
            item => (
              <Item
                key={item.id}
                id={item.id}
                value={item.value}
                imageUrl={item.imageUrl}
                isSelected={item.isSelected}
              />
            ),
          )}
        </div>
        {getItemList().length === 0 && 'No results'}
      </Dropdown>
    </div>
  )
}
