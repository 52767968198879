import { ChevronRightIcon } from '@heroicons/react/solid'
import { truncate } from 'lodash'
import React from 'react'
import CaseIcon from 'theorem-lib/src/assets/icons/case.svg'
import { Client } from '../../../generated/api'
import { useActions } from '../../../presenter'
import Dropdown from '../../atoms/Dropdown/Dropdown'

type ClientDropdownProps = {
  client: Client
  title: React.ReactNode
}

export function ClientDropdown({ client, title }: ClientDropdownProps) {
  const { redirectAction } = useActions()

  const getDate = (): string => {
    const date = new Date(client.createdOn)
    return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`
  }

  const goToClientPage = () => {
    redirectAction(`/clients/${client.id}`)
  }

  return (
    <Dropdown shouldOpenOnHover openButton={title} openTo='right'>
      <div className='w-[337px] text-primary-100 flex flex-row justify-between cursor-pointer' onClick={goToClientPage}>
        <div className='flex flex-row'>
          <div
            className='w-[60px] h-[60px] bg-primary-300 rounded mr-4 bg-cover bg-no-repeat bg-center text-center'
            style={{ backgroundImage: `url(${client.logoUrl})` }}
          >
            {!client.logoUrl && <CaseIcon className='inline text-primary-500 mt-5' />}
          </div>
          <div className='flex flex-col justify-between'>
            <div className='text-base font-semibold'>{client.name}</div>
            <div className='text-xs text-primary-300 font-semibold'>
              {truncate(client?.industries?.map(industry => industry?.name).join(', '), { length: 35 })}
            </div>
            {client.createdBy && (
              <div className='text-xs font-normal'>
                <span className='text-primary-300'>Created by</span>
                <span className='text-primary-100 mx-1'>
                  {`${client.createdBy.firstName} ${client.createdBy.lastName}`}
                </span>
                <span className='text-primary-300'>on</span>
                <span className='text-primary-100 mx-1'>{`${getDate()}`}</span>
              </div>
            )}
          </div>
        </div>
        <div className='flex items-center'>
          <ChevronRightIcon className='w-[28px] h-[24px] text-primary-300' />
        </div>
      </div>
    </Dropdown>
  )
}
