import React from 'react'
import { Modal } from 'theorem-lib/src/components/molecules/Modal/Modal'

interface CancelProjectWizardModalProps {
  showCancelModal: boolean
  onConfirmLeave: () => void
  onKeepEditing: () => void
}

export function CancelProjectWizardModal(props: CancelProjectWizardModalProps) {
  return (
    <Modal
      isOpen={props.showCancelModal}
      onClose={props.onKeepEditing}
    >
      <div className='flex flex-col justify-center items-center w-[80vw] sm:w-[558px]'>
        <div className='text-primary-100 text-xl font-semibold mb-2 text-center'>
          Are you sure you want to leave without saving?
        </div>
        <div className='text-primary-300 text-base font-normal text-center mb-6'>
          Changes you have made so far will not be saved.
        </div>
        <div className='flex flex-row'>
          <button type='button' className='button mx-2' onClick={props.onKeepEditing}>Keep Editing</button>
          <button type='button' className='button cta mx-2' onClick={props.onConfirmLeave}>Confirm</button>
        </div>
      </div>
    </Modal>
  )
}
