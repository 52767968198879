export enum BusinessRolesEnum {
  // Admin and Member
  Sales = 'Sales',
  SalesSupport = 'Sales Support',
  Design = 'Design',
  ProjectManagement = 'Project Management',
  Operations = 'Operations',
  Administrative = 'Administrative',
  Other = 'Other',

  // Participant
  Client = 'Client',
  RealEstate = 'Real Estate',
  Architect = 'Architect',
  ADDesigner = 'A&D Designer',
  Contractor = 'Contractor',
}
