import React, { ReactElement } from 'react'

export const PulseAnimation = (): ReactElement => {
  return (
    <div className='shadow rounded-md p-4 max-w-full w-full '>
      <div className='animate-pulse flex space-x-4'>
        <div className='rounded-full bg-gray-400 h-12 w-12'></div>
        <div className='flex-1 space-y-4 py-1'>
          <div className='h-4 bg-gray-400 rounded w-3/4'></div>
          <div className='space-y-2'>
            <div className='h-4 bg-gray-400 rounded'></div>
            <div className='h-4 bg-gray-400 rounded w-5/6'></div>
          </div>
        </div>
      </div>
    </div>
  )
}
