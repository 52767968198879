import { toast } from 'react-toastify'
import { CreateServicesListInput, UpdateServicesInput } from '../../generated/api'
import { Context } from '..'
import { defaultErrorHandler } from './actions'

export async function deleteServiceAction(context: Context, value: { serviceId: string }) {
  try {
    await context.effects.deleteService(value.serviceId)
    context.state.selectedProjectEstimates = await context.effects.getProjectEstimates(context.state.selectedProject.id)
    const projectServices = await context.effects.getProjectServices(context.state.selectedProject.id)
    context.state.selectedProjectServices = projectServices
    toast('Service deleted successfully!')
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function createServiceAction(
  context: Context,
  value: { projectId: string; servicesList: CreateServicesListInput[] },
) {
  try {
    await context.effects.createServices(value)
    context.state.selectedProjectEstimates = await context.effects.getProjectEstimates(value.projectId)
    const projectServices = await context.effects.getProjectServices(value.projectId)
    context.state.selectedProjectServices = projectServices
    toast('The list of services have been created successfully!')
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function updateServiceAction(
  context: Context,
  value: { projectId: string; service: UpdateServicesInput },
) {
  try {
    await context.effects.updateServices(value.projectId, value.service)
    context.state.selectedProjectEstimates = await context.effects.getProjectEstimates(value.projectId)
    const projectServices = await context.effects.getProjectServices(value.projectId)
    context.state.selectedProjectServices = projectServices
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function listAllServicesTemplates(context: Context, id: string) {
  context.state.servicesTemplates = await context.effects.getAllServicesTemplates()
}
