import React, { useEffect } from 'react'
import ProjectsIcon from 'theorem-lib/src/assets/icons/case.svg'
import { useActions, useAppState } from '../../presenter'
import { UserProjectsInvitations } from '../atoms/UserProjectInvitations/UserProjectsInvitations'
import { IconCard } from '../molecules/IconCard/IconCard'
import { NotificationWidget } from '../molecules/NotificationWidget/NotificationWidget'

export function Dashboard() {
  const { authenticatedUser, notifications, projectCount } = useAppState()
  const {
    showWelcomeToFolioAction,
  } = useActions()

  useEffect(() => {
    showWelcomeToFolioAction()
  }, [])

  return (
    <>
      <div className='flex flex-row'>
        <h2 className='text-xl font-medium mb-5'>Dashboard</h2>
      </div>
      <div className='flex flex-row'>
        <h2 className='text-xl font-medium text-primary-100'>
          <p>
            <span className='text-primary-400'>Welcome back,</span> {authenticatedUser.firstName} 👋!
          </p>
        </h2>
      </div>
      <div className='flex flex-row'>
        <div className='flex flex-col grow'>
          <div className='my-8'>
            <IconCard icon={ProjectsIcon} label='Projects' value={projectCount} iconClassName='mt-4' />
          </div>
          <UserProjectsInvitations />
        </div>
        <div className='flex flex-col grow'>
          <NotificationWidget notifications={notifications} />
        </div>
      </div>
    </>
  )
}
