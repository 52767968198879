import React, { useState } from 'react'

interface CheckboxProps {
  text: string
  value: string
  checked?: boolean
  onChange: (checked: boolean) => void
}

export const Checkbox = (props: CheckboxProps) => {
  const [checked, setChecked] = useState<boolean>(props.checked ?? false)

  const handleOnClick = async () => {
    setChecked(prevState => !prevState)
    props.onChange(checked)
  }

  return (
    <div
      className='flex w-80 h-20 items-center pl-4 rounded border border-[#CFDBE0] cursor-pointer'
      onClick={handleOnClick}
    >
      <input
        checked={checked}
        id='bordered-checkbox-1'
        onChange={(e) => <></>}
        type='checkbox'
        value={props.value}
        name='bordered-checkbox'
        className='w-4 h-4 text-primary-200 rounded border-[#CFDBE0] focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:border-[#CFDBE0]'
      />
      <span className='ml-6 text-primary-200 font-semibold'>{props.text}</span>
    </div>
  )
}
