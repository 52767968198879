import { IndustriesEnum } from '../components/molecules/NewProjectCreation/IndustriesSelect'
import {
  OtherAdministrativeGoalsEnum,
  OtherBusinessGoalsEnum,
  OtherClinicalGoalsEnum,
  OtherSpacesEnum,
} from '../generated/api'

export const getBusinessEnumByIndustry = (industry: string): OtherBusinessGoalsEnum[] => {
  switch (industry) {
    case IndustriesEnum.Corporate:
      return [
        OtherBusinessGoalsEnum.DrivingCulture,
        OtherBusinessGoalsEnum.TargetingNewBusinessSegment,
        OtherBusinessGoalsEnum.LeveragingNewTechnology,
        OtherBusinessGoalsEnum.ReducingRealEstateCost,
        OtherBusinessGoalsEnum.ImprovingCommunications,
        OtherBusinessGoalsEnum.DrivingInnovation,
        OtherBusinessGoalsEnum.RetainingEmployees,
        OtherBusinessGoalsEnum.IncreasingProductivity,
        OtherBusinessGoalsEnum.HealthAndSafetyInitiatives,
        OtherBusinessGoalsEnum.ManagingAShiftInBusiness,
        OtherBusinessGoalsEnum.ManagingADistributedWorkforce,
        OtherBusinessGoalsEnum.SocialImpact,
        OtherBusinessGoalsEnum.Other,
        OtherBusinessGoalsEnum.NotSure,
      ]
    case IndustriesEnum.Technology:
      return [
        OtherBusinessGoalsEnum.DrivingCulture,
        OtherBusinessGoalsEnum.TargetingNewBusinessSegment,
        OtherBusinessGoalsEnum.LeveragingNewTechnology,
        OtherBusinessGoalsEnum.ReducingRealEstateCost,
        OtherBusinessGoalsEnum.ImprovingCommunications,
        OtherBusinessGoalsEnum.DrivingInnovation,
        OtherBusinessGoalsEnum.RetainingEmployees,
        OtherBusinessGoalsEnum.IncreasingProductivity,
        OtherBusinessGoalsEnum.HealthAndSafetyInitiatives,
        OtherBusinessGoalsEnum.ManagingAShiftInBusiness,
        OtherBusinessGoalsEnum.ManagingADistributedWorkforce,
        OtherBusinessGoalsEnum.SocialImpact,
        OtherBusinessGoalsEnum.Other,
        OtherBusinessGoalsEnum.NotSure,
      ]
    case IndustriesEnum.Education:
      return [
        OtherBusinessGoalsEnum.DrivingCulture,
        OtherBusinessGoalsEnum.TargetingNewBusinessSegment,
        OtherBusinessGoalsEnum.LeveragingNewTechnology,
        OtherBusinessGoalsEnum.ReducingRealEstateCost,
        OtherBusinessGoalsEnum.ImprovingCommunications,
        OtherBusinessGoalsEnum.DrivingInnovation,
        OtherBusinessGoalsEnum.AttractingFutureStudents,
        OtherBusinessGoalsEnum.SecuringNewStudents,
        OtherBusinessGoalsEnum.EnhancingFacultyOrStudentExperience,
        OtherBusinessGoalsEnum.PreparingStudentsForEmployment,
        OtherBusinessGoalsEnum.RetainingEmployees,
        OtherBusinessGoalsEnum.RetainingStudents,
        OtherBusinessGoalsEnum.HealthAndSafetyInitiatives,
        OtherBusinessGoalsEnum.ManagingAShiftInBusiness,
        OtherBusinessGoalsEnum.ManagingHybridLearning,
        OtherBusinessGoalsEnum.SocialImpact,
        OtherBusinessGoalsEnum.ResearchInitiatives,
        OtherBusinessGoalsEnum.IncreasingProductivity,
        OtherBusinessGoalsEnum.FosteringStudentCollaboration,
        OtherBusinessGoalsEnum.StudentWellnessAndPersonalGrowth,
        OtherBusinessGoalsEnum.Other,
        OtherBusinessGoalsEnum.NotSure,
      ]
    case IndustriesEnum.Government:
      return [
        OtherBusinessGoalsEnum.DrivingAgencyCulture,
        OtherBusinessGoalsEnum.TargetingNewAgencyEnvironments,
        OtherBusinessGoalsEnum.LeveragingNewTechnology,
        OtherBusinessGoalsEnum.ReducingRealEstateCost,
        OtherBusinessGoalsEnum.ImprovingCommunications,
        OtherBusinessGoalsEnum.DrivingInnovation,
        OtherBusinessGoalsEnum.RetainingEmployees,
        OtherBusinessGoalsEnum.IncreasingProductivity,
        OtherBusinessGoalsEnum.HealthAndSafetyInitiatives,
        OtherBusinessGoalsEnum.ManagingAShiftInAgencyFocus,
        OtherBusinessGoalsEnum.ManagingADistributedAgencyWorkforce,
        OtherBusinessGoalsEnum.SocialImpact,
        OtherBusinessGoalsEnum.Other,
        OtherBusinessGoalsEnum.NotSure,
      ]
    default:
      return []
  }
}

export const getSpacesEnumByIndustry = (industry: string): OtherSpacesEnum[] => {
  switch (industry) {
    case IndustriesEnum.Corporate:
      return [
        OtherSpacesEnum.LobbyAndReception,
        OtherSpacesEnum.HuddleAndPhone,
        OtherSpacesEnum.Conference,
        OtherSpacesEnum.Training,
        OtherSpacesEnum.PrivateOffice,
        OtherSpacesEnum.OpenOffice,
        OtherSpacesEnum.LoungeAndBreakout,
        OtherSpacesEnum.CafeAndBreakroom,
        OtherSpacesEnum.FilingAndStorage,
        OtherSpacesEnum.WellnessAndSpecialty,
        OtherSpacesEnum.Outdoor,
        OtherSpacesEnum.WorkFromHome,
        OtherSpacesEnum.Other,
      ]
    case IndustriesEnum.Technology:
      return [
        OtherSpacesEnum.LobbyAndReception,
        OtherSpacesEnum.HuddleAndPhone,
        OtherSpacesEnum.Conference,
        OtherSpacesEnum.Training,
        OtherSpacesEnum.PrivateOffice,
        OtherSpacesEnum.OpenOffice,
        OtherSpacesEnum.LoungeAndBreakout,
        OtherSpacesEnum.CafeAndBreakroom,
        OtherSpacesEnum.FilingAndStorage,
        OtherSpacesEnum.WellnessAndSpecialty,
        OtherSpacesEnum.Outdoor,
        OtherSpacesEnum.WorkFromHome,
        OtherSpacesEnum.Other,
      ]
    case IndustriesEnum.Healthcare:
      return [
        OtherSpacesEnum.LobbyAndWaiting,
        OtherSpacesEnum.HuddleAndPhone,
        OtherSpacesEnum.Conference,
        OtherSpacesEnum.Training,
        OtherSpacesEnum.PhysicianOffices,
        OtherSpacesEnum.WorkFromHome,
        OtherSpacesEnum.CheckInAndCheckOut,
        OtherSpacesEnum.AdministrativeStations,
        OtherSpacesEnum.LoungeAndBreakout,
        OtherSpacesEnum.CafeAndBreakroom,
        OtherSpacesEnum.NurseStations,
        OtherSpacesEnum.RadiologyReadingRoom,
        OtherSpacesEnum.Consultation,
        OtherSpacesEnum.ExamRooms,
        OtherSpacesEnum.Labs,
        OtherSpacesEnum.PatientRooms,
        OtherSpacesEnum.BehavioralHealth,
        OtherSpacesEnum.WellnessAndSpecialty,
        OtherSpacesEnum.Telemedicine,
        OtherSpacesEnum.Treatment,
        OtherSpacesEnum.Other,
      ]
    case IndustriesEnum.Education:
      return [
        OtherSpacesEnum.LobbyAndReception,
        OtherSpacesEnum.HuddleAndPhone,
        OtherSpacesEnum.Conference,
        OtherSpacesEnum.Training,
        OtherSpacesEnum.CafeAndBreakroom,
        OtherSpacesEnum.Library,
        OtherSpacesEnum.Labs,
        OtherSpacesEnum.TestingStations,
        OtherSpacesEnum.Classrooms,
        OtherSpacesEnum.Athletic,
        OtherSpacesEnum.StudentHousing,
        OtherSpacesEnum.LearningCollaborative,
        OtherSpacesEnum.OpenOffice,
        OtherSpacesEnum.PrivateOffice,
        OtherSpacesEnum.WellnessAndSpecialty,
        OtherSpacesEnum.LoungeAndBreakout,
        OtherSpacesEnum.FilingAndStorage,
        OtherSpacesEnum.Other,
      ]
    case IndustriesEnum.Government:
      return [
        OtherSpacesEnum.LobbyAndReception,
        OtherSpacesEnum.HuddleAndPhone,
        OtherSpacesEnum.Conference,
        OtherSpacesEnum.Training,
        OtherSpacesEnum.PrivateOffice,
        OtherSpacesEnum.OpenOffice,
        OtherSpacesEnum.LoungeAndBreakout,
        OtherSpacesEnum.CafeAndBreakroom,
        OtherSpacesEnum.FilingAndStorage,
        OtherSpacesEnum.WellnessAndSpecialty,
        OtherSpacesEnum.Outdoor,
        OtherSpacesEnum.Benching,
        OtherSpacesEnum.Other,
      ]
    default:
      return []
  }
}

export const getAdminEnumByIndustry = (industry: string): OtherAdministrativeGoalsEnum[] => {
  switch (industry) {
    case IndustriesEnum.Healthcare:
      return [
        OtherAdministrativeGoalsEnum.LeveragingNewTechnology,
        OtherAdministrativeGoalsEnum.ReducingRealEstateCost,
        OtherAdministrativeGoalsEnum.ImprovingCommunications,
        OtherAdministrativeGoalsEnum.DrivingInnovation,
        OtherAdministrativeGoalsEnum.ManagingPatientExperience,
        OtherAdministrativeGoalsEnum.RefreshingTheSpace,
        OtherAdministrativeGoalsEnum.RetainingStaff,
        OtherAdministrativeGoalsEnum.IncreasingProductivity,
        OtherAdministrativeGoalsEnum.HealthAndSafetyInitiatives,
        OtherAdministrativeGoalsEnum.ManagingADistributedWorkforce,
        OtherAdministrativeGoalsEnum.SocialImpact,
        OtherAdministrativeGoalsEnum.NotSure,
        OtherAdministrativeGoalsEnum.DoesNotApply,
        OtherAdministrativeGoalsEnum.Other,
      ]
    default:
      return []
  }
}

export const getClinicalEnumByIndustry = (industry: string): OtherClinicalGoalsEnum[] => {
  switch (industry) {
    case IndustriesEnum.Healthcare:
      return [
        OtherClinicalGoalsEnum.IncreasingCaregiverSupport,
        OtherClinicalGoalsEnum.ReducingRealEstateCost,
        OtherClinicalGoalsEnum.ImprovingCommunications,
        OtherClinicalGoalsEnum.ExpandingIntoRegionalCommunities,
        OtherClinicalGoalsEnum.DrivingInnovation,
        OtherClinicalGoalsEnum.Telemedicine,
        OtherClinicalGoalsEnum.RefreshingTheSpace,
        OtherClinicalGoalsEnum.LeveragingNewTechnology,
        OtherClinicalGoalsEnum.ManagingAShiftInCare,
        OtherClinicalGoalsEnum.HealthAndSafetyInitiatives,
        OtherClinicalGoalsEnum.ExpandingTreatmentOfferings,
        OtherClinicalGoalsEnum.ManagingPatientExperienceAndFlow,
        OtherClinicalGoalsEnum.SocialImpact,
        OtherClinicalGoalsEnum.Other,
        OtherClinicalGoalsEnum.NotSure,
        OtherClinicalGoalsEnum.DoesNotApply,
      ]
    default:
      return []
  }
}

export const getEnumKeyByEnumValue = <T extends { [index: string]: string }>(
  myEnum: T,
  enumValue: string,
): keyof T | null => {
  const keys = Object.keys(myEnum).filter(x => myEnum[x] == enumValue)
  return keys.length > 0 ? keys[0] : null
}
