import { DotsVerticalIcon } from '@heroicons/react/solid'
import { truncate } from 'lodash'
import React, { useState } from 'react'
import AvatarCircle from 'theorem-lib/src/components/atoms/AvatarCircle/AvatarCircle'
import AvatarInitial from 'theorem-lib/src/components/atoms/AvatarInitial/AvatarInitial'
import { Project } from '../../../generated/api'
import { useActions } from '../../../presenter'
import Card from '../../atoms/Card/Card'
import { ProjectDropdown } from '../ProjectDropdown/ProjectDropdown'

type ProjectCardProps = {
  project?: Project
}

export const ProjectCard = (props: ProjectCardProps): React.ReactElement => {
  const { redirectAction, setSelectedProjectAction } = useActions()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const TITLE_CHAR_LIMIT = 50
  const MEMBERS_IMAGE_AMOUNT = 3

  const renderTeamMembers = (): React.ReactElement => {
    const styles = 'h-7 w-7 box-border text-[10px] border-2 border-white border-solid ml-[-7px]'
    const members = props.project?.members?.slice(0, MEMBERS_IMAGE_AMOUNT) || []
    const extraAmount = props.project?.members
      ? props.project.members.length - MEMBERS_IMAGE_AMOUNT
      : 0

    const avatars = members.map((member) => {
      return member.avatarUrl
        ? (
          <AvatarCircle
            key={member.id}
            url={member.avatarUrl}
            className={styles}
          />
        )
        : (
          <AvatarInitial
            key={member.id}
            firstName={member.firstName || ''}
            lastName={member.lastName || ''}
            className={styles}
          />
        )
    })
    return (
      <div className='flex flex-row-reverse w-[312px] justify-start -mb-3 pr-3'>
        {extraAmount > 0 && (
          <AvatarInitial
            className={styles}
            firstName='+'
            lastName={extraAmount.toString()}
          />
        )}
        {avatars}
      </div>
    )
  }

  const onCardClick = () => {
    setSelectedProjectAction(props.project)
    redirectAction(`/project/${props.project?.id}/dashboard`)
  }

  return (
    <Card
      className='w-[312px] flex flex-col mr-4 cursor-pointer'
      onClick={() =>
        props.project
        && onCardClick()}
    >
      <div
        className={`w-[312px] h-[120px] bg-primary-300 rounded-t-lg bg-cover bg-no-repeat bg-center flex items-end`}
        style={{ backgroundImage: `url(${props.project?.coverImageUrl})` }}
      >
        {!!props.project?.members && renderTeamMembers()}
      </div>
      <div className='h-[88px] px-3 pb-3 pt-2'>
        <div className='text-primary-300 font-semibold text-xs pb-1 leading-4'>
          {props.project?.industry.name}
        </div>
        <div className='flex flex-row justify-between'>
          <div className='text-primary-100 font-semibold text-lg leading-6'>
            {truncate(props.project?.name, { length: TITLE_CHAR_LIMIT })}
          </div>
          <div className='h-6 w-6 ml-1'>
            <ProjectDropdown
              isOpen={isDropdownOpen}
              onClose={() => setIsDropdownOpen(false)}
              project={props.project}
              isInternal
              openButton={
                <DotsVerticalIcon
                  onClick={(e) => {
                    e.stopPropagation()
                    setIsDropdownOpen(!isDropdownOpen)
                  }}
                  className='text-primary-300 w-5'
                />
              }
            />
          </div>
        </div>
      </div>
    </Card>
  )
}

export default ProjectCard
