import { yupResolver } from '@hookform/resolvers/yup'
import React, { useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import Select from 'react-select'
import PlusIcon from 'theorem-lib/src/assets/icons/plus.svg'
import TrashIcon from 'theorem-lib/src/assets/icons/trash.svg'
import { useActions, useAppState } from '../../../presenter'
import { AddSpaceFormValidator } from '../../../validators/addSpaceFormValidator'
import { customStyles, IconOption, IconSingleValue } from '../../atoms/ReactSelectStyling/selectStyle'
import { SlideOut } from '../../atoms/SlideOut/SlideOut'
import { SlideOutFooter } from '../../atoms/SlideOutFooter/SlideoutFooter'
import TextInput from '../../atoms/TextInput/TextInput'

type SpaceFormType = {
  templateId: string
  quantity: number
}

type SubmitFormType = {
  values: SpaceFormType[]
}

type itemObject = {
  totalLowPrice: number | undefined
  totalMidPrice: number | undefined
  totalHighPrice: number | undefined
  spaceId: string
}

type SelectOptionType = {
  image: string
  label: string
  value: string
}

export function AddSpaceSlideOut() {
  const {
    displayModalAction,
    displaySlideOutAction,
    submitCreateProjectSpaceAction,
  } = useActions()
  const { selectedProject, spaces, spacesItemTemplates } = useAppState()

  const { control, formState: { errors }, handleSubmit, register, setValue } = useForm({
    defaultValues: {
      values: [{
        quantity: 1,
        templateId: '',
      }],
    },
    resolver: yupResolver(AddSpaceFormValidator),
  })

  const [isDirty, setIsDirty] = useState<boolean>(false)

  const { append, fields, remove } = useFieldArray(
    {
      control,
      name: 'values',
    },
  )

  const onSubmit = async (data: SubmitFormType) => {
    displaySlideOutAction('None')
    const newSpacesList = data.values.map(i => ({
      quantity: Math.floor(i.quantity),
      templateId: i.templateId,
    }))
    const spacesFilteredList = newSpacesList.filter((space: SpaceFormType) =>
      space.templateId !== '' && space.quantity > 0
    )

    const isInvalid = spacesFilteredList.length !== newSpacesList.length

    await submitCreateProjectSpaceAction({
      isInvalid,
      projectId: selectedProject.id,
      spacesList: spacesFilteredList,
    })
  }

  const spaceItems: itemObject[] = []

  for (const space of spacesItemTemplates) {
    const totalHighPrice = space.itemTemplate?.highPrice * space.itemsQuantity || 0
    const totalMidPrice = space.itemTemplate?.midPrice * space.itemsQuantity || 0
    const totalLowPrice = space.itemTemplate?.lowPrice * space.itemsQuantity || 0
    const item: itemObject = {
      spaceId: space.spacesTemplate?.id || '',
      totalHighPrice,
      totalLowPrice,
      totalMidPrice,
    }
    spaceItems.push(item)
  }

  return (
    <SlideOut
      title='Add Spaces'
      onClose={() => isDirty ? displayModalAction('CancelProjectEditModal') : displaySlideOutAction('None')}
    >
      <h2 className='mb-6 text-primary-200 font-normal'>
        Choose from a curated list of pre-defined spaces and items to help you quickly build a high-level budget.
      </h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ul>
          {fields.map((field, index) => {
            return (
              <li key={field.id} className='flex flex-row mb-3'>
                <div className='w-3/5 border-b border-primary-400'>
                  <Select
                    placeholder='Select a space'
                    options={spaces.map(space => ({
                      description: spaceItems.filter(spaceItem => spaceItem.spaceId === space.id),
                      image: space.image,
                      label: space.name,
                      value: space.id,
                    }))}
                    styles={customStyles}
                    onChange={(value) => {
                      setIsDirty(true)
                      setValue(`values.${index}.templateId`, (value as SelectOptionType)?.value)
                    }}
                    components={{ Option: IconOption, SingleValue: IconSingleValue }}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        neutral10: 'none',
                        primary: 'lightblue',
                      },
                    })}
                  />
                </div>
                <div className='mt-6 ml-2 w-1/5'>
                  <TextInput
                    label='Qty'
                    type='number'
                    min='0'
                    {...register(`values.${index}.quantity`)}
                  />
                  <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                    {errors.values?.[index]?.quantity?.message}
                  </span>
                </div>
                {index !== 0
                  && (
                    <div className='mt-6 inline-block align-text-baseline'>
                      <button
                        className=' ml-8 cursor-pointer fill-[#2b5b74] button rounded-lg px-2 py-2 bg-blue-100 '
                        type='button'
                        onClick={() => remove(index)}
                      >
                        <TrashIcon />
                      </button>
                    </div>
                  )}
              </li>
            )
          })}
        </ul>
        <div>
          <button
            onClick={() => {
              append({
                quantity: 1,
                templateId: '',
              })
            }}
            type='button'
            className='mt-3 inline-flex items-center font-bold text-pink-700'
          >
            <PlusIcon />
            Add another space
          </button>
        </div>

        <SlideOutFooter>
          <button type='submit' className='button cta'>Save</button>
        </SlideOutFooter>
      </form>
    </SlideOut>
  )
}
