import React from 'react'
import RoomIcon from 'theorem-lib/src/assets/icons/room.svg'

type ServiceEmptyStateProps = {
  isParticipant?: boolean
}

export const ServiceEmptyState = (
  props: ServiceEmptyStateProps,
): React.ReactElement => (
  <div className='flex flex-col justify-center items-center w-full mt-20'>
    <div className='rounded-full border-primary-100 border-2 border-solid p-3 mb-4'>
      <RoomIcon />
    </div>
    <div className='text-primary-100 text-xl font-semibold mb-1'>
      You don&apos;t have any services yet
    </div>
    {props.isParticipant
      ? (
        <>
          <div className='text-primary-300 text-base font-normal text-center max-w-[600px]'>
            As services are added by your team, you’ll see them here.
          </div>
        </>
      )
      : (
        <>
          <div className='text-primary-300 text-base font-normal text-center max-w-[600px]'>
            Click the Manage Services button to add services like installation, freight, and more to help your client
            have a clear vision of budgetary project cost.
          </div>
        </>
      )}
  </div>
)
