import { createOvermind, IContext } from 'overmind'
import { createActionsHook, createStateHook } from 'overmind-react'
import { uploadAvatarImage } from 'theorem-lib/src/gateways/FileStoreGateway'
import { getIndustries } from '../gateways/IndustryGateway'
import { deleteNotification, getNotifications, sendNotification } from '../gateways/NotificationGateway'
import {
  acceptInvitation,
  deleteProjectMember,
  denyInvitation,
  inviteCollaborators,
  inviteMembersToProject,
  listUsersForProjectInvite,
  resendInvitation,
  sendWelcomeToFolioEmail,
  updateProjectMemberRole,
  viewProjectCount,
  viewUserInvitations,
} from '../gateways/ProjectMemberGateway'
import {
  addClient,
  createProject,
  deleteClient,
  deleteProject,
  getClient,
  getClients,
  getFolioClients,
  getProject,
  getProjectEstimates,
  getProjectServices,
  getProjectSpaces,
  getProjectsRoles,
  searchProjectsAndClients,
  toggleProjectPin,
  updateClient,
  updateDiscInc,
  updateProject,
  updateProjectOtherSpaces,
  updateProjectsStage,
} from '../gateways/ProjectsAndClientsGateway'
import { createServices, deleteService, getAllServicesTemplates, updateServices } from '../gateways/ServiceGateway'
import {
  copySpace,
  createProjectSpaces,
  createSpaceComment,
  deleteSpace,
  getAllItemTemplates,
  getAllSpaceComments,
  getAllSpacesItemTemplates,
  getSpaces,
  updateItemIndex,
  updateProjectSpace,
  updateSpaceLists,
} from '../gateways/SpacesGateway'
import {
  addRoleToUser,
  adminUpdateUser,
  getAuthenticatedUser,
  getDisplayUser,
  getDisplayUsersByUserIds,
  getUsers,
  getUsersByUserIds,
  getUsersForProjectInvite,
  inviteCollaboratorsToFolio,
  inviteUser,
  reInviteUser,
  removeRoleFromUser,
  updateAuthenticatedUser,
  updateUser,
} from '../gateways/UserGateway'
import {
  addTaskComment,
  addTasklistToProject,
  archiveTasklist,
  completeSection,
  createorUpdateTaskAnswer,
  createTask,
  createTasklist,
  deleteTask,
  deleteTasklist,
  getPredefinedTasklists,
  getTaskComments,
  getTasklists,
  updateTask,
  updateTasklist,
} from '../gateways/WorkbookGateway'
import {
  bootstrapStateAction,
  displayDashboardAction,
  displayModalAction,
  displayNotFoundAction,
  displayRegistrationWelcomeToFolioAction,
  displaySlideOutAction,
  redirectAction,
} from './actions/actions'
import {
  cleanNewClientStateAction,
  closeClientEditAction,
  closeClientNewAction,
  deleteClientAction,
  discardClientEditsAction,
  discardClientNewAction,
  displayClientHomeAction,
  getClientAction,
  getFolioClientsAction,
  showWelcomeToFolioAction,
  submitClientEditAction,
  submitClientNewAction,
  submitUpdateClientAction,
  updateClientEditAction,
  updateClientNewAction,
  uploadClientImageAction,
} from './actions/clients'
import {
  dismissNotificationAction,
  getNotificationsAction,
  sendAssignedToTaskListNotificationAction,
  sendAssignedToTaskNotificationAction,
  sendMentionNotificationAction,
  sendUnassignedToTaskListNotificationAction,
  sendUnassignedToTaskNotificationAction,
} from './actions/notifications'
import {
  acceptInvitationAction,
  cleanProjectCreation,
  cleanSelectedProjectIndustryDetails,
  clearNewProjectCreation,
  clearProjectsAndClientsFilterAction,
  deleteProjectAction,
  deleteProjectMemberAction,
  denyInvitationAction,
  displayNewProjectSlideOut,
  displayProjectDashboardAction,
  displayProjectDetailsAction,
  displayProjectImageryAction,
  displayProjectsAction,
  displayProjectSpacesAction,
  displayProjectTasklistsAction,
  displayProjectTeamAction,
  displayProjectWorkbookAction,
  excelGenerator,
  formatProjectPricingCSVAction,
  getProjectById,
  projectCreationStateMachine,
  resendMemberInvitationAction,
  searchProjectsAndClientsAction,
  setDrawerDataToWizard,
  setNewProjectCreationClientId,
  setProjectCreationAdministrativeGoals,
  setProjectCreationBusinessGoals,
  setProjectCreationClientId,
  setProjectCreationClinicalGoals,
  setProjectCreationElements,
  setProjectCreationExperienceLevel,
  setProjectCreationFacilities,
  setProjectCreationGeneralInfo,
  setProjectCreationHeadCount,
  setProjectCreationHomeType,
  setProjectCreationHomeWorkSpace,
  setProjectCreationIndustry,
  setProjectCreationSize,
  setProjectCreationSpaces,
  setProjectCreationVisualPreferences,
  setSelectedProjectAction,
  setSelectedProjectTaskListIndex,
  setWizardDataToDrawer,
  submitCreateProjectAction,
  submitProjectCreationAction,
  submitProjectDiscIncEditAction,
  submitProjectEditAction,
  submitUpdateProjectAction,
  submitUpdateProjectStageAction,
  toggleProjectDetailsEditMode,
  toggleProjectPinAction,
  updateDateEndFilterAction,
  updateDateStartFilterAction,
  updateIndustryFilterAction,
  updateMembersFilterAction,
  updateProjectDeleteIdAction,
  updateProjectMemberDeleteIdAction,
  updateProjectMemberRoleAction,
  updateProjectMemberRoleIdsAction,
  updateProjectOtherSpacesAction,
} from './actions/projects'
import {
  createServiceAction,
  deleteServiceAction,
  listAllServicesTemplates,
  updateServiceAction,
} from './actions/services'
import {
  copySpaceAction,
  createSpaceCommentAction,
  deleteSpaceAction,
  getAllItemTemplates as getAllItemTemplatesAction,
  getAllSpaceCommentsAction,
  getAllSpacesItemTemplates as getAllSpacesItemTemplatesAction,
  submitCreateProjectSpaceAction,
  submitUpdateProjectSpaceAction,
  updateItemIndexAction,
  updateSelectedSpaceAction,
  updateSpaceDeleteIdAction,
  updateSpaceListAction,
} from './actions/spaces'
import {
  displayUserEditAction,
  getUsersByIds,
  submitUserEditAction,
  uploadAvatarImageAction,
  userRemoveAvatarAction,
} from './actions/user'
import {
  displayUserManagementAction,
  reinviteUserAction,
  searchUser,
  submitCollaboratorInviteNewUserAction,
  submitCollaboratorInvitesToFolioAction,
  submitInviteNewUserAction,
  submitMemberNewAction,
  updateUserBusinessRoleAction,
  updateUserEditAction,
  updateUserSecurityRoleAction,
  updateUserStatusAction,
} from './actions/user_mgmt'
import {
  addTaskCommentAction,
  addTasklistToProjectAction,
  archiveTasklistAction,
  closeManageTaskAction,
  completeSectionAction,
  createOrUpdateTaskAnswerAction,
  createTaskAction,
  createTasklistAction,
  deleteTaskAction,
  deleteTasklistAction,
  displayProjectTaskAction,
  displayUpdateTaskListSlideOut,
  setDeleteTasklistIdAction,
  submitUpdateTasklistAction,
  updateTaskAction,
  updateTaskEditAction,
} from './actions/workbook'

import { getTenantInfoByCode } from '../gateways/TenantGateway'
import { getTenantInfoByCodeAction, getTenantInformationAction } from './actions/tenants'
import { redirect } from './router'
import { clientState, coreState, notificationState, tenantState, userState } from './state'

const overmindConfig = {
  actions: {
    acceptInvitationAction,
    addTaskCommentAction,
    addTasklistToProjectAction,
    archiveTasklistAction,
    bootstrapStateAction,
    cleanNewClientStateAction,
    cleanProjectCreation,
    cleanSelectedProjectIndustryDetails,
    clearNewProjectCreation,
    clearProjectsAndClientsFilterAction,
    closeClientEditAction,
    closeClientNewAction,
    closeManageTaskAction,
    completeSectionAction,
    copySpaceAction,
    createOrUpdateTaskAnswerAction,
    createServiceAction,
    createSpaceCommentAction,
    createTaskAction,
    createTasklistAction,
    deleteClientAction,
    deleteProjectAction,
    deleteProjectMemberAction,
    deleteServiceAction,
    deleteSpaceAction,
    deleteTaskAction,
    deleteTasklistAction,
    denyInvitationAction,
    discardClientEditsAction,
    discardClientNewAction,
    dismissNotificationAction,
    displayClientHomeAction,
    displayDashboardAction,
    displayModalAction,
    displayNewProjectSlideOut,
    displayNotFoundAction,
    displayProjectDashboardAction,
    displayProjectDetailsAction,
    displayProjectImageryAction,
    displayProjectSpacesAction,
    displayProjectTaskAction,
    displayProjectTasklistsAction,
    displayProjectTeamAction,
    displayProjectWorkbookAction,
    displayProjectsAction,
    displayRegistrationWelcomeToFolioAction,
    displaySlideOutAction,
    displayUpdateTaskListSlideOut,
    displayUserEditAction,
    displayUserManagementAction,
    excelGenerator,
    formatProjectPricingCSVAction,
    getAllItemTemplatesAction,
    getAllSpaceCommentsAction,
    getAllSpacesItemTemplatesAction,
    getClientAction,
    getFolioClientsAction,
    getNotificationsAction,
    getProjectById,
    getTenantInfoByCodeAction,
    getTenantInformationAction,
    getUsersByIds,
    listAllServicesTemplates,
    projectCreationStateMachine,
    redirectAction,
    reinviteUserAction,
    resendMemberInvitationAction,
    searchProjectsAndClientsAction,
    searchUser,
    sendAssignedToTaskListNotificationAction,
    sendAssignedToTaskNotificationAction,
    sendMentionNotificationAction,
    sendUnassignedToTaskListNotificationAction,
    sendUnassignedToTaskNotificationAction,
    setDeleteTasklistIdAction,
    setDrawerDataToWizard,
    setNewProjectCreationClientId,
    setProjectCreationAdministrativeGoals,
    setProjectCreationBusinessGoals,
    setProjectCreationClientId,
    setProjectCreationClinicalGoals,
    setProjectCreationElements,
    setProjectCreationExperienceLevel,
    setProjectCreationFacilities,
    setProjectCreationGeneralInfo,
    setProjectCreationHeadCount,
    setProjectCreationHomeType,
    setProjectCreationHomeWorkSpace,
    setProjectCreationIndustry,
    setProjectCreationSize,
    setProjectCreationSpaces,
    setProjectCreationVisualPreferences,
    setSelectedProjectAction,
    setSelectedProjectTaskListIndex,
    setWizardDataToDrawer,
    showWelcomeToFolioAction,
    submitClientEditAction,
    submitClientNewAction,
    submitCollaboratorInviteNewUserAction,
    submitCollaboratorInvitesToFolioAction,
    submitCreateProjectAction,
    submitCreateProjectSpaceAction,
    submitInviteNewUserAction,
    submitMemberNewAction,
    submitProjectCreationAction,
    submitProjectDiscIncEditAction,
    submitProjectEditAction,
    submitUpdateClientAction,
    submitUpdateProjectAction,
    submitUpdateProjectSpaceAction,
    submitUpdateProjectStageAction,
    submitUpdateTasklistAction,
    submitUserEditAction,
    toggleProjectDetailsEditMode,
    toggleProjectPinAction,
    updateClientEditAction,
    updateClientNewAction,
    updateDateEndFilterAction,
    updateDateStartFilterAction,
    updateIndustryFilterAction,
    updateItemIndexAction,
    updateMembersFilterAction,
    updateProjectDeleteIdAction,
    updateProjectMemberDeleteIdAction,
    updateProjectMemberRoleAction,
    updateProjectMemberRoleIdsAction,
    updateProjectOtherSpacesAction,
    updateSelectedSpaceAction,
    updateServiceAction,
    updateSpaceDeleteIdAction,
    updateSpaceListAction,
    updateTaskAction,
    updateTaskEditAction,
    updateUser,
    updateUserBusinessRoleAction,
    updateUserEditAction,
    updateUserSecurityRoleAction,
    updateUserStatusAction,
    uploadAvatarImageAction,
    uploadClientImageAction,
    userRemoveAvatarAction,
  },
  effects: {
    acceptInvitation,
    addClient,
    addRoleToUser,
    addTaskComment,
    addTasklistToProject,
    adminUpdateUser,
    archiveTasklist,
    completeSection,
    copySpace,
    createProject,
    createProjectSpaces,
    createServices,
    createSpaceComment,
    createTask,
    createTasklist,
    createorUpdateTaskAnswer,
    deleteClient,
    deleteNotification,
    deleteProject,
    deleteProjectMember,
    deleteService,
    deleteSpace,
    deleteTask,
    deleteTasklist,
    denyInvitation,
    getAllItemTemplates,
    getAllServicesTemplates,
    getAllSpaceComments,
    getAllSpacesItemTemplates,
    getAuthenticatedUser,
    getClient,
    getClients,
    getDisplayUser,
    getDisplayUsersByUserIds,
    getFolioClients,
    getIndustries,
    getNotifications,
    getPredefinedTasklists,
    getProject,
    getProjectEstimates,
    getProjectServices,
    getProjectSpaces,
    getProjectsRoles,
    getSpaces,
    getTaskComments,
    getTasklists,
    getTenantInfoByCode,
    getUsers,
    getUsersByUserIds,
    getUsersForProjectInvite,
    inviteCollaborators,
    inviteCollaboratorsToFolio,
    inviteMembersToProject,
    inviteUser,
    listUsersForProjectInvite,
    reInviteUser,
    redirect,
    removeRoleFromUser,
    resendInvitation,
    searchProjectsAndClients,
    sendNotification,
    sendWelcomeToFolioEmail,
    toggleProjectPin,
    updateAuthenticatedUser,
    updateClient,
    updateDiscInc,
    updateItemIndex,
    updateProject,
    updateProjectMemberRole,
    updateProjectOtherSpaces,
    updateProjectSpace,
    updateProjectsStage,
    updateServices,
    updateSpaceLists,
    updateTask,
    updateTasklist,
    updateUser,
    uploadAvatarImage,
    viewProjectCount,
    viewUserInvitations,
  },
  state: {
    ...coreState,
    ...userState,
    ...clientState,
    ...notificationState,
    ...tenantState,
  },
}

export type Context = IContext<{
  state: typeof overmindConfig.state
  actions: typeof overmindConfig.actions
  effects: typeof overmindConfig.effects
}>

export const overmindApp = createOvermind(overmindConfig)

export const useAppState = createStateHook<Context>()
export const useActions = createActionsHook<Context>()
