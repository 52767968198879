import { ApolloClient, ApolloError, FetchResult, InMemoryCache, ServerError } from '@apollo/client'
import config from '../config'
import {
  AddTaskCommentDocument,
  AddTasklistToProjectDocument,
  ArchiveTasklistDocument,
  CompleteTasksInSectionDocument,
  CreateCommentInput,
  CreateTaskDocument,
  CreateTaskListDocument,
  CreateTasklistInput,
  DeleteTaskDocument,
  DeleteTasklistDocument,
  GetTaskCommentsDocument,
  PredefinedTasklistsDocument,
  Task,
  TaskComment,
  Tasklist,
  TasklistsForProjectDocument,
  UpdateTaskDocument,
  UpdateTaskInput,
  UpdateTaskListDocument,
  UpdateTasklistInput,
  UpsertTaskAnswerDocument,
  UpsertTaskAnswerInput,
} from '../generated/api'
import { UnauthenticatedError, UnexpectedError } from '../presenter/errors'

function isApolloError(err: unknown | ApolloError): err is ApolloError {
  return (err as ApolloError).graphQLErrors !== undefined
}

function isServerError(err: unknown | ServerError): err is ServerError {
  return (err as ServerError).name == 'ServerError'
}

function graphqlErrorHandler(err: unknown | ApolloError) {
  if (isApolloError(err)) {
    const networkError = err.networkError
    if (isServerError(networkError)) {
      if (networkError.statusCode == 401) throw new UnauthenticatedError()
      if (networkError.statusCode == 404) throw new UnexpectedError()
    }
  }
}

const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  credentials: 'include',
  uri: config.appApiGraphqlUrl,
})

export const getTasklists = async (projectId: string): Promise<Tasklist[]> => {
  try {
    const { data } = await apolloClient.query({
      fetchPolicy: 'no-cache',
      query: TasklistsForProjectDocument,
      variables: { projectId },
    })
    return data.tasklistsForProject
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const getPredefinedTasklists = async (projectId: string): Promise<Tasklist[]> => {
  try {
    const { data } = await apolloClient.query({
      fetchPolicy: 'no-cache',
      query: PredefinedTasklistsDocument,
      variables: { projectId },
    })
    return data.predefinedTasklists
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const updateTask = async (projectId: string, input: UpdateTaskInput) => {
  try {
    const t = await apolloClient.mutate({
      fetchPolicy: 'no-cache',
      mutation: UpdateTaskDocument,
      variables: { input: input, projectId: projectId },
    })
    if (t && t.data && t.data.updateTask) {
      return t.data.updateTask
    }
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const deleteTask = async (projectId: string, taskId: string): Promise<void> => {
  try {
    await apolloClient.mutate({
      fetchPolicy: 'no-cache',
      mutation: DeleteTaskDocument,
      variables: { id: taskId, projectId },
    })
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const addTasklistToProject = async (projectId: string, templateId: string): Promise<void> => {
  try {
    await apolloClient.mutate({
      fetchPolicy: 'no-cache',
      mutation: AddTasklistToProjectDocument,
      variables: { projectId, templateId },
    })
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const updateTasklist = async (projectId: string, tasklist: UpdateTasklistInput): Promise<void> => {
  const input: UpdateTasklistInput = {
    assignedUserIds: tasklist.assignedUserIds,
    description: tasklist.description,
    dueDate: tasklist.dueDate,
    id: tasklist.id,
    name: tasklist.name,
    removedUserIds: tasklist.removedUserIds,
  }
  try {
    await apolloClient.mutate({
      mutation: UpdateTaskListDocument,
      variables: { input, projectId },
    })
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const completeSection = async (projectId: string, sectionId: string): Promise<void> => {
  try {
    await apolloClient.mutate({
      fetchPolicy: 'no-cache',
      mutation: CompleteTasksInSectionDocument,
      variables: { projectId, sectionId },
    })
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const createTask = async (
  taskName: string,
  projectId: string,
  sectionId: string,
): Promise<{ createTask: Task }> => {
  try {
    const newTask = await apolloClient.mutate({
      fetchPolicy: 'no-cache',
      mutation: CreateTaskDocument,
      variables: {
        input: {
          name: taskName,
          sectionId: sectionId,
        },
        projectId: projectId,
      },
    })
    return newTask.data
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const createorUpdateTaskAnswer = async (
  projectId: string,
  input: UpsertTaskAnswerInput,
): Promise<FetchResult> => {
  try {
    const newAnswer = await apolloClient.mutate({
      fetchPolicy: 'no-cache',
      mutation: UpsertTaskAnswerDocument,
      variables: {
        input: input,
        projectId: projectId,
      },
    })
    return newAnswer
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const getTaskComments = async (
  projectId: string,
  taskId: string,
) => {
  try {
    const comments = await apolloClient.query({
      fetchPolicy: 'no-cache',
      query: GetTaskCommentsDocument,
      variables: {
        projectId,
        taskId,
      },
    })
    comments.data.getTaskComments.sort(sortCommentByDate)
    return comments.data.getTaskComments
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

const sortCommentByDate = (curr: TaskComment, next: TaskComment) => {
  if (!curr.createdOn || !next.createdOn) return 0
  if (curr.createdOn < next.createdOn) return 1
  if (curr.createdOn > next.createdOn) return -1
  return 0
}

export const addTaskComment = async (input: CreateCommentInput) => {
  try {
    const newComment = await apolloClient.mutate({
      fetchPolicy: 'no-cache',
      mutation: AddTaskCommentDocument,
      variables: {
        input,
      },
    })
    return newComment
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const createTasklist = async (input: CreateTasklistInput) => {
  try {
    const newList = await apolloClient.mutate({
      fetchPolicy: 'no-cache',
      mutation: CreateTaskListDocument,
      variables: {
        input,
      },
    })
    return newList
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const deleteTasklist = async (projectId: string, id: string) => {
  try {
    await apolloClient.mutate({
      fetchPolicy: 'no-cache',
      mutation: DeleteTasklistDocument,
      variables: {
        id,
        projectId,
      },
    })
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const archiveTasklist = async (projectId: string, id: string) => {
  try {
    await apolloClient.mutate({
      fetchPolicy: 'no-cache',
      mutation: ArchiveTasklistDocument,
      variables: {
        id,
        projectId,
      },
    })
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}
