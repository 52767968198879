import * as Yup from 'yup'

export const ManageDiscountFormValidator = Yup.object().shape({
  values: Yup.array().of(
    Yup.object().shape({
      percentage: Yup.number().typeError('Field can not be empty').integer().min(1, 'Value can not be less than 1.')
        .max(99, 'Value can not be more than 99').nullable(true),
    }),
  ),
})
