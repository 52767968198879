import React, { useEffect, useState } from 'react'
import { useForm, useFormState } from 'react-hook-form'
import Select from 'react-select'
import { toast } from 'react-toastify'
import PlusIcon from 'theorem-lib/src/assets/icons/plus.svg'
import TrashIcon from 'theorem-lib/src/assets/icons/trash.svg'
import {
  InviteProjectCollaboratorsListInput,
  Maybe,
  ProjectCollaboratorInvite,
  SecurityRoleEnum,
  Status,
  User,
} from '../../../generated/api'
import { useActions, useAppState } from '../../../presenter'
import { ProjectSecurityRoles } from '../../../types/gql_enums'
import { SlideOut } from '../../atoms/SlideOut/SlideOut'
import { SlideOutFooter } from '../../atoms/SlideOutFooter/SlideoutFooter'
import TextInput from '../../atoms/TextInput/TextInput'

export function MemberInvitationSlideOut() {
  const { projectRoles, selectedProject, users } = useAppState()

  const {
    displayModalAction,
    displaySlideOutAction,
    getUsersByIds,
    submitCollaboratorInviteNewUserAction,
    submitInviteNewUserAction,
    submitMemberNewAction,
  } = useActions()

  interface NewUserProjectInvite {
    index: number
    required: boolean
  }

  const { formState: { errors }, getValues, handleSubmit, register, setValue, unregister } = useForm<SubmitFormType>()

  const [userList, setUserList] = useState<User[]>([])
  const [options, setOptions] = useState<UserSelectOptions[]>([])
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const [inputList, setInputList] = useState([{ email: 'Email', firstName: 'First Name', role: 'default' }])
  const [radios, setRadios] = useState<boolean>(false)

  useEffect(() => {
    const getUserList = async () => {
      const usersByIds = await getUsersByIds(users.map(user => user.id))
      if (usersByIds != undefined) {
        usersByIds.forEach(user => {
          setUserList(prevState => [...prevState, user])
          const existingProjectMember = selectedProject.members.find(u => u.id === user.id)
          if (
            typeof user.firstName === 'string'
            && typeof user.lastName === 'string'
            && user.securityRoles[0].toString() !== SecurityRoleEnum.Participant
            && user.status === Status.Active
            && !existingProjectMember
          ) {
            const newUserObject = {
              avatarUrl: user.avatarUrl,
              firstName: user.firstName,
              id: user.id,
              label: user.firstName + ' ' + user.lastName,
              lastName: user.lastName,
              value: user.id,
            }
            setOptions(prevState => [...prevState, newUserObject])
          }
        })
      }
    }

    getUserList()
  }, [users, submitInviteNewUserAction, submitCollaboratorInviteNewUserAction])

  const handleAddClick = () => {
    setInputList([...inputList, {
      email: 'Email',
      firstName: 'First Name',
      role: 'default',
    }])
  }

  const setNewUserInvite = (index: number, required: boolean) => {
    const newIndex: NewUserProjectInvite = {
      index: index,
      required: required,
    }
  }

  const handleDeleteClick = (index: number) => {
    const list = [...inputList]
    list.splice(index, 1)
    setInputList(list)
  }

  const onSubmit = async (data: SubmitFormType) => {
    data.values.map((value: AddMemberFormType) => {
      if (!value.isCollaborator) {
        const newInviteList = {
          email: value.email,
          firstName: value?.user?.firstName,
          memberId: value?.user?.id,
          projectRole: value.role,
        }

        const memberRole = projectRoles.find(r => r.name === newInviteList.projectRole)

        const memberInviteList = {
          role: memberRole,
          user: userList.find(u => u.id === newInviteList.memberId),
        }

        submitMemberNewAction({
          memberList: [memberInviteList],
          projectId: selectedProject.id,
        })
      } else if (value.isCollaborator || value.role === ProjectSecurityRoles.Collaborator) {
        const duplicateMemberEmail = selectedProject.members.find(u => u.email === value.email)

        if (duplicateMemberEmail) {
          toast('This user is already a member of the project - ' + value.email)
        } else {
          const collaboratorsListInput: InviteProjectCollaboratorsListInput = {
            projectId: selectedProject.id,
            usersList: [{
              email: value.email,
              firstName: value.firstName,
              roleId: projectRoles.find(f => f.name === ProjectSecurityRoles.Collaborator)?.id,
            }] as ProjectCollaboratorInvite[],
          }

          submitNewUser(collaboratorsListInput)
        }
      } else {
        toast('All fields are required')
      }
    })
  }

  const submitNewUser = async (collaboratorsListInput: InviteProjectCollaboratorsListInput) => {
    await submitCollaboratorInviteNewUserAction(collaboratorsListInput)
    displaySlideOutAction('None')
  }
  const onErrors = () => {
    toast.error('Error sending invitation')
  }

  type AddMemberFormType = {
    email?: string
    firstName?: string
    role?: string
    user?: UserSelectOptions
    isCollaborator?: boolean
  }

  type SubmitFormType = {
    values: AddMemberFormType[]
  }

  type UserSelectOptions = {
    avatarUrl?: string | null
    firstName?: Maybe<string> | undefined
    id?: string
    label?: string
    lastName?: string
    value?: string
  }

  const swapDivs = (index: string) => {
    setNewUserInvite(Number(index), true)

    const d1 = document.getElementById(index + 'selectDiv')
    const d2 = document.getElementById(index + 'collabButDiv')
    const d3 = document.getElementById(index + 'firstNameDiv')
    const d4 = document.getElementById(index + 'emailDiv')
    const t1 = document.getElementById(index + 'deleteButtonDiv')

    if (
      d1 != null
      && d2 != null
      && d3 != null
      && d4 != null
      && t1 != undefined
    ) {
      if (d3.style.display == 'none') {
        d1.style.display = 'none'
        d2.style.display = 'none'
        d3.style.display = 'inline-block'
        d4.style.display = 'inline-block'
        t1.style.display = 'block'
      }
    }
  }

  const displayRadios = (index: number) => {
    setRadios(true)
    const indexString = index.toString()

    const d1 = document.getElementById(indexString + 'collabButDiv')
    const s1 = document.getElementById(indexString + 'radioMessage')
    const r1 = document.getElementById(indexString + 'leadRadio')
    const r2 = document.getElementById(indexString + 'editorRadio')
    const t1 = document.getElementById(indexString + 'deleteButtonDiv')

    if (r1 != null && r2 != null && s1 != null) {
      d1!.style.display = 'none'
      s1!.style.display = 'block'
      r1!.style.display = 'block'
      r2!.style.display = 'block'
      t1!.style.display = 'block'
    }
  }

  return (
    <SlideOut
      title='Add New Project Members and Collaborators'
      onClose={() => isDirty ? displayModalAction('CancelProjectEditModal') : displaySlideOutAction('None')}
    >
      <h1 className='mb-10 mt-1'>Easily add new people to your project.</h1>

      <form
        onSubmit={handleSubmit(onSubmit, onErrors)}
      >
        {inputList.map((input, index) => {
          return (
            <div key={index} id={index.toString()} className='flex flex-row mb-3 mr-3 border-l-4 border-l-pink-700'>
              <div className='w-1/2 pl-2' id={index.toString() + 'selectDiv'} style={{ display: 'inline-block' }}>
                <label htmlFor='name'>
                  Member
                </label>
                <Select
                  placeholder='Select a member'
                  options={options}
                  onChange={(value) => {
                    setIsDirty(true)
                    setValue(`values.${index}.user`, value as UserSelectOptions)
                    setValue(`values.${index}.isCollaborator`, false)
                    displayRadios(index)
                  }}
                />

                <br></br>
                <label id={index.toString() + 'leadRadio'} style={{ display: 'none' }}>
                  <span id={index.toString() + 'radioMessage'} style={{ display: 'none' }}>Select Member Type</span>
                  <input
                    type='radio'
                    value='Lead'
                    className='form-check-input mr-3'
                    {...register(`values.${index}.role`, {
                      required: !getValues(`values.${index}.isCollaborator`),
                    })}
                  />
                  Lead
                </label>
                <label id={index.toString() + 'editorRadio'} style={{ display: 'none' }}>
                  <input
                    type='radio'
                    value='Editor'
                    className='form-check-input mr-3'
                    {...register(`values.${index}.role`, {
                      required: !getValues(`values.${index}.isCollaborator`),
                    })}
                  />
                  Editor
                </label>
                <span className='flex flex-grow text-error font-medium text-xs'>
                  {!radios && 'Please select a user'}
                </span>
                <span className='flex flex-grow text-error font-medium text-xs'>
                  {errors.values?.[index]?.role !== undefined && 'Please select a role'}
                </span>
              </div>
              <div
                id={index.toString() + 'collabButDiv'}
                className='ml-2 w-2/5 pt-5 pl-3'
                style={{ alignItems: 'center', display: 'inline-block', justifyContent: 'center' }}
              >
                <button
                  onClick={() => (
                    swapDivs(index.toString()),
                      setValue(`values.${index}.isCollaborator`, true),
                      setValue(`values.${index}.role`, 'Collaborator'),
                      unregister(`values.${index}.role`)
                  )}
                  type='button'
                  className='mt-3 inline-flex items-center font-bold text-pink-700'
                >
                  <PlusIcon />
                  Add new collaborator
                </button>
              </div>
              <div className='w-1/2' id={index.toString() + 'radioButtonDiv'} style={{ display: 'none' }}>
              </div>
              <div
                id={index.toString() + 'firstNameDiv'}
                className='inline-block w-1/2 mt-8 mb-4 pl-2 align-top'
                style={{ display: 'none' }}
              >
                <TextInput
                  id={index.toString() + 'firstNameText'}
                  placeholder='First Name'
                  label='First Name'
                  type='text'
                  {...register(`values.${index}.firstName`, {
                    minLength: 1,
                    required: getValues(`values.${index}.isCollaborator`),
                  })}
                />
                <span className='flex flex-grow text-error font-medium text-xs'>
                  {errors.values?.[index]?.firstName !== undefined && 'Please enter a valid First Name'}
                </span>
              </div>
              <div
                id={index.toString() + 'emailDiv'}
                className='inline-block w-1/2 mt-8 mb-4 pl-2 align-top'
                style={{ display: 'none' }}
              >
                <TextInput
                  id={index.toString() + 'emailText'}
                  placeholder='Email'
                  label='Email'
                  type='text'
                  {...register(`values.${index}.email`, {
                    pattern: {
                      message: 'Please enter a valid email address',
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    },
                    required: getValues(`values.${index}.isCollaborator`),
                  })}
                />
                <span className='flex flex-grow text-error font-medium text-xs'>
                  {errors.values?.[index]?.email !== undefined && 'Please enter a valid email address'}
                </span>
              </div>
              <div
                className='pl-8 pt-7 align-center block cursor-pointer fill-[#DCDCDC]'
                id={index.toString() + 'deleteButtonDiv'}
                style={{ display: 'none' }}
              >
                <button
                  onClick={() =>
                    handleDeleteClick(index)}
                >
                  <TrashIcon />
                </button>
              </div>
            </div>
          )
        })}
        <div>
          <button
            onClick={handleAddClick}
            type='button'
            className='mt-3 inline-flex items-center font-bold text-pink-700'
          >
            <PlusIcon />
            Add another member or collaborator
          </button>
        </div>
        <SlideOutFooter>
          <button type='submit' className={'button cta'}>
            Send Invite
          </button>
        </SlideOutFooter>
      </form>
    </SlideOut>
  )
}
