import React from 'react'
import Card from '../../atoms/Card/Card'

type IconCardProps = {
  label: string
  value: React.ReactNode
  icon: React.ElementType
  cardClassName?: string
  iconClassName?: string
}

export const IconCard = (props: IconCardProps) => {
  const classOrEmptyString = (input: string | undefined) => {
    return input ? input : ''
  }

  return (
    <Card
      className={`bg-no-repeat bg-center bg-cover flex flex-col flex-1 mr-6 h-40 w-44 bg-cta-400 bg-opacity-10 shadow-none ${
        classOrEmptyString(props.cardClassName)
      }`}
    >
      <div
        className={'rounded-full bg-white w-14 h-14 mt-4 ml-4 text-cta-400 text-center'}
      >
        {<props.icon className={`inline ml-auto mr-auto ${classOrEmptyString(props.iconClassName)}`} />}
      </div>

      <div className={'flex flex-row items-center'}>
        <div className='ml-4 mt-3 text-xl truncate block w-34 mr-8'>
          {props.value}
        </div>
      </div>
      <div className={'flex flex-row items-center'}>
        <div className='ml-4 mt-1 text-xs truncate w-34 mr-20'>
          {props.label}
        </div>
      </div>
    </Card>
  )
}
