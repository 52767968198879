import page from 'page'
import { Context } from './index'

export enum RouteNames {
  Dashboard = 'Home',
  Projects = 'Projects',
  Admin = 'Admin',
  MRL = 'MRL',
  Indeal = 'INDEAL',
  Help = 'Help',
  Profile = 'Profile',
  Slack = 'Slack',
}

export function initializeRouter(context: Context): void {
  page.base('')

  page('/', () => {
    context.actions.displayDashboardAction()
  })

  page('/invitations/accept/:id', () => {
    context.actions.displayDashboardAction()
  })

  page('/registration/welcome', () => {
    context.actions.displayRegistrationWelcomeToFolioAction()
  })

  page('/users/edit', () => {
    context.actions.displayUserEditAction()
  })

  page('/users/management', () => {
    context.actions.displayUserManagementAction()
  })

  page('/projects', () => {
    context.actions.displayProjectsAction()
  })

  page('/project/:id/dashboard', ({ params }) => {
    context.actions.displayProjectDashboardAction(params.id)
  })

  page('/project/:id/details', ({ params }) => {
    context.actions.displayProjectDetailsAction(params.id)
  })

  page('/project/:id/budget', ({ params }) => {
    context.actions.displayProjectSpacesAction(params.id)
  })

  page('/project/:id/team', ({ params }) => {
    context.actions.displayProjectTeamAction(params.id)
  })

  page('/project/:id/workbook', ({ params }) => {
    context.actions.displayProjectWorkbookAction(params.id)
  })

  page('/project/:id/workbook/:tasklistId', ({ params }) => {
    context.actions.displayProjectTasklistsAction({ projectId: params.id, tasklistId: params.tasklistId })
  })

  page('/project/:id/workbook/:tasklistId/task/:taskId', ({ params }) => {
    context.actions.displayProjectTaskAction({
      projectId: params.id,
      taskId: params.taskId,
      tasklistId: params.tasklistId,
    })
  })

  page('/project/:id/imagery', ({ params }) => {
    context.actions.displayProjectImageryAction(params.id)
  })

  page('/clients/:id', ({ params }) => {
    context.actions.displayClientHomeAction(params.id)
  })

  page('*', () => {
    context.actions.displayNotFoundAction()
  })

  page.start()
}

export function redirect(url: string): void {
  page.redirect(url)
}
