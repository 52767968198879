import { XIcon } from '@heroicons/react/outline'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { PalletesImagesPool } from '../../../../assets/Industries/imagesPool'
import { VisualPreferencesInput } from '../../../generated/api'
import { useActions, useAppState } from '../../../presenter'
import { LikeDislike } from '../../atoms/LikeDislike/LikeDislike'
import { SlideIndicators } from '../../atoms/SlideIndicators/SlideIndicators'
import { Wizard } from '../../atoms/Wizard/Wizard'
import { IndustriesChip } from '../../molecules/IndustriesChip/IndustriesChip'
import { IndustriesEnum } from '../../molecules/NewProjectCreation/IndustriesSelect'
import { CancelProjectWizardModal } from '../Modals/CancelProjectWizardModal'

export const VisualPreferencesWizard = () => {
  const { currentModal, wizardProjectSelectedIndustry } = useAppState()
  const { cleanProjectCreation, displayModalAction, setProjectCreationVisualPreferences } = useActions()
  const [industry] = useState<IndustriesEnum | undefined>(wizardProjectSelectedIndustry)
  const [visualPreferences, setVisualPreferences] = useState<VisualPreferencesInput[]>([])
  const [showCancelProjectWizardModal, setShowCancelProjectWizardModal] = useState<boolean>(false)

  useEffect(() => {
    setVisualPreferences(PalletesImagesPool)
  }, [])

  const goBack = () => {
    SaveProject()
    displayModalAction(
      wizardProjectSelectedIndustry === IndustriesEnum.WorkFromHome
        ? 'HomeOfficeElementsWizard'
        : 'ProjectSizeWizard',
    )
  }

  const onClose = () => cleanProjectCreation()

  const onClickNext = () => {
    if (!visualPreferences?.find(f => f.like === true || f.dislike === true)) {
      toast('Indicate your visual preferences to continue', { type: 'error' })
      return
    }

    SaveProject()
    displayModalAction('GeneralProjectDetailsWizard')
  }

  const SaveProject = () => {
    setProjectCreationVisualPreferences(visualPreferences)
  }

  const onClickExit = () => {
    setShowCancelProjectWizardModal(true)
  }

  const onConfirmLeaveWizardModal = () => {
    cleanProjectCreation()
    displayModalAction('None')
  }

  const onKeepEditingWizardModal = () => {
    setShowCancelProjectWizardModal(false)
  }

  const Header = () => {
    return (
      <>
        <button
          type='button'
          className='rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none'
          onClick={onClickExit}
        >
          <XIcon className='h-6 w-6' aria-hidden='true' />
        </button>
      </>
    )
  }

  const Footer = () => {
    return (
      <>
        <button
          className='bg-[#F1F1F1] items-center text-[#35697D] px-3 rounded-lg font-semibold mr-2 h-10'
          onClick={() => goBack()}
        >
          {'<-'} Back
        </button>
        <button
          className='bg-cta-200 items-center text-white px-3 rounded-lg font-semibold mr-2 h-10'
          onClick={onClickNext}
        >
          Next
        </button>
      </>
    )
  }
  const onLikeClick = (cover: VisualPreferencesInput) => {
    const updatedList = visualPreferences?.map(item => {
      if (item.id == cover.id) {
        return { ...item, dislike: false, like: true }
      }
      return item
    })

    setVisualPreferences(updatedList)
  }
  const onDislikeClick = (cover: VisualPreferencesInput) => {
    const updatedList = visualPreferences?.map(item => {
      if (item.id == cover.id) {
        return { ...item, dislike: true, like: false }
      }
      return item
    })

    setVisualPreferences(updatedList)
  }
  const Thumbnail = (cover: VisualPreferencesInput) => (
    <div
      key={cover.id}
      className={`min-w-[276px] h-40 bg-primary-200 mr-2 rounded-md bg-cover bg-no-repeat bg-center cursor-pointer`}
      style={{ backgroundImage: `url('${cover.url}')` }}
    >
      <LikeDislike
        liked={cover.like}
        paddingTop='pt-36'
        disliked={cover.dislike}
        onLikeClick={() => onLikeClick(cover)}
        onDislikeClick={() => onDislikeClick(cover)}
      />
    </div>
  )

  return (
    <Wizard
      isOpen={currentModal === 'VisualPreferencesWizard'}
      onClose={onClose}
      height='800px'
      header={<Header />}
      footer={<Footer />}
      exitModal={
        <CancelProjectWizardModal
          showCancelModal={showCancelProjectWizardModal}
          onConfirmLeave={onConfirmLeaveWizardModal}
          onKeepEditing={onKeepEditingWizardModal}
        />
      }
    >
      {industry
        && (
          <>
            <div className='flex flex-col justify-center items-center w-full sm:w-full'>
              <div className='text-primary-100 text-xl font-bold mb-2 text-center'>
                Let&lsquo;s get to know your style preferences
              </div>
              <div className='text-primary-200 text-base font-semibold text-center mb-6'>
                What are some styles you find appealing?
              </div>
              <div className='text-primary-200 text-base font-semibold text-center mb-6'>
                <IndustriesChip industry={industry} />
              </div>
              <div className='grid grid-cols-3 gap-4 scroll-smooth w-full'>
                {visualPreferences.map((image, index) => (
                  <div key={index} className='mb-10'>
                    <Thumbnail key={index} {...image} dislike={image.dislike} />
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      <SlideIndicators />
    </Wizard>
  )
}
