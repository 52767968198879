import React from 'react'
import { SelectedProjectRole } from 'theorem-lib/src/entities/enums/SelectedProjectRole'
import { useAppState } from '../../../presenter'
import { DashboardTemplate } from './DashboardTemplate'

export function ProjectDashboard() {
  const { selectedProjectRole } = useAppState()

  const DashboardScreen = () => {
    switch (selectedProjectRole) {
      case SelectedProjectRole.Admin:
      case SelectedProjectRole.Lead:
        return <DashboardTemplate />
      case SelectedProjectRole.Editor:
        return (
          <DashboardTemplate
            hideHeaderDropDown
          />
        )
      case SelectedProjectRole.Collaborator:
        return (
          <DashboardTemplate
            hideHeaderDropDown
            readOnlyProjectStage
          />
        )
      default:
        return <DashboardTemplate />
    }
  }
  return <DashboardScreen />
}
