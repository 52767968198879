import { yupResolver } from '@hookform/resolvers/yup'
import React, { Fragment, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { formatDate } from 'theorem-lib/src/helpers/format'
import { ProjectMemberStatusEnum } from '../../../generated/api'
import { useActions, useAppState } from '../../../presenter'
import { ManageTasklistFormValidator } from '../../../validators/manageTasklistFormValidator'
import { SlideOut } from '../../atoms/SlideOut/SlideOut'
import { SlideOutFooter } from '../../atoms/SlideOutFooter/SlideoutFooter'
import TextInput from '../../atoms/TextInput/TextInput'
import { Toggle } from '../../atoms/Toggle/Toggle'
import { ListFilter } from '../../molecules/ListFilter/ListFilter'

export type AssignedIdType = { id: string; imageUrl?: string; isSelected?: boolean; value: string }
export type ChecklistFormType = {
  assignedUserIds: AssignedIdType[]
  name: string
  description?: string
  dueDate?: string
}

export function ManageTasklistSlideOut() {
  const {
    archiveTasklistAction,
    displayModalAction,
    displaySlideOutAction,
    submitUpdateTasklistAction,
  } = useActions()
  const { selectedProject, selectedTasklist } = useAppState()
  const [isAssignedUserIdsFilterOpen, setIsAssignedUserIdsFilterOpen] = useState(false)

  const initialValues: ChecklistFormType = {
    assignedUserIds: selectedTasklist?.tasklistUsers?.map(user => ({
      id: user.id,
      imageUrl: '',
      isSelected: false,
      value: '',
    })) || [],
    description: selectedTasklist?.description || '',
    dueDate: formatDate(selectedTasklist?.dueDate || '', 'YYYY-MM-DD'),
    name: selectedTasklist?.name || '',
  }

  const { control, formState: { errors, isDirty }, getValues, handleSubmit, register } = useForm(
    {
      defaultValues: initialValues,
      resolver: yupResolver(ManageTasklistFormValidator),
    },
  )

  const onSubmit = (values: ChecklistFormType) => {
    if (!selectedTasklist) return
    submitUpdateTasklistAction({
      tasklist: {
        ...selectedTasklist,
        assignedUserIds: values.assignedUserIds?.map((obj) => obj.id) || [],
        description: values.description,
        dueDate: values.dueDate,
        name: values.name,
      },
    })
  }

  const userSelectData = () => {
    if (selectedProject && selectedProject.members) {
      return selectedProject.members.filter(m => m.status === ProjectMemberStatusEnum.Accepted).map(member => {
        return {
          id: member.id,
          imageUrl: member.avatarUrl || '',
          isSelected: !!getValues('assignedUserIds').find((obj) => obj.id === member.id),
          value: `${member.firstName} ${member.lastName}`,
        }
      })
    }
    return []
  }

  const updateIsArchived = () => {
    archiveTasklistAction(selectedTasklist!.id)
  }

  if (!selectedTasklist) {
    return <></>
  }

  return (
    <SlideOut
      title={selectedTasklist?.name || ''}
      onClose={() =>
        isDirty || !!selectedTasklist?.id
          ? displayModalAction('CancelTasklistEditModal')
          : displaySlideOutAction('None')}
    >
      <div className='text-xs font-light text-primary-200 -mt-4'>
        <span>
          Added by {selectedTasklist.createUser?.firstName} {selectedTasklist.createUser?.lastName} on{' '}
          {formatDate(selectedTasklist.createdOn, 'MM.DD.YYYY')}
        </span>
      </div>
      <div className='mt-10'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='mb-4'>
            <TextInput label='Task Name' hasAutoFocus {...register('name')} maxLength={52} />
            <span className='flex flex-grow justify-end text-error font-medium text-xs'>{errors.name?.message}</span>
          </div>
          <div className='mb-4'>
            <TextInput label='Description' hasAutoFocus {...register('description')} />
            <span className='flex flex-grow justify-end text-error font-medium text-xs'>
              {errors.description?.message}
            </span>
          </div>
          <div className='flex flex-row'>
            <div className='flex flex-col mr-2 flex-grow'>
              <div className='mb-6 mt-2'>
                <Controller
                  name='assignedUserIds'
                  control={control}
                  render={({ field, fieldState }) => (
                    <Fragment>
                      <div className='border-primary-400 border-b'>
                        <ListFilter
                          label='Assign to'
                          items={userSelectData()}
                          onChange={(items) => field.onChange(items)}
                          isOpen={isAssignedUserIdsFilterOpen}
                          onClose={() => setIsAssignedUserIdsFilterOpen(false)}
                          onClick={() => {
                            setIsAssignedUserIdsFilterOpen(true)
                          }}
                          hasBorder={false}
                          className='!rounded-none'
                        />
                      </div>
                      <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                        {fieldState.error?.message}
                      </span>
                    </Fragment>
                  )}
                />
              </div>
            </div>
            <div className='flex flex-col flex-grow'>
              <div className='mb-6 mt-2'>
                <div className='flex flex-row min-w-[150px] grow h-14 px-4 py-2 border-primary-400 border-b cursor-pointer justify-between'>
                  <div className='flex flex-col grow'>
                    <div className='text-primary-400 text-xs'>Due Date</div>
                    <div className='text-primary-100 text-base'>
                      <input
                        type='date'
                        className='p-0 border-none focus:ring-0 w-full'
                        {...register('dueDate')}
                      />
                    </div>
                  </div>
                </div>
                <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                  {errors.dueDate?.message}
                </span>
              </div>
            </div>
          </div>
          <div className='flex flex-row justify-start'>
            <span className='mr-4'>Archive Tasklist:</span>
            <Toggle
              enabled={selectedTasklist.isArchived}
              setEnabled={updateIsArchived}
            />
          </div>
          <SlideOutFooter>
            <button className='bg-cta-200 text-white p-3 rounded-lg font-semibold mr-2' type='submit'>
              Save
            </button>
          </SlideOutFooter>
        </form>
      </div>
    </SlideOut>
  )
}
