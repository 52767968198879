import React from 'react'
import { useAppState } from '../../../presenter'
import { ManageTaskSlideOutTemplates } from './ManageTaskSlideOutTemplate'

export const ManageTaskSlideOut = () => {
  const { selectedProjectRole } = useAppState()

  return (
    <>
      {selectedProjectRole === 'Admin'
        ? <ManageTaskSlideOutTemplates />
        : selectedProjectRole === 'Lead'
        ? <ManageTaskSlideOutTemplates />
        : selectedProjectRole === 'Editor'
        ? <ManageTaskSlideOutTemplates />
        : selectedProjectRole === 'Collaborator'
        ? (
          <ManageTaskSlideOutTemplates
            hideDeleteTaskOption
          />
        )
        : <>Invalid Role</>}
    </>
  )
}
