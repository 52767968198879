import React from 'react'
import { useAppState } from '../../../presenter'
import { CollaboratorInvitationSlideOutTemplate } from './CollaboratorFolioInvitationSlideOutTemplate'

export function CollaboratorInvitationToFolioSlideOut() {
  const { isAdmin, isMember, isParticipant } = useAppState()

  return (
    <>
      {isAdmin
        ? <CollaboratorInvitationSlideOutTemplate subtitle='Easily invite your clients to create a project in FOLIO.' />
        : isMember
        ? <CollaboratorInvitationSlideOutTemplate subtitle='Easily invite your clients to create a project in FOLIO.' />
        : isParticipant
        ? (
          <CollaboratorInvitationSlideOutTemplate subtitle='Easily invite collaborators to create their own projects in FOLIO.' />
        )
        : <>Invalid Role</>}
    </>
  )
}
