import { yupResolver } from '@hookform/resolvers/yup'
import React, { useState } from 'react'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import ReactSelect from 'react-select'
import PlusIcon from 'theorem-lib/src/assets/icons/plus.svg'
import TrashIcon from 'theorem-lib/src/assets/icons/trash.svg'
import { NEWLY_ADDED_CLIENT_ID } from 'theorem-lib/src/common/constants'
import { formatDate } from 'theorem-lib/src/helpers/format'
import {
  ExperienceLevelEnum,
  Industry,
  IndustryCover,
  OtherAdministrativeGoalsEnum,
  OtherBusinessGoalsEnum,
  OtherClinicalGoalsEnum,
  OtherElementsEnum,
  OtherFacilityTypesEnum,
  OtherHomeTypeEnum,
  OtherSpacesEnum,
  OtherWorkspaceTypeEnum,
  ProjectInput,
  ProjectSizeEnum,
  QuantValueSchemaInput,
} from '../../../generated/api'
import { getSpacesEnumByIndustry } from '../../../helpers/industryHelper'
import { getNameWithoutUnderScores } from '../../../helpers/projectHelper'
import { useActions, useAppState } from '../../../presenter'
import { newProjectStateEnum } from '../../../types/project'
import {
  CollabCorporateFormValidator,
  CollabHealthcareFormValidator,
  CollabWorkFromHomeFormValidator,
  CorporateFormValidator,
  HealthcareFormValidator,
  WorkFromHomeFormValidator,
} from '../../../validators/manageProjectFormValidator'
import { customStyles } from '../../atoms/ReactSelectStyling/selectStyle'
import Select from '../../atoms/Select/Select'
import { SlideOut } from '../../atoms/SlideOut/SlideOut'
import { SlideOutFooter } from '../../atoms/SlideOutFooter/SlideoutFooter'
import TextInput from '../../atoms/TextInput/TextInput'
import { Tip } from '../../atoms/Tip/Tip'
import {
  IndustriesEnum,
  IndustriesSelect,
  InvalidIndustriesEnum,
} from '../../molecules/NewProjectCreation/IndustriesSelect'

export type ProjectFormType = {
  budget: number
  coverImageUrl?: string
  date?: string
  name: string
  client: { id: string; name: string }
  headCount: number
  industry: Industry
  industryAdministrativeGoals: OtherAdministrativeGoalsEnum[] | []
  industryAdministrativeGoalsOther: string
  industryBusinessGoals: OtherBusinessGoalsEnum[] | []
  industryBusinessGoalsOther: string
  industryClinicalGoals: OtherClinicalGoalsEnum[] | []
  industryClinicalGoalsOther: string
  industryExperienceLevel: ExperienceLevelEnum[] | []
  industryExperienceOther: string
  industryFacilityTypes: OtherFacilityTypesEnum[] | []
  industryFacilityTypesOther: string
  industryHomeType: OtherHomeTypeEnum[] | []
  industryHomeTypeOther: string
  industryOfficeElements: OtherElementsEnum[] | []
  industryOfficeElementsOther: string
  industryProjectScale: ProjectSizeEnum[] | []
  industryProjectScaleOther: string
  industryTypesOfSpaces: OtherSpacesEnum[] | []
  industryTypesOfSpacesQuantities: number[]
  industryTypesOfSpacesQuantValues: QuantValueSchemaInput[] | []
  industryTypesOfSpacesOther: string
  industryWorkSpace: OtherWorkspaceTypeEnum[] | []
  industryWorkSpaceOther: string
  members: { id: string; imageUrl?: string; isSelected?: boolean; value: string }[]
  squareFootage: number
  quantity: number
  companyName?: string
}

export enum OtherOptions {
  otherAdministrative = 'Other Administrative Goals',
  otherBusiness = 'Other Business Type',
  otherClinical = 'Other Clinical Type',
  otherElements = 'Other Elements',
  otherHomeType = 'Other Home Type',
  otherSpace = 'Other Spaces Type',
  otherWorkSpace = 'Other Workspace Type',
  otherDefault = 'Other',
  otherEmpty = '',
}
interface ProjectSlideOutProps {
  readonly?: boolean
  hideDeleteButtonOption?: boolean
  hideClientInvite?: boolean
  participantExpLevel?: boolean
}

export const ProjectSlideOutTemplate = (props: ProjectSlideOutProps) => {
  const {
    displayModalAction,
    displaySlideOutAction,
    projectCreationStateMachine,
    setDrawerDataToWizard,
  } = useActions()
  const { allClients, clients, industries, isMember, isParticipant, newProjectCreation, selectedProject } =
    useAppState()
  const clientsList = isMember ? allClients : clients
  const [uploadedFile, setUploadedFile] = useState<File>()
  const [uploadedImage, setUploadedImage] = useState<string | ArrayBuffer>('')
  const [selectedCover, setSelectedCover] = useState<IndustryCover>(
    !industries[1]?.covers || industries[1]?.covers?.length === 0
      ? { id: '', url: '' }
      : industries[1].covers[0],
  )
  const [industryCovers, setIndustryCovers] = useState<IndustryCover[]>(
    selectedProject.industry.covers || industries[1].covers || [],
  )

  const [industryName, setIndustryName] = useState<string>()
  const UPLOADED_IMAGE_ID = 'uploaded'

  // Temporary until IndustriesSelect component is updated
  const filteredIndustries = industries.filter((industry) =>
    industry.name != InvalidIndustriesEnum.Amenity
    && industry.name != InvalidIndustriesEnum.Hospitality
    && industry.name != InvalidIndustriesEnum.MultifamilyHousing
    && industry.name != InvalidIndustriesEnum.WallsShadesFlooring
  )

  const initialValues: ProjectFormType = {
    budget: newProjectCreation?.project?.budget ? Number(newProjectCreation.project?.budget) : 0,
    client: {
      id: newProjectCreation?.project?.clientId ?? '',
      name: clientsList.find(f => f.id === newProjectCreation?.project?.clientId)?.name ?? '',
    },
    companyName: newProjectCreation?.project?.companyName ?? '',
    coverImageUrl: selectedCover.url,
    date: newProjectCreation?.project?.moveInDate !== undefined
      ? formatDate(newProjectCreation?.project?.moveInDate, 'YYYY-MM-DD')
      : undefined,
    headCount: newProjectCreation?.project?.headCount ? Number(newProjectCreation?.project?.headCount) : 0,
    industry: {
      covers: industryCovers,
      id: newProjectCreation?.project?.industryId ?? '',
      name: industries.find(f => newProjectCreation?.project?.industryId === f.id)?.name ?? '',
    },
    industryAdministrativeGoals: newProjectCreation?.project?.industryDetails?.otherAdministrativeGoals?.values ?? [],
    industryAdministrativeGoalsOther: newProjectCreation?.project?.industryDetails?.otherAdministrativeGoals?.other
      ? newProjectCreation?.project?.industryDetails?.otherAdministrativeGoals?.other
      : OtherOptions.otherEmpty,
    industryBusinessGoals: newProjectCreation?.project?.industryDetails?.otherBusinessGoals?.values ?? [],
    industryBusinessGoalsOther: newProjectCreation?.project?.industryDetails?.otherBusinessGoals?.other
      ? newProjectCreation?.project?.industryDetails?.otherBusinessGoals?.other
      : OtherOptions.otherEmpty,
    industryClinicalGoals: newProjectCreation?.project?.industryDetails?.otherClinicalGoals?.values ?? [],
    industryClinicalGoalsOther: newProjectCreation?.project?.industryDetails?.otherClinicalGoals?.other
      ? newProjectCreation?.project?.industryDetails?.otherClinicalGoals?.other
      : OtherOptions.otherEmpty,
    industryExperienceLevel: newProjectCreation?.project?.industryDetails?.experienceLevel?.values ?? [],
    industryExperienceOther: '',
    industryFacilityTypes: newProjectCreation?.project?.industryDetails?.otherFacilityTypes?.values ?? [],
    industryFacilityTypesOther: '',
    industryHomeType: newProjectCreation?.project?.industryDetails?.otherHomeType?.values ?? [],
    industryHomeTypeOther: newProjectCreation?.project?.industryDetails?.otherHomeType?.other
      ? newProjectCreation?.project?.industryDetails?.otherHomeType?.other
      : OtherOptions.otherEmpty,
    industryOfficeElements: newProjectCreation?.project?.industryDetails?.otherElements?.values ?? [],
    industryOfficeElementsOther: newProjectCreation?.project?.industryDetails?.otherElements?.other
      ? newProjectCreation?.project?.industryDetails?.otherElements?.other
      : OtherOptions.otherEmpty,
    industryProjectScale: newProjectCreation?.project?.industryDetails?.projectSize?.values ?? [],
    industryProjectScaleOther: '',
    industryTypesOfSpaces: newProjectCreation?.project?.industryDetails?.otherSpaces?.values
      ?? [OtherSpacesEnum.Athletic], // OtherSpacesEnum value included to populate initial useFieldArray
    industryTypesOfSpacesOther: '',
    industryTypesOfSpacesQuantValues: newProjectCreation?.project?.industryDetails?.otherSpaces?.quantValues ?? [],
    industryTypesOfSpacesQuantities: newProjectCreation?.project?.industryDetails?.otherSpaces?.quantities ?? [1],
    industryWorkSpace: newProjectCreation?.project?.industryDetails?.otherWorkspaceType?.values ?? [],
    industryWorkSpaceOther: newProjectCreation?.project?.industryDetails?.otherWorkspaceType?.other
      ? newProjectCreation?.project?.industryDetails?.otherWorkspaceType?.other
      : OtherOptions.otherEmpty,
    members: [],
    name: newProjectCreation?.project?.name ?? '',
    quantity: newProjectCreation?.project?.buildingsQty ? Number(newProjectCreation.project?.buildingsQty) : 0,
    squareFootage: newProjectCreation?.project?.squareFootage ? Number(newProjectCreation.project?.squareFootage) : 0,
  }
  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(
      isParticipant
        ? initialValues.industry.name === IndustriesEnum.Healthcare
          ? CollabHealthcareFormValidator
          : initialValues.industry.name === IndustriesEnum.WorkFromHome
          ? CollabWorkFromHomeFormValidator
          : CollabCorporateFormValidator
        : initialValues.industry.name === IndustriesEnum.Healthcare
        ? HealthcareFormValidator
        : initialValues.industry.name === IndustriesEnum.WorkFromHome
        ? WorkFromHomeFormValidator
        : CorporateFormValidator,
    ),
  })
  const { control, formState: { dirtyFields, isDirty } } = methods

  const { append, fields, remove } = useFieldArray(
    {
      control,
      name: 'industryTypesOfSpacesQuantValues',
    },
  )

  const selectOptions = getSpacesEnumByIndustry(methods.getValues('industry.name')).map(space => ({
    label: getNameWithoutUnderScores(space),
    value: space as OtherSpacesEnum,
  }))

  const selectedSpaces = methods.getValues().industryTypesOfSpacesQuantValues.map((space) => {
    return space.values
  })

  const filteredSelectOptions = selectOptions.filter((element) => (!selectedSpaces?.includes(element.value)))

  const onUploadFile = (file: File): void => {
    setUploadedFile(file)
    const reader = new FileReader()
    reader.onload = function() {
      setUploadedImage(reader.result || '')
      setSelectedCover({ id: UPLOADED_IMAGE_ID, url: reader.result as string })
    }
    reader.readAsDataURL(file)
  }

  const clearSpaceData = () => {
    methods.resetField('industryTypesOfSpacesQuantValues')
  }

  const onNewClientClick = () => {
    const newProject = setProject()
    projectCreationStateMachine({ currentState: newProjectStateEnum.ClientCreation, project: newProject })
  }

  const onInviteClientClick = () => {
    displaySlideOutAction('CollaboratorInvitationToFolioSlideOut')
  }

  const onLaunchProjectWizardClick = () => {
    saveProject()
    setDrawerDataToWizard()
    displaySlideOutAction('None')
    displayModalAction('IndustrySelectorWizard')
  }

  const onSubmit = () => {
    const newProject = setProject()
    saveProject()
    projectCreationStateMachine({ currentState: newProjectStateEnum.VisualPreferences, project: newProject })
  }

  const saveProject = () => {
    const savedProject = setProject()
    projectCreationStateMachine({ currentState: newProjectStateEnum.ManageProjectSlideOut, project: savedProject })
  }

  const setProject = (): ProjectInput => {
    let newCoverImageUrl = ''
    if (selectedCover.id !== UPLOADED_IMAGE_ID && selectedCover && selectedCover.url) {
      newCoverImageUrl = selectedCover.url
    } else if (selectedCover.id !== UPLOADED_IMAGE_ID && methods.getValues('coverImageUrl')) {
      newCoverImageUrl = methods.getValues('coverImageUrl') ?? ''
    }

    const sindustry = {
      experienceLevel: {
        other: methods.getValues('industryExperienceOther') ? methods.getValues('industryExperienceOther') : 'None',
        values: methods.getValues('industryExperienceLevel')
          ? methods.getValues('industryExperienceLevel')
          : [],
      },
      otherAdministrativeGoals: {
        other: methods.getValues('industryAdministrativeGoalsOther')
          ? methods.getValues('industryAdministrativeGoalsOther')
          : '',
        values: methods.getValues('industryAdministrativeGoals')
          ? methods.getValues('industryAdministrativeGoals')
          : [],
      },
      otherBusinessGoals: {
        other: methods.getValues('industryBusinessGoalsOther')
          ? methods.getValues('industryBusinessGoalsOther')
          : '',
        values: methods.getValues('industryBusinessGoals')
          ? methods.getValues('industryBusinessGoals')
          : [],
      },
      otherClinicalGoals: {
        other: methods.getValues('industryClinicalGoalsOther')
          ? methods.getValues('industryClinicalGoalsOther')
          : '',
        values: methods.getValues('industryClinicalGoals') ? methods.getValues('industryClinicalGoals') : [],
      },
      otherElements: (!methods.getValues('industryOfficeElements') && !methods.getValues('industryOfficeElementsOther'))
        ? null
        : {
          other: methods.getValues('industryOfficeElementsOther')
            ? methods.getValues('industryOfficeElementsOther')
            : '',
          values: methods.getValues('industryOfficeElements'),
        },
      otherFacilityTypes: {
        other: methods.getValues('industryFacilityTypesOther')
          ? methods.getValues('industryFacilityTypesOther')
          : '',
        values: methods.getValues('industryFacilityTypes')
          ? methods.getValues('industryFacilityTypes')
          : [],
      },
      otherHomeType: (!methods.getValues('industryHomeType') && !methods.getValues('industryHomeTypeOther'))
        ? undefined
        : {
          other: methods.getValues('industryHomeTypeOther')
            ? methods.getValues('industryHomeTypeOther')
            : '',
          values: methods.getValues('industryHomeType'),
        },
      otherSpaces: (!methods.getValues('industryTypesOfSpaces') && !methods.getValues('industryTypesOfSpacesQuantities')
          && !methods.getValues('industryTypesOfSpacesQuantValues'))
        ? undefined
        : {
          other: methods.getValues('industryTypesOfSpacesOther')
            ? methods.getValues('industryTypesOfSpacesOther')
            : '',
          quantValues: methods.getValues('industryTypesOfSpacesQuantValues'),
          quantities: methods.getValues('industryTypesOfSpacesQuantities'),
          values: methods.getValues('industryTypesOfSpaces'),
        },
      otherWorkspaceType: (!methods.getValues('industryWorkSpace') && !methods.getValues('industryWorkSpaceOther'))
        ? undefined
        : {
          other: methods.getValues('industryWorkSpaceOther')
            ? methods.getValues('industryWorkSpaceOther')
            : '',
          values: methods.getValues('industryWorkSpace'),
        },
      projectSize: {
        other: '',
        values: methods.getValues('industryProjectScale'),
      },
      visualPreferences: newProjectCreation?.project?.industryId !== methods.getValues('industry').id
        ? undefined
        : newProjectCreation?.project?.industryDetails?.visualPreferences,
    }

    const newProject: ProjectInput = {
      budget: parseFloat(methods.getValues('budget').toString()),
      buildingsQty: methods.getValues('quantity'),
      clientId: isParticipant ? NEWLY_ADDED_CLIENT_ID : methods.getValues('client.id'),
      companyName: methods.getValues('companyName'),
      coverImageUrl: industryCovers[0].url,
      headCount: parseFloat(methods.getValues('headCount').toString()),
      industryDetails: sindustry,
      industryId: methods.getValues('industry.id'),
      members: methods.getValues('members').map(member => member.id),
      moveInDate: (methods.getValues('date') !== undefined || methods.getValues('date') !== '')
        ? methods.getValues('date')
        : undefined,
      name: methods.getValues('name'),
      squareFootage: parseFloat(methods.getValues('squareFootage').toString()),
    }
    return newProject
  }

  const getFolioAdminForm = () => {
    return (
      <div>
        <h2 className='mb-6 text-primary-200 font-normal'>
          Prefer a guided visual process?
          <a
            className='ml-1 text-[#c04184] font-semibold hover:text-pink-500 '
            aria-label='Close'
            onClick={() => onLaunchProjectWizardClick()}
          >
            Launch Project Wizard
          </a>
        </h2>
        {!props.hideClientInvite
          && (
            <Tip
              text='Invite your client to complete this info with Project Wizard'
              link
              linkText='Invite Client'
              onLinkClick={() => onInviteClientClick()}
            />
          )}
        <p className='mb-5 text-base font-bold text-gray-900'>
          Basic Project Details
        </p>
        <div className='mb-6'>
          <TextInput
            readOnly={props.readonly}
            label='Project Name*'
            hasAutoFocus
            {...methods.register('name')}
            maxLength={52}
          />
          <span className='flex flex-grow justify-end text-error font-medium text-xs'>
            {methods.formState.errors.name?.message}
          </span>
        </div>
        <div className='flex -mx-2'>
          <div className='w-1/2 px-2'>
            <div className='mb-4'>
              <Select
                label='Client Name*'
                default={{ key: initialValues.client.id, value: initialValues.client.name }}
                data={clientsList.map(client => ({ key: client.id, value: client.name }))}
                onNewClient={() => onNewClientClick()}
                onChange={client => {
                  methods.clearErrors('client.id')
                  methods.setValue('client', { id: client.key, name: client.value }, { shouldDirty: true })
                }}
              />
              <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                {methods.formState.errors.client?.id?.message}
              </span>
            </div>
          </div>
          <div className='w-1/2 px-2'>
            <div className='mb-6'>
              <div className='flex flex-row min-w-[150px] grow h-14 px-4 py-2 border-primary-400 border-b cursor-pointer justify-between'>
                <div className='flex flex-col grow'>
                  <div className='text-primary-400 text-xs'>Move In Date*</div>
                  <div className='text-primary-100 text-base'>
                    <input
                      type='date'
                      onKeyDown={event => event.preventDefault()}
                      className='p-0 border-none focus:ring-0 w-full'
                      {...methods.register('date')}
                    />
                  </div>
                </div>
              </div>
              <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                {methods.formState.errors.date?.message}
              </span>
            </div>
          </div>
        </div>
        <div className='flex -mx-2'>
          <div className='w-1/2 px-2'>
            <div className='mb-6 mt-6'>
              <TextInput type='number' label='Furniture Budget' {...methods.register('budget')} />
            </div>
          </div>
          <div className='w-1/2 px-2 mb-4'>
            <div className='mb-6 mt-6'>
              <TextInput type='number' label='Square Footage' {...methods.register('squareFootage')} />
              <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                {methods.formState.errors.squareFootage?.message}
              </span>
            </div>
          </div>
        </div>
        {methods.getValues('industry.name') !== 'Work From Home'
          && (
            <div className='flex -mx-2'>
              <div className='w-1/2 px-2'>
                <div className='mb-6'>
                  <Select
                    label='Qty of Buildings'
                    data={[...Array(10).keys()].map(qty => ({
                      key: (qty + 1).toString(),
                      value: (qty + 1).toString(),
                    }))}
                    onChange={(value) => methods.setValue('quantity', parseInt(value.key), { shouldDirty: true })}
                    default={{ key: initialValues.quantity.toString(), value: initialValues.quantity.toString() }}
                  />
                </div>
              </div>
            </div>
          )}

        <p className='mb-5 mt-5 text-base font-bold text-gray-900'>
          Industry Details
        </p>

        <IndustriesSelect
          {...methods}
          label='Industry*'
          projectCreation={true}
          expLevelFilter={props.participantExpLevel}
          default={methods.getValues('industry')
            ? { key: methods.getValues('industry').id, value: methods.getValues('industry').name }
            : { key: initialValues.industry.id, value: initialValues.industry.name }}
          data={filteredIndustries.map(industry => ({ key: industry.id, value: industry.name }))}
          selectedValue={methods.getValues('industry').name}
          onChange={industry => {
            methods.setValue('industry', { id: industry.key, name: industry.value }, { shouldDirty: true })
            setIndustryCovers(industries.find(storeIndustry => industry.key === storeIndustry.id)?.covers || [])
            clearSpaceData()
            setIndustryName(industry.value)
          }}
        />

        <span className='flex flex-grow justify-end text-error font-medium text-xs'>
          {methods.formState.errors.industry?.id?.message}
        </span>
        {methods.getValues('industry').name !== 'Work From Home' && (
          <>
            <p className='mb-2 mt-5 text-base font-bold text-gray-900'>
              Spaces Select
            </p>
            <span className='text-xs font-small'>
              You must select an industry before selecting spaces.
            </span>
            <ul>
              {fields.map((field, index) => {
                return (
                  <li key={index} className='flex flex-row mb-3'>
                    <div className='w-3/5 border-b border-primary-400'>
                      <ReactSelect
                        {...methods.register(`industryTypesOfSpacesQuantValues.${index}.values`)}
                        placeholder='Types of Spaces*'
                        options={filteredSelectOptions}
                        styles={customStyles}
                        menuPlacement='top'
                        onChange={(value: any) => {
                          methods.setValue(
                            `industryTypesOfSpacesQuantValues.${index}.values`,
                            value.value as OtherSpacesEnum,
                          )
                        }}
                      />
                    </div>
                    <div className='mt-6 ml-2 w-1/5 block'>
                      <TextInput
                        label='Qty'
                        type='number'
                        min='0'
                        {...methods.register(`industryTypesOfSpacesQuantValues.${index}.amount`, {
                          valueAsNumber: true,
                        })}
                      />
                    </div>
                    <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                    </span>
                    {index !== 0
                      && (
                        <div className='mt-6 inline-block align-text-baseline'>
                          <button
                            className=' ml-8 cursor-pointer fill-[#2b5b74] button rounded-lg px-2 py-2 bg-blue-100 '
                            type='button'
                            onClick={() => remove(index)}
                          >
                            <TrashIcon />
                          </button>
                        </div>
                      )}

                    {methods.getValues(`industryTypesOfSpacesQuantValues.${index}.values`) === 'Other'
                      && (
                        <div className='w-100 pt-4 px-2'>
                          <TextInput
                            readOnly={false}
                            label='What other types of spaces?'
                            placeholder='Other spaces'
                            hasAutoFocus
                            maxLength={52}
                            {...methods.register(`industryTypesOfSpacesQuantValues.${index}.other`)}
                          />
                          <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                          </span>
                        </div>
                      )}
                  </li>
                )
              })}
            </ul>

            <div>
              <button
                onClick={() => {
                  append({
                    amount: 0,
                    other: '',
                    values: OtherOptions.otherEmpty as unknown as OtherSpacesEnum,
                  })
                }}
                type='button'
                className='mt-3 inline-flex items-center font-bold text-pink-700'
              >
                <PlusIcon />
                Add another space
              </button>
            </div>
          </>
        )}
      </div>
    )
  }

  const getParticipantForm = () => {
    return (
      <div>
        <h2 className='mb-6 text-primary-200 font-normal'>
          Fill in the project details and let the magic begin.
        </h2>
        <Tip
          text='Tip: Prefer a guided visual process?'
          link
          linkText='Launch Project Wizard'
          onLinkClick={() => onLaunchProjectWizardClick()}
        />
        <p className='mb-5 text-base font-bold text-gray-900'>
          Basic Project Details
        </p>
        <div className='mb-6'>
          <TextInput
            readOnly={props.readonly}
            label='Project Name'
            hasAutoFocus
            {...methods.register('name')}
            maxLength={52}
          />
          <span className='flex flex-grow justify-end text-error font-medium text-xs'>
            {methods.formState.errors.name?.message}
          </span>
        </div>

        <div className='flex -mx-2'>
          <div className='w-1/2 px-2'>
            <div className='mb-6 mt-5'>
              <TextInput
                readOnly={props.readonly}
                label='Company Name*'
                {...methods.register('companyName')}
                maxLength={52}
              />
              <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                {methods.formState.errors.companyName?.message}
              </span>
            </div>
          </div>
          <div className='w-1/2 px-2'>
            <div className='mb-6'>
              <div className='flex flex-row min-w-[150px] grow h-14 px-4 py-2 border-primary-400 border-b cursor-pointer justify-between'>
                <div className='flex flex-col grow'>
                  <div className='text-primary-400 text-xs'>Move In Date*</div>
                  <div className='text-primary-100 text-base'>
                    <input
                      readOnly={props.readonly}
                      type='date'
                      className='p-0 border-none focus:ring-0 w-full'
                      {...methods.register('date')}
                    />
                  </div>
                </div>
              </div>
              <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                {methods.formState.errors.date?.message}
              </span>
            </div>
          </div>
        </div>
        <div className='flex -mx-2'>
          <div className='w-1/2 px-2'>
            <div className='mb-6 mt-6'>
              <TextInput
                readOnly={props.readonly}
                type='number'
                label='Furniture Budget'
                {...methods.register('budget')}
              />
            </div>
          </div>
          <div className='w-1/2 px-2 mb-4'>
            <div className='mb-6 mt-6'>
              <TextInput
                type='number'
                label='Square Footage'
                {...methods.register('squareFootage')}
                readOnly={props.readonly}
              />
              <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                {methods.formState.errors.squareFootage?.message}
              </span>
            </div>
          </div>
        </div>
        {methods.getValues('industry.name') !== 'Work From Home' && (
          <div className='mb-6 w-1/2'>
            <Select
              readonly={props.readonly}
              label='Qty of Buildings'
              data={[...Array(10).keys()].map(qty => ({ key: (qty + 1).toString(), value: (qty + 1).toString() }))}
              onChange={(value) => methods.setValue('quantity', parseInt(value.key), { shouldDirty: true })}
              default={{ key: initialValues.quantity.toString(), value: initialValues.quantity.toString() }}
            />
          </div>
        )}

        <p className='mb-5 mt-5 text-base font-bold text-gray-900'>
          Industry Details
        </p>
        <IndustriesSelect
          {...methods}
          projectCreation={true}
          label='Industry*'
          expLevelFilter={props.participantExpLevel}
          default={methods.getValues('industry')
            ? { key: methods.getValues('industry').id, value: methods.getValues('industry').name }
            : { key: initialValues.industry.id, value: initialValues.industry.name }}
          data={filteredIndustries.map(industry => ({ key: industry.id, value: industry.name }))}
          selectedValue={methods.getValues('industry').name}
          onChange={industry => {
            methods.setValue('industry', { id: industry.key, name: industry.value }, { shouldDirty: true })
            setIndustryCovers(industries.find(storeIndustry => industry.key === storeIndustry.id)?.covers || [])
            setIndustryName(industry.value)
          }}
        />
        <span className='flex flex-grow justify-end text-error font-medium text-xs'>
          {methods.formState.errors.industry?.id?.message}
        </span>
        {methods.getValues('industry').name !== 'Work From Home' && (
          <>
            <p className='mb-2 mt-5 text-base font-bold text-gray-900'>
              Spaces Select
            </p>
            <span className='text-xs font-small'>
              You must select an industry before selecting spaces.
            </span>
            <ul>
              {fields.map((field, index) => {
                return (
                  <li key={index} className='flex flex-row mb-3'>
                    <div className='w-3/5 border-b border-primary-400'>
                      <ReactSelect
                        {...methods.register(`industryTypesOfSpacesQuantValues.${index}.values`)}
                        placeholder='Types of Spaces*'
                        options={filteredSelectOptions}
                        styles={customStyles}
                        menuPlacement='top'
                        onChange={(value: any) => {
                          methods.setValue(
                            `industryTypesOfSpacesQuantValues.${index}.values`,
                            value.value as OtherSpacesEnum,
                          )
                        }}
                      />
                    </div>
                    <div className='mt-6 ml-2 w-1/5 block'>
                      <TextInput
                        label='Qty'
                        type='number'
                        min='0'
                        {...methods.register(`industryTypesOfSpacesQuantValues.${index}.amount`, {
                          valueAsNumber: true,
                        })}
                      />
                    </div>
                    <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                    </span>
                    {index !== 0
                      && (
                        <div className='mt-6 inline-block align-text-baseline'>
                          <button
                            className=' ml-8 cursor-pointer fill-[#2b5b74] button rounded-lg px-2 py-2 bg-blue-100 '
                            type='button'
                            onClick={() => remove(index)}
                          >
                            <TrashIcon />
                          </button>
                        </div>
                      )}

                    {methods.getValues(`industryTypesOfSpacesQuantValues.${index}.values`) === 'Other'
                      && (
                        <div className='w-100 pt-4 px-2'>
                          <TextInput
                            readOnly={false}
                            label='What other types of spaces?'
                            placeholder='Other spaces'
                            hasAutoFocus
                            maxLength={52}
                            {...methods.register(`industryTypesOfSpacesQuantValues.${index}.other`)}
                          />
                          <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                          </span>
                        </div>
                      )}
                  </li>
                )
              })}
            </ul>

            <div>
              <button
                onClick={() => {
                  append({
                    amount: 0,
                    other: '',
                    values: OtherOptions.otherEmpty as unknown as OtherSpacesEnum,
                  })
                }}
                type='button'
                className='mt-3 inline-flex items-center font-bold text-pink-700'
              >
                <PlusIcon />
                Add another space
              </button>
            </div>
          </>
        )}
      </div>
    )
  }

  const onCloseSlideOut = () => {
    if (props.readonly) return displaySlideOutAction('None')

    if (isDirty && Object.values(dirtyFields).length > 0) {
      return displayModalAction('CancelProjectEditModal')
    } else {
      projectCreationStateMachine({ currentState: newProjectStateEnum.CleanProject })
      return displaySlideOutAction('None')
    }
  }

  return (
    <SlideOut
      title={'New Project'}
      onClose={() => onCloseSlideOut()}
      maxWidth='max-w-2xl'
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className='mb-12'>
            {isParticipant ? getParticipantForm() : getFolioAdminForm()}
          </div>
          <SlideOutFooter>
            {props.readonly
              ? (
                <>
                  <button
                    onClick={() => displaySlideOutAction('None')}
                    className='bg-cta-200 text-white p-3 rounded-lg font-semibold mr-2'
                  >
                    Close
                  </button>
                </>
              )
              : (
                <>
                  <section className='flex flex-col mb-5'>
                    <div className='flex flex-row-reverse'>
                      <button
                        className='mt-8 bg-cta-200 text-white p-3 rounded-lg font-semibold mr-2'
                        type='submit'
                      >
                        Select Visual Preferences {'->'}
                      </button>
                    </div>
                  </section>
                </>
              )}
          </SlideOutFooter>
        </form>
      </FormProvider>
    </SlideOut>
  )
}
