import React, { useEffect, useState } from 'react'
import CalendarIcon from 'theorem-lib/src/assets/icons/calendar.svg'

import { formatDate } from 'theorem-lib/src/helpers/format'
import { listOverdue, taskOverdue } from 'theorem-lib/src/helpers/overdue'
import { Tasklist } from '../../../generated/api'

import Archive from 'theorem-lib/src/assets/icons/archive.svg'
import Envelope from 'theorem-lib/src/assets/icons/envelope.svg'

type WorkbookTaskListCardProps = {
  list: Tasklist
  currentIndex: number
  isActive?: boolean
  onClick: React.MouseEventHandler<HTMLDivElement>
}

export function WorkbookTaskListCard(props: WorkbookTaskListCardProps) {
  const [tasksOverdue, setTasksOverdue] = useState(false)
  useEffect(() => {
    let result = false
    if (props.list.sections) {
      for (const section of props.list.sections) {
        for (const item of section.tasks) {
          if (taskOverdue(item.dueDate, item.completedDate)) {
            result = true
          }
        }
      }
    }
    setTasksOverdue(result)
  }, [props])

  const nonActiveClass = `border border-solid ${
    tasksOverdue ? 'border-error' : 'border-gray-300'
  } rounded-lg h-28 mr-4 mb-4 cursor-pointer`
  const activeClass = `border-t border-l border-b border-solid  ${
    tasksOverdue ? 'border-error' : 'border-[#f5eeed]'
  } rounded-lg rounded-r-none h-28 mb-4 bg-[#f5eeed] sticky top-0 bottom-0`

  const renderTeamMembers = (): React.ReactElement => {
    const MEMBERS_IMAGE_AMOUNT = 3
    const members = props.list?.tasklistUsers?.slice(0, MEMBERS_IMAGE_AMOUNT) || []
    const extraAmount = props.list?.tasklistUsers
      ? props.list.tasklistUsers.length - MEMBERS_IMAGE_AMOUNT
      : 0
    const classes =
      'flex rounded-full bg-no-repeat bg-cover bg-center bg-primary-300 w-6 h-6 text-center text-xs leading-6 text-white mx-2 ml-[-14px]'
    const avatars = members.map((member) => {
      return (
        <div
          key={member.id}
          className={classes}
          style={{ backgroundImage: `url(${member.avatarUrl})` }}
        >
          {!member.avatarUrl && (
            <span className='w-full'>
              {member.firstName?.charAt(0)}
              {member.lastName?.charAt(0)}
            </span>
          )}
        </div>
      )
    })
    return (
      <div className='flex flex-row-reverse justify-start float-right mt-1'>
        {extraAmount > 0 && (
          <div className={classes}>
            {`+ ${extraAmount}`}
          </div>
        )}
        {avatars}
      </div>
    )
  }

  return (
    <div className={props.isActive ? activeClass : nonActiveClass} onClick={props.onClick}>
      <div className='mt-6 mx-8 text-primary-200 text-sm truncate'>{props.list.name}</div>

      <div className='py-2 ml-6 mt-2'>
        {props.list.isArchived
          && (
            <button className={`text-cta-100 ${props.list.dueDate ? `pl-2 pr-2` : `pl-2`}`}>
              <Archive className='inline' />
            </button>
          )}
        {props.list.dueDate && (
          <div
            className={`inline-block text-xs rounded py-1 px-2 mx-2 ${
              listOverdue(props.list)
                ? 'bg-error text-white'
                : 'bg-gray-200 text-black'
            }`}
          >
            <CalendarIcon className='inline mr-1' width='12' height='12' /> {formatDate(props.list.dueDate)}
          </div>
        )}

        {props.list.tasklistUsers && renderTeamMembers()}
      </div>
    </div>
  )
}
