import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'
import React, { useEffect, useRef, useState } from 'react'
import { useActions } from '../../../presenter'
import './style.css'

type CarouselProps = {
  title?: string | React.ReactNode
  clientId?: string
  children: React.ReactNode
  hasSeeAll?: boolean
  hasArrows: boolean
  itemSize?: number
  itemAmount: number
}

export const Carousel = (props: CarouselProps): React.ReactElement => {
  const scrollRef = useRef<HTMLDivElement>(null)
  const [shouldShowArrows, setShouldShowArrows] = useState(false)
  const { redirectAction } = useActions()

  const onLeftArrowClick = () => {
    if (scrollRef && scrollRef.current) {
      const offset = scrollRef.current.getBoundingClientRect().width
      scrollRef?.current?.scroll({ behavior: 'smooth', left: scrollRef.current.scrollLeft - offset })
    }
  }

  const onRightArrowClick = () => {
    if (scrollRef && scrollRef.current) {
      const offset = scrollRef.current.getBoundingClientRect().width
      scrollRef.current.scroll({ behavior: 'smooth', left: scrollRef.current.scrollLeft + offset })
    }
  }

  const handleResize = () => {
    const scrollWidth = scrollRef.current?.getBoundingClientRect().width || 0
    const amountOfCardsAllowed = Math.floor(scrollWidth / (props.itemSize || 312))
    setShouldShowArrows(amountOfCardsAllowed < props.itemAmount)
  }

  const seeAllClick = (clientId?: string) => {
    if (clientId) {
      redirectAction(`/clients/${props.clientId}`)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [props.children])

  return (
    <div className='flex flex-col mt-7'>
      <div className='flex flex-row justify-between'>
        <h3 className='text-lg font-medium mb-5 text-primary-100'>
          {props.title}
        </h3>
        <div className='flex flex-row text-primary-100'>
          {props.hasSeeAll && (
            <div
              className='text-cta-200 mr-2.5 cursor-pointer'
              onClick={() => seeAllClick(props.clientId)}
            >
              See all
            </div>
          )}
          {props.hasArrows && shouldShowArrows && (
            <>
              <div
                onClick={onLeftArrowClick}
                className='cursor-pointer rounded-full bg-grey-400 h-[24px] w-[24px] mr-2'
              >
                <ChevronLeftIcon />
              </div>
              <div onClick={onRightArrowClick} className='cursor-pointer rounded-full bg-grey-400 h-[24px] w-[24px]'>
                <ChevronRightIcon />
              </div>
            </>
          )}
        </div>
      </div>
      <div ref={scrollRef} className='flex flex-row overflow-x-scroll w-full py-1 no-scrollbar'>
        {props.children}
      </div>
    </div>
  )
}

export default Carousel
