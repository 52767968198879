import React, { useMemo, useState } from 'react'
import CloseIcon from 'theorem-lib/src/assets/icons/close.svg'
import FiltersIcon from 'theorem-lib/src/assets/icons/filters.svg'
import SearchIcon from 'theorem-lib/src/assets/icons/search.svg'
import { NEWLY_ADDED_CLIENT_ID } from 'theorem-lib/src/common/constants'
import { Client, Project as ProjectType, User } from '../../generated/api'
import { useActions, useAppState } from '../../presenter'
import { ProjectSecurityRoles } from '../../types/gql_enums'
import { ClientEmptyState } from '../atoms/EmptyStates/ClientEmptyState'
import { ParticipantProjectsEmptyState } from '../atoms/EmptyStates/ParticipantProjectsEmptyState'
import { AddMenuDropdown } from '../molecules/AddMenuDropdown/AddMenuDropdown'
import { CalendarFilter } from '../molecules/CalendarFilter/CalendarFilter'
import Carousel from '../molecules/Carousel/Carousel'
import { ClientDropdown } from '../molecules/ClientDropdown/ClientDropdown'
import { ListFilter } from '../molecules/ListFilter/ListFilter'
import Project from '../molecules/ProjectCard/ProjectCard'
import ProjectPinnedCard from '../molecules/ProjectPinnedCard/ProjectPinnedCard'
import ProjectSearch from '../molecules/ProjectSearch/ProjectSearch'

export interface ProjectsProps {
  hideClientSummaryDropdown?: boolean
  showParticipantProjectsEmptyState?: boolean
}

export const ProjectsTemplate = (props: ProjectsProps) => {
  const {
    authenticatedUser,
    clients,
    filters,
    industries,
    isAdmin,
    membersToFilter,
    pinnedProjects,
    selectedProject,
  } = useAppState()

  const {
    clearProjectsAndClientsFilterAction,
    displayNewProjectSlideOut,
    displaySlideOutAction,
    redirectAction,
    updateDateEndFilterAction,
    updateDateStartFilterAction,
    updateIndustryFilterAction,
    updateMembersFilterAction,
  } = useActions()
  const newlyAddedClient = clients.filter(client => NEWLY_ADDED_CLIENT_ID === client.id)
  const filteredClients = clients.filter(client => NEWLY_ADDED_CLIENT_ID != client.id)
  const isEmpty = !filteredClients || filteredClients.length === 0
  const isNewlyAddedClientEmpty = !newlyAddedClient || newlyAddedClient.length === 0

  const [isSearchOpen, setIsSearchOpen] = useState(false)
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [isIndustriesFilterOpen, setIsIndustriesFilterOpen] = useState(false)
  const [isMembersFilterOpen, setIsMembersFilterOpen] = useState(false)

  const checkIsProjectCollaborator = (selectedProject: ProjectType | null, authenticatedUser: User) => {
    return selectedProject?.members?.some((member) => {
      if (member.id === authenticatedUser.id) {
        return member.role.name === ProjectSecurityRoles.Collaborator
      }
    })
  }

  const isProjectCollaborator = useMemo(() => checkIsProjectCollaborator(selectedProject, authenticatedUser), [
    selectedProject,
    authenticatedUser,
  ])

  const isAuthorized = (!isProjectCollaborator)

  const getClientTitle = (client: Client): React.ReactNode => {
    const goToClientPage = () => {
      redirectAction(`/clients/${client.id}`)
    }

    return (
      <div className='flex flex-row'>
        {props.hideClientSummaryDropdown
          ? <h2 className='text-primary-200 font-semibold text-xl'>{client.name}</h2>
          : (
            <ClientDropdown
              client={client}
              title={<div className='text-cta-200 cursor-pointer' onClick={goToClientPage}>{client.name}</div>}
            />
          )}
        <span className='text-primary-100 ml-1'>Projects</span>
      </div>
    )
  }

  const getNewlyAddedTitle = (clientName: string) => {
    return (
      <div className='flex flex-row'>
        <h2 className='text-primary-200 font-semibold text-xl'>{clientName}</h2>
        <span className='text-primary-100 ml-1'>Projects</span>
      </div>
    )
  }

  const getPinnedCarouselTitle = (): React.ReactNode => (
    <div className='flex flex-col'>
      <h2 className='text-primary-200 font-semibold text-xl'>Pinned</h2>
      <h3 className='text-primary-200 font-normal text-base'>
        Pin your active projects here for quick access. Easily unpin to remove as needed.
      </h3>
    </div>
  )

  return (
    <div className='flex flex-col'>
      {/* Header Desktop */}
      <div className='hidden sm:flex flex-row justify-between items-center'>
        <h2 className='text-xl font-medium text-primary-100'>
          Clients & Projects
        </h2>
        <div className='flex flex-row'>
          {!isSearchOpen && (
            <div className='flex flex-row'>
              {<AddMenuDropdown />}
              <div
                className='bg-grey-400 rounded-lg flex items-center justify-center w-10 h-10 cursor-pointer ml-4'
                onClick={() => setIsFilterOpen(!isFilterOpen)}
              >
                <FiltersIcon />
              </div>
              <div
                className='bg-grey-400 rounded-lg flex items-center justify-center w-10 h-10 cursor-pointer ml-4'
                onClick={() => setIsSearchOpen(true)}
              >
                <SearchIcon />
              </div>
            </div>
          )}
          {isSearchOpen && <ProjectSearch onIconClick={() => setIsSearchOpen(false)} />}
        </div>
      </div>

      {/* Header Mobile */}
      <div className='flex flex-col justify-between sm:hidden'>
        <div className='flex flex-row items-center justify-between'>
          <h2 className='text-xl font-medium text-primary-100'>
            Clients & Projects
          </h2>
          <div className='flex flex-row'>
            <div className='flex flex-row'>
              {isAuthorized || isAdmin && <AddMenuDropdown />}
              <div
                className='bg-grey-400 rounded-lg flex items-center justify-center w-10 h-10 cursor-pointer ml-4'
                onClick={() => setIsSearchOpen(!isSearchOpen)}
              >
                {isSearchOpen ? <CloseIcon /> : <SearchIcon />}
              </div>
            </div>
          </div>
        </div>
        {isSearchOpen && (
          <div className='flex flex-row items-center justify-between mt-4'>
            <ProjectSearch
              onIconClick={() => setIsFilterOpen(!isFilterOpen)}
              rightIcon={FiltersIcon}
            />
          </div>
        )}
      </div>

      {/* Filters */}
      {isFilterOpen && (
        <div className='flex flex-col xl:flex-row justify-end mt-6 grow'>
          <div className='mb-2 xl:mr-5 xl:mb-0'>
            <ListFilter
              label='Industries'
              items={industries.map((i) => ({
                id: i.id,
                isSelected: !!filters.industries.find(
                  (filter) => filter.id === i.id && filter.isSelected,
                ),
                value: i.name,
              }))}
              onChange={(items) => updateIndustryFilterAction(items)}
              onClearAll={() => updateIndustryFilterAction([])}
              isOpen={isIndustriesFilterOpen}
              onClose={() => setIsIndustriesFilterOpen(false)}
              onClick={() => {
                setIsMembersFilterOpen(false)
                setIsIndustriesFilterOpen(true)
              }}
            />
          </div>
          <div className='mb-2 xl:mr-5 xl:mb-0'>
            <ListFilter
              label='Members'
              items={membersToFilter.map((member) => ({
                id: member.id,
                imageUrl: member.avatarUrl || '',
                isSelected: !!filters.members.find(
                  (filter) => filter.id === member.id && filter.isSelected,
                ),
                value: `${member.firstName} ${member.lastName}`,
              }))}
              onChange={(items) => updateMembersFilterAction(items)}
              onClearAll={() => updateMembersFilterAction([])}
              isOpen={isMembersFilterOpen}
              onClose={() => setIsMembersFilterOpen(false)}
              onClick={() => {
                setIsIndustriesFilterOpen(false)
                setIsMembersFilterOpen(true)
              }}
            />
          </div>
          <div className='mb-2 xl:mr-5 xl:mb-0'>
            <CalendarFilter
              label='Move in Date: Start'
              value={filters.moveInDate.from}
              onChange={(date) => updateDateStartFilterAction(date)}
            />
          </div>
          <div className='mb-2 xl:mr-5 xl:mb-0'>
            <CalendarFilter
              label='Move in Date: End'
              value={filters.moveInDate.to}
              onChange={(date) => updateDateEndFilterAction(date)}
            />
          </div>
          <div
            className='flex justify-end items-center cursor-pointer font-semibold text-base text-primary-400'
            onClick={clearProjectsAndClientsFilterAction}
          >
            Clear filters
          </div>
        </div>
      )}

      {((!isEmpty || !isNewlyAddedClientEmpty) && pinnedProjects.length > 0) && (
        <Carousel
          hasArrows={pinnedProjects.length !== 0}
          title={getPinnedCarouselTitle()}
          hasSeeAll={false} // TODO: create a page for pinned projects
          itemAmount={pinnedProjects.length}
        >
          {pinnedProjects.map((project) => (
            <ProjectPinnedCard
              key={project.id}
              client={project.client.name}
              project={project}
            />
          ))}
        </Carousel>
      )}

      {(isEmpty && isNewlyAddedClientEmpty && !props.showParticipantProjectsEmptyState) && (
        <ClientEmptyState
          isAuthorized={isAdmin || isAuthorized}
          onAction={() => displaySlideOutAction('ClientNewSlideOut')}
        />
      )}

      {(isEmpty && isNewlyAddedClientEmpty && props.showParticipantProjectsEmptyState) && (
        <ParticipantProjectsEmptyState
          onAction={() => displayNewProjectSlideOut()}
        />
      )}

      {!isNewlyAddedClientEmpty
        && newlyAddedClient.map((client) => (
          <Carousel
            hasArrows={client.projects?.length !== 0}
            key={client.id}
            clientId={client.id}
            title={getNewlyAddedTitle(client.name)}
            hasSeeAll={(client?.projects?.length || 0) > 3}
            itemAmount={client?.projects?.length || 0}
          >
            {client.projects?.map((project) => <Project key={project?.id} project={project || undefined} />)}
          </Carousel>
        ))}

      {!isEmpty
        && filteredClients.map((client) => (
          <Carousel
            hasArrows={client.projects?.length !== 0}
            key={client.id}
            clientId={client.id}
            title={getClientTitle(client)}
            hasSeeAll={(client?.projects?.length || 0) > 3}
            itemAmount={client?.projects?.length || 0}
          >
            {client.projects?.map((project) => <Project key={project?.id} project={project || undefined} />)}
            {client.projects?.length === 0 && (
              <div className='font-normal text-primary-300'>
                This client has no projects yet. Click + to add a project.
              </div>
            )}
          </Carousel>
        ))}
    </div>
  )
}
