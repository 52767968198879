import { ApolloClient, ApolloError, InMemoryCache, ServerError } from '@apollo/client'
import config from '../config'
import {
  CreateNotificationDocument,
  DeleteNotificationDocument,
  GetNotificationsDocument,
  NotificationDetail,
  NotificationInput,
} from '../generated/api'
import { UnauthenticatedError, UnexpectedError } from '../presenter/errors'

function isApolloError(err: unknown | ApolloError): err is ApolloError {
  return (err as ApolloError).graphQLErrors !== undefined
}

function isServerError(err: unknown | ServerError): err is ServerError {
  return (err as ServerError).name == 'ServerError'
}

function graphqlErrorHandler(err: unknown | ApolloError) {
  if (isApolloError(err)) {
    const networkError = err.networkError
    if (isServerError(networkError)) {
      if (networkError.statusCode == 401) throw new UnauthenticatedError()
      if (networkError.statusCode == 404) throw new UnexpectedError()
    }
  }
}

const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  credentials: 'include',
  uri: config.appApiGraphqlUrl,
})

export const getNotifications = async (): Promise<NotificationDetail[]> => {
  try {
    const { data } = await apolloClient.query({ fetchPolicy: 'no-cache', query: GetNotificationsDocument })
    return data.getNotifications
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const sendNotification = async (input: NotificationInput) => {
  try {
    return await apolloClient.mutate({
      fetchPolicy: 'no-cache',
      mutation: CreateNotificationDocument,
      variables: { input },
    })
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const deleteNotification = async (id: string): Promise<void> => {
  try {
    await apolloClient.mutate({
      fetchPolicy: 'no-cache',
      mutation: DeleteNotificationDocument,
      variables: { id },
    })
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}
