import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useState } from 'react'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import Select from 'react-select'
import TrashIconGrey from 'theorem-lib/src/assets/icons/trash-icon-grey.svg'
import { useActions, useAppState } from '../../../presenter'
import { ManageServicesFormValidator } from '../../../validators/manageServicesFormValidator'
import { serviceStyles } from '../../atoms/ReactSelectStyling/selectStyle'
import { Service } from '../../atoms/Service/Service'
import { SlideOut } from '../../atoms/SlideOut/SlideOut'
import { SlideOutFooter } from '../../atoms/SlideOutFooter/SlideoutFooter'

type ServiceFormType = {
  templateId: string | null
  lowEstimate: string | null
  midEstimate: string | null
  highEstimate: string | null
  percentage: string | null
}

type SubmitFormType = {
  values: ServiceFormType[]
}

export function ManageServicesSlideOut() {
  const {
    createServiceAction,
    deleteServiceAction,
    displayModalAction,
    displaySlideOutAction,
    updateServiceAction,
  } = useActions()
  const { filteredProjectServices, selectedProject, selectedProjectServices, servicesTemplates } = useAppState()
  const methods = useForm({
    defaultValues: {
      values: [{
        highEstimate: '',
        lowEstimate: '',
        midEstimate: '',
        name: 'initial',
        percentage: null,
        templateId: '',
      }],
    },
    resolver: yupResolver(ManageServicesFormValidator),
  })

  const { control } = methods
  const { append, fields, remove } = useFieldArray(
    {
      control,
      name: 'values',
    },
  )

  useEffect(() => {
    methods.reset({
      // @ts-expect-error Conflict between string and number causing issues on BE //
      values: filteredProjectServices.map((service) => {
        if (service.percentage === 0) {
          return ({
            highEstimate: service.highEstimate,
            lowEstimate: service.lowEstimate,
            midEstimate: service.midEstimate,
            name: service.servicesTemplates.name,
            percentage: null,
            templateId: service.servicesTemplates.id,
          })
        } else {
          return ({
            highEstimate: null,
            lowEstimate: null,
            midEstimate: null,
            name: service.servicesTemplates.name,
            percentage: service.percentage,
            templateId: service.servicesTemplates.id,
          })
        }
      }),
    })
  }, [filteredProjectServices])

  const [isDirty, setIsDirty] = useState<boolean>(false)
  const [currentSelections, setCurrentSelections] = useState<string[]>([])

  const selectedTemplateIds = filteredProjectServices.map((serviceId) => serviceId.servicesTemplates.id)

  const filteredTemplates = servicesTemplates.filter(template => !selectedTemplateIds.includes(template.id))

  const selectedArray: string[] = []

  const onRemove = async (index: number, templateId: string, name: string) => {
    remove(index)

    const indexToRemove = selectedArray.indexOf(name)
    selectedArray.splice(indexToRemove, 1)

    const projectForDeletion = selectedProjectServices.find((service) => service.servicesTemplates.id === templateId)

    await deleteServiceAction({ serviceId: projectForDeletion!.id })
  }

  const onSubmit = async (data: SubmitFormType) => {
    displaySlideOutAction('None')

    const updateList: string[] = []

    selectedProjectServices.forEach((selected) => {
      data.values.forEach((data) => {
        if (data.templateId === selected.servicesTemplates.id && (data.percentage === null || data.percentage === '')) {
          updateList.push(data.templateId)
          updateServiceAction(
            {
              projectId: selectedProject.id,
              service: {
                highEstimate: parseInt(data.highEstimate!),
                lowEstimate: parseInt(data.lowEstimate!),
                midEstimate: parseInt(data.midEstimate!),
                percentage: 0,
                serviceId: selected.id,
              },
            },
          )
        } else if (data.templateId === selected.servicesTemplates.id && data.lowEstimate === null) {
          updateList.push(data.templateId)
          updateServiceAction(
            {
              projectId: selectedProject.id,
              service: {
                highEstimate: 0,
                lowEstimate: 0,
                midEstimate: 0,
                percentage: parseFloat(data.percentage!),
                serviceId: selected.id,
              },
            },
          )
        }
      })
    })

    const newServiceDollarList = data.values.filter((service) =>
      service.percentage === null
      && service.templateId !== ''
      && !updateList.includes(service.templateId!)
    )

    const newServicePercentageList = data.values.filter((service) =>
      service.lowEstimate === null
      && service.templateId !== ''
      && !updateList.includes(service.templateId!)
    )

    const filteredDollarList = newServiceDollarList.map(i => ({
      highEstimate: parseInt(i.highEstimate!),
      lowEstimate: parseInt(i.lowEstimate!),
      midEstimate: parseInt(i.midEstimate!),
      percentage: 0,
      templateId: i.templateId!,
    }))

    const filteredPercentageList = newServicePercentageList.map(i => ({
      highEstimate: 0,
      lowEstimate: 0,
      midEstimate: 0,
      percentage: parseFloat(i.percentage!),
      templateId: i.templateId!,
    }))

    await createServiceAction({
      projectId: selectedProject.id,
      servicesList: filteredDollarList,
    })

    await createServiceAction({
      projectId: selectedProject.id,
      servicesList: filteredPercentageList,
    })
  }

  return (
    <SlideOut
      title='Manage Services'
      onClose={() => isDirty ? displayModalAction('CancelProjectEditModal') : displaySlideOutAction('None')}
    >
      <h2 className='mb-6 text-primary-200 font-normal'>
        Select a service to add to your estimated project budget.
      </h2>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <ul>
            <div className='w-3/5 border-b border-primary-400'>
              <Select
                className='serviceSelect'
                placeholder='Add a service'
                options={filteredTemplates.map(service => ({
                  id: service.id,
                  label: service.name,
                }))}
                styles={serviceStyles}
                onChange={(value) => {
                  setIsDirty(true)
                  append({
                    highEstimate: '',
                    lowEstimate: '',
                    midEstimate: '',
                    name: value?.label,
                    percentage: null,
                    templateId: value?.id,
                  })
                }}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    neutral10: 'none',
                    primary: 'gray',
                  },
                })}
              />
            </div>
            {fields.map((field, index) => {
              if (!selectedArray.includes(field.name)) {
                selectedArray.push(field.name)
                return (
                  <li key={field.id} className='flex flex-row mb-3 mt-3'>
                    <Service
                      name={field.name}
                      id={field.templateId}
                      index={index}
                    />
                    {field.name !== 'initial'
                      && (
                        <div className='mt-6 inline-block align-text-baseline'>
                          <button
                            className='ml-8 cursor-pointer px-2 py-2'
                            type='button'
                            onClick={() => onRemove(index, field.templateId, field.name)}
                          >
                            <TrashIconGrey />
                          </button>
                        </div>
                      )}
                  </li>
                )
              } else {
                undefined
              }
            })}
          </ul>
          <SlideOutFooter>
            <button type='submit' className='button cta'>Save</button>
          </SlideOutFooter>
        </form>
      </FormProvider>
    </SlideOut>
  )
}
