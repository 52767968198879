import { cloneDeep } from 'lodash'
import React, { useEffect, useState } from 'react'
import { PalletesImagesPool } from '../../../../assets/Industries/imagesPool'
import { Project, VisualPreferences, VisualPreferencesInput } from '../../../generated/api'
import { useActions, useAppState } from '../../../presenter'
import { LikeDislike } from '../../atoms/LikeDislike/LikeDislike'
import { PulseAnimation } from '../../atoms/Loading/Loading'
import { SlideOut } from '../../atoms/SlideOut/SlideOut'
import { SlideOutFooter } from '../../atoms/SlideOutFooter/SlideoutFooter'
import { IndustriesEnum } from '../../molecules/NewProjectCreation/IndustriesSelect'

export const EditVisualPreferencesSlideOut = () => {
  const {
    displayModalAction,
    displaySlideOutAction,
    submitUpdateProjectAction,
  } = useActions()
  const { industries, selectedProject } = useAppState()
  const [visualPreferences, setVisualPreferences] = useState<VisualPreferences[]>()
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    if (selectedProject.industryDetails?.visualPreferences) {
      setVisualPreferences(selectedProject.industryDetails?.visualPreferences)
    } else {
      setVisualPreferences(PalletesImagesPool)
    }
  }, [selectedProject.industryDetails?.visualPreferences, industries])

  setTimeout(() => {
    setLoading(false)
  }, 600)

  const saveVisualPreferences = async () => {
    const copyProject = cloneDeep(selectedProject)

    if (copyProject) {
      const sProject: Project = {
        ...copyProject,
        industryDetails: {
          id: copyProject.industryDetails?.visualPreferences ? copyProject.industryDetails?.id : '',
          ...copyProject.industryDetails,
          visualPreferences: visualPreferences,
        },
      }

      await submitUpdateProjectAction({
        image: undefined,
        project: sProject,
        visualPreference: true,
      })
      displaySlideOutAction('None')
    }
  }

  const onLikeClick = (cover: VisualPreferencesInput) => {
    const updatedList = visualPreferences?.map(item => {
      if (item.id == cover.id) {
        return { ...item, dislike: false, like: true }
      }
      return item
    })

    setVisualPreferences(updatedList)
  }
  const onDislikeClick = (cover: VisualPreferencesInput) => {
    const updatedList = visualPreferences?.map(item => {
      if (item.id == cover.id) {
        return { ...item, dislike: true, like: false }
      }
      return item
    })

    setVisualPreferences(updatedList)
  }

  const Thumbnail = (cover: VisualPreferencesInput) => (
    <div
      key={cover.id}
      className={`min-w-[128px] h-24 bg-primary-200 mr-2 rounded-md bg-cover bg-no-repeat bg-center cursor-pointer flex flex-col`}
      style={{ backgroundImage: `url('${cover.url}')` }}
    >
      <LikeDislike
        liked={cover.like}
        disliked={cover.dislike}
        onLikeClick={() => onLikeClick(cover)}
        onDislikeClick={() => onDislikeClick(cover)}
      />
    </div>
  )

  return (
    <SlideOut
      onClose={() => displayModalAction('CancelProjectEditModal')}
    >
      <p className='text-base font-bold text-gray-900'>
        Visual Preferences
      </p>
      <p className='mb-6 text-primary-200 font-normal'>
        What are some styles you find appealing?
      </p>
      <div className='flex flex-wrap'>
        {(loading)
          ? <PulseAnimation />
          : visualPreferences && (
            <>
              {visualPreferences.map((image, index) => (
                <div key={index} className='w-1/2 mb-10'>
                  <Thumbnail key={index} {...image} dislike={image.dislike} />
                </div>
              ))}
            </>
          )}
      </div>
      <SlideOutFooter justify='between py-6'>
        <button
          className='bg-[#F1F1F1] items-center text-[#35697D] px-3 rounded-lg font-semibold mr-2 h-10'
          onClick={() => displaySlideOutAction('None')}
        >
          {'<-'} Back to Details
        </button>

        <button
          className='bg-cta-200 items-center text-white px-3 rounded-lg font-semibold mr-2 h-10'
          type='submit'
          onClick={() => saveVisualPreferences()}
        >
          Save Changes
        </button>
      </SlideOutFooter>
    </SlideOut>
  )
}
