import React, { Fragment } from 'react'

import { useActions, useAppState } from '../../../presenter'
import { ServiceEmptyState } from '../../atoms/EmptyStates/ServiceEmptyState'
import { templateOptions } from '../../atoms/Service/Service'

export interface ServicesProps {
  hideAddNewServiceButton?: boolean
  hideExportDataButton?: boolean
}

export const ServicesTemplates = (props: ServicesProps) => {
  const { displaySlideOutAction } = useActions()
  const {
    filteredProjectServices,

    selectedProjectEstimates,
    selectedProjectServices,
  } = useAppState()

  const regex = /(\d)(?=(\d{3})+(?!\d))/g

  let lowTotal = 0
  let midTotal = 0
  let highTotal = 0

  const percentageServices = selectedProjectServices.filter(service => service.percentage !== 0)
  const percentageServicesObject = [{
    id: '',
    name: '',
  }]

  const totalValuesFinal = () => {
    lowTotal = 0
    midTotal = 0
    highTotal = 0

    selectedProjectServices.forEach((service) => {
      lowTotal = lowTotal + service.lowEstimate
      midTotal = midTotal + service.midEstimate
      highTotal = highTotal + service.highEstimate
    })

    percentageServices.forEach((service, index) => {
      const nextPercentage = {
        id: service.id,
        name: service.servicesTemplates.name,
      }

      percentageServicesObject.push(nextPercentage)
    })

    lowTotal = lowTotal + selectedProjectEstimates.installPercentLow + selectedProjectEstimates.freightPercentLow
    midTotal = midTotal + selectedProjectEstimates.installPercentMid + selectedProjectEstimates.freightPercentMid
    highTotal = highTotal + selectedProjectEstimates.installPercentHigh + selectedProjectEstimates.freightPercentHigh
  }

  totalValuesFinal()

  return (
    <div className='flex flex-col'>
      <div className='flex flex-row justify-between items-center'>
        <h2 className='text-xl font-medium text-primary-100'>
          Services
        </h2>
        <div className='flex flex-row'>
          {!props.hideAddNewServiceButton
            && (
              <button
                id='addService'
                onClick={() => displaySlideOutAction('ManageServicesSlideOut')}
                className='button cta'
              >
                <div>
                  Manage Services
                </div>
              </button>
            )}
        </div>
      </div>
      <div className='flex flex-col'>
        <div className='text-primary-300 text-base font-normal text-left max-w-[800px]'>
          Add & manage services here to be reflected in the high, medium, and low budgets for your project.
        </div>
      </div>
      {selectedProjectServices.length === 0
        ? (
          <ServiceEmptyState
            isParticipant={props.hideAddNewServiceButton}
          />
        )
        : (
          <section className='pb-2 mb-24'>
            <table className='w-full'>
              <thead>
                <tr>
                  <th className='p-4'>Service</th>
                  <th className='p-4'>LOW ESTIMATE</th>
                  <th className='p-4'>MID ESTIMATE</th>
                  <th className='p-4'>HIGH ESTIMATE</th>
                </tr>
              </thead>
              <tbody>
                {filteredProjectServices.map(service => (
                  service.percentage === 0
                    ? (
                      <tr key={service.id} className='even:bg-gray-100'>
                        <td className='p-4'>
                          <span className='inline-block'>{service.servicesTemplates.name}</span>
                        </td>
                        <td className='p-4'>
                          ${service.lowEstimate.toFixed(2).replace(regex, '$1,')}
                        </td>
                        <td className='p-4'>
                          ${service.midEstimate.toFixed(2).replace(regex, '$1,')}
                        </td>
                        <td className='p-4'>
                          ${service.highEstimate.toFixed(2).replace(regex, '$1,')}
                        </td>
                      </tr>
                    )
                    : (service.percentage !== 0 && service.servicesTemplates.name === templateOptions.freightCost)
                    ? (
                      <tr key={service.id} className='even:bg-gray-100'>
                        <td className='p-4'>
                          <span className='inline-block'>{service.servicesTemplates.name}</span>
                        </td>
                        <td className='p-4'>
                          ${selectedProjectEstimates.freightPercentLow.toFixed(2).replace(
                            regex,
                            '$1,',
                          )}
                        </td>
                        <td className='p-4'>
                          ${selectedProjectEstimates.freightPercentMid.toFixed(2).replace(
                            regex,
                            '$1,',
                          )}
                        </td>
                        <td className='p-4'>
                          ${selectedProjectEstimates.freightPercentHigh.toFixed(2).replace(
                            regex,
                            '$1,',
                          )}
                        </td>
                      </tr>
                    )
                    : (service.percentage !== 0 && service.servicesTemplates.name === templateOptions.installationCosts)
                    ? (
                      <tr key={service.id} className='even:bg-gray-100'>
                        <td className='p-4'>
                          <span className='inline-block'>{service.servicesTemplates.name}</span>
                        </td>
                        <td className='p-4'>
                          ${selectedProjectEstimates.installPercentLow.toFixed(2).replace(
                            regex,
                            '$1,',
                          )}
                        </td>
                        <td className='p-4'>
                          ${selectedProjectEstimates.installPercentMid.toFixed(2).replace(
                            regex,
                            '$1,',
                          )}
                        </td>
                        <td className='p-4'>
                          ${selectedProjectEstimates.installPercentHigh.toFixed(2).replace(
                            regex,
                            '$1,',
                          )}
                        </td>
                      </tr>
                    )
                    : (service.percentage !== 0 && service.servicesTemplates.name === templateOptions.productTax)
                    ? (
                      <tr key={service.id} className='even:bg-gray-100'>
                        <td className='p-4'>
                          <span className='inline-block'>{service.servicesTemplates.name}</span>
                        </td>
                        <td className='p-4'>
                          ${selectedProjectEstimates.productTaxPercentLow.toFixed(2).replace(
                            regex,
                            '$1,',
                          )}
                        </td>
                        <td className='p-4'>
                          ${selectedProjectEstimates.productTaxPercentMid.toFixed(2).replace(
                            regex,
                            '$1,',
                          )}
                        </td>
                        <td className='p-4'>
                          ${selectedProjectEstimates.productTaxPercentHigh.toFixed(2).replace(
                            regex,
                            '$1,',
                          )}
                        </td>
                      </tr>
                    )
                    : (service.percentage !== 0 && service.servicesTemplates.name === templateOptions.servicesTax)
                    ? (
                      <tr key={service.id} className='even:bg-gray-100'>
                        <td className='p-4'>
                          <span className='inline-block'>{service.servicesTemplates.name}</span>
                        </td>
                        <td className='p-4'>
                          ${selectedProjectEstimates.serviceTaxPercentLow.toFixed(2).replace(
                            regex,
                            '$1,',
                          )}
                        </td>
                        <td className='p-4'>
                          ${selectedProjectEstimates.serviceTaxPercentMid.toFixed(2).replace(
                            regex,
                            '$1,',
                          )}
                        </td>
                        <td className='p-4'>
                          ${selectedProjectEstimates.serviceTaxPercentHigh.toFixed(2).replace(
                            regex,
                            '$1,',
                          )}
                        </td>
                      </tr>
                    )
                    : (service.percentage !== 0 && service.servicesTemplates.name === templateOptions.estimatedTaxRate)
                    ? (
                      <tr key={service.id} className='even:bg-gray-100'>
                        <td className='p-4'>
                          <span className='inline-block'>{service.servicesTemplates.name}</span>
                        </td>
                        <td className='p-4'>
                          ${selectedProjectEstimates.totalTaxPercentLow.toFixed(2).replace(
                            regex,
                            '$1,',
                          )}
                        </td>
                        <td className='p-4'>
                          ${selectedProjectEstimates.totalTaxPercentMid.toFixed(2).replace(
                            regex,
                            '$1,',
                          )}
                        </td>
                        <td className='p-4'>
                          ${selectedProjectEstimates.totalTaxPercentHigh.toFixed(2).replace(
                            regex,
                            '$1,',
                          )}
                        </td>
                      </tr>
                    )
                    : (service.percentage !== 0 && service.servicesTemplates.name === templateOptions.antiPriceInc)
                    ? (
                      <tr key={service.id} className='even:bg-gray-100'>
                        <td className='p-4'>
                          <span className='inline-block'>{service.servicesTemplates.name}</span>
                        </td>
                        <td className='p-4'>
                          ${selectedProjectEstimates.antiPricePercentLow.toFixed(2).replace(
                            regex,
                            '$1,',
                          )}
                        </td>
                        <td className='p-4'>
                          ${selectedProjectEstimates.antiPricePercentMid.toFixed(2).replace(
                            regex,
                            '$1,',
                          )}
                        </td>
                        <td className='p-4'>
                          ${selectedProjectEstimates.antiPricePercentHigh.toFixed(2).replace(
                            regex,
                            '$1,',
                          )}
                        </td>
                      </tr>
                    )
                    : undefined
                ))}
              </tbody>
              <tfoot>
                <tr className='border-t-2 border-slate-400'>
                  <td className='p-4'>
                    <span className='inline-block'>Total (Services)</span>
                  </td>
                  <td className='p-4'>
                    <span className='inline-block'>
                      ${selectedProjectEstimates.lowServiceEstimate.toFixed(2).replace(
                        regex,
                        '$1,',
                      )}
                    </span>
                  </td>
                  <td className='p-4'>
                    <span className='inline-block'>
                      ${selectedProjectEstimates.midServiceEstimate.toFixed(2).replace(
                        regex,
                        '$1,',
                      )}
                    </span>
                  </td>
                  <td className='p-4'>
                    <span className='inline-block'>
                      ${selectedProjectEstimates.highServiceEstimate.toFixed(2).replace(
                        regex,
                        '$1,',
                      )}
                    </span>
                  </td>
                </tr>
              </tfoot>
            </table>
          </section>
        )}
    </div>
  )
}
