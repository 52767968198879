import React from 'react'
import PinIcon from 'theorem-lib/src/assets/icons/pin.svg'
import SettingsIcon from 'theorem-lib/src/assets/icons/settings.svg'
import TrashIcon from 'theorem-lib/src/assets/icons/trash.svg'
import { Project } from '../../../generated/api'
import { useActions, useAppState } from '../../../presenter'
import { ProjectSecurityRoles } from '../../../types/gql_enums'
import Dropdown from '../../atoms/Dropdown/Dropdown'

type ProjectDropdownProps = {
  project?: Project
  openButton: React.ReactNode
  isOpen?: boolean
  onClose?: () => void
  isInternal?: boolean
}

export function ProjectDropdown({ isInternal = false, isOpen, onClose, openButton, project }: ProjectDropdownProps) {
  const { authenticatedUser, projects } = useAppState()

  const {
    displayModalAction,
    redirectAction,
    setSelectedProjectAction,
    toggleProjectPinAction,
    updateProjectDeleteIdAction,
  } = useActions()

  const goToTab = (tab: string) => redirectAction(`/project/${project?.id}/${tab}`)
  const checkIsProjectLead = () => {
    const thisProject = projects.find((proj) => {
      return proj.id === project?.id
    })

    return thisProject?.members?.some((member) => {
      if (member.id === authenticatedUser.id) {
        if (member.role.name) {
          return member.role.name === ProjectSecurityRoles.Lead
        }
      }
    })
  }

  const onDelete = () => {
    project && updateProjectDeleteIdAction(project.id)
    displayModalAction('ProjectDeleteModal')
  }

  return (
    <Dropdown isInternal={isInternal} shouldOpenOnHover openButton={openButton} isOpen={isOpen} onClose={onClose}>
      <div className='flex flex-col p-2 w-[240px] cursor-default text-primary-300'>
        <div
          className='flex flex-row mb-3 cursor-pointer'
          onClick={() => project && toggleProjectPinAction(project)}
        >
          <PinIcon />
          <span className='text-primary-300 text-base font-normal ml-3'>{project?.isPinned ? 'Unpin' : 'Pin'}</span>
        </div>

        <>
          <div
            className='flex flex-row mb-3 cursor-pointer'
            onClick={async () => {
              onClose && onClose()

              await setSelectedProjectAction(project)
              goToTab('details')
            }}
          >
            <SettingsIcon />
            <span className='text-primary-300 text-base font-normal ml-3'>Manage Settings</span>
          </div>
          {checkIsProjectLead()
            && (
              <div className='flex flex-row cursor-pointer' onClick={onDelete}>
                <TrashIcon />
                <span className='text-primary-300 text-base font-normal ml-3'>Delete</span>
              </div>
            )}
        </>
      </div>
    </Dropdown>
  )
}
