import React from 'react'

export const SlideIndicators = () => {
  return (
    <div className='absolute z-30 flex space-x-3 -translate-x-1/2 bottom-5 left-1/2'>
      <button
        type='button'
        className='w-3 h-3 rounded-full'
        aria-current='true'
        aria-label='Slide 1'
        data-carousel-slide-to='0'
      >
      </button>
      <button
        type='button'
        className='w-3 h-3 rounded-full'
        aria-current='false'
        aria-label='Slide 2'
        data-carousel-slide-to='1'
      >
      </button>
      <button
        type='button'
        className='w-3 h-3 rounded-full'
        aria-current='false'
        aria-label='Slide 3'
        data-carousel-slide-to='2'
      >
      </button>
      <button
        type='button'
        className='w-3 h-3 rounded-full'
        aria-current='false'
        aria-label='Slide 4'
        data-carousel-slide-to='3'
      >
      </button>
      <button
        type='button'
        className='w-3 h-3 rounded-full'
        aria-current='false'
        aria-label='Slide 5'
        data-carousel-slide-to='4'
      >
      </button>
    </div>
  )
}
