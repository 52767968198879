import React from 'react'
import ReactTooltip from 'react-tooltip'
import PlusIcon from 'theorem-lib/src/assets/icons/plus.svg'
import ResendIcon from 'theorem-lib/src/assets/icons/resend.svg'
import TrashIcon from 'theorem-lib/src/assets/icons/trash.svg'
import AvatarCircle from 'theorem-lib/src/components/atoms/AvatarCircle/AvatarCircle'
import AvatarInitial from 'theorem-lib/src/components/atoms/AvatarInitial/AvatarInitial'
import { Member, ProjectMemberStatusEnum, ProjectRole } from '../../../generated/api'
import { useActions, useAppState } from '../../../presenter'
import { ProjectSecurityRoles } from '../../../types/gql_enums'
import { ProjectHeader } from '../../molecules/ProjectHeader'

export type allowedRolesResendInvite = ProjectSecurityRoles

interface ProjectTeamProps {
  hideHeaderDropDown?: boolean
  hideHeaderDeleteOption?: boolean
  disableRoleSelection?: boolean
  disableAddMemberButton?: boolean
  disableReSendInvite?: boolean
  readOnlyProjectStage?: boolean
  allowedRolesToResendInvite?: allowedRolesResendInvite[]
  collaboratorInvitationSlideOut?: boolean
  disableDeleteProjectMembers?: boolean
}

export const ProjectTeamTemplate = (props: ProjectTeamProps) => {
  const { projectRoles, selectedProject } = useAppState()
  const {
    displayModalAction,
    displaySlideOutAction,
    resendMemberInvitationAction,
    updateProjectMemberDeleteIdAction,
    updateProjectMemberRoleAction,
    updateProjectMemberRoleIdsAction,
  } = useActions()

  const resendInvitationHandler = async (memberId: string) => {
    resendMemberInvitationAction({ memberId, projectId: selectedProject.id })
  }

  const onDelete = (memberId: string) => {
    selectedProject && updateProjectMemberDeleteIdAction({ memberId, projectId: selectedProject.id })
    displayModalAction('ProjectMemberDeleteModal')
  }

  const allowedToResendInvite = (role: ProjectRole) => {
    return props.allowedRolesToResendInvite
      ? props.allowedRolesToResendInvite.find(f => f === role.name)
      : true
  }

  return (
    <div className='flex flex-col'>
      <ProjectHeader
        activeTab='team'
        hideDropDown={props.hideHeaderDropDown}
        hideDeleteOption={props.hideHeaderDeleteOption}
        readOnlyProjectStage={props.readOnlyProjectStage}
      />
      <div className='hidden sm:flex flex-row justify-between items-center mt-7'>
        <h2 className='text-xl font-medium text-primary-100'>Team Members</h2>

        {(!props.disableAddMemberButton) && (
          <div
            className='bg-cta-200 rounded-lg flex items-center justify-center w-10 h-10 text-white cursor-pointer'
            onClick={() => {
              props.collaboratorInvitationSlideOut
                ? displaySlideOutAction('CollaboratorInvitationSlideOut')
                : displaySlideOutAction('MemberInvitationSlideOut')
            }}
          >
            <PlusIcon />
          </div>
        )}
      </div>

      <table className='mt-4 text-base'>
        <thead>
          <tr className='text-[#70757a]'>
            <th>NAME</th>
            <th>EMAIL</th>
            <th>ROLE</th>
            <th>PHONE</th>
            <th>PROJECT ROLE</th>
            <th>STATUS</th>
          </tr>
        </thead>
        <tbody>
          {selectedProject.members.map((member: Member) => (
            <tr key={member.id}>
              <td className='inline-flex items-center'>
                <div>
                  {member?.avatarUrl
                    ? <AvatarCircle url={member.avatarUrl} />
                    : <AvatarInitial firstName={member.firstName || ''} lastName={member.lastName || ''} />}
                </div>

                <div className='ml-4 pr-8'>
                  {member.firstName} {member.lastName}
                </div>
              </td>
              <td>
                <a href={'mailto:' + member.email}>{member.email}</a>
              </td>
              <td className='me-4'>{member.businessRole}</td>

              <td>{member.phoneNumber}</td>

              <td>
                {member.role.name === ProjectSecurityRoles.Lead
                    || member.role.name === ProjectSecurityRoles.Editor
                  ? (
                    <select
                      disabled={props.disableRoleSelection}
                      className='text-base'
                      value={member.role?.id}
                      onChange={(event) => {
                        updateProjectMemberRoleIdsAction({
                          memberId: member.id,
                          projectId: selectedProject.id,
                          roleId: event.target.value,
                        })
                        updateProjectMemberRoleAction()
                      }}
                    >
                      {projectRoles.map(function(role) {
                        if (
                          role?.name != ProjectSecurityRoles.Collaborator
                        ) {
                          return <option key={role?.id} value={role?.id}>{role?.name}</option>
                        }
                      })}
                    </select>
                  )
                  : <div>Collaborator</div>}
              </td>
              {
                <td className='flex'>
                  {member.status}
                  {(!props.disableReSendInvite
                    && (member.status !== ProjectMemberStatusEnum.Accepted))
                    && (allowedToResendInvite(member.role))
                    && (
                      <button
                        className='inline-flex items-center ml-4 text-cta-200'
                        onClick={() => resendInvitationHandler(member.id)}
                      >
                        <span className='cursor-pointer fill-[#C04184]'>
                          <ReactTooltip id='resendTooltip' place='top' effect='solid' />
                          <ResendIcon data-for='resendTooltip' data-tip='Click to resend invite' />
                        </span>
                        <span className='pl-2'>Re-send</span>
                      </button>
                    )}
                </td>
              }
              <td>
                {!props.disableDeleteProjectMembers && (
                  <div
                    className='flex flex-row cursor-pointer fill-[#2b5b74]'
                    onClick={() => onDelete(member.id)}
                  >
                    <TrashIcon />
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
