export default {
  '&multiLine': {
    control: {
      fontFamily: 'manrope',
      minHeight: 63,
    },
    highlighter: {
      border: '1px solid transparent',
      padding: 9,
    },
    input: {
      border: '1px solid silver',
      padding: 9,
    },
  },

  '&singleLine': {
    display: 'inline-block',
    highlighter: {
      border: '2px inset transparent',
      padding: 15,
    },

    input: {
      border: '2px inset',
      padding: 1,
    },
    width: 180,
  },

  control: {
    backgroundColor: '#fff',
    fontSize: '1rem',
    fontWeight: 'normal',
  },

  suggestions: {
    item: {
      '&focused': {
        backgroundColor: '#cee4e5',
      },
      borderBottom: '1px solid rgba(0,0,0,0.15)',
      padding: '5px 15px',
    },
    list: {
      backgroundColor: 'white',
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: '1rem',
    },
  },
}
