import { Client, Member, Project } from '../../../../app/client/src/generated/api'
import { IProjectClient } from '../../../../app/client/src/interfaces/IProjectClient'
import { byFirstName } from './userHelper'

export const getDerivedProjects = (clients: Client[]): IProjectClient[] => {
  const projects: IProjectClient[] = []
  clients.forEach(client => {
    client.projects?.forEach(project => {
      if (project) projects.push({ ...project, client })
    })
  })
  return projects
}

export const getMembers = (projects: Project[]): Member[] => {
  const members: Member[] = []
  projects.forEach(project => {
    project.members?.forEach(member => {
      const isInArray = !!members.find(m => m.id === member.id)
      if (!isInArray) members.push(member)
    })
  })
  return members.sort(byFirstName)
}

export const sortPinnedCards = (p1: IProjectClient, p2: IProjectClient) => {
  return new Date(p2.datePinned).getTime() - new Date(p1.datePinned).getTime()
}
