import { setActiveScreen } from 'theorem-lib/src/helpers/setActiveScreen'
import config from '../../config'
import { SecurityRole } from '../../generated/api'
import { Context } from '..'
import { ForbiddenError, InternalServerError, UnauthenticatedError } from '../errors'
import { getFolioClientsAction } from './clients'

export function defaultErrorHandler(context: Context, err: unknown) {
  if (err instanceof UnauthenticatedError) {
    window.location.assign(`${config.identityUrl}/auth/login?url=${encodeURI(window.location.href)}`)
  } else if (err instanceof ForbiddenError) {
    context.state.currentPage = 'Forbidden'
  } else if (err instanceof InternalServerError) {
    context.state.currentPage = 'Unexpected'
  } else {
    context.state.currentPage = 'Unexpected'
  }
}

export async function redirectAction(context: Context, url: string) {
  context.effects.redirect(url)
}

export async function bootstrapStateAction(context: Context): Promise<boolean> {
  try {
    context.state.authenticatedUser = await context.effects.getAuthenticatedUser()
    const securityRoles = context.state?.authenticatedUser?.securityRoles
    if (
      !securityRoles.includes(SecurityRole.Admin) && !securityRoles.includes(SecurityRole.Member)
      && !securityRoles.includes(SecurityRole.Participant)
    ) {
      throw new ForbiddenError()
    }
    context.state.tenant = await context.actions.getTenantInformationAction()

    return true
  } catch (err) {
    defaultErrorHandler(context, err)
    return false
  }
}

export async function displayNotFoundAction(context: Context) {
  context.state.currentPage = 'NotFound'
}

export async function displayDashboardAction(context: Context) {
  try {
    context.state.currentPage = 'Dashboard'
    const invitations = await context.effects.viewUserInvitations()
    const projectCount = await context.effects.viewProjectCount()
    const notifications = await context.effects.getNotifications()
    context.state.userInvitations = invitations
    context.state.projectCount = projectCount
    context.state.notifications = notifications
    setActiveScreen(context, 'Home')
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function displayRegistrationWelcomeToFolioAction(context: Context) {
  try {
    context.state.currentPage = 'Dashboard'
    context.state.industries = await context.effects.getIndustries()
    context.state.currentModal = 'RegistrationWelcomeToFolioModal'
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function displayModalAction(
  context: Context,
  modalName:
    | 'AdministrativeGoalsWizard'
    | 'ProjectDeleteModal'
    | 'ClientNewWizard'
    | 'ClinicalGoalsWizard'
    | 'ProjectMemberDeleteModal'
    | 'BusinessGoalsWizard'
    | 'DeleteClientModal'
    | 'DeleteSpaceModal'
    | 'DeleteTaskModal'
    | 'DeleteTasklistModal'
    | 'FacilityTypesWizard'
    | 'HomeTypeWizard'
    | 'HomeWorkSpaceWizard'
    | 'HomeOfficeElementsWizard'
    | 'LicensesLimitModal'
    | 'CancelProjectEditModal'
    | 'ProjectSizeWizard'
    | 'ProjectCreationModal'
    | 'SpacesWizard'
    | 'VisualPreferencesWizard'
    | 'GeneralProjectDetailsWizard'
    | 'CancelTasklistEditModal'
    | 'IndustrySelectorWizard'
    | 'GenericCancelModal'
    | 'None',
) {
  if ((modalName === 'GeneralProjectDetailsWizard' && context.state.isMember)) {
    await getFolioClientsAction(context)
  }

  context.state.currentModal = modalName
}

export async function displaySlideOutAction(
  context: Context,
  slideOutName:
    | 'AddCustomTasklistSlideOut'
    | 'ClientNewSlideOut'
    | 'ManageProjectSlideOut'
    | 'AddSpaceSlideOut'
    | 'ManageServicesSlideOut'
    | 'ManageSpaceSlideOut'
    | 'ManageTaskSlideOut'
    | 'ManageTasklistSlideOut'
    | 'MemberInvitationSlideOut'
    | 'CollaboratorInvitationSlideOut'
    | 'CollaboratorInvitationToFolioSlideOut'
    | 'ClientEditSlideOut'
    | 'TaskEditSlideOut'
    | 'AddUserSlideOut'
    | 'VisualPreferencesSlideOut'
    | 'VisualPreferencesReadOnlySlideOut'
    | 'EditVisualPreferencesSlideOut'
    | 'ManageSpaceDiscountSlideOut'
    | 'EditProjectSpaceSlideOut'
    | 'None',
) {
  if (slideOutName === 'MemberInvitationSlideOut') {
    context.state.users = await context.effects.listUsersForProjectInvite(context.state.selectedProject?.id)
  }
  if (slideOutName === 'ManageProjectSlideOut' && context.state.isMember) {
    await getFolioClientsAction(context)
  }
  if (
    slideOutName === 'ManageSpaceDiscountSlideOut'
    || slideOutName === 'ManageServicesSlideOut'
    || slideOutName === 'AddSpaceSlideOut'
  ) {
    const selectedProjectId = context.state.selectedProject.id
    const projectSpaces = await context.effects.getProjectSpaces(selectedProjectId)
    context.state.selectedProjectSpaces = projectSpaces
  }

  context.state.currentSlideOut = slideOutName
}
