import { Context } from '../../../../app/client/src/presenter'

export function setActiveScreen(context: Context, screenName: string): void {
  const screenIndex = context.state.navOptions.findIndex(screen => screen.name === screenName)

  if (screenIndex >= 0) {
    const navOptions = [...context.state.navOptions]
    navOptions.forEach((screen, i) => screen.current = i === screenIndex)
    context.state.navOptions = navOptions
  }
}
