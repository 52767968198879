import React, { ReactElement, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import {
  ExperienceLevelEnum,
  OtherAdministrativeGoalsEnum,
  OtherBusinessGoalsEnum,
  OtherClinicalGoalsEnum,
  OtherElementsEnum,
  OtherFacilityTypesEnum,
  OtherHomeTypeEnum,
  OtherSpacesEnum,
  OtherWorkspaceTypeEnum,
  ProjectInput,
  ProjectSizeEnum,
  QuantValueSchemaInput,
} from '../../../generated/api'
import {
  getAdminEnumByIndustry,
  getBusinessEnumByIndustry,
  getClinicalEnumByIndustry,
  getSpacesEnumByIndustry,
} from '../../../helpers/industryHelper'
import { getNameWithoutUnderScores } from '../../../helpers/projectHelper'
import { useActions, useAppState } from '../../../presenter'
import { newProjectStateEnum } from '../../../types/project'
import MultiSelect from '../../atoms/Select/MultiSelect'
import { MultiOption } from '../../atoms/Select/MultiSelect'
import Select, { Option, SelectProps } from '../../atoms/Select/Select'
import TextInput from '../../atoms/TextInput/TextInput'
import { OtherOptions } from '../../organisms/SlideOuts/ProjectSlideOutTemplate'

interface IndustriesSelect extends SelectProps {
  selectedValue: string
  expLevelFilter?: boolean
  projectCreation?: boolean
}

export enum IndustriesEnum {
  Corporate = 'Corporate',
  Technology = 'Technology',
  Healthcare = 'Healthcare',
  Education = 'Education',
  Government = 'Government',
  WorkFromHome = 'Work From Home',
}

export enum InvalidIndustriesEnum {
  Hospitality = 'Hospitality',
  Amenity = 'Amenity',
  MultifamilyHousing = 'Multifamily Housing',
  WallsShadesFlooring = 'Walls Shades and Flooring',
}

export const IndustriesSelect = (props: IndustriesSelect): ReactElement => {
  const {
    formState: { errors },
    getValues,
    register,
    setValue,
  } = useFormContext()
  const { newProjectCreation, selectedProject } = useAppState()
  const { projectCreationStateMachine } = useActions()
  const other = (element: string) => element === OtherOptions.otherDefault
  const quantValues = selectedProject.industryDetails?.otherSpaces?.quantValues as QuantValueSchemaInput[]

  const [otherBusiness, setOtherBusiness] = useState<boolean>(
    props.projectCreation
      ? !!newProjectCreation?.project?.industryDetails?.otherBusinessGoals?.values.some(other)
      : !!selectedProject?.industryDetails?.otherBusinessGoals?.values.some(other),
  )
  const [otherSpaces, setOtherSpaces] = useState<boolean>(
    props.projectCreation
      ? !!newProjectCreation?.project?.industryDetails?.otherSpaces?.values.some(other)
      : !!selectedProject?.industryDetails?.otherSpaces?.values.some(other),
  )
  const [otherAdminGoals, setOtherAdminGoals] = useState<boolean>(
    props.projectCreation
      ? !!newProjectCreation?.project?.industryDetails?.otherAdministrativeGoals?.values.some(other)
      : !!selectedProject?.industryDetails?.otherAdministrativeGoals?.values.some(other),
  )
  const [otherClinical, setOtherClinical] = useState<boolean>(
    props.projectCreation
      ? !!newProjectCreation?.project?.industryDetails?.otherClinicalGoals?.values.some(other)
      : !!selectedProject?.industryDetails?.otherClinicalGoals?.values.some(other),
  )
  const [otherHomeType, setOtherHomeType] = useState<boolean>(
    props.projectCreation
      ? !!newProjectCreation?.project?.industryDetails?.otherHomeType?.values.some(other)
      : !!selectedProject?.industryDetails?.otherHomeType?.values.some(other),
  )
  const [otherWorkSpace, setOtherWorkSpace] = useState<boolean>(
    props.projectCreation
      ? !!newProjectCreation?.project?.industryDetails?.otherWorkspaceType?.values.some(other)
      : !!selectedProject?.industryDetails?.otherWorkspaceType?.values.some(other),
  )
  const [otherElements, setOtherElements] = useState<boolean>(
    props.projectCreation
      ? !!newProjectCreation?.project?.industryDetails?.otherElements?.values.some(other)
      : !!selectedProject?.industryDetails?.otherElements?.values.some(other),
  )

  const IndustrySelection = () => {
    switch (props.selectedValue) {
      case IndustriesEnum.Corporate:
        return <Corporate />
      case IndustriesEnum.Technology:
        return <Technology />
      case IndustriesEnum.Healthcare:
        return <HealthCare />
      case IndustriesEnum.Education:
        return <Education />
      case IndustriesEnum.Government:
        return <Government />
      case IndustriesEnum.WorkFromHome:
        return <WorkFromHome />
      default:
        return <Corporate />
    }
  }

  const saveProject = () => {
    const savedProject = setProject()

    props.projectCreation
      ? projectCreationStateMachine({ currentState: newProjectStateEnum.ManageProjectSlideOut, project: savedProject })
      : projectCreationStateMachine({ currentState: newProjectStateEnum.CurrentPage, project: savedProject })
  }

  const setProject = () => {
    const sindustry = {
      experienceLevel: {
        other: getValues('industryExperienceOther') ? getValues('industryExperienceOther') : 'None',
        values: getValues('industryExperienceLevel')
          ? getValues('industryExperienceLevel')
          : [],
      },
      otherAdministrativeGoals: {
        other: getValues('industryAdministrativeGoalsOther')
          ? getValues('industryAdministrativeGoalsOther')
          : '',
        values: getValues('industryAdministrativeGoals')
          ? getValues('industryAdministrativeGoals')
          : [],
      },
      otherBusinessGoals: {
        other: getValues('industryBusinessGoalsOther')
          ? getValues('industryBusinessGoalsOther')
          : '',
        values: getValues('industryBusinessGoals')
          ? getValues('industryBusinessGoals')
          : [],
      },
      otherClinicalGoals: {
        other: getValues('industryClinicalGoalsOther')
          ? getValues('industryClinicalGoalsOther')
          : '',
        values: getValues('industryClinicalGoals') ? getValues('industryClinicalGoals') : [],
      },
      otherElements: (!getValues('industryOfficeElements') && !getValues('industryOfficeElementsOther'))
        ? null
        : {
          other: getValues('industryOfficeElementsOther')
            ? getValues('industryOfficeElementsOther')
            : '',
          values: getValues('industryOfficeElements'),
        },
      otherFacilityTypes: {
        other: getValues('industryFacilityTypesOther')
          ? getValues('industryFacilityTypesOther')
          : '',
        values: getValues('industryFacilityTypes')
          ? getValues('industryFacilityTypes')
          : [],
      },
      otherHomeType: (!getValues('industryHomeType') && !getValues('industryHomeTypeOther'))
        ? undefined
        : {
          other: getValues('industryHomeTypeOther')
            ? getValues('industryHomeTypeOther')
            : '',
          values: getValues('industryHomeType'),
        },
      otherSpaces: {
        other: getValues('industryTypesOfSpacesOther')
          ? getValues('industryTypesOfSpacesOther')
          : '',
        quantValues: quantValues as QuantValueSchemaInput[],
        quantities: selectedProject.industryDetails?.otherSpaces?.quantities as number[],
        values: getValues('industryTypesOfSpaces')
          ? getValues('industryTypesOfSpaces')
          : [],
      },
      otherWorkspaceType: (!getValues('industryWorkSpace') && !getValues('industryWorkSpaceOther'))
        ? undefined
        : {
          other: getValues('industryWorkSpaceOther')
            ? getValues('industryWorkSpaceOther')
            : '',
          values: getValues('industryWorkSpace'),
        },
      projectSize: {
        other: '',
        values: getValues('industryProjectScale'),
      },
      visualPreferences: newProjectCreation?.project?.industryId !== getValues('industry').id
        ? undefined
        : newProjectCreation?.project?.industryDetails?.visualPreferences,
    }
    const newProject: ProjectInput = {
      budget: parseFloat(getValues('budget').toString()),
      buildingsQty: getValues('quantity'),
      clientId: '',
      companyName: getValues('companyName'),
      coverImageUrl: '',
      headCount: parseFloat(getValues('headCount').toString()),
      industryDetails: sindustry,
      industryId: getValues('industry.id'),
      members: [],
      moveInDate: (getValues('date') !== undefined || getValues('date') !== '')
        ? getValues('date')
        : undefined,
      name: getValues('name'),
      squareFootage: parseFloat(getValues('squareFootage').toString()),
    }
    return newProject
  }

  const findKeyProjectSizeValue = (item: ProjectSizeEnum) => {
    const key = Object.values(ProjectSizeEnum).findIndex(element => element === item)
    return key.toString()
  }

  const findKeyExperienceValue = (item: ExperienceLevelEnum) => {
    const key = Object.values(ExperienceLevelEnum).findIndex(element => element === item)
    return key.toString()
  }

  const findKeyHomeTypeValue = (item: OtherHomeTypeEnum) => {
    const key = Object.values(OtherHomeTypeEnum).findIndex(element => element === item)

    return key.toString()
  }

  const findKeyWorkSpaceValue = (item: OtherWorkspaceTypeEnum) => {
    const key = Object.values(OtherWorkspaceTypeEnum).findIndex(element => element === item)
    return key.toString()
  }

  const adminGoalData = (items: MultiOption[]) => {
    setValue('industryAdministrativeGoals', [])

    items.forEach((item, index) => {
      setValue(`industryAdministrativeGoals[${index}]`, item.value)
    })
    const other = (element: string) => element === 'Other'
    const otherCheck = getValues('industryAdministrativeGoals').some(other)
    if (otherCheck) {
      setOtherAdminGoals(true)
      saveProject()
    } else {
      setOtherAdminGoals(false)
      setValue('industryAdministrativeGoalsOther', OtherOptions.otherEmpty)
    }
  }

  const businessData = async (items: MultiOption[]) => {
    setValue('industryBusinessGoals', [])

    items.forEach((item, index) => {
      if (item) {
        setValue(`industryBusinessGoals[${index}]`, item.value)
      }
    })
    const other = (element: string) => element === 'Other'
    const otherCheck = getValues('industryBusinessGoals').some(other)
    if (otherCheck) {
      setOtherBusiness(true)
      saveProject()
    } else {
      setOtherBusiness(false)
      setValue('industryBusinessGoalsOther', OtherOptions.otherEmpty)
    }
  }

  const clinicalData = (items: MultiOption[]) => {
    setValue('industryClinicalGoals', [])

    items.forEach((item, index) => {
      setValue(`industryClinicalGoals[${index}]`, item.value)
    })
    const other = (element: string) => element === 'Other'
    const otherCheck = getValues('industryClinicalGoals').some(other)
    if (otherCheck) {
      setOtherClinical(true)
      saveProject()
    } else {
      setOtherClinical(false)
      setValue('industryClinicalGoalsOther', OtherOptions.otherEmpty)
    }
  }

  const elementsData = (items: MultiOption[]) => {
    setValue('industryOfficeElements', [])

    items.forEach((item, index) => {
      setValue(`industryOfficeElements[${index}]`, item.value)
    })
    const other = (element: string) => element === 'Other'
    const otherCheck = getValues('industryOfficeElements').some(other)
    if (otherCheck) {
      setOtherElements(true)
      saveProject()
    } else {
      setOtherElements(false)
      setValue('industryOfficeElementsOther', OtherOptions.otherEmpty)
    }
  }
  const facilityData = (items: MultiOption[]) => {
    setValue('industryFacilityTypes', [])

    items.forEach((item, index) => {
      setValue(`industryFacilityTypes[${index}]`, item.value)
    })
  }

  const spacesData = (items: MultiOption[]) => {
    setValue('industryTypesOfSpaces', [])

    items.forEach((item, index) => {
      setValue(`industryTypesOfSpaces[${index}]`, item.value)
    })
    const other = (element: string) => element === 'Other'
    const otherCheck = getValues('industryTypesOfSpaces').some(other)
    if (otherCheck) {
      setOtherSpaces(true)
      saveProject()
    } else {
      setOtherSpaces(false)
      setValue('industryTypesOfSpacesOther', OtherOptions.otherEmpty)
    }
  }

  const Corporate = (): ReactElement => {
    return (
      <div>
        <div className={!props.projectCreation ? 'flex -mx-2 mb-8 w-1/2' : 'flex -mx-2 mb-8 w-100'}>
          <div className='w-1/2 px-2'>
            <Select {...props} />
            <span className='flex flex-grow justify-end text-error font-medium text-xs'>
              {errors.industry?.name.message}
            </span>
          </div>
          <div className='w-1/2 px-2'>
            <MultiSelect
              label='Business Goals*'
              default={newProjectCreation?.project?.industryDetails?.otherBusinessGoals
                ? newProjectCreation?.project?.industryDetails?.otherBusinessGoals?.values.map((
                  goal: OtherBusinessGoalsEnum,
                  index: number,
                ) => ({
                  display: getNameWithoutUnderScores(goal),
                  key: index,
                  value: goal,
                }))
                : getValues('industryBusinessGoals')
                ? getValues('industryBusinessGoals').map((goal: OtherBusinessGoalsEnum, index: number) => ({
                  display: getNameWithoutUnderScores(goal),
                  key: index,
                  value: goal,
                }))
                : selectedProject.industryDetails?.otherBusinessGoals?.values.map((
                  goal: OtherBusinessGoalsEnum,
                  index: number,
                ) => ({
                  display: getNameWithoutUnderScores(goal),
                  key: index,
                  value: goal,
                }))}
              data={(getBusinessEnumByIndustry(props.selectedValue)).map((
                goal: OtherBusinessGoalsEnum,
                index: number,
              ) => ({
                display: getNameWithoutUnderScores(goal),
                key: index,
                value: goal,
              }))}
              onChange={(items: MultiOption[]) => {
                businessData(items)
              }}
            />
            <span className='flex flex-grow justify-end text-error font-medium text-xs'>
              {errors.industryBusinessGoals?.message}
            </span>
          </div>
        </div>
        {otherBusiness
          && (
            <div className={!props.projectCreation ? 'w-100 py-10 pt-4 px-2 w-1/2' : 'w-100 pt-4 px-2'}>
              <TextInput
                label='What are your other business goals?'
                maxLength={52}
                placeholder='Other business Goals'
                hasAutoFocus
                readOnly={false}
                {...register('industryBusinessGoalsOther')}
              />
              <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                {errors.industryBusinessGoalsOther?.message}
              </span>
            </div>
          )}
        <div className={!props.projectCreation ? 'flex -mx-2 mb-8 w-1/2' : 'flex -mx-2 mb-8 w-100'}>
          <div className='w-1/2 px-2'>
            <Select
              label='Project Scale*'
              default={newProjectCreation
                ? Object.values(ProjectSizeEnum)
                  .filter(o => o === newProjectCreation?.project?.industryDetails?.projectSize?.values[0]).map((
                    goal,
                  ) => ({
                    enum: goal,
                    key: findKeyProjectSizeValue(goal),
                    value: getNameWithoutUnderScores(goal),
                  }))[0] || { enum: '', key: '', value: '' }
                : Object.values(ProjectSizeEnum)
                  .filter(o => o === selectedProject.industryDetails?.projectSize?.values[0]).map((
                    goal,
                  ) => ({
                    enum: goal,
                    key: findKeyProjectSizeValue(goal),
                    value: getNameWithoutUnderScores(goal),
                  }))[0] || { enum: '', key: '', value: '' }}
              data={(Object.values(ProjectSizeEnum)).map((scale, index) => ({
                enum: scale,
                key: index.toString(),
                value: getNameWithoutUnderScores(scale),
              }))}
              onChange={(projectScale: Option) => {
                setValue('industryProjectScale', [projectScale.enum], { shouldDirty: true })
                saveProject()
              }}
            />
            <span className='flex flex-grow justify-end text-error font-medium text-xs'>
              {errors.industryProjectScale?.message}
            </span>
          </div>
          <div className='w-1/2 pt-4 px-2'>
            <TextInput
              readOnly={false}
              label='Project Headcount'
              maxLength={52}
              {...register('headCount')}
            />
          </div>
        </div>
        <div className={!props.projectCreation ? 'flex -mx-2 mb-8 w-1/2' : 'flex -mx-2 mb-8'}>
          <div className='w-1/2 px-2'>
            <Select
              label={props.expLevelFilter
                ? 'Furniture Experience level*'
                : 'Clients Furniture Experience*'}
              default={newProjectCreation
                ? Object.values(ExperienceLevelEnum)
                  .filter(o => o === newProjectCreation?.project?.industryDetails?.experienceLevel?.values[0]).map((
                    goal,
                  ) => ({
                    enum: goal,
                    key: findKeyExperienceValue(goal),
                    value: getNameWithoutUnderScores(goal),
                  }))[0] || { enum: '', key: '', value: '' }
                : Object.values(ExperienceLevelEnum)
                  .filter(o => o === selectedProject.industryDetails?.experienceLevel?.values[0]).map((
                    goal,
                  ) => ({
                    enum: goal,
                    key: findKeyExperienceValue(goal),
                    value: getNameWithoutUnderScores(goal),
                  }))[0] || { enum: '', key: '', value: '' }}
              data={(Object.values(ExperienceLevelEnum) as Array<ExperienceLevelEnum>).map((exp, index) => ({
                enum: exp,
                key: index.toString(),
                value: getNameWithoutUnderScores(exp),
              }))}
              onChange={(experience: Option) => {
                setValue('industryExperienceLevel', [experience.enum], { shouldDirty: true })
                saveProject()
              }}
            />
            <span className='flex flex-grow justify-end text-error font-medium text-xs'>
              {errors.industryExperienceLevel?.message}
            </span>
          </div>
        </div>
        <div className={!props.projectCreation ? 'flex -mx-2 mb-8 w-1/2' : 'flex -mx-2 mb-8'}>
        </div>
        {otherSpaces
          && (
            <div className={!props.projectCreation ? 'w-100 py-10 pt-4 px-2 w-1/2' : 'w-100 pt-4 px-2'}>
              <TextInput
                readOnly={false}
                label='What Other Spaces Do We Need to Consider'
                placeholder='Other Spaces'
                hasAutoFocus
                maxLength={52}
                {...register('industryTypesOfSpacesOther')}
              />
            </div>
          )}
      </div>
    )
  }
  const Technology = (): ReactElement => {
    return (
      <div>
        <div className={!props.projectCreation ? 'flex -mx-2 mb-8 w-1/2' : 'flex -mx-2 mb-8'}>
          <div className='w-1/2 px-2'>
            <Select {...props} />
            <span className='flex flex-grow justify-end text-error font-medium text-xs'>
              {errors.industry?.name.message}
            </span>
          </div>
          <div className='w-1/2 px-2'>
            <MultiSelect
              label='Business Goals*'
              default={newProjectCreation?.project?.industryDetails?.otherBusinessGoals
                ? newProjectCreation?.project?.industryDetails?.otherBusinessGoals?.values.map((
                  goal: OtherBusinessGoalsEnum,
                  index: number,
                ) => ({
                  display: getNameWithoutUnderScores(goal),
                  key: index,
                  value: goal,
                }))
                : getValues('industryBusinessGoals')
                ? getValues('industryBusinessGoals').map((goal: OtherBusinessGoalsEnum, index: number) => ({
                  display: getNameWithoutUnderScores(goal),
                  key: index,
                  value: goal,
                }))
                : selectedProject.industryDetails?.otherBusinessGoals?.values.map((
                  goal: OtherBusinessGoalsEnum,
                  index: number,
                ) => ({
                  display: getNameWithoutUnderScores(goal),
                  key: index,
                  value: goal,
                }))}
              data={(getBusinessEnumByIndustry(props.selectedValue)).map((
                goal: OtherBusinessGoalsEnum,
                index: number,
              ) => ({
                display: getNameWithoutUnderScores(goal),
                key: index,
                value: goal,
              }))}
              onChange={(items: MultiOption[]) => {
                businessData(items)
              }}
            />
            <span className='flex flex-grow justify-end text-error font-medium text-xs'>
              {errors.industryBusinessGoals?.message}
            </span>
          </div>
        </div>
        {otherBusiness
          && (
            <div className={!props.projectCreation ? 'w-1/2 py-10 pt-4 px-2' : 'w-100 pt-4 px-2'}>
              <TextInput
                readOnly={false}
                label='What are your other business goals?'
                placeholder='Other business Goals'
                hasAutoFocus
                maxLength={52}
                {...register('industryBusinessGoalsOther')}
              />
              <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                {errors.industryBusinessGoalsOther?.message}
              </span>
            </div>
          )}
        <div className={!props.projectCreation ? 'flex -mx-2 mb-8 w-1/2' : 'flex -mx-2 mb-8'}>
          <div className='w-1/2 px-2'>
            <Select
              label='Project Scale*'
              default={newProjectCreation
                ? Object.values(ProjectSizeEnum)
                  .filter(o => o === newProjectCreation?.project?.industryDetails?.projectSize?.values[0]).map((
                    goal,
                  ) => ({
                    enum: goal,
                    key: findKeyProjectSizeValue(goal),
                    value: getNameWithoutUnderScores(goal),
                  }))[0] || { enum: '', key: '', value: '' }
                : Object.values(ProjectSizeEnum)
                  .filter(o => o === selectedProject.industryDetails?.projectSize?.values[0]).map((
                    goal,
                  ) => ({
                    enum: goal,
                    key: findKeyProjectSizeValue(goal),
                    value: getNameWithoutUnderScores(goal),
                  }))[0] || { enum: '', key: '', value: '' }}
              data={(Object.values(ProjectSizeEnum) as Array<string>).map((scale, index) => ({
                enum: scale,
                key: index.toString(),
                value: getNameWithoutUnderScores(scale),
              }))}
              onChange={(projectScale: Option) => {
                setValue('industryProjectScale', [projectScale.enum])
                saveProject()
              }}
            />
            <span className='flex flex-grow justify-end text-error font-medium text-xs'>
              {errors.industryProjectScale?.message}
            </span>
          </div>
          <div className='w-1/2 pt-4 px-2'>
            <TextInput
              readOnly={false}
              label='Project Headcount'
              maxLength={52}
              {...register('headCount')}
            />
          </div>
        </div>
        <div className={!props.projectCreation ? 'flex -mx-2 mb-8 w-1/2' : 'flex -mx-2 mb-8'}>
          <div className='w-1/2 px-2'>
            <Select
              label={props.expLevelFilter
                ? 'Experience level*'
                : 'Clients Experience Level*'}
              default={newProjectCreation
                ? Object.values(ExperienceLevelEnum)
                  .filter(o => o === newProjectCreation?.project?.industryDetails?.experienceLevel?.values[0]).map((
                    goal,
                  ) => ({
                    enum: goal,
                    key: findKeyExperienceValue(goal),
                    value: getNameWithoutUnderScores(goal),
                  }))[0] || { enum: '', key: '', value: '' }
                : Object.values(ExperienceLevelEnum)
                  .filter(o => o === selectedProject.industryDetails?.experienceLevel?.values[0]).map((
                    goal,
                  ) => ({
                    enum: goal,
                    key: findKeyExperienceValue(goal),
                    value: getNameWithoutUnderScores(goal),
                  }))[0] || { enum: '', key: '', value: '' }}
              data={(Object.values(ExperienceLevelEnum) as Array<ExperienceLevelEnum>).map((exp, index) => ({
                enum: exp,
                key: index.toString(),
                value: getNameWithoutUnderScores(exp),
              }))}
              onChange={(experience: Option) => {
                setValue('industryExperienceLevel', [experience.enum])
                saveProject()
              }}
            />
            <span className='flex flex-grow justify-end text-error font-medium text-xs'>
              {errors.industryExperienceLevel?.message}
            </span>
          </div>
        </div>
        {otherSpaces
          && (
            <div className={!props.projectCreation ? 'w-1/2 py-10 pt-4 px-2' : 'w-100 pt-4 px-2'}>
              <TextInput
                readOnly={false}
                label='What Other Spaces Do We Need to Consider'
                placeholder='Other Spaces'
                hasAutoFocus
                maxLength={52}
                {...register('industryTypesOfSpacesOther')}
              />
            </div>
          )}
      </div>
    )
  }
  const HealthCare = (): ReactElement => {
    return (
      <div>
        <div className={!props.projectCreation ? 'flex -mx-2 mb-8 w-1/2' : 'flex -mx-2 mb-8'}>
          <div className='w-1/2 px-2'>
            <Select {...props} />
            <span className='flex flex-grow justify-end text-error font-medium text-xs'>
              {errors.industry?.name.message}
            </span>
          </div>
          <div className='w-1/2 px-2'>
            <MultiSelect
              label='Facility Type*'
              default={newProjectCreation?.project?.industryDetails?.otherFacilityTypes
                ? newProjectCreation?.project?.industryDetails?.otherFacilityTypes?.values.map((
                  goal: OtherFacilityTypesEnum,
                  index: number,
                ) => ({
                  display: getNameWithoutUnderScores(goal),
                  key: index,
                  value: goal,
                }))
                : getValues('industryFacilityTypes')
                ? getValues('industryFacilityTypes').map((goal: OtherFacilityTypesEnum, index: number) => ({
                  display: getNameWithoutUnderScores(goal),
                  key: index,
                  value: goal,
                }))
                : selectedProject.industryDetails?.otherFacilityTypes?.values.map((
                  goal: OtherFacilityTypesEnum,
                  index: number,
                ) => ({
                  display: getNameWithoutUnderScores(goal),
                  key: index,
                  value: goal,
                }))}
              data={Object.values(OtherFacilityTypesEnum).map((goal, index) => ({
                display: getNameWithoutUnderScores(goal),
                key: index,
                value: goal,
              }))}
              onChange={(items: MultiOption[]) => {
                facilityData(items)
              }}
            />
            <span className='flex flex-grow justify-end text-error font-medium text-xs'>
              {errors.industryFacilityTypes?.message}
            </span>
          </div>
        </div>

        <div className={!props.projectCreation ? 'flex -mx-2 mb-8 w-1/2' : 'flex -mx-2 mb-8'}>
          <div className='w-1/2 px-2'>
            <MultiSelect
              label='Administrative Goals*'
              default={newProjectCreation?.project?.industryDetails?.otherAdministrativeGoals
                ? newProjectCreation?.project?.industryDetails?.otherAdministrativeGoals?.values.map((
                  goal: OtherAdministrativeGoalsEnum,
                  index: number,
                ) => ({
                  display: getNameWithoutUnderScores(goal),
                  key: index,
                  value: goal,
                }))
                : getValues('industryAdministrativeGoals')
                ? getValues('industryAdministrativeGoals').map((goal: OtherAdministrativeGoalsEnum, index: number) => ({
                  display: getNameWithoutUnderScores(goal),
                  key: index,
                  value: goal,
                }))
                : selectedProject.industryDetails?.otherAdministrativeGoals?.values.map((
                  goal: OtherAdministrativeGoalsEnum,
                  index: number,
                ) => ({
                  display: getNameWithoutUnderScores(goal),
                  key: index,
                  value: goal,
                }))}
              data={getAdminEnumByIndustry(props.selectedValue).map((
                goal: OtherAdministrativeGoalsEnum,
                index: number,
              ) => ({
                display: getNameWithoutUnderScores(goal),
                key: index,
                value: goal,
              }))}
              onChange={(items: MultiOption[]) => {
                adminGoalData(items)
              }}
            />
            <span className='flex flex-grow justify-end text-error font-medium text-xs'>
              {errors.industryFacilityTypes?.message}
            </span>
          </div>
          <div className='w-1/2 px-2'>
            <MultiSelect
              label='Clinical Goals*'
              default={newProjectCreation?.project?.industryDetails?.otherClinicalGoals
                ? newProjectCreation?.project?.industryDetails?.otherClinicalGoals?.values.map((
                  goal: OtherClinicalGoalsEnum,
                  index: number,
                ) => ({
                  display: getNameWithoutUnderScores(goal),
                  key: index,
                  value: goal,
                }))
                : getValues('industryClinicalGoals')
                ? getValues('industryClinicalGoals').map((goal: OtherClinicalGoalsEnum, index: number) => ({
                  display: getNameWithoutUnderScores(goal),
                  key: index,
                  value: goal,
                }))
                : selectedProject.industryDetails?.otherClinicalGoals?.values.map((
                  goal: OtherClinicalGoalsEnum,
                  index: number,
                ) => ({
                  display: getNameWithoutUnderScores(goal),
                  key: index,
                  value: goal,
                }))}
              data={getClinicalEnumByIndustry(props.selectedValue).map((
                goal: OtherClinicalGoalsEnum,
                index: number,
              ) => ({
                display: getNameWithoutUnderScores(goal),
                key: index,
                value: goal,
              }))}
              onChange={(items: MultiOption[]) => {
                clinicalData(items)
              }}
            />
            <span className='flex flex-grow justify-end text-error font-medium text-xs'>
              {errors.industryFacilityTypes?.message}
            </span>
          </div>
        </div>
        {otherAdminGoals
          && (
            <div className={!props.projectCreation ? 'w-1/2 py-10 pt-4 px-2' : 'w-100 pt-4 px-2'}>
              <TextInput
                readOnly={false}
                label='What are your other administrative goals?'
                placeholder='Other administrative Goals'
                hasAutoFocus
                maxLength={52}
                {...register('industryAdministrativeGoalsOther')}
              />
              <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                {errors.industryAdministrativeGoalsOther?.message}
              </span>
            </div>
          )}
        {otherClinical
          && (
            <div className={!props.projectCreation ? 'w-1/2 py-10 pt-4 px-2' : 'w-100 pt-4 px-2'}>
              <TextInput
                readOnly={false}
                label='What are your other clinical goals?'
                placeholder='Other Clinical Goals'
                hasAutoFocus
                maxLength={52}
                {...register('industryClinicalGoalsOther')}
              />
              <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                {errors.industryAdministrativeGoalsOther?.message}
              </span>
            </div>
          )}
        <div className={!props.projectCreation ? 'flex -mx-2 mb-8 w-1/2' : 'flex -mx-2 mb-8'}>
          <div className='w-1/2 px-2'>
            <Select
              label='Project Scale*'
              default={newProjectCreation
                ? Object.values(ProjectSizeEnum)
                  .filter(o => o === newProjectCreation?.project?.industryDetails?.projectSize?.values[0]).map((
                    goal,
                  ) => ({
                    enum: goal,
                    key: findKeyProjectSizeValue(goal),
                    value: getNameWithoutUnderScores(goal),
                  }))[0] || { enum: '', key: '', value: '' }
                : Object.values(ProjectSizeEnum)
                  .filter(o => o === selectedProject.industryDetails?.projectSize?.values[0]).map((
                    goal,
                  ) => ({
                    enum: goal,
                    key: findKeyProjectSizeValue(goal),
                    value: getNameWithoutUnderScores(goal),
                  }))[0] || { enum: '', key: '', value: '' }}
              data={(Object.values(ProjectSizeEnum) as Array<string>).map((scale, index) => ({
                enum: scale,
                key: index.toString(),
                value: getNameWithoutUnderScores(scale),
              }))}
              onChange={(projectScale: Option) => {
                setValue('industryProjectScale', [projectScale.enum])
                saveProject()
              }}
            />
            <span className='flex flex-grow justify-end text-error font-medium text-xs'>
              {errors.industryProjectScale?.message}
            </span>
          </div>
          <div className='w-1/2 pt-4 px-2'>
            <TextInput
              readOnly={false}
              label='Project Headcount'
              maxLength={52}
              {...register('headCount')}
            />
          </div>
        </div>
        <div className={!props.projectCreation ? 'flex -mx-2 mb-8 w-1/2' : 'flex -mx-2 mb-8'}>
          <div className='w-1/2 px-2'>
            <Select
              label={props.expLevelFilter
                ? 'Experience level*'
                : 'Clients Experience Level*'}
              default={newProjectCreation
                ? Object.values(ExperienceLevelEnum)
                  .filter(o => o === newProjectCreation?.project?.industryDetails?.experienceLevel?.values[0]).map((
                    goal,
                  ) => ({
                    enum: goal,
                    key: findKeyExperienceValue(goal),
                    value: getNameWithoutUnderScores(goal),
                  }))[0] || { enum: '', key: '', value: '' }
                : Object.values(ExperienceLevelEnum)
                  .filter(o => o === selectedProject.industryDetails?.experienceLevel?.values[0]).map((
                    goal,
                  ) => ({
                    enum: goal,
                    key: findKeyExperienceValue(goal),
                    value: getNameWithoutUnderScores(goal),
                  }))[0] || { enum: '', key: '', value: '' }}
              data={(Object.values(ExperienceLevelEnum) as Array<ExperienceLevelEnum>).map((exp, index) => ({
                enum: exp,
                key: index.toString(),
                value: getNameWithoutUnderScores(exp),
              }))}
              onChange={(experience: Option) => {
                setValue('industryExperienceLevel', [experience.enum])
                saveProject()
              }}
            />
            <span className='flex flex-grow justify-end text-error font-medium text-xs'>
              {errors.industryExperienceLevel?.message}
            </span>
          </div>
        </div>
        {otherSpaces
          && (
            <div className={!props.projectCreation ? 'w-1/2 py-10 pt-4 px-2' : 'w-100 pt-4 px-2'}>
              <TextInput
                readOnly={false}
                label='What Other Spaces Do We Need to Consider'
                placeholder='Other Spaces'
                hasAutoFocus
                maxLength={52}
                {...register('industryTypesOfSpacesOther')}
              />
            </div>
          )}
      </div>
    )
  }
  const Education = (): ReactElement => {
    return (
      <div>
        <div className={!props.projectCreation ? 'flex -mx-2 mb-8 w-1/2' : 'flex -mx-2 mb-8'}>
          <div className='w-1/2 px-2'>
            <Select {...props} />
            <span className='flex flex-grow justify-end text-error font-medium text-xs'>
              {errors.industry?.name.message}
            </span>
          </div>
          <div className='w-1/2 px-2'>
            <MultiSelect
              label='Business Goals*'
              default={newProjectCreation?.project?.industryDetails?.otherBusinessGoals
                ? newProjectCreation?.project?.industryDetails?.otherBusinessGoals?.values.map((
                  goal: OtherBusinessGoalsEnum,
                  index: number,
                ) => ({
                  display: getNameWithoutUnderScores(goal),
                  key: index,
                  value: goal,
                }))
                : getValues('industryBusinessGoals')
                ? getValues('industryBusinessGoals').map((goal: OtherBusinessGoalsEnum, index: number) => ({
                  display: getNameWithoutUnderScores(goal),
                  key: index,
                  value: goal,
                }))
                : selectedProject.industryDetails?.otherBusinessGoals?.values.map((
                  goal: OtherBusinessGoalsEnum,
                  index: number,
                ) => ({
                  display: getNameWithoutUnderScores(goal),
                  key: index,
                  value: goal,
                }))}
              data={(getBusinessEnumByIndustry(props.selectedValue)).map((
                goal: OtherBusinessGoalsEnum,
                index: number,
              ) => ({
                display: getNameWithoutUnderScores(goal),
                key: index,
                value: goal,
              }))}
              onChange={(items: MultiOption[]) => {
                businessData(items)
              }}
            />
            <span className='flex flex-grow justify-end text-error font-medium text-xs'>
              {errors.industryBusinessGoals?.message}
            </span>
          </div>
        </div>
        {otherBusiness
          && (
            <div className={!props.projectCreation ? 'w-1/2 py-10 pt-4 px-2' : 'w-100 pt-4 px-2'}>
              <TextInput
                hasAutoFocus
                label='What are your other business goals?'
                maxLength={52}
                placeholder='Other business Goals'
                readOnly={false}
                {...register('industryBusinessGoalsOther')}
              />
              <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                {errors.industryBusinessGoalsOther?.message}
              </span>
            </div>
          )}
        <div className={!props.projectCreation ? 'flex -mx-2 mb-8 w-1/2' : 'flex -mx-2 mb-8'}>
          <div className='w-1/2 px-2'>
            <Select
              label='Project Scale*'
              default={newProjectCreation
                ? Object.values(ProjectSizeEnum)
                  .filter(o => o === newProjectCreation?.project?.industryDetails?.projectSize?.values[0]).map((
                    goal,
                  ) => ({
                    enum: goal,
                    key: findKeyProjectSizeValue(goal),
                    value: getNameWithoutUnderScores(goal),
                  }))[0] || { enum: '', key: '', value: '' }
                : Object.values(ProjectSizeEnum)
                  .filter(o => o === selectedProject.industryDetails?.projectSize?.values[0]).map((
                    goal,
                  ) => ({
                    enum: goal,
                    key: findKeyProjectSizeValue(goal),
                    value: getNameWithoutUnderScores(goal),
                  }))[0] || { enum: '', key: '', value: '' }}
              data={(Object.values(ProjectSizeEnum)).map((scale, index) => ({
                enum: scale,
                key: index.toString(),
                value: getNameWithoutUnderScores(scale),
              }))}
              onChange={(projectScale: Option) => {
                setValue('industryProjectScale', [projectScale.enum])
                saveProject()
              }}
            />
            <span className='flex flex-grow justify-end text-error font-medium text-xs'>
              {errors.industryProjectScale?.message}
            </span>
          </div>
          <div className='w-1/2 pt-4 px-2'>
            <TextInput
              readOnly={false}
              label='Project Headcount'
              maxLength={52}
              {...register('headCount')}
            />
          </div>
        </div>
        <div className={!props.projectCreation ? 'flex -mx-2 mb-8 w-1/2' : 'flex -mx-2 mb-8'}>
          <div className='w-1/2 px-2'>
            <Select
              label={props.expLevelFilter
                ? 'Experience level*'
                : 'Clients Experience Level*'}
              default={newProjectCreation
                ? Object.values(ExperienceLevelEnum)
                  .filter(o => o === newProjectCreation?.project?.industryDetails?.experienceLevel?.values[0]).map((
                    goal,
                  ) => ({
                    enum: goal,
                    key: findKeyExperienceValue(goal),
                    value: getNameWithoutUnderScores(goal),
                  }))[0] || { enum: '', key: '', value: '' }
                : Object.values(ExperienceLevelEnum)
                  .filter(o => o === selectedProject.industryDetails?.experienceLevel?.values[0]).map((
                    goal,
                  ) => ({
                    enum: goal,
                    key: findKeyExperienceValue(goal),
                    value: getNameWithoutUnderScores(goal),
                  }))[0] || { enum: '', key: '', value: '' }}
              data={(Object.values(ExperienceLevelEnum) as Array<ExperienceLevelEnum>).map((exp, index) => ({
                enum: exp,
                key: index.toString(),
                value: getNameWithoutUnderScores(exp),
              }))}
              onChange={(experience: Option) => {
                setValue('industryExperienceLevel', [experience.enum])
                saveProject()
              }}
            />
            <span className='flex flex-grow justify-end text-error font-medium text-xs'>
              {errors.industryExperienceLevel?.message}
            </span>
          </div>
        </div>
        {otherSpaces
          && (
            <div className={!props.projectCreation ? 'w-1/2 py-10 pt-4 px-2' : 'w-100 pt-4 px-2'}>
              <TextInput
                readOnly={false}
                label='What Other Spaces Do We Need to Consider'
                placeholder='Other Spaces'
                hasAutoFocus
                maxLength={52}
                {...register('industryTypesOfSpacesOther')}
              />
            </div>
          )}
      </div>
    )
  }
  const Government = (): ReactElement => {
    return (
      <div>
        <div className={!props.projectCreation ? 'flex -mx-2 mb-8 w-1/2' : 'flex -mx-2 mb-8'}>
          <div className='w-1/2 px-2'>
            <Select {...props} />
            <span className='flex flex-grow justify-end text-error font-medium text-xs'>
              {errors.industry?.name.message}
            </span>
          </div>
          <div className='w-1/2 px-2'>
            <MultiSelect
              label='Business Goals*'
              default={newProjectCreation?.project?.industryDetails?.otherBusinessGoals
                ? newProjectCreation?.project?.industryDetails?.otherBusinessGoals?.values.map((
                  goal: OtherBusinessGoalsEnum,
                  index: number,
                ) => ({
                  display: getNameWithoutUnderScores(goal),
                  key: index,
                  value: goal,
                }))
                : getValues('industryBusinessGoals')
                ? getValues('industryBusinessGoals').map((goal: OtherBusinessGoalsEnum, index: number) => ({
                  display: getNameWithoutUnderScores(goal),
                  key: index,
                  value: goal,
                }))
                : selectedProject.industryDetails?.otherBusinessGoals?.values.map((
                  goal: OtherBusinessGoalsEnum,
                  index: number,
                ) => ({
                  display: getNameWithoutUnderScores(goal),
                  key: index,
                  value: goal,
                }))}
              data={(getBusinessEnumByIndustry(props.selectedValue)).map((
                goal: OtherBusinessGoalsEnum,
                index: number,
              ) => ({
                display: getNameWithoutUnderScores(goal),
                key: index,
                value: goal,
              }))}
              onChange={(items: MultiOption[]) => {
                businessData(items)
              }}
            />
            <span className='flex flex-grow justify-end text-error font-medium text-xs'>
              {errors.industryBusinessGoals?.message}
            </span>
          </div>
        </div>
        {otherBusiness
          && (
            <div className={!props.projectCreation ? 'w-1/2 py-10 pt-4 px-2' : 'w-100 pt-4 px-2'}>
              <TextInput
                hasAutoFocus
                label='What are your other business goals?'
                maxLength={52}
                placeholder='Other business Goals'
                readOnly={false}
                {...register('industryBusinessGoalsOther')}
              />
              <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                {errors.industryBusinessGoalsOther?.message}
              </span>
            </div>
          )}
        <div className={!props.projectCreation ? 'flex -mx-2 mb-8 w-1/2' : 'flex -mx-2 mb-8'}>
          <div className='w-1/2 px-2'>
            <Select
              label='Project Scale*'
              default={newProjectCreation
                ? Object.values(ProjectSizeEnum)
                  .filter(o => o === newProjectCreation?.project?.industryDetails?.projectSize?.values[0]).map((
                    goal,
                  ) => ({
                    enum: goal,
                    key: findKeyProjectSizeValue(goal),
                    value: getNameWithoutUnderScores(goal),
                  }))[0] || { enum: '', key: '', value: '' }
                : Object.values(ProjectSizeEnum)
                  .filter(o => o === selectedProject.industryDetails?.projectSize?.values[0]).map((
                    goal,
                  ) => ({
                    enum: goal,
                    key: findKeyProjectSizeValue(goal),
                    value: getNameWithoutUnderScores(goal),
                  }))[0] || { enum: '', key: '', value: '' }}
              data={(Object.values(ProjectSizeEnum)).map((scale, index) => ({
                enum: scale,
                key: index.toString(),
                value: getNameWithoutUnderScores(scale),
              }))}
              onChange={(projectScale: Option) => {
                setValue('industryProjectScale', [projectScale.enum])
                saveProject()
              }}
            />
            <span className='flex flex-grow justify-end text-error font-medium text-xs'>
              {errors.industryProjectScale?.message}
            </span>
          </div>
          <div className='w-1/2 pt-4 px-2'>
            <TextInput
              readOnly={false}
              label='Project Headcount'
              maxLength={52}
              {...register('headCount')}
            />
          </div>
        </div>
        <div className={!props.projectCreation ? 'flex -mx-2 mb-8 w-1/2' : 'flex -mx-2 mb-8'}>
          <div className='w-1/2 px-2'>
            <Select
              label={props.expLevelFilter
                ? 'Experience level*'
                : 'Clients Experience Level*'}
              default={newProjectCreation
                ? Object.values(ExperienceLevelEnum)
                  .filter(o => o === newProjectCreation?.project?.industryDetails?.experienceLevel?.values[0]).map((
                    goal,
                  ) => ({
                    enum: goal,
                    key: findKeyExperienceValue(goal),
                    value: getNameWithoutUnderScores(goal),
                  }))[0] || { enum: '', key: '', value: '' }
                : Object.values(ExperienceLevelEnum)
                  .filter(o => o === selectedProject.industryDetails?.experienceLevel?.values[0]).map((
                    goal,
                  ) => ({
                    enum: goal,
                    key: findKeyExperienceValue(goal),
                    value: getNameWithoutUnderScores(goal),
                  }))[0] || { enum: '', key: '', value: '' }}
              data={(Object.values(ExperienceLevelEnum) as Array<ExperienceLevelEnum>).map((exp, index) => ({
                enum: exp,
                key: index.toString(),
                value: getNameWithoutUnderScores(exp),
              }))}
              onChange={(experience: Option) => {
                setValue('industryExperienceLevel', [experience.enum])
                saveProject()
              }}
            />
            <span className='flex flex-grow justify-end text-error font-medium text-xs'>
              {errors.industryExperienceLevel?.message}
            </span>
          </div>
        </div>
        {otherSpaces
          && (
            <div className={!props.projectCreation ? 'w-1/2 py-10 pt-4 px-2' : 'w-100 pt-4 px-2'}>
              <TextInput
                readOnly={false}
                label='What Other Spaces Do We Need to Consider'
                placeholder='Other Spaces'
                hasAutoFocus
                maxLength={52}
                {...register('industryTypesOfSpacesOther')}
              />
            </div>
          )}
      </div>
    )
  }
  const WorkFromHome = (): ReactElement => {
    return (
      <div>
        <div className={!props.projectCreation ? 'flex -mx-2 mb-8 w-1/2' : 'flex -mx-2 mb-8'}>
          <div className='w-1/2 px-2'>
            <Select {...props} />
          </div>
        </div>
        <div className={!props.projectCreation ? 'w-1/2 mb-8' : 'w-100 mb-8'}>
          <Select
            label='What best describes your home type?*'
            default={newProjectCreation
              ? Object.values(OtherHomeTypeEnum)
                .filter(o => o === newProjectCreation?.project?.industryDetails?.otherHomeType?.values[0]).map((
                  goal,
                ) => ({
                  enum: goal,
                  key: findKeyHomeTypeValue(goal),
                  value: getNameWithoutUnderScores(goal),
                }))[0] || { enum: '', key: '', value: '' }
              : Object.values(OtherHomeTypeEnum)
                .filter(o => o === selectedProject.industryDetails?.otherHomeType?.values[0]).map((
                  goal,
                ) => ({
                  enum: goal,
                  key: findKeyHomeTypeValue(goal),
                  value: getNameWithoutUnderScores(goal),
                }))[0] || { enum: '', key: '', value: '' }}
            data={(Object.values(OtherHomeTypeEnum) as Array<string>).map((exp, index) => ({
              enum: exp,
              key: index.toString(),
              value: getNameWithoutUnderScores(exp),
            }))}
            onChange={(experience: Option) => {
              setValue('industryHomeType', [experience.enum])
              saveProject()
              if (getValues('industryHomeType') == OtherHomeTypeEnum.Other) {
                setOtherHomeType(true)
              } else {
                setOtherHomeType(false)
              }
            }}
          />
          <span className='flex flex-grow justify-end text-error font-medium text-xs'>
            {errors.industryHomeType?.message}
          </span>
        </div>
        {otherHomeType
          && (
            <div className={!props.projectCreation ? 'w-1/2 py-10 mb-8' : 'w-100 mb-8'}>
              <TextInput
                readOnly={false}
                label='What else best decribes your home type?'
                placeholder='Other Home Type'
                hasAutoFocus
                maxLength={52}
                {...register('industryHomeTypeOther')}
              />
            </div>
          )}
        <div className={!props.projectCreation ? 'w-1/2 mb-8' : 'w-100 mb-8'}>
          <Select
            label='What best describes your home workspace?*'
            default={newProjectCreation
              ? Object.values(OtherWorkspaceTypeEnum)
                .filter(o => o === newProjectCreation?.project?.industryDetails?.otherWorkspaceType?.values[0]).map((
                  goal,
                ) => ({
                  enum: goal,
                  key: findKeyWorkSpaceValue(goal),
                  value: getNameWithoutUnderScores(goal),
                }))[0] || { enum: '', key: '', value: '' }
              : Object.values(OtherWorkspaceTypeEnum)
                .filter(o => o === selectedProject.industryDetails?.otherWorkspaceType?.values[0]).map((
                  goal,
                ) => ({
                  enum: goal,
                  key: findKeyWorkSpaceValue(goal),
                  value: getNameWithoutUnderScores(goal),
                }))[0] || { enum: '', key: '', value: '' }}
            data={(Object.values(OtherWorkspaceTypeEnum) as Array<string>).map((exp, index) => ({
              enum: exp,
              key: index.toString(),
              value: getNameWithoutUnderScores(exp),
            }))}
            onChange={(experience: Option) => {
              setValue('industryWorkSpace', [experience.enum])
              saveProject()
              if (getValues('industryWorkSpace') == OtherHomeTypeEnum.Other) {
                setOtherWorkSpace(true)
              } else {
                setOtherWorkSpace(false)
              }
            }}
          />
          <span className='flex flex-grow justify-end text-error font-medium text-xs'>
            {errors.industryWorkSpace?.message}
          </span>
        </div>
        {otherWorkSpace
          && (
            <div className={!props.projectCreation ? 'w-1/2 py-10 mb-8' : 'w-100 mb-8'}>
              <TextInput
                readOnly={false}
                label='What else best describes your home workspace?'
                placeholder='Other Workspace Type'
                hasAutoFocus
                maxLength={52}
                {...register('industryWorkSpaceOther')}
              />
            </div>
          )}
        <div className={!props.projectCreation ? 'w-1/2 mb-8' : 'w-100 mb-8'}>
          <MultiSelect
            label='What home office elements should we consider?*'
            default={newProjectCreation?.project?.industryDetails?.otherElements
              ? newProjectCreation?.project?.industryDetails?.otherElements?.values.map((
                goal: OtherElementsEnum,
                index: number,
              ) => ({
                display: getNameWithoutUnderScores(goal),
                key: index,
                value: goal,
              }))
              : getValues('industryOfficeElements')
              ? getValues('industryOfficeElements').map((goal: OtherElementsEnum, index: number) => ({
                display: getNameWithoutUnderScores(goal),
                key: index,
                value: goal,
              }))
              : selectedProject.industryDetails?.otherElements?.values.map((
                goal: OtherElementsEnum,
                index: number,
              ) => ({
                display: getNameWithoutUnderScores(goal),
                key: index,
                value: goal,
              }))}
            data={Object.values(OtherElementsEnum).map((space, index) => ({
              display: getNameWithoutUnderScores(space),
              key: index,
              value: space,
            }))}
            onChange={(items: MultiOption[]) => {
              elementsData(items)
            }}
          />
          <span className='flex flex-grow justify-end text-error font-medium text-xs'>
            {errors.industryOfficeElements?.message}
          </span>
        </div>
        {otherElements
          && (
            <div className={!props.projectCreation ? 'w-1/2 py-10 mb-8' : 'w-100 mb-8'}>
              <TextInput
                readOnly={false}
                label='What other home office elements should we consider?'
                placeholder='Other Elements'
                hasAutoFocus
                maxLength={52}
                {...register('industryOfficeElementsOther')}
              />
            </div>
          )}
      </div>
    )
  }

  return <IndustrySelection />
}
