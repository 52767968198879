import React from 'react'
import { Modal } from 'theorem-lib/src/components/molecules/Modal/Modal'
import { useActions, useAppState } from '../../../presenter'

export const LicensesLimitModal = () => {
  const { currentModal, tenantInfo } = useAppState()
  const { displayModalAction } = useActions()

  const onClose = () => {
    displayModalAction('None')
  }

  const MailTo = (email: string, subject: string, body: string) => {
    window.open(`mailto:${email}?subject=${subject}&body=${body}`)
  }

  const PurchaseMoreBodyText =
    'Hello, I am writing to request additional FOLIO licenses. Number of additional licenses requested: XX'

  return (
    <Modal
      isOpen={currentModal === 'LicensesLimitModal'}
      onClose={onClose}
      contentStyle={{
        width: '466px',
      }}
    >
      <div className='flex flex-col justify-center items-center w-full[80vw] sm:w-full'>
        <div className='text-primary-100 text-xl font-bold mt-4 mb-4 text-center'>
          You&apos;ve reached your license limit.
        </div>
        <div className='px-4 text-primary-300 text-base font-normal text-center mb-4'>
          <p className='mb-4'>Contact the FOLIO Team to purchase more licenses or for help with this message.</p>
        </div>
        <div className='flex flex-col items-center'>
          <button
            type='button'
            className='button cta mx-2 mb-3'
            onClick={() => {
              MailTo(
                'customercare@foliosi.com',
                tenantInfo.tenantName + ' - Request for More Licenses',
                PurchaseMoreBodyText,
              ), onClose()
            }}
          >
            Purchase Licenses
          </button>
          <a
            className='text-[#c04184] text-sm font-bold hover:text-pink-500 '
            aria-label='Close'
            onClick={() => {
              MailTo('customercare@foliosi.com', tenantInfo.tenantName + ' - FOLIO License Support Request', ''),
                onClose()
            }}
          >
            Contact Customer Care
          </a>
        </div>
      </div>
    </Modal>
  )
}
