import React from 'react'
import { Modal } from 'theorem-lib/src/components/molecules/Modal/Modal'
import { useActions, useAppState } from '../../../presenter'

export function ProjectDeleteModal() {
  const { currentModal } = useAppState()
  const { deleteProjectAction, displayModalAction, displaySlideOutAction, redirectAction } = useActions()

  const onClose = () => displayModalAction('None')
  const onDelete = async () => {
    await deleteProjectAction()
    onClose()
    displaySlideOutAction('None')
    redirectAction('/projects')
  }

  return (
    <Modal
      isOpen={currentModal === 'ProjectDeleteModal'}
      onClose={onClose}
    >
      <div className='flex flex-col justify-center items-center w-[80vw] sm:w-[558px]'>
        <div className='text-primary-100 text-xl font-semibold mb-2 text-center'>
          Are you sure you want to delete this project?
        </div>
        <div className='text-primary-300 text-base font-normal text-center mb-6'>
          Deleting this project will permanently remove the project and all project data, including spaces, items,
          budget, and tasks. You can&apos;t undo this once it&apos;s confirmed.
        </div>
        <div className='flex flex-row'>
          <button type='button' className='button mx-2' onClick={onClose}>Cancel</button>
          <button type='button' className='button cta mx-2' onClick={onDelete}>Delete</button>
        </div>
      </div>
    </Modal>
  )
}
