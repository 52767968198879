import React from 'react'
import { SelectedProjectRole } from 'theorem-lib/src/entities/enums/SelectedProjectRole'
import { useAppState } from '../../../presenter'
import { ImageryTemplate } from './ImageryTemplate'

export const ProjectImagery = () => {
  const { selectedProjectRole } = useAppState()

  const ImageryScreen = () => {
    switch (selectedProjectRole) {
      case SelectedProjectRole.Admin:
      case SelectedProjectRole.Lead:
        return <ImageryTemplate />
      case SelectedProjectRole.Editor:
        return (
          <ImageryTemplate
            hideHeaderDropDown
          />
        )
      case SelectedProjectRole.Collaborator:
        return (
          <ImageryTemplate
            hideHeaderDropDown
            readOnlyProjectStage
          />
        )
      default:
        return <ImageryTemplate />
    }
  }
  return <ImageryScreen />
}
