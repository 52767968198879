import React from 'react'
import { useAppState } from '../../../presenter'
import { ProjectSlideOutTemplate } from './ProjectSlideOutTemplate'

export const ManageProjectSlideOut = () => {
  const { isAdmin, isMember, isParticipant } = useAppState()

  return (
    <>
      {isAdmin
        ? <ProjectSlideOutTemplate />
        : isMember
        ? <ProjectSlideOutTemplate />
        : isParticipant
        ? <ProjectSlideOutTemplate participantExpLevel hideClientInvite />
        : <>Invalid Role</>}
    </>
  )
}
