import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/solid'
import React, { Fragment, useState } from 'react'

export type MultiOption = {
  key?: number
  display: string
  value: any
}

export type SelectProps = {
  data: MultiOption[]
  default?: MultiOption[]
  label: string
  onChange: (value: MultiOption[]) => void
  readonly?: boolean
}

export default function MultiSelect(props: SelectProps) {
  const [selected, setSelected] = useState<(MultiOption)[]>(
    props.data.filter(o => props.default?.some(k => o.value === k.value)) ?? [],
  )

  const onChange = (value: MultiOption[]) => {
    setSelected(value)
    props.onChange(value)
  }

  return (
    <div className='w-full border-b border-primary-400'>
      <Listbox value={selected} onChange={onChange} multiple>
        <div className='relative'>
          <Listbox.Button
            name='select'
            className='relative w-full py-2 pl-2 pr-10 text-left bg-white rounded-lg cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-black focus-visible:ring-offset-2 sm:text-xs'
          >
            {selected.length >= 1
              ? <label className='text-xs' htmlFor='select'>{props.label}</label>
              : <label className='mt-4 text-base' htmlFor='select'>{props.label}</label>}
            <span className={`block truncate font-light text-base`}>
              {selected.length >= 1
                ? selected[0].display + ' +' + (selected.length - 1)
                : ''}
            </span>
            <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
              <ChevronDownIcon
                className='h-5 w-5 text-gray-400'
                aria-hidden='true'
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave='transition ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Listbox.Options className='absolute w-full py-1 mt-1 overflow-auto text-base bg-grey-400 rounded-lg shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-50'>
              {props.data.map((goal, goalIdx) => (
                <Listbox.Option
                  key={goalIdx}
                  className={({ active }) =>
                    `cursor-default select-none relative py-2 pl-10 pr-4 ${
                      active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                    }`}
                  value={goal}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}
                      >
                        {goal.display}
                      </span>
                      {selected
                        ? (
                          <span className='absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600'>
                            <CheckIcon className='h-5 w-5' aria-hidden='true' />
                          </span>
                        )
                        : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  )
}
