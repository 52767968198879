import { yupResolver } from '@hookform/resolvers/yup'
import React, { Fragment, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { CreateTasklistInput, ProjectMemberStatusEnum } from '../../../generated/api'
import { useActions, useAppState } from '../../../presenter'
import { ManageTasklistFormValidator } from '../../../validators/manageTasklistFormValidator'
import { SlideOut } from '../../atoms/SlideOut/SlideOut'
import { SlideOutFooter } from '../../atoms/SlideOutFooter/SlideoutFooter'
import TextInput from '../../atoms/TextInput/TextInput'
import { ListFilter } from '../../molecules/ListFilter/ListFilter'
import { ChecklistFormType } from './ManageTasklistSlideOut'

export function AddCustomTasklistSlideOut() {
  const {
    createTasklistAction,
    displayModalAction,
    displaySlideOutAction,
  } = useActions()
  const { selectedProject } = useAppState()
  const [isAssignedUserIdsFilterOpen, setIsAssignedUserIdsFilterOpen] = useState(false)
  const { control, formState: { errors, isDirty }, getValues, handleSubmit, register } = useForm<ChecklistFormType>(
    {
      defaultValues: {
        assignedUserIds: [],
        description: '',
        dueDate: '',
        name: '',
      },
      resolver: yupResolver(ManageTasklistFormValidator),
    },
  )

  const onSubmit = (values: ChecklistFormType) => {
    if (!selectedProject.id) {
      return
    }
    const payload: CreateTasklistInput = {
      assignedUserIds: values.assignedUserIds.map(u => u.id),
      associatedObjectId: selectedProject.id,
      description: values.description,
      dueDate: values.dueDate,
      name: values.name,
    }
    createTasklistAction(payload)
  }

  const userSelectData = () => {
    if (selectedProject && selectedProject.members) {
      return selectedProject.members.filter(m => m.status === ProjectMemberStatusEnum.Accepted).map(member => {
        return {
          id: member.id,
          imageUrl: member.avatarUrl || '',
          isSelected: !!getValues('assignedUserIds').find((obj) => obj.id === member.id),
          value: `${member.firstName} ${member.lastName}`,
        }
      })
    }
    return []
  }

  return (
    <SlideOut
      title='Create New List'
      onClose={() =>
        isDirty
          ? displayModalAction('CancelTasklistEditModal')
          : displaySlideOutAction('None')}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='mb-4'>
          <TextInput label='Checklist Name' {...register('name')} maxLength={52} />
          <span className='flex flex-grow justify-end text-error font-medium text-xs'>{errors.name?.message}</span>
        </div>
        <div className='mb-4'>
          <TextInput label='Description' {...register('description')} />
          <span className='flex flex-grow justify-end text-error font-medium text-xs'>
            {errors.description?.message}
          </span>
        </div>
        <div className='flex flex-row'>
          <div className='flex flex-col mr-2 flex-grow'>
            <div className='mb-6 mt-2'>
              <Controller
                name='assignedUserIds'
                control={control}
                render={({ field, fieldState }) => (
                  <Fragment>
                    <div className='border-primary-400 border-b'>
                      <ListFilter
                        label='Assign to'
                        items={userSelectData()}
                        onChange={(items) => field.onChange(items)}
                        isOpen={isAssignedUserIdsFilterOpen}
                        onClose={() => setIsAssignedUserIdsFilterOpen(false)}
                        onClick={() => {
                          setIsAssignedUserIdsFilterOpen(true)
                        }}
                        hasBorder={false}
                        className='!rounded-none'
                      />
                    </div>
                    <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                      {fieldState.error?.message}
                    </span>
                  </Fragment>
                )}
              />
            </div>
          </div>
          <div className='flex flex-col flex-grow'>
            <div className='mb-6 mt-2'>
              <div className='flex flex-row min-w-[150px] grow h-14 px-4 py-2 border-primary-400 border-b cursor-pointer justify-between'>
                <div className='flex flex-col grow'>
                  <div className='text-primary-400 text-xs'>Due Date</div>
                  <div className='text-primary-100 text-base'>
                    <input
                      type='date'
                      className='p-0 border-none focus:ring-0 w-full'
                      {...register('dueDate')}
                    />
                  </div>
                </div>
              </div>
              <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                {errors.dueDate?.message}
              </span>
            </div>
          </div>
        </div>
        <SlideOutFooter>
          <button className='bg-cta-200 text-white p-3 rounded-lg font-semibold mr-2' type='submit'>
            Create
          </button>
        </SlideOutFooter>
      </form>
    </SlideOut>
  )
}
