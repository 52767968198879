import React from 'react'
import { Modal } from 'theorem-lib/src/components/molecules/Modal/Modal'
import { useActions, useAppState } from '../../../presenter'

export function DeleteTaskModal() {
  const { currentModal } = useAppState()
  const { deleteTaskAction, displayModalAction } = useActions()

  const onClose = () => {
    displayModalAction('None')
  }
  const onDelete = async () => {
    deleteTaskAction()
    displayModalAction('None')
  }

  return (
    <Modal
      isOpen={currentModal === 'DeleteTaskModal'}
      onClose={onClose}
    >
      <div className='flex flex-col justify-center items-center w-[80vw] sm:w-[558px]'>
        <div className='text-primary-100 text-xl font-semibold mb-2 text-center'>
          Are you sure you want to delete this task?
        </div>
        <div className='text-primary-300 text-base font-normal text-center mb-6'>
          You can&lsquo;t undo this once it&lsquo;s confirmed.
        </div>
        <div className='flex flex-row'>
          <button type='button' className='button mx-2' onClick={onClose}>Cancel</button>
          <button type='button' className='button cta mx-2' onClick={onDelete}>Delete</button>
        </div>
      </div>
    </Modal>
  )
}
