import React, { useState } from 'react'
import PlusIcon from 'theorem-lib/src/assets/icons/plus.svg'
import { useActions, useAppState } from '../../../presenter'
import Dropdown from '../../atoms/Dropdown/Dropdown'

export interface AddTasklistDropdownProps {
  hidePredefinedTemplatesList?: boolean
}

export const AddTasklistDropdownTemplate = (props: AddTasklistDropdownProps) => {
  const { selectedProjectPredefinedTasklists } = useAppState()
  const { addTasklistToProjectAction, displaySlideOutAction } = useActions()
  const [isAddMenuOpen, setIsAddMenuOpen] = useState(false)

  const handleAddClick = (listId?: string) => {
    if (listId) {
      addTasklistToProjectAction(listId)
    }
  }

  const handleAddCustom = () => {
    setIsAddMenuOpen(false)
    displaySlideOutAction('AddCustomTasklistSlideOut')
  }

  return (
    <Dropdown
      isOpen={isAddMenuOpen}
      onClose={() => setIsAddMenuOpen(false)}
      openButton={
        <div
          className='bg-cta-200 rounded-lg flex items-center justify-center w-10 h-10 text-white cursor-pointer'
          onClick={() => setIsAddMenuOpen(true)}
        >
          <PlusIcon />
        </div>
      }
    >
      <div className='text-primary-300 text-base w-max'>
        {!props.hidePredefinedTemplatesList
          && selectedProjectPredefinedTasklists.map(list => (
            <div
              key={list.name}
              className='cursor-pointer ml-3 mb-3'
              onClick={() => {
                setIsAddMenuOpen(false)
                handleAddClick(list.tasklistTemplateId?.toString())
              }}
            >
              <div className='truncate w-80'>{list.name}</div>
              <div className='truncate w-80 text-xs'>{list.description}</div>
            </div>
          ))}
        <div
          key='custom-add'
          className={!props.hidePredefinedTemplatesList
            ? 'cursor-pointer ml-3 mb-3 border-t-2 border-gray-200 border-solid mt-6 pt-4'
            : ''}
          onClick={handleAddCustom}
        >
          <PlusIcon className='text-cta-200 inline mr-2 align-middle' />
          New list
        </div>
      </div>
    </Dropdown>
  )
}
