import { ApolloClient, ApolloError, InMemoryCache, ServerError } from '@apollo/client'
import { sortBy } from 'lodash'
import config from '../config'
import {
  CopySpaceDocument,
  CreateSpaceCommentDocument,
  CreateSpacesDocument,
  DeleteSpaceDocument,
  GetAllItemTemplatesDocument,
  GetCommentsBySpaceIdDocument,
  ItemTemplate,
  ListSpacesItemTemplatesDocument,
  ListSpacesTemplatesDocument,
  Space,
  SpaceComment,
  SpaceCommentInput,
  SpacesItemTemplate,
  SpaceTemplate,
  UpdateItemIndexDocument,
  UpdateItemIndexInput,
  UpdateSpaceDocument,
  UpdateSpaceInput,
  UpdateSpaceListInput,
  UpdateSpaceListsDocument,
} from '../generated/api'
import { UnauthenticatedError, UnexpectedError } from '../presenter/errors'

function isApolloError(err: unknown | ApolloError): err is ApolloError {
  return (err as ApolloError).graphQLErrors !== undefined
}

function isServerError(err: unknown | ServerError): err is ServerError {
  return (err as ServerError).name == 'ServerError'
}

function graphqlErrorHandler(err: unknown | ApolloError) {
  if (isApolloError(err)) {
    const networkError = err.networkError
    if (isServerError(networkError)) {
      if (networkError.statusCode == 401) throw new UnauthenticatedError()
      if (networkError.statusCode == 404) throw new UnexpectedError()
    }
  }
}

const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  credentials: 'include',
  uri: config.appApiGraphqlUrl,
})

export const getSpaces = async (): Promise<SpaceTemplate[]> => {
  try {
    const { data } = await apolloClient.query({ fetchPolicy: 'no-cache', query: ListSpacesTemplatesDocument })
    return sortBy(data.listSpacesTemplates, ['name'])
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const createProjectSpaces = async (
  spaceInput: { isInvalid: boolean; projectId: string; spacesList: { templateId: string; quantity: number }[] },
): Promise<void> => {
  try {
    const { isInvalid, ...input } = spaceInput
    await apolloClient.mutate({
      fetchPolicy: 'no-cache',
      mutation: CreateSpacesDocument,
      variables: { input },
    })
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const deleteSpace = async (id: string): Promise<void> => {
  try {
    await apolloClient.mutate({ fetchPolicy: 'no-cache', mutation: DeleteSpaceDocument, variables: { id } })
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const copySpace = async (id: string): Promise<void> => {
  try {
    await apolloClient.mutate({ fetchPolicy: 'no-cache', mutation: CopySpaceDocument, variables: { id } })
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const updateProjectSpace = async (id: string, input: UpdateSpaceInput): Promise<void> => {
  try {
    await apolloClient.mutate({
      fetchPolicy: 'no-cache',
      mutation: UpdateSpaceDocument,
      variables: { id, input },
    })
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const getAllItemTemplates = async (): Promise<ItemTemplate[]> => {
  try {
    const { data } = await apolloClient.query({
      fetchPolicy: 'no-cache',
      query: GetAllItemTemplatesDocument,
    })

    return data.getAllItemTemplates
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const getAllSpacesItemTemplates = async (): Promise<SpacesItemTemplate[]> => {
  try {
    const { data } = await apolloClient.query({
      fetchPolicy: 'no-cache',
      query: ListSpacesItemTemplatesDocument,
    })

    return data.listSpacesItemTemplates
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const createSpaceComment = async (input: SpaceCommentInput): Promise<SpaceComment> => {
  try {
    const { data } = await apolloClient.mutate({
      fetchPolicy: 'no-cache',
      mutation: CreateSpaceCommentDocument,
      variables: { input },
    })

    return data.createSpaceComment
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const getAllSpaceComments = async (id: string): Promise<SpaceComment[]> => {
  try {
    const { data } = await apolloClient.query({
      fetchPolicy: 'no-cache',
      query: GetCommentsBySpaceIdDocument,
      variables: { id },
    })

    return data.getCommentsBySpaceId
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const updateSpaceLists = async (input: UpdateSpaceListInput[]): Promise<boolean> => {
  try {
    const { data } = await apolloClient.mutate({
      fetchPolicy: 'no-cache',
      mutation: UpdateSpaceListsDocument,
      variables: { input },
    })

    return data.updateSpaceList
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const updateItemIndex = async (input: UpdateItemIndexInput[]): Promise<boolean> => {
  try {
    const { data } = await apolloClient.mutate({
      fetchPolicy: 'no-cache',
      mutation: UpdateItemIndexDocument,
      variables: { input },
    })
    return data.updateItemIndex
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}
