import React from 'react'
import { IndustriesEnum } from '../NewProjectCreation/IndustriesSelect'

export type IndustriesCardProps = {
  id: string
  label: string
  name: React.ReactNode
  icon: React.ElementType
  cardClassName?: string
  iconClassName?: string
  iconBackgroundColor: string
  selected?: boolean
  selectedIndustry: IndustriesEnum
  onClick?: () => void
  order?: number
}

export const IndustriesCard = (props: IndustriesCardProps) => {
  const classOrEmptyString = (input: string | undefined) => {
    return input ? input : ''
  }
  return (
    <div
      className={`h-48 w-64 border-2 ${
        props.selected ? 'border-3 border-[#35697D]' : 'border-gray-200'
      } rounded cursor-pointer`}
      onClick={props.onClick}
    >
      <div
        className={`rounded-full ${props.iconBackgroundColor} w-12 h-12 mt-4 ml-4 text-center`}
      >
        {<props.icon className={`inline ml-auto mr-auto ${classOrEmptyString(props.iconClassName)}`} />}
      </div>
      <div className='ml-4 mr-4 mt-3 text-primary-200  font-bold text-xl'>
        {props.name}
      </div>
      <div className='ml-4 mr-4 mt-1 text-primary-200  text-xs'>
        {props.label}
      </div>
    </div>
  )
}
