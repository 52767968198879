import { ApolloClient, ApolloError, InMemoryCache, ServerError } from '@apollo/client'
import config from '../config'
import {
  CreateServicesDocument,
  CreateServicesInput,
  DeleteServiceDocument,
  ListServicesTemplatesDocument,
  ServiceTemplate,
  UpdateServicesDocument,
  UpdateServicesInput,
} from '../generated/api'

import { UnauthenticatedError, UnexpectedError } from '../presenter/errors'

function isApolloError(err: unknown | ApolloError): err is ApolloError {
  return (err as ApolloError).graphQLErrors !== undefined
}

function isServerError(err: unknown | ServerError): err is ServerError {
  return (err as ServerError).name == 'ServerError'
}

function graphqlErrorHandler(err: unknown | ApolloError) {
  if (isApolloError(err)) {
    const networkError = err.networkError
    if (isServerError(networkError)) {
      if (networkError.statusCode == 401) throw new UnauthenticatedError()
      if (networkError.statusCode == 404) throw new UnexpectedError()
    }
  }
}

const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  credentials: 'include',
  uri: config.appApiGraphqlUrl,
})

export const createServices = async (input: CreateServicesInput): Promise<void> => {
  try {
    await apolloClient.mutate({
      fetchPolicy: 'no-cache',
      mutation: CreateServicesDocument,
      variables: { input },
    })
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const deleteService = async (id: string): Promise<void> => {
  try {
    await apolloClient.mutate({
      fetchPolicy: 'no-cache',
      mutation: DeleteServiceDocument,
      variables: { id },
    })
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const updateServices = async (id: string, input: UpdateServicesInput): Promise<void> => {
  try {
    await apolloClient.mutate({
      fetchPolicy: 'no-cache',
      mutation: UpdateServicesDocument,
      variables: { id, input },
    })
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const getAllServicesTemplates = async (): Promise<ServiceTemplate[]> => {
  try {
    const { data } = await apolloClient.query({
      fetchPolicy: 'no-cache',
      query: ListServicesTemplatesDocument,
    })

    return data.listServicesTemplates
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}
