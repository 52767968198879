import parse from 'html-react-parser'
import React, { useState } from 'react'
import { Mention, MentionsInput } from 'react-mentions'
import AirplaneIcon from 'theorem-lib/src/assets/icons/airplane.svg'
import CheckIcon from 'theorem-lib/src/assets/icons/check.svg'
import TrashIcon from 'theorem-lib/src/assets/icons/trash.svg'
import { formatDate } from 'theorem-lib/src/helpers/format'
import { taskOverdue } from 'theorem-lib/src/helpers/overdue'
import { CreateCommentInput, ProjectMemberStatusEnum, TaskComment } from '../../../generated/api'
import { useActions, useAppState } from '../../../presenter'
import defaultMentionStyle from '../../../schemas/styles/defaultMentionStyle'
import defaultStyle from '../../../schemas/styles/defaultStyle'
import Select, { Option } from '../../atoms/Select/Select'
import { SlideOut } from '../../atoms/SlideOut/SlideOut'
import TextInput from '../../atoms/TextInput/TextInput'
import { WorkbookFieldGroup } from '../../molecules/Workbook/WorkbookFieldGroup'

interface ManageTaskSlideOutProps {
  hideDeleteTaskOption?: boolean
}

interface Mention {
  id: string
  display: string
}

export const ManageTaskSlideOutTemplates = (props: ManageTaskSlideOutProps) => {
  const { selectedProject, taskEdit } = useAppState()
  const {
    addTaskCommentAction,
    closeManageTaskAction,
    displayModalAction,
    sendAssignedToTaskNotificationAction,
    sendMentionNotificationAction,
    sendUnassignedToTaskNotificationAction,
    updateTaskAction,
  } = useActions()

  const [comment, setComment] = useState('')
  const [mentions, setMentions] = useState<Mention[]>([])

  const userData = (): Mention[] => {
    const d: Mention[] = []
    if (selectedProject && selectedProject.members) {
      for (const member of selectedProject.members) {
        if (member.status === ProjectMemberStatusEnum.Accepted) {
          d.push({
            display: member.firstName + ' ' + member.lastName,
            id: member.id,
          })
        }
      }
    }
    return d
  }

  const onAdd = (id: string | number, display: string) => {
    const newMention = {
      display: display,
      id: id.toString(),
    }
    setMentions(prevMentions => [...prevMentions, newMention])
  }

  const filterMentions = () => {
    for (let i = 0; i < mentions.length; i++) {
      const included = comment.includes(mentions[i].display)
      if (!included) {
        setMentions(mentions.splice(i, 1))
      }
    }
  }

  const handleSaveComment = () => {
    if (taskEdit.task && comment && comment !== '') {
      const peopleTagged = mentions.map(tag => ({
        display: tag.display,
        userId: tag.id,
      }))

      const input: CreateCommentInput = {
        comment: comment,
        projectId: selectedProject.id,
        tags: peopleTagged,
        taskId: taskEdit.task.id,
      }

      addTaskCommentAction(input)

      filterMentions()

      for (let i = 0; i < mentions.length; i++) {
        // loop through and dispatch sendMentionNotificationAction for each id
        const newTask = { ...taskEdit.task, assignedUserId: mentions[i].id }
        sendMentionNotificationAction(newTask)
      }
    }

    setComment('')
    setMentions([])
  }

  const handleToggleComplete = () => {
    if (!taskEdit.task) return
    const newTask = { ...taskEdit.task }
    newTask.completedDate = taskEdit.task.completedDate ? undefined : new Date().toString()
    updateTaskAction(newTask)
  }

  const handleTaskNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!taskEdit.task) return
    const newTask = { ...taskEdit.task }
    newTask.name = e.target.value
    updateTaskAction(newTask)
  }

  const handleCommentChange = (e: { target: { value: string } }) => {
    setComment(e.target.value)
  }

  const handleCommentKeyUp = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      handleSaveComment()
    }
  }

  const handleDeleteTask = () => {
    displayModalAction('DeleteTaskModal')
  }

  const handleDueDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!taskEdit.task) return
    const newTask = { ...taskEdit.task }
    newTask.dueDate = e.target.value
    updateTaskAction(newTask)
  }

  const handleAssignChange = (e: { key: string; value: string }) => {
    if (!taskEdit.task) return
    const newTask = { ...taskEdit.task }
    newTask.assignedUserId = e.key
    updateTaskAction(newTask)

    if (taskEdit.task?.assignedUser?.id !== e.key) {
      if (newTask.assignedUser) {
        sendUnassignedToTaskNotificationAction(taskEdit.task)
      }
      sendAssignedToTaskNotificationAction(newTask)
    }
  }

  const formatDateFieldValue = (date: string) => {
    if (!date) return ''
    const d = new Date(date)
    const result = d.toISOString().split('T')[0]
    return result
  }

  const selectData = (): Option[] => {
    const d: Option[] = []
    if (selectedProject && selectedProject.members) {
      for (const member of selectedProject.members) {
        if (member.status === ProjectMemberStatusEnum.Accepted) {
          d.push({
            key: member.id,
            value: `${member.firstName} ${member.lastName}`,
          })
        }
      }
    }
    return d
  }

  if (!taskEdit.task) {
    return <></>
  }

  const taskHasBeenModified = taskEdit.task?.createdOn !== taskEdit.task?.modifiedOn
  const taskModificationMessage = taskHasBeenModified
    ? `Modified by ${taskEdit.task?.modifyUser?.firstName} ${taskEdit.task?.modifyUser?.lastName}`
    : `Created by ${taskEdit?.task?.createUser?.firstName} ${taskEdit?.task?.createUser?.lastName}`

  return (
    <SlideOut onClose={() => closeManageTaskAction()}>
      <div className='flex align items-center -mt-14'>
        <button
          onClick={() => handleToggleComplete()}
          className={`w-12 h-12 mr-4 text-center rounded-full grow-0 shrink-0 ${
            taskEdit.task.completedDate
              ? 'bg-teal-500 text-white'
              : 'border border-[#d9c3c1] text-[#f0e7e6]'
          }`}
        >
          <CheckIcon className='inline -mt-1 scale-150' />
        </button>
        <h2 className='text-lg font-medium text-primary-100 flex'>{taskEdit?.task.name}</h2>
      </div>
      <div className='text-xs font-light text-primary-200 pl-16'>
        {taskEdit.task.completedDate
          && (
            <>
              <span>Completed by&nbsp;</span>
              <span>{taskEdit.task.completedByUser?.firstName} {taskEdit.task.completedByUser?.lastName}&nbsp;</span>
              <span>on {formatDate(taskEdit.task.completedDate)}.&nbsp;</span>
            </>
          )}
        <span>
          {taskModificationMessage} on {formatDate(taskEdit.task.createdOn, 'MM.DD.YYYY')}
        </span>
      </div>
      <div className='mt-6'>
        <TextInput label='Task name' name='Task name' defaultValue={taskEdit.task.name} onBlur={handleTaskNameChange} />
      </div>
      <div className='mt-4'>
        <WorkbookFieldGroup task={taskEdit.task} />
      </div>
      <div>
        <div className='inline-block w-1/2 mt-3 mb-4 pr-2 align-top'>
          <Select
            data={selectData()}
            label='Assign to'
            onChange={handleAssignChange}
            default={taskEdit.task.assignedUser
              ? {
                key: taskEdit.task.assignedUser.id,
                value: `${taskEdit.task.assignedUser?.firstName} ${taskEdit.task.assignedUser?.lastName}`,
              }
              : undefined}
          />
        </div>
        <div className='inline-block w-1/2 mt-8 mb-4 pl-2 align-top'>
          <TextInput
            type='date'
            onChange={handleDueDateChange}
            label='Due Date'
            name='duedate'
            value={formatDateFieldValue(taskEdit.task.dueDate)}
          />
          {taskOverdue(taskEdit.task.dueDate, taskEdit.task.completedDate) && (
            <div className='text-right text-error font-medium text-xs'>Task Overdue</div>
          )}
        </div>
      </div>
      <div className='mt-8'>
        <div className='text-xs text-primary-400'>Comments</div>
        <div className='relative mb-6'>
          <div className='advanced'>
            <MentionsInput
              style={defaultStyle}
              placeholder='Enter a Comment'
              value={comment}
              onChange={handleCommentChange}
              onKeyUp={handleCommentKeyUp}
            >
              <Mention
                style={defaultMentionStyle}
                data={userData}
                trigger='@'
                displayTransform={(id, display) => '@' + display}
                markup={'<span style="color: pink">@__display__</span>'}
                onAdd={onAdd}
                renderSuggestion={(suggestion, search, highlightedDisplay) => (
                  <div className='user'>{highlightedDisplay}</div>
                )}
              >
              </Mention>
            </MentionsInput>
          </div>
          <button className='absolute right-4 top-5 text-primary-400' onClick={handleSaveComment}>
            <AirplaneIcon />
          </button>
        </div>
        {taskEdit.comments.length === 0 && <div className='text-center text-primary-400 py-8'>No comments yet.</div>}
        {taskEdit.comments.map((comment: TaskComment) => (
          <div key={comment.id} className='mb-6'>
            <div className='text-black text-base'>{parse(comment.comment)}</div>
            <div className='text-primary-400 text-sm mt-1'>
              {comment.createUser.firstName} {comment.createUser.lastName} |{'  '}
              {formatDate(comment.createdOn, 'MM.DD.YYYY')}
            </div>
          </div>
        ))}
      </div>
      {!props.hideDeleteTaskOption && (
        <div className='py-4 text-right'>
          <button className='button' onClick={handleDeleteTask} title='Delete this task'>
            <TrashIcon className='inline' /> Delete Task
          </button>
        </div>
      )}
    </SlideOut>
  )
}
