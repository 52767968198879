import { XIcon } from '@heroicons/react/outline'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { ProjectSizeEnum } from '../../../generated/api'
import { getNameWithoutUnderScores } from '../../../helpers/projectHelper'
import { useActions, useAppState } from '../../../presenter'
import { RadioButton } from '../../atoms/RadioButton/RadioButton'
import { SlideIndicators } from '../../atoms/SlideIndicators/SlideIndicators'
import TextInput from '../../atoms/TextInput/TextInput'
import { Wizard } from '../../atoms/Wizard/Wizard'
import { IndustriesChip } from '../../molecules/IndustriesChip/IndustriesChip'
import { IndustriesEnum } from '../../molecules/NewProjectCreation/IndustriesSelect'
import { CancelProjectWizardModal } from '../Modals/CancelProjectWizardModal'

export const ProjectSizeWizard = () => {
  const { currentModal, wizardProjectCreation, wizardProjectSelectedIndustry } = useAppState()
  const { cleanProjectCreation, displayModalAction, setProjectCreationHeadCount, setProjectCreationSize } = useActions()
  const [industry] = useState<IndustriesEnum | undefined>(wizardProjectSelectedIndustry)
  const [projectSize, setProjectSize] = useState<ProjectSizeEnum[]>(
    wizardProjectCreation.industryDetails?.projectSize?.values ?? [],
  )
  const [other, setOther] = useState<string>(wizardProjectCreation.industryDetails?.projectSize?.other ?? '')
  const [headcount, setHeadCount] = useState<number>(wizardProjectCreation.headCount)
  const [showCancelProjectWizardModal, setShowCancelProjectWizardModal] = useState<boolean>(false)

  const goBack = () => {
    SaveProject()
    displayModalAction('SpacesWizard')
  }

  const onClose = () => cleanProjectCreation()

  const onClickNext = () => {
    if (projectSize.length < 1) {
      toast('Select an option to continue', { type: 'error' })
      return
    }

    SaveProject()
    displayModalAction('VisualPreferencesWizard')
  }

  const SaveProject = () => {
    setProjectCreationHeadCount(headcount)
    setProjectCreationSize({
      other: other,
      values: projectSize,
    })
  }

  const onClickExit = () => {
    setShowCancelProjectWizardModal(true)
  }

  const onConfirmLeaveWizardModal = () => {
    cleanProjectCreation()
    displayModalAction('None')
  }

  const onKeepEditingWizardModal = () => {
    setShowCancelProjectWizardModal(false)
  }

  const Header = () => {
    return (
      <>
        <button
          type='button'
          className='rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none'
          onClick={onClickExit}
        >
          <XIcon className='h-6 w-6' aria-hidden='true' />
        </button>
      </>
    )
  }

  const Footer = () => {
    return (
      <>
        <button
          className='bg-[#F1F1F1] items-center text-[#35697D] px-3 rounded-lg font-semibold mr-2 h-10'
          onClick={() => goBack()}
        >
          {'<-'} Back
        </button>
        <button
          className='bg-cta-200 items-center text-white px-3 rounded-lg font-semibold mr-2 h-10'
          onClick={onClickNext}
        >
          Next
        </button>
      </>
    )
  }

  return (
    <Wizard
      isOpen={currentModal === 'ProjectSizeWizard'}
      onClose={onClose}
      header={<Header />}
      footer={<Footer />}
      exitModal={
        <CancelProjectWizardModal
          showCancelModal={showCancelProjectWizardModal}
          onConfirmLeave={onConfirmLeaveWizardModal}
          onKeepEditing={onKeepEditingWizardModal}
        />
      }
    >
      {industry
        && (
          <>
            <div className='flex flex-col justify-center items-center w-full sm:w-full'>
              <div className='text-primary-100 text-xl font-bold mb-2 text-center'>
                How many people do we need to account for within this project?
              </div>
              <div className='text-primary-200 text-base font-semibold text-center mb-6'>
                Select all that apply
              </div>
              <div className='text-primary-200 text-base font-semibold text-center mb-6'>
                <IndustriesChip industry={industry} />
              </div>
              <div className='overflow-x-visible grid grid-cols-1 gap-4 scroll-smooth'>
                {(Object.values(ProjectSizeEnum) as Array<ProjectSizeEnum>).map((size, index) => (
                  <RadioButton
                    key={index}
                    name='projectSizeEnum'
                    checked={projectSize.find(f => f === size) ? true : undefined}
                    text={getNameWithoutUnderScores(size)}
                    value={size}
                    onChange={(checked) => {
                      const value = (Object.values(ProjectSizeEnum) as Array<ProjectSizeEnum>).find(f => f === checked)
                      if (value) setProjectSize([value])
                    }}
                  />
                ))}
              </div>
              <div className='my-6 w-18'>
                <div className='text-primary-100 text-lg font-bold mb-2 text-center'>
                  Do you know the exact headcount?
                </div>
                <TextInput
                  label='Headcount (Optional)'
                  type='number'
                  maxLength={52}
                  defaultValue={headcount.toString() ?? ''}
                  onChange={(e) => {
                    setHeadCount(Number(e.target.value))
                  }}
                />
                <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                </span>
              </div>
            </div>
          </>
        )}
      <SlideIndicators />
    </Wizard>
  )
}
