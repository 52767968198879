import React from 'react'
import { useAppState } from '../../../presenter'
import { ProjectHeader } from '../../molecules/ProjectHeader'

export interface ImageryProps {
  readOnlyProjectStage?: boolean
  hideHeaderDeleteOption?: boolean
  hideHeaderDropDown?: boolean
}

export const ImageryTemplate = (props: ImageryProps) => {
  const { tenant } = useAppState()
  const iframeUrl = tenant.iFrameUrl

  return (
    <div className='flex flex-col h-full'>
      <ProjectHeader
        activeTab='imagery'
        hideDropDown={props.hideHeaderDropDown}
        hideDeleteOption={props.hideHeaderDeleteOption}
        readOnlyProjectStage={props.readOnlyProjectStage}
      />
      <iframe className='h-full' src={iframeUrl}>
      </iframe>
    </div>
  )
}
