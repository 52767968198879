import React from 'react'
import { Modal } from 'theorem-lib/src/components/molecules/Modal/Modal'
import { DiscIncInput } from '../../../generated/api'
import { useActions, useAppState } from '../../../presenter'

interface RemoveDiscIncModalProps {
  showRemoveDiscIncModal: boolean
  onConfirmRemove: () => void
  onKeepEditing: () => void
}

export function RemoveDiscIncModal(props: RemoveDiscIncModalProps) {
  const { selectedProject } = useAppState()

  // Input needed to set budget without discout or increase
  const updatedDiscIncInput: DiscIncInput = {
    discountPercentage: null,
    increasePercentage: null,
    projectId: selectedProject.id,
  }

  return (
    <Modal
      isOpen={props.showRemoveDiscIncModal}
      onClose={props.onKeepEditing}
    >
      <div className='flex flex-col justify-center items-center w-[80vw] sm:w-[558px]'>
        <div className='text-primary-100 text-xl font-semibold mb-2 text-center'>
          Are you sure you want to remove this discount/increase from the budget?
        </div>
        <div className='flex flex-row'>
          <button type='button' className='button mx-2' onClick={props.onKeepEditing}>Cancel</button>
          <button type='button' className='button cta mx-2' onClick={props.onConfirmRemove}>Remove</button>
        </div>
      </div>
    </Modal>
  )
}
