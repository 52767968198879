import { getTenantCodeByHostname } from 'theorem-lib/src/helpers/tenantHelper'
import config from '../../config'
import { Context } from '..'
import { defaultErrorHandler } from './actions'

const baseWpContentUrl = `${config.wordPressFolioUrl}/wp-content/uploads/`
const baseWpIframeUrl = `${config.wordPressFolioUrl}/image-iframe-`
const png = '.png'
const folioImageName = 'folio' + png

export async function getTenantInformationAction() {
  return {
    defaultLogoUrl: baseWpContentUrl + folioImageName,
    iFrameUrl: getIframeUrl(),
    logoUrl: getLogoUrl(),
  }
}

function getIframeUrl(): string {
  const hostname = window.location.hostname
  const iframeUrl = baseWpIframeUrl + getTenantCodeByHostname(hostname)

  return iframeUrl
}

function getLogoUrl(): string {
  const hostname = window.location.hostname
  const logoImageUrl = baseWpContentUrl + getTenantCodeByHostname(hostname) + png

  return logoImageUrl
}

export async function getTenantInfoByCodeAction(context: Context) {
  try {
    const results = await context.effects.getTenantInfoByCode()

    results.tenantName
      ? context.state.tenant.name = results.tenantName
      : context.state.tenant.name = 'No tenant name available'
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}
