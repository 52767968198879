import React from 'react'
import ReactModal from 'react-modal'

interface wizardProps {
  height?: string
  currentModal?: string
  isOpen: boolean
  children: React.ReactNode
  footer?: JSX.Element
  header?: JSX.Element
  onClose: (event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>) => void
  exitModal?: JSX.Element
}

export function Wizard(props: wizardProps) {
  const modalStyles = {
    content: {
      backgroundColor: '#FFFFFF',
      border: 'none',
      borderRadius: 8,
      bottom: 'auto',
      height: props.height ?? '620px', // '660px',
      left: '50%',
      marginRight: '-50%',
      overflow: 'visible', // to allow scroll modal
      right: 'auto',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      width: '960px',
    },
    overlay: {
      backgroundColor: 'black',
      zIndex: 150,
    },
  }
  const headerStyle = `flex justify-end bg-white`
  const footerStyle = `flex justify-between py-6 fixed px-6 bottom-0 right-0 w-full bg-white`

  return (
    <ReactModal
      isOpen={props.isOpen}
      style={modalStyles}
      ariaHideApp={false}
      onRequestClose={props.onClose}
      shouldCloseOnEsc={true}
    >
      {props.header
        && (
          <header className={headerStyle}>
            {props.header}
          </header>
        )}

      <div className={`overflow-auto relative w-full ${props.footer ? 'h-5/6' : 'h-full'} pb-2`}>
        {props.children}
      </div>

      {props.footer
        && (
          <footer className={footerStyle}>
            {props.footer}
          </footer>
        )}

      {props.exitModal
        && (
          props.exitModal
        )}
    </ReactModal>
  )
}
