import React, { useRef, useState } from 'react'
import { useClickOutside } from 'theorem-lib/src/helpers/useClickOutside'

type DropdownProps = {
  children: React.ReactNode
  isOpen?: boolean
  openButton: React.ReactNode
  onClose?: () => void
  shouldOpenOnHover?: boolean
  isInternal?: boolean
  openTo?: 'left' | 'right'
}

export const Dropdown = (
  { children, isInternal = false, isOpen, onClose, openButton, openTo = 'left', shouldOpenOnHover }: DropdownProps,
): React.ReactElement => {
  const ContainerRef = useRef(null)
  const [isHovering, setIsHovering] = useState(false)
  useClickOutside(ContainerRef, onClose, isOpen)

  const shouldDisplay = (shouldOpenOnHover && isHovering) || isOpen

  return (
    <div
      onMouseEnter={(): void => {
        setIsHovering(true)
      }}
      onMouseLeave={(): void => {
        setIsHovering(false)
      }}
      aria-hidden='true'
      className='relative'
      onClick={e => {
        setIsHovering(false)
        e.stopPropagation()
      }}
    >
      {openButton}
      {shouldDisplay && (
        <div
          ref={ContainerRef}
          className={`flex mt-[1px] flex-col bg-grey-400 w-full min-w-max absolute rounded-lg p-4 shadow-md z-10
          ${isInternal && 'bottom-5'} 
          ${openTo === 'left' ? 'right-0' : 'left-0'}`}
        >
          {children}
        </div>
      )}
    </div>
  )
}

export default Dropdown
