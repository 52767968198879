import React, { useEffect, useState } from 'react'
import CheckIcon from 'theorem-lib/src/assets/icons/check.svg'
import CogIcon from 'theorem-lib/src/assets/icons/cog.svg'
import EditIcon from 'theorem-lib/src/assets/icons/edit.svg'
import PlusIcon from 'theorem-lib/src/assets/icons/plus.svg'
import TrashIcon from 'theorem-lib/src/assets/icons/trash.svg'
import { Section, Tasklist } from '../../../generated/api'
import { useActions, useAppState } from '../../../presenter'
import Dropdown from '../../atoms/Dropdown/Dropdown'
import { AddTasklistDropdown } from '../../molecules/AddTasklistDropdown/AddTasklistDropdown'
import { ProjectHeader } from '../../molecules/ProjectHeader'
import { TempWorkbookTask } from '../../molecules/Workbook/TempWorkbookTask'
import { WorkbookTask } from '../../molecules/Workbook/WorkbookTask'
import { WorkbookTaskListCard } from '../../molecules/Workbook/WorkbookTaskListCard'

export interface WorkbookProps {
  readOnlyProjectStage?: boolean
  hideHeaderDeleteOption?: boolean
  hideHeaderDropDown?: boolean
  checkIfShowManageListOption?: boolean
  hideDeleteListOption?: boolean
}

type TaskListSectionProps = {
  section: Section
}

export const WorkbookTemplates = (props: WorkbookProps) => {
  const {
    displayModalAction,
    displayUpdateTaskListSlideOut,
    setDeleteTasklistIdAction,
    setSelectedProjectTaskListIndex,
  } = useActions()
  const { authenticatedUser, selectedProjectTaskListIndex, selectedProjectTaskLists } = useAppState()
  const [selectedTaskList, setSelectedTaskList] = useState<Tasklist | undefined>(undefined)
  const [isSettingMenuOpen, setIsSettingMenuOpen] = useState(false)

  // set initial active list
  if (selectedProjectTaskListIndex) {
    useEffect(() => {
      if (selectedProjectTaskLists && selectedProjectTaskLists[selectedProjectTaskListIndex]) {
        setSelectedTaskList(selectedProjectTaskLists[selectedProjectTaskListIndex])
      }
    }, [selectedProjectTaskLists])
  } else {
    useEffect(() => {
      if (selectedTaskList === undefined && selectedProjectTaskLists && selectedProjectTaskLists[0]) {
        setSelectedTaskList(selectedProjectTaskLists[0])
      }
    }, [selectedProjectTaskLists])
  }

  const handleTaskListClick = (list: Tasklist, index: number) => {
    if (list && selectedTaskList && list.id !== selectedTaskList.id) {
      setSelectedProjectTaskListIndex({ index: index })
      setSelectedTaskList(list)
    }
  }

  const handleDeleteCustomList = (id?: string) => {
    if (id) {
      setDeleteTasklistIdAction(id)
      displayModalAction('DeleteTasklistModal')
    }
  }

  const isShowManageListOptionAvailable = () => {
    if (props.checkIfShowManageListOption) {
      return authenticatedUser.id == selectedTaskList?.createUser.id
        || selectedTaskList?.tasklistUsers?.some(user => authenticatedUser.id === user.id)
    } else {
      return true
    }
  }

  return (
    <div className='flex flex-col'>
      <ProjectHeader
        activeTab='workbook'
        readOnlyProjectStage={props.readOnlyProjectStage}
        hideDropDown={props.hideHeaderDropDown}
        hideDeleteOption={props.hideHeaderDeleteOption}
      />
      <div className='flex flex-row absolute top-44 bottom-0 left-32 right-8'>
        <div className='absolute -top-14 right-0'>
          <AddTasklistDropdown />
        </div>
        {selectedProjectTaskLists.length === 0 && <div key='nada'>No active task lists for this project.</div>}
        {selectedProjectTaskLists.length !== 0 && (
          <>
            <div className='absolute w-80 top-4 bottom-0 left-0 overflow-y-auto' key='some'>
              {selectedProjectTaskLists.map((list, index: number) => (
                <WorkbookTaskListCard
                  key={list.id}
                  list={list}
                  currentIndex={index}
                  isActive={selectedTaskList && list.id === selectedTaskList.id}
                  onClick={() => {
                    handleTaskListClick(list, index)
                  }}
                />
              ))}
            </div>
            <div className='absolute top-0 bottom-0 right-0 left-80 bg-[#f5eeed] rounded-lg rounded-b-none px-4 pt-4 overflow-y-auto'>
              <div className='text-xl font-semibold text-black flex justify-between'>
                {selectedTaskList?.name}
                {isShowManageListOptionAvailable() && (
                  <Dropdown
                    isOpen={isSettingMenuOpen}
                    onClose={() => setIsSettingMenuOpen(false)}
                    openButton={
                      <CogIcon
                        className='cursor-pointer'
                        onClick={() => {
                          setIsSettingMenuOpen(true)
                        }}
                      />
                    }
                  >
                    <div className='text-primary-300 text-base w-max'>
                      <div
                        key='manage-checklist'
                        className='cursor-pointer ml-3'
                        onClick={() => {
                          displayUpdateTaskListSlideOut(selectedTaskList?.id)
                          setIsSettingMenuOpen(false)
                        }}
                      >
                        <div className='truncate w-48 flex'>
                          <EditIcon className='mr-3' /> Manage List
                        </div>
                      </div>
                      {(!props.hideDeleteListOption) && (
                        <div
                          key='del-checklist'
                          className='cursor-pointer ml-3 mt-4'
                          onClick={() => {
                            handleDeleteCustomList(selectedTaskList?.id)
                          }}
                        >
                          <div className='truncate w-80 flex'>
                            <TrashIcon className='mr-3' /> Delete List
                          </div>
                        </div>
                      )}
                    </div>
                  </Dropdown>
                )}
              </div>
              <div className='text-xs mt-2 mb-8 text-black'>
                {selectedTaskList?.description}
              </div>
              <div>
                {selectedTaskList?.sections.map(section => <TaskListSection section={section} key={section.name} />)}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export function TaskListSection(props: TaskListSectionProps) {
  const { completeSectionAction } = useActions()
  const [showTempTask, setShowTempTask] = useState(false)

  const handleCompleteSection = (sectionId: string) => {
    completeSectionAction(sectionId)
  }

  const handleAddTaskClick = () => {
    setShowTempTask(true)
  }

  const handleTaskAdded = () => {
    setShowTempTask(false)
  }

  const handleCancelNewTask = () => {
    setShowTempTask(false)
  }

  if (!props) {
    return <></>
  }

  return (
    <div>
      <div className='text-sm mb-4 mt-6 relative font-semibold text-black'>
        {props.section.name}
        <button className='text-cta-100 absolute right-0' onClick={() => handleCompleteSection(props.section.id)}>
          <CheckIcon className='inline mr-1' />Mark section tasks complete
        </button>
      </div>
      <div>
        {props.section.tasks.map(task => <WorkbookTask task={task} key={task.id} />)}
        {showTempTask && (
          <TempWorkbookTask sectionId={props.section.id} taskAddedFn={handleTaskAdded} cancelFn={handleCancelNewTask} />
        )}
      </div>
      <div className='text-sm relative h-16'>
        {!showTempTask && (
          <button className='text-cta-100 absolute right-0' onClick={() => handleAddTaskClick()}>
            <PlusIcon className='inline' />Add new task
          </button>
        )}
      </div>
    </div>
  )
}
