import React from 'react'
import { Modal } from 'theorem-lib/src/components/molecules/Modal/Modal'
import { useActions, useAppState } from '../../../presenter'

export const WelcomeToFolioModal = () => {
  const { currentModal } = useAppState()
  const { displayModalAction, redirectAction } = useActions()

  const onClose = () => {
    const projectId = new URLSearchParams(window.location.search).get(
      'projectId',
    )
    redirectAction(`/project/${projectId}/dashboard`)
    displayModalAction('None')
  }

  return (
    <Modal
      isOpen={currentModal === 'WelcomeToFolioModal'}
      onClose={onClose}
      shouldCloseOnEsc={false}
      contentStyle={{
        width: '486px',
      }}
    >
      <div className='flex flex-col justify-center items-center w-full[80vw] sm:w-full'>
        <div className='text-primary-100 text-xl font-bold mt-4 mb-4 text-center'>
          Welcome to FOLIO! <span>🎉</span>
        </div>
        <div className='px-4 text-primary-300 text-base font-normal text-center mb-4'>
          <p className='mb-4'>Your team will use FOLIO to collaborate and communicate on ideas and plans.</p>
          <p className='mb-4'>Head to your Project Dashboard to see your project overview.</p>
        </div>
        <div className='flex flex-row'>
          <button type='button' className='button cta mx-2' onClick={onClose}>View Dashboard</button>
        </div>
      </div>
    </Modal>
  )
}
