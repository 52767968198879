import { ClientInput } from '../generated/api'

export function validateClientInput(clientInput: ClientInput): Map<string, string> {
  const results = new Map<string, string>()

  if (!clientInput.name || clientInput.name === '') {
    results.set('clientName', 'Please enter a client name.')
  }

  if (!clientInput.industriesIds || clientInput.industriesIds.length === 0) {
    results.set('industriesIds', 'Please select at least one industry.')
  }

  return results
}
