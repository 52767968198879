import { cloneDeep } from 'lodash'
import { toast } from 'react-toastify'
import {
  Space,
  SpaceCommentInput,
  UpdateItemIndexInput,
  UpdateSpaceInput,
  UpdateSpaceListInput,
} from '../../generated/api'
import { Context } from '..'
import { defaultErrorHandler } from './actions'

export async function deleteSpaceAction(context: Context) {
  try {
    await context.effects.deleteSpace(context.state.spaceDeleteId)
    context.state.spaceDeleteId = ''
    context.state.selectedProjectEstimates = await context.effects.getProjectEstimates(context.state.selectedProject.id)
    const projectSpaces = await context.effects.getProjectSpaces(context.state.selectedProject.id)
    context.state.selectedProjectSpaces = projectSpaces
    toast('Space deleted successfully!')
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function updateSpaceDeleteIdAction(context: Context, spaceId: string) {
  context.state.spaceDeleteId = spaceId
}

export async function submitCreateProjectSpaceAction(
  context: Context,
  value: { isInvalid: boolean; projectId: string; spacesList: { quantity: number; templateId: string }[] },
) {
  try {
    if (value.spacesList.length < 1) {
      toast('We’re sorry, something went wrong.')
      return
    }
    if (value.isInvalid) {
      toast('We’re sorry, something went wrong.')
    }
    await context.effects.createProjectSpaces(value)
    context.state.selectedProjectEstimates = await context.effects.getProjectEstimates(value.projectId)
    const projectSpaces = await context.effects.getProjectSpaces(value.projectId)
    context.state.selectedProjectSpaces = projectSpaces
    toast('The list of spaces have been created successfully!')
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function submitUpdateProjectSpaceAction(
  context: Context,
  value: { projectId: string; spaceId: string; space: UpdateSpaceInput },
) {
  try {
    await context.effects.updateProjectSpace(value.spaceId, value.space)
    context.state.selectedProjectEstimates = await context.effects.getProjectEstimates(context.state.selectedProject.id)
    const projectSpaces = await context.effects.getProjectSpaces(value.projectId)
    context.state.selectedProjectSpaces = projectSpaces
    // toast('The space has been updated successfully!')
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function createSpaceCommentAction(context: Context, input: SpaceCommentInput) {
  try {
    await context.effects.createSpaceComment(input)
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function getAllSpaceCommentsAction(context: Context, id: string) {
  try {
    const spaceComments = await context.effects.getAllSpaceComments(id)
    return spaceComments
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function updateSelectedSpaceAction(context: Context, space: Space) {
  context.state.selectedSpace = cloneDeep(space)
}

export async function getAllItemTemplates(context: Context) {
  context.state.allItemTemplates = await context.effects.getAllItemTemplates()
}

export async function getAllSpacesItemTemplates(context: Context) {
  context.state.spacesItemTemplates = await context.effects.getAllSpacesItemTemplates()
}

export async function updateProjectSpacesAction(context: Context, input: any) {
  try {
    // TODO: Add effects call after back end functions are created
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function updateSpaceListAction(context: Context, input: UpdateSpaceListInput[]) {
  try {
    context.effects.updateSpaceLists(input)
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function updateItemIndexAction(context: Context, input: UpdateItemIndexInput[]) {
  try {
    await context.effects.updateItemIndex(input)
    const selectedProjectId = context.state.selectedProject.id
    const projectSpaces = await context.effects.getProjectSpaces(selectedProjectId)
    context.state.selectedProjectSpaces = projectSpaces
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function copySpaceAction(context: Context, id: string) {
  try {
    await context.effects.copySpace(id)
    const selectedProjectId = context.state.selectedProject.id
    const projectSpaces = await context.effects.getProjectSpaces(selectedProjectId)
    context.state.selectedProjectSpaces = projectSpaces
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}
