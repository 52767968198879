import React from 'react'
import { SelectedProjectRole } from 'theorem-lib/src/entities/enums/SelectedProjectRole'
import { useAppState } from '../../../presenter'
import { AddTasklistDropdownTemplate } from './AddTasklistDropdownTemplate'

export function AddTasklistDropdown() {
  const { selectedProjectRole } = useAppState()

  const AddTasklistDropdownScreen = () => {
    switch (selectedProjectRole) {
      case SelectedProjectRole.Admin:
      case SelectedProjectRole.Lead:
      case SelectedProjectRole.Editor:
        return <AddTasklistDropdownTemplate />
      case SelectedProjectRole.Collaborator:
        return (
          <AddTasklistDropdownTemplate
            hidePredefinedTemplatesList
          />
        )
      default:
        return <AddTasklistDropdownScreen />
    }
  }
  return <AddTasklistDropdownScreen />
}
