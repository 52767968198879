import React from 'react'
import { SelectedProjectRole } from 'theorem-lib/src/entities/enums/SelectedProjectRole'
import { useAppState } from '../../../presenter'
import { ProjectTeamTemplate } from './ProjectTeamTemplate'

export const ProjectTeam = () => {
  const { selectedProjectRole } = useAppState()

  const ProjectTeamScreen = () => {
    switch (selectedProjectRole) {
      case SelectedProjectRole.Admin:
      case SelectedProjectRole.Lead:
        return <ProjectTeamTemplate />
      case SelectedProjectRole.Editor:
        return (
          <ProjectTeamTemplate
            hideHeaderDropDown
            disableRoleSelection
            disableDeleteProjectMembers
          />
        )
      case SelectedProjectRole.Collaborator:
        return (
          <ProjectTeamTemplate
            hideHeaderDropDown
            disableRoleSelection
            disableReSendInvite
            readOnlyProjectStage
            collaboratorInvitationSlideOut
            disableDeleteProjectMembers
          />
        )
      default:
        return <>Invalid Role</>
    }
  }
  return <ProjectTeamScreen />
}
