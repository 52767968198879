import { ApolloClient, ApolloError, InMemoryCache, ServerError } from '@apollo/client'
import config from '../config'
import {
  AcceptProjectInvitationDocument,
  DeleteMemberDocument,
  DenyProjectInvitationDocument,
  InviteCollaboratorsDocument,
  InviteMembersDocument,
  InviteProjectCollaboratorsListInput,
  ListUsersForProjectInviteDocument,
  ProjectMemberInvite,
  ResendInvitationDocument,
  SendWelcomeToFolioDocument,
  TenantsDetails,
  TenantsDetailsInput,
  UpdateProjectMemberRoleDocument,
  User,
  ViewProjectCountDocument,
  ViewUserInvitationsDocument,
} from '../generated/api'
import { UnauthenticatedError, UnexpectedError } from '../presenter/errors'

function isApolloError(err: unknown | ApolloError): err is ApolloError {
  return (err as ApolloError).graphQLErrors !== undefined
}

function isServerError(err: unknown | ServerError): err is ServerError {
  return (err as ServerError).name == 'ServerError'
}

function graphqlErrorHandler(err: unknown | ApolloError) {
  if (isApolloError(err)) {
    const networkError = err.networkError
    if (isServerError(networkError)) {
      if (networkError.statusCode == 401) throw new UnauthenticatedError()
      if (networkError.statusCode == 404) throw new UnexpectedError()
    }
  }
}

const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  credentials: 'include',
  uri: config.appApiGraphqlUrl,
})

export const updateProjectMemberRole = async (projectId: string, memberId: string, roleId: string): Promise<void> => {
  try {
    await apolloClient.mutate({
      mutation: UpdateProjectMemberRoleDocument,
      variables: { input: { memberId, projectId, roleId } },
    })
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}
export const deleteProjectMember = async (projectId: string, memberId: string): Promise<void> => {
  try {
    await apolloClient.mutate({
      mutation: DeleteMemberDocument,
      variables: { input: { memberId, projectId } },
    })
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const resendInvitation = async (
  userId: string,
  projectId: string,
  tenant?: Omit<TenantsDetails, '__typename'>,
) => {
  try {
    await apolloClient.mutate({
      mutation: ResendInvitationDocument,
      variables: { input: { projectId, tenant, userId } },
    })
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const inviteMembersToProject = async (
  projectId: string,
  membersList: ProjectMemberInvite[],
  tenant: TenantsDetailsInput,
): Promise<void> => {
  try {
    await apolloClient.mutate({
      mutation: InviteMembersDocument,
      variables: { input: { membersList, projectId, tenant } },
    })
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const acceptInvitation = async (projectId: string, tenantDetails: TenantsDetailsInput): Promise<boolean> => {
  const tenant: TenantsDetailsInput = {
    code: tenantDetails.code,
    email: tenantDetails.email,
    id: tenantDetails.id,
    licenses: tenantDetails.licenses,
    tenantName: tenantDetails.tenantName,
    used: tenantDetails.used,
  }

  try {
    const { data } = await apolloClient.query({
      fetchPolicy: 'no-cache',
      query: AcceptProjectInvitationDocument,
      variables: { projectId, tenant },
    })
    return data.acceptInvitation
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const denyInvitation = async (projectId: string): Promise<boolean> => {
  try {
    const { data } = await apolloClient.query({
      fetchPolicy: 'no-cache',
      query: DenyProjectInvitationDocument,
      variables: { projectId },
    })
    return data.denyInvitation
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const viewUserInvitations = async (): Promise<Array<{ id: string; name: string }>> => {
  try {
    const { data } = await apolloClient.query({
      fetchPolicy: 'no-cache',
      query: ViewUserInvitationsDocument,
    })

    return data.viewUserInvitations
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const viewProjectCount = async (): Promise<number> => {
  try {
    const { data } = await apolloClient.query({
      fetchPolicy: 'no-cache',
      query: ViewProjectCountDocument,
    })
    return data.viewProjectCount
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const listUsersForProjectInvite = async (projectId: string): Promise<User[]> => {
  try {
    const { data } = await apolloClient.query({
      fetchPolicy: 'no-cache',
      query: ListUsersForProjectInviteDocument,
      variables: { projectId },
    })
    return data.listUsersForProjectInvite
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const inviteCollaborators = async (input: InviteProjectCollaboratorsListInput): Promise<void> => {
  try {
    const { data } = await apolloClient.query({
      fetchPolicy: 'no-cache',
      query: InviteCollaboratorsDocument,
      variables: { input },
    })
    return data.listUsersForProjectInvite
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const sendWelcomeToFolioEmail = async (userId: string, projectId: string): Promise<User[]> => {
  try {
    const { data } = await apolloClient.query({
      fetchPolicy: 'no-cache',
      query: SendWelcomeToFolioDocument,
      variables: { projectId, userId },
    })
    return data.listUsersForProjectInvite
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}
