import { ApolloClient, ApolloError, InMemoryCache, ServerError } from '@apollo/client'
import { sortBy } from 'lodash'
import config from '../config'
import { Industry, ListIndustriesDocument } from '../generated/api'
import { UnauthenticatedError, UnexpectedError } from '../presenter/errors'

function isApolloError(err: unknown | ApolloError): err is ApolloError {
  return (err as ApolloError).graphQLErrors !== undefined
}

function isServerError(err: unknown | ServerError): err is ServerError {
  return (err as ServerError).name == 'ServerError'
}

function graphqlErrorHandler(err: unknown | ApolloError) {
  if (isApolloError(err)) {
    const networkError = err.networkError
    if (isServerError(networkError)) {
      if (networkError.statusCode == 401) throw new UnauthenticatedError()
      if (networkError.statusCode == 404) throw new UnexpectedError()
    }
  }
}

const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  credentials: 'include',
  uri: config.appApiGraphqlUrl,
})

export const getIndustries = async (): Promise<Industry[]> => {
  try {
    const { data } = await apolloClient.query({ fetchPolicy: 'no-cache', query: ListIndustriesDocument })
    return sortBy(data.listIndustries, ['name'])
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}
