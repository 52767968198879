import React from 'react'
import { useAppState } from '../../presenter'
import { ProjectsTemplate } from './ProjectsTemplate'

export const Projects = () => {
  const { isAdmin, isMember, isParticipant } = useAppState()

  return (
    <>
      {isAdmin
        ? <ProjectsTemplate />
        : isMember
        ? <ProjectsTemplate />
        : isParticipant
        ? (
          <ProjectsTemplate
            hideClientSummaryDropdown
            showParticipantProjectsEmptyState
          />
        )
        : <>Invalid Role</>}
    </>
  )
}
