import React, { useState } from 'react'
import CheckIcon from 'theorem-lib/src/assets/icons/check.svg'
import TextInput from 'theorem-lib/src/components/atoms/Input/TextInput'
import { useActions } from '../../../presenter'

type WorkbookTaskProps = {
  sectionId: string
  taskAddedFn: () => void
  cancelFn: () => void
}

export function TempWorkbookTask(props: WorkbookTaskProps) {
  const { createTaskAction } = useActions()
  const [taskName, setTaskName] = useState('')
  const [loading, setLoading] = useState(false)

  const handleCreateTask = async () => {
    if (taskName && !loading) {
      setLoading(true)
      await createTaskAction({
        sectionId: props.sectionId,
        taskName: taskName,
      })
      props.taskAddedFn()
    } else {
      props.cancelFn()
    }
  }

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      handleCreateTask()
    }
  }

  return (
    <div className='flex border border-solid border-gray-300 rounded-lg p-4 bg-white mb-2 relative overflow-auto click-ignore'>
      <div className='mr-4 align items-center flex click-ignore'>
        <div className='w-6 h-6 text-center rounded-full border border-[#d9c3c1] text-[#f0e7e6] cursor-not-allowed click-ignore'>
          <CheckIcon className='inline -mt-1 pointer-events-none' />
        </div>
      </div>
      <div className='flex-grow items-center flex max-w-[43%] mr-4 click-ignore'>
        <TextInput
          hasAutoFocus
          type='text'
          name='tmp_task'
          label='Add New Task'
          onBlur={handleCreateTask}
          onKeyUp={handleKeyUp}
          onChange={(e) => setTaskName(e.target.value)}
        />
      </div>
    </div>
  )
}
