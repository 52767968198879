import { ApolloClient, ApolloError, InMemoryCache, ServerError } from '@apollo/client'
import { omit } from 'lodash'
import config from '../config'
import {
  AddRoleDocument,
  AdminUpdateUserDocument,
  CollaboratorInformation,
  GetDisplayUserDocument,
  GetDisplayUsersByUserIdsDocument,
  GetUsersByUserIdsDocument,
  GetUsersDocument,
  GetUsersForProjectInviteDocument,
  InviteCollaboratorsToFolioDocument,
  InviteUserDocument,
  LoadCurrentUserDocument,
  Member,
  RemoveRoleDocument,
  ResendInviteLinkDocument,
  TenantsDetails,
  UpdateUserDocument,
  User,
} from '../generated/api'
import { UnauthenticatedError, UnexpectedError } from '../presenter/errors'

const client = new ApolloClient({
  cache: new InMemoryCache(),
  credentials: 'include',
  uri: config.identityGraphqlUrl,
})

function isApolloError(err: unknown | ApolloError): err is ApolloError {
  return (err as ApolloError).graphQLErrors !== undefined
}

function isServerError(err: unknown | ServerError): err is ServerError {
  return (err as ServerError).name == 'ServerError'
}

function graphqlErrorHandler(err: unknown | ApolloError) {
  if (isApolloError(err)) {
    const networkError = err.networkError
    if (isServerError(networkError)) {
      if (networkError.statusCode == 401) throw new UnauthenticatedError()
      if (networkError.statusCode == 404) throw new UnexpectedError()
    }
  }
}

export const getUsers = async (): Promise<User[]> => {
  try {
    const { data } = await client.query({ fetchPolicy: 'no-cache', query: GetUsersDocument })
    return data.getUsers
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const getUsersByUserIds = async (ids: string[]): Promise<User[]> => {
  try {
    const { data } = await client.query({
      fetchPolicy: 'no-cache',
      query: GetUsersByUserIdsDocument,
      variables: { ids },
    })
    return data.getUsersByUserIds
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const getDisplayUser = async (id: string): Promise<User> => {
  try {
    const { data } = await client.query({ fetchPolicy: 'no-cache', query: GetDisplayUserDocument, variables: { id } })
    return data.getDisplayUser
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const getDisplayUsersByUserIds = async (ids: string[]): Promise<User[]> => {
  try {
    const { data } = await client.query({
      fetchPolicy: 'no-cache',
      query: GetDisplayUsersByUserIdsDocument,
      variables: { ids },
    })
    return data.getDisplayUsersByUserIds
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const getAuthenticatedUser = async (): Promise<User> => {
  try {
    const { data } = await client.query({ fetchPolicy: 'no-cache', query: LoadCurrentUserDocument })
    return data.currentUser
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const updateUser = async (user: User): Promise<void> => {
  try {
    await client.mutate({ mutation: UpdateUserDocument, variables: { ...omit(user, ['id']) } })
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const adminUpdateUser = async (user: User): Promise<void> => {
  try {
    await client.mutate({
      mutation: AdminUpdateUserDocument,
      variables: {
        id: user.id,
        userInfo: { ...omit(user, ['id', 'securityRoles']), __typename: undefined },
      },
    })
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const inviteUser = async (email: string, firstName: string, securityRoles: Array<string>): Promise<void> => {
  try {
    await client.mutate({ mutation: InviteUserDocument, variables: { email, firstName, securityRoles } })
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const reInviteUser = async (user: User): Promise<void> => {
  try {
    await client.mutate({ mutation: ResendInviteLinkDocument, variables: { email: user.email, id: user.id } })
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const addRoleToUser = async (user: User, role: string): Promise<void> => {
  try {
    await client.mutate({ mutation: AddRoleDocument, variables: { id: user.id, role } })
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const removeRoleFromUser = async (user: User, role: string): Promise<void> => {
  try {
    await client.mutate({ mutation: RemoveRoleDocument, variables: { id: user.id, role } })
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const updateAuthenticatedUser = async (user: User): Promise<void> => {
  try {
    await client.mutate({
      mutation: UpdateUserDocument,
      variables: {
        userInfo: { ...omit(user, ['id', 'status', 'isTheoremAdmin', 'securityRoles']), __typename: undefined },
      },
    })
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const getUsersForProjectInvite = async (): Promise<Array<Member>> => {
  try {
    const { data } = await client.query({
      fetchPolicy: 'no-cache',
      query: GetUsersForProjectInviteDocument,
    })
    return data.getUsersForProjectInvite
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const inviteCollaboratorsToFolio = async (
  collaborators: CollaboratorInformation[],
  tenant: TenantsDetails,
): Promise<void> => {
  try {
    await client.mutate({
      mutation: InviteCollaboratorsToFolioDocument,
      variables: { collaborators, tenant },
    })
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}
