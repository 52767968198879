import React, { useEffect, useState } from 'react'
import TextInput from 'theorem-lib/src/components/atoms/Input/TextInput'
import { CheckboxField, DateField, RadioField, Task, TaskField, TextField } from '../../../generated/api'
import { useActions } from '../../../presenter'

export function WorkbookFieldGroup(props: { task: Task }) {
  const initialAnswers = props.task.taskAnswer ? { ...props.task.taskAnswer.values } : {}
  const { createOrUpdateTaskAnswerAction } = useActions()
  const [textValues, setTextValues] = useState<Record<string, string>>(props.task.taskAnswer?.values || {})

  useEffect(() => {
    setTextValues(props.task.taskAnswer?.values || {})
  }, [props.task.taskAnswer?.values])

  const generateFalseAnswers = (task: Task) => {
    const ans: Record<string, unknown> = {}
    if (task && task.fields) {
      for (const field of task.fields) {
        ans[field.name] = false
      }
    }
    return ans
  }

  const handleTextChange = (e: any, key: string) => {
    const values: Record<string, string> = { ...textValues }
    values[key] = e.target.value
    setTextValues(values)
  }

  const handleTextBlur = (key: string) => {
    const value = textValues[key]
    const fields: Record<string, unknown> = { ...initialAnswers }
    fields[key] = value
    createOrUpdateTaskAnswerAction({ taskId: props.task.id, values: fields })
  }

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value: string | boolean = e.target.value
    const name = e.target.name
    const HTMLtype = e.target.attributes.getNamedItem('type')?.value
    let fields: Record<string, unknown> = { ...initialAnswers }
    if (HTMLtype === 'checkbox') {
      const allFieldsFalse = generateFalseAnswers(props.task)
      fields = Object.assign(allFieldsFalse, fields)
      value = !fields[name]
    }
    fields[name] = value
    createOrUpdateTaskAnswerAction({ taskId: props.task.id, values: fields })
  }

  const getAnswer = (name: string) => {
    if (props.task && props.task.taskAnswer && props.task.taskAnswer.values && props.task.taskAnswer.values[name]) {
      return props.task.taskAnswer.values[name]
    }
    return false
  }

  const renderRadioField = (radio: RadioField, answer: string) => {
    if (radio && radio.options) {
      return radio.options.map(option => (
        <label className='inline-block mr-4 my-2' key={option.value + option.label}>
          <input
            className='form-checkbox checked:bg-cta-200 checked:text-cta-200 outline-none focus:outline-none'
            type='radio'
            value={option.value}
            name={radio.name}
            onChange={handleFieldChange}
            checked={option.value === answer}
          />
          <span className='ml-2'>{option.label}</span>
        </label>
      ))
    }
  }

  const renderFields = (fields: TaskField[]) => {
    return (
      <form>
        {fields.map(field => {
          switch (field.type) {
            case 'area': {
              return (
                <>
                  <label htmlFor={field.name}>{(field as TextField).label}</label>
                  <textarea
                    className='w-full'
                    id={field.name}
                    name={field.name}
                    key={field.name}
                    onChange={(e) => handleTextChange(e, field.name)}
                    onBlur={() => handleTextBlur(field.name)}
                    value={textValues[field.name]}
                  />
                </>
              )
            }
            case 'text': {
              return (
                <TextInput
                  name={field.name}
                  label={(field as TextField).label}
                  key={field.name}
                  onChange={(e) => handleTextChange(e, field.name)}
                  onBlur={() => handleTextBlur(field.name)}
                  value={textValues[field.name]}
                />
              )
            }
            case 'radio': {
              const answer = getAnswer(field.name)
              return <div key={field.name}>{renderRadioField(field as RadioField, answer)}</div>
            }
            case 'checkbox': {
              const answer = getAnswer(field.name)
              return (
                <label className='inline-block mr-4 my-2' key={field.name + (field as CheckboxField).label}>
                  <input
                    className='form-checkbox'
                    type='checkbox'
                    name={field.name}
                    onChange={handleFieldChange}
                    checked={answer}
                  />
                  <span className='ml-2'>{(field as CheckboxField).label}</span>
                </label>
              )
            }
            case 'date': {
              return (
                <TextInput
                  name={field.name}
                  label={(field as DateField).label}
                  key={field.name}
                  type='date'
                  onChange={(e) => handleTextChange(e, field.name)}
                  onBlur={() => handleTextBlur(field.name)}
                  value={textValues[field.name]}
                />
              )
            }
          }
        })}
      </form>
    )
  }

  return (
    <>
      {props.task.fields && renderFields(props.task.fields)}
    </>
  )
}
