import { CloudUploadIcon } from '@heroicons/react/solid'
import React, { useState } from 'react'
import CaseIcon from 'theorem-lib/src/assets/icons/case.svg'
import TrashIcon from 'theorem-lib/src/assets/icons/trash.svg'
import { ImageCropper } from 'theorem-lib/src/components/atoms/ImageCropper/ImageCropper'
import { useActions, useAppState } from '../../../presenter'
import { LoadingSpinner } from '../../atoms/Loading/LoadingSpinner'
import Select from '../../atoms/Select/Select'
import { Wizard } from '../../atoms/Wizard/Wizard'

export const ClientNewWizard = () => {
  const { clientNew, currentModal, industries, validationErrors } = useAppState()
  const {
    cleanProjectCreation,
    displayModalAction,
    setProjectCreationClientId,
    submitClientNewAction,
    updateClientNewAction,
    uploadClientImageAction,
  } = useActions()

  const [loading, setLoading] = useState<boolean>(false)

  const goBack = () => {
    displayModalAction('GeneralProjectDetailsWizard')
  }

  const handleSubmit = async () => {
    setLoading(true)
    const clientId = await submitClientNewAction()
    setLoading(false)
    if (clientId) SaveProject(clientId.id)
  }

  const SaveProject = (clientId: string) => {
    setProjectCreationClientId(clientId)
    displayModalAction('GeneralProjectDetailsWizard')
  }

  const onClose = () => cleanProjectCreation()

  const Footer = () => {
    return (
      <>
        <button
          className='bg-[#F1F1F1] items-center text-[#35697D] px-3 rounded-lg font-semibold mr-2 h-10'
          onClick={() => goBack()}
        >
          {'<-'} Back to details
        </button>
        <button
          className='bg-cta-200 items-center text-white px-3 rounded-lg font-semibold mr-2 h-10'
          onClick={() => handleSubmit()}
        >
          Create Client
        </button>
      </>
    )
  }
  return (
    <Wizard
      isOpen={currentModal === 'ClientNewWizard'}
      onClose={onClose}
      footer={<Footer />}
    >
      {loading
        ? (
          <div className='translate-y-48'>
            <LoadingSpinner
              title='Creating your Client'
              subtitle='Don&lsquo;t navigate away from this page'
            />
          </div>
        )
        : (
          <div className='flex flex-col justify-start items-left w-full sm:w-full pl-8'>
            <div className='text-primary-100 text-xl font-bold mb-8 text-left'>
              New Client
            </div>
            <div className='overflow-x-visible grid grid-cols-1 gap-4 scroll-smooth'>
              <div className='mb-8'>
                {clientNew.logoUrl && (
                  <img
                    src={clientNew.logoUrl}
                    className='bg-primary-500 w-24 h-24 rounded-full inline-block align-middle'
                  />
                )}
                {!clientNew.logoUrl && (
                  <div className='bg-primary-500 w-24 h-24 rounded-full  text-primary-300 inline-flex flex-row  justify-center items-center'>
                    <CaseIcon />
                  </div>
                )}

                <div className='ml-8 inline-block align-middle'>
                  <label htmlFor='cropper' className='text-cta-200 cursor-pointer font-normal'>
                    <CloudUploadIcon className='w-5 inline-block mr-1' />Select image
                  </label>

                  {clientNew.logoUrl && (
                    <button
                      type='button'
                      className=' text-primary-300 mt-2'
                      onClick={() => updateClientNewAction({ client: { ...clientNew, logoUrl: '' } })}
                    >
                      <TrashIcon className='w-5 inline-block mr-1' />Remove Image
                    </button>
                  )}
                </div>
              </div>

              <ImageCropper
                size={300}
                setCroppedImageFile={(file) => uploadClientImageAction({ avatarFile: file, client: clientNew })}
              />

              <div className='mb-8 relative'>
                <input
                  value={clientNew.name || ''}
                  onChange={(event) => updateClientNewAction({ client: { ...clientNew, name: event.target.value } })}
                  id='name'
                  name='name'
                  type='text'
                  placeholder=' '
                  maxLength={52}
                />
                <label htmlFor='name'>
                  Client Name
                </label>
                <p className='text-error'>{validationErrors.get('clientName')}</p>
              </div>

              <div className='mb-8 pr-96'>
                <Select
                  label='Industry Type'
                  data={industries.map(industry => ({ key: industry.id, value: industry.name }))}
                  onChange={industry => {
                    const updatedIndustries = [...clientNew.industriesIds || []]
                    updatedIndustries.push(industry.key)
                    updateClientNewAction({ client: { ...clientNew, industriesIds: updatedIndustries } })
                  }}
                />
              </div>

              <p className='text-error'>{validationErrors.get('industriesIds')}</p>
            </div>
          </div>
        )}
    </Wizard>
  )
}
