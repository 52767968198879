import { SecurityRole } from '../../../../app/client/src/generated/api'
import { BusinessRolesEnum } from '../entities/enums/BusinessRoles'

export function getBusinessRolesBySecurityRole(securityRole: SecurityRole): BusinessRolesEnum[] {
  switch (securityRole) {
    case SecurityRole.Admin:
    case SecurityRole.Member:
      return [
        BusinessRolesEnum.Sales,
        BusinessRolesEnum.SalesSupport,
        BusinessRolesEnum.Design,
        BusinessRolesEnum.ProjectManagement,
        BusinessRolesEnum.Operations,
        BusinessRolesEnum.Administrative,
        BusinessRolesEnum.Other,
      ]
    case SecurityRole.Participant:
      return [
        BusinessRolesEnum.Client,
        BusinessRolesEnum.RealEstate,
        BusinessRolesEnum.Architect,
        BusinessRolesEnum.ADDesigner,
        BusinessRolesEnum.Contractor,
        BusinessRolesEnum.Other,
      ]
    default:
      return []
  }
}
