import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useState } from 'react'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import Select from 'react-select'
import TrashIconGrey from 'theorem-lib/src/assets/icons/trash-icon-grey.svg'
import { DiscIncInput, SpaceItemInput } from '../../../generated/api'
import { useActions, useAppState } from '../../../presenter'
import { ManageDiscountFormValidator } from '../../../validators/manageDiscountFormValidator'
import { DiscInc } from '../../atoms/Discount/DiscInc'
import { serviceStyles } from '../../atoms/ReactSelectStyling/selectStyle'
import { SlideOut } from '../../atoms/SlideOut/SlideOut'
import { SlideOutFooter } from '../../atoms/SlideOutFooter/SlideoutFooter'
import { RemoveDiscIncModal } from '../Modals/RemoveDiscIncModal'

enum discIncOptions {
  disc = 'Discount',
  inc = 'Increase',
}

export function ManageSpaceDiscountSlideOut() {
  const {
    displayModalAction,
    displaySlideOutAction,
    submitProjectDiscIncEditAction,
    submitUpdateProjectSpaceAction,
  } = useActions()
  const { selectedProject, selectedProjectSpaces } = useAppState()

  const selectOptions = [
    {
      id: 1,
      typeChange: 'Discount',
    },
    {
      id: 2,
      typeChange: 'Increase',
    },
  ]

  const prevDiscIncAmount = {
    type: 'initial',
    value: 0,
  }

  const [selectOpen, setSelectOpen] = useState(true)
  const [saveOpen, setSaveOpen] = useState(true)
  const [showRemoveModal, setShowRemoveModal] = useState(false)
  const indexValue = 0

  const methods = useForm({
    defaultValues: {
      values: [{
        id: 0,
        percentage: '',
        typeChange: 'initial',
      }],
    },
    resolver: yupResolver(ManageDiscountFormValidator),
  })

  const { control, getValues } = methods
  const { fields, remove, replace } = useFieldArray(
    {
      control,
      name: 'values',
    },
  )

  useEffect(() => {
    if (selectedProject.discountPercentage != null && selectedProject.increasePercentage === null) {
      setSelectOpen(false)
      setSaveOpen(false)
      methods.reset({
        values: [{
          id: 1,
          percentage: selectedProject.discountPercentage.toString(),
          typeChange: 'Discount',
        }],
      })
    } else if (selectedProject.increasePercentage != null && selectedProject.discountPercentage === null) {
      setSelectOpen(false)
      setSaveOpen(false)
      methods.reset({
        values: [{
          id: 2,
          percentage: selectedProject.increasePercentage.toString(),
          typeChange: 'Increase',
        }],
      })
    } else if (selectedProject.increasePercentage === null && selectedProject.discountPercentage === null) {
      setSelectOpen(true)
    }
  }, [])

  const [isDirty, setIsDirty] = useState<boolean>(false)

  const onRemove = async () => {
    if (selectOpen) {
      remove(indexValue)
    } else {
      setShowRemoveModal(true)
    }
  }

  const onConfirmRemoveDiscInc = async () => {
    if (selectedProject.discountPercentage != null && selectedProject.increasePercentage === null) {
      prevDiscIncAmount.value = selectedProject.discountPercentage
      prevDiscIncAmount.type = discIncOptions.disc
    } else if (selectedProject.increasePercentage != null && selectedProject.discountPercentage === null) {
      prevDiscIncAmount.value = selectedProject.increasePercentage
      prevDiscIncAmount.type = discIncOptions.inc
    }

    const updatedDiscIncInput: DiscIncInput = {
      discountPercentage: null,
      increasePercentage: null,
      projectId: selectedProject.id,
    }

    await submitProjectDiscIncEditAction(updatedDiscIncInput)

    if (prevDiscIncAmount.type === discIncOptions.disc) {
      const percentTotal = 100 - prevDiscIncAmount.value

      selectedProjectSpaces.forEach(space => {
        const newItems: SpaceItemInput[] = []
        space.items?.forEach(item => {
          const updatedItem = {
            highPrice: (item.highPrice / percentTotal) * 100,
            id: item.id,
            itemTemplatesId: item.itemTemplatesId,
            lowPrice: (item.lowPrice / percentTotal) * 100,
            midPrice: (item.midPrice / percentTotal) * 100,
            name: item.name,
            quantity: item.quantity,
          }
          newItems.push(updatedItem)
        })

        const newSpace = {
          description: space.description as string,
          discountPercentage: null,
          image: space.image as string,
          increasePercentage: null,
          items: newItems,
          name: space.name,
          quantity: space.quantity,
        }

        submitUpdateProjectSpaceAction({ projectId: selectedProject.id, space: newSpace, spaceId: space.id })
      })
    } else if (prevDiscIncAmount.type === discIncOptions.inc) {
      const percentTotal = prevDiscIncAmount.value + 100

      selectedProjectSpaces.forEach(space => {
        const newItems: SpaceItemInput[] = []
        space.items?.forEach(item => {
          const updatedItem = {
            highPrice: (item.highPrice / percentTotal) * 100,
            id: item.id,
            itemTemplatesId: item.itemTemplatesId,
            lowPrice: (item.lowPrice / percentTotal) * 100,
            midPrice: (item.midPrice / percentTotal) * 100,
            name: item.name,
            quantity: item.quantity,
          }
          newItems.push(updatedItem)
        })

        const newSpace = {
          description: space.description as string,
          discountPercentage: null,
          image: space.image as string,
          increasePercentage: null,
          items: newItems,
          name: space.name,
          quantity: space.quantity,
        }

        submitUpdateProjectSpaceAction({ projectId: selectedProject.id, space: newSpace, spaceId: space.id })
      })
    }
    displaySlideOutAction('None')
    setShowRemoveModal(false)
  }

  const onKeepEditing = async () => {
    setShowRemoveModal(false)
  }

  const onSubmit = async () => {
    displaySlideOutAction('None')
    const currentDiscIncVal = {
      type: 'initial',
      value: 0,
    }
    let discountVal: number | null = null
    let increaseVal: number | null = null

    if (selectedProject.discountPercentage != null && selectedProject.increasePercentage === null) {
      currentDiscIncVal.value = selectedProject.discountPercentage
      currentDiscIncVal.type = discIncOptions.disc
    } else if (selectedProject.discountPercentage === null && selectedProject.increasePercentage != null) {
      currentDiscIncVal.value = selectedProject.increasePercentage
      currentDiscIncVal.type = discIncOptions.inc
    } else {
      currentDiscIncVal.value = 0
      currentDiscIncVal.type = 'initial'
    }

    if ((getValues().values[0].typeChange === discIncOptions.disc && getValues().values[0].percentage != 'null')) {
      discountVal = parseFloat(getValues().values[0].percentage)
      increaseVal = null
      const updatedDiscIncInput: DiscIncInput = {
        discountPercentage: parseFloat(getValues().values[0].percentage),
        increasePercentage: null,
        projectId: selectedProject.id,
      }
      await submitProjectDiscIncEditAction(updatedDiscIncInput)
      selectedProjectSpaces.forEach(space => {
        const newItems: SpaceItemInput[] = []
        space.items?.forEach(item => {
          const updatedItem = {
            highPrice: item.highPrice,
            id: item.id,
            itemTemplatesId: item.itemTemplatesId,
            lowPrice: item.lowPrice,
            midPrice: item.midPrice,
            name: item.name,
            quantity: item.quantity,
          }
          newItems.push(updatedItem)
        })

        const newSpace = {
          description: space.description as string,
          discountPercentage: discountVal,
          image: space.image as string,
          increasePercentage: increaseVal,
          items: newItems,
          name: space.name,
          quantity: space.quantity,
        }

        submitUpdateProjectSpaceAction({ projectId: selectedProject.id, space: newSpace, spaceId: space.id })
      })
    } else if (
      (getValues().values[0].typeChange === discIncOptions.inc && getValues().values[0].percentage != 'null')
    ) {
      discountVal = null
      increaseVal = parseFloat(getValues().values[0].percentage)
      const updatedDiscIncInput: DiscIncInput = {
        discountPercentage: null,
        increasePercentage: parseFloat(getValues().values[0].percentage),
        projectId: selectedProject.id,
      }

      await submitProjectDiscIncEditAction(updatedDiscIncInput)
      selectedProjectSpaces.forEach(space => {
        const newItems: SpaceItemInput[] = []
        space.items?.forEach(item => {
          const updatedItem = {
            highPrice: item.highPrice,
            id: item.id,
            itemTemplatesId: item.itemTemplatesId,
            lowPrice: item.lowPrice,
            midPrice: item.midPrice,
            name: item.name,
            quantity: item.quantity,
          }
          newItems.push(updatedItem)
        })

        const newSpace = {
          description: space.description as string,
          discountPercentage: discountVal,
          image: space.image as string,
          increasePercentage: increaseVal,
          items: newItems,
          name: space.name,
          quantity: space.quantity,
        }

        submitUpdateProjectSpaceAction({ projectId: selectedProject.id, space: newSpace, spaceId: space.id })
      })
    }
  }

  return (
    <SlideOut
      title='Manage Discounts and Increases'
      onClose={() => isDirty ? displayModalAction('CancelProjectEditModal') : displaySlideOutAction('None')}
    >
      <h2 className='mb-6 text-primary-200 font-normal'>
        Discounts and increases are ONLY applied to the cost of all spaces and items.
      </h2>
      {!selectOpen
        && (
          <h2 className='mb-6 text-primary-200 font-normal'>
            Existing discounts or increases must be deleted and saved before applying a new discount or increase.
          </h2>
        )}

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <ul>
            <div className='w-3/5 border-b border-primary-400'>
              <Select
                isDisabled={!selectOpen}
                className='discountIncreaseSelect'
                placeholder='Select one'
                options={selectOptions.map(option => ({
                  id: option.id,
                  label: option.typeChange,
                }))}
                styles={serviceStyles}
                onChange={(value) => {
                  setIsDirty(true)
                  replace({
                    id: value?.id,
                    percentage: '',
                    typeChange: value?.label,
                  })
                }}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    neutral10: 'none',
                    primary: 'gray',
                  },
                })}
              />
            </div>
            {fields.map((field, index) => {
              return (
                <li key={field.id} className='flex flex-row mb-3 mt-3'>
                  <DiscInc
                    name={field.typeChange}
                    id={field.id}
                    index={index}
                  />
                  {field.typeChange !== 'initial'
                    && (
                      <div className='mt-6 inline-block align-text-baseline'>
                        <button
                          className='ml-8 cursor-pointer px-2 py-2'
                          type='button'
                          onClick={() => onRemove()}
                        >
                          <TrashIconGrey />
                        </button>
                      </div>
                    )}
                </li>
              )
            })}
          </ul>
          <SlideOutFooter>
            {saveOpen && <button type='submit' className='button cta'>Save</button>}
          </SlideOutFooter>
        </form>
      </FormProvider>
      {showRemoveModal && (
        <RemoveDiscIncModal
          showRemoveDiscIncModal={showRemoveModal}
          onConfirmRemove={onConfirmRemoveDiscInc}
          onKeepEditing={onKeepEditing}
        />
      )}
    </SlideOut>
  )
}
