import { Document, Image, Page, View } from '@react-pdf/renderer'
import React from 'react'
import Html from 'react-pdf-html'
import { formatDate } from 'theorem-lib/src/helpers/format'
import folioLogo from '../../assets/Logos/folioLogo.png'
import { IndustriesEnum } from '../components/molecules/NewProjectCreation/IndustriesSelect'
import {
  OtherAdministrativeGoalsEnum,
  OtherBusinessGoalsEnum,
  OtherClinicalGoalsEnum,
  OtherElementsEnum,
  OtherHomeTypeEnum,
  OtherSpacesEnum,
  OtherWorkspaceTypeEnum,
} from '../generated/api'
import { getNameWithoutUnderScores } from './projectHelper'

export const GeneratePdf = (props: any) => {
  const element = (
    ` <html>
          <body>
            <div style="padding-bottom: 8px; color: #35697D; font-family: Helvetica-Bold;">
            Service Provider - ${props.project.tenantName}
            </div>
            
            <div style="padding-bottom: 8px; color: #35697D; font-family: Helvetica-Bold;">
              Basic Project Details
            </div>
            <div style="
              display: block; 
              font-family: Helvetica;
              padding-bottom: 8px;
              "
             >
              Project Name* - ${props.project.name}
            </div>
            
            <div style="font-weight: 400; 
              display: block; 
              font-family: Helvetica;
              padding-bottom: 8px;
              ">
              Client Name* - ${props.project.client}
            </div>
            <div style="font-weight: 400; 
            display: block; 
            font-family: Helvetica;
            padding-bottom: 8px;
            ">
            Move in Date* - ${formatDate(props.project.moveInDate, 'YYYY-MM-DD')}
            
          </div>
          <div style="font-weight: 400; 
          display: block; 
          font-family: Helvetica;
          padding-bottom: 8px;
          ">
          Furniture Budget - ${props.project.budget}
        </div>
        <div style="font-weight: 400; 
        display: block; 
        font-family: Helvetica;
        padding-bottom: 8px;
        ">
        Quantity of Buildings - ${props.project.quantity}
      </div>
      <div style="font-weight: 400; 
          display: block; 
          font-family: Helvetica;
          padding-bottom: 8px;
          ">
          Square Footage - ${props.project.sqrFootage}
        </div>
        ${
      (props.project.industryName === IndustriesEnum.Corporate
          || props.project.industryName === IndustriesEnum.Education
          || props.project.industryName === IndustriesEnum.Government
          || props.project.industryName === IndustriesEnum.Technology)
        ? (`
          <div style="padding-bottom: 8px; padding-top: 8px; color: #35697D; font-family: Helvetica-Bold;">
          Industry Details
        </div>
        <div style="font-weight: 400; 
          display: block; 
          font-family: Helvetica;
          padding-bottom: 8px;
          ">
          Industry* - ${props.project.industryName}
        </div>
        <div style="font-weight: 400; 
          display: block; 
          font-family: Helvetica;
          padding-bottom: 8px;
          ">
          Business Goals* :
        </div>
        <div style="padding-bottom: 16px;">
        ${
          props.project.industryDetails?.otherBusinessGoals?.values.map((goal: any, index: any) => {
            if (goal === OtherBusinessGoalsEnum.Other) {
              return `<li style="font-family: Helvetica;" key=${index}>${props.project.industryDetails
                ?.otherBusinessGoals?.other}</li>`
            } else {
              return `<li style="font-family: Helvetica;" key=${index}>${
                getNameWithoutUnderScores(goal.toString())
              }</li>`
            }
          }).join('')
        }
        </div>
        <div style="font-weight: 400; 
        display: block; 
        font-family: Helvetica;
        padding-bottom: 8px;
        ">
        Project Scale* - ${
          getNameWithoutUnderScores(props.project.industryDetails?.projectSize?.values.toString() ?? '')
        }
      </div>
      <div style="font-weight: 400; 
        display: block; 
        font-family: Helvetica;
        padding-bottom: 8px;
        ">
        Project Headcount* - ${props.project.scale}
      </div>
      <div style="font-weight: 400; 
        display: block; 
        font-family: Helvetica;
        padding-bottom: 8px;
        ">
        Types of Spaces* : 
      </div>
      <div style="padding-bottom: 16px;">
        ${
          props.project.industryDetails?.otherSpaces?.quantValues.map((space: any, index: any) => {
            if (space.values === OtherSpacesEnum.Other) {
              return `<li style="font-family: Helvetica;" key=${index}>${space.other} - ${space.amount}</li>`
            }
            return `<li style="font-family: Helvetica;" key=${index}>${
              getNameWithoutUnderScores(space.values.toString())
            } - ${space.amount + '\n'}</li>`
          }).join('')
        }
      </div>
      <div style="font-weight: 400; 
        display: block; 
        font-family: Helvetica;
        ">
        Client's Furniture Experience* - ${
          getNameWithoutUnderScores(
            props.project.industryDetails?.experienceLevel?.values.toString() ?? '',
          )
        }
      </div>`)
        : `<div></div>`
    }
          ${
      props.project.industryName === IndustriesEnum.Healthcare
        ? (
          `
          <div style="padding-bottom: 8px; padding-top: 8px; color: #35697D; font-family: Helvetica;">
          Industry Details
        </div>
        <div style="font-weight: 400; 
          display: block; 
          font-family: Helvetica;
          padding-bottom: 8px;
          ">
          Industry* - ${props.project.industryName}
        </div>
        <div style="font-weight: 400; 
          display: block; 
          font-family: Helvetica;
          padding-bottom: 8px;
          ">
          Facility Type* : 
        </div>
        <div>
           ${
            props.project.industryDetails?.otherFacilityTypes?.values.map((f: any, i: any) => {
              return `<li style="font-family: Helvetica;" key=${i}>${
                getNameWithoutUnderScores(f.toString()) + '\n'
              }</li>`
            }).join('')
          }
        </div>


        <div style="font-weight: 700; 
          font-family: Helvetica;
          display: block; 
          padding-top: 8px;
          padding-bottom: 8px;
          ">
          Administrative Goals* :
        </div>
        <div>
        ${
            props.project.industryDetails?.otherAdministrativeGoals?.values.map((goal: any, index: any) => {
              if (goal === OtherAdministrativeGoalsEnum.Other) {
                return (
                  `<li style="font-family: Helvetica;" key=${index}>${props.project.industryDetails
                    ?.otherAdministrativeGoals?.other}</li>`
                )
              } else {
                return `<li style="font-family: Helvetica;" key=${index}>${
                  getNameWithoutUnderScores(goal.toString()) + '\n'
                }</li>`
              }
            }).join('')
          }
        </div>
        <div style="font-weight: 400; 
        display: block; 
        font-family: Helvetica;
        padding-top: 8px;
        padding-bottom: 8px;
        ">
        Clinical Goals* : 
      </div>
      <div>
      ${
            props.project.industryDetails?.otherClinicalGoals?.values.map((goal: any, index: any) => {
              if (goal === OtherClinicalGoalsEnum.Other) {
                return `<li style="font-family: Helvetica;" key=${index}>${props.project.industryDetails
                  ?.otherClinicalGoals?.other}</li>`
              } else {
                return `<li style="font-family: Helvetica;" key=${index}>${
                  getNameWithoutUnderScores(goal.toString()) + '\n'
                }</li>`
              }
            }).join('')
          }
      </div>
        <div style="font-weight: 400; 
        display: block; 
        font-family: Helvetica;
        padding-bottom: 8px;
        padding-top: 8px;
        ">
        Project Scale* - ${
            getNameWithoutUnderScores(props.project.industryDetails?.projectSize?.values.toString() ?? '')
          }
      </div>
      <div style="font-weight: 400; 
        display: block; 
        font-family: Helvetica;
        padding-bottom: 8px;
        ">
        Project Headcount* - ${props.project.scale}
      </div>
      <div style="font-weight: 400; 
        display: block; 
        font-family: Helvetica;
        padding-bottom: 8px;
        ">
        Types of Spaces* :
      </div>
      <div>
        ${
            props.project.industryDetails?.otherSpaces?.values.map((space: any, index: any) => {
              if (space === OtherSpacesEnum.Other) {
                return `<li style="font-family: Helvetica;" key=${index}>${props.project.industryDetails?.otherSpaces
                  ?.other}</li>`
              }
              return `<li style="font-family: Helvetica;" key=${index}>${
                getNameWithoutUnderScores(space.toString()) + '\n'
              }</li>`
            }).join('')
          }
      </div>
      <div style="font-weight: 400; 
        display: block; 
        font-family: Helvetica;
        padding-top: 8px;
        ">
        Client's Furniture Experience* - ${
            getNameWithoutUnderScores(
              props.project.industryDetails?.experienceLevel?.values.toString() ?? '',
            )
          }
      </div>`
        )
        : `<div></div>`
    } ${
      props.project.industryName === IndustriesEnum.WorkFromHome
        ? (` <div style="padding-bottom: 8px; padding-top: 8px; color: #35697D; font-family: Helvetica-Bold;">
        Industry Details
      </div>
      <div style="font-weight: 400; 
        display: block; 
        font-family: Helvetica;
        padding-bottom: 8px;
        ">
        Industry* - ${props.project.industryName}
      </div>
      <div style="font-weight: 400; 
        display: block; 
        font-family: Helvetica;
        padding-bottom: 8px;
        ">
        Home Type* :
      </div>
      <div>
      ${
          props.project.industryDetails?.otherHomeType?.values.map((home: any, index: any) => {
            if (home === OtherHomeTypeEnum.Other) {
              return `<li style="font-family: Helvetica;" key=${index}>${props.project.industryDetails?.otherHomeType
                ?.other}</li>`
            } else {
              return `<li style="font-family: Helvetica;" key=${index}>${getNameWithoutUnderScores(home)}</li>`
            }
          }).join('')
        }
      </div>
      <div style="font-weight: 400; 
        display: block; 
        font-family: Helvetica;
        padding-top: 8px;
        padding-bottom: 8px;
        ">
        Work Space* : 
      </div>
      <div>
      ${
          props.project.industryDetails?.otherWorkspaceType?.values.map((workspace: any, index: any) => {
            if (workspace === OtherWorkspaceTypeEnum.Other) {
              return `<li style="font-family: Helvetica;" key=${index}>${props.project.industryDetails
                ?.otherWorkspaceType?.other}</li>`
            } else {
              return `<li style="font-family: Helvetica;" key=${index}>${
                getNameWithoutUnderScores(workspace.toString())
              }</li>`
            }
          }).join('')
        }
      </div>
      <div style="font-weight: 400; 
        display: block; 
        font-family: Helvetica;
        padding-top: 8px;
        padding-bottom: 8px;
        ">
        Elements* : 
      </div>
      <div>
      ${
          props.project.industryDetails?.otherElements?.values.map((goal: any, index: any) => {
            if (goal === OtherElementsEnum.Other) {
              return `<li style="font-family: Helvetica;" key=${index}>${props.project.industryDetails?.otherElements
                ?.other}</li>`
            } else {
              return `<li style="font-family: Helvetica;" key=${index}>${
                getNameWithoutUnderScores(goal.toString())
              }</li>`
            }
          }).join('')
        }
      </div>
      
      
      `)
        : `<div></div>`
    }
          </body>
    </html>`
  )

  return (
    <Document>
      <Page>
        <View>
          <Image style={{ height: '80px', width: '80px' }} src={folioLogo} />
          <Html>{element}</Html>
        </View>
      </Page>
    </Document>
  )
}
