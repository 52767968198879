import React from 'react'

interface chipProps {
  text?: string
  icon: React.ElementType
  iconBackgroundColor: string
}

export const Chip = ({
  iconBackgroundColor = 'bg-gray-200',
  ...props
}: chipProps): JSX.Element => {
  return (
    <div className='flex flex-wrap justify-center space-x-2'>
      <span
        className={`px-4 py-2 rounded-full text-[#35697D] font-semibold text-sm flex align-center w-max transition duration-300 ease
             ${iconBackgroundColor} `}
      >
        <div className='bg-transparent pr-2'>
          <props.icon />
        </div>
        {props.text}
      </span>
    </div>
  )
}
