import { CloudUploadIcon } from '@heroicons/react/solid'
import { find } from 'lodash'
import React from 'react'
import CaseIcon from 'theorem-lib/src/assets/icons/case.svg'
import TrashIcon from 'theorem-lib/src/assets/icons/trash.svg'
import { ImageCropper } from 'theorem-lib/src/components/atoms/ImageCropper/ImageCropper'
import { SecurityRole } from '../../../generated/api'
import { useActions, useAppState } from '../../../presenter'
import { SlideOut } from '../../atoms/SlideOut/SlideOut'
import { SlideOutFooter } from '../../atoms/SlideOutFooter/SlideoutFooter'

export function ClientEditSlideOut() {
  const { authenticatedUser, clientEdit, industries, validationErrors } = useAppState()
  const {
    closeClientEditAction,
    displayModalAction,
    submitClientEditAction,
    updateClientEditAction,
    uploadClientImageAction,
  } = useActions()

  const isAdmin = authenticatedUser.securityRoles.includes(SecurityRole.Admin)

  return (
    <SlideOut title='Manage Client' onClose={() => closeClientEditAction()}>
      <form
        onSubmit={async (event) => {
          event.preventDefault()
          await submitClientEditAction()
        }}
      >
        <div className='mb-8'>
          {clientEdit.logoUrl && (
            <img
              src={clientEdit.logoUrl}
              className='bg-primary-500 w-24 h-24 rounded-full inline-block align-middle'
            />
          )}
          {!clientEdit.logoUrl && (
            <div className='bg-primary-500 w-24 h-24 rounded-full  text-primary-300 inline-flex flex-row  justify-center items-center'>
              <CaseIcon />
            </div>
          )}

          <div className='ml-8 inline-block align-middle'>
            <label htmlFor='cropper' className='text-cta-200 cursor-pointer font-normal'>
              <CloudUploadIcon className='w-5 inline-block mr-1' />Select image
            </label>

            {clientEdit.logoUrl && (
              <button
                type='button'
                className=' text-primary-300 mt-2'
                onClick={() => updateClientEditAction({ client: { ...clientEdit, logoUrl: '' } })}
              >
                <TrashIcon className='w-5 inline-block mr-1' />Remove Image
              </button>
            )}
          </div>
        </div>

        <ImageCropper
          size={300}
          setCroppedImageFile={(file) => uploadClientImageAction({ avatarFile: file, client: clientEdit })}
        />

        <div className='mb-8 relative'>
          <input
            value={clientEdit.name || ''}
            onChange={(event) => updateClientEditAction({ client: { ...clientEdit, name: event.target.value } })}
            id='name'
            name='name'
            type='text'
            placeholder=' '
            maxLength={52}
          />
          <label htmlFor='name'>
            Client Name
          </label>
          <p className='text-error'>{validationErrors.get('clientName')}</p>
        </div>

        <div className='mb-8'>
          <label>
            Industries
          </label>
          {industries.map(industry => (
            <div key={industry.id}>
              <label className='inline-flex items-center my-2'>
                <input
                  className='form-checkbox'
                  type='checkbox'
                  name='industriesIds'
                  value={industry.id}
                  checked={!!find(clientEdit.industries, item => item?.id === industry.id)}
                  onChange={() => {
                    let updatedIndustries = [...clientEdit.industries || []]
                    if (find(clientEdit.industries, item => item?.id === industry.id)) {
                      updatedIndustries = clientEdit.industries?.filter(item => item?.id !== industry.id) || []
                    } else {
                      updatedIndustries.push(industry)
                    }
                    updateClientEditAction({ client: { ...clientEdit, industries: updatedIndustries } })
                  }}
                />
                <span className='ml-2 text-sm'>{industry.name}</span>
              </label>
            </div>
          ))}
        </div>

        <p className='text-error'>{validationErrors.get('industries')}</p>

        <SlideOutFooter>
          <button type='submit' className='button cta mx-2'>Save</button>
          {isAdmin
            && (
              <button type='button' className='button mx-2' onClick={() => displayModalAction('DeleteClientModal')}>
                Delete
              </button>
            )}
        </SlideOutFooter>
      </form>
    </SlideOut>
  )
}
