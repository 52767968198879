import React from 'react'
import { useFormContext } from 'react-hook-form'

import PercentSign from 'theorem-lib/src/assets/icons/percent-sign.svg'

import TextInput from '../TextInput/TextInput'

type DiscIncStateProps = {
  name: string
  id: string
  index: number
}

export enum templateOptions {
  discount = 'Discount',
  increase = 'Increase',
}

export const DiscInc = (props: DiscIncStateProps) => {
  const {
    formState: { errors },
    register,
    setValue,
  } = useFormContext()

  const SelectedDiscInc = (): JSX.Element => {
    switch (props.name) {
      case templateOptions.discount:
        setValue(`values.${props.index}.id`, props.id)
        setValue(`values.${props.index}.typeChange`, props.name)

        return (
          <div className='border-l-4 border-l-pink-700 pl-2'>
            <h1 className='text-lg font-bold text-gray-900'>{props.name}</h1>
            <div className='flex flex-row'>
              <TextInput
                hasAutoFocus={true}
                type='number'
                {...register(`values.${props.index}.percentage`)}
              />
              <PercentSign className='mt-2' />
            </div>
            <span className='flex flex-grow justify-start text-error font-medium text-xs'>
              {errors.values?.[props.index]?.percentage?.message}
            </span>
          </div>
        )
      case templateOptions.increase:
        setValue(`values.${props.index}.id`, props.id)
        setValue(`values.${props.index}.typeChange`, props.name)
        return (
          <div className='border-l-4 border-l-pink-700 pl-2'>
            <h1 className='text-lg font-bold text-gray-900'>{props.name}</h1>
            <div className='flex flex-row'>
              <TextInput
                hasAutoFocus={true}
                type='number'
                {...register(`values.${props.index}.percentage`)}
              />
              <PercentSign className='mt-2' />
            </div>
            <span className='flex flex-grow justify-start text-error font-medium text-xs'>
              {errors.values?.[props.index]?.percentage?.message}
            </span>
          </div>
        )

      default:
        return <></>
    }
  }
  return <SelectedDiscInc />
}
