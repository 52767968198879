import { CheckIcon } from '@heroicons/react/solid'
import { yupResolver } from '@hookform/resolvers/yup'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { cloneDeep, intersectionWith } from 'lodash'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import DownloadIcon from 'theorem-lib/src/assets/icons/download.svg'
import UploadIcon from 'theorem-lib/src/assets/icons/upload.svg'
import { NEWLY_ADDED_CLIENT_ID } from 'theorem-lib/src/common/constants'
import { ImageCropper } from 'theorem-lib/src/components/atoms/ImageCropper/ImageCropper'
import { formatDate } from 'theorem-lib/src/helpers/format'
import {
  ExperienceLevelEnum,
  Industry,
  IndustryCover,
  OtherAdministrativeGoalsEnum,
  OtherBusinessGoalsEnum,
  OtherClinicalGoalsEnum,
  OtherElementsEnum,
  OtherFacilityTypesEnum,
  OtherHomeTypeEnum,
  OtherSpacesEnum,
  OtherWorkspaceTypeEnum,
  Project,
  ProjectInput,
  ProjectSizeEnum,
  QuantValueSchemaInput,
} from '../../../generated/api'
import { GeneratePdf } from '../../../helpers/pdfHelper'
import { getNameWithoutUnderScores, isProjectCollaborator } from '../../../helpers/projectHelper'
import { useActions, useAppState } from '../../../presenter'
import { newProjectStateEnum } from '../../../types/project'
import {
  CorporateFormValidator,
  HealthcareFormValidator,
  WorkFromHomeFormValidator,
} from '../../../validators/manageProjectFormValidator'
import Card from '../../atoms/Card/Card'
import Select from '../../atoms/Select/Select'
import TextInput from '../../atoms/TextInput/TextInput'
import Carousel from '../../molecules/Carousel/Carousel'
import {
  IndustriesEnum,
  IndustriesSelect,
  InvalidIndustriesEnum,
} from '../../molecules/NewProjectCreation/IndustriesSelect'
import { ProjectHeader } from '../../molecules/ProjectHeader'
import { OtherOptions } from '../../organisms/SlideOuts/ProjectSlideOutTemplate'

export interface DetailProps {
  hideHeaderDropDown?: boolean
  hideHeaderDeleteOption?: boolean
  hideEditProjectDetailsButton?: boolean
  enableVisualPrefButton?: boolean
  readonly?: boolean
  readOnlyProjectStage?: boolean
}

export type ProjectFormType = {
  budget: number
  coverImageUrl?: string
  date?: string
  name: string
  client: { id: string; name: string }
  headCount: number
  industry: Industry
  industryAdministrativeGoals: OtherAdministrativeGoalsEnum[] | []
  industryAdministrativeGoalsOther: string
  industryBusinessGoals: OtherBusinessGoalsEnum[] | []
  industryBusinessGoalsOther: string
  industryClinicalGoals: OtherClinicalGoalsEnum[] | []
  industryClinicalGoalsOther: string
  industryExperienceLevel: ExperienceLevelEnum[] | []
  industryExperienceOther: string
  industryFacilityTypes: OtherFacilityTypesEnum[] | []
  industryFacilityTypesOther: string
  industryHomeType: OtherHomeTypeEnum[] | []
  industryHomeTypeOther: string
  industryProjectScale: ProjectSizeEnum[] | []
  industryProjectScaleOther: string
  industryOfficeElements: OtherElementsEnum[] | []
  industryOfficeElementsOther: string
  industryTypesOfSpaces: OtherSpacesEnum[] | []
  industryTypesOfSpacesOther: string
  industryWorkSpace: OtherWorkspaceTypeEnum[] | []
  industryWorkSpaceOther: string
  members: { id: string; imageUrl?: string; isSelected?: boolean; value: string }[]
  squareFootage: number
  quantity: number
  companyName?: string
}

export const ProjectDetailTemplates = (props: DetailProps) => {
  const {
    cleanNewClientStateAction,
    displaySlideOutAction,
    getTenantInfoByCodeAction,
    projectCreationStateMachine,
    submitUpdateProjectAction,
    toggleProjectDetailsEditMode,
  } = useActions()
  const {
    allClients,
    authenticatedUser,
    clientNew,
    clients,
    industries,
    isAdmin,
    isMember,
    isParticipant,
    members,
    newProjectCreation,
    projectDetailsEditMode,
    selectedProject,
    tenant,
  } = useAppState()

  const pdfObject = {
    budget: selectedProject.budget,
    client: selectedProject.client.name,
    industryDetails: selectedProject.industryDetails,
    industryName: selectedProject.industry.name,
    moveInDate: selectedProject.moveInDate,
    name: selectedProject.name,
    quantity: selectedProject.buildingsQty,
    scale: selectedProject.headCount,
    sqrFootage: selectedProject.squareFootage,
    tenantName: tenant.name,
  }

  const [uploadedFile, setUploadedFile] = useState<File>()
  const [uploadedImage, setUploadedImage] = useState<string | ArrayBuffer>('')

  const [industryCovers, setIndustryCovers] = useState<IndustryCover[]>(
    selectedProject.industry.covers || industries[0].covers || [],
  )

  const quantValues = selectedProject.industryDetails?.otherSpaces?.quantValues.map((ele) => {
    return {
      amount: ele.amount,
      other: ele.other,
      values: ele.values,
    }
  })

  const originalCoverURL = selectedProject.industry?.covers?.some((cover) =>
    cover.url === selectedProject.coverImageUrl
  )
  const UPLOADED_IMAGE_ID = 'uploaded'
  const originalCover: IndustryCover = {
    id: UPLOADED_IMAGE_ID,
    url: selectedProject.coverImageUrl,
  }
  const isCollaborator = isProjectCollaborator(selectedProject, authenticatedUser)
  let collabFile: File | undefined = undefined

  const filteredIndustries = industries.filter((industry) =>
    industry.name != InvalidIndustriesEnum.Amenity
    && industry.name != InvalidIndustriesEnum.Hospitality
    && industry.name != InvalidIndustriesEnum.MultifamilyHousing
    && industry.name != InvalidIndustriesEnum.WallsShadesFlooring
  )

  const [selectedCover, setSelectedCover] = useState<IndustryCover>(
    originalCoverURL
      ? selectedProject.coverImageUrl
        ? selectedProject.industry.covers?.find(cover => selectedProject.coverImageUrl === cover.url)
          || { id: '', url: '' }
        : { id: '', url: '' }
      : originalCover,
  )

  const findIndustry = newProjectCreation?.project?.industryId
    ? industries.find(e => e.id === newProjectCreation?.project?.industryId)?.name
    : undefined

  const [industryName, setIndustryName] = useState<string>(findIndustry ? findIndustry : selectedProject.industry.name)
  const clientsList = isMember ? allClients : clients

  useEffect(() => {
    // Removed for THEOR-496 - toggleProjectDetailsEditMode(false)
    !originalCoverURL ? setUploadedImage(selectedProject.coverImageUrl) : undefined
    const getTenantInfo = async () => {
      await getTenantInfoByCodeAction()
    }

    getTenantInfo()
  }, [])

  const initialValues: ProjectFormType = {
    budget: selectedProject.budget,
    client: newProjectCreation?.project?.clientId
      ? {
        id: newProjectCreation?.project?.clientId,
        name: clients.find(f => f.id === newProjectCreation?.project?.clientId)?.name ?? '',
      }
      : clientNew.name != ''
      ? { id: clientNew.clientId!, name: clientNew.name }
      : { id: selectedProject.client.id, name: selectedProject.client.name },
    companyName: selectedProject.companyName || '',
    coverImageUrl: selectedProject.coverImageUrl,
    date: newProjectCreation?.project?.moveInDate
      ? formatDate(newProjectCreation?.project?.moveInDate, 'YYYY-MM-DD')
      : selectedProject.moveInDate || selectedProject.moveInDate !== ''
      ? formatDate(selectedProject.moveInDate, 'YYYY-MM-DD')
      : undefined,
    headCount: selectedProject.headCount,
    industry: newProjectCreation?.project?.industryId
      ? {
        covers: industryCovers,
        id: newProjectCreation.project.industryId,
        name: industries.find(industry => industry.id === newProjectCreation?.project?.industryId)?.name ?? '',
      }
      : {
        covers: industryCovers,
        id: selectedProject.industry.id || industries[0].id,
        name: selectedProject.industry.name || industries[0].name,
      },
    industryAdministrativeGoals: newProjectCreation?.project?.industryDetails?.otherAdministrativeGoals?.values
      ? newProjectCreation?.project?.industryDetails?.otherAdministrativeGoals?.values
      : selectedProject.industryDetails?.otherAdministrativeGoals?.values ?? [],
    industryAdministrativeGoalsOther: newProjectCreation?.project?.industryDetails?.otherAdministrativeGoals?.other
      ? newProjectCreation?.project?.industryDetails?.otherAdministrativeGoals?.other
      : selectedProject.industryDetails?.otherAdministrativeGoals?.other
      ? selectedProject.industryDetails?.otherAdministrativeGoals?.other
      : OtherOptions.otherEmpty,
    industryBusinessGoals: newProjectCreation?.project?.industryDetails?.otherBusinessGoals?.values
      ? newProjectCreation?.project?.industryDetails?.otherBusinessGoals?.values
      : selectedProject.industryDetails?.otherBusinessGoals?.values ?? [],
    industryBusinessGoalsOther: newProjectCreation?.project?.industryDetails?.otherBusinessGoals?.other
      ? newProjectCreation?.project?.industryDetails?.otherBusinessGoals?.other
      : selectedProject.industryDetails?.otherBusinessGoals?.other
      ? selectedProject.industryDetails?.otherBusinessGoals?.other
      : OtherOptions.otherEmpty,
    industryClinicalGoals: newProjectCreation?.project?.industryDetails?.otherClinicalGoals?.values
      ? newProjectCreation?.project?.industryDetails?.otherClinicalGoals?.values
      : selectedProject.industryDetails?.otherClinicalGoals?.values ?? [],
    industryClinicalGoalsOther: newProjectCreation?.project?.industryDetails?.otherClinicalGoals?.other
      ? newProjectCreation?.project?.industryDetails?.otherClinicalGoals?.other
      : selectedProject.industryDetails?.otherClinicalGoals?.other
      ? selectedProject.industryDetails?.otherClinicalGoals?.other
      : OtherOptions.otherEmpty,
    industryExperienceLevel: newProjectCreation?.project?.industryDetails?.experienceLevel?.values
      ? newProjectCreation?.project?.industryDetails?.experienceLevel?.values
      : selectedProject.industryDetails?.experienceLevel?.values ?? [],
    industryExperienceOther: '',
    industryFacilityTypes: newProjectCreation?.project?.industryDetails?.otherFacilityTypes?.values
      ? newProjectCreation?.project?.industryDetails?.otherFacilityTypes.values
      : selectedProject.industryDetails?.otherFacilityTypes?.values ?? [],
    industryFacilityTypesOther: '',
    industryHomeType: newProjectCreation?.project?.industryDetails?.otherHomeType?.values
      ? newProjectCreation?.project?.industryDetails?.otherHomeType?.values
      : selectedProject.industryDetails?.otherHomeType?.values ?? [],
    industryHomeTypeOther: newProjectCreation?.project?.industryDetails?.otherHomeType?.other
      ? newProjectCreation?.project?.industryDetails?.otherHomeType?.other
      : selectedProject.industryDetails?.otherHomeType?.other
      ? selectedProject.industryDetails?.otherHomeType?.other
      : OtherOptions.otherEmpty,
    industryOfficeElements: newProjectCreation?.project?.industryDetails?.otherElements?.values
      ? newProjectCreation?.project?.industryDetails?.otherElements?.values
      : selectedProject.industryDetails?.otherElements?.values ?? [],
    industryOfficeElementsOther: newProjectCreation?.project?.industryDetails?.otherElements?.other
      ? newProjectCreation?.project?.industryDetails?.otherElements?.other
      : selectedProject.industryDetails?.otherElements?.other
      ? selectedProject.industryDetails?.otherElements?.other
      : OtherOptions.otherEmpty,
    industryProjectScale: newProjectCreation?.project?.industryDetails?.projectSize?.values
      ? newProjectCreation?.project?.industryDetails?.projectSize?.values
      : selectedProject.industryDetails?.projectSize?.values ?? [],
    industryProjectScaleOther: '',
    industryTypesOfSpaces: newProjectCreation?.project?.industryDetails?.otherSpaces?.values
      ? newProjectCreation?.project?.industryDetails?.otherSpaces?.values
      : selectedProject.industryDetails?.otherSpaces?.values ?? [],
    industryTypesOfSpacesOther: newProjectCreation?.project?.industryDetails?.otherSpaces?.other
      ? newProjectCreation?.project?.industryDetails?.otherSpaces?.other
      : selectedProject.industryDetails?.otherSpaces?.other
      ? selectedProject.industryDetails?.otherSpaces?.other
      : OtherOptions.otherEmpty,
    industryWorkSpace: newProjectCreation?.project?.industryDetails?.otherWorkspaceType?.values
      ? newProjectCreation?.project?.industryDetails?.otherWorkspaceType?.values
      : selectedProject.industryDetails?.otherWorkspaceType?.values
      ? selectedProject.industryDetails?.otherWorkspaceType?.values
      : [],
    industryWorkSpaceOther: newProjectCreation?.project?.industryDetails?.otherWorkspaceType?.other
      ? newProjectCreation?.project?.industryDetails?.otherWorkspaceType?.other
      : selectedProject.industryDetails?.otherWorkspaceType?.other
      ? selectedProject.industryDetails?.otherWorkspaceType?.other
      : OtherOptions.otherEmpty,
    members: selectedProject.members?.map(member => ({
      id: member.id,
      imageUrl: member.avatarUrl || '',
      isSelected: true,
      value: `${member.firstName} ${member.lastName}`,
    })) || [],
    name: newProjectCreation?.project?.name
      ? newProjectCreation?.project?.name
      : selectedProject.name,

    quantity: selectedProject.buildingsQty,
    squareFootage: selectedProject.squareFootage,
  }

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(
      industryName === IndustriesEnum.WorkFromHome
        ? WorkFromHomeFormValidator
        : industryName === IndustriesEnum.Healthcare
        ? HealthcareFormValidator
        : CorporateFormValidator,
    ),
  })

  const onUploadFile = (file: File): void => {
    setUploadedFile(file)

    collabFile = file

    const reader = new FileReader()
    reader.onload = function() {
      setUploadedImage(reader.result || '')
      setSelectedCover({ id: UPLOADED_IMAGE_ID, url: reader.result as string })
    }
    reader.readAsDataURL(file)

    isCollaborator ? saveCollabImage(collabFile) : undefined
  }

  const Thumbnail = (cover: IndustryCover) => (
    <div
      key={cover.id}
      onClick={() => setSelectedCover(cover)}
      className={`min-w-[128px] h-24 bg-primary-200 mr-2 rounded-md bg-cover bg-no-repeat bg-center cursor-pointer flex flex-col ${
        selectedCover.id === cover.id && 'border-2 border-cta-200 text-cta-200'
      }`}
      style={{ backgroundImage: `url(${cover.url})` }}
    >
      {selectedCover.id === cover.id && <CheckIcon className='w-6 self-end m-2' />}
    </div>
  )

  const clearSelections = async (industry: string) => {
    if (industry === IndustriesEnum.Healthcare) {
      const other = (element: string) => element === 'Other'
      const otherAdminCheck = methods.getValues('industryAdministrativeGoals').some(other)
      !otherAdminCheck ? methods.setValue('industryAdministrativeGoalsOther', OtherOptions.otherEmpty) : undefined
      const otherClinicalCheck = methods.getValues('industryClinicalGoals').some(other)
      !otherClinicalCheck ? methods.setValue('industryClinicalGoalsOther', OtherOptions.otherEmpty) : undefined
      const otherSpacesCheck = methods.getValues('industryTypesOfSpaces').some(other)
      !otherSpacesCheck ? methods.setValue('industryTypesOfSpacesOther', OtherOptions.otherEmpty) : undefined
      methods.setValue('industryBusinessGoals', [])
      methods.setValue('industryBusinessGoalsOther', OtherOptions.otherEmpty)
      methods.setValue('industryHomeType', [])
      methods.setValue('industryHomeTypeOther', OtherOptions.otherEmpty)
      methods.setValue('industryOfficeElements', [])
      methods.setValue('industryOfficeElementsOther', OtherOptions.otherEmpty)
      methods.setValue('industryWorkSpace', [])
      methods.setValue('industryWorkSpaceOther', OtherOptions.otherEmpty)
    } else if (industry === IndustriesEnum.WorkFromHome) {
      const other = (element: string) => element === 'Other'
      const otherHomeCheck = methods.getValues('industryHomeType').some(other)
      !otherHomeCheck ? methods.setValue('industryHomeTypeOther', OtherOptions.otherEmpty) : undefined
      const otherWorkspaceCheck = methods.getValues('industryWorkSpace').some(other)
      !otherWorkspaceCheck ? methods.setValue('industryWorkSpaceOther', OtherOptions.otherEmpty) : undefined
      const otherElementsCheck = methods.getValues('industryOfficeElements').some(other)
      !otherElementsCheck ? methods.setValue('industryOfficeElementsOther', OtherOptions.otherEmpty) : undefined
      methods.setValue('headCount', 0)
      methods.setValue('industryProjectScale', [])
      methods.setValue('industryBusinessGoals', [])
      methods.setValue('industryBusinessGoalsOther', OtherOptions.otherEmpty)
      methods.setValue('industryAdministrativeGoals', [])
      methods.setValue('industryAdministrativeGoalsOther', OtherOptions.otherEmpty)
      methods.setValue('industryClinicalGoals', [])
      methods.setValue('industryClinicalGoalsOther', OtherOptions.otherEmpty)
      methods.setValue('industryFacilityTypes', [])
      methods.setValue('industryFacilityTypesOther', '')
      methods.setValue('industryProjectScale', [])
      methods.setValue('industryProjectScaleOther', '')
      methods.setValue('industryExperienceLevel', [])
      methods.setValue('industryExperienceOther', '')
      methods.setValue('industryTypesOfSpaces', [])
      methods.setValue('industryTypesOfSpacesOther', OtherOptions.otherEmpty)
    } else if (industry === IndustriesEnum.Corporate) {
      const other = (element: string) => element === 'Other'
      const otherBusinessCheck = methods.getValues('industryBusinessGoals').some(other)
      !otherBusinessCheck ? methods.setValue('industryBusinessGoalsOther', OtherOptions.otherEmpty) : undefined
      const otherSpacesCheck = methods.getValues('industryTypesOfSpaces').some(other)
      !otherSpacesCheck ? methods.setValue('industryTypesOfSpacesOther', OtherOptions.otherEmpty) : undefined
      methods.setValue('industryAdministrativeGoals', [])
      methods.setValue('industryAdministrativeGoalsOther', OtherOptions.otherEmpty)
      methods.setValue('industryClinicalGoals', [])
      methods.setValue('industryClinicalGoalsOther', OtherOptions.otherEmpty)
      methods.setValue('industryFacilityTypes', [])
      methods.setValue('industryFacilityTypesOther', '')
      methods.setValue('industryHomeType', [])
      methods.setValue('industryHomeTypeOther', OtherOptions.otherEmpty)
      methods.setValue('industryWorkSpace', [])
      methods.setValue('industryWorkSpaceOther', OtherOptions.otherEmpty)
      methods.setValue('industryOfficeElements', [])
      methods.setValue('industryOfficeElementsOther', OtherOptions.otherEmpty)
    } else if (industry === IndustriesEnum.Education) {
      const other = (element: string) => element === 'Other'
      const otherBusinessCheck = methods.getValues('industryBusinessGoals').some(other)
      !otherBusinessCheck ? methods.setValue('industryBusinessGoalsOther', OtherOptions.otherEmpty) : undefined
      const otherSpacesCheck = methods.getValues('industryTypesOfSpaces').some(other)
      !otherSpacesCheck ? methods.setValue('industryTypesOfSpacesOther', OtherOptions.otherEmpty) : undefined
      methods.setValue('industryAdministrativeGoals', [])
      methods.setValue('industryAdministrativeGoalsOther', OtherOptions.otherEmpty)
      methods.setValue('industryClinicalGoals', [])
      methods.setValue('industryClinicalGoalsOther', OtherOptions.otherEmpty)
      methods.setValue('industryFacilityTypes', [])
      methods.setValue('industryFacilityTypesOther', '')
      methods.setValue('industryHomeType', [])
      methods.setValue('industryHomeTypeOther', OtherOptions.otherEmpty)
      methods.setValue('industryWorkSpace', [])
      methods.setValue('industryWorkSpaceOther', OtherOptions.otherEmpty)
      methods.setValue('industryOfficeElements', [])
      methods.setValue('industryOfficeElementsOther', OtherOptions.otherEmpty)
    } else if (industry === IndustriesEnum.Government) {
      const other = (element: string) => element === 'Other'
      const otherBusinessCheck = methods.getValues('industryBusinessGoals').some(other)
      !otherBusinessCheck ? methods.setValue('industryBusinessGoalsOther', OtherOptions.otherEmpty) : undefined
      const otherSpacesCheck = methods.getValues('industryTypesOfSpaces').some(other)
      !otherSpacesCheck ? methods.setValue('industryTypesOfSpacesOther', OtherOptions.otherEmpty) : undefined
      methods.setValue('industryAdministrativeGoals', [])
      methods.setValue('industryAdministrativeGoalsOther', OtherOptions.otherEmpty)
      methods.setValue('industryClinicalGoals', [])
      methods.setValue('industryClinicalGoalsOther', OtherOptions.otherEmpty)
      methods.setValue('industryFacilityTypes', [])
      methods.setValue('industryFacilityTypesOther', '')
      methods.setValue('industryHomeType', [])
      methods.setValue('industryHomeTypeOther', OtherOptions.otherEmpty)
      methods.setValue('industryWorkSpace', [])
      methods.setValue('industryWorkSpaceOther', OtherOptions.otherEmpty)
      methods.setValue('industryOfficeElements', [])
      methods.setValue('industryOfficeElementsOther', OtherOptions.otherEmpty)
    } else if (industry === IndustriesEnum.Technology) {
      const other = (element: string) => element === 'Other'
      const otherBusinessCheck = methods.getValues('industryBusinessGoals').some(other)
      !otherBusinessCheck ? methods.setValue('industryBusinessGoalsOther', OtherOptions.otherEmpty) : undefined
      const otherSpacesCheck = methods.getValues('industryTypesOfSpaces').some(other)
      !otherSpacesCheck ? methods.setValue('industryTypesOfSpacesOther', OtherOptions.otherEmpty) : undefined
      methods.setValue('industryAdministrativeGoals', [])
      methods.setValue('industryAdministrativeGoalsOther', OtherOptions.otherEmpty)
      methods.setValue('industryClinicalGoals', [])
      methods.setValue('industryClinicalGoalsOther', OtherOptions.otherEmpty)
      methods.setValue('industryFacilityTypes', [])
      methods.setValue('industryFacilityTypesOther', '')
      methods.setValue('industryHomeType', [])
      methods.setValue('industryHomeTypeOther', OtherOptions.otherEmpty)
      methods.setValue('industryWorkSpace', [])
      methods.setValue('industryWorkSpaceOther', OtherOptions.otherEmpty)
      methods.setValue('industryOfficeElements', [])
      methods.setValue('industryOfficeElementsOther', OtherOptions.otherEmpty)
    }
  }

  const saveCollabImage = async (file: File) => {
    const copyProject = cloneDeep(selectedProject)

    if (copyProject) {
      const sProject: Project = {
        ...copyProject,
      }

      await submitUpdateProjectAction({
        image: file,
        project: sProject,
        visualPreference: false,
      })
    }
  }

  const onNewClientClick = async () => {
    await cleanNewClientStateAction()
    displaySlideOutAction('ClientNewSlideOut')
  }

  const clearProject = async () => {
    await clearSelections(selectedProject.industry.name)
    const savedProject = await setProject()
    await projectCreationStateMachine({ currentState: newProjectStateEnum.CurrentPage, project: savedProject })
  }

  const saveProject = async () => {
    await clearSelections(industryName)
    const savedProject = await setProject()
    await projectCreationStateMachine({ currentState: newProjectStateEnum.CurrentPage, project: savedProject })
  }

  const setProject = () => {
    const sindustry = {
      experienceLevel: {
        other: methods.getValues('industryExperienceOther') ? methods.getValues('industryExperienceOther') : 'None',
        values: methods.getValues('industryExperienceLevel')
          ? methods.getValues('industryExperienceLevel')
          : [],
      },
      otherAdministrativeGoals: {
        other: methods.getValues('industryAdministrativeGoalsOther')
          ? methods.getValues('industryAdministrativeGoalsOther')
          : '',
        values: methods.getValues('industryAdministrativeGoals')
          ? methods.getValues('industryAdministrativeGoals')
          : [],
      },
      otherBusinessGoals: {
        other: methods.getValues('industryBusinessGoalsOther')
          ? methods.getValues('industryBusinessGoalsOther')
          : '',
        values: methods.getValues('industryBusinessGoals')
          ? methods.getValues('industryBusinessGoals')
          : [],
      },
      otherClinicalGoals: {
        other: methods.getValues('industryClinicalGoalsOther')
          ? methods.getValues('industryClinicalGoalsOther')
          : '',
        values: methods.getValues('industryClinicalGoals') ? methods.getValues('industryClinicalGoals') : [],
      },
      otherElements: (!methods.getValues('industryOfficeElements') && !methods.getValues('industryOfficeElementsOther'))
        ? null
        : {
          other: methods.getValues('industryOfficeElementsOther')
            ? methods.getValues('industryOfficeElementsOther')
            : '',
          values: methods.getValues('industryOfficeElements'),
        },
      otherFacilityTypes: {
        other: methods.getValues('industryFacilityTypesOther')
          ? methods.getValues('industryFacilityTypesOther')
          : '',
        values: methods.getValues('industryFacilityTypes')
          ? methods.getValues('industryFacilityTypes')
          : [],
      },
      otherHomeType: (!methods.getValues('industryHomeType') && !methods.getValues('industryHomeTypeOther'))
        ? undefined
        : {
          other: methods.getValues('industryHomeTypeOther')
            ? methods.getValues('industryHomeTypeOther')
            : '',
          values: methods.getValues('industryHomeType'),
        },
      otherSpaces: {
        other: methods.getValues('industryTypesOfSpacesOther')
          ? methods.getValues('industryTypesOfSpacesOther')
          : '',
        quantValues: quantValues as QuantValueSchemaInput[],
        quantities: selectedProject.industryDetails?.otherSpaces?.quantities
          ? selectedProject.industryDetails?.otherSpaces?.quantities as number[]
          : [0],
        values: methods.getValues('industryTypesOfSpaces')
          ? methods.getValues('industryTypesOfSpaces')
          : [],
      },
      otherWorkspaceType: (!methods.getValues('industryWorkSpace') && !methods.getValues('industryWorkSpaceOther'))
        ? undefined
        : {
          other: methods.getValues('industryWorkSpaceOther')
            ? methods.getValues('industryWorkSpaceOther')
            : '',
          values: methods.getValues('industryWorkSpace'),
        },
      projectSize: {
        other: '',
        values: methods.getValues('industryProjectScale'),
      },
      visualPreferences: newProjectCreation?.project?.industryId !== methods.getValues('industry').id
        ? undefined
        : newProjectCreation?.project?.industryDetails?.visualPreferences,
    }

    const newProject: ProjectInput = {
      budget: parseFloat(methods.getValues('budget').toString()),
      buildingsQty: methods.getValues('quantity'),
      clientId: methods.getValues('client.id'),
      companyName: methods.getValues('companyName'),
      coverImageUrl: '',
      headCount: parseFloat(methods.getValues('headCount').toString()),
      industryDetails: sindustry,
      industryId: methods.getValues('industry.id'),
      members: [],
      moveInDate: (methods.getValues('date') !== undefined || methods.getValues('date') !== '')
        ? methods.getValues('date')
        : undefined,
      name: methods.getValues('name'),
      squareFootage: parseFloat(methods.getValues('squareFootage').toString()),
    }
    return newProject
  }

  const onSubmit = async (values: ProjectFormType) => {
    let newCoverImageUrl = ''
    await clearSelections(industryName)

    const foundClient = clientsList.find((e) => e.id === methods.getValues('client.id'))

    if (selectedCover.id === UPLOADED_IMAGE_ID) {
      newCoverImageUrl = originalCover.url // Retain custom image if selected
    } else if (selectedCover.id !== UPLOADED_IMAGE_ID && selectedCover && selectedCover.url) {
      newCoverImageUrl = selectedCover.url
    } else if (selectedCover.id !== UPLOADED_IMAGE_ID && values.coverImageUrl) {
      newCoverImageUrl = values.coverImageUrl
    }

    await saveProject()

    const industryDetailsEdit = {
      ...selectedProject.industryDetails,
      experienceLevel: (methods.getValues('industryExperienceLevel') || !methods.getValues('industryExperienceOther'))
        ? {
          other: methods.getValues('industryExperienceOther') ?? '',
          values: methods.getValues('industryExperienceLevel') ?? [],
        }
        : undefined,
      id: selectedProject.industryDetails?.id ?? '',
      otherAdministrativeGoals:
        (methods.getValues('industryAdministrativeGoals') || methods.getValues('industryAdministrativeGoalsOther'))
          ? {
            other: methods.getValues('industryAdministrativeGoalsOther') ?? '',
            values: methods.getValues('industryAdministrativeGoals') ?? [],
          }
          : undefined,
      otherBusinessGoals:
        (methods.getValues('industryBusinessGoals') || methods.getValues('industryBusinessGoalsOther'))
          ? {
            other: methods.getValues('industryBusinessGoalsOther') ?? '',
            values: methods.getValues('industryBusinessGoals') ?? [],
          }
          : undefined,
      otherClinicalGoals:
        (methods.getValues('industryClinicalGoals') || methods.getValues('industryClinicalGoalsOther'))
          ? {
            other: methods.getValues('industryClinicalGoalsOther') ?? '',
            values: methods.getValues('industryClinicalGoals') ?? [],
          }
          : undefined,
      otherElements: (methods.getValues('industryOfficeElements') || methods.getValues('industryOfficeElementsOther'))
        ? {
          other: methods.getValues('industryOfficeElementsOther') ?? '',
          values: methods.getValues('industryOfficeElements') ?? [],
        }
        : undefined,
      otherFacilityTypes:
        (methods.getValues('industryFacilityTypes') || methods.getValues('industryFacilityTypesOther'))
          ? {
            other: '',
            values: methods.getValues('industryFacilityTypes') ?? [],
          }
          : undefined,
      otherHomeType: (methods.getValues('industryHomeType') || methods.getValues('industryHomeTypeOther'))
        ? {
          other: methods.getValues('industryHomeTypeOther') ?? '',
          values: methods.getValues('industryHomeType') ?? [],
        }
        : undefined,
      otherSpaces: (methods.getValues('industryTypesOfSpaces') || methods.getValues('industryTypesOfSpacesOther'))
        ? {
          other: methods.getValues('industryTypesOfSpacesOther') ?? '',
          quantValues: quantValues as QuantValueSchemaInput[],
          quantities: selectedProject.industryDetails?.otherSpaces?.quantities
            ? selectedProject.industryDetails?.otherSpaces?.quantities as number[]
            : [0],
          values: methods.getValues('industryTypesOfSpaces') ?? [],
        }
        : undefined,
      otherWorkspaceType: (methods.getValues('industryWorkSpace') || methods.getValues('industryWorkSpaceOther'))
        ? {
          other: methods.getValues('industryWorkSpaceOther') ?? '',
          values: methods.getValues('industryWorkSpace') ?? [],
        }
        : undefined,
      projectSize: (methods.getValues('industryProjectScale') || !methods.getValues('industryProjectScale'))
        ? {
          other: methods.getValues('industryProjectScaleOther') ?? '',
          values: methods.getValues('industryProjectScale') ?? [],
        }
        : undefined,
    }

    const projectEdit: Project = {
      budget: parseFloat(values.budget.toString()),
      buildingsQty: values.quantity,
      client: foundClient ? foundClient : selectedProject.client,
      companyName: values.companyName,
      coverImageUrl: newCoverImageUrl,
      createdOn: selectedProject.createdOn,
      discountPercentage: selectedProject.discountPercentage,
      headCount: parseFloat(methods.getValues('headCount').toString()),
      id: selectedProject.id,
      increasePercentage: selectedProject.increasePercentage,
      industry: values.industry,
      industryDetails: industryDetailsEdit,
      isPinned: selectedProject.isPinned,
      members: selectedProject.members.map(member => member),
      modifiedOn: selectedProject.modifiedOn,
      moveInDate: values.date || values.date !== '' ? values.date : undefined,
      name: values.name,
      squareFootage: parseFloat(values.squareFootage.toString()),
      stage: selectedProject.stage,
    }

    const p = {
      ...selectedProject,
      budget: projectEdit.budget,
      buildingsQty: projectEdit.buildingsQty,
      client: projectEdit.client,
      companyName: projectEdit.companyName,
      coverImageUrl: projectEdit.coverImageUrl,
      headCount: projectEdit.headCount,
      industry: projectEdit.industry,
      industryDetails: projectEdit.industryDetails,
      members: intersectionWith(members, values.members, (m1, m2) => m1.id === m2.id),
      moveInDate: projectEdit.moveInDate,
      name: projectEdit.name,
      squareFootage: projectEdit.squareFootage,
    }

    if (p.client === undefined && selectedProject.client) {
      p.client = selectedProject.client
    }

    await submitUpdateProjectAction({
      image: uploadedFile,
      project: p,
      visualPreference: false,
    })

    await toggleProjectDetailsEditMode(false)

    await projectCreationStateMachine({ currentState: newProjectStateEnum.CleanProject })
    await cleanNewClientStateAction()
  }

  if (projectDetailsEditMode) {
    return (
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className='flex flex-col pb-6'>
            <ProjectHeader
              activeTab='details'
              hideDropDown={props.hideHeaderDropDown}
              hideDeleteOption={props.hideHeaderDeleteOption}
              readOnlyProjectStage={props.readOnlyProjectStage}
            />
            <div className='hidden sm:flex flex-row justify-between items-center mt-7'>
              <h2 className='block text-xl font-medium text-primary-100'>Project Details</h2>
            </div>
            <div>
              <h3 className='text-primary-400 mb-3'>
                Access project & industry details and visual preferences all in one place
              </h3>
            </div>

            <Card className='w-1/2 py-3 px-4 border border-gray-300 bg-[#edf1f3] rounded-md relative shadow-none stroke-none'>
              <div className='flex flex-column justify-between '>
                <div className='text-s text-[#35697D] flex-initial'>
                  ✏️ You are in edit mode
                </div>
                <div className='flex-initial pl-8'>
                  <button
                    className='text-base text-[#C04184] font-bold'
                    onClick={async () => {
                      await toggleProjectDetailsEditMode(false)
                      await cleanNewClientStateAction()
                      await clearProject()
                    }}
                  >
                    Cancel
                  </button>{' '}
                  or{' '}
                  <button className='text-base text-[#C04184] font-bold ' type='submit'>
                    Save Changes
                  </button>
                </div>
              </div>
            </Card>
            <div className='text-xl font-semibold text-black flex justify-between mt-5 mb-2 pb-4'>
              Basic Project Details
            </div>
            <div className='mb-7 w-1/2'>
              <TextInput
                readOnly={props.readonly}
                label='Project Name*'
                hasAutoFocus
                {...methods.register('name')}
                maxLength={52}
              />
              <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                {methods.formState.errors.name?.message}
              </span>
            </div>
            <div className='flex -mx-2 w-1/2'>
              <div className='w-1/2 px-2'>
                <div className='mb-10'>
                  {isAdmin
                    ? (
                      <>
                        <Select
                          label='Client Name*'
                          default={{ key: initialValues.client.id, value: initialValues.client.name }}
                          data={clientsList.map(client => ({ key: client.id, value: client.name }))}
                          onNewClient={() => onNewClientClick()}
                          onChange={client => {
                            methods.clearErrors('client.id')
                            methods.setValue('client', { id: client.key, name: client.value })
                          }}
                        />
                        <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                          {methods.formState.errors.client?.id?.message}
                        </span>
                      </>
                    )
                    : (
                      <>
                        <Select
                          label='Client Name*'
                          default={{ key: initialValues.client.id, value: initialValues.client.name }}
                          data={clientsList.map(client => ({ key: client.id, value: client.name }))}
                          onChange={client => {
                            methods.clearErrors('client.id')
                            methods.setValue('client', { id: client.key, name: client.value })
                          }}
                        />
                        <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                          {methods.formState.errors.client?.id?.message}
                        </span>
                      </>
                    )}
                </div>
              </div>
              <div className='w-1/2 px-2'>
                <div className='mb-10'>
                  <div className='flex flex-row min-w-[150px] grow h-14 px-4 py-2 border-primary-400 border-b cursor-pointer justify-between'>
                    <div className='flex flex-col grow'>
                      <div className='text-primary-400 text-xs'>Move In Date*</div>
                      <div className='text-primary-100 text-base'>
                        <input
                          type='date'
                          className='p-0 border-none focus:ring-0 w-full'
                          {...methods.register('date')}
                        />
                      </div>
                    </div>
                  </div>
                  <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                    {methods.formState.errors.date?.message}
                  </span>
                </div>
              </div>
            </div>
            {initialValues.client.id === NEWLY_ADDED_CLIENT_ID
              && (
                <>
                  <div className='mb-10 w-1/2'>
                    <TextInput
                      label='Company Name'
                      {...methods.register('companyName')}
                      maxLength={52}
                    />
                    <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                      {methods.formState.errors.companyName?.message}
                    </span>
                  </div>
                </>
              )}
            <div className='flex -mx-2 w-1/2'>
              <div className='w-1/2 px-2'>
                <div className='mb-10'>
                  <TextInput type='number' label='Furniture Budget' {...methods.register('budget')} />
                </div>
              </div>
              <div className='w-1/2 px-2'>
                <div className='mb-10'>
                  <TextInput type='number' label='Square Footage' {...methods.register('squareFootage')} />
                  <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                    {methods.formState.errors.squareFootage?.message}
                  </span>
                </div>
              </div>
            </div>

            {((selectedProject.industry.name != IndustriesEnum.WorkFromHome)
              && (industryName != IndustriesEnum.WorkFromHome))
              && (
                <div className='flex -mx-2'>
                  <div className='w-1/2 px-2'>
                    <div className='mb-10'>
                      <Select
                        label='Qty of Buildings'
                        data={[...Array(10).keys()].map(qty => ({
                          key: (qty + 1).toString(),
                          value: (qty + 1).toString(),
                        }))}
                        onChange={(value) => methods.setValue('quantity', parseInt(value.key))}
                        default={{ key: initialValues.quantity.toString(), value: initialValues.quantity.toString() }}
                      />
                    </div>
                  </div>
                </div>
              )}

            <div className='text-xl font-semibold text-black flex justify-between mb-2'>
              Industry Details
            </div>

            <div>
              <IndustriesSelect
                {...methods}
                label='Industry*'
                projectCreation={false}
                default={newProjectCreation?.project?.industryId
                  ? {
                    key: newProjectCreation.project?.industryId,
                    value: industries.find(industry => industry.id === newProjectCreation.project?.industryId)?.name
                      ?? '',
                  }
                  : { key: methods.getValues('industry').id, value: methods.getValues('industry').name }}
                data={filteredIndustries.map(industry => ({ key: industry.id, value: industry.name }))}
                selectedValue={methods.getValues('industry').name}
                onChange={async industry => {
                  methods.setValue('industry', { id: industry.key, name: industry.value })
                  setIndustryCovers(industries.find(storeIndustry => industry.key === storeIndustry.id)?.covers || [])
                  setIndustryName(industry.value)
                  saveProject()
                }}
              />
              <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                {methods.formState.errors.industry?.id?.message}
              </span>
            </div>
            <div className='flex w-1/2 justify-between'>
              <div className='text-xl font-semibold text-black flex-grow mt-5 -mb-3 '>
                Visual Preferences
              </div>
              <div className='text-primary-400 flex-grow top-0 right-0'>
                <button
                  id='editProjectDetails'
                  className='button cta mt-7 top-0 right-0 text-base font-semibold'
                  onClick={async () => {
                    await saveProject().then(() => {
                      displaySlideOutAction('EditVisualPreferencesSlideOut')
                    })
                  }}
                >
                  Edit Visual Preferences
                </button>
              </div>
            </div>
            <div className='flex-grow text-xs font-small text-primary-400 -mt-3'>
              What are some styles you find appealing?
            </div>
            <br></br>
            <div className='flex flex-col'>
              <div className='flex w-1/2 justify-between'>
                <div className='text-xl font-semibold text-black flex-grow mt-5 mb-2'>
                  Select a cover image
                </div>
                <div className=' flex-grow '>
                  <ImageCropper
                    size={300}
                    setCroppedImageFile={onUploadFile}
                  />
                  <label
                    htmlFor='cropper'
                    className='text-cta-200 text-base font-medium cursor-pointer flex flex-row items-center mr-4 mt-5'
                  >
                    <UploadIcon />
                    <span className='ml-2 '>Upload Image</span>
                  </label>
                </div>
              </div>
              <section className='w-1/2 flex flex-col mb-5 -pt-5'>
                <Carousel
                  hasArrows
                  itemAmount={selectedProject.industry.covers?.length ?? 0}
                  hasSeeAll={false}
                  itemSize={128}
                >
                  {uploadedImage && <Thumbnail id={UPLOADED_IMAGE_ID} url={uploadedImage as string} />}
                  {industryCovers.map(cover => <Thumbnail key={cover.id} id={cover.id} url={cover.url} />)}
                </Carousel>
              </section>
            </div>
          </div>
        </form>
      </FormProvider>
    )
  } else {
    return (
      <div className='flex flex-col pb-6'>
        <ProjectHeader
          activeTab='details'
          hideDropDown={props.hideHeaderDropDown}
          hideDeleteOption={props.hideHeaderDeleteOption}
          readOnlyProjectStage={props.readOnlyProjectStage}
        />

        <div className='hidden sm:flex flex-row justify-start items-center mt-7 block'>
          <h2
            className={!isCollaborator
              ? 'block font-bold text-xl font-medium text-primary-100'
              : 'pb-3 block font-bold text-xl font-medium text-primary-100'}
          >
            Project Details
          </h2>
        </div>
        <div>
          <h3 className='text-primary-400 mb-3 -mt-3 pb-5 pt-3'>
            Access project & industry details and visual preferences all in one place
          </h3>
        </div>

        <div className='pdfSection' id='pdfPage1'>
          <Card className='w-1/2 p-6 border border-gray-300 rounded-md relative'>
            <div className='text-xl font-semibold text-black flex justify-between mb-2'>
              Basic Project Details
              <div className='flex flex-row justify-end'>
                {!props.hideEditProjectDetailsButton
                  && (
                    <button
                      id='editProjectDetails'
                      onClick={async () => {
                        await toggleProjectDetailsEditMode(true)
                      }}
                      className='button cta mb-7 text-base font-semibold'
                    >
                      Edit Details
                    </button>
                  )}

                <PDFDownloadLink
                  document={
                    <GeneratePdf
                      project={pdfObject}
                    />
                  }
                  fileName={`${selectedProject.name}.pdf`}
                >
                  <button className='w-10 h-10 bg-cta-200 rounded-md text-primary-100 flex justify-center items-center ml-3'>
                    <DownloadIcon className='text-white' />
                  </button>
                </PDFDownloadLink>
              </div>
            </div>

            <div className='text-[#35697D] block pb-10 text-base font-semibold'>
              Project Name*
              <p className='block text-lg text-primary-100 font-thin' id='block text-lg text-primary-100 font-thin'>
                {selectedProject.name}
              </p>
            </div>
            <div className='flex justify-between w-11/12 pb-10'>
              <div className='text-[#35697D] flex-initial w-60 pr-10 text-base font-semibold'>
                Client Name*
                <p className='block text-lg text-primary-100 font-thin' id='block text-lg text-primary-100 font-thin'>
                  {selectedProject.client.name}
                </p>
              </div>
              <div className='text-[#35697D] flex-initial w-60 pr-10 text-base font-semibold'>
                Move in Date*
                <div className='block text-lg text-primary-100 font-thin' id='block text-lg text-primary-100 font-thin'>
                  {formatDate(selectedProject.moveInDate, 'YYYY-MM-DD')}
                </div>
              </div>
            </div>
            {selectedProject.client.id === NEWLY_ADDED_CLIENT_ID
              && (
                <>
                  <div className='text-[#35697D] block pb-10 text-base font-semibold'>
                    Company Name
                    <p
                      className='block text-lg text-primary-100 font-thin'
                      id='block text-lg text-primary-100 font-thin'
                    >
                      {selectedProject.companyName}
                    </p>
                  </div>
                </>
              )}
            <div className='flex justify-between w-11/12 pb-10'>
              <div className='text-[#35697D] flex-initial w-60 pr-10 text-base font-semibold'>
                Furniture Budget
                <div className='block text-lg text-primary-100 font-thin' id='block text-lg text-primary-100 font-thin'>
                  {selectedProject.budget}
                </div>
              </div>
              <div className='text-[#35697D] flex-initial w-60 pr-10 text-base font-semibold'>
                Quantity of Buildings
                <div className='block text-lg text-primary-100 font-thin' id='block text-lg text-primary-100 font-thin'>
                  {selectedProject.buildingsQty}
                </div>
              </div>
            </div>

            <div className='text-[#35697D] block text-base font-semibold'>
              Square Footage
              <div className='block text-lg text-primary-100 font-thin' id='block text-lg text-primary-100 font-thin'>
                {selectedProject.squareFootage}
              </div>
            </div>
          </Card>
        </div>
        <div id='pdfPage2'>
          <br></br>
          {(selectedProject.industry.name === IndustriesEnum.Corporate
            || selectedProject.industry.name === IndustriesEnum.Education
            || selectedProject.industry.name === IndustriesEnum.Government
            || selectedProject.industry.name === IndustriesEnum.Technology)
            && (
              <Card className='w-1/2 p-6 border border-gray-300 rounded-md relative'>
                <div className='text-xl font-semibold text-black flex justify-between mb-2'>
                  Industry Details
                </div>
                <div className='flex justify-between w-11/12 pb-5'>
                  <div className='text-[#35697D] block pb-10 pr-10 text-base font-semibold'>
                    Industry*
                    <div
                      className='block text-lg text-primary-100 font-thin'
                      id='block text-lg text-primary-100 font-thin'
                    >
                      {selectedProject.industry.name}
                    </div>
                  </div>
                  <div className='whitespace-nowrap text-[#35697D] flex-initial w-60 pr-10 pb-10 text-base font-semibold'>
                    Business Goals*
                    <div
                      className='block text-lg text-primary-100 font-thin'
                      id='overflow-auto block text-lg text-primary-100 font-thin'
                    >
                      {selectedProject.industryDetails?.otherBusinessGoals?.values.map((goal, index) => {
                        if (goal === OtherBusinessGoalsEnum.Other) {
                          return <div key={index}>{selectedProject.industryDetails?.otherBusinessGoals?.other}</div>
                        } else {
                          return <div key={index}>{getNameWithoutUnderScores(goal.toString())}</div>
                        }
                      })}
                    </div>
                  </div>
                </div>

                <div className='flex justify-between w-11/12 pb-5' id='pdfTest'>
                  <div className='text-[#35697D] block pb-10 text-base font-semibold'>
                    Project Scale*
                    <div
                      className='block text-lg text-primary-100 font-thin'
                      id='block text-lg text-primary-100 font-thin'
                    >
                      {getNameWithoutUnderScores(selectedProject.industryDetails?.projectSize?.values.toString() ?? '')}
                    </div>
                  </div>
                  <div className='text-[#35697D] flex-initial w-60 pr-10 pb-10 text-base font-semibold'>
                    Project Headcount
                    <div
                      className='block text-lg text-primary-100 font-thin'
                      id='block text-lg text-primary-100 font-thin'
                    >
                      {selectedProject.headCount}
                    </div>
                  </div>
                </div>

                <div className='flex justify-between w-11/12 pb-5'>
                  <div
                    className={`text-[#35697D] flex-initial w-70 ${
                      isParticipant ? ' ' + 'pr-8' : ' ' + 'pr-4'
                    } pb-10 text-base font-semibold`}
                  >
                    {isParticipant
                      ? 'Furniture Experience Level*'
                      : 'Clients Furniture Experience*'}
                    <div
                      className='block text-lg text-primary-100 font-thin'
                      id='block text-lg text-primary-100 font-thin'
                    >
                      {getNameWithoutUnderScores(
                        selectedProject.industryDetails?.experienceLevel?.values.toString() ?? '',
                      )}
                    </div>
                  </div>
                </div>
              </Card>
            )}
          {selectedProject.industry.name === IndustriesEnum.Healthcare
            && (
              <Card className='w-1/2 p-6 border border-gray-300 rounded-md relative'>
                <div className='text-xl font-semibold text-black flex justify-between mb-2'>
                  Industry Details
                </div>
                <div className='flex justify-between w-11/12 pb-5'>
                  <div className='text-[#35697D] block pb-10 text-base font-semibold'>
                    Industry*
                    <div
                      className='block text-lg text-primary-100 font-thin'
                      id='block text-lg text-primary-100 font-thin'
                    >
                      {selectedProject.industry.name}
                    </div>
                  </div>
                  <div className='text-[#35697D] flex-initial w-60 pr-10 pb-10 text-base font-semibold'>
                    Facility Type*
                    <div
                      className='block text-lg text-primary-100 font-thin'
                      id='block text-lg text-primary-100 font-thin'
                    >
                      {selectedProject.industryDetails?.otherFacilityTypes?.values.map((f, i) => {
                        return <div key={i}>{getNameWithoutUnderScores(f.toString()) + '\n'}</div>
                      })}
                    </div>
                  </div>
                </div>

                <div className='flex justify-between w-11/12 pb-5'>
                  <div className='text-[#35697D] block pb-10 text-base font-semibold'>
                    Administrative Goals*
                    <div
                      className='whitespace-nowrap block text-lg text-primary-100 font-thin'
                      id='whitespace-nowrap block text-lg text-primary-100 font-thin'
                    >
                      {selectedProject.industryDetails?.otherAdministrativeGoals?.values.map((goal, index) => {
                        if (goal === OtherAdministrativeGoalsEnum.Other) {
                          return (
                            <div key={index}>{selectedProject.industryDetails?.otherAdministrativeGoals?.other}</div>
                          )
                        } else {
                          return <div key={index}>{getNameWithoutUnderScores(goal.toString()) + '\n'}</div>
                        }
                      })}
                    </div>
                  </div>
                  <div className='text-[#35697D] flex-initial w-60 pr-10 pb-10 text-base font-semibold'>
                    Clinical Goals*
                    <div
                      className='whitespace-nowrap block text-lg text-primary-100 font-thin'
                      id='whitespace-nowrap block text-lg text-primary-100 font-thin'
                    >
                      {selectedProject.industryDetails?.otherClinicalGoals?.values.map((goal, index) => {
                        if (goal === OtherClinicalGoalsEnum.Other) {
                          return <div key={index}>{selectedProject.industryDetails?.otherClinicalGoals?.other}</div>
                        } else {
                          return <div key={index}>{getNameWithoutUnderScores(goal.toString()) + '\n'}</div>
                        }
                      })}
                    </div>
                  </div>
                </div>

                <div className='flex justify-between w-11/12 pb-5'>
                  <div className='text-[#35697D] block pb-10 text-base font-semibold'>
                    Project Scale*
                    <div
                      className='block text-lg text-primary-100 font-thin'
                      id='block text-lg text-primary-100 font-thin'
                    >
                      {getNameWithoutUnderScores(selectedProject.industryDetails?.projectSize?.values.toString() ?? '')}
                    </div>
                  </div>
                  <div className='text-[#35697D] flex-initial w-60 pr-10 pb-10 text-base font-semibold'>
                    Project Headcount
                    <div
                      className='block text-lg text-primary-100 font-thin'
                      id='block text-lg text-primary-100 font-thin'
                    >
                      {selectedProject.headCount}
                    </div>
                  </div>
                </div>

                <div className='flex justify-between w-11/12 pb-5'>
                  <div className='text-[#35697D] flex-initial w-60 pr-10 pb-10 text-base font-semibold'>
                    Experience Level*
                    <div
                      className='block text-lg text-primary-100 font-thin'
                      id='block text-lg text-primary-100 font-thin'
                    >
                      {getNameWithoutUnderScores(
                        selectedProject.industryDetails?.experienceLevel?.values.toString() ?? '',
                      )}
                    </div>
                  </div>
                </div>
              </Card>
            )}
          {selectedProject.industry.name === IndustriesEnum.WorkFromHome && (
            <Card className='w-1/2 p-6 border border-gray-300 rounded-md relative'>
              <div className='text-xl font-semibold text-black flex justify-between mb-2'>
                Industry Details
              </div>
              <div className='flex justify-between w-11/12 pb-5'>
                <div className='text-[#35697D] block pb-10 text-base font-semibold'>
                  Industry*
                  <div
                    className='block text-lg text-primary-100 font-thin'
                    id='block text-lg text-primary-100 font-thin'
                  >
                    {selectedProject.industry.name}
                  </div>
                </div>
              </div>

              <div className='flex justify-between w-11/12 pb-5'>
                <div className='text-[#35697D] block pb-10 text-base font-semibold'>
                  Home Type*
                  <div
                    className='block text-lg text-primary-100 font-thin'
                    id='block text-lg text-primary-100 font-thin'
                  >
                    {selectedProject.industryDetails?.otherHomeType?.values.map((home, index) => {
                      if (home === OtherHomeTypeEnum.Other) {
                        return <div key={index}>{selectedProject.industryDetails?.otherHomeType?.other}</div>
                      } else {
                        return <div key={index}>{getNameWithoutUnderScores(home)}</div>
                      }
                    })}
                  </div>
                </div>
              </div>

              <div className='flex justify-between w-11/12 pb-5'>
                <div className='text-[#35697D] block pb-10 text-base font-semibold'>
                  Work Space*
                  <div
                    className='block text-lg text-primary-100 font-thin'
                    id='block text-lg text-primary-100 font-thin'
                  >
                    {selectedProject.industryDetails?.otherWorkspaceType?.values.map((workspace, index) => {
                      if (workspace === OtherWorkspaceTypeEnum.Other) {
                        return <div key={index}>{selectedProject.industryDetails?.otherWorkspaceType?.other}</div>
                      } else {
                        return <div key={index}>{getNameWithoutUnderScores(workspace.toString())}</div>
                      }
                    })}
                  </div>
                </div>
              </div>
              <div className='flex justify-between w-11/12 pb-5'>
                <div className='text-[#35697D] block pb-10 text-base font-semibold'>
                  Elements*
                  <div
                    className='block text-lg text-primary-100 font-thin'
                    id='block text-lg text-primary-100 font-thin'
                  >
                    {selectedProject.industryDetails?.otherElements?.values.map((goal, index) => {
                      if (goal === OtherElementsEnum.Other) {
                        return <div key={index}>{selectedProject.industryDetails?.otherElements?.other}</div>
                      } else {
                        return <div key={index}>{getNameWithoutUnderScores(goal.toString())}</div>
                      }
                    })}
                  </div>
                </div>
              </div>
            </Card>
          )}
        </div>
        <br></br>

        <Card className='w-1/2 p-6 border border-gray-300 rounded-md relative'>
          <div className='text-xl font-semibold text-black flex justify-between mb-2'>
            Spaces*
            {!isCollaborator
              && (
                <div className='flex flex-row '>
                  <button
                    id='editProjectSpaces'
                    onClick={async () => {
                      displaySlideOutAction('EditProjectSpaceSlideOut')
                    }}
                    className='button cta text-base font-semibold'
                  >
                    Edit Spaces
                  </button>
                </div>
              )}
          </div>

          <div className='flex justify-between w-11/12 pb-2'>
            <div className='text-[#35697D] block pb-10 text-base font-semibold'>
              Predefined Types
            </div>
            <div className='text-[#35697D] flex-initial w-60 pr-10 pb-4 text-base font-semibold'>
              Quantities
            </div>
          </div>
          {selectedProject.industryDetails?.otherSpaces?.quantValues?.map((qv, index) => {
            return (
              <>
                <div className='flex justify-between w-11/12 pb-5'>
                  <div className='inline-block text-lg text-primary-100 font-thin' key={index}>
                    {qv.values != 'Other' ? getNameWithoutUnderScores(qv.values) : qv.other}
                  </div>
                  <div className='block flex-initial text-lg w-60 pr-10 pb-2 text-primary-100 font-thin' key={index}>
                    {qv.amount}
                  </div>
                </div>
              </>
            )
          })}
        </Card>
        <br></br>
        <Card className='w-1/2 p-6 border border-gray-300 rounded-md relative'>
          <div className='text-xl font-semibold text-black flex justify-between'>
            Visual Preferences
            <div className='flex flex-row'>
              {isProjectCollaborator(selectedProject, authenticatedUser)
                ? (
                  <button
                    id='editVisualPreferences'
                    className='whitespace-nowrap button cta mb-7 text-base font-semibold'
                    onClick={() => displaySlideOutAction('EditVisualPreferencesSlideOut')}
                  >
                    Edit Visual Preferences
                  </button>
                )
                : (
                  <button
                    id='projectDetailsReadOnly'
                    className='whitespace-nowrap button cta mb-7 text-base font-semibold'
                    onClick={() => displaySlideOutAction('VisualPreferencesReadOnlySlideOut')}
                  >
                    View Visual Preferences
                  </button>
                )}
            </div>
          </div>
          <div className=' block text-base font-small text-[#35697D]'>
            What are some styles you find appealing?
          </div>
        </Card>
        <br></br>
        {isCollaborator
          ? (
            <div className='flex flex-col'>
              <div className='flex w-1/2 justify-between'>
                <div className='text-xl font-semibold text-black flex-grow mt-5 mb-2'>
                  Select a cover image
                </div>
                <div className='flex-grow'>
                  <ImageCropper
                    size={300}
                    setCroppedImageFile={onUploadFile}
                  />
                  <label
                    htmlFor='cropper'
                    className='text-cta-200 text-base font-medium cursor-pointer flex flex-row items-center mr-4 mt-5'
                  >
                    <UploadIcon />
                    <span className='ml-2 '>Upload Image</span>
                  </label>
                </div>
              </div>
              <Card className='w-1/2 p-6 pb-6 border border-gray-300 rounded-md relative'>
                <section className='flex flex-col mb-5'>
                  <Carousel
                    hasArrows={true}
                    itemAmount={selectedProject.industry.covers?.length ?? 0}
                    hasSeeAll={false}
                    title={<span className='text-primary-100 font-semibold text-xl'>Cover Image</span>}
                    itemSize={128}
                  >
                    {uploadedImage && <Thumbnail id={UPLOADED_IMAGE_ID} url={uploadedImage as string} />}
                    {industryCovers.map(cover => <Thumbnail key={cover.id} id={cover.id} url={cover.url} />)}
                  </Carousel>
                </section>
              </Card>
            </div>
          )
          : (
            <Card className='w-1/2 p-6 border border-gray-300 rounded-md relative'>
              <section className='flex flex-col mb-5'>
                <Carousel
                  hasArrows={false}
                  itemAmount={selectedProject.industry.covers?.length ?? 0}
                  hasSeeAll={false}
                  title={<span className='text-primary-100 font-semibold text-xl'>Cover Image</span>}
                  itemSize={128}
                >
                  {uploadedImage && <Thumbnail id={UPLOADED_IMAGE_ID} url={uploadedImage as string} />}
                  {industryCovers.map(cover => <Thumbnail key={cover.id} id={cover.id} url={cover.url} />)}
                </Carousel>
              </section>
            </Card>
          )}
      </div>
    )
  }
}
