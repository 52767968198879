import React from 'react'
import { ReactElement } from 'react'
import Spinner from 'theorem-lib/src/assets/icons/spinner.svg'

interface LoadingSpinnerProps {
  title?: string
  subtitle?: string
}

export const LoadingSpinner = (props: LoadingSpinnerProps): ReactElement => {
  return (
    <div className='flex justify-center items-center h-full flex-col space-y-4'>
      <div className='d-flex justify-content-center'>
        <Spinner className='inline mr-2 w-10 h-10 text-gray-200 animate-spin dark:text-gray-600 fill-[#C04184]' />
      </div>
      <div className='row'>
        <div className='col-12'>
          <h3 className='font-extrabold text-2xl flex flex-row items-center'>
            {props.title}
          </h3>
          <p className='text-primary-400 font-medium text-base flex flex-row items-center'>
            {props.subtitle}
          </p>
        </div>
      </div>
    </div>
  )
}
