import React from 'react'
import { SelectedProjectRole } from 'theorem-lib/src/entities/enums/SelectedProjectRole'
import { useAppState } from '../../../presenter'
import { WorkbookTemplates } from './WorkbookTemplate'

export function ProjectWorkbook() {
  const { selectedProjectRole } = useAppState()

  const ProjectWorkbookScreen = () => {
    switch (selectedProjectRole) {
      case SelectedProjectRole.Admin:
        return <WorkbookTemplates />
      case SelectedProjectRole.Lead:
        return <WorkbookTemplates />
      case SelectedProjectRole.Editor:
        return <WorkbookTemplates />
      case SelectedProjectRole.Collaborator:
        return (
          <WorkbookTemplates
            hideHeaderDropDown
            readOnlyProjectStage
            checkIfShowManageListOption
            hideDeleteListOption
          />
        )
      default:
        return <WorkbookTemplates />
    }
  }
  return <ProjectWorkbookScreen />
}
