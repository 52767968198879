import React, { useState } from 'react'
import CaseIcon from 'theorem-lib/src/assets/icons/case.svg'
import EnvelopeIcon from 'theorem-lib/src/assets/icons/envelope.svg'
import PlusIcon from 'theorem-lib/src/assets/icons/plus.svg'
import UserIcon from 'theorem-lib/src/assets/icons/user.svg'
import { SecurityRole } from '../../../generated/api'
import { useActions, useAppState } from '../../../presenter'
import Dropdown from '../../atoms/Dropdown/Dropdown'

export function AddMenuDropdown() {
  const { authenticatedUser, clients, isAdmin } = useAppState()
  const isParticipant = authenticatedUser.securityRoles.includes(SecurityRole.Participant)
  const isMember = authenticatedUser.securityRoles.includes(SecurityRole.Member)
  const { displayNewProjectSlideOut, displaySlideOutAction } = useActions()
  const [isAddMenuOpen, setIsAddMenuOpen] = useState(false)

  return (
    <Dropdown
      isOpen={(isAdmin || isMember || isParticipant) && isAddMenuOpen}
      onClose={() => setIsAddMenuOpen(false)}
      shouldOpenOnHover={isAdmin || isMember || isParticipant}
      openButton={
        <div
          className='bg-cta-200 rounded-lg flex items-center justify-center w-10 h-10 text-white'
          onClick={() => setIsAddMenuOpen(true)}
        >
          <PlusIcon />
        </div>
      }
    >
      <div className='flex flex-col text-primary-300 text-base w-max'>
        {(clients.length > 0 || isParticipant || isMember) && (
          <div
            className='flex flex-row cursor-pointer'
            onClick={() => {
              setIsAddMenuOpen(false)
              displayNewProjectSlideOut()
            }}
          >
            <CaseIcon />
            <div className='ml-3'>New Project</div>
          </div>
        )}
        {(isAdmin || isMember) && (
          <div className='flex flex-row cursor-pointer mt-3'>
            <UserIcon />
            <div
              className='ml-3'
              onClick={() => {
                setIsAddMenuOpen(false)
                displaySlideOutAction('ClientNewSlideOut')
              }}
            >
              New Client
            </div>
          </div>
        )}
        <div
          className='flex flex-row cursor-pointer mt-3 text-primary-300'
          onClick={() => {
            setIsAddMenuOpen(false)
            displaySlideOutAction('CollaboratorInvitationToFolioSlideOut')
          }}
        >
          <EnvelopeIcon />
          <div className='ml-3'>Invite Collaborator</div>
        </div>
      </div>
    </Dropdown>
  )
}
