import React from 'react'

type PriceCardProps = {
  backgroundImage?: string
  className?: string
  icon?: React.ElementType
  name: string
  value: number
}

export const PriceCard = (props: PriceCardProps) => {
  const backgroundPath =
    `linear-gradient(80.67deg, rgba(20, 53, 69, 0.96) 31.45%, rgba(20, 53, 69, 0.32) 72.16%), url(${props.backgroundImage})`
  return (
    <div
      className={`rounded-lg bg-no-repeat bg-center bg-cover flex flex-col flex-1 w-80 bg-cta-400 bg-opacity-10 p-4 shadow-none 
        ${!props.backgroundImage && `justify-between`}  ${props.className}`}
      style={{ backgroundImage: props.backgroundImage ? backgroundPath : '' }}
    >
      <div
        className={`block w-14 h-14 bg-white rounded-full flex justify-center items-center ${
          props.backgroundImage ? 'text-primary-200 mb-4' : 'text-cta-400'
        }`}
      >
        {props.icon && <props.icon />}
      </div>
      <div className='block'>
        <div className={`text-xl font-semibold ${props.backgroundImage ? 'text-white' : 'text-primary-100'}`}>
          <sup>$</sup>
          {Math.ceil(props.value).toLocaleString()}
          <sup>00</sup>
        </div>
        <div className={`text-xs font-normal ${props.backgroundImage ? 'text-white' : 'text-primary-100'}`}>
          {props.name}
        </div>
      </div>
    </div>
  )
}
