import React, { ChangeEventHandler, forwardRef, KeyboardEvent } from 'react'

type TextInputProps = {
  id?: string
  className?: string
  defaultValue?: string
  hasAutoFocus?: boolean
  value?: string
  iconLeft?: string
  iconRight?: string
  type?: string
  label?: string
  name?: string
  maxLength?: number
  placeholder?: string
  readOnly?: boolean
  onBlur?: ChangeEventHandler<HTMLInputElement>
  onChange?: ChangeEventHandler<HTMLInputElement>
  onKeyUp?: (e: KeyboardEvent<HTMLInputElement>) => void
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void
}

const TextInput = (
  {
    className,
    defaultValue,
    hasAutoFocus,
    iconLeft: IconLeft,
    iconRight: IconRight,
    label,
    onBlur,
    onKeyDown,
    onKeyUp,
    placeholder,
    readOnly,
    type,
    ...rest
  }: TextInputProps,
  ref: React.ForwardedRef<HTMLInputElement>,
): React.ReactElement => {
  return (
    <div className='border-b border-primary-400 flex flex-row grow relative'>
      {!!IconLeft && (
        <div className='flex items-center justify-center w-10 h-10'>
          <IconLeft />
        </div>
      )}
      <input
        defaultValue={defaultValue}
        type={type ? type : 'text'}
        autoFocus={hasAutoFocus}
        className={className ? className : 'border-none focus:ring-0 grow w-full'}
        onBlur={onBlur}
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        readOnly={readOnly}
        id={rest.name}
        ref={ref}
        step='any'
        {...rest}
      />

      {label && rest.name && <label htmlFor={rest.name}>{label}</label>}
      {!!IconRight && (
        <div className='flex items-center justify-center w-10 h-10 text-primary-400'>
          <IconRight />
        </div>
      )}
    </div>
  )
}

export default forwardRef(TextInput)
