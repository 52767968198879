import React from 'react'
import { SelectedProjectRole } from 'theorem-lib/src/entities/enums/SelectedProjectRole'
import { useAppState } from '../../../presenter'
import { ProjectDetailTemplates } from './ProjectDetailTemplate'

export function ProjectDetail() {
  const { selectedProjectRole } = useAppState()

  const ProjectDetailByRoles = () => {
    switch (selectedProjectRole) {
      case SelectedProjectRole.Admin:
      case SelectedProjectRole.Lead:
        return <ProjectDetailTemplates />
      case SelectedProjectRole.Editor:
        return (
          <ProjectDetailTemplates
            hideHeaderDropDown
          />
        )
      case SelectedProjectRole.Collaborator:
        return (
          <ProjectDetailTemplates
            hideHeaderDropDown
            hideEditProjectDetailsButton
            readOnlyProjectStage
          />
        )
      default:
        return <>Invalid Role</>
    }
  }
  return <ProjectDetailByRoles />
}
