import React from 'react'
import { SelectedProjectRole } from 'theorem-lib/src/entities/enums/SelectedProjectRole'
import { useAppState } from '../../../presenter'
import { ServicesTemplates } from './ServicesTemplate'
import { SpacesTemplates } from './SpacesTemplate'

export const ProjectSpaces = () => {
  const { selectedProjectRole } = useAppState()

  const ProjectSpaceScreen = () => {
    switch (selectedProjectRole) {
      case SelectedProjectRole.Admin:
      case SelectedProjectRole.Lead:
        return (
          <div>
            <SpacesTemplates
              showSpaceCardDeleteButton
            />
            <ServicesTemplates />
          </div>
        )
      case SelectedProjectRole.Editor:
        return (
          <div>
            <SpacesTemplates
              hideHeaderDropDown
            />
            <ServicesTemplates />
          </div>
        )
      case SelectedProjectRole.Collaborator:
        return (
          <div>
            <SpacesTemplates
              hideAddNewSpaceButton
              hideHeaderDropDown
              hideProjectSpacesDropDown
              hideAddSpaceButton
              hideExportDataButton
              readOnlyProjectStage
            />
            <ServicesTemplates
              hideAddNewServiceButton
            />
          </div>
        )
      default:
        return (
          <div>
            <SpacesTemplates />
            <ServicesTemplates />
          </div>
        )
    }
  }

  return <ProjectSpaceScreen />
}
