import { truncate } from 'lodash'
import React, { KeyboardEvent, useEffect, useMemo, useState } from 'react'
import CloseIcon from 'theorem-lib/src/assets/icons/close.svg'
import SearchIcon from 'theorem-lib/src/assets/icons/search.svg'
import { useActions, useAppState } from '../../../presenter'
import TextInput from '../../atoms/TextInput/TextInput'
import './style.css'

type ProjectSearchProps = {
  onIconClick: () => void
  rightIcon?: string
}

type DropdownSelection = {
  index: number
  type: 'client' | 'project'
}

export const ProjectSearch = (
  props: ProjectSearchProps,
): React.ReactElement => {
  const { filteredClients, filteredProjects } = useAppState()
  const { redirectAction, searchProjectsAndClientsAction } = useActions()
  const [selectedIndex, setSelectedIndex] = useState<DropdownSelection>({
    index: 0,
    type: 'client',
  })
  const [searchString, setSearchString] = useState('')
  let typingTimer: ReturnType<typeof setTimeout>

  const TYPING_INTERVAL = 1000
  const KEY_UP = 'ArrowUp'
  const KEY_DOWN = 'ArrowDown'
  const KEY_ENTER = 'Enter'

  useEffect(() => {
    if (filteredClients.length > 0) {
      setSelectedIndex({ index: 0, type: 'client' })
    } else if (filteredProjects.length > 0) {
      setSelectedIndex({ index: 0, type: 'project' })
    }
  }, [filteredClients, filteredProjects])

  const shouldDisplayDropdown = useMemo(
    () => searchString.trim().length > 0,
    [filteredClients, filteredProjects, searchString],
  )

  const NAME_CHAR_LIMIT = 29

  const search = () => {
    if (searchString.trim().length > 0) {
      searchProjectsAndClientsAction(searchString)
    }
  }

  const onKeyUp = (event: KeyboardEvent) => {
    if ([KEY_UP, KEY_DOWN, KEY_ENTER].includes(event.key)) return
    clearTimeout(typingTimer)
    typingTimer = setTimeout(search, TYPING_INTERVAL)
  }

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === KEY_ENTER) {
      const selectedRegister = selectedIndex.type === 'client'
        ? filteredClients[selectedIndex.index]
        : filteredProjects[selectedIndex.index - filteredClients.length]
      if (selectedIndex.type === 'client') {
        redirectAction(`/clients/${selectedRegister.id}`)
      }
      if (selectedIndex.type === 'project') {
        redirectAction(`/project/${selectedRegister.id}/dashboard`)
      }
    }
    if (event.key === KEY_DOWN || event.key === KEY_UP) {
      event.preventDefault()
      if (filteredClients.length === 0 && filteredProjects.length === 0) return
      if (event.key === KEY_DOWN) {
        if (
          selectedIndex.index + 1
            >= filteredClients.length + filteredProjects.length
        ) {
          return
        }
        const isClient = selectedIndex.index < filteredClients.length - 1
          && selectedIndex.type === 'client'
        setSelectedIndex({
          index: selectedIndex.index + 1,
          type: isClient ? 'client' : 'project',
        })
      }
      if (event.key === KEY_UP) {
        if (selectedIndex.index - 1 < 0) return
        const isProject = selectedIndex.index > filteredClients.length
          && selectedIndex.type === 'project'
        setSelectedIndex({
          index: selectedIndex.index - 1,
          type: isProject ? 'project' : 'client',
        })
      }
    }
  }

  const onKeyDown = (event: KeyboardEvent) => {
    clearTimeout(typingTimer)
    handleKeyDown(event)
  }

  const onClose = () => {
    setSearchString('')
    props.onIconClick()
  }

  return (
    <div className='flex flex-row relative grow'>
      <TextInput
        value={searchString}
        onChange={(e) => setSearchString(e.target.value)}
        iconLeft={SearchIcon}
        hasAutoFocus
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
        type='search'
      />
      <div
        className='bg-grey-400 rounded-lg flex items-center justify-center w-10 h-10 cursor-pointer ml-4'
        onClick={onClose}
      >
        {props.rightIcon ? <props.rightIcon /> : <CloseIcon />}
      </div>
      {shouldDisplayDropdown && (
        <div className='absolute bg-white top-11 w-64 shadow-md right-11.5 p-4 text-sm max-h-[500px] overflow-y-auto'>
          <div className='mb-3'>
            <p className='font-medium mb-3'>CLIENTS</p>
            <div className='p-3'>
              {filteredClients.length === 0 && <p>No matches were found</p>}
              {filteredClients.map((client, i) => (
                <div
                  tabIndex={-1}
                  className={`cursor-pointer font-normal p-2 rounded-lg hover:bg-grey-400 ${
                    selectedIndex?.index === i
                    && selectedIndex?.type === 'client'
                    && 'bg-grey-400'
                  }`}
                  key={client.id}
                >
                  <a href={`/clients/${client.id}`}>
                    {truncate(client.name, { length: NAME_CHAR_LIMIT })}
                  </a>
                </div>
              ))}
            </div>
          </div>
          <div className='text-sm'>
            <p className='font-medium mb-3'>PROJECTS</p>
            <div className='p-3'>
              {filteredProjects.length === 0 && <p>No matches were found</p>}
              {filteredProjects.map((project, i) => (
                <div
                  tabIndex={-1}
                  className={`cursor-pointer font-normal p-2 rounded-lg hover:bg-grey-400 ${
                    selectedIndex?.index - filteredClients.length === i
                    && selectedIndex?.type === 'project'
                    && 'bg-grey-400'
                  }`}
                  key={project.id}
                >
                  <a href={`/project/${project.id}/dashboard`}>
                    {truncate(project.name, { length: NAME_CHAR_LIMIT })}
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ProjectSearch
