import { yupResolver } from '@hookform/resolvers/yup'
import React, { useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import PlusIcon from 'theorem-lib/src/assets/icons/plus.svg'
import TrashIcon from 'theorem-lib/src/assets/icons/trash.svg'
import { InviteCollaboratorFormValidator } from 'theorem-lib/src/validators/inviteUserFormValidator'
import {
  InviteProjectCollaboratorsListInput,
  ProjectCollaboratorInvite,
} from '../../../../../../app/client/src/generated/api'
import { useActions, useAppState } from '../../../../../../app/client/src/presenter'
import { ProjectSecurityRoles } from '../../../types/gql_enums'
import { SlideOut } from '../../atoms/SlideOut/SlideOut'
import { SlideOutFooter } from '../../atoms/SlideOutFooter/SlideoutFooter'
import TextInput from '../../atoms/TextInput/TextInput'

export const CollaboratorInvitationSlideOut = () => {
  const {
    displayModalAction,
    displaySlideOutAction,
    submitCollaboratorInviteNewUserAction,
  } = useActions()
  const { projectRoles, selectedProject } = useAppState()

  const [isDirty, setIsDirty] = useState<boolean>(false)
  const { control, formState: { errors }, handleSubmit, register } = useForm({
    defaultValues: {
      values: [{
        email: '',
        firstName: '',
      }],
    },
    resolver: yupResolver(InviteCollaboratorFormValidator),
  })

  const { append, fields, remove } = useFieldArray(
    {
      control,
      name: 'values',
    },
  )
  const onSubmit = async (data: SubmitFormType) => {
    displaySlideOutAction('None')

    const collaboratorsListInput: InviteProjectCollaboratorsListInput = {
      projectId: selectedProject.id,
      usersList: data.values.map(user => ({
        email: user.email,
        firstName: user.firstName,
        roleId: projectRoles.find(f => f.name === ProjectSecurityRoles.Collaborator)?.id,
      } as ProjectCollaboratorInvite)),
    }

    await submitCollaboratorInviteNewUserAction(collaboratorsListInput)
  }

  const onErrors = () => {
    toast.error('Error sending invitation')
  }

  type InviteUserFormType = {
    email: string
    firstName: string
  }

  type SubmitFormType = {
    values: InviteUserFormType[]
  }

  return (
    <SlideOut
      title='Add Collaborators'
      onClose={() => isDirty ? displayModalAction('CancelProjectEditModal') : displaySlideOutAction('None')}
    >
      <h2 className='mb-6 text-primary-200 font-normal'>
        Easily invite new collaborators to your project.
      </h2>
      <form onSubmit={handleSubmit(onSubmit, onErrors)}>
        <ul>
          {fields.map((field, index) => {
            return (
              <li key={field.id} className='block mb-3 border-l-4 border-l-pink-700'>
                <div className='inline-block w-25 mt-3 mb-4 ml-2 pr-2 align-top'>
                  <TextInput
                    label='First Name'
                    type='text'
                    {...register(`values.${index}.firstName`, {
                      minLength: 1,
                      onChange: (() => {
                        setIsDirty(true)
                      }),
                      required: true,
                    })}
                  />
                  <span className='flex flex-grow text-error font-medium text-xs'>
                    {errors.values?.[index]?.firstName !== undefined && 'Please enter a valid First Name'}
                  </span>
                </div>
                <div className='inline-block w-25 mt-3 mb-4 pr-2  align-top'>
                  <TextInput
                    label='email'
                    hasAutoFocus
                    {...register(`values.${index}.email`, {
                      onChange: (() => {
                        setIsDirty(true)
                      }),
                      pattern: {
                        message: 'Please enter a valid email address',
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      },
                      required: 'This field is required.',
                    })}
                  />
                  <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                    {errors.values?.[index]?.email?.message}
                  </span>
                </div>
                <div className='mt-6 inline-block align-text-baseline'>
                  <button
                    className=' inline-block ml-8 cursor-pointer fill-[#2b5b74] button rounded-lg px-2 py-2 bg-blue-100 '
                    type='button'
                    onClick={() => remove(index)}
                  >
                    <TrashIcon />
                  </button>
                </div>
              </li>
            )
          })}
        </ul>
        <div>
          <button
            onClick={() => {
              append({
                email: '',
                firstName: '',
              })
            }}
            type='button'
            className='mt-3 inline-flex items-center font-bold text-pink-700'
          >
            <PlusIcon />
            Add another collaborator
          </button>
        </div>

        <SlideOutFooter>
          <button
            className={`bg-cta-200 hover:bg-cta-300 text-white p-3 rounded-lg font-semibold mr-2`}
            type='submit'
          >
            Send Invitation
          </button>
        </SlideOutFooter>
      </form>
    </SlideOut>
  )
}
