import React, { useEffect } from 'react'
import BudgetIcon from 'theorem-lib/src/assets/icons/budget-card.svg'
import HeadCountIcon from 'theorem-lib/src/assets/icons/capacity-card.svg'
import CheckIcon from 'theorem-lib/src/assets/icons/check.svg'
import MoveInIcon from 'theorem-lib/src/assets/icons/date-card.svg'
import EmptyMembersIcon from 'theorem-lib/src/assets/icons/members-empty.svg'
import SqftIcon from 'theorem-lib/src/assets/icons/sqft-card.svg'
import AvatarCircle from 'theorem-lib/src/components/atoms/AvatarCircle/AvatarCircle'
import AvatarInitial from 'theorem-lib/src/components/atoms/AvatarInitial/AvatarInitial'
import { formatDate } from 'theorem-lib/src/helpers/format'
import { Member, ProjectMemberStatusEnum } from '../../../generated/api'
import { useActions, useAppState } from '../../../presenter'
import { Card } from '../../atoms/Card/Card'
import { IconCard } from '../../molecules/IconCard/IconCard'
import { ProjectHeader } from '../../molecules/ProjectHeader'

export interface ProjectDashboardProps {
  hideHeaderDropDown?: boolean
  hideHeaderDeleteOption?: boolean
  readOnlyProjectStage?: boolean
}

export const DashboardTemplate = (props: ProjectDashboardProps) => {
  const { selectedProject } = useAppState()
  const { toggleProjectDetailsEditMode } = useActions()

  let squareFootage = selectedProject.budget / selectedProject.squareFootage
  squareFootage = squareFootage || 0
  let lowEstSqft = 0
  let highEstSqft = 0

  if (selectedProject.projectEstimate?.lowPrice) {
    lowEstSqft = selectedProject.projectEstimate?.lowPrice / selectedProject.squareFootage
  } else {
    lowEstSqft = 0
  }

  if (selectedProject.projectEstimate?.highPrice) {
    highEstSqft = selectedProject.projectEstimate?.highPrice / selectedProject.squareFootage
  } else {
    highEstSqft = 0
  }

  const renderMembers = (members: Array<Member>) => {
    return members.map(member => (
      member.status !== ProjectMemberStatusEnum.Denied && (
        <Member
          key={member.id}
          avatarUrl={member.avatarUrl}
          firstName={member.firstName}
          lastName={member.lastName}
          businessRole={member.businessRole}
        />
      )
    ))
  }

  useEffect(() => {
    toggleProjectDetailsEditMode(false)
  }, [])

  return (
    <div className='flex flex-col'>
      <ProjectHeader
        activeTab='dashboard'
        hideDropDown={props.hideHeaderDropDown}
        hideDeleteOption={props.hideHeaderDeleteOption}
        readOnlyProjectStage={props.readOnlyProjectStage}
      />
      <div className='flex mt-6'>
        {selectedProject.coverImageUrl
          && (
            <Card
              className='bg-no-repeat bg-center bg-cover flex flex-col mr-6 h-40 w-60 shadow-none'
              style={{ backgroundImage: `url(${selectedProject.coverImageUrl})` }}
            >
            </Card>
          )}
        {selectedProject.headCount != undefined
          && (
            <IconCard
              icon={HeadCountIcon}
              value={selectedProject.headCount.toLocaleString()}
              label='Occupancy'
              cardClassName='max-w-[20%]'
            />
          )}
        {selectedProject.moveInDate != undefined
          && (
            <IconCard
              icon={MoveInIcon}
              value={formatDate(selectedProject.moveInDate)}
              label='Move in Date'
              cardClassName='max-w-[20%]'
            />
          )}
        {selectedProject.assignedTasklistsCount != undefined
          && (
            <IconCard
              icon={CheckIcon}
              value={selectedProject.assignedTasklistsCount}
              label='Assigned Lists'
              iconClassName='scale-[200%] mt-6'
              cardClassName='max-w-[20%]'
            />
          )}
        {selectedProject.squareFootage != undefined
          && (
            <IconCard
              icon={SqftIcon}
              value={
                <>
                  {selectedProject.squareFootage.toLocaleString()} <sup>ft</sup>
                </>
              }
              label='Square Footage'
              cardClassName='max-w-[20%]'
            />
          )}
      </div>

      <div className='flex mt-6'>
        {(selectedProject.budget != undefined && selectedProject.budget != 0)
          ? (
            <IconCard
              icon={BudgetIcon}
              value={
                <>
                  $
                  {selectedProject.budget.toLocaleString()}
                </>
              }
              label='Budget'
              cardClassName='max-w-[20%]'
            />
          )
          : (
            <IconCard
              icon={BudgetIcon}
              value={
                <>
                  $
                  {selectedProject.budget.toLocaleString()}
                </>
              }
              label='Budget'
              cardClassName='max-w-[20%]'
            />
          )}
        {squareFootage != undefined
          && (
            <IconCard
              icon={BudgetIcon}
              value={
                <>
                  ${squareFootage.toLocaleString()}
                </>
              }
              label='Cost per sq ft'
              cardClassName='max-w-[20%]'
            />
          )}
        {selectedProject.projectEstimate != undefined
          && (
            <IconCard
              icon={BudgetIcon}
              value={`$${selectedProject.projectEstimate?.lowPrice.toLocaleString()} - $${
                selectedProject.projectEstimate?.highPrice.toLocaleString()
              }`}
              label='Project Budget Range'
              cardClassName='max-w-[20%]'
            />
          )}
        {(selectedProject.budget != 0 && selectedProject.squareFootage != 0)
          && (
            <IconCard
              icon={BudgetIcon}
              value={`$${lowEstSqft.toLocaleString(undefined, { maximumFractionDigits: 2 })} - $${
                highEstSqft.toLocaleString(undefined, { maximumFractionDigits: 2 })
              }`}
              label='Budget Cost Per Sq Ft Range'
              cardClassName='max-w-[20%]'
            />
          )}
      </div>
      <div className='mt-12 text-xl'>
        <h4 className='font-semibold'>Team Members</h4>
        {selectedProject.members?.length
          ? (
            <div className='mt-4'>
              {renderMembers(selectedProject.members)}
            </div>
          )
          : <NoMembers />}
      </div>
    </div>
  )
}

export type MemberProps = {
  avatarUrl?: string | null
  firstName?: string | null
  lastName?: string | null
  businessRole?: string | null
}

const NoMembers = (): React.ReactElement => {
  return (
    <div className='max-w-md ml-auto mr-auto text-center'>
      <div className='rounded-full bg-white w-14 h-14 mr-auto ml-auto mt-10'>
        <EmptyMembersIcon className='ml-auto mr-auto' />
      </div>
      <h4 className='text-xl font-semibold leading-8'>
        You don&lsquo;t have any members yet. Add team members to begin collaborating.
      </h4>
      <p className='text-base leading-6 mt-1 text-primary-200'>
        All members you add on this project will be displayed here.
      </p>
      <a href='team'>
        <button className='button cta m5 mt-6'>Add New Member</button>
      </a>
    </div>
  )
}

const Member = (props: MemberProps): React.ReactElement => {
  return (
    <div className='text-center float-left w-36 mr-6 mb-6'>
      {props.avatarUrl
        ? <AvatarCircle url={props.avatarUrl} className='ml-auto mr-auto' sizeRem={4} />
        : (
          <AvatarInitial
            firstName={props.firstName || ''}
            lastName={props.lastName || ''}
            className='ml-auto mr-auto'
            sizeRem={4}
          />
        )}
      <div className='text-base mt-3'>{props.firstName} {props.lastName}</div>
      {props.businessRole
        && <div className='text-xs text-primary-400'>{props.businessRole}</div>}
    </div>
  )
}

/** */
