import moment from 'moment'
import React from 'react'
import ArrowRightCircleIcon from 'theorem-lib/src/assets/icons/arrow-right-circle.svg'
import CalendarIcon from 'theorem-lib/src/assets/icons/calendar.svg'
import DoneIcon from 'theorem-lib/src/assets/icons/done.svg'
import MentionIcon from 'theorem-lib/src/assets/icons/mention.svg'
import MinusPersonIcon from 'theorem-lib/src/assets/icons/minus_person.svg'
import RedXIcon from 'theorem-lib/src/assets/icons/red-x.svg'
import Card from 'theorem-lib/src/components/atoms/Card/Card'
import { NotificationDetail, NotificationsTypeEnum } from '../../../generated/api'
import { useActions } from '../../../presenter'

const isAssignedToTaskList = (notification: NotificationDetail): boolean => {
  return notification.type === NotificationsTypeEnum.AssignedToTaskList && !!notification.tasklist
}
const isUnassignedToTaskList = (notification: NotificationDetail): boolean => {
  return notification.type === NotificationsTypeEnum.UnassignedToTaskList && !!notification.tasklist
}
const isAssignedToTask = (notification: NotificationDetail): boolean => {
  return notification.type === NotificationsTypeEnum.AssignedToTask && !!notification.tasklist
    && !!notification.tasklist.section && !!notification.tasklist.section.task
}
const isMentionedInTask = (notification: NotificationDetail): boolean => {
  return notification.type === NotificationsTypeEnum.MentionedInTask && !!notification.tasklist
    && !!notification.tasklist.section && !!notification.tasklist.section.task
}
const isUnassignedToTask = (notification: NotificationDetail): boolean => {
  return notification.type === NotificationsTypeEnum.UnassignedToTask && !!notification.tasklist
    && !!notification.tasklist.section && !!notification.tasklist.section.task
}

type CompletedProps = {
  completedDate: string
}
const CompletedDate = ({ completedDate }: CompletedProps) => (
  <>
    {completedDate && (
      <>
        <div className='text-sm text-success'>
          <DoneIcon width='18' className='inline-block mb-1 mr-1' /> Completed
        </div>
      </>
    )}
  </>
)

type DueDateProps = {
  dueDate: string
}
const DueDate = ({ dueDate }: DueDateProps) => (
  <>
    {dueDate && (
      <div
        className={`flex text-sm ${
          moment.utc(dueDate).isBefore(moment.utc(), 'day')
            ? 'text-error'
            : moment.utc(dueDate).isSame(moment.utc(), 'day')
            ? 'text-warn'
            : moment.utc(dueDate).isAfter(moment.utc(), 'day')
            ? 'text-primary-300'
            : ''
        }`}
      >
        <div className='flex-col'>
          <CalendarIcon width='18' className='inline-block mb-1 mr-1 mt-3' />
        </div>
        <div className='flex-col'>
          {moment.utc(dueDate).format('dddd')}
          <br />
          {moment.utc(dueDate).format('MM/DD/YYYY')}
        </div>
      </div>
    )}
  </>
)

type NotificationCardProps = {
  className?: string
  notification: NotificationDetail
}

export const NotificationCard = ({
  className,
  notification,
}: NotificationCardProps) => {
  const { dismissNotificationAction, redirectAction } = useActions()

  const handleNotificationGoTo = () => {
    if (isAssignedToTaskList(notification)) {
      redirectAction(`/project/${notification.project.id}/workbook/${notification.tasklist?.id}`)
    }

    if (isAssignedToTask(notification) || isMentionedInTask(notification)) {
      redirectAction(
        `/project/${notification.project.id}/workbook/${notification.tasklist?.id}/task/${notification?.tasklist
          ?.section?.task?.id}`,
      )
    }
  }

  const handleDismissNotification = async () => {
    await dismissNotificationAction(notification.id)
  }

  return (
    <Card
      className={`py-3 px-4 border border-gray-300 rounded-md relative ${className}`}
      style={{
        borderColor: moment.utc(notification?.tasklist?.dueDate).isBefore(moment.utc(), 'day')
            || moment.utc(notification?.tasklist?.section?.task?.dueDate).isBefore(moment.utc(), 'day')
          ? '#EA4160'
          : '',
      }}
    >
      <div className='flex flex-row'>
        <div className='flex flex-col'>
          {isAssignedToTaskList(notification) && <ArrowRightCircleIcon />}
          {isUnassignedToTaskList(notification) && <MinusPersonIcon />}
          {isAssignedToTask(notification) && <ArrowRightCircleIcon />}
          {isMentionedInTask(notification) && <MentionIcon />}
          {isUnassignedToTask(notification) && <MinusPersonIcon />}
        </div>
        <div className='flex flex-col grow px-3'>
          <div className='text-primary-400 text-sm'>
            {notification.project.name} • {notification.project?.client?.name}
          </div>
          <div className='text-primary-200 text-lg mb-2 cursor-pointer' onClick={handleNotificationGoTo}>
            {isAssignedToTaskList(notification) && <>{notification?.tasklist?.name}</>}
            {isUnassignedToTaskList(notification) && <>{notification?.tasklist?.name}</>}
            {isAssignedToTask(notification) && <>{notification?.tasklist?.section?.task?.name}</>}
            {isMentionedInTask(notification) && <>{notification?.tasklist?.section?.task?.name}</>}
            {isUnassignedToTask(notification) && <>{notification?.tasklist?.section?.task?.name}</>}
          </div>
          <div className='text-primary-400 text-sm'>
            {isAssignedToTaskList(notification) && (
              <>{notification.fromUser.firstName} {notification.fromUser.lastName} assigned to you</>
            )}
            {isUnassignedToTaskList(notification) && (
              <>You’ve been unassigned by {notification.fromUser.firstName} {notification.fromUser.lastName}</>
            )}
            {isAssignedToTask(notification) && (
              <>{notification.fromUser.firstName} {notification.fromUser.lastName} assigned to you</>
            )}
            {isMentionedInTask(notification) && (
              <>{notification.fromUser.firstName} {notification.fromUser.lastName} mentioned you</>
            )}
            {isUnassignedToTask(notification) && (
              <>You’ve been unassigned by {notification.fromUser.firstName} {notification.fromUser.lastName}</>
            )}
            <strong className='ml-2'>{moment.utc(notification.date).fromNow()}</strong>
          </div>
        </div>
        <div className='flex-col'>
          {isAssignedToTaskList(notification) && (
            <>
              {/* Tasklist can't be completed */}
              <DueDate dueDate={notification?.tasklist?.dueDate}></DueDate>
            </>
          )}
          {isUnassignedToTaskList(notification) && (
            <>
              {/* Tasklist can't be completed */}
              <DueDate dueDate={notification?.tasklist?.dueDate}></DueDate>
            </>
          )}
          {isAssignedToTask(notification) && (
            <>
              {notification?.tasklist?.section?.task?.completedDate && (
                <>
                  <CompletedDate completedDate={notification?.tasklist?.section?.task?.completedDate} />
                </>
              )}
              {!notification?.tasklist?.section?.task?.completedDate && (
                <>
                  <DueDate dueDate={notification?.tasklist?.section?.task?.dueDate}></DueDate>
                </>
              )}
            </>
          )}
          {isMentionedInTask(notification) && (
            <>
              {notification?.tasklist?.section?.task?.completedDate && (
                <>
                  <CompletedDate completedDate={notification?.tasklist?.section?.task?.completedDate} />
                </>
              )}
              {!notification?.tasklist?.section?.task?.completedDate && (
                <>
                  <DueDate dueDate={notification?.tasklist?.section?.task?.dueDate}></DueDate>
                </>
              )}
            </>
          )}
          {isUnassignedToTask(notification) && (
            <>
              {notification?.tasklist?.section?.task?.completedDate && (
                <>
                  <CompletedDate completedDate={notification?.tasklist?.section?.task?.completedDate} />
                </>
              )}
              {!notification?.tasklist?.section?.task?.completedDate && (
                <>
                  <DueDate dueDate={notification?.tasklist?.section?.task?.dueDate}></DueDate>
                </>
              )}
            </>
          )}
        </div>
      </div>
      <div>
        <button className='absolute -right-2 -top-2 bg-white cursor-pointer' onClick={handleDismissNotification}>
          <RedXIcon />
        </button>
      </div>
    </Card>
  )
}
