import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { Fragment } from 'react'
import React from 'react'
import { useAppState } from '../../../presenter'

type SlideOutProps = {
  title?: string
  onClose: () => void
  children: React.ReactNode
  maxWidth?: 'max-w-xl' | 'max-w-2xl' | 'max-w-3xl'
}

export function SlideOut({
  maxWidth = 'max-w-xl',
  ...props
}: SlideOutProps) {
  const { currentModal } = useAppState()

  return (
    <Transition.Root show={true} as={Fragment} appear>
      <Dialog
        as='div'
        className='fixed inset-0 overflow-hidden'
        onClose={() => {
          if (currentModal === 'None') {
            props.onClose()
          }
        }}
      >
        <div className='absolute inset-0 overflow-hidden'>
          <Transition.Child
            as={Fragment}
            enter='ease-in-out duration-500'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in-out duration-500'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity backdrop-blur-md' />
          </Transition.Child>

          <div className='pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10'>
            <Transition.Child
              as={Fragment}
              enter='transform transition ease-in-out duration-500 sm:duration-700'
              enterFrom='translate-x-full'
              enterTo='translate-x-0'
              leave='transform transition ease-in-out duration-500 sm:duration-700'
              leaveFrom='translate-x-0'
              leaveTo='translate-x-full'
            >
              <div className={`pointer-events-auto w-screen ${maxWidth}`}>
                <div className='flex h-full flex-col overflow-y-scroll bg-white pt-6 pb-28 shadow-xl'>
                  <div className='px-4 sm:px-6'>
                    <div className='flex items-start justify-between'>
                      <Dialog.Title className='text-lg font-bold text-gray-900'>{props.title}</Dialog.Title>
                      <div className='ml-3 flex h-7 items-center'>
                        <button
                          type='button'
                          className='rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none absolute top-8 right-4 z-10'
                          onClick={() => props.onClose()}
                        >
                          <span className='sr-only'>Close panel</span>
                          <XIcon className='h-6 w-6' aria-hidden='true' />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className='relative mt-6 flex-1 px-4 sm:px-6'>
                    {props.children}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
