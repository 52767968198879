import React from 'react'

type CalendarFilterProps = {
  label: string
  value?: string
  onChange: (date: string) => void
}

export function CalendarFilter({ label, onChange, value }: CalendarFilterProps) {
  return (
    <div className='flex flex-row min-w-[150px] grow h-14 px-4 py-2 rounded-lg box-border border-primary-500 border cursor-pointer justify-between'>
      <div className='flex flex-col grow'>
        <div className='text-primary-400 text-xs'>{label}</div>
        <div className='text-primary-100 text-base'>
          <input
            id='native-calendar'
            value={value}
            type='date'
            className='p-0 border-none focus:ring-0 w-full'
            onChange={e => onChange(e.target.value)}
          />
        </div>
      </div>
    </div>
  )
}
