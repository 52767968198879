import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import DollarSign from 'theorem-lib/src/assets/icons/dollar-sign.svg'
import PercentSign from 'theorem-lib/src/assets/icons/percent-sign.svg'
import PinkDollar from 'theorem-lib/src/assets/icons/pink-dollar-sign.svg'
import PinkPercent from 'theorem-lib/src/assets/icons/pink-percent.svg'
import SmallTextInput from '../SmallTextInput/SmallTextInput'
import TextInput from '../TextInput/TextInput'

type ServiceCreationStateProps = {
  name: string
  id: string
  index: number
}

export enum templateOptions {
  architecturalProducts = 'Architectural Products',
  changeManagement = 'Change Management',
  design = 'Design',
  estimatedTaxRate = 'Tax-Estimated-All Products and Services',
  dealerServices = 'Dealer Services',
  freightCost = 'Freight Costs',
  ffeConsulting = 'FF&E Consulting',
  installationCosts = 'Installation Costs',
  productTax = 'Tax-Estimated-Product Only',
  projectManagement = 'Project Management',
  servicesTax = 'Tax-Estimated-Services',
  soundMasking = 'Sound Masking',
  workplaceStrategy = 'Workplace Strategy',
  surcharge = 'Surcharge',
  reconfiguration = 'Reconfiguration',
  relocationServices = 'Relocation Services',
  contingencyFee = 'Contingency Fee',
  antiPriceInc = 'Anticipated Price Increases',
}

export const Service = (props: ServiceCreationStateProps) => {
  const {
    formState: { errors },
    getValues,
    register,
    setValue,
  } = useFormContext()

  const [freightToggle, setFreightToggle] = useState<boolean>()
  const [instaToggle, setInstaToggle] = useState<boolean>()
  const [serviceTaxToggle, setServiceTaxToggle] = useState<boolean>()
  const [productTaxToggle, setProductTaxToggle] = useState<boolean>()
  const [totalTaxToggle, setTotalTaxToggle] = useState<boolean>()
  const [priceIncToggle, setPriceIncToggle] = useState<boolean>()

  useEffect(() => {
    // Set initial values for installation costs
    if (
      (getValues(`values.${props.index}.percentage`) === 0 || getValues(`values.${props.index}.percentage`) === null)
      && props.name === templateOptions.installationCosts
    ) {
      setInstaToggle(false)
    } else if (
      getValues(`values.${props.index}.percentage`) !== 0 && props.name === templateOptions.installationCosts
    ) {
      setValue(`values.${props.index}.lowEstimate`, null)
      setValue(`values.${props.index}.midEstimate`, null)
      setValue(`values.${props.index}.highEstimate`, null)
      setInstaToggle(true)
    }
    // Set initial values for freight costs
    if (
      (getValues(`values.${props.index}.percentage`) === 0 || getValues(`values.${props.index}.percentage`) === null)
      && props.name === templateOptions.freightCost
    ) {
      setFreightToggle(false)
    } else if (getValues(`values.${props.index}.percentage`) !== 0 && props.name === templateOptions.freightCost) {
      setValue(`values.${props.index}.lowEstimate`, null)
      setValue(`values.${props.index}.midEstimate`, null)
      setValue(`values.${props.index}.highEstimate`, null)
      setFreightToggle(true)
    }
    // Set initial values for service tax
    if (
      (getValues(`values.${props.index}.percentage`) === 0 || getValues(`values.${props.index}.percentage`) === null)
      && props.name === templateOptions.servicesTax
    ) {
      setServiceTaxToggle(false)
    } else if (
      getValues(`values.${props.index}.percentage`) !== 0 && props.name === templateOptions.servicesTax
    ) {
      setValue(`values.${props.index}.lowEstimate`, null)
      setValue(`values.${props.index}.midEstimate`, null)
      setValue(`values.${props.index}.highEstimate`, null)
      setServiceTaxToggle(true)
    }
    // Set initial values for product tax
    if (
      (getValues(`values.${props.index}.percentage`) === 0 || getValues(`values.${props.index}.percentage`) === null)
      && props.name === templateOptions.productTax
    ) {
      setProductTaxToggle(false)
    } else if (
      getValues(`values.${props.index}.percentage`) !== 0 && props.name === templateOptions.productTax
    ) {
      setValue(`values.${props.index}.lowEstimate`, null)
      setValue(`values.${props.index}.midEstimate`, null)
      setValue(`values.${props.index}.highEstimate`, null)
      setProductTaxToggle(true)
    }
    // Set initial values for total tax
    if (
      (getValues(`values.${props.index}.percentage`) === 0 || getValues(`values.${props.index}.percentage`) === null)
      && props.name === templateOptions.estimatedTaxRate
    ) {
      setTotalTaxToggle(false)
    } else if (
      getValues(`values.${props.index}.percentage`) !== 0 && props.name === templateOptions.estimatedTaxRate
    ) {
      setValue(`values.${props.index}.lowEstimate`, null)
      setValue(`values.${props.index}.midEstimate`, null)
      setValue(`values.${props.index}.highEstimate`, null)
      setTotalTaxToggle(true)
    }

    if (
      (getValues(`values.${props.index}.percentage`) === 0 || getValues(`values.${props.index}.percentage`) === null)
      && props.name === templateOptions.antiPriceInc
    ) {
      setPriceIncToggle(false)
    } else if (getValues(`values.${props.index}.percentage`) !== 0 && props.name === templateOptions.antiPriceInc) {
      setValue(`values.${props.index}.lowEstimate`, null)
      setValue(`values.${props.index}.midEstimate`, null)
      setValue(`values.${props.index}.highEstimate`, null)
      setPriceIncToggle(true)
    }
  }, [])

  const priceIncPercentSwap = () => {
    setPriceIncToggle(true)
    setValue(`values.${props.index}.lowEstimate`, null)
    setValue(`values.${props.index}.midEstimate`, null)
    setValue(`values.${props.index}.highEstimate`, null)
    setValue(`values.${props.index}.percentage`, '')
  }

  const priceIncDollarSwap = () => {
    setPriceIncToggle(false)
    setValue(`values.${props.index}.percentage`, null)
    setValue(`values.${props.index}.lowEstimate`, '')
    setValue(`values.${props.index}.midEstimate`, '')
    setValue(`values.${props.index}.highEstimate`, '')
  }

  const productTaxDollarSwap = () => {
    setProductTaxToggle(false)
    setValue(`values.${props.index}.percentage`, null)
    setValue(`values.${props.index}.lowEstimate`, '')
    setValue(`values.${props.index}.midEstimate`, '')
    setValue(`values.${props.index}.highEstimate`, '')
  }

  const productTaxPercentSwap = () => {
    setProductTaxToggle(true)
    setValue(`values.${props.index}.lowEstimate`, null)
    setValue(`values.${props.index}.midEstimate`, null)
    setValue(`values.${props.index}.highEstimate`, null)
    setValue(`values.${props.index}.percentage`, '')
  }

  const totalTaxDollarSwap = () => {
    setTotalTaxToggle(false)
    setValue(`values.${props.index}.percentage`, null)
    setValue(`values.${props.index}.lowEstimate`, '')
    setValue(`values.${props.index}.midEstimate`, '')
    setValue(`values.${props.index}.highEstimate`, '')
  }

  const totalTaxPercentSwap = () => {
    setTotalTaxToggle(true)
    setValue(`values.${props.index}.lowEstimate`, null)
    setValue(`values.${props.index}.midEstimate`, null)
    setValue(`values.${props.index}.highEstimate`, null)
    setValue(`values.${props.index}.percentage`, '')
  }

  const serviceTaxDollarSwap = () => {
    setServiceTaxToggle(false)
    setValue(`values.${props.index}.percentage`, null)
    setValue(`values.${props.index}.lowEstimate`, '')
    setValue(`values.${props.index}.midEstimate`, '')
    setValue(`values.${props.index}.highEstimate`, '')
  }

  const serviceTaxPercentSwap = () => {
    setServiceTaxToggle(true)
    setValue(`values.${props.index}.lowEstimate`, null)
    setValue(`values.${props.index}.midEstimate`, null)
    setValue(`values.${props.index}.highEstimate`, null)
    setValue(`values.${props.index}.percentage`, '')
  }

  const freightPercentSwap = () => {
    setFreightToggle(true)
    setValue(`values.${props.index}.lowEstimate`, null)
    setValue(`values.${props.index}.midEstimate`, null)
    setValue(`values.${props.index}.highEstimate`, null)
    setValue(`values.${props.index}.percentage`, '')
  }

  const freightDollarSwap = () => {
    setFreightToggle(false)
    setValue(`values.${props.index}.percentage`, null)
    setValue(`values.${props.index}.lowEstimate`, '')
    setValue(`values.${props.index}.midEstimate`, '')
    setValue(`values.${props.index}.highEstimate`, '')
  }

  const installationPercentSwap = () => {
    setInstaToggle(true)
    setValue(`values.${props.index}.lowEstimate`, null)
    setValue(`values.${props.index}.midEstimate`, null)
    setValue(`values.${props.index}.highEstimate`, null)
    setValue(`values.${props.index}.percentage`, '')
  }
  const installationDollarSwap = () => {
    setInstaToggle(false)
    setValue(`values.${props.index}.percentage`, null)
    setValue(`values.${props.index}.lowEstimate`, '')
    setValue(`values.${props.index}.midEstimate`, '')
    setValue(`values.${props.index}.highEstimate`, '')
  }

  const SelectedService = (): JSX.Element => {
    switch (props.name) {
      case templateOptions.architecturalProducts:
        setValue(`values.${props.index}.templateId`, props.id)
        setValue(`values.${props.index}.name`, props.name)
        return (
          <div className='border-l-4 border-l-pink-700 pl-2'>
            <h1 className='text-lg font-bold text-gray-900 pb-2'>{props.name}</h1>
            <div className='flex flex-row'>
              <DollarSign className='mt-2' />
              <div className='w-32 pr-4 pl-2'>
                <SmallTextInput
                  type={'number'}
                  placeholder={'Low'}
                  hasAutoFocus={true}
                  {...register(`values.${props.index}.lowEstimate`)}
                />
                <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                  {errors.values?.[props.index]?.lowEstimate?.message}
                </span>
              </div>
              <DollarSign className='mt-2' />
              <div className='w-32 pr-4 pl-2'>
                <SmallTextInput
                  type={'number'}
                  hasAutoFocus={true}
                  {...register(`values.${props.index}.midEstimate`)}
                />
                <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                  {errors.values?.[props.index]?.midEstimate?.message}
                </span>
              </div>
              <DollarSign className='mt-2' />
              <div className='w-32 pr-4 pl-2'>
                <SmallTextInput
                  type={'number'}
                  hasAutoFocus={true}
                  {...register(`values.${props.index}.highEstimate`)}
                />
                <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                  {errors.values?.[props.index]?.highEstimate?.message}
                </span>
              </div>
            </div>
          </div>
        )
      case templateOptions.changeManagement:
        setValue(`values.${props.index}.templateId`, props.id)
        setValue(`values.${props.index}.name`, props.name)
        return (
          <div className='border-l-4 border-l-pink-700 pl-2'>
            <h1 className='text-lg font-bold text-gray-900'>{props.name}</h1>
            <div className='flex flex-row'>
              <DollarSign className='mt-2' />
              <div className='w-32 pr-4 pl-2'>
                <SmallTextInput
                  type={'number'}
                  hasAutoFocus={true}
                  {...register(`values.${props.index}.lowEstimate`)}
                />
                <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                  {errors.values?.[props.index]?.lowEstimate?.message}
                </span>
              </div>
              <DollarSign className='mt-2' />
              <div className='w-32 pr-4 pl-2'>
                <SmallTextInput
                  type={'number'}
                  hasAutoFocus={true}
                  {...register(`values.${props.index}.midEstimate`)}
                />
                <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                  {errors.values?.[props.index]?.midEstimate?.message}
                </span>
              </div>
              <DollarSign className='mt-2' />
              <div className='w-32 pr-4 pl-2'>
                <SmallTextInput
                  type={'number'}
                  hasAutoFocus={true}
                  {...register(`values.${props.index}.highEstimate`)}
                />
                <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                  {errors.values?.[props.index]?.highEstimate?.message}
                </span>
              </div>
            </div>
          </div>
        )
      case templateOptions.design:
        setValue(`values.${props.index}.templateId`, props.id)
        setValue(`values.${props.index}.name`, props.name)
        return (
          <div className='border-l-4 border-l-pink-700 pl-2'>
            <h1 className='text-lg font-bold text-gray-900'>{props.name}</h1>
            <div className='flex flex-row'>
              <DollarSign className='mt-2' />
              <div className='w-32 pr-4 pl-2'>
                <SmallTextInput
                  type={'number'}
                  hasAutoFocus={true}
                  {...register(`values.${props.index}.lowEstimate`)}
                />
                <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                  {errors.values?.[props.index]?.lowEstimate?.message}
                </span>
              </div>
              <DollarSign className='mt-2' />
              <div className='w-32 pr-4 pl-2'>
                <SmallTextInput
                  type={'number'}
                  hasAutoFocus={true}
                  {...register(`values.${props.index}.midEstimate`)}
                />
                <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                  {errors.values?.[props.index]?.midEstimate?.message}
                </span>
              </div>
              <DollarSign className='mt-2' />
              <div className='w-32 pr-4 pl-2'>
                <SmallTextInput
                  type={'number'}
                  hasAutoFocus={true}
                  {...register(`values.${props.index}.highEstimate`)}
                />
                <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                  {errors.values?.[props.index]?.highEstimate?.message}
                </span>
              </div>
            </div>
          </div>
        )
      case templateOptions.estimatedTaxRate:
        setValue(`values.${props.index}.templateId`, props.id)
        setValue(`values.${props.index}.name`, props.name)
        return (
          <div className='border-l-4 border-l-pink-700 pl-2'>
            <h1 className='text-lg font-bold text-gray-900'>{props.name}</h1>
            {totalTaxToggle
              ? (
                <div>
                  <div className='flex flex-row'>
                    <TextInput
                      hasAutoFocus={true}
                      {...register(`values.${props.index}.percentage`)}
                    />
                    <PercentSign className='mt-2' />
                    <button className='pl-8 pb-2 block' onClick={totalTaxDollarSwap}>
                      <PinkDollar />
                    </button>
                  </div>
                  <span className='flex flex-grow justify-start text-error font-medium text-xs'>
                    {errors.values?.[props.index]?.percentage?.message}
                  </span>
                </div>
              )
              : (
                <div className='flex flex-row'>
                  <DollarSign className='mt-2' />
                  <div className='w-32 pr-4 pl-2'>
                    <SmallTextInput
                      type={'number'}
                      hasAutoFocus={true}
                      {...register(`values.${props.index}.lowEstimate`)}
                    />
                    <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                      {errors.values?.[props.index]?.lowEstimate?.message}
                    </span>
                  </div>
                  <DollarSign className='mt-2' />
                  <div className='w-32 pr-4 pl-2'>
                    <SmallTextInput
                      type={'number'}
                      hasAutoFocus={true}
                      {...register(`values.${props.index}.midEstimate`)}
                    />
                    <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                      {errors.values?.[props.index]?.midEstimate?.message}
                    </span>
                  </div>
                  <DollarSign className='mt-2' />
                  <div className='w-32 pr-4 pl-2'>
                    <SmallTextInput
                      type={'number'}
                      hasAutoFocus={true}
                      {...register(`values.${props.index}.highEstimate`)}
                    />
                    <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                      {errors.values?.[props.index]?.highEstimate?.message}
                    </span>
                  </div>
                  <button className='pl-6' onClick={totalTaxPercentSwap}>
                    <PinkPercent />
                  </button>
                </div>
              )}
          </div>
        )
      case templateOptions.dealerServices:
        setValue(`values.${props.index}.templateId`, props.id)
        setValue(`values.${props.index}.name`, props.name)
        return (
          <div className='border-l-4 border-l-pink-700 pl-2'>
            <h1 className='text-lg font-bold text-gray-900'>{props.name}</h1>
            <div className='flex flex-row'>
              <DollarSign className='mt-2' />
              <div className='w-32 pr-4 pl-2'>
                <SmallTextInput
                  type={'number'}
                  hasAutoFocus={true}
                  {...register(`values.${props.index}.lowEstimate`)}
                />
                <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                  {errors.values?.[props.index]?.lowEstimate?.message}
                </span>
              </div>
              <DollarSign className='mt-2' />
              <div className='w-32 pr-4 pl-2'>
                <SmallTextInput
                  type={'number'}
                  hasAutoFocus={true}
                  {...register(`values.${props.index}.midEstimate`)}
                />
                <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                  {errors.values?.[props.index]?.midEstimate?.message}
                </span>
              </div>
              <DollarSign className='mt-2' />
              <div className='w-32 pr-4 pl-2'>
                <SmallTextInput
                  type={'number'}
                  hasAutoFocus={true}
                  {...register(`values.${props.index}.highEstimate`)}
                />
                <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                  {errors.values?.[props.index]?.highEstimate?.message}
                </span>
              </div>
            </div>
          </div>
        )
      case templateOptions.freightCost:
        setValue(`values.${props.index}.templateId`, props.id)
        setValue(`values.${props.index}.name`, props.name)
        return (
          <div className='border-l-4 border-l-pink-700 pl-2'>
            <h1 className='text-lg font-bold text-gray-900'>{props.name}</h1>
            {freightToggle
              ? (
                <div>
                  <div className='flex flex-row'>
                    <TextInput
                      hasAutoFocus={true}
                      {...register(`values.${props.index}.percentage`)}
                    />
                    <PercentSign className='mt-2' />
                    <button className='pl-8 pb-2 block' onClick={freightDollarSwap}>
                      <PinkDollar />
                    </button>
                  </div>
                  <span className='flex flex-grow justify-start text-error font-medium text-xs'>
                    {errors.values?.[props.index]?.percentage?.message}
                  </span>
                </div>
              )
              : (
                <div className='flex flex-row'>
                  <DollarSign className='mt-2' />
                  <div className='w-32 pr-4 pl-2'>
                    <SmallTextInput
                      type={'number'}
                      hasAutoFocus={true}
                      {...register(`values.${props.index}.lowEstimate`)}
                    />
                    <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                      {errors.values?.[props.index]?.lowEstimate?.message}
                    </span>
                  </div>
                  <DollarSign className='mt-2' />
                  <div className='w-32 pr-4 pl-2'>
                    <SmallTextInput
                      type={'number'}
                      hasAutoFocus={true}
                      {...register(`values.${props.index}.midEstimate`)}
                    />
                    <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                      {errors.values?.[props.index]?.midEstimate?.message}
                    </span>
                  </div>
                  <DollarSign className='mt-2' />
                  <div className='w-32 pr-4 pl-2'>
                    <SmallTextInput
                      type={'number'}
                      hasAutoFocus={true}
                      {...register(`values.${props.index}.highEstimate`)}
                    />
                    <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                      {errors.values?.[props.index]?.highEstimate?.message}
                    </span>
                  </div>
                  <button className='pl-6' onClick={freightPercentSwap}>
                    <PinkPercent />
                  </button>
                </div>
              )}
          </div>
        )
      case templateOptions.ffeConsulting:
        setValue(`values.${props.index}.templateId`, props.id)
        setValue(`values.${props.index}.name`, props.name)
        return (
          <div className='border-l-4 border-l-pink-700 pl-2'>
            <h1 className='text-lg font-bold text-gray-900'>{props.name}</h1>
            <div className='flex flex-row'>
              <DollarSign className='mt-2' />
              <div className='w-32 pr-4 pl-2'>
                <SmallTextInput
                  type={'number'}
                  hasAutoFocus={true}
                  {...register(`values.${props.index}.lowEstimate`)}
                />
                <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                  {errors.values?.[props.index]?.lowEstimate?.message}
                </span>
              </div>
              <DollarSign className='mt-2' />
              <div className='w-32 pr-4 pl-2'>
                <SmallTextInput
                  type={'number'}
                  hasAutoFocus={true}
                  {...register(`values.${props.index}.midEstimate`)}
                />
                <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                  {errors.values?.[props.index]?.midEstimate?.message}
                </span>
              </div>
              <DollarSign className='mt-2' />
              <div className='w-32 pr-4 pl-2'>
                <SmallTextInput
                  type={'number'}
                  hasAutoFocus={true}
                  {...register(`values.${props.index}.highEstimate`)}
                />
                <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                  {errors.values?.[props.index]?.highEstimate?.message}
                </span>
              </div>
            </div>
          </div>
        )
      case templateOptions.installationCosts:
        setValue(`values.${props.index}.templateId`, props.id)
        setValue(`values.${props.index}.name`, props.name)

        return (
          <div className='border-l-4 border-l-pink-700 pl-2'>
            <h1 className='text-lg font-bold text-gray-900'>{props.name}</h1>
            {instaToggle
              ? (
                <div>
                  <div className='flex flex-row'>
                    <TextInput
                      hasAutoFocus={true}
                      {...register(`values.${props.index}.percentage`)}
                    />
                    <PercentSign className='mt-2' />
                    <button className='pl-8 pb-2' onClick={installationDollarSwap}>
                      <PinkDollar />
                    </button>
                  </div>
                  <span className='flex flex-grow justify-start text-error font-medium text-xs'>
                    {errors.values?.[props.index]?.percentage?.message}
                  </span>
                </div>
              )
              : (
                <div className='flex flex-row'>
                  <DollarSign className='mt-2' />
                  <div className='w-32 pr-4 pl-2'>
                    <SmallTextInput
                      type={'number'}
                      hasAutoFocus={true}
                      {...register(`values.${props.index}.lowEstimate`)}
                    />
                    <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                      {errors.values?.[props.index]?.lowEstimate?.message}
                    </span>
                  </div>
                  <DollarSign className='mt-2' />
                  <div className='w-32 pr-4 pl-2'>
                    <SmallTextInput
                      type={'number'}
                      hasAutoFocus={true}
                      {...register(`values.${props.index}.midEstimate`)}
                    />
                    <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                      {errors.values?.[props.index]?.midEstimate?.message}
                    </span>
                  </div>
                  <DollarSign className='mt-2' />
                  <div className='w-32 pr-4 pl-2'>
                    <SmallTextInput
                      type={'number'}
                      hasAutoFocus={true}
                      {...register(`values.${props.index}.highEstimate`)}
                    />
                    <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                      {errors.values?.[props.index]?.highEstimate?.message}
                    </span>
                  </div>
                  <button className='pl-6' onClick={installationPercentSwap}>
                    <PinkPercent />
                  </button>
                </div>
              )}
          </div>
        )
      case templateOptions.productTax:
        setValue(`values.${props.index}.templateId`, props.id)
        setValue(`values.${props.index}.name`, props.name)
        return (
          <div className='border-l-4 border-l-pink-700 pl-2'>
            <h1 className='text-lg font-bold text-gray-900'>{props.name}</h1>
            {productTaxToggle
              ? (
                <div>
                  <div className='flex flex-row'>
                    <TextInput
                      hasAutoFocus={true}
                      {...register(`values.${props.index}.percentage`)}
                    />
                    <PercentSign className='mt-2' />
                    <button className='pl-8 pb-2 block' onClick={productTaxDollarSwap}>
                      <PinkDollar />
                    </button>
                  </div>
                  <span className='flex flex-grow justify-start text-error font-medium text-xs'>
                    {errors.values?.[props.index]?.percentage?.message}
                  </span>
                </div>
              )
              : (
                <div className='flex flex-row'>
                  <DollarSign className='mt-2' />
                  <div className='w-32 pr-4 pl-2'>
                    <SmallTextInput
                      type={'number'}
                      hasAutoFocus={true}
                      {...register(`values.${props.index}.lowEstimate`)}
                    />
                    <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                      {errors.values?.[props.index]?.lowEstimate?.message}
                    </span>
                  </div>
                  <DollarSign className='mt-2' />
                  <div className='w-32 pr-4 pl-2'>
                    <SmallTextInput
                      type={'number'}
                      hasAutoFocus={true}
                      {...register(`values.${props.index}.midEstimate`)}
                    />
                    <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                      {errors.values?.[props.index]?.midEstimate?.message}
                    </span>
                  </div>
                  <DollarSign className='mt-2' />
                  <div className='w-32 pr-4 pl-2'>
                    <SmallTextInput
                      type={'number'}
                      hasAutoFocus={true}
                      {...register(`values.${props.index}.highEstimate`)}
                    />
                    <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                      {errors.values?.[props.index]?.highEstimate?.message}
                    </span>
                  </div>
                  <button className='pl-6' onClick={productTaxPercentSwap}>
                    <PinkPercent />
                  </button>
                </div>
              )}
          </div>
        )
      case templateOptions.projectManagement:
        setValue(`values.${props.index}.templateId`, props.id)
        setValue(`values.${props.index}.name`, props.name)
        return (
          <div className='border-l-4 border-l-pink-700 pl-2'>
            <h1 className='text-lg font-bold text-gray-900'>{props.name}</h1>
            <div className='flex flex-row'>
              <DollarSign className='mt-2' />
              <div className='w-32 pr-4 pl-2'>
                <SmallTextInput
                  type={'number'}
                  hasAutoFocus={true}
                  {...register(`values.${props.index}.lowEstimate`)}
                />
                <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                  {errors.values?.[props.index]?.lowEstimate?.message}
                </span>
              </div>
              <DollarSign className='mt-2' />
              <div className='w-32 pr-4 pl-2'>
                <SmallTextInput
                  type={'number'}
                  hasAutoFocus={true}
                  {...register(`values.${props.index}.midEstimate`)}
                />
                <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                  {errors.values?.[props.index]?.midEstimate?.message}
                </span>
              </div>
              <DollarSign className='mt-2' />
              <div className='w-32 pr-4 pl-2'>
                <SmallTextInput
                  type={'number'}
                  hasAutoFocus={true}
                  {...register(`values.${props.index}.highEstimate`)}
                />
                <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                  {errors.values?.[props.index]?.highEstimate?.message}
                </span>
              </div>
            </div>
          </div>
        )
      case templateOptions.servicesTax:
        setValue(`values.${props.index}.templateId`, props.id)
        setValue(`values.${props.index}.name`, props.name)
        return (
          <div className='border-l-4 border-l-pink-700 pl-2'>
            <h1 className='text-lg font-bold text-gray-900'>{props.name}</h1>
            {serviceTaxToggle
              ? (
                <div>
                  <div className='flex flex-row'>
                    <TextInput
                      hasAutoFocus={true}
                      {...register(`values.${props.index}.percentage`)}
                    />
                    <PercentSign className='mt-2' />
                    <button className='pl-8 pb-2 block' onClick={serviceTaxDollarSwap}>
                      <PinkDollar />
                    </button>
                  </div>
                  <span className='flex flex-grow justify-start text-error font-medium text-xs'>
                    {errors.values?.[props.index]?.percentage?.message}
                  </span>
                </div>
              )
              : (
                <div className='flex flex-row'>
                  <DollarSign className='mt-2' />
                  <div className='w-32 pr-4 pl-2'>
                    <SmallTextInput
                      type={'number'}
                      hasAutoFocus={true}
                      {...register(`values.${props.index}.lowEstimate`)}
                    />
                    <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                      {errors.values?.[props.index]?.lowEstimate?.message}
                    </span>
                  </div>
                  <DollarSign className='mt-2' />
                  <div className='w-32 pr-4 pl-2'>
                    <SmallTextInput
                      type={'number'}
                      hasAutoFocus={true}
                      {...register(`values.${props.index}.midEstimate`)}
                    />
                    <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                      {errors.values?.[props.index]?.midEstimate?.message}
                    </span>
                  </div>
                  <DollarSign className='mt-2' />
                  <div className='w-32 pr-4 pl-2'>
                    <SmallTextInput
                      type={'number'}
                      hasAutoFocus={true}
                      {...register(`values.${props.index}.highEstimate`)}
                    />
                    <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                      {errors.values?.[props.index]?.highEstimate?.message}
                    </span>
                  </div>
                  <button className='pl-6' onClick={serviceTaxPercentSwap}>
                    <PinkPercent />
                  </button>
                </div>
              )}
          </div>
        )
      case templateOptions.soundMasking:
        setValue(`values.${props.index}.templateId`, props.id)
        setValue(`values.${props.index}.name`, props.name)
        return (
          <div className='border-l-4 border-l-pink-700 pl-2'>
            <h1 className='text-lg font-bold text-gray-900'>{props.name}</h1>
            <div className='flex flex-row'>
              <DollarSign className='mt-2' />
              <div className='w-32 pr-4 pl-2'>
                <SmallTextInput
                  type={'number'}
                  hasAutoFocus={true}
                  {...register(`values.${props.index}.lowEstimate`)}
                />
                <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                  {errors.values?.[props.index]?.lowEstimate?.message}
                </span>
              </div>
              <DollarSign className='mt-2' />
              <div className='w-32 pr-4 pl-2'>
                <SmallTextInput
                  type={'number'}
                  hasAutoFocus={true}
                  {...register(`values.${props.index}.midEstimate`)}
                />
                <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                  {errors.values?.[props.index]?.midEstimate?.message}
                </span>
              </div>
              <DollarSign className='mt-2' />
              <div className='w-32 pr-4 pl-2'>
                <SmallTextInput
                  type={'number'}
                  hasAutoFocus={true}
                  {...register(`values.${props.index}.highEstimate`)}
                />
                <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                  {errors.values?.[props.index]?.highEstimate?.message}
                </span>
              </div>
            </div>
          </div>
        )
      case templateOptions.workplaceStrategy:
        setValue(`values.${props.index}.templateId`, props.id)
        setValue(`values.${props.index}.name`, props.name)
        return (
          <div className='border-l-4 border-l-pink-700 pl-2'>
            <h1 className='text-lg font-bold text-gray-900'>{props.name}</h1>
            <div className='flex flex-row'>
              <DollarSign className='mt-2' />
              <div className='w-32 pr-4 pl-2'>
                <SmallTextInput
                  type={'number'}
                  hasAutoFocus={true}
                  {...register(`values.${props.index}.lowEstimate`)}
                />
                <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                  {errors.values?.[props.index]?.lowEstimate?.message}
                </span>
              </div>
              <DollarSign className='mt-2' />
              <div className='w-32 pr-4 pl-2'>
                <SmallTextInput
                  type={'number'}
                  hasAutoFocus={true}
                  {...register(`values.${props.index}.midEstimate`)}
                />
                <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                  {errors.values?.[props.index]?.midEstimate?.message}
                </span>
              </div>
              <DollarSign className='mt-2' />
              <div className='w-32 pr-4 pl-2'>
                <SmallTextInput
                  type={'number'}
                  hasAutoFocus={true}
                  {...register(`values.${props.index}.highEstimate`)}
                />
                <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                  {errors.values?.[props.index]?.highEstimate?.message}
                </span>
              </div>
            </div>
          </div>
        )
      case templateOptions.antiPriceInc:
        setValue(`values.${props.index}.templateId`, props.id)
        setValue(`values.${props.index}.name`, props.name)
        return (
          <div className='border-l-4 border-l-pink-700 pl-2'>
            <h1 className='text-lg font-bold text-gray-900'>{props.name}</h1>
            {priceIncToggle
              ? (
                <div>
                  <div className='flex flex-row'>
                    <TextInput
                      hasAutoFocus={true}
                      {...register(`values.${props.index}.percentage`)}
                    />
                    <PercentSign className='mt-2' />
                    <button className='pl-8 pb-2' onClick={priceIncDollarSwap}>
                      <PinkDollar />
                    </button>
                  </div>
                  <span className='flex flex-grow justify-start text-error font-medium text-xs'>
                    {errors.values?.[props.index]?.percentage?.message}
                  </span>
                </div>
              )
              : (
                <div className='flex flex-row'>
                  <DollarSign className='mt-2' />
                  <div className='w-32 pr-4 pl-2'>
                    <SmallTextInput
                      type={'number'}
                      hasAutoFocus={true}
                      {...register(`values.${props.index}.lowEstimate`)}
                    />
                    <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                      {errors.values?.[props.index]?.lowEstimate?.message}
                    </span>
                  </div>
                  <DollarSign className='mt-2' />
                  <div className='w-32 pr-4 pl-2'>
                    <SmallTextInput
                      type={'number'}
                      hasAutoFocus={true}
                      {...register(`values.${props.index}.midEstimate`)}
                    />
                    <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                      {errors.values?.[props.index]?.midEstimate?.message}
                    </span>
                  </div>
                  <DollarSign className='mt-2' />
                  <div className='w-32 pr-4 pl-2'>
                    <SmallTextInput
                      type={'number'}
                      hasAutoFocus={true}
                      {...register(`values.${props.index}.highEstimate`)}
                    />
                    <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                      {errors.values?.[props.index]?.highEstimate?.message}
                    </span>
                  </div>
                  <button className='pl-6' onClick={priceIncPercentSwap}>
                    <PinkPercent />
                  </button>
                </div>
              )}
          </div>
        )
      case templateOptions.surcharge:
        setValue(`values.${props.index}.templateId`, props.id)
        setValue(`values.${props.index}.name`, props.name)
        return (
          <div className='border-l-4 border-l-pink-700 pl-2'>
            <h1 className='text-lg font-bold text-gray-900'>{props.name}</h1>
            <div className='flex flex-row'>
              <DollarSign className='mt-2' />
              <div className='w-32 pr-4 pl-2'>
                <SmallTextInput
                  type={'number'}
                  hasAutoFocus={true}
                  {...register(`values.${props.index}.lowEstimate`)}
                />
                <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                  {errors.values?.[props.index]?.lowEstimate?.message}
                </span>
              </div>
              <DollarSign className='mt-2' />
              <div className='w-32 pr-4 pl-2'>
                <SmallTextInput
                  type={'number'}
                  hasAutoFocus={true}
                  {...register(`values.${props.index}.midEstimate`)}
                />
                <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                  {errors.values?.[props.index]?.midEstimate?.message}
                </span>
              </div>
              <DollarSign className='mt-2' />
              <div className='w-32 pr-4 pl-2'>
                <SmallTextInput
                  type={'number'}
                  hasAutoFocus={true}
                  {...register(`values.${props.index}.highEstimate`)}
                />
                <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                  {errors.values?.[props.index]?.highEstimate?.message}
                </span>
              </div>
            </div>
          </div>
        )
      case templateOptions.reconfiguration:
        setValue(`values.${props.index}.templateId`, props.id)
        setValue(`values.${props.index}.name`, props.name)
        return (
          <div className='border-l-4 border-l-pink-700 pl-2'>
            <h1 className='text-lg font-bold text-gray-900'>{props.name}</h1>
            <div className='flex flex-row'>
              <DollarSign className='mt-2' />
              <div className='w-32 pr-4 pl-2'>
                <SmallTextInput
                  type={'number'}
                  hasAutoFocus={true}
                  {...register(`values.${props.index}.lowEstimate`)}
                />
                <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                  {errors.values?.[props.index]?.lowEstimate?.message}
                </span>
              </div>
              <DollarSign className='mt-2' />
              <div className='w-32 pr-4 pl-2'>
                <SmallTextInput
                  type={'number'}
                  hasAutoFocus={true}
                  {...register(`values.${props.index}.midEstimate`)}
                />
                <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                  {errors.values?.[props.index]?.midEstimate?.message}
                </span>
              </div>
              <DollarSign className='mt-2' />
              <div className='w-32 pr-4 pl-2'>
                <SmallTextInput
                  type={'number'}
                  hasAutoFocus={true}
                  {...register(`values.${props.index}.highEstimate`)}
                />
                <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                  {errors.values?.[props.index]?.highEstimate?.message}
                </span>
              </div>
            </div>
          </div>
        )
      case templateOptions.relocationServices:
        setValue(`values.${props.index}.templateId`, props.id)
        setValue(`values.${props.index}.name`, props.name)
        return (
          <div className='border-l-4 border-l-pink-700 pl-2'>
            <h1 className='text-lg font-bold text-gray-900'>{props.name}</h1>
            <div className='flex flex-row'>
              <DollarSign className='mt-2' />
              <div className='w-32 pr-4 pl-2'>
                <SmallTextInput
                  type={'number'}
                  hasAutoFocus={true}
                  {...register(`values.${props.index}.lowEstimate`)}
                />
                <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                  {errors.values?.[props.index]?.lowEstimate?.message}
                </span>
              </div>
              <DollarSign className='mt-2' />
              <div className='w-32 pr-4 pl-2'>
                <SmallTextInput
                  type={'number'}
                  hasAutoFocus={true}
                  {...register(`values.${props.index}.midEstimate`)}
                />
                <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                  {errors.values?.[props.index]?.midEstimate?.message}
                </span>
              </div>
              <DollarSign className='mt-2' />
              <div className='w-32 pr-4 pl-2'>
                <SmallTextInput
                  type={'number'}
                  hasAutoFocus={true}
                  {...register(`values.${props.index}.highEstimate`)}
                />
                <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                  {errors.values?.[props.index]?.highEstimate?.message}
                </span>
              </div>
            </div>
          </div>
        )
      case templateOptions.contingencyFee:
        setValue(`values.${props.index}.templateId`, props.id)
        setValue(`values.${props.index}.name`, props.name)
        return (
          <div className='border-l-4 border-l-pink-700 pl-2'>
            <h1 className='text-lg font-bold text-gray-900'>{props.name}</h1>
            <div className='flex flex-row'>
              <DollarSign className='mt-2' />
              <div className='w-32 pr-4 pl-2'>
                <SmallTextInput
                  type={'number'}
                  hasAutoFocus={true}
                  {...register(`values.${props.index}.lowEstimate`)}
                />
                <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                  {errors.values?.[props.index]?.lowEstimate?.message}
                </span>
              </div>
              <DollarSign className='mt-2' />
              <div className='w-32 pr-4 pl-2'>
                <SmallTextInput
                  type={'number'}
                  hasAutoFocus={true}
                  {...register(`values.${props.index}.midEstimate`)}
                />
                <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                  {errors.values?.[props.index]?.midEstimate?.message}
                </span>
              </div>
              <DollarSign className='mt-2' />
              <div className='w-32 pr-4 pl-2'>
                <SmallTextInput
                  type={'number'}
                  hasAutoFocus={true}
                  {...register(`values.${props.index}.highEstimate`)}
                />
                <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                  {errors.values?.[props.index]?.highEstimate?.message}
                </span>
              </div>
            </div>
          </div>
        )
      default:
        return <></>
    }
  }
  return <SelectedService />
}
