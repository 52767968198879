import React from 'react'
import DevicesIcon from 'theorem-lib/src/assets/icons/devices.svg'
import EducationIcon from 'theorem-lib/src/assets/icons/education.svg'
import GovernmentIcon from 'theorem-lib/src/assets/icons/government.svg'
import HealthIcon from 'theorem-lib/src/assets/icons/health.svg'
import WFHIcon from 'theorem-lib/src/assets/icons/wfh.svg'
import CorporateIcon from 'theorem-lib/src/assets/icons/wizardCase.svg'
import { Chip } from '../../atoms/Chips/Chips'
import { IndustriesEnum } from '../NewProjectCreation/IndustriesSelect'

interface IndustriesChipProps {
  industry: IndustriesEnum
}

export const IndustriesChip = (props: IndustriesChipProps) => {
  const SelectedChip = (): JSX.Element => {
    switch (props.industry) {
      case IndustriesEnum.Corporate:
        return <Chip text='Corporate' icon={CorporateIcon} iconBackgroundColor='bg-[#DAE7F0]' />
      case IndustriesEnum.Education:
        return <Chip text='Education' icon={EducationIcon} iconBackgroundColor='bg-[#FCF8E5]' />
      case IndustriesEnum.Government:
        return <Chip text='Government' icon={GovernmentIcon} iconBackgroundColor='bg-[#F1E6DD]' />
      case IndustriesEnum.Healthcare:
        return <Chip text='Healthcare' icon={HealthIcon} iconBackgroundColor='bg-[#F7EEED]' />
      case IndustriesEnum.Technology:
        return <Chip text='Technology' icon={DevicesIcon} iconBackgroundColor='bg-[#D6EBEC]' />
      case IndustriesEnum.WorkFromHome:
        return <Chip text='WorkFromHome' icon={WFHIcon} iconBackgroundColor='bg-[#F4E5FB]' />
      default:
        return <></>
    }
  }

  return <SelectedChip />
}
