import * as ExcelJS from 'exceljs'
import saveAs from 'file-saver'
import { merge, sortBy } from 'lodash'
import { toast } from 'react-toastify'
import { SelectedProjectRole } from 'theorem-lib/src/entities/enums/SelectedProjectRole'
import { formatDate } from 'theorem-lib/src/helpers/format'
import { setActiveScreen } from 'theorem-lib/src/helpers/setActiveScreen'
import { FilterItem } from '../../components/molecules/ListFilter/ListFilter'
import { IndustriesEnum } from '../../components/molecules/NewProjectCreation/IndustriesSelect'
import {
  DiscIncInput,
  ExperienceLevelInput,
  OtherAdministrativeGoalsInput,
  OtherBusinessGoalsInput,
  OtherClinicalGoalsInput,
  OtherElementsInput,
  OtherFacilityTypesInput,
  OtherHomeTypeInput,
  OtherSpacesInput,
  OtherWorkspaceTypeInput,
  Project,
  ProjectEstimate,
  ProjectInput,
  ProjectSizeInput,
  Service,
  Space,
  SpaceComment,
  Tasklist,
  UpdateSpaceInput,
  VisualPreferencesInput,
} from '../../generated/api'
import { isProjectCollaborator, isProjectEditor, isProjectLead } from '../../helpers/projectHelper'
import { filterProjectWorkbookByProjectRole } from '../../helpers/tasklistHelper'
import { IprojectStateMachine, newProjectStateEnum } from '../../types/project'
import { Context } from '..'
import { redirect, RouteNames } from '../router'
import { defaultErrorHandler } from './actions'
import { getClientsWithFilters, getFolioClientsAction } from './clients'

export async function setSelectedProjectAction(context: Context, project: Project | undefined) {
  if (project?.id) context.state.selectedProject = await context.effects.getProject(project.id)
}

export async function submitProjectEditAction(context: Context, project: Project) {
  try {
    await context.effects.updateProject(project)
    context.state.selectedProject = await context.effects.getProject(project.id)
    context.state.currentSlideOut = 'None'
    toast('Project saved successfully!')
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function submitProjectDiscIncEditAction(context: Context, input: DiscIncInput) {
  try {
    await context.effects.updateDiscInc(input)
    context.state.selectedProject = await context.effects.getProject(input.projectId)
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

function hasPendingInvitations(context: Context) {
  // check if have pending invitations the notification didn't send within last hour
  if (context.state.userInvitations.length > 0) {
    if (!context.state.invitationNotificationTime) return true
    const now = new Date()
    const timeDiff = (now.getTime() - context.state.invitationNotificationTime.getTime()) / 1000 / 60
    const minutesDiff = Math.abs(Math.round(timeDiff))
    if (minutesDiff >= 60) return true
    return false
  }
  return false
}

export async function displayProjectsAction(context: Context) {
  try {
    context.state.currentPage = 'Loading'
    await getClientsWithFilters(context)
    context.state.industries = await context.effects.getIndustries()

    // check for pending invitations and send a notification
    if (hasPendingInvitations(context)) {
      toast('You have new project invitations')
      context.state.invitationNotificationTime = new Date()
    }

    context.state.currentPage = 'Projects'
    setActiveScreen(context, 'Projects')
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function displayProjectDashboardAction(context: Context, projectId: string) {
  try {
    context.state.currentPage = 'Loading'
    const selectedProject = await context.effects.getProject(projectId)
    context.state.selectedProject = { ...context.state.selectedProject, ...selectedProject }
    loadProjectRolesInState(context)
    context.state.industries = await context.effects.getIndustries()
    context.state.currentPage = 'ProjectDashboard'
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

function loadProjectRolesInState(context: Context) {
  const project = context.state.selectedProject
  const authenticatedUser = context.state.authenticatedUser
  const isAdmin = context.state.isAdmin
  const isUserProjectLead = isProjectLead(project, authenticatedUser)
  const isUserProjectEditor = isProjectEditor(project, authenticatedUser)
  const isUserProjectCollaborator = isProjectCollaborator(project, authenticatedUser)
  if (isAdmin) {
    context.state.selectedProjectRole = SelectedProjectRole.Admin
  } else {
    if (isUserProjectLead) context.state.selectedProjectRole = SelectedProjectRole.Lead
    if (isUserProjectEditor) context.state.selectedProjectRole = SelectedProjectRole.Editor
    if (isUserProjectCollaborator) context.state.selectedProjectRole = SelectedProjectRole.Collaborator
  }
}

export async function displayProjectDetailsAction(context: Context, projectId: string) {
  try {
    context.state.currentPage = 'Loading'
    const selectedProject = await context.effects.getProject(projectId)
    if (!context.state.clients.length || !context.state.allClients.length) {
      if (context.state.isMember) await getFolioClientsAction(context)
      else await getClientsWithFilters(context)
    }
    if (!context.state.industries.length) {
      context.state.industries = await context.effects.getIndustries()
    }
    context.state.selectedProject = { ...context.state.selectedProject, ...selectedProject }
    loadProjectRolesInState(context)
    context.state.currentPage = 'ProjectDetail'
    setActiveScreen(context, RouteNames.Projects)
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function displayProjectSpacesAction(context: Context, projectId: string) {
  try {
    context.state.currentPage = 'Loading'
    const selectedProject = await context.effects.getProject(projectId)
    context.state.selectedProject = { ...context.state.selectedProject, ...selectedProject }
    context.state.selectedProjectEstimates = await context.effects.getProjectEstimates(projectId)

    const projectSpaces = await context.effects.getProjectSpaces(projectId)
    context.state.selectedProjectSpaces = projectSpaces

    const projectServices = await context.effects.getProjectServices(projectId)
    context.state.selectedProjectServices = projectServices

    context.state.industries = await context.effects.getIndustries()
    context.state.spaces = await context.effects.getSpaces()
    context.state.servicesTemplates = await context.effects.getAllServicesTemplates()
    context.state.currentPage = 'ProjectSpaces'
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function displayProjectTeamAction(context: Context, projectId: string) {
  try {
    context.state.currentPage = 'Loading'
    context.state.projectRoles = await context.effects.getProjectsRoles()
    const selectedProject = await context.effects.getProject(projectId)
    context.state.selectedProject = { ...context.state.selectedProject, ...selectedProject }
    loadProjectRolesInState(context)
    context.state.currentPage = 'ProjectTeam'
    setActiveScreen(context, RouteNames.Projects)
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

function tasklistSort(a: Tasklist, b: Tasklist) {
  if (a && b && a.name && b.name) {
    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
    return 0
  }
  return 0
}

export async function displayProjectWorkbookAction(context: Context, projectId: string) {
  try {
    context.state.currentPage = 'Loading'
    const selectedProject = await context.effects.getProject(projectId)
    context.state.selectedProject = { ...context.state.selectedProject, ...selectedProject }
    const projectWorkbook = (await context.effects.getTasklists(projectId)).sort((a, b) =>
      (+a.isArchived) - (+b.isArchived) || a.name.localeCompare(b.name)
    )
    context.state.selectedProjectTaskLists = filterProjectWorkbookByProjectRole(
      context.state.authenticatedUser,
      context.state.selectedProject,
      projectWorkbook,
    )

    const predefinedTasklists = (await context.effects.getPredefinedTasklists(projectId)).sort(tasklistSort)
    context.state.selectedProjectPredefinedTasklists = predefinedTasklists

    context.state.currentPage = 'ProjectWorkbook'
    setActiveScreen(context, RouteNames.Projects)
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function displayProjectImageryAction(context: Context, projectId: string) {
  try {
    context.state.currentPage = 'Loading'
    const selectedProject = await context.effects.getProject(projectId)
    context.state.selectedProject = { ...context.state.selectedProject, ...selectedProject }
    context.state.currentPage = 'ProjectImagery'
    setActiveScreen(context, RouteNames.Projects)
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function displayProjectTasklistsAction(
  context: Context,
  params: { projectId: string; tasklistId: string },
) {
  try {
    await displayProjectWorkbookAction(context, params.projectId)
    context.state.selectedProjectTaskListIndex = context.state.selectedProjectTaskLists.findIndex(list =>
      list.id === params.tasklistId
    )
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function setSelectedProjectTaskListIndex(
  context: Context,
  params: { index: number },
) {
  try {
    context.state.selectedProjectTaskListIndex = params.index
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function deleteProjectAction(context: Context) {
  try {
    await context.effects.deleteProject(context.state.projectDeleteId)
    context.state.projectDeleteId = ''
    await getClientsWithFilters(context)
    toast('Project deleted successfully!')
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function updateProjectMemberDeleteIdAction(
  context: Context,
  value: { projectId: string; memberId: string },
) {
  context.state.projectDeleteId = value.projectId
  context.state.projectMemberDeleteId = value.memberId
}

export async function deleteProjectMemberAction(context: Context) {
  try {
    await context.effects.deleteProjectMember(context.state.projectDeleteId, context.state.projectMemberDeleteId)

    if (context.state.selectedProject?.members) {
      context.state.selectedProject.members = context.state?.selectedProject?.members?.filter(member =>
        member.id !== context.state.projectMemberDeleteId
      )
    }
    context.state.projectDeleteId = ''
    context.state.projectMemberDeleteId = ''
    toast('Your member was successfully removed from this project')
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function toggleProjectPinAction(context: Context, project: Project) {
  project.isPinned = !project.isPinned

  context.state.clients = [...context.state.clients].map(client => {
    client.projects?.map(projectInState => {
      if (projectInState?.id === project.id) {
        projectInState.isPinned = project.isPinned
        projectInState.datePinned = new Date()
      }
      return projectInState
    })
    return client
  })

  try {
    await context.effects.toggleProjectPin(project)
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function updateProjectDeleteIdAction(context: Context, projectId: string) {
  context.state.projectDeleteId = projectId
}
export async function toggleProjectDetailsEditMode(context: Context, editMode: boolean) {
  context.state.projectDetailsEditMode = editMode
}

export async function submitUpdateProjectAction(
  context: Context,
  { image, project, visualPreference }: { project: Project; image: File | undefined; visualPreference: boolean },
) {
  try {
    const coverImageUrl = image !== undefined
      ? await context.effects.uploadAvatarImage(image)
      : project.coverImageUrl

    const updatedProject = await context.effects.updateProject({ ...project, coverImageUrl })
    context.state.selectedProject = { ...context.state.selectedProject, ...updatedProject }

    context.state.currentSlideOut = 'None'

    // update clients state with updated project
    if (project.client) {
      context.state.clients = context.state.clients.map(client => {
        if (client.id === project.client.id) {
          client.projects = client.projects?.map((existingProject) => {
            if (existingProject.id === project.id) {
              return project
            }
            return existingProject
          })
        }
        return client
      })
      // update state for client home view
      if (
        context.state.clientEdit
        && context.state.clientEdit.id === project.client.id
        && context.state.clientEdit.projects
      ) {
        context.state.clientEdit.projects = context.state.clientEdit.projects.map(existingProject => {
          if (existingProject.id === project.id) {
            return project
          }
          return existingProject
        })
      }
    }
    visualPreference
      ? toast('Visual Preferences Updated!')
      : toast('Project saved successfully!')
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function submitUpdateProjectStageAction(context: Context, project: Project) {
  try {
    await context.effects.updateProjectsStage(project.id, project.stage)
    context.state.selectedProject = project
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function displayNewProjectSlideOut(context: Context) {
  context.state.selectedProject = {
    budget: 0,
    buildingsQty: 0,
    client: {
      createdBy: { id: '' },
      createdOn: '',
      id: '',
      industries: [],
      logoUrl: '',
      modifiedOn: '',
      name: '',
    },
    coverImageUrl: '',
    createdOn: '',
    discountPercentage: 1,
    headCount: 0,
    id: '',
    increasePercentage: 1,
    industry: {
      id: '',
      name: '',
    },
    isPinned: false,
    members: [],
    modifiedOn: '',
    moveInDate: '',
    name: '',
    squareFootage: 0,
    stage: '',
  }
  context.state.newProjectCreation = null
  context.actions.displaySlideOutAction('ManageProjectSlideOut')
}

export function formatProjectPricingCSVAction(
  context: Context,
  value: { spaces: Space[]; projectEstimate: ProjectEstimate },
) {
  const headers = [
    { key: 'type', label: 'Type' },
    { key: 'name', label: ' ' },
    { key: 'qty', label: 'Qty' },
    { key: 'high', label: 'High' },
    { key: 'mid', label: 'Mid' },
    { key: 'low', label: 'Low' },
  ]
  const data = []
  value.spaces.forEach(space => {
    const spacePrices = { high: 0, low: 0, mid: 0 }
    space.items?.forEach(item => {
      spacePrices.high += item.highPrice * item.quantity,
        spacePrices.mid += item.midPrice * item.quantity,
        spacePrices.low += item.lowPrice * item.quantity
    })
    data.push({
      high: `$${spacePrices.high}`,
      low: `$${spacePrices.low}`,
      mid: `$${spacePrices.mid}`,
      name: space.name,
      qty: space.quantity,
      type: 'Space',
    })
    space.items?.forEach(item => {
      data.push({
        high: item.highPrice,
        low: item.lowPrice,
        mid: item.midPrice,
        name: item.name,
        qty: item.quantity,
        type: 'Item',
      })
    })
  })
  data.push({})
  data.push({
    high: `$${value.projectEstimate.highPrice}`,
    low: `$${value.projectEstimate.lowPrice}`,
    mid: `$${value.projectEstimate.midPrice}`,
    type: 'Total Cost',
  })
  return { data, headers }
}

export async function excelGenerator(
  context: Context,
  value: { projectEstimate: ProjectEstimate; services: Service[]; spaces: Space[]; project: Project },
) {
  let comments: SpaceComment[] = []

  for (const space of value.spaces) {
    const data = await context.effects.getAllSpaceComments(space.id)

    comments = [...comments, ...data]
  }

  let rowCounter = 4
  const workbook = new ExcelJS.Workbook()
  const sheet = workbook.addWorksheet('Project Name', {
    pageSetup: { blackAndWhite: false },
    properties: { tabColor: { argb: 'FF00FF00' } },
  })

  sheet.columns = [
    { key: 'qty', width: 15 },
    { key: 'product', width: 70 },
    { key: 'lowUnit', style: { numFmt: '$#,##0.00' }, width: 15 },
    { key: 'lowExt', style: { numFmt: '$#,##0.00' }, width: 15 },
    { key: 'lowGap', width: 3 },
    { key: 'medUnit', style: { numFmt: '$#,##0.00' }, width: 15 },
    { key: 'medExt', style: { numFmt: '$#,##0.00' }, width: 17 },
    { key: 'medGap', width: 3 },
    { key: 'highUnit', style: { numFmt: '$#,##0.00' }, width: 15 },
    { key: 'highExt', style: { numFmt: '$#,##0.00' }, width: 15 },
  ]

  sheet.getRow(1).values = {
    qty: `CLIENT NAME: ${value.project.client.name}`,
  }
  sheet.getRow(1).font = {
    bold: true,
    size: 14,
  }
  sheet.mergeCells('A1:J1')
  sheet.getCell('A1').border = {
    bottom: { color: { argb: 'FFFFFFFF' }, style: 'double' },
    left: { color: { argb: 'FFFFFFFF' }, style: 'double' },
    right: { color: { argb: 'FFFFFFFF' }, style: 'double' },
    top: { color: { argb: 'FFFFFFFF' }, style: 'double' },
  }
  sheet.getRow(2).values = {
    qty: `PROJECT NAME: ${value.project.name}`,
  }
  sheet.getRow(2).font = {
    bold: true,
    size: 14,
  }
  sheet.mergeCells('A2:J2')
  sheet.getCell('A2').border = {
    bottom: { color: { argb: 'FFFFFFFF' }, style: 'double' },
    left: { color: { argb: 'FFFFFFFF' }, style: 'double' },
    right: { color: { argb: 'FFFFFFFF' }, style: 'double' },
  }
  sheet.getRow(3).values = {
    qty: `DATE OF EXPORT: ${new Date()}`,
  }
  sheet.getRow(3).font = {
    bold: true,
    size: 14,
  }
  sheet.mergeCells('A3:J3')
  sheet.getCell('A3').border = {
    left: { color: { argb: 'FFFFFFFF' }, style: 'double' },
    right: { color: { argb: 'FFFFFFFF' }, style: 'double' },
  }
  sheet.getRow(rowCounter++).values = {
    highExt: 'High Extended',
    highUnit: 'High Unit',
    lowExt: 'Low Extended',
    lowGap: ' ',
    lowUnit: 'Low Unit',
    medExt: 'Medium Extended',
    medGap: ' ',
    medUnit: 'Medium Unit',
    product: 'Product',
    qty: 'Quantity',
  }

  sheet.getRow(rowCounter - 1).fill = {
    fgColor: { argb: '#FFFFFF' },
    pattern: 'solid',
    type: 'pattern',
  }
  sheet.getRow(rowCounter - 1).font = {
    'color': { 'argb': 'FFFFFF' },
    'name': 'Calibri',
    'scheme': 'minor',
    'size': 12,
  }
  // Loop through each space
  value.spaces.forEach(space => {
    sheet.getRow(rowCounter++).values = {
      product: space.name,
    }
    const startingRow = rowCounter
    const copyItems = space?.items?.map(el => {
      return el
    })
    const sortedItems = sortBy(copyItems, (item) => item.dragDropIndex)

    sheet.getRow(rowCounter - 1).fill = {
      fgColor: { argb: '#FFFFFF' },
      pattern: 'solid',
      type: 'pattern',
    }
    sheet.getRow(rowCounter - 1).font = {
      'color': { 'argb': 'FFFFFF' },
      'name': 'Calibri',
      'scheme': 'minor',
      'size': 12,
    },
      sortedItems.forEach(item => {
        sheet.getRow(rowCounter++).values = {
          highExt: { date1904: false, formula: `I${rowCounter - 1}*A${rowCounter - 1}` },
          highUnit: item.highPrice,
          lowExt: { date1904: false, formula: `C${rowCounter - 1}*A${rowCounter - 1}` },
          lowUnit: item.lowPrice,
          medExt: { date1904: false, formula: `F${rowCounter - 1}*A${rowCounter - 1}` },
          medUnit: item.midPrice,
          product: item.name,
          qty: item.quantity,
        }
      })
    const endingRow = rowCounter - 1

    sheet.getRow(rowCounter++).values = {
      highExt: { date1904: false, formula: `SUM(J${startingRow}:J${endingRow})` },
      lowExt: { date1904: false, formula: `SUM(D${startingRow}:D${endingRow})` },
      medExt: { date1904: false, formula: `SUM(G${startingRow}:G${endingRow})` },
      product: `${space.name} - Unit Price`,
    }

    sheet.getRow(rowCounter++).values = {
      highExt: { date1904: false, formula: `J${rowCounter - 2}*A${rowCounter - 1}` },
      lowExt: { date1904: false, formula: `D${rowCounter - 2}*A${rowCounter - 1}` },
      medExt: { date1904: false, formula: `G${rowCounter - 2}*A${rowCounter - 1}` },
      product: `${space.name} - Extended Price`,
      qty: space.quantity,
    }

    sheet.getRow(rowCounter++).values = {
      highExt: '',
      lowExt: '',
      lowUnit: 'User Name',
      medExt: '',
      medUnit: 'Date Posted',
      product: '',
      qty: 'Comments',
    }
    sheet.getRow(rowCounter - 1).fill = {
      fgColor: { argb: '#FFFFFF' },
      pattern: 'solid',
      type: 'pattern',
    }
    sheet.getRow(rowCounter - 1).font = {
      'color': { 'argb': 'FFFFFF' },
      'name': 'Calibri',
      'scheme': 'minor',
      'size': 12,
    }

    for (const comment of comments) {
      if (comment.spaceId === space.id) {
        sheet.getRow(rowCounter++).values = {
          highExt: '',
          lowUnit: `${comment.userFirstName} ${comment.userLastName}`,
          medUnit: formatDate(comment.createdOn),
          product: '',
          qty: comment.comment,
        }
        sheet.mergeCells(`A${rowCounter - 1}:B${rowCounter - 1}`)
        sheet.mergeCells(`C${rowCounter - 1}:D${rowCounter - 1}`)
      }
    }

    sheet.getRow(rowCounter++).values = {
      highExt: '',
      lowExt: '',
      medExt: '',
      product: '',
      qty: '',
    }
    sheet.mergeCells(`A${rowCounter - 1}:J${rowCounter - 1}`)
  })
  sheet.getRow(rowCounter++).values = {
    highExt: 'High Ext Subtotal',
    lowExt: 'Low Ext Subtotal',
    medExt: 'Med Ext Subtotal',
    product: 'PRODUCT SUBTOTAL',
  }

  sheet.getRow(rowCounter++).values = {
    highExt: 'High Total',
    highUnit: 'High Each',
    lowExt: 'Low Total',
    lowUnit: 'Low Each',
    medExt: 'Medium Total',
    medUnit: 'Medium Each',
    product: 'Services',
    qty: 'Quantity Total',
  }
  sheet.getRow(rowCounter - 1).fill = {
    fgColor: { argb: '#OOOOOO' },
    pattern: 'solid',
    type: 'pattern',
  }
  sheet.getRow(rowCounter - 1).font = {
    'color': { 'argb': 'FFFFFF' },
    'name': 'Calibri',
    'scheme': 'minor',
    'size': 12,
  }

  const serviceStartRow = rowCounter
  // Loop through each service
  value.services.forEach(service => {
    if (service.percentage === 0) {
      sheet.getRow(rowCounter++).values = {
        highExt: service.highEstimate,
        lowExt: service.lowEstimate,
        medExt: service.midEstimate,
        product: service.servicesTemplates.name,
        qty: 1,
      }
    } else if (service.percentage !== 0 && service.servicesTemplates.name === 'Tax-Estimated-Product Only') {
      sheet.getRow(rowCounter++).values = {
        highExt: ((service.percentage / 100) * value.projectEstimate.highPrice),
        lowExt: ((service.percentage / 100) * value.projectEstimate.lowPrice),
        medExt: ((service.percentage / 100) * value.projectEstimate.midPrice),
        product: service.servicesTemplates.name,
        qty: 1,
      }
    } else if (service.percentage !== 0 && service.servicesTemplates.name === 'Tax-Estimated-Services') {
      sheet.getRow(rowCounter++).values = {
        highExt: value.projectEstimate.serviceTaxPercentHigh,
        lowExt: value.projectEstimate.serviceTaxPercentLow,
        medExt: value.projectEstimate.serviceTaxPercentMid,
        product: service.servicesTemplates.name,
        qty: 1,
      }
    } else if (
      service.percentage !== 0 && service.servicesTemplates.name === 'Tax-Estimated-All Products and Services'
    ) {
      sheet.getRow(rowCounter++).values = {
        highExt: value.projectEstimate.totalTaxPercentHigh,
        lowExt: value.projectEstimate.totalTaxPercentLow,
        medExt: value.projectEstimate.totalTaxPercentMid,
        product: service.servicesTemplates.name,
        qty: 1,
      }
    } else {
      sheet.getRow(rowCounter++).values = {
        highExt: ((service.percentage / 100) * value.projectEstimate.highPrice),
        lowExt: ((service.percentage / 100) * value.projectEstimate.lowPrice),
        medExt: ((service.percentage / 100) * value.projectEstimate.midPrice),
        product: service.servicesTemplates.name,
        qty: 1,
      }
    }
  })

  const serviceEndingRow = rowCounter - 1

  sheet.getRow(rowCounter++).values = {
    highExt: { date1904: false, formula: `SUM(J${serviceStartRow}:J${serviceEndingRow})` },
    lowExt: { date1904: false, formula: `SUM(D${serviceStartRow}:D${serviceEndingRow})` },
    medExt: { date1904: false, formula: `SUM(G${serviceStartRow}:G${serviceEndingRow})` },
    product: 'Services Subtotal',
  }

  sheet.getRow(rowCounter++).values = {
    highExt: '',
    lowExt: '',
    medExt: '',
    product: '',
  }

  sheet.getRow(rowCounter++).values = {
    highExt: 'High Grand total',
    lowExt: 'Low Grand total',
    medExt: 'Med Grand total',
    product: 'GRAND TOTAL',
  }

  sheet.getRow(rowCounter - 1).fill = {
    fgColor: { argb: '#OOOOOO' },
    pattern: 'solid',
    type: 'pattern',
  }
  sheet.getRow(rowCounter - 1).font = {
    'color': { 'argb': 'FFFFFF' },
    'name': 'Calibri',
    'scheme': 'minor',
    'size': 12,
  }

  const buf = await workbook.xlsx.writeBuffer()
  saveAs(new Blob([buf]), `${context.state.selectedProject.name}.xlsx`)
}

export async function updateProjectMemberRoleIdsAction(
  context: Context,
  value: { roleId: string; memberId: string; projectId: string },
) {
  context.state.selectedRoleId = value.roleId
  context.state.selectedMemberId = value.memberId
  context.state.selectedProjectId = value.projectId
}

export async function updateProjectMemberRoleAction(
  context: Context,
) {
  try {
    await context.effects.updateProjectMemberRole(
      context.state.selectedProjectId,
      context.state.selectedMemberId,
      context.state.selectedRoleId,
    )

    if (context.state.selectedProject?.members) {
      context.state?.selectedProject?.members?.forEach(member => {
        if (member.id === context.state.selectedMemberId) {
          member.role.id = context.state.selectedRoleId
        }
      })
    }
    context.state.selectedRoleId = ''
    context.state.selectedMemberId = ''
    context.state.selectedProjectId = ''
    toast('Member Role updated successfully!')
  } catch (err) {
    defaultErrorHandler(context, err)
    toast('There was an error updating member roles.')
  }
}

export async function searchProjectsAndClientsAction(context: Context, value: string) {
  try {
    const projectsAndClients = await context.effects.searchProjectsAndClients(value)
    context.state.filteredClients = projectsAndClients.clients
    context.state.filteredProjects = projectsAndClients.projects
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function clearProjectsAndClientsFilterAction(context: Context) {
  context.state.filters.industries = []
  context.state.filters.members = []
  context.state.filters.moveInDate.from = ''
  context.state.filters.moveInDate.to = ''
  try {
    await getClientsWithFilters(context)
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function updateIndustryFilterAction(context: Context, industries: FilterItem[]) {
  context.state.filters = {
    ...context.state.filters,
    industries,
  }
  try {
    await getClientsWithFilters(context)
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function updateMembersFilterAction(context: Context, members: FilterItem[]) {
  context.state.filters = {
    ...context.state.filters,
    members,
  }
  try {
    await getClientsWithFilters(context)
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function updateDateStartFilterAction(context: Context, beginDate: string) {
  context.state.filters.moveInDate.from = beginDate
  try {
    await getClientsWithFilters(context)
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function updateDateEndFilterAction(context: Context, endDate: string) {
  context.state.filters.moveInDate.to = endDate
  try {
    await getClientsWithFilters(context)
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function resendMemberInvitationAction(context: Context, value: { memberId: string; projectId: string }) {
  const tenant = await context.effects.getTenantInfoByCode()
  const { __typename, ...restOfTenantDetails } = tenant
  await context.effects.resendInvitation(value.memberId, value.projectId, restOfTenantDetails)
  toast('Invitation sent successfully!')
}

export async function submitCreateProjectAction(
  context: Context,
  { image, newProject }: { newProject: ProjectInput; image: File | undefined },
) {
  try {
    const coverImageUrl = !!image && !newProject.coverImageUrl
      ? await context.effects.uploadAvatarImage(image)
      : newProject.coverImageUrl
    const project = await context.effects.createProject({ ...newProject, coverImageUrl })
    context.state.clients.map(client => {
      if (client.id === project.client?.id) {
        client.projects?.push(project)
      }
    })
    if (context.state.clientEdit.projects && project.client.id === context.state.clientEdit.id) {
      context.state.clientEdit.projects.push(project)
    }
    context.state.currentSlideOut = 'None'
    toast('Project created successfully!')
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function setProjectCreationClientId(
  context: Context,
  clientId: string,
) {
  context.state.wizardProjectCreation = {
    ...context.state.wizardProjectCreation,
    clientId: clientId,
  }
}

export async function setNewProjectCreationClientId(context: Context, clientId: string) {
  const project = {
    ...context.state.newProjectCreation?.project,
    clientId: clientId,
  }

  context.state.newProjectCreation = merge({ ...context.state.newProjectCreation, project })
}

export async function setDrawerDataToWizard(context: Context) {
  context.state.wizardProjectCreation = {
    ...context.state.newProjectCreation?.project ?? { ...context.state.wizardProjectCreation },
  }
}

export async function setWizardDataToDrawer(context: Context) {
  context.state.newProjectCreation = {
    currentState: newProjectStateEnum.ManageProjectSlideOut,
    project: {
      ...context.state.wizardProjectCreation,
    },
  }
}

export async function setProjectCreationIndustry(
  context: Context,
  selectedIndustry: { industryId: string; industryEnum: IndustriesEnum },
) {
  context.state.wizardProjectCreation = {
    ...context.state.wizardProjectCreation,
    industryId: selectedIndustry.industryId,
  }
  context.state.wizardProjectSelectedIndustry = selectedIndustry.industryEnum
}
export async function setProjectCreationBusinessGoals(
  context: Context,
  businessGoals: OtherBusinessGoalsInput,
) {
  context.state.wizardProjectCreation = {
    ...context.state.wizardProjectCreation,
    industryDetails: {
      ...context.state.wizardProjectCreation.industryDetails,
      otherBusinessGoals: businessGoals,
    },
  }
}

export async function setProjectCreationAdministrativeGoals(
  context: Context,
  administrativeGoals: OtherAdministrativeGoalsInput,
) {
  context.state.wizardProjectCreation = {
    ...context.state.wizardProjectCreation,
    industryDetails: {
      ...context.state.wizardProjectCreation.industryDetails,
      otherAdministrativeGoals: administrativeGoals,
    },
  }
}
export async function setProjectCreationClinicalGoals(
  context: Context,
  clinicalGoals: OtherClinicalGoalsInput,
) {
  context.state.wizardProjectCreation = {
    ...context.state.wizardProjectCreation,
    industryDetails: {
      ...context.state.wizardProjectCreation.industryDetails,
      otherClinicalGoals: clinicalGoals,
    },
  }
}

export async function setProjectCreationHomeType(
  context: Context,
  homeType: OtherHomeTypeInput,
) {
  context.state.wizardProjectCreation = {
    ...context.state.wizardProjectCreation,
    industryDetails: {
      ...context.state.wizardProjectCreation.industryDetails,
      otherHomeType: homeType,
    },
  }
}

export async function setProjectCreationHomeWorkSpace(
  context: Context,
  workSpace: OtherWorkspaceTypeInput,
) {
  context.state.wizardProjectCreation = {
    ...context.state.wizardProjectCreation,
    industryDetails: {
      ...context.state.wizardProjectCreation.industryDetails,
      otherWorkspaceType: workSpace,
    },
  }
}

export async function setProjectCreationElements(
  context: Context,
  elements: OtherElementsInput,
) {
  context.state.wizardProjectCreation = {
    ...context.state.wizardProjectCreation,
    industryDetails: {
      ...context.state.wizardProjectCreation.industryDetails,
      otherElements: elements,
    },
  }
}
export async function setProjectCreationSpaces(
  context: Context,
  spaces: OtherSpacesInput,
) {
  context.state.wizardProjectCreation = {
    ...context.state.wizardProjectCreation,
    industryDetails: {
      ...context.state.wizardProjectCreation.industryDetails,
      otherSpaces: spaces,
    },
  }
}

export async function setProjectCreationSize(
  context: Context,
  size: ProjectSizeInput,
) {
  context.state.wizardProjectCreation = {
    ...context.state.wizardProjectCreation,
    industryDetails: {
      ...context.state.wizardProjectCreation.industryDetails,
      projectSize: size,
    },
  }
}

export async function setProjectCreationHeadCount(
  context: Context,
  headCount: number,
) {
  context.state.wizardProjectCreation = {
    ...context.state.wizardProjectCreation,
    headCount: headCount,
  }
}

export async function setProjectCreationFacilities(
  context: Context,
  facility: OtherFacilityTypesInput,
) {
  context.state.wizardProjectCreation = {
    ...context.state.wizardProjectCreation,
    industryDetails: {
      ...context.state.wizardProjectCreation.industryDetails,
      otherFacilityTypes: facility,
    },
  }
}
export async function setProjectCreationVisualPreferences(
  context: Context,
  visualPreferences: VisualPreferencesInput[],
) {
  context.state.wizardProjectCreation = {
    ...context.state.wizardProjectCreation,
    industryDetails: {
      ...context.state.wizardProjectCreation.industryDetails,
      visualPreferences: visualPreferences,
    },
  }
}

export async function setProjectCreationExperienceLevel(
  context: Context,
  experienceLevel: ExperienceLevelInput,
) {
  context.state.wizardProjectCreation = {
    ...context.state.wizardProjectCreation,
    industryDetails: {
      ...context.state.wizardProjectCreation.industryDetails,
      experienceLevel: experienceLevel,
    },
  }
}

export async function setProjectCreationGeneralInfo(
  context: Context,
  projectInfo: ProjectInput,
) {
  context.state.wizardProjectCreation = {
    ...context.state.wizardProjectCreation,
    budget: projectInfo.budget,
    buildingsQty: projectInfo.buildingsQty,
    clientId: projectInfo.clientId,
    companyName: projectInfo.companyName,
    coverImageUrl: projectInfo.coverImageUrl,
    headCount: projectInfo.headCount,
    members: projectInfo.members,
    moveInDate: projectInfo.moveInDate,
    name: projectInfo.name,
    squareFootage: projectInfo.squareFootage,
    stage: projectInfo.stage,
  }
}

export async function cleanProjectCreation(
  context: Context,
) {
  context.state.wizardProjectCreation = {
    budget: 0,
    buildingsQty: 0,
    clientId: '',
    companyName: '',
    coverImageUrl: '',
    headCount: 0,
    industryDetails: {
      experienceLevel: {
        other: '',
        values: [],
      },
    },
    industryId: '',
    members: [],
    name: '',
    squareFootage: 0,
  } as ProjectInput
  context.state.wizardProjectSelectedIndustry = undefined
}

export async function submitProjectCreationAction(
  context: Context,
) {
  try {
    if (context.state.wizardProjectCreation) {
      const project = await context.effects.createProject({ ...context.state.wizardProjectCreation })
      if (context.state.clientEdit.projects && project.client.id === context.state.clientEdit.id) {
        context.state.clientEdit.projects.push(project)
      }
      toast('Project Created')
      await cleanProjectCreation(context)
      context.state.currentSlideOut = 'None'
      context.state.currentModal = 'None'
      return project
    }
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function projectCreationStateMachine(
  context: Context,
  projectContext: IprojectStateMachine,
) {
  try {
    context.state.newProjectCreation = merge({ ...context.state.newProjectCreation, ...projectContext })
    switch (projectContext.currentState) {
      case newProjectStateEnum.ClientCreation:
        context.state.currentSlideOut = 'ClientNewSlideOut'
        break
      case newProjectStateEnum.CurrentPage:
        context.state.currentSlideOut = 'None'
        break
      case newProjectStateEnum.ManageProjectSlideOut:
        context.state.currentSlideOut = 'ManageProjectSlideOut'
        break
      case newProjectStateEnum.VisualPreferences:
        context.state.currentSlideOut = 'VisualPreferencesSlideOut'
        break
      case newProjectStateEnum.SubmitProject:
        {
          const project = await submitProject()
          if (context.state.isParticipant) context.state.currentModal = 'ProjectCreationModal'
          if (project) redirect(`/project/${project.id}/${context.state.isParticipant ? 'details' : 'dashboard'}`)
        }
        break
      case newProjectStateEnum.CleanProject:
        context.state.newProjectCreation = null
        break
    }
  } catch (err) {
    defaultErrorHandler(context, err)
  }

  async function submitProject() {
    try {
      if (context.state.newProjectCreation?.project) {
        const project = await context.effects.createProject({ ...context.state.newProjectCreation.project })
        if (context.state.clientEdit.projects && project.client.id === context.state.clientEdit.id) {
          context.state.clientEdit.projects.push(project)
        }
        toast('Project Created')
        context.state.newProjectCreation = null
        context.state.currentSlideOut = 'None'
        return project
      }
    } catch (err) {
      defaultErrorHandler(context, err)
    }
  }
}

export async function clearNewProjectCreation(context: Context) {
  try {
    context.state.newProjectCreation = null
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function acceptInvitationAction(context: Context, projectId: string) {
  const tenant = await context.effects.getTenantInfoByCode()

  try {
    await context.effects.acceptInvitation(projectId, tenant)
    context.state.userInvitations = context.state.userInvitations.filter(i => i.id !== projectId)
    const selectedProject = await context.effects.getProject(projectId)
    context.state.selectedProject = { ...context.state.selectedProject, ...selectedProject }
    toast('Invitation was successfully accepted.')
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function denyInvitationAction(context: Context, projectId: string) {
  try {
    await context.effects.denyInvitation(projectId)
    context.state.userInvitations = context.state.userInvitations.filter(i => i.id !== projectId)
    toast('Invitation was successfully denied.')
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function getProjectById(context: Context, projectId: string): Promise<Project | undefined> {
  try {
    const payloadProject = await context.effects.getProject(projectId).then((content) => {
      return content
    })
    return payloadProject
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function cleanSelectedProjectIndustryDetails(context: Context) {
  context.state.selectedProject.industryDetails = {
    experienceLevel: {
      other: '',
      values: [],
    },
    id: context.state.selectedProject.industryDetails?.id || '',
    otherAdministrativeGoals: {
      other: '',
      values: [],
    },
    otherBusinessGoals: {
      other: '',
      values: [],
    },
    otherClinicalGoals: {
      other: '',
      values: [],
    },
    otherElements: {
      other: '',
      values: [],
    },
    otherFacilityTypes: {
      other: '',
      values: [],
    },
    otherHomeType: {
      other: '',
      values: [],
    },
    otherSpaces: {
      other: '',
      quantValues: [],
      quantities: [],
      values: [],
    },
    otherWorkspaceType: {
      other: '',
      values: [],
    },
    projectSize: {
      other: '',
      values: [],
    },
    visualPreferences: context.state.selectedProject.industryDetails?.visualPreferences,
  }
}

export async function updateProjectOtherSpacesAction(context: Context, input: OtherSpacesInput) {
  const projectId = context.state.selectedProject.id
  try {
    const spaceData = await context.effects.updateProjectOtherSpaces(input, projectId)
    toast('Spaces successfully updated!')
    context.state.currentSlideOut = 'None'
    context.state.selectedProject = await context.effects.getProject(projectId)
    return spaceData
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}
