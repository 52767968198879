import { XIcon } from '@heroicons/react/outline'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { NEWLY_ADDED_CLIENT_ID } from 'theorem-lib/src/common/constants'
import { ExperienceLevelEnum, IndustryCover, ProjectInput } from '../../../generated/api'
import { getNameWithoutUnderScores } from '../../../helpers/projectHelper'
import { useActions, useAppState } from '../../../presenter'
import { newProjectStateEnum } from '../../../types/project'
import {
  ManageWizardProjectFormValidator,
  ManageWizardProjectFormValidatorParticipant,
  ManageWizardProjectFormValidatorWFH,
  ManageWizardProjectFormValidatorWFHParticipant,
} from '../../../validators/manageProjectFormValidator'
import { LoadingSpinner } from '../../atoms/Loading/LoadingSpinner'
import Select, { Option } from '../../atoms/Select/Select'
import { SlideIndicators } from '../../atoms/SlideIndicators/SlideIndicators'
import TextInput from '../../atoms/TextInput/TextInput'
import { Wizard } from '../../atoms/Wizard/Wizard'
import { IndustriesEnum } from '../../molecules/NewProjectCreation/IndustriesSelect'
import { CancelProjectWizardModal } from '../Modals/CancelProjectWizardModal'

export const GeneralProjectDetailsWizard = () => {
  const {
    allClients,
    clients,
    currentModal,
    industries,
    isMember,
    isParticipant,
    wizardProjectCreation,
    wizardProjectSelectedIndustry,
  } = useAppState()
  const {
    cleanProjectCreation,
    displayModalAction,
    projectCreationStateMachine,
    redirectAction,
    setProjectCreationExperienceLevel,
    setProjectCreationGeneralInfo,
    submitProjectCreationAction,
  } = useActions()
  const [industry] = useState<IndustriesEnum | undefined>(wizardProjectSelectedIndustry)
  const [projectDetails] = useState<ProjectInput>(wizardProjectCreation)
  const clientsList = isMember ? allClients : clients
  const industrySelected = industries.find(industry => wizardProjectSelectedIndustry === industry.name)
  const defaultIndustryCover = industries[0].covers ? industries[0].covers[0] : { id: '', url: '' }
  const [selectedCover] = useState<IndustryCover>(
    industrySelected?.covers ? industrySelected.covers[0] : defaultIndustryCover,
  )
  const [loading, setLoading] = useState<boolean>(false)
  const [showCancelProjectWizardModal, setShowCancelProjectWizardModal] = useState<boolean>(false)

  const experienceLevel = (Object.values(ExperienceLevelEnum) as Array<ExperienceLevelEnum>).map((
    exp,
    index,
  ) => ({
    enum: exp,
    key: index.toString(),
    value: getNameWithoutUnderScores(exp),
  }))

  const { clearErrors, formState: { errors, isDirty }, getValues, handleSubmit, register, setValue } = useForm(
    {
      defaultValues: projectDetails,
      resolver: wizardProjectSelectedIndustry === IndustriesEnum.WorkFromHome
        ? isParticipant
          ? yupResolver(ManageWizardProjectFormValidatorWFHParticipant)
          : yupResolver(ManageWizardProjectFormValidatorWFH)
        : isParticipant
        ? yupResolver(ManageWizardProjectFormValidatorParticipant)
        : yupResolver(ManageWizardProjectFormValidator),
    },
  )

  const goBack = () => {
    saveProjectDetails()
    displayModalAction('VisualPreferencesWizard')
  }

  const onClose = () => cleanProjectCreation()

  const onClickExit = () => {
    setShowCancelProjectWizardModal(true)
  }

  const onConfirmLeaveWizardModal = () => {
    cleanProjectCreation()
    displayModalAction('None')
  }

  const onKeepEditingWizardModal = () => {
    setShowCancelProjectWizardModal(false)
  }

  const Header = () => {
    return (
      <>
        <button
          type='button'
          className='rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none'
          onClick={onClickExit}
        >
          <XIcon className='h-6 w-6' aria-hidden='true' />
        </button>
      </>
    )
  }

  const Footer = () => {
    return (
      <div className='flex justify-between py-6 fixed px-6 bottom-0 right-0 w-full bg-white'>
        <button
          className='bg-[#F1F1F1] items-center text-[#35697D] px-3 rounded-lg font-semibold mr-2 h-10'
          onClick={() => goBack()}
        >
          {'<-'} Back
        </button>
        <button
          className='bg-cta-200 items-center text-white px-3 rounded-lg font-semibold mr-2 h-10'
          type='submit'
        >
          Create Project
        </button>
      </div>
    )
  }

  const onNewClientClick = () => {
    saveProjectDetails()
    displayModalAction('ClientNewWizard')
  }

  const saveProjectDetails = () => {
    const newProject: ProjectInput = {
      budget: parseFloat(getValues('budget').toString()),
      buildingsQty: parseFloat(getValues('buildingsQty').toString()),
      clientId: isParticipant ? NEWLY_ADDED_CLIENT_ID : getValues('clientId'),
      companyName: getValues('companyName'),
      coverImageUrl: selectedCover.url,
      headCount: getValues('headCount'),
      industryId: getValues('industryId'),
      members: getValues('members')?.map(member => member),
      moveInDate: getValues('moveInDate') || getValues('moveInDate') !== '' ? getValues('moveInDate') : undefined,
      name: getValues('name'),
      squareFootage: parseFloat(getValues('squareFootage').toString()),
    }
    const experienceLevel = getValues('industryDetails')?.experienceLevel
    if (experienceLevel) {
      setProjectCreationExperienceLevel(experienceLevel)
    }
    setProjectCreationGeneralInfo(newProject)
  }

  const onSubmit = async () => {
    setLoading(true)
    saveProjectDetails()
    const projectId = await submitProjectCreationAction()
    setLoading(false)
    if (projectId) redirectAction(`/project/${projectId.id}/${isParticipant ? 'details' : 'dashboard'}`)
    if (isParticipant) displayModalAction('ProjectCreationModal')
    await projectCreationStateMachine({ currentState: newProjectStateEnum.CleanProject })
  }

  return (
    <Wizard
      isOpen={currentModal === 'GeneralProjectDetailsWizard'}
      onClose={onClose}
      header={<Header />}
      exitModal={
        <CancelProjectWizardModal
          showCancelModal={showCancelProjectWizardModal}
          onConfirmLeave={onConfirmLeaveWizardModal}
          onKeepEditing={onKeepEditingWizardModal}
        />
      }
    >
      {industry
        && (
          (
            <>
              {loading
                ? (
                  <div className='translate-y-48'>
                    <LoadingSpinner
                      title='Creating your project'
                      subtitle='Don&lsquo;t navigate away from this page'
                    />
                  </div>
                )
                : (
                  <div className='flex flex-col justify-center items-center w-full sm:w-full'>
                    <div className='text-primary-100 text-xl font-bold mb-2 text-center'>
                      Finally, some project details to consider! 🎉
                    </div>
                    <div className='text-primary-200 text-base font-semibold text-center mb-6'>
                      Let us know some additional details below to help keep the project on track.
                    </div>
                    <div className='w-full pt-4 px-12'>
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='mb-8'>
                          <label className='mb-4 text-primary-100 text-lg font-bold'>Name Your Project*</label>
                          <TextInput
                            label='Project Name*'
                            hasAutoFocus
                            {...register('name')}
                            maxLength={52}
                          />
                          <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                            {errors.name?.message}
                          </span>
                        </div>
                        <div className='grid grid-cols-2 gap-4'>
                          <div>
                            {isParticipant
                              ? (
                                <>
                                  <label className='mb-4 text-primary-100 text-lg font-bold'>Company Name*</label>
                                  <TextInput
                                    label='Company Name*'
                                    hasAutoFocus
                                    {...register('companyName')}
                                    maxLength={52}
                                  />
                                  <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                                    {errors.companyName?.message}
                                  </span>
                                </>
                              )
                              : (
                                <>
                                  <label className='text-primary-100 text-lg font-bold'>Client Name*</label>
                                  <Select
                                    label='Client Name*'
                                    default={{
                                      key: projectDetails.clientId,
                                      value: clients.find(f => f.id === projectDetails.clientId)?.name ?? '',
                                    }}
                                    data={clientsList.map(client => ({ key: client.id, value: client.name }))}
                                    onNewClient={() => onNewClientClick()}
                                    onChange={client => {
                                      clearErrors('clientId')
                                      setValue('clientId', client.key)
                                    }}
                                  />
                                  <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                                    {errors.clientId?.message}
                                  </span>
                                </>
                              )}
                          </div>
                          {wizardProjectSelectedIndustry !== IndustriesEnum.WorkFromHome
                            && (
                              <div>
                                <label className='text-primary-100 text-lg font-bold'>
                                  {isParticipant ? 'Experience Level*' : 'Client’s Experience Level*'}
                                </label>
                                <Select
                                  label='Client’s Experience Level With Furniture Projects'
                                  default={experienceLevel.find(f =>
                                    f.enum === wizardProjectCreation.industryDetails?.experienceLevel?.values[0]
                                  )}
                                  data={experienceLevel}
                                  onChange={(experience: Option) => {
                                    setValue('industryDetails.experienceLevel.values', [experience.enum])
                                  }}
                                />
                                <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                                  {(errors.industryDetails as any)?.experienceLevel.values.message}
                                </span>
                              </div>
                            )}
                          <div>
                            <label className='mb-4 text-primary-100 text-lg font-bold'>Move In Date*</label>
                            <div className='flex flex-row min-w-[150px] grow border-primary-400 border-b cursor-pointer justify-between'>
                              <div className='flex flex-col grow'>
                                <div className='text-primary-400 text-xs'>First Day of Business</div>
                                <div className='text-primary-100 text-base'>
                                  <input
                                    type='date'
                                    className='p-0 border-none focus:ring-0 w-full'
                                    {...register('moveInDate')}
                                  />
                                </div>
                              </div>
                            </div>
                            <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                              {errors.moveInDate?.message}
                            </span>
                          </div>
                          <div>
                            <label className='mb-4 text-primary-100 text-lg font-bold'>Furniture Budget</label>
                            <TextInput
                              label='Furniture Budget'
                              type='number'
                              hasAutoFocus
                              {...register('budget')}
                              maxLength={52}
                            />
                            <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                              {errors.budget?.message}
                            </span>
                          </div>
                          <div>
                            <label className='mb-4 text-primary-100 text-lg font-bold'>Square Footage</label>
                            <TextInput
                              label='Square Footage'
                              hasAutoFocus
                              type='number'
                              {...register('squareFootage')}
                              maxLength={52}
                            />
                            <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                              {errors.squareFootage?.message}
                            </span>
                          </div>
                          {wizardProjectSelectedIndustry !== IndustriesEnum.WorkFromHome
                            && (
                              <div>
                                <label className='text-primary-100 text-lg font-bold'>Quantity of Buildings</label>
                                <Select
                                  label='Quantity of Buildings'
                                  data={[...Array(10).keys()].map(qty => ({
                                    key: (qty + 1).toString(),
                                    value: (qty + 1).toString(),
                                  }))}
                                  onChange={(value) => setValue('buildingsQty', parseInt(value.key))}
                                  default={{
                                    key: projectDetails.buildingsQty?.toString(),
                                    value: projectDetails.buildingsQty?.toString(),
                                  }}
                                />
                                <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                                  {errors.buildingsQty?.message}
                                </span>
                              </div>
                            )}
                        </div>
                        <Footer />
                      </form>
                    </div>
                  </div>
                )}
            </>
          )
        )}
      <SlideIndicators />
    </Wizard>
  )
}
