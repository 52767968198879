import * as Yup from 'yup'

const errorMessage = 'Please fill in the required field'

export const HealthcareFormValidator = Yup.object().shape({
  client: Yup.object().shape({
    id: Yup.string().required(errorMessage),
    name: Yup.string().required(errorMessage),
  }),
  date: Yup.string().required(errorMessage),
  industryAdministrativeGoals: Yup.array().min(1, 'Please select at least one').required(errorMessage),
  industryAdministrativeGoalsOther: Yup.string(),
  industryClinicalGoals: Yup.array().min(1, 'Please select at least one').required(errorMessage),
  industryClinicalGoalsOther: Yup.string(),
  industryExperienceLevel: Yup.array().min(1, 'Please select at least one').required(errorMessage),
  industryFacilityType: Yup.array().min(1, errorMessage),
  industryFacilityTypes: Yup.array().min(1, 'Please select at least one').required(errorMessage),
  industryProjectScale: Yup.array().min(1, 'Please select at least one').required(errorMessage),
  name: Yup.string().required(errorMessage).max(52),
  squareFootage: Yup.string().max(8, 'Maximum of 8 characters'),
})

export const WorkFromHomeFormValidator = Yup.object().shape({
  client: Yup.object().shape({
    id: Yup.string().required(errorMessage),
    name: Yup.string().required(errorMessage),
  }),
  date: Yup.string().required(errorMessage),
  industryHomeType: Yup.array().min(1, 'Please select at least one').required(errorMessage),
  industryHomeTypeOther: Yup.string(),
  industryOfficeElements: Yup.array().min(1, 'Please select at least one').required(errorMessage),
  industryOfficeElementsOther: Yup.string(),
  industryWorkSpace: Yup.array().min(1, 'Please select at least one').required(errorMessage),
  industryWorkSpaceOther: Yup.string(),
  name: Yup.string().required(errorMessage).max(52),
})

export const CorporateFormValidator = Yup.object().shape({
  client: Yup.object().shape({
    id: Yup.string().required(errorMessage),
    name: Yup.string().required(errorMessage),
  }),
  date: Yup.string().required(errorMessage),
  industryBusinessGoals: Yup.array().min(1, 'Please select at least one').required(errorMessage),
  industryBusinessGoalsOther: Yup.string(),
  industryExperienceLevel: Yup.array().min(1, 'Please select at least one').required(errorMessage),
  industryProjectScale: Yup.array().min(1, 'Please select at least one').required(errorMessage),
  industryTypesOfSpacesOther: Yup.string(),
  // Quant Values
  industryTypesOfSpacesQuantValues: Yup.array().of(
    Yup.object().shape({
      amount: Yup.number().integer().min(1, 'Can not be less than 1').required(),
      values: Yup.string().required(errorMessage),
    }),
  ),
  name: Yup.string().required(errorMessage).max(52),
  squareFootage: Yup.string().max(8, 'Maximum of 8 characters'),
})

export const ManageProjectFormValidator = Yup.object().shape({
  client: Yup.object().shape({
    id: Yup.string().required(errorMessage),
    name: Yup.string().required(errorMessage),
  }),
  date: Yup.string().required(errorMessage),
  headcount: Yup.string().max(8, 'Maximum of 8 characters'),
  industry: Yup.object().shape({
    id: Yup.string().required(errorMessage),
    name: Yup.string().required(errorMessage),
  }),
})

export const ManageProjectParticipantFormValidator = Yup.object().shape({
  companyName: Yup.string().required(errorMessage).max(52),
  date: Yup.string().required(errorMessage),
  headcount: Yup.string().max(8, 'Maximum of 8 characters'),
  industry: Yup.object().shape({
    id: Yup.string().required(errorMessage),
    name: Yup.string().required(errorMessage),
  }),
  name: Yup.string().required(errorMessage).max(52),
  squareFootage: Yup.string().max(8, 'Maximum of 8 characters'),
})

export const ManageWizardProjectFormValidatorParticipant = Yup.object().shape({
  companyName: Yup.string().required(errorMessage).max(52),
  industryDetails: Yup.object().shape({
    experienceLevel: Yup.object().shape({
      values: Yup.array().min(1, 'Please select at least one').required(errorMessage),
    }),
  }),
  moveInDate: Yup.string().required(errorMessage),
  name: Yup.string().required(errorMessage).max(52),
})

export const ManageWizardProjectFormValidator = Yup.object().shape({
  clientId: Yup.string().required(errorMessage).max(52),
  industryDetails: Yup.object().shape({
    experienceLevel: Yup.object().shape({
      values: Yup.array().min(1, 'Please select at least one').required(errorMessage),
    }),
  }),
  moveInDate: Yup.string().required(errorMessage),
  name: Yup.string().required(errorMessage).max(52),
})

export const ManageWizardProjectFormValidatorWFHParticipant = Yup.object().shape({
  companyName: Yup.string().required(errorMessage).max(52),
  moveInDate: Yup.string().required(errorMessage),
  name: Yup.string().required(errorMessage).max(52),
})

export const ManageWizardProjectFormValidatorWFH = Yup.object().shape({
  clientId: Yup.string().required(errorMessage).max(52),
  moveInDate: Yup.string().required(errorMessage),
  name: Yup.string().required(errorMessage).max(52),
})

export const CollabHealthcareFormValidator = Yup.object().shape({
  companyName: Yup.string().required(errorMessage),
  date: Yup.string().required(errorMessage),
  industryAdministrativeGoals: Yup.array().min(1, 'Please select at least one').required(errorMessage),
  industryAdministrativeGoalsOther: Yup.string(),
  industryClinicalGoals: Yup.array().min(1, 'Please select at least one').required(errorMessage),
  industryClinicalGoalsOther: Yup.string(),
  industryExperienceLevel: Yup.array().min(1, 'Please select at least one').required(errorMessage),
  industryFacilityType: Yup.array().min(1, errorMessage),
  industryFacilityTypes: Yup.array().min(1, 'Please select at least one').required(errorMessage),
  industryProjectScale: Yup.array().min(1, 'Please select at least one').required(errorMessage),
  industryTypesOfSpaces: Yup.array().min(1, 'Please select at least one').required(errorMessage),
  name: Yup.string().required(errorMessage).max(52),
  squareFootage: Yup.string().max(8, 'Maximum of 8 characters'),
})

export const CollabWorkFromHomeFormValidator = Yup.object().shape({
  companyName: Yup.string().required(errorMessage),
  date: Yup.string().required(errorMessage),
  industryHomeType: Yup.array().min(1, 'Please select at least one').required(errorMessage),
  industryHomeTypeOther: Yup.string(),
  industryOfficeElements: Yup.array().min(1, 'Please select at least one').required(errorMessage),
  industryOfficeElementsOther: Yup.string(),
  industryWorkSpace: Yup.array().min(1, 'Please select at least one').required(errorMessage),
  industryWorkSpaceOther: Yup.string(),
  name: Yup.string().required(errorMessage).max(52),
})

export const CollabCorporateFormValidator = Yup.object().shape({
  companyName: Yup.string().required(errorMessage),
  date: Yup.string().required(errorMessage),
  industryBusinessGoals: Yup.array().min(1, 'Please select at least one').required(errorMessage),
  industryBusinessGoalsOther: Yup.string(),
  industryExperienceLevel: Yup.array().min(1, 'Please select at least one').required(errorMessage),
  industryProjectScale: Yup.array().min(1, 'Please select at least one').required(errorMessage),
  industryTypesOfSpaces: Yup.array().min(1, 'Please select at least one').required(errorMessage),
  industryTypesOfSpacesOther: Yup.string(),
  name: Yup.string().required(errorMessage).max(52),
  squareFootage: Yup.string().max(8, 'Maximum of 8 characters'),
})
