import { CloudUploadIcon } from '@heroicons/react/solid'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import CaseIcon from 'theorem-lib/src/assets/icons/case.svg'
import TrashIcon from 'theorem-lib/src/assets/icons/trash.svg'
import { ImageCropper } from 'theorem-lib/src/components/atoms/ImageCropper/ImageCropper'
import { useActions, useAppState } from '../../../presenter'
import { LoadingSpinner } from '../../atoms/Loading/LoadingSpinner'
import { SlideOut } from '../../atoms/SlideOut/SlideOut'
import { SlideOutFooter } from '../../atoms/SlideOutFooter/SlideoutFooter'

export const ClientNewSlideOut = () => {
  const { clientNew, clients, industries, validationErrors } = useAppState()
  const { closeClientNewAction, submitClientNewAction, updateClientNewAction, uploadClientImageAction } = useActions()
  const [loading, setLoading] = useState<boolean>(false)

  const onSubmit = async (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault()
    const duplicateName = clients.find(el => el.name == clientNew.name)

    if (!duplicateName) {
      setLoading(true)
      await submitClientNewAction()
      setLoading(false)
    } else {
      toast('Client name already exists')
    }
  }

  return (
    <SlideOut title='New Client' onClose={() => closeClientNewAction()}>
      {!loading
        ? (
          <form
            onSubmit={async (event) => await onSubmit(event)}
          >
            <div className='mb-8'>
              {clientNew.logoUrl && (
                <img
                  src={clientNew.logoUrl}
                  className='bg-primary-500 w-24 h-24 rounded-full inline-block align-middle'
                />
              )}
              {!clientNew.logoUrl && (
                <div className='bg-primary-500 w-24 h-24 rounded-full  text-primary-300 inline-flex flex-row  justify-center items-center'>
                  <CaseIcon />
                </div>
              )}

              <div className='ml-8 inline-block align-middle'>
                <label htmlFor='cropper' className='text-cta-200 cursor-pointer font-normal'>
                  <CloudUploadIcon className='w-5 inline-block mr-1' />Select image
                </label>

                {clientNew.logoUrl && (
                  <button
                    type='button'
                    className=' text-primary-300 mt-2'
                    onClick={() => updateClientNewAction({ client: { ...clientNew, logoUrl: '' } })}
                  >
                    <TrashIcon className='w-5 inline-block mr-1' />Remove Image
                  </button>
                )}
              </div>
            </div>

            <ImageCropper
              size={300}
              setCroppedImageFile={(file) => uploadClientImageAction({ avatarFile: file, client: clientNew })}
            />

            <div className='mb-8 relative'>
              <input
                value={clientNew.name || ''}
                onChange={(event) => updateClientNewAction({ client: { ...clientNew, name: event.target.value } })}
                id='name'
                name='name'
                type='text'
                placeholder=' '
                maxLength={52}
              />
              <label htmlFor='name'>
                Client Name
              </label>
              <p className='text-error'>{validationErrors.get('clientName')}</p>
            </div>

            <div className='mb-8'>
              <label>
                Industries
              </label>
              {industries.map(industry => (
                <div key={industry.id}>
                  <label className='inline-flex items-center my-2'>
                    <input
                      className='form-checkbox'
                      type='checkbox'
                      name='industriesIds'
                      value={industry.id}
                      checked={clientNew.industriesIds?.includes(industry.id)}
                      onChange={() => {
                        let updatedIndustries = [...clientNew.industriesIds || []]
                        if (clientNew.industriesIds?.includes(industry.id)) {
                          updatedIndustries = clientNew.industriesIds.filter(item => item !== industry.id)
                        } else {
                          updatedIndustries.push(industry.id)
                        }
                        updateClientNewAction({ client: { ...clientNew, industriesIds: updatedIndustries } })
                      }}
                    />
                    <span className='ml-2 text-sm'>{industry.name}</span>
                  </label>
                </div>
              ))}
            </div>

            <p className='text-error'>{validationErrors.get('industriesIds')}</p>

            <SlideOutFooter>
              <button type='submit' className='button cta'>Create</button>
            </SlideOutFooter>
          </form>
        )
        : (
          <div className='translate-y-48'>
            <LoadingSpinner
              title='Creating your Client'
              subtitle='Don&lsquo;t navigate away from this page'
            />
          </div>
        )}
    </SlideOut>
  )
}
