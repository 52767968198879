import { ApolloClient, ApolloError, InMemoryCache, ServerError } from '@apollo/client'
import { omit } from 'lodash'
import config from '../config'
import {
  AddClientDocument,
  AddProjectDocument,
  Client,
  ClientInput,
  DeleteClientDocument,
  DeleteProjectDocument,
  DiscIncInput,
  GetClientDocument,
  GetProjectDocument,
  GetProjectEstimatesDocument,
  GetProjectServicesDocument,
  ListFolioClientsDocument,
  ListProjectSpacesDocument,
  ListProjectsRolesDocument,
  LoadClientsAndProjectsDocument,
  OtherSpacesInput,
  Project,
  ProjectEstimate,
  ProjectInput,
  ProjectRole,
  ProjectStageInput,
  SearchClientsAndProjectsDocument,
  Service,
  SetProjectPinDocument,
  Space,
  UpdateClientDocument,
  UpdateDiscIncValuesDocument,
  UpdateOtherSpacesDocument,
  UpdateProjectDocument,
  UpdateProjectsStageDocument,
} from '../generated/api'
import { UnauthenticatedError, UnexpectedError } from '../presenter/errors'

const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  credentials: 'include',
  uri: config.appApiGraphqlUrl,
})

function isApolloError(err: unknown | ApolloError): err is ApolloError {
  return (err as ApolloError).graphQLErrors !== undefined
}

function isServerError(err: unknown | ServerError): err is ServerError {
  return (err as ServerError).name == 'ServerError'
}

function graphqlErrorHandler(err: unknown | ApolloError) {
  if (isApolloError(err)) {
    const networkError = err.networkError
    if (isServerError(networkError)) {
      if (networkError.statusCode == 401) throw new UnauthenticatedError()
      if (networkError.statusCode == 404) throw new UnexpectedError()
    }
  }
}

export type ProjectAndClients = {
  clients: Client[]
  projects: Project[]
}

export const getClients = async (
  industriesIds: string[],
  memberIds: string[],
  startDate: string | undefined,
  endDate: string | undefined,
): Promise<Client[]> => {
  try {
    const inputStartDate = startDate === '' ? null : startDate
    const inputEndDate = endDate === '' ? null : endDate
    const { data } = await apolloClient.query({
      fetchPolicy: 'no-cache',
      query: LoadClientsAndProjectsDocument,
      variables: { endDate: inputEndDate, industriesIds, memberIds, startDate: inputStartDate },
    })
    return data.listMemberClients
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const getFolioClients = async (): Promise<Client[]> => {
  try {
    const { data } = await apolloClient.query({
      fetchPolicy: 'no-cache',
      query: ListFolioClientsDocument,
    })
    return data.listFolioClients
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const getClient = async (id: string): Promise<Client> => {
  try {
    const { data } = await apolloClient.query({ fetchPolicy: 'no-cache', query: GetClientDocument, variables: { id } })
    return data.getClient
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const getProject = async (id: string): Promise<Project> => {
  try {
    const { data } = await apolloClient.query({ fetchPolicy: 'no-cache', query: GetProjectDocument, variables: { id } })
    return data.getProject
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const getProjectEstimates = async (id: string): Promise<ProjectEstimate> => {
  try {
    const { data } = await apolloClient.query({
      fetchPolicy: 'no-cache',
      query: GetProjectEstimatesDocument,
      variables: { id },
    })
    return data.getProjectEstimates
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const getProjectSpaces = async (id: string): Promise<Space[]> => {
  try {
    const { data } = await apolloClient.query({
      fetchPolicy: 'no-cache',
      query: ListProjectSpacesDocument,
      variables: { id },
    })
    return data.listProjectSpaces
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const getProjectServices = async (id: string): Promise<Service[]> => {
  try {
    const { data } = await apolloClient.query({
      fetchPolicy: 'no-cache',
      query: GetProjectServicesDocument,
      variables: { id },
    })
    return data.listProjectServices
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const getProjectsRoles = async (): Promise<ProjectRole[]> => {
  try {
    const { data } = await apolloClient.query({ fetchPolicy: 'no-cache', query: ListProjectsRolesDocument })
    return data.listProjectsRoles
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const createProject = async (newProject: ProjectInput): Promise<Project> => {
  try {
    const { data } = await apolloClient.mutate({
      mutation: AddProjectDocument,
      variables: { input: newProject },
    })
    return data.createProject
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const updateDiscInc = async (input: DiscIncInput): Promise<Project> => {
  try {
    const { data } = await apolloClient.mutate({
      mutation: UpdateDiscIncValuesDocument,
      variables: { input: input },
    })
    return data.spaces
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const updateProject = async (project: Project): Promise<Project> => {
  const input: ProjectInput = {
    budget: project.budget,
    buildingsQty: project.buildingsQty,
    clientId: project.client.id,
    companyName: project.companyName,
    coverImageUrl: project.coverImageUrl,
    discountPercentage: project.discountPercentage,
    headCount: project.headCount,
    increasePercentage: project.increasePercentage,
    industryDetails: {
      experienceLevel: !project.industryDetails?.experienceLevel
        ? null
        : { ...omit(project.industryDetails?.experienceLevel, ['__typename']) },
      id: project.industryDetails?.id,
      otherAdministrativeGoals: !project.industryDetails?.otherAdministrativeGoals
        ? null
        : { ...omit(project.industryDetails?.otherAdministrativeGoals, ['__typename']) },
      otherBusinessGoals: !project.industryDetails?.otherBusinessGoals
        ? null
        : { ...omit(project.industryDetails?.otherBusinessGoals, ['__typename']) },
      otherClinicalGoals: !project.industryDetails?.otherClinicalGoals
        ? null
        : { ...omit(project.industryDetails?.otherClinicalGoals, ['__typename']) },
      otherElements: !project.industryDetails?.otherElements
        ? null
        : { ...omit(project.industryDetails?.otherElements, ['__typename']) },
      otherFacilityTypes: !project.industryDetails?.otherFacilityTypes
        ? null
        : { ...omit(project.industryDetails?.otherFacilityTypes, ['__typename']) },
      otherHomeType: !project.industryDetails?.otherHomeType
        ? null
        : { ...omit(project.industryDetails?.otherHomeType, ['__typename']) },
      otherSpaces: !project.industryDetails?.otherSpaces
        ? null
        : { ...omit(project.industryDetails?.otherSpaces, ['__typename']) },
      otherWorkspaceType: !project.industryDetails?.otherWorkspaceType
        ? null
        : { ...omit(project.industryDetails?.otherWorkspaceType, ['__typename']) },
      projectSize: !project.industryDetails?.projectSize
        ? null
        : { ...omit(project.industryDetails?.projectSize, ['__typename']) },
      visualPreferences: project.industryDetails?.visualPreferences?.map(({ __typename, ...vp }) => vp),
    },
    industryId: project.industry.id,
    moveInDate: project.moveInDate,
    name: project.name,
    squareFootage: project.squareFootage,
    stage: project.stage,
  }
  if (input.moveInDate === '-') {
    delete input.moveInDate
  }

  try {
    const { data } = await apolloClient.mutate({
      mutation: UpdateProjectDocument,
      variables: { id: project.id, input },
    })
    return data.updateProject
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const updateProjectsStage = async (id: string, stage: string): Promise<void> => {
  const input: ProjectStageInput = {
    stage,
  }

  try {
    await apolloClient.mutate({
      mutation: UpdateProjectsStageDocument,
      variables: { id, input },
    })
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const toggleProjectPin = async (project: Project): Promise<void> => {
  try {
    await apolloClient.mutate({
      mutation: SetProjectPinDocument,
      variables: { pinProjectInfo: { isPinned: project.isPinned, projectId: project.id } },
    })
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const deleteProject = async (id: string): Promise<void> => {
  try {
    await apolloClient.mutate({ mutation: DeleteProjectDocument, variables: { id } })
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export interface createClient {
  id: string
  name: string
}

export const addClient = async (client: ClientInput): Promise<createClient> => {
  try {
    const { data } = await apolloClient.mutate({
      mutation: AddClientDocument,
      variables: {
        clientInfo: { ...client },
      },
    })
    return data.createClient
  } catch (err: unknown | ApolloError) {
    console.log('createclientErrorjbs', err)
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const updateClient = async (client: Client): Promise<void> => {
  const industriesIds = client.industries?.map(industry => industry?.id)

  try {
    await apolloClient.mutate({
      mutation: UpdateClientDocument,
      variables: {
        clientInfo: { industriesIds, logoUrl: client.logoUrl, name: client.name },
        id: client.id,
      },
    })
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const deleteClient = async (id: string): Promise<void> => {
  try {
    await apolloClient.mutate({ mutation: DeleteClientDocument, variables: { id } })
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const searchProjectsAndClients = async (name: string): Promise<ProjectAndClients> => {
  try {
    const { data } = await apolloClient.query({
      fetchPolicy: 'no-cache',
      query: SearchClientsAndProjectsDocument,
      variables: { fragment: name },
    })
    return { clients: data.searchProjectsClients.clients, projects: data.searchProjectsClients.projects }
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const updateProjectOtherSpaces = async (input: OtherSpacesInput, id: string) => {
  try {
    await apolloClient.mutate({
      fetchPolicy: 'no-cache',
      mutation: UpdateOtherSpacesDocument,
      variables: {
        id: id,
        input: input,
      },
    })
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}
