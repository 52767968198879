import moment from 'moment'
import React from 'react'
import BellIcon from 'theorem-lib/src/assets/icons/bell.svg'
import { NotificationDetail } from '../../../generated/api'
import { NotificationCard } from '../../atoms/NotificationCard/NotificationCard'

// Abstract out Task vs Tasklist dueDate logic
export const withDueDates = (
  notification1: NotificationDetail,
  notification2: NotificationDetail | null,
  callback: (d1: string, d2: string) => any,
) => {
  let dueDate1 = notification1?.tasklist?.dueDate
  let dueDate2 = notification2?.tasklist?.dueDate
  if (notification1?.tasklist?.section?.task?.dueDate) {
    dueDate1 = notification1?.tasklist?.section?.task?.dueDate
  }
  if (notification2?.tasklist?.section?.task?.dueDate) {
    dueDate2 = notification2?.tasklist?.section?.task?.dueDate
  }
  return callback(dueDate1, dueDate2)
}

const sortAlphabetically = (a: { name: string }, b: { name: string }) => {
  if (a.name < b.name) {
    return -1
  }
  if (a.name > b.name) {
    return 1
  }
  return 0
}

const sortByDateReceived = (notification1: NotificationDetail, notification2: NotificationDetail): number => {
  if (moment.utc(notification1.date).isBefore(moment.utc(notification2.date))) {
    return -1
  } else if (moment.utc(notification1.date).isAfter(moment.utc(notification2.date))) {
    return 1
  } else {
    return 0
  }
}

const dueDateFilterOverdue = (dueDate: string) => {
  return moment.utc(dueDate).isBefore(moment.utc(), 'day')
}
const dueDateFilterDueToday = (dueDate: string) => {
  return moment.utc(dueDate).isSame(moment.utc(), 'day')
}
const dueDateFilterDueThisWeek = (dueDate: string) => {
  return !moment.utc(dueDate).isBefore(moment.utc(), 'day') && !moment.utc(dueDate).isSame(moment.utc(), 'day')
    && moment.utc(dueDate).isSame(moment.utc(), 'week')
}
const dueDateFilterDueLater = (dueDate: string) => {
  return moment.utc(dueDate).isAfter(moment.utc(), 'week')
}
const dueDateFilterNoDueDate = (dueDate: string) => {
  return !dueDate
}

const dateReceivedFilterToday = (notification: NotificationDetail) => {
  return moment.utc(notification.date).isSame(moment.utc(), 'day')
}
const dateReceivedFilterThisWeek = (notification: NotificationDetail) => {
  return !moment.utc(notification.date).isSame(moment.utc(), 'day')
    && moment.utc(notification.date).isSame(moment.utc(), 'week')
}
const dateReceivedFilterPrevious = (notification: NotificationDetail) => {
  return moment.utc(notification.date).isBefore(moment.utc(), 'week')
}

export interface FilterLink {
  label: string
  accessor: string
}

export enum FilterAccessors {
  Project = 'Project',
  DueDate = 'DueDate',
  DateReceived = 'DateReceived',
}

type NotificationListProps = {
  className?: string
  emptyMessage1: string
  emptyMessage2: string
  filter: string
  notifications: Array<NotificationDetail>
}

export const NotificationList = ({
  className,
  emptyMessage1,
  emptyMessage2,
  filter,
  notifications,
}: NotificationListProps) => {
  // [{
  //   id: "", // clientId
  //   ...,
  //   projects: [{
  //     id: "",
  //     ...
  //   }]
  // }]
  const uniqueClientsAndProjects = (notifications: NotificationDetail[]) => {
    // Unique Clients
    const uniqueClientsAndProjects: any[] = []
    notifications.forEach((notification: NotificationDetail) => {
      if (!uniqueClientsAndProjects.filter(tds => tds.id === notification.project?.client?.id)[0]) {
        uniqueClientsAndProjects.push({ ...notification.project.client, projects: [] })
      }
    })

    // Unique Projects
    uniqueClientsAndProjects.forEach(uniqueClient => {
      notifications
        .filter(n => n.project?.client?.id === uniqueClient.id)
        .forEach(n => {
          if (!uniqueClient.projects.filter((p: any) => p.id === n.project.id)[0]) {
            uniqueClient.projects.push({ ...n.project })
          }
        })
    })

    // Sort Clients & Projects Alphabetically
    uniqueClientsAndProjects.sort(sortAlphabetically)
    uniqueClientsAndProjects.forEach(client => {
      client.projects.sort(sortAlphabetically)
    })

    return uniqueClientsAndProjects
  }

  return (
    <div className={`${className}`}>
      {notifications.length <= 0
        && (
          <div className='text-center my-12'>
            <h2 className='text-xl text-secondary-200'>
              <BellIcon className='inline-block mr-2' /> <br />
              {emptyMessage1}
            </h2>
            <h3 className='inline-block text-primary-200'>{emptyMessage2}</h3>
          </div>
        )}
      {notifications.length > 0 && (
        <>
          {filter === FilterAccessors.Project && (
            <>
              {uniqueClientsAndProjects(notifications).map((client) => (
                <div key={client.id}>
                  <div className='p-3 bg-gray-100 rounded-md mt-5'>
                    {client.name || 'Pending Client Assignment'}
                  </div>
                  {client.projects.map((project: any) => (
                    <div key={project.id}>
                      <div className='text-primary-200 ml-3 my-4'>
                        {project.name}
                      </div>
                      {notifications
                        .filter(n => n.project.id === project.id && n.project?.client?.id === client.id)
                        .map(n => <NotificationCard key={n.id} className='mb-3' notification={n} />)}
                    </div>
                  ))}
                </div>
              ))}
            </>
          )}
          {filter === FilterAccessors.DueDate && (
            <>
              <div className='text-primary-200 ml-3 my-4'>
                Overdue
              </div>
              {notifications
                .filter((n: NotificationDetail) => withDueDates(n, null, dueDateFilterOverdue))
                .map(n => <NotificationCard key={n.id} className='mb-3' notification={n} />)}
              <div className='text-primary-200 ml-3 my-4'>
                Due Today
              </div>
              {notifications
                .filter((n: NotificationDetail) => withDueDates(n, null, dueDateFilterDueToday))
                .map(n => <NotificationCard key={n.id} className='mb-3' notification={n} />)}
              <div className='text-primary-200 ml-3 my-4'>
                Due This Week
              </div>
              {notifications
                .filter((n: NotificationDetail) => withDueDates(n, null, dueDateFilterDueThisWeek))
                .map(n => <NotificationCard key={n.id} className='mb-3' notification={n} />)}
              <div className='text-primary-200 ml-3 my-4'>
                Due Later
              </div>
              {notifications
                .filter((n: NotificationDetail) => withDueDates(n, null, dueDateFilterDueLater))
                .map(n => <NotificationCard key={n.id} className='mb-3' notification={n} />)}
              <div className='text-primary-200 ml-3 my-4'>
                No Due Date
              </div>
              {notifications
                .filter((n: NotificationDetail) => withDueDates(n, null, dueDateFilterNoDueDate))
                .map(n => <NotificationCard key={n.id} className='mb-3' notification={n} />)}
            </>
          )}
          {filter === FilterAccessors.DateReceived && (
            <>
              <div className='text-primary-200 ml-3 my-4'>
                Today
              </div>
              {notifications
                .filter(dateReceivedFilterToday)
                .sort(sortByDateReceived)
                .map(n => <NotificationCard key={n.id} className='mb-3' notification={n} />)}
              <div className='text-primary-200 ml-3 my-4'>
                This Week
              </div>
              {notifications
                .filter(dateReceivedFilterThisWeek)
                .sort(sortByDateReceived)
                .map(n => <NotificationCard key={n.id} className='mb-3' notification={n} />)}
              <div className='text-primary-200 ml-3 my-4'>
                Previous
              </div>
              {notifications
                .filter(dateReceivedFilterPrevious)
                .sort(sortByDateReceived)
                .map(n => <NotificationCard key={n.id} className='mb-3' notification={n} />)}
            </>
          )}
        </>
      )}
    </div>
  )
}
