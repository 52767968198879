import { Client } from '../generated/api'

export function validateClient(client: Client): Map<string, string> {
  const results = new Map<string, string>()

  if (!client.name || client.name === '') {
    results.set('clientName', 'Please enter a client name.')
  }

  if (!client.industries || client.industries.length === 0) {
    results.set('industries', 'Please select at least one industry.')
  }

  return results
}
