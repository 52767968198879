import { XIcon } from '@heroicons/react/outline'
import { merge } from 'lodash'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import DevicesIcon from 'theorem-lib/src/assets/icons/devices.svg'
import EducationIcon from 'theorem-lib/src/assets/icons/education.svg'
import GovernmentIcon from 'theorem-lib/src/assets/icons/government.svg'
import HealthIcon from 'theorem-lib/src/assets/icons/health.svg'
import WFHIcon from 'theorem-lib/src/assets/icons/wfh.svg'
import CorporateIcon from 'theorem-lib/src/assets/icons/wizardCase.svg'
import { useActions, useAppState } from '../../../presenter'
import { Wizard } from '../../atoms/Wizard/Wizard'
import { IndustriesCard, IndustriesCardProps } from '../../molecules/IndustriesCard/IndustriesCard'
import { IndustriesEnum, InvalidIndustriesEnum } from '../../molecules/NewProjectCreation/IndustriesSelect'
import { CancelProjectWizardModal } from '../Modals/CancelProjectWizardModal'

export function IndustrySelectorWizard() {
  const { currentModal, industries, wizardProjectCreation } = useAppState()
  const {
    cleanProjectCreation,
    displayModalAction,
    displaySlideOutAction,
    setProjectCreationIndustry,
    setWizardDataToDrawer,
  } = useActions()
  const [sindustries, setIndustries] = useState<IndustriesCardProps[]>()
  const industrySelection = wizardProjectCreation ? wizardProjectCreation.industryId : undefined
  const [showCancelProjectWizardModal, setShowCancelProjectWizardModal] = useState<boolean>(false)

  useEffect(() => {
    const result: IndustriesCardProps[] = industries.map((industry) => {
      switch (industry.name) {
        case IndustriesEnum.Corporate:
          return {
            icon: CorporateIcon,
            iconBackgroundColor: 'bg-[#DAE7F0]',
            iconClassName: 'mt-4',
            id: industry.id,
            label: 'Workspaces for large and small businesses in a typical corporate setting',
            name: industry.name,
            order: 1,
            selectedIndustry: IndustriesEnum.Corporate,
          }
        case IndustriesEnum.Technology:
          return {
            icon: DevicesIcon,
            iconBackgroundColor: 'bg-[#D6EBEC]',
            iconClassName: 'mt-4',
            id: industry.id,
            label: 'Spaces for technology startups and established technology-focused companies',
            name: industry.name,
            order: 2,
            selectedIndustry: IndustriesEnum.Technology,
          }
        case IndustriesEnum.Education:
          return {
            icon: EducationIcon,
            iconBackgroundColor: 'bg-[#FCF8E5]',
            iconClassName: 'mt-4',
            id: industry.id,
            label: 'Learning-focused spaces for students, faculty, and staff',
            name: industry.name,
            order: 5,
            selectedIndustry: IndustriesEnum.Education,
          }
        case IndustriesEnum.Government:
          return {
            icon: GovernmentIcon,
            iconBackgroundColor: 'bg-[#F1E6DD]',
            iconClassName: 'mt-4',
            id: industry.id,
            label: 'Spaces for federal, state & local, and affiliated government agencies',
            name: industry.name,
            order: 3,
            selectedIndustry: IndustriesEnum.Government,
          }
        case IndustriesEnum.Healthcare:
          return {
            icon: HealthIcon,
            iconBackgroundColor: 'bg-[#F7EEED]',
            iconClassName: 'mt-4',
            id: industry.id,
            label: 'Medical spaces for patient caregiving & healing, labs, and administrative offices',
            name: industry.name,
            order: 4,
            selectedIndustry: IndustriesEnum.Healthcare,
          }
        case IndustriesEnum.WorkFromHome:
          return {
            icon: WFHIcon,
            iconBackgroundColor: 'bg-[#F4E5FB]',
            iconClassName: 'mt-4',
            id: industry.id,
            label: 'Create the perfect home office setup for remote and hybrid employees',
            name: industry.name,
            order: 6,
            selectedIndustry: IndustriesEnum.WorkFromHome,
          }
        default:
          return {
            icon: CorporateIcon,
            iconBackgroundColor: 'bg-sky-300',
            iconClassName: 'mt-4',
            id: industry.id,
            label: '',
            name: industry.name,
            selectedIndustry: IndustriesEnum.Corporate,
          }
      }
    })

    const selected = result.find(f => f.id === industrySelection)
    const res = selected ? selected.selected = true : null
    setIndustries(merge(result, res))
  }, [industries])

  const onClickCompleteManually = () => {
    saveIndustrySelected()
    setWizardDataToDrawer()
    displayModalAction('None')
    displaySlideOutAction('ManageProjectSlideOut')
  }

  const onClickNext = () => {
    if (sindustries) {
      const selectedIndustry = sindustries.find(f => f.selected)
      if (selectedIndustry?.id !== wizardProjectCreation.industryId) {
        cleanProjectCreation()
      }
      if (selectedIndustry) {
        setProjectCreationIndustry({
          industryEnum: selectedIndustry.selectedIndustry,
          industryId: selectedIndustry?.id ?? '',
        })

        if (selectedIndustry.selectedIndustry === IndustriesEnum.Healthcare) {
          displayModalAction('FacilityTypesWizard')
        } else if (selectedIndustry.selectedIndustry === IndustriesEnum.WorkFromHome) {
          displayModalAction('HomeTypeWizard')
        } else {
          displayModalAction('BusinessGoalsWizard')
        }
      } else {
        toast('Select a project type to continue', { type: 'error' })
      }
    }
  }

  const onClose = () => {
    cleanProjectCreation()
  }

  const onClickExit = () => {
    setShowCancelProjectWizardModal(true)
  }

  const onConfirmLeaveWizardModal = () => {
    cleanProjectCreation()
    displayModalAction('None')
  }

  const onKeepEditingWizardModal = () => {
    setShowCancelProjectWizardModal(false)
  }

  const onSelectIndustry = (industry: IndustriesCardProps) => {
    const result = sindustries?.map((item) => {
      item.selected = false
      if (item.name === industry.name) {
        item.selected = true
      }
      return item
    })
    setIndustries(result)
  }

  const saveIndustrySelected = () => {
    if (sindustries) {
      const selectedIndustry = sindustries.find(f => f.selected)
      if (selectedIndustry?.id !== wizardProjectCreation.industryId) {
        cleanProjectCreation()
      }
      if (selectedIndustry) {
        setProjectCreationIndustry({
          industryEnum: selectedIndustry.selectedIndustry,
          industryId: selectedIndustry?.id ?? '',
        })
      }
    }
  }

  const Header = () => {
    return (
      <>
        <button
          type='button'
          className='rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none'
          onClick={() => onClickExit()}
        >
          <XIcon className='h-6 w-6' aria-hidden='true' />
        </button>
      </>
    )
  }

  const Footer = () => {
    return (
      <>
        <button
          className='bg-[#F1F1F1] items-center text-[#35697D] px-3 rounded-lg font-semibold mr-2 h-10'
          onClick={onClickCompleteManually}
        >
          Complete Manually
        </button>
        <button
          className='bg-cta-200 items-center text-white px-6 rounded-lg font-semibold mr-2 h-10'
          onClick={onClickNext}
        >
          Next
        </button>
      </>
    )
  }

  return (
    <Wizard
      isOpen={currentModal === 'IndustrySelectorWizard'}
      onClose={onClose}
      header={<Header />}
      footer={<Footer />}
      exitModal={
        <CancelProjectWizardModal
          showCancelModal={showCancelProjectWizardModal}
          onConfirmLeave={onConfirmLeaveWizardModal}
          onKeepEditing={onKeepEditingWizardModal}
        />
      }
    >
      <div className='flex flex-col w-full sm:w-full h-96'>
        <div className='text-primary-100 text-xl font-bold mb-2 text-center'>
          What type of project are you looking to create?
        </div>
        <div className='text-primary-200 font-bold text-center mb-6'>
          Select one
        </div>
        <div className='flex flex-wrap mb-3 place-content-center'>
          {sindustries?.filter((industry) =>
            industry.name != InvalidIndustriesEnum.Amenity
            && industry.name != InvalidIndustriesEnum.Hospitality
            && industry.name != InvalidIndustriesEnum.MultifamilyHousing
            && industry.name != InvalidIndustriesEnum.WallsShadesFlooring
          )
            .sort((a, b) => ((a.order ?? 1) - (b.order ?? 2))).map((industry, index) => (
              <div key={index} className='px-2 py-2'>
                <IndustriesCard
                  key={index}
                  {...industry}
                  onClick={() => {
                    onSelectIndustry(industry)
                  }}
                />
              </div>
            ))}
        </div>
      </div>
    </Wizard>
  )
}
