import { Member, ProjectRole, User } from '../generated/api'
import { Project } from '../generated/api'
import { ProjectSecurityRoles } from '../types/gql_enums'

export const checkProjectLeadOrEditor = (selectedMember: Member, authenticatedUser: User) => {
  if (selectedMember.id === authenticatedUser.id) {
    if (
      selectedMember.role.name === ProjectSecurityRoles.Lead
      || selectedMember.role.name === ProjectSecurityRoles.Editor
    ) {
      return true
    }
  }
}

export const getUserProjectRole = (
  selectedProject: Project,
  authenticatedUser: User,
): ProjectRole | undefined => {
  return selectedProject.members
    .find((member) => member.id === authenticatedUser.id)
    ?.role
}

export const isProjectLead = (
  selectedProject: Project,
  authenticatedUser: User,
): boolean => {
  return selectedProject.members
    .find((member) => member.id === authenticatedUser.id)
    ?.role.name === ProjectSecurityRoles.Lead
}

export const isProjectCollaborator = (
  selectedProject: Project,
  authenticatedUser: User,
): boolean => {
  return selectedProject.members
    .find((member) => member.id === authenticatedUser.id)
    ?.role.name === ProjectSecurityRoles.Collaborator
}

export const isProjectEditor = (
  selectedProject: Project,
  authenticatedUser: User,
): boolean => {
  return selectedProject.members
    .find((member) => member.id === authenticatedUser.id)
    ?.role.name === ProjectSecurityRoles.Editor
}

export const getNameWithoutUnderScores = (str: string) => {
  let i
  const frags = str.split('_')
  for (i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].toLowerCase().slice(1).trim()
  }
  return frags.join(' ')
}
