import { toast } from 'react-toastify'
import { setActiveScreen } from 'theorem-lib/src/helpers/setActiveScreen'
import { User } from '../../generated/api'
import { Context } from '..'
import { defaultErrorHandler } from './actions'

export async function displayUserEditAction(context: Context) {
  context.state.currentPage = 'Loading'
  try {
    context.state.userEdit = await context.effects.getAuthenticatedUser()
    context.state.currentPage = 'UserEdit'
    setActiveScreen(context, 'Profile')
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function submitUserEditAction(context: Context, payload: User) {
  try {
    await context.effects.updateAuthenticatedUser(payload)
    context.state.authenticatedUser = await context.effects.getAuthenticatedUser()
    context.state.userEdit = { ...context.state.authenticatedUser }
    toast('Changes have been saved successfully.')
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function uploadAvatarImageAction(context: Context, value: { avatarFile: File; user: User }) {
  try {
    const avatarUrl = await context.effects.uploadAvatarImage(value.avatarFile)
    const newUser = { ...value.user }
    newUser.avatarUrl = avatarUrl
    context.actions.submitUserEditAction(newUser)
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function userRemoveAvatarAction(context: Context) {
  const u = { ...context.state.authenticatedUser }
  u.avatarUrl = ''
  try {
    await context.effects.updateAuthenticatedUser(u)
    context.state.authenticatedUser = await context.effects.getAuthenticatedUser()
    context.state.userEdit = { ...context.state.authenticatedUser }
    toast('Changes have been saved successfully.')
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

// New action to retrieve a single user by ID
export async function getUsersByIds(context: Context, ids: string[]) {
  try {
    const targetUser = await context.effects.getUsersByUserIds(ids)
    return targetUser
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}
