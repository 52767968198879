import { DotsVerticalIcon } from '@heroicons/react/solid'
import { truncate } from 'lodash'
import React, { useState } from 'react'
import { Project } from '../../../generated/api'
import { useActions } from '../../../presenter'
import Card from '../../atoms/Card/Card'
import { ProjectDropdown } from '../ProjectDropdown/ProjectDropdown'

type ProjectPinnedCardProps = {
  client: string
  project: Project
}

export const ProjectPinnedCard = (props: ProjectPinnedCardProps): React.ReactElement => {
  const { redirectAction, setSelectedProjectAction } = useActions()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const TITLE_CHAR_LIMIT = 30

  return (
    <Card
      className='w-[312px] flex flex-col mr-4 cursor-pointer'
      onClick={() => props.project && redirectAction(`/project/${props.project?.id}/dashboard`)}
      onMouseEnter={() => setSelectedProjectAction(props.project)}
    >
      <div
        className={`w-[312px] h-[120px] bg-primary-300 rounded-t-lg bg-cover bg-no-repeat bg-center flex items-end`}
        style={{ backgroundImage: `url(${props.project.coverImageUrl})` }}
      />
      <div className='h-20 px-3 pb-3 pt-2 bg-black rounded-b-lg'>
        <div className='text-white font-light text-xs pb-1 leading-4'>{props.client}</div>
        <div className='flex flex-row justify-between'>
          <div className='text-white font-semibold text-base leading-6'>
            {truncate(props.project.name, { length: TITLE_CHAR_LIMIT })}
          </div>
          <div className='h-6 w-6 ml-1'>
            <ProjectDropdown
              isOpen={isDropdownOpen}
              onClose={() => setIsDropdownOpen(false)}
              project={props.project}
              isInternal
              openButton={
                <DotsVerticalIcon
                  onClick={(e) => {
                    e.stopPropagation()
                    setIsDropdownOpen(!isDropdownOpen)
                  }}
                  className='text-primary-300 w-5'
                />
              }
            />
          </div>
        </div>
      </div>
    </Card>
  )
}

export default ProjectPinnedCard
