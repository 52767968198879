import React from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Forbidden } from 'theorem-lib/src/components/errors/Forbidden'
import { NotFound } from 'theorem-lib/src/components/errors/NotFound'
import { Unexpected } from 'theorem-lib/src/components/errors/Unexpected'
import { Sidebar } from 'theorem-lib/src/components/molecules/Sidebar/Sidebar'
import { useAppState } from '../presenter'
import { CancelClientEditModal } from './organisms/Modals/CancelClientEditModal'
import { CancelClientNewModal } from './organisms/Modals/CancelClientNewModal'
import { CancelProjectEditModal } from './organisms/Modals/CancelProjectEditModal'
import { CancelTasklistEditModal } from './organisms/Modals/CancelTasklistEditModal'
import { DeleteClientModal } from './organisms/Modals/DeleteClientModal'
import { DeleteSpaceModal } from './organisms/Modals/DeleteSpaceModal'
import { DeleteTasklistModal } from './organisms/Modals/DeleteTasklistModal'
import { DeleteTaskModal } from './organisms/Modals/DeleteTaskModal'
import { GenericCancelModal } from './organisms/Modals/GenericCancelModal'
import { LicensesLimitModal } from './organisms/Modals/LicensesLimitModal'
import { ProjectCreationModal } from './organisms/Modals/ProjectCreationModal'
import { ProjectDeleteModal } from './organisms/Modals/ProjectDeleteModal'
import { ProjectMemberDeleteModal } from './organisms/Modals/ProjectMemberDeleteModal'
import { RegistrationWelcomeToFolioModal } from './organisms/Modals/RegistrationWelcomeToFolioModal'
import { WelcomeToFolioModal } from './organisms/Modals/WelcomeToFolioModal'
import { AddCustomTasklistSlideOut } from './organisms/SlideOuts/AddCustomTasklistSlideOut'
import { AddSpaceSlideOut } from './organisms/SlideOuts/AddSpaceSlideOut'
import { AddUserSlideOut } from './organisms/SlideOuts/AddUserSlideOut'
import { ClientEditSlideOut } from './organisms/SlideOuts/ClientEditSlideOut'
import { ClientNewSlideOut } from './organisms/SlideOuts/ClientNewSlideOut'
import { CollaboratorInvitationToFolioSlideOut } from './organisms/SlideOuts/CollaboratorFolioInvitationSlideOut'
import { CollaboratorInvitationSlideOut } from './organisms/SlideOuts/CollaboratorInvitationSlideOut'
import { EditProjectSpaceSlideOut } from './organisms/SlideOuts/EditProjectSpaceSlideOut'
import { EditVisualPreferencesSlideOut } from './organisms/SlideOuts/EditVisualPreferencesSlideOut'
import { ManageProjectSlideOut } from './organisms/SlideOuts/ManageProjectSlideOut'
import { ManageServicesSlideOut } from './organisms/SlideOuts/ManageServicesSlideOut'
import { ManageSpaceDiscountSlideOut } from './organisms/SlideOuts/ManageSpaceDiscountSlideOut'
import { ManageSpaceSlideOut } from './organisms/SlideOuts/ManageSpaceSlideOut'
import { ManageTasklistSlideOut } from './organisms/SlideOuts/ManageTasklistSlideOut'
import { ManageTaskSlideOut } from './organisms/SlideOuts/ManageTaskSlideOut'
import { MemberInvitationSlideOut } from './organisms/SlideOuts/MemberInvitationSlideOut'
import { VisualPreferencesReadOnlySlideOut } from './organisms/SlideOuts/VisualPreferencesReadOnlySlideOut'
import { VisualPreferencesSlideOut } from './organisms/SlideOuts/VisualPreferencesSlideOut'
import { AdministrativeGoalsWizard } from './organisms/Wizard/AdministrativeGoalsWizard'
import { BusinessGoalsWizard } from './organisms/Wizard/BusinessGoalsWizard'
import { ClientNewWizard } from './organisms/Wizard/ClientNewWizard'
import { ClinicalGoalsWizard } from './organisms/Wizard/ClinicalGoalsWizard'
import { FacilityTypesWizard } from './organisms/Wizard/FacilityTypesWizard'
import { GeneralProjectDetailsWizard } from './organisms/Wizard/GeneralProjectDetailsWizard'
import { HomeOfficeElementsWizard } from './organisms/Wizard/HomeOfficeElementsWizard'
import { HomeTypeWizard } from './organisms/Wizard/HomeTypeWizard'
import { HomeWorkSpaceWizard } from './organisms/Wizard/HomeWorkSpaceWizard'
import { IndustrySelectorWizard } from './organisms/Wizard/IndustrySelectorWizard'
import { ProjectSizeWizard } from './organisms/Wizard/ProjectSizeWizard'
import { SpacesWizard } from './organisms/Wizard/SpacesWizard'
import { VisualPreferencesWizard } from './organisms/Wizard/VisualPreferencesWizard'
import { ClientHome } from './templates/Client/ClientHome'
import { Dashboard } from './templates/Dashboard'
import { Loading } from './templates/Loading'
import { ProjectDashboard } from './templates/Project/Dashboard'
import { ProjectImagery } from './templates/Project/Imagery'
import { ProjectDetail } from './templates/Project/ProjectDetail'
import { ProjectTeam } from './templates/Project/ProjectTeam'
import { ProjectSpaces } from './templates/Project/Spaces'
import { ProjectWorkbook } from './templates/Project/Workbook'
import { Projects } from './templates/Projects'
import { UserEdit } from './templates/Users/UserEdit'
import { UserManagement } from './templates/Users/UserManagement'

const pages: { [key: string]: () => JSX.Element } = {
  ClientHome,
  Dashboard,
  Forbidden,
  Loading,
  NotFound,
  ProjectDashboard,
  ProjectDetail,
  ProjectImagery,
  ProjectSpaces,
  ProjectTeam,
  ProjectWorkbook,
  Projects,
  Unexpected,
  UserEdit,
  UserManagement,
}

const modals: { [key: string]: () => JSX.Element } = {
  AdministrativeGoalsWizard,
  BusinessGoalsWizard,
  CancelClientEditModal,
  CancelClientNewModal,
  CancelProjectEditModal,
  CancelTasklistEditModal,
  ClientNewWizard,
  ClinicalGoalsWizard,
  DeleteClientModal,
  DeleteSpaceModal,
  DeleteTaskModal,
  DeleteTasklistModal,
  FacilityTypesWizard,
  GeneralProjectDetailsWizard,
  GenericCancelModal,
  HomeOfficeElementsWizard,
  HomeTypeWizard,
  HomeWorkSpaceWizard,
  IndustrySelectorWizard,
  LicensesLimitModal,
  None: () => <></>,
  ProjectCreationModal,
  ProjectDeleteModal,
  ProjectMemberDeleteModal,
  ProjectSizeWizard,
  RegistrationWelcomeToFolioModal,
  SpacesWizard,
  VisualPreferencesWizard,
  WelcomeToFolioModal,
}

const slideOuts: { [key: string]: () => JSX.Element } = {
  AddCustomTasklistSlideOut,
  AddSpaceSlideOut,
  AddUserSlideOut,
  ClientEditSlideOut,
  ClientNewSlideOut,
  CollaboratorInvitationSlideOut,
  CollaboratorInvitationToFolioSlideOut,
  EditProjectSpaceSlideOut,
  EditVisualPreferencesSlideOut,
  ManageProjectSlideOut,
  ManageServicesSlideOut,
  ManageSpaceDiscountSlideOut,
  ManageSpaceSlideOut,
  ManageTaskSlideOut,
  ManageTasklistSlideOut,
  MemberInvitationSlideOut,
  None: () => <></>,
  VisualPreferencesReadOnlySlideOut,
  VisualPreferencesSlideOut,
}

export function CurrentPage() {
  const { authenticatedUser, currentModal, currentPage, currentSlideOut, navOptions, tenant } = useAppState()
  const CurrentPage = pages[currentPage]
  const CurrentSlideOut = slideOuts[currentSlideOut]
  const CurrentModal = modals[currentModal]

  return (
    <>
      <Sidebar
        navOptions={navOptions}
        name={{ first: authenticatedUser.firstName || '', last: authenticatedUser.lastName || '' }}
        avatar={authenticatedUser.avatarUrl || ''}
        tenantInfo={tenant}
      >
        <CurrentPage />
        <CurrentModal />
        <CurrentSlideOut />
        <ToastContainer position='top-center' hideProgressBar={true} />
      </Sidebar>
    </>
  )
}
