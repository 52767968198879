type FormatType = 'MM.DD.YYYY' | 'YYYY-MM-DD'

// converts date string to mm.dd.yyyy
export const formatDate = (input: string, format: FormatType = 'MM.DD.YYYY'): string => {
  if (!input || input === '') {
    return '-'
  }
  const date = new Date(input)
  // invalid date string input
  if (isNaN(date.getTime())) {
    return '-'
  }

  const day = date.getUTCDate().toString().padStart(2, '0')
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0')
  const year = date.getUTCFullYear()

  switch (format) {
    case 'MM.DD.YYYY':
      return `${month}.${day}.${year}`
    case 'YYYY-MM-DD':
      return `${year}-${month}-${day}`
    default:
      return `${month}.${day}.${year}`
  }
}
