import React, { useEffect, useState } from 'react'
import { PalletesImagesPool } from '../../../../assets/Industries/imagesPool'
import { VisualPreferencesInput } from '../../../generated/api'
import { useActions, useAppState } from '../../../presenter'
import { LikeDislike } from '../../atoms/LikeDislike/LikeDislike'
import { PulseAnimation } from '../../atoms/Loading/Loading'
import { SlideOut } from '../../atoms/SlideOut/SlideOut'
import { SlideOutFooter } from '../../atoms/SlideOutFooter/SlideoutFooter'

export const VisualPreferencesReadOnlySlideOut = () => {
  const {
    displaySlideOutAction,
  } = useActions()
  const { industries, selectedProject } = useAppState()
  const [visualPreferences, setVisualPreferences] = useState<VisualPreferencesInput[]>()
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    if (selectedProject.industryDetails?.visualPreferences) {
      setVisualPreferences(selectedProject.industryDetails?.visualPreferences)
    } else {
      setVisualPreferences(PalletesImagesPool)
    }
  }, [selectedProject.industry.id, industries])

  setTimeout(() => {
    setLoading(false)
  }, 600)

  const Thumbnail = (cover: VisualPreferencesInput) => (
    <div
      key={cover.id}
      className={`min-w-[128px] h-24 bg-primary-200 mr-2 rounded-md bg-cover bg-no-repeat bg-center cursor-pointer flex flex-col`}
      style={{ backgroundImage: `url('${cover.url}')` }}
    >
      <LikeDislike
        liked={cover.like}
        disliked={cover.dislike}
      />
    </div>
  )

  return (
    <SlideOut
      title='Visual Preferences'
      onClose={() => displaySlideOutAction('None')}
    >
      {
        <>
          <p className='-mt-5 mb-6 text-primary-200 font-normal'>
            What are some styles you find appealing?
          </p>
          <div className='flex flex-wrap'>
            {(loading)
              ? <PulseAnimation />
              : visualPreferences && (
                <>
                  {visualPreferences.map((image, index) => (
                    <div key={index} className='w-1/2 mb-10'>
                      <Thumbnail key={index} {...image} dislike={image.dislike} />
                    </div>
                  ))}
                </>
              )}
          </div>
          <SlideOutFooter justify='end'>
            <button
              className='bg-cta-200 items-center text-white px-3 rounded-lg font-semibold mr-2 h-10'
              onClick={() => {
                displaySlideOutAction('None')
              }}
            >
              Close
            </button>
          </SlideOutFooter>
        </>
      }
    </SlideOut>
  )
}
