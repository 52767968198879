import { yupResolver } from '@hookform/resolvers/yup'
import React, { useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import PlusIcon from 'theorem-lib/src/assets/icons/plus.svg'
import TrashIcon from 'theorem-lib/src/assets/icons/trash.svg'
import { InviteUserFormValidator } from 'theorem-lib/src/validators/inviteUserFormValidator'
import { SecurityRole } from '../../../../../../app/client/src/generated/api'
import { useActions, useAppState } from '../../../../../../app/client/src/presenter'
import { getUsersWithoutDefaultLicenses } from '../../../helpers/userManagementHelper'
import Select, { Option } from '../../atoms/Select/Select'
import { SlideOut } from '../../atoms/SlideOut/SlideOut'
import { SlideOutFooter } from '../../atoms/SlideOutFooter/SlideoutFooter'
import TextInput from '../../atoms/TextInput/TextInput'
import { AppStateType } from '../../templates/Users/UserManagement'

export type InviteUser = {
  email: string
  firstName: string
  securityRoles: Array<string>
}

export function AddUserSlideOut() {
  const { filteredUsers } = useAppState<AppStateType>()
  const { tenantInfo } = useAppState()
  const {
    displayModalAction,
    displaySlideOutAction,
    submitInviteNewUserAction,
  } = useActions()

  const { users } = useAppState()

  const [isDirty, setIsDirty] = useState<boolean>(false)

  const { control, formState: { errors }, handleSubmit, register, setValue } = useForm({
    defaultValues: {
      values: [{
        email: '',
        firstName: '',
        role: '',
      }],
    },
    resolver: yupResolver(InviteUserFormValidator),
  })

  const { append, fields, remove } = useFieldArray(
    {
      control,
      name: 'values',
    },
  )

  const selectData = (): Option[] => {
    const data: Option[] = []
    const adminOption = {
      key: '1',
      value: SecurityRole.Admin,
    }
    const memberOption = {
      key: '2',
      value: SecurityRole.Member,
    }

    data.push(adminOption)
    data.push(memberOption)

    return data
  }

  const onSubmit = async (data: SubmitFormType) => {
    displaySlideOutAction('None')

    const newInviteList = data.values.map(user => ({
      email: user.email,
      firstName: user.firstName,
      securityRoles: [user.role],
    }))

    newInviteList.forEach(user => {
      const duplicateEmail = users.find(u => u.email === user.email)

      if (duplicateEmail) {
        toast('User already exists with the email ' + user.email)
      } else {
        submitInviteNewUserAction(user)
      }
    })
  }

  const onErrors = () => {
    toast.error('Error sending invitation')
  }

  type InviteUserFormType = {
    email: string
    firstName: string
    role: string
  }

  type SubmitFormType = {
    values: InviteUserFormType[]
  }

  const activeUsersLicenses = getUsersWithoutDefaultLicenses(filteredUsers).length
  const licensesLimitReached = (activeUsersLicenses + fields.length) >= tenantInfo.licenses

  return (
    <SlideOut
      title='Add Users'
      onClose={() => isDirty ? displayModalAction('CancelProjectEditModal') : displaySlideOutAction('None')}
    >
      <h2 className='mb-6 text-primary-200 font-normal'>
        Enter the email of the user you would like to invite and assign an access level.
      </h2>
      <form onSubmit={handleSubmit(onSubmit, onErrors)}>
        <ul>
          {fields.map((field, index) => {
            return (
              <li key={field.id} className='block mb-3 border-left '>
                <div className='inline-block w-25 mt-3 mb-4 pr-2 align-top'>
                  <TextInput
                    label='First Name'
                    type='text'
                    {...register(`values.${index}.firstName`, { minLength: 1, required: true })}
                  />
                  <span className='flex flex-grow text-error font-medium text-xs'>
                    {errors.values?.[index]?.firstName !== undefined && 'Please enter a valid First Name'}
                  </span>
                </div>
                <div className='inline-block w-25 mt-3 mb-4 pr-2  align-top'>
                  <TextInput
                    label='email'
                    hasAutoFocus
                    {...register(`values.${index}.email`, {
                      pattern: {
                        message: 'Please enter a valid email address',
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      },
                      required: 'This field is required.',
                    })}
                  />
                  <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                    {errors.values?.[index]?.email?.message}
                  </span>
                </div>
                <div className='mt-6 inline-block align-text-baseline'>
                  <button
                    className=' inline-block ml-8 cursor-pointer fill-[#2b5b74] button rounded-lg px-2 py-2 bg-blue-100 '
                    type='button'
                    onClick={() => remove(index)}
                  >
                    <TrashIcon />
                  </button>
                </div>

                <div className='block align-top'>
                  <Select
                    data={selectData()}
                    label='Folio Access'
                    onChange={(value) => {
                      setIsDirty(true)
                      setValue(`values.${index}.role`, (value as Option)?.value)
                    }}
                  />
                  <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                    {errors.values?.[index]?.role?.message}
                  </span>
                </div>
              </li>
            )
          })}
        </ul>
        <div>
          <button
            onClick={() => {
              licensesLimitReached
                ? displayModalAction('LicensesLimitModal')
                : append({
                  email: '',
                  firstName: '',
                  role: '',
                })
            }}
            type='button'
            className='mt-3 inline-flex items-center font-bold text-pink-700'
          >
            <PlusIcon />
            Add another user
          </button>
        </div>

        <SlideOutFooter>
          <button
            className={`bg-cta-200 hover:bg-cta-300 text-white p-3 rounded-lg font-semibold mr-2`}
            type='submit'
          >
            Send Invitation
          </button>
        </SlideOutFooter>
      </form>
    </SlideOut>
  )
}
