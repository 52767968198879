import React from 'react'
import { ManageSpaceSlideOut as LibSlideOut } from 'theorem-lib/src/components/organisms/ManageSpaces/ManageSpaceSlideOut'
import { LibIndustry, LibItemTemplate, LibSpaceTemplate } from 'theorem-lib/src/entities/types/Spaces'
import { UpdateSpaceInput } from '../../../generated/api'
import { useActions, useAppState } from '../../../presenter'

export function ManageSpaceSlideOut() {
  const { displayModalAction, displaySlideOutAction, submitUpdateProjectSpaceAction } = useActions()
  const { allItemTemplates, selectedProject, selectedSpace } = useAppState()

  const totalItems = allItemTemplates
  const onSubmit = (spaceToSubmit: LibSpaceTemplate & { newItems: LibItemTemplate[] }) => {
    displaySlideOutAction('None')

    let items = [
      ...spaceToSubmit.newItems?.map(item => ({
        discountPercentage: item.discountPercentage,
        highPrice: parseInt(item.highPrice.toString()),
        increasePercentage: item.increasePercentage,
        itemTemplatesId: item.itemTemplatesId,
        lowPrice: parseInt(item.lowPrice.toString()),
        midPrice: parseInt(item.midPrice.toString()),
        name: item.name,
        quantity: Math.floor(item.quantity),
      })),
    ].filter(item => {
      // remove placeholder items by default value (user clicked add but didnt select an item)
      if (item.highPrice === 0 && item.midPrice === 0 && item.lowPrice === 0 && item.name == '') return false
      return true
    })

    items = [
      ...items,
      ...spaceToSubmit.items.map(item => ({
        discountPercentage: item.discountPercentage,
        highPrice: parseInt(item.highPrice.toString()),
        id: item.id,
        increasePercentage: item.increasePercentage,
        itemTemplatesId: item.itemTemplatesId,
        lowPrice: parseInt(item.lowPrice.toString()),
        midPrice: parseInt(item.midPrice.toString()),
        name: item.name,
        quantity: Math.floor(item.quantity),
      })),
    ]

    const data: UpdateSpaceInput = {
      description: spaceToSubmit.description,
      image: spaceToSubmit.image!,
      items,
      name: spaceToSubmit.name,
      quantity: Math.floor(spaceToSubmit.quantity),
    }

    submitUpdateProjectSpaceAction({ projectId: selectedProject.id, space: data, spaceId: selectedSpace.id })
  }

  const onClose = () => {
    displaySlideOutAction('None')
  }

  const onDiscard = () => {
    displayModalAction('GenericCancelModal')
  }

  let translatedIndustry: LibIndustry | undefined = undefined
  if (selectedSpace.industry !== null) {
    translatedIndustry = {
      covers: selectedSpace.industry?.covers?.map((cover) => {
        return {
          id: cover.id,
          url: cover.url,
        }
      }),
      id: selectedSpace.industry?.id,
      name: selectedSpace.industry?.name,
    }
  }

  const translatedItems: LibItemTemplate[] = selectedSpace.items?.map((item) => {
    return {
      category: {
        createdOn: item.category.createdOn,
        id: item.category.id,
        modifiedOn: item.category.modifiedOn,
        name: item.category.name,
      },
      createdOn: item.createdOn,
      highPrice: item.highPrice,
      id: item.id,
      itemTemplatesId: item.itemTemplatesId ?? undefined,
      lowPrice: item.lowPrice,
      midPrice: item.midPrice,
      modifiedOn: item.modifiedOn,
      name: item.name,
      quantity: item.quantity,
    }
  }) ?? []

  const translatedTotalItems: LibItemTemplate[] = totalItems.map((item) => {
    return {
      category: {
        createdOn: item.category?.createdOn,
        id: item.category?.id || '',
        modifiedOn: item.category?.modifiedOn,
        name: item.category?.name || '',
      },
      createdOn: item.createdOn,
      highPrice: item.highPrice,
      id: item.id,
      image: item.image ? item.image : undefined,
      lowPrice: item.lowPrice,
      midPrice: item.midPrice,
      modifiedOn: item.modifiedOn,
      name: item.name,
      quantity: item.quantity,
    }
  })

  const translatedSpace: LibSpaceTemplate = {
    createdOn: selectedSpace.createdOn,
    description: selectedSpace.description ?? '',
    id: selectedSpace.id,
    image: selectedSpace.image ?? undefined,
    industry: translatedIndustry,
    items: translatedItems,
    modifiedOn: selectedSpace.modifiedOn,
    name: selectedSpace.name,
    quantity: selectedSpace.quantity,
    spaceTemplateId: selectedSpace.templateId ?? undefined,
  }

  return (
    <LibSlideOut
      discountPercentage={selectedProject.discountPercentage!}
      increasePercentage={selectedProject.increasePercentage!}
      space={translatedSpace}
      totalItems={translatedTotalItems}
      onSubmit={onSubmit}
      onClose={onClose}
      onDiscard={onDiscard}
      isTemplate={false}
      industries={undefined}
      isCreate={false}
      isTheorem={false}
    />
  )
}
