import { VisualPreferencesInput } from '../../src/generated/api'
import c1 from './Corporate/Corporate-Style-1.png'
import c10 from './Corporate/Corporate-Style-10.png'
import c11 from './Corporate/Corporate-Style-11.png'
import c12 from './Corporate/Corporate-Style-12.png'
import c13 from './Corporate/Corporate-Style-13.png'
import c14 from './Corporate/Corporate-Style-14.png'
import c15 from './Corporate/Corporate-Style-15.png'
import c2 from './Corporate/Corporate-Style-2.png'
import c3 from './Corporate/Corporate-Style-3.png'
import c4 from './Corporate/Corporate-Style-4.png'
import c5 from './Corporate/Corporate-Style-5.png'
import c6 from './Corporate/Corporate-Style-6.png'
import c7 from './Corporate/Corporate-Style-7.png'
import c8 from './Corporate/Corporate-Style-8.png'
import c9 from './Corporate/Corporate-Style-9.png'

export const CorporateImagesPool: VisualPreferencesInput[] = [
  { url: c1, id: '1', dislike: false, like: false },
  { url: c2, id: '2', dislike: false, like: false },
  { url: c3, id: '3', dislike: false, like: false },
  { url: c4, id: '4', dislike: false, like: false },
  { url: c5, id: '5', dislike: false, like: false },
  { url: c6, id: '6', dislike: false, like: false },
  { url: c7, id: '7', dislike: false, like: false },
  { url: c8, id: '8', dislike: false, like: false },
  { url: c9, id: '9', dislike: false, like: false },
  { url: c10, id: '10', dislike: false, like: false },
  { url: c11, id: '11', dislike: false, like: false },
  { url: c12, id: '12', dislike: false, like: false },
  { url: c13, id: '13', dislike: false, like: false },
  { url: c14, id: '14', dislike: false, like: false },
  { url: c15, id: '15', dislike: false, like: false },
]

import e1 from './Education/Education_Style1.png'
import e10 from './Education/Education_Style10.png'
import e11 from './Education/Education_Style11.png'
import e12 from './Education/Education_Style12.png'
import e13 from './Education/Education_Style13.png'
import e14 from './Education/Education_Style14.png'
import e15 from './Education/Education_Style15.png'
import e2 from './Education/Education_Style2.png'
import e3 from './Education/Education_Style3.png'
import e4 from './Education/Education_Style4.png'
import e5 from './Education/Education_Style5.png'
import e6 from './Education/Education_Style6.png'
import e7 from './Education/Education_Style7.png'
import e8 from './Education/Education_Style8.png'
import e9 from './Education/Education_Style9.png'

export const EducationImagesPool = [
  { url: e1, id: '1', dislike: false, like: false },
  { url: e2, id: '2', dislike: false, like: false },
  { url: e3, id: '3', dislike: false, like: false },
  { url: e4, id: '4', dislike: false, like: false },
  { url: e5, id: '5', dislike: false, like: false },
  { url: e6, id: '6', dislike: false, like: false },
  { url: e7, id: '7', dislike: false, like: false },
  { url: e8, id: '8', dislike: false, like: false },
  { url: e9, id: '9', dislike: false, like: false },
  { url: e10, id: '10', dislike: false, like: false },
  { url: e11, id: '11', dislike: false, like: false },
  { url: e12, id: '12', dislike: false, like: false },
  { url: e13, id: '13', dislike: false, like: false },
  { url: e14, id: '14', dislike: false, like: false },
  { url: e15, id: '15', dislike: false, like: false },
]

import g1 from './Government/Government_Style1.png'
import g10 from './Government/Government_Style10.png'
import g11 from './Government/Government_Style11.png'
import g12 from './Government/Government_Style12.png'
import g13 from './Government/Government_Style13.png'
import g14 from './Government/Government_Style14.png'
import g15 from './Government/Government_Style15.png'
import g2 from './Government/Government_Style2.png'
import g3 from './Government/Government_Style3.png'
import g4 from './Government/Government_Style4.png'
import g5 from './Government/Government_Style5.png'
import g6 from './Government/Government_Style6.png'
import g7 from './Government/Government_Style7.png'
import g8 from './Government/Government_Style8.png'
import g9 from './Government/Government_Style9.png'

export const GovermentImagesPool = [
  { url: g1, id: '1', dislike: false, like: false },
  { url: g2, id: '2', dislike: false, like: false },
  { url: g3, id: '3', dislike: false, like: false },
  { url: g4, id: '4', dislike: false, like: false },
  { url: g5, id: '5', dislike: false, like: false },
  { url: g6, id: '6', dislike: false, like: false },
  { url: g7, id: '7', dislike: false, like: false },
  { url: g8, id: '8', dislike: false, like: false },
  { url: g9, id: '9', dislike: false, like: false },
  { url: g10, id: '10', dislike: false, like: false },
  { url: g11, id: '11', dislike: false, like: false },
  { url: g12, id: '12', dislike: false, like: false },
  { url: g13, id: '13', dislike: false, like: false },
  { url: g14, id: '14', dislike: false, like: false },
  { url: g15, id: '15', dislike: false, like: false },
]

import h1 from './Healthcare/Healthcare_Style1.png'
import h10 from './Healthcare/Healthcare_Style10.png'
import h11 from './Healthcare/Healthcare_Style11.png'
import h12 from './Healthcare/Healthcare_Style12.png'
import h13 from './Healthcare/Healthcare_Style13.png'
import h14 from './Healthcare/Healthcare_Style14.png'
import h15 from './Healthcare/Healthcare_Style15.png'
import h2 from './Healthcare/Healthcare_Style2.png'
import h3 from './Healthcare/Healthcare_Style3.png'
import h4 from './Healthcare/Healthcare_Style4.png'
import h5 from './Healthcare/Healthcare_Style5.png'
import h6 from './Healthcare/Healthcare_Style6.png'
import h7 from './Healthcare/Healthcare_Style7.png'
import h8 from './Healthcare/Healthcare_Style8.png'
import h9 from './Healthcare/Healthcare_Style9.png'

export const HealthcareImagesPool = [
  { url: h1, id: '1', dislike: false, like: false },
  { url: h2, id: '2', dislike: false, like: false },
  { url: h3, id: '3', dislike: false, like: false },
  { url: h4, id: '4', dislike: false, like: false },
  { url: h5, id: '5', dislike: false, like: false },
  { url: h6, id: '6', dislike: false, like: false },
  { url: h7, id: '7', dislike: false, like: false },
  { url: h8, id: '8', dislike: false, like: false },
  { url: h9, id: '9', dislike: false, like: false },
  { url: h10, id: '10', dislike: false, like: false },
  { url: h11, id: '11', dislike: false, like: false },
  { url: h12, id: '12', dislike: false, like: false },
  { url: h13, id: '13', dislike: false, like: false },
  { url: h14, id: '14', dislike: false, like: false },
  { url: h15, id: '15', dislike: false, like: false },
]

import t1 from './Technology/Technology_Style1.png'
import t10 from './Technology/Technology_Style10.png'
import t11 from './Technology/Technology_Style11.png'
import t12 from './Technology/Technology_Style12.png'
import t13 from './Technology/Technology_Style13.png'
import t14 from './Technology/Technology_Style14.png'
import t15 from './Technology/Technology_Style15.png'
import t2 from './Technology/Technology_Style2.png'
import t3 from './Technology/Technology_Style3.png'
import t4 from './Technology/Technology_Style4.png'
import t5 from './Technology/Technology_Style5.png'
import t6 from './Technology/Technology_Style6.png'
import t7 from './Technology/Technology_Style7.png'
import t8 from './Technology/Technology_Style8.png'
import t9 from './Technology/Technology_Style9.png'

export const TechnologyImagesPool = [
  { url: t1, id: '1', dislike: false, like: false },
  { url: t2, id: '2', dislike: false, like: false },
  { url: t3, id: '3', dislike: false, like: false },
  { url: t4, id: '4', dislike: false, like: false },
  { url: t5, id: '5', dislike: false, like: false },
  { url: t6, id: '6', dislike: false, like: false },
  { url: t7, id: '7', dislike: false, like: false },
  { url: t8, id: '8', dislike: false, like: false },
  { url: t9, id: '9', dislike: false, like: false },
  { url: t10, id: '10', dislike: false, like: false },
  { url: t11, id: '11', dislike: false, like: false },
  { url: t12, id: '12', dislike: false, like: false },
  { url: t13, id: '13', dislike: false, like: false },
  { url: t14, id: '14', dislike: false, like: false },
  { url: t15, id: '15', dislike: false, like: false },
]

import w1 from './WorkFromHome/WorkFromHome_Style1.png'
import w10 from './WorkFromHome/WorkFromHome_Style10.png'
import w11 from './WorkFromHome/WorkFromHome_Style11.png'
import w12 from './WorkFromHome/WorkFromHome_Style12.png'
import w13 from './WorkFromHome/WorkFromHome_Style13.png'
import w14 from './WorkFromHome/WorkFromHome_Style14.png'
import w15 from './WorkFromHome/WorkFromHome_Style15.png'
import w2 from './WorkFromHome/WorkFromHome_Style2.png'
import w3 from './WorkFromHome/WorkFromHome_Style3.png'
import w4 from './WorkFromHome/WorkFromHome_Style4.png'
import w5 from './WorkFromHome/WorkFromHome_Style5.png'
import w6 from './WorkFromHome/WorkFromHome_Style6.png'
import w7 from './WorkFromHome/WorkFromHome_Style7.png'
import w8 from './WorkFromHome/WorkFromHome_Style8.png'
import w9 from './WorkFromHome/WorkFromHome_Style9.png'

export const WorkFromHomeImagesPool = [
  { url: w1, id: '1', dislike: false, like: false },
  { url: w2, id: '2', dislike: false, like: false },
  { url: w3, id: '3', dislike: false, like: false },
  { url: w4, id: '4', dislike: false, like: false },
  { url: w5, id: '5', dislike: false, like: false },
  { url: w6, id: '6', dislike: false, like: false },
  { url: w7, id: '7', dislike: false, like: false },
  { url: w8, id: '8', dislike: false, like: false },
  { url: w9, id: '9', dislike: false, like: false },
  { url: w10, id: '10', dislike: false, like: false },
  { url: w11, id: '11', dislike: false, like: false },
  { url: w12, id: '12', dislike: false, like: false },
  { url: w13, id: '13', dislike: false, like: false },
  { url: w14, id: '14', dislike: false, like: false },
  { url: w15, id: '15', dislike: false, like: false },
]

import p1 from './../Palettes/Palette_Style1.png'
import p10 from './../Palettes/Palette_Style10.png'
import p11 from './../Palettes/Palette_Style11.png'
import p12 from './../Palettes/Palette_Style12.png'
import p13 from './../Palettes/Palette_Style13.png'
import p14 from './../Palettes/Palette_Style14.png'
import p15 from './../Palettes/Palette_Style15.png'
import p16 from './../Palettes/Palette_Style16.png'
import p17 from './../Palettes/Palette_Style17.png'
import p18 from './../Palettes/Palette_Style18.png'
import p19 from './../Palettes/Palette_Style19.png'
import p2 from './../Palettes/Palette_Style2.png'
import p20 from './../Palettes/Palette_Style20.png'
import p21 from './../Palettes/Palette_Style21.png'
import p22 from './../Palettes/Palette_Style22.png'
import p23 from './../Palettes/Palette_Style23.png'
import p24 from './../Palettes/Palette_Style24.png'
import p25 from './../Palettes/Palette_Style25.png'
import p26 from './../Palettes/Palette_Style26.png'
import p4 from './../Palettes/Palette_Style4.png'
import p5 from './../Palettes/Palette_Style5.png'
import p6 from './../Palettes/Palette_Style6.png'
import p8 from './../Palettes/Palette_Style8.png'
import p9 from './../Palettes/Palette_Style9.png'

export const PalletesImagesPool: VisualPreferencesInput[] = [
  { url: p8, id: '1', dislike: false, like: false },
  { url: p22, id: '2', dislike: false, like: false },
  { url: p26, id: '3', dislike: false, like: false },
  { url: p23, id: '4', dislike: false, like: false },
  { url: p14, id: '5', dislike: false, like: false },
  { url: p21, id: '6', dislike: false, like: false },
  { url: p5, id: '7', dislike: false, like: false },
  { url: p12, id: '8', dislike: false, like: false },
  { url: p17, id: '9', dislike: false, like: false },
  { url: p2, id: '10', dislike: false, like: false },
  { url: p9, id: '11', dislike: false, like: false },
  { url: p25, id: '12', dislike: false, like: false },
  { url: p4, id: '13', dislike: false, like: false },
  { url: p18, id: '14', dislike: false, like: false },
  { url: p20, id: '15', dislike: false, like: false },
  { url: p1, id: '16', dislike: false, like: false },
  { url: p24, id: '17', dislike: false, like: false },
  { url: p16, id: '18', dislike: false, like: false },
  { url: p10, id: '19', dislike: false, like: false },
  { url: p13, id: '20', dislike: false, like: false },
  { url: p11, id: '21', dislike: false, like: false },
  { url: p19, id: '22', dislike: false, like: false },
  { url: p6, id: '23', dislike: false, like: false },
  { url: p15, id: '24', dislike: false, like: false },
]
