import { SecurityRole, Status, User } from '../generated/api'

export const getUsersWithoutDefaultLicenses = (users: User[]) => {
  // filtering theoremAdmin,systemAdmin,inactive users and participants
  return users.filter(user => {
    return !user.isTheoremAdmin
      && user.email !== 'system@example.org'
      && user.status !== Status.Inactive
      && !user.securityRoles.includes(SecurityRole.Participant)
  })
}
