import { derived } from 'overmind'
import { SelectedProjectRole } from 'theorem-lib/src/entities/enums/SelectedProjectRole'
import { SidebarIcons } from 'theorem-lib/src/entities/enums/SidebarIcons'
import { LibNavOption } from 'theorem-lib/src/entities/types/NavOption'
import { getDerivedProjects, getMembers, sortPinnedCards } from 'theorem-lib/src/helpers/projectHelper'
import { searchUsers } from 'theorem-lib/src/helpers/userHelper'
import { FilterItem } from '../components/molecules/ListFilter/ListFilter'
import { IndustriesEnum } from '../components/molecules/NewProjectCreation/IndustriesSelect'
import config from '../config'
import { orderServices } from '../helpers/projectServicesHelper'

import {
  Client,
  ClientInput,
  Industry,
  ItemTemplate,
  Member,
  NotificationDetail,
  Project,
  ProjectEstimate,
  ProjectInput,
  SecurityRole,
  Service,
  ServiceTemplate,
  Space,
  SpaceComment,
  SpacesItemTemplate,
  SpaceTemplate,
  Status,
  Task,
  TaskComment,
  Tasklist,
  TenantsDetails,
  User,
} from '../generated/api'
import { IProjectClient } from '../interfaces/IProjectClient'
import { IProjectRole } from '../interfaces/IProjectRole'
import { IprojectStateMachine } from '../types/project'
import { RouteNames } from './router'

export type ProjectsAndClientsFilters = {
  industries: FilterItem[]
  members: FilterItem[]
  moveInDate: { from: string; to: string }
}

export type CoreState = {
  authenticatedUser: User
  currentPage:
    | 'ClientHome'
    | 'Dashboard'
    | 'Forbidden'
    | 'Loading'
    | 'NotFound'
    | 'Project'
    | 'ProjectDashboard'
    | 'ProjectDetail'
    | 'ProjectMembers'
    | 'ProjectSpaces'
    | 'ProjectTeam'
    | 'ProjectImagery'
    | 'ProjectWorkbook'
    | 'Projects'
    | 'Unexpected'
    | 'UserEdit'
    | 'UserManagement'
  currentModal:
    | 'CancelClientEditModal'
    | 'ClinicalGoalsWizard'
    | 'ClientNewWizard'
    | 'CancelClientNewModal'
    | 'CancelProjectEditModal'
    | 'CancelTasklistEditModal'
    | 'BusinessGoalsWizard'
    | 'AdministrativeGoalsWizard'
    | 'DeleteClientModal'
    | 'DeleteSpaceModal'
    | 'DeleteTaskModal'
    | 'IndustrySelectorWizard'
    | 'HomeTypeWizard'
    | 'HomeWorkSpaceWizard'
    | 'HomeOfficeElementsWizard'
    | 'FacilityTypesWizard'
    | 'LicensesLimitModal'
    | 'SpacesWizard'
    | 'ProjectSizeWizard'
    | 'ProjectCreationModal'
    | 'VisualPreferencesWizard'
    | 'GeneralProjectDetailsWizard'
    | 'DeleteTasklistModal'
    | 'None'
    | 'ProjectDeleteModal'
    | 'ProjectMemberDeleteModal'
    | 'GenericCancelModal'
    | 'RegistrationWelcomeToFolioModal'
    | 'WelcomeToFolioModal'
  currentSlideOut: string
  isAdmin: boolean
  isMember: boolean
  isParticipant: boolean
  navOptions: LibNavOption[]
  validationErrors: Map<string, string>
}

export const adminNavOptions = (helpDeskLink: string, mrlLink: string, indealLink: string, slackLink: string) => [
  { current: false, href: '/', icon: SidebarIcons.Home, name: RouteNames.Dashboard },
  { current: false, href: '/projects', icon: SidebarIcons.Case, name: RouteNames.Projects },
  { current: false, href: '/users/management', icon: SidebarIcons.Cog, name: RouteNames.Admin },
  { current: false, href: slackLink, icon: SidebarIcons.Slack, name: RouteNames.Slack },
  { current: false, href: mrlLink, icon: SidebarIcons.MRLIndeal, name: RouteNames.MRL },
  { current: false, href: indealLink, icon: SidebarIcons.MRLIndeal, name: RouteNames.Indeal },
  { current: false, href: helpDeskLink, icon: SidebarIcons.Help, name: RouteNames.Help },
  { current: false, href: '/users/edit', icon: SidebarIcons.Cog, name: RouteNames.Profile },
]
export const memberNavOptions = (helpDeskLink: string, mrlLink: string, indealLink: string, slackLink: string) => {
  return adminNavOptions(helpDeskLink, mrlLink, indealLink, slackLink)
}
export const participantNavOptions = (helpDeskLink: string, mrlLink: string, indealLink: string, slackLink: string) => {
  return adminNavOptions(helpDeskLink, mrlLink, indealLink, slackLink).filter(
    navOption =>
      navOption.name != RouteNames.Admin && navOption.name != RouteNames.Indeal && navOption.name != RouteNames.MRL,
  )
}

function getNavOptions(securityRoles: Array<SecurityRole>): LibNavOption[] {
  if (securityRoles.length > 0) {
    switch (securityRoles[0]) {
      case SecurityRole.Admin:
        return adminNavOptions(
          `${config.dealerHelpDeskUrl}`,
          `${config.mrlLink}`,
          `${config.indealLink}`,
          `${config.navBarSlackLink}`,
        )
      case SecurityRole.Member:
        return memberNavOptions(
          `${config.dealerHelpDeskUrl}`,
          `${config.mrlLink}`,
          `${config.indealLink}`,
          `${config.navBarSlackLink}`,
        )
      case SecurityRole.Participant:
        return participantNavOptions(
          `${config.participantHelpDeskUrl}`,
          `${config.mrlLink}`,
          `${config.indealLink}`,
          `${config.navBarSlackLink}`,
        )
      default:
        return []
    }
  }
  return []
}

export const coreState: CoreState = {
  authenticatedUser: {
    email: '',
    firstName: '',
    id: '',
    lastName: '',
    securityRoles: [],
    status: Status.Pending,
  },
  currentModal: 'None',
  currentPage: 'Loading',
  currentSlideOut: 'None',
  isAdmin: derived((coreState: CoreState) =>
    coreState.authenticatedUser.securityRoles?.includes(SecurityRole.Admin) || false
  ),
  isMember: derived((coreState: CoreState) =>
    coreState.authenticatedUser.securityRoles?.includes(SecurityRole.Member) || false
  ),
  isParticipant: derived((coreState: CoreState) =>
    coreState.authenticatedUser.securityRoles?.includes(SecurityRole.Participant) || false
  ),
  navOptions: derived((coreState: CoreState) => getNavOptions(coreState.authenticatedUser.securityRoles)),
  validationErrors: new Map<string, string>(),
}

export type UserState = {
  filteredUsers: User[]
  userEdit: User
  userSearchString: string
  tenantInfo: TenantsDetails
  users: User[]
}

export const userState: UserState = {
  filteredUsers: derived((userState: UserState) => searchUsers(userState.userSearchString, userState.users)),
  tenantInfo: {} as TenantsDetails,
  userEdit: {
    email: '',
    id: '',
    securityRoles: [],
    status: Status.Pending,
  },
  userSearchString: '',
  users: [],
}

export type ClientState = {
  allItemTemplates: ItemTemplate[]
  allClients: Client[]
  clientEdit: Client
  clientNew: ClientInput
  clients: Client[]
  deleteTasklistId: string
  projectDetailsEditMode: boolean
  filteredClients: Client[]
  filteredProjects: Project[]
  filteredProjectServices: Service[]
  filters: ProjectsAndClientsFilters
  industries: Industry[]
  invitationNotificationTime: Date
  members: Member[]
  membersToFilter: Member[]
  newProjectCreation: IprojectStateMachine | null
  wizardProjectCreation: ProjectInput
  wizardProjectSelectedIndustry: IndustriesEnum | undefined
  pinnedProjects: IProjectClient[]
  projectDeleteId: string
  projectMemberDeleteId: string
  projectRoles: IProjectRole[]
  projects: IProjectClient[]
  selectedMemberId: string
  selectedProject: Project
  selectedTasklist?: Tasklist
  selectedProjectTaskListIndex?: number
  selectedProjectEstimates: ProjectEstimate
  selectedProjectServices: Service[]
  selectedProjectSpaces: Space[]
  selectedProjectTaskLists: Tasklist[]
  selectedProjectPredefinedTasklists: Tasklist[]
  selectedSpace: Space
  selectedProjectId: string
  selectedProjectRole: SelectedProjectRole
  selectedRoleId: string
  spacesItemTemplates: SpacesItemTemplate[]
  taskEdit: {
    task: Task | undefined
    comments: TaskComment[]
  }
  userInvitations: { id: string; name: string }[]
  projectCount: number
  servicesTemplates: ServiceTemplate[]
  spaces: SpaceTemplate[]
  spaceDiscountAmt: number
  spaceIncreaseAmt: number
  spaceDeleteId: string
}

export const clientState: ClientState = {
  allClients: [],
  allItemTemplates: [],
  clientEdit: {
    createdBy: {
      firstName: '',
      id: '',
      lastName: '',
    },
    createdOn: Date.toString(),
    id: '',
    industries: [],
    logoUrl: '',
    modifiedOn: '',
    name: '',
    projects: [],
  },
  clientNew: {
    clientId: '',
    industriesIds: [],
    logoUrl: '',
    name: '',
  },
  clients: [],
  deleteTasklistId: '',
  filteredClients: [],
  filteredProjectServices: derived((clientState: ClientState) => orderServices(clientState.selectedProjectServices)),
  filteredProjects: [],
  filters: {
    industries: [],
    members: [],
    moveInDate: { from: '', to: '' },
  },
  industries: [],
  invitationNotificationTime: new Date(),
  members: [],
  membersToFilter: derived((clientState: ClientState) => getMembers(clientState.projects)),
  newProjectCreation: null,
  pinnedProjects: derived((clientState: ClientState) =>
    clientState.projects.filter(project => project.isPinned).sort(sortPinnedCards)
  ),
  projectCount: 0,
  projectDeleteId: '',
  projectDetailsEditMode: false, // derived((clientState: ClientState) => clientState.projectDetailsEditMode),
  projectMemberDeleteId: '',
  projectRoles: [],
  projects: derived((clientState: ClientState) => getDerivedProjects(clientState.clients)),
  selectedMemberId: '',
  selectedProject: {
    budget: 0,
    buildingsQty: 0,
    client: {
      createdBy: { id: '' },
      createdOn: '',
      id: '',
      industries: [],
      logoUrl: '',
      modifiedOn: '',
      name: '',
    },
    coverImageUrl: '',
    createdOn: '',
    headCount: 0,
    id: '',
    industry: {
      covers: [],
      id: '',
      name: '',
    },
    isPinned: false,
    members: [],
    modifiedOn: '',
    moveInDate: new Date(),
    name: '',
    squareFootage: 0,
    stage: '',
  },
  selectedProjectEstimates: {
    antiPricePercentHigh: 0,
    antiPricePercentLow: 0,
    antiPricePercentMid: 0,
    freightPercentHigh: 0,
    freightPercentLow: 0,
    freightPercentMid: 0,
    highPrice: 0,
    highServiceEstimate: 0,
    installPercentHigh: 0,
    installPercentLow: 0,
    installPercentMid: 0,
    lowPrice: 0,
    lowServiceEstimate: 0,
    midPrice: 0,
    midServiceEstimate: 0,
    productTaxPercentHigh: 0,
    productTaxPercentLow: 0,
    productTaxPercentMid: 0,
    serviceTaxPercentHigh: 0,
    serviceTaxPercentLow: 0,
    serviceTaxPercentMid: 0,
    totalTaxPercentHigh: 0,
    totalTaxPercentLow: 0,
    totalTaxPercentMid: 0,
  },
  selectedProjectId: '',
  selectedProjectPredefinedTasklists: [],
  selectedProjectRole: SelectedProjectRole.Admin,
  selectedProjectServices: [],
  selectedProjectSpaces: [],
  selectedProjectTaskLists: [],
  selectedRoleId: '',
  selectedSpace: {
    createdOn: '',
    description: '',
    id: '',
    items: [],
    name: '',
    quantity: 0,
    templateId: '',
  },
  selectedTasklist: undefined,
  servicesTemplates: [],
  spaceDeleteId: '',
  spaceDiscountAmt: 1,
  spaceIncreaseAmt: 1,
  spaces: [],
  spacesItemTemplates: [],
  taskEdit: {
    comments: [],
    task: undefined,
  },
  userInvitations: [],
  wizardProjectCreation: {
    budget: 0,
    buildingsQty: 0,
    clientId: '',
    companyName: '',
    coverImageUrl: '',
    headCount: 0,
    industryDetails: {
      experienceLevel: {
        other: '',
        values: [],
      },
    },
    industryId: '',
    members: [],
    name: '',
    squareFootage: 0,
  } as ProjectInput,
  wizardProjectSelectedIndustry: undefined,
}

export const notificationState: { notifications: Array<NotificationDetail> } = {
  notifications: [],
}

export type Tenant = {
  defaultLogoUrl: string
  iFrameUrl: string
  logoUrl: string
  name?: string
}

export type TenantState = {
  tenant: Tenant
}

export const tenantState: TenantState = {
  tenant: {
    defaultLogoUrl: '',
    iFrameUrl: '',
    logoUrl: '',
    name: '',
  },
}
