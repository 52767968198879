import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import Select from 'react-select'
import { toast } from 'react-toastify'
import PlusIcon from 'theorem-lib/src/assets/icons/plus.svg'
import TrashIcon from 'theorem-lib/src/assets/icons/trash.svg'
import { OtherSpacesEnum, OtherSpacesInput, QuantValueSchemaInput } from '../../../generated/api'
import { getSpacesEnumByIndustry } from '../../../helpers/industryHelper'
import { getNameWithoutUnderScores } from '../../../helpers/projectHelper'
import { useActions, useAppState } from '../../../presenter'
import { EditOtherSpacesFormValidator } from '../../../validators/editOtherSpacesFormValidator'
import { customStyles } from '../../atoms/ReactSelectStyling/selectStyle'
import { SlideOut } from '../../atoms/SlideOut/SlideOut'
import { SlideOutFooter } from '../../atoms/SlideOutFooter/SlideoutFooter'
import TextInput from '../../atoms/TextInput/TextInput'
import { OtherOptions } from './ProjectSlideOutTemplate'

type EditSpacesFormType = {
  industryTypesOfSpacesQuantValues: QuantValueSchemaInput[] | []
}

export function EditProjectSpaceSlideOut() {
  const {
    displayModalAction,
    displaySlideOutAction,
    updateProjectOtherSpacesAction,
  } = useActions()
  const { selectedProject } = useAppState()
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const [otherInput, setOtherInput] = useState<boolean>(false)
  const [stateIndex, setStateIndex] = useState<number | null>(null)

  let otherIndex: number | null = null

  useEffect(() => {
    selectedProject.industryDetails?.otherSpaces?.quantValues.forEach((el, index) => {
      if (el.values === 'Other') {
        otherIndex = index
        setOtherInput(true)
        setStateIndex(index)
      } else {
        otherIndex = null
        setStateIndex(null)
      }
    })
  }, [])

  const industry = selectedProject.industry.name

  const initialFieldArrayValues: EditSpacesFormType = {
    industryTypesOfSpacesQuantValues: selectedProject.industryDetails?.otherSpaces?.quantValues ?? [],
  }

  const { control, formState: { errors }, getValues, handleSubmit, register, setValue } = useForm({
    defaultValues: initialFieldArrayValues,
    resolver: yupResolver(EditOtherSpacesFormValidator),
  })

  const valuesFields = useFieldArray({ control, name: 'industryTypesOfSpacesQuantValues' })

  const fields = valuesFields.fields
  const append = valuesFields.append
  const remove = valuesFields.remove

  const selectOptions = getSpacesEnumByIndustry(industry).map(space => ({
    label: getNameWithoutUnderScores(space),
    value: space as OtherSpacesEnum,
  }))

  const selectedSpaces = getValues().industryTypesOfSpacesQuantValues.map((space) => {
    return space.values
  })

  let filteredSelectOptions = selectOptions.filter((element) => (!selectedSpaces?.includes(element.value)))

  const onRemove = (index: number) => {
    getValues('industryTypesOfSpacesQuantValues').forEach((el, index) => {
      if (el.values != 'Other') {
        setStateIndex(null)
      }
    })
    if (index === stateIndex) {
      setOtherInput(false)
      setStateIndex(null)
    }
    remove(index)
    setIsDirty(true)
  }
  const updateSelections = () => {
    filteredSelectOptions = filteredSelectOptions.filter((element) => (!selectedSpaces?.includes(element.value)))
  }
  const checkDuplicates = () => {
    // @ts-expect-error type value not recognized but exists
    errors.industryTypesOfSpacesQuantValues?.type === 'checkDuplicates' && toast('Please select unique values')
  }

  const checkOther = (checkIndex: number) => {
    const value = getValues(`industryTypesOfSpacesQuantValues.${checkIndex}.values`)

    if (value === 'Other' && stateIndex != checkIndex) {
      setStateIndex(checkIndex)
      setOtherInput(true)
    } else if (value != 'Other' && stateIndex === checkIndex) {
      setStateIndex(null)
      setOtherInput(false)
    }
  }

  const onSubmit = async (data: EditSpacesFormType) => {
    const updateSpacesData: OtherSpacesInput = {
      other: '',
      quantValues: data.industryTypesOfSpacesQuantValues.map((qv: QuantValueSchemaInput) => {
        return ({
          amount: qv.amount,
          other: qv.values === 'Other' ? qv.other : '',
          values: qv.values,
        })
      }),
      quantities: [],
      values: [],
    }
    await updateProjectOtherSpacesAction(updateSpacesData)
  }

  return (
    <SlideOut
      title='Edit Spaces'
      onClose={() => isDirty ? displayModalAction('CancelProjectEditModal') : displaySlideOutAction('None')}
    >
      <h2 className='mb-6 text-primary-200 font-normal'>
        Update the spaces in your existing project.
      </h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ul>
          {fields.map((field, index) => {
            return (
              <>
                <li key={index} className='flex flex-row mb-3'>
                  <div className='w-3/5 border-b border-primary-400'>
                    <Select
                      {...register(`industryTypesOfSpacesQuantValues.${index}.values`)}
                      placeholder={getValues(`industryTypesOfSpacesQuantValues.${index}.values`)
                          === OtherOptions.otherEmpty as unknown as OtherSpacesEnum
                        ? 'Select a Space'
                        : getNameWithoutUnderScores(
                          getValues(`industryTypesOfSpacesQuantValues.${index}.values`).toString(),
                        )}
                      options={filteredSelectOptions.map(space => ({
                        label: getNameWithoutUnderScores(space.label),
                        value: space.value as OtherSpacesEnum,
                      }))}
                      menuPlacement='top'
                      styles={customStyles}
                      onChange={(value) => {
                        setIsDirty(true)
                        setValue(`industryTypesOfSpacesQuantValues.${index}.values`, value?.value as OtherSpacesEnum)
                        updateSelections()
                        checkOther(index)
                      }}
                    />
                  </div>
                  <div className='mt-6 ml-2 w-1/5'>
                    <TextInput
                      label='Qty'
                      type='number'
                      min='0'
                      {...register(`industryTypesOfSpacesQuantValues.${index}.amount`, { valueAsNumber: true })}
                    />
                  </div>

                  <div className='mt-6 inline-block align-text-baseline'>
                    <button
                      className=' ml-8 cursor-pointer fill-[#2b5b74] button rounded-lg px-2 py-2 bg-blue-100 '
                      type='button'
                      onClick={() => onRemove(index)}
                    >
                      <TrashIcon />
                    </button>
                  </div>
                </li>
                <div className='flex block'>
                  <span className='flex flex-grow justify-start text-error font-medium text-xs block'>
                    {errors.industryTypesOfSpacesQuantValues?.[index]?.values?.message}
                  </span>
                </div>
                <div className='flex mb-3 block'>
                  <span className='flex flex-grow justify-start text-error font-medium text-xs'>
                    {errors.industryTypesOfSpacesQuantValues?.[index]?.amount?.message}
                  </span>
                </div>
              </>
            )
          })}
        </ul>
        <div>
          <button
            onClick={() => {
              append({
                amount: 0,
                other: '',
                values: OtherOptions.otherEmpty as unknown as OtherSpacesEnum,
              })
            }}
            type='button'
            className='mt-3 mb-3 inline-flex items-center font-bold text-pink-700'
          >
            <PlusIcon />
            Add another space
          </button>
        </div>
        {otherInput
          && (
            <>
              <h2 className='mb-2 text-primary-200 font-normal'>
                What other types of spaces?
              </h2>
              <div className='w-100 px-2 w-1/2'>
                <TextInput
                  placeholder='Other Value'
                  maxLength={52}
                  readOnly={false}
                  {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore: other value exists on type industryTypesOfSpacesQuantValues.other
                    ...register(`industryTypesOfSpacesQuantValues.${stateIndex}.other`)
                  }
                />
              </div>
            </>
          )}

        <SlideOutFooter>
          <button type='submit' onClick={checkDuplicates} className='button cta'>Save</button>
        </SlideOutFooter>
      </form>
    </SlideOut>
  )
}
