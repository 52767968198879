import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid'
import React, { useState } from 'react'

type FinanceCardProps = {
  className?: string
  icon?: React.ElementType
  name: string
  value: number
}

export const FinanceCard = (props: FinanceCardProps) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const demoApplyPath = 'https://navitex.navitascredit.com/resource_centers/Folio_RC_QQ'
  const demoLMPath = 'https://foliosi.com/financing-with-folio/'

  const onExpand = () => {
    setIsExpanded(!isExpanded)
  }

  const handleLMClick = () => {
    window.open(demoLMPath, '_blank', 'noreferrer')
  }

  const handleApplyClick = () => {
    window.open(demoApplyPath, '_blank', 'noreferrer')
  }

  return (
    <div
      className={`rounded-lg bg-no-repeat bg-center bg-cover flex flex-row flex-1 ${
        isExpanded ? `h-44` : `h-20`
      } w-44 bg-[#1435452B] p-4 shadow-none  ${props.className}`}
    >
      <div
        className={`${
          isExpanded ? `w-[50px] h-8` : `w-8 h-8`
        } bg-white rounded-full flex flex-row justify-center items-center`}
      >
        {isExpanded
          ? <ChevronUpIcon className='w-6 h-6 cursor-pointer' onClick={onExpand} />
          : <ChevronDownIcon className='w-6 h-6 cursor-pointer' onClick={onExpand} />}
      </div>
      <div className='flex flex-col'>
        <span className='ml-4 text-xl font-semibold text-primary-100'>
          <sup>$</sup>
          {Math.ceil(props.value).toLocaleString()}
          <sup>00</sup>
        </span>
        <span className='ml-4 text-xs font-normal text-primary-100'>Monthly Payment</span>
        {isExpanded && (
          <>
            <div className='ml-4 text-sm font-normal text-primary-100'>
              Estimated financing monthly payment for 60 months. Pricing is subject to approval.
            </div>
            <div className='mt-2 ml-4 flex flex-row'>
              <button className='button cta w-28 mr-3' onClick={handleLMClick}>Learn More</button>
              <button className='button cta w-20' onClick={handleApplyClick}>Apply</button>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
