import { PlusIcon } from '@heroicons/react/solid'
import React from 'react'
import RefreshIcon from 'theorem-lib/src/assets/icons/resend.svg'
import AvatarCircle from 'theorem-lib/src/components/atoms/AvatarCircle/AvatarCircle'
import AvatarInitial from 'theorem-lib/src/components/atoms/AvatarInitial/AvatarInitial'
import { BusinessRolesEnum } from 'theorem-lib/src/entities/enums/BusinessRoles'
import { getBusinessRolesBySecurityRole } from 'theorem-lib/src/helpers/businessRolesHelper'
import { SecurityRole, Status, User } from '../../../generated/api'
import { getUsersWithoutDefaultLicenses } from '../../../helpers/userManagementHelper'
import { useActions, useAppState } from '../../../presenter'
import { FolioSecurityRoles } from '../../../types/gql_enums'
import { Tip } from '../../atoms/Tip/Tip'
import { Toggle } from '../../atoms/Toggle/Toggle'

export type AppStateType = {
  filteredUsers: User[]
}

export function UserManagement() {
  const { filteredUsers } = useAppState<AppStateType>()
  const { authenticatedUser, tenantInfo, users } = useAppState()
  const {
    displayModalAction,
    displaySlideOutAction,
    reinviteUserAction,
    searchUser,
    updateUserBusinessRoleAction,
    updateUserSecurityRoleAction,
    updateUserStatusAction,
  } = useActions()

  const PurchaseMoreBodyText =
    'Hello, I am writing to request additional FOLIO licenses. Number of additional licenses requested: XX'
  const activeUsersLicenses = getUsersWithoutDefaultLicenses(users).length
  const licensesLimitReached = activeUsersLicenses >= tenantInfo.licenses

  const getAddUserButton = () => {
    if (authenticatedUser.securityRoles[0] === SecurityRole.Admin) {
      return (
        <button
          type='button'
          className='button cta invite absolute right-8'
          onClick={() =>
            licensesLimitReached ? displayModalAction('LicensesLimitModal') : displaySlideOutAction('AddUserSlideOut')}
        >
          <PlusIcon className='h-5 w-5' />
        </button>
      )
    }
  }

  const getBusinessRoleColumn = (user: User) => {
    return (
      authenticatedUser.securityRoles[0] === SecurityRole.Admin
        ? (
          <select
            className='bg-transparent border-0 w-48'
            value={user.businessRole || ''}
            onChange={(event) => {
              updateUserBusinessRoleAction({
                businessRole: event.target.value,
                user,
              })
            }}
          >
            <option value=''></option>
            {getBusinessRolesBySecurityRole(user.securityRoles[0])
              .map(businessRole => (
                <option
                  key={Object.keys(
                    BusinessRolesEnum,
                  )[Object.values(BusinessRolesEnum).indexOf(businessRole as unknown as BusinessRolesEnum)]}
                  value={businessRole}
                >
                  {businessRole}
                </option>
              ))}
          </select>
        )
        : <span className='inline-block'>{user.businessRole}</span>
    )
  }

  const getFolioAccessColumn = (user: User) => {
    if (authenticatedUser.securityRoles[0] === SecurityRole.Admin && user.status === Status.Active) {
      return (
        user.securityRoles[0].toString() === FolioSecurityRoles.Participant
          ? <span className='inline-block'>{user.securityRoles[0]}</span>
          : (
            <select
              className='bg-transparent border-0 w-48'
              value={user.securityRoles[0] || ''}
              onChange={(event) => updateUserSecurityRoleAction({ securityRole: event.target.value, user })}
            >
              {Object.keys(FolioSecurityRoles)
                .filter(role => !role.includes(FolioSecurityRoles.Participant))
                .map(key => (
                  <option key={key} value={FolioSecurityRoles[key as keyof typeof FolioSecurityRoles]}>
                    {FolioSecurityRoles[key as keyof typeof FolioSecurityRoles]}
                  </option>
                ))}
            </select>
          )
      )
    } else return <span className='inline-block'>{user.securityRoles[0]}</span>
  }

  const getStatusColumn = (user: User) => {
    if (authenticatedUser.securityRoles[0] === SecurityRole.Admin) {
      if (user.status != Status.Pending) {
        return (
          <Toggle
            enabled={user.status == Status.Active}
            setEnabled={() => {
              if (user.status == Status.Active) updateUserStatusAction({ status: Status.Inactive, user })
              else {
                if (
                  licensesLimitReached && user.email != 'system@example.org'
                  && !user.securityRoles.includes(SecurityRole.Participant)
                ) {
                  displayModalAction('LicensesLimitModal')
                } else updateUserStatusAction({ status: Status.Active, user })
              }
            }}
          />
        )
      }

      if (user.status === Status.Pending) {
        return (
          <>
            <button
              type='button'
              className='bg-transparent appearance-none text-[#41687b]'
              onClick={() => {
                reinviteUserAction({ user })
              }}
            >
              <RefreshIcon className='h-5 w-5' />
            </button>
            <span className='ml-4'>Pending</span>
          </>
        )
      }
    } else {
      return <span className='inline-block'>{user.status}</span>
    }
  }

  const MailTo = (email: string, subject: string, body: string) => {
    window.open(`mailto:${email}?subject=${subject} - Request for More Licenses&body=${body}`)
  }

  const getLicensesBanner = () => {
    if (authenticatedUser.securityRoles[0] === SecurityRole.Admin) {
      return (
        <Tip
          text={`You have ${activeUsersLicenses} registered users out of ${tenantInfo.licenses}.`}
          icon='info'
          backgroundColor={activeUsersLicenses === tenantInfo.licenses
            ? 'bg-yellow-100'
            : activeUsersLicenses > tenantInfo.licenses
            ? 'bg-red-100'
            : undefined}
          link
          linkText='Purchase More'
          onLinkClick={() => MailTo('customercare@foliosi.com', tenantInfo.tenantName ?? '', PurchaseMoreBodyText)}
        />
      )
    }
  }

  return (
    <>
      <div className='flex justify-between mb-5'>
        <h2 className='text-xl font-medium'>User Management</h2>
        {getAddUserButton()}
      </div>
      <div className='flex justify-between mb-4'>
        <div className='w-1/3'>
          {getLicensesBanner()}
        </div>
        <div>
          <label htmlFor='text'>Search users</label>
          <input id='text' type='text' className='md:max-w-xs' onChange={e => searchUser(e.target.value)} />
        </div>
      </div>

      <table className='w-full'>
        <thead>
          <tr>
            <th className='p-4'>User</th>
            <th className='p-4'>Email</th>
            <th className='p-4'>Role</th>
            <th className='p-4'>Folio Access</th>
            <th className='p-4'>Status</th>
          </tr>
        </thead>
        <tbody>
          {filteredUsers.map(user => (
            <tr key={user.id} className=' even:bg-gray-100'>
              <td className='p-4'>
                <div className='inline-block mr-3'>
                  {user.avatarUrl
                    ? <AvatarCircle url={user.avatarUrl} sizeRem={2} />
                    : (
                      <AvatarInitial
                        firstName={user.firstName || ''}
                        lastName={user.lastName || ''}
                        sizeRem={2}
                      />
                    )}
                </div>
                <span className='inline-block'>{user.firstName} {user.lastName}</span>
              </td>
              <td className='p-4'>
                <span className='inline-block'>{user.email}</span>
              </td>
              <td className='p-4'>{getBusinessRoleColumn(user)}</td>
              <td className='p-4'>{user.id != authenticatedUser.id && getFolioAccessColumn(user)}</td>
              <td className='p-4'>{user.id != authenticatedUser.id && getStatusColumn(user)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}
