import { ProjectInput } from '../generated/api'

export enum newProjectStateEnum {
  ClientCreation,
  ManageProjectSlideOut,
  VisualPreferences,
  SubmitProject,
  CleanProject,
  CurrentPage,
}

export interface IprojectStateMachine {
  currentState?: newProjectStateEnum
  project?: ProjectInput
}
