import * as Yup from 'yup'

const errorMessage = 'Please select a space value.'

Yup.addMethod(Yup.array, 'checkDuplicates', function(message, mapper = (a: any) => a) {
  return this.test('checkDuplicates', message, function(list) {
    return list?.length === new Set(list?.map(mapper)).size
  })
})

export const EditOtherSpacesFormValidator = Yup.object().shape({
  industryTypesOfSpacesQuantValues: Yup.array().of(
    Yup.object().shape({
      amount: Yup.number().integer().min(1, 'Can not be less than 1').required(),
      values: Yup.string().required(errorMessage),
    }),
  ),
  // .checkDuplicates('Please Select Unique Values', a => a.values),
})
