import React from 'react'
import PeopleCarry from 'theorem-lib/src/assets/icons/people_carry.svg'
import SiteMap from 'theorem-lib/src/assets/icons/site_map.svg'
import SolidEye from 'theorem-lib/src/assets/icons/solid_eye.svg'
import { useActions, useAppState } from '../../../presenter'
import { Wizard } from '../../atoms/Wizard/Wizard'

export const RegistrationWelcomeToFolioModal = () => {
  const { currentModal } = useAppState()
  const { displayModalAction } = useActions()

  const onJoinThePartyClick = () => {
    displayModalAction('IndustrySelectorWizard')
  }

  const onClose = () => {
    // TODO add logic when closing if need it.
  }

  return (
    <Wizard
      isOpen={currentModal === 'RegistrationWelcomeToFolioModal'}
      onClose={onClose}
    >
      <div className='px-20 pt-4 flex flex-col justify-center w-full[80vw] sm:w-full'>
        <div className='text-primary-100 text-2xl font-bold mt-2'>
          Welcome to FOLIO! <span>👋</span>
        </div>
        <div className='text-primary-300 text-base font-normal mb-8'>
          <p>You&apos;ve been invited to collaborate with your workspace project provider</p>
        </div>
        <div className='text-primary-100 text-lg text-base font-bold mb-6'>
          <p>FOLIO is your all-in-one project communication hub:</p>
        </div>
        <div className='text-primary-100 text-lg text-base mb-8'>
          <ul className='list-disc pl-8'>
            <li>Clearly define your business needs and project goals</li>
            <li>Easily view and approve a project budget</li>
            <li>Stay up to date on project status, next steps and much more</li>
          </ul>
        </div>
        <div className='mb-12'>
          and have more fun doing it all! <span>😀</span>
        </div>
        <div className='flex flex-row justify-between mb-0'>
          <div className='text-primary-100 text-lg text-base font-bold mb-6'>
            <SolidEye className='mb-4' />
            <p className='mb-1 text-base font-bold text-gray-900'>Visualize</p>
            <div className='text-primary-300 text-base font-normal mb-8'>
              Select design style, use of space, budgets, and view project status.
            </div>
          </div>
          <div className='text-primary-100 text-lg text-base font-bold'>
            <SiteMap className='mb-4' />
            <p className='mb-1 text-base font-bold text-gray-900'>Organize</p>
            <div className='text-primary-300 text-base font-normal mb-8'>
              Manage your project team, details, and tasks from one place.
            </div>
          </div>
          <div className='text-primary-100 text-lg text-base font-bold'>
            <PeopleCarry className='mb-4' />
            <p className='mb-1 text-base font-bold text-gray-900'>Collaborate</p>
            <div className='text-primary-300 text-base font-normal mb-8'>
              Share information, feedback, and assign tasks for each project.
            </div>
          </div>
        </div>
        <div className='flex flex-row justify-end mb-6'>
          <button type='button' className='button cta mx-2' onClick={onJoinThePartyClick}>Join The Party</button>
        </div>
      </div>
    </Wizard>
  )
}
