import * as Yup from 'yup'

const errorMessage = 'Please fill in the required field'

export const ProfileFormValidator = Yup.object().shape({
  businessRole: Yup.string().required(errorMessage),
  email: Yup.string().email('Please enter a valid email address').required(errorMessage),
  firstName: Yup.string().required(errorMessage),
  lastName: Yup.string().required(errorMessage),
})
