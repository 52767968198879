import { Project, Section, Task, Tasklist, User } from '../generated/api'
import { isProjectCollaborator, isProjectEditor, isProjectLead } from './projectHelper'

export function filterProjectWorkbookByProjectRole(
  authenticatedUser: User,
  selectedProject: Project,
  projectTaskLists: Tasklist[],
) {
  const isUserProjectLead = isProjectLead(selectedProject, authenticatedUser)
  const isUserProjectEditor = isProjectEditor(selectedProject, authenticatedUser)
  const isUserProjectCollaborator = isProjectCollaborator(selectedProject, authenticatedUser)

  switch (true) {
    case isUserProjectLead:
    case isUserProjectEditor:
      return projectTaskLists
    case isUserProjectCollaborator:
      return getCollaboratorWorkbook(authenticatedUser, projectTaskLists)
    default:
      return projectTaskLists
  }
}

export const getCollaboratorWorkbook = (user: User, tasklists: Tasklist[]): Tasklist[] => {
  const collaboratorTaskLists: Tasklist[] = []
  for (const tasklist of tasklists) {
    if (!shouldCollaboratorSeeEntireTasklistAndTasks(user, tasklist)) {
      tasklist.sections = getTaskslistsAndTasksFiltered(user, tasklist.sections)
    }
    if (tasklist.sections.length > 0) collaboratorTaskLists.push(tasklist)
  }
  return collaboratorTaskLists
}

export const shouldCollaboratorSeeEntireTasklistAndTasks = (user: User, tasklist: Tasklist): boolean => {
  return isUserTaskListCreator(user, tasklist) || isTaskListAssignedToUser(user, tasklist)
}

export const isUserTaskListCreator = (user: User, tasklist: Tasklist): boolean => {
  return user.id === tasklist.createUser.id
}

export const isTaskListAssignedToUser = (user: User, tasklist: Tasklist): boolean => {
  return tasklist.tasklistUsers ? tasklist.tasklistUsers?.some(tasklistUser => user.id === tasklistUser.id) : false
}

export const getTaskslistsAndTasksFiltered = (user: User, sections: Section[]): Section[] => {
  const collaboratorSections: Section[] = []
  for (const section of sections) {
    const collaboratorTasks: Task[] = []
    for (const task of section.tasks) {
      if (shouldCollaboratorSeeTask(user, task)) {
        collaboratorTasks.push(task)
      }
    }
    section.tasks = collaboratorTasks
    if (section.tasks.length > 0) collaboratorSections.push(section)
  }
  return collaboratorSections
}

export const shouldCollaboratorSeeTask = (user: User, task: Task): boolean => {
  return isUserTaggedInTask(user, task) || isTaskAssignedToUser(user, task) || isUserTaskCreator(user, task)
}

export const isUserTaggedInTask = (user: User, task: Task): boolean => {
  return task.comments ? task.comments?.some(comment => comment.tags?.some(tag => user.id === tag.userId)) : false
}

export const isTaskAssignedToUser = (user: User, task: Task): boolean => {
  return user.id == task.assignedUser?.id
}

export const isUserTaskCreator = (user: User, task: Task): boolean => {
  return user.id === task.createUser.id
}
