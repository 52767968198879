import { NotificationInput, NotificationsTypeEnum, Task, UpdateTasklistInput } from '../../generated/api'
import { Context } from '..'

export async function getNotificationsAction(context: Context) {
  context.state.notifications = await context.effects.getNotifications()
}

export async function sendMentionNotificationAction(context: Context, task: Task) {
  const input: NotificationInput = {
    fromUserId: context.state.authenticatedUser.id,
    projectId: context.state.selectedProject.id,
    sectionId: task.sectionId,
    taskId: task.id,
    tasklistId: getTaskListIdBySectionId(context, task.sectionId),
    toUserId: task.assignedUserId!, // Here the toUserId will match the mention.  One action call per @
    type: NotificationsTypeEnum.MentionedInTask,
  }

  await context.effects.sendNotification(input)
}

export async function sendAssignedToTaskNotificationAction(context: Context, task: Task) {
  const input: NotificationInput = {
    fromUserId: context.state.authenticatedUser.id,
    projectId: context.state.selectedProject.id,
    sectionId: task.sectionId,
    taskId: task.id,
    tasklistId: getTaskListIdBySectionId(context, task.sectionId),
    toUserId: task.assignedUserId!,
    type: NotificationsTypeEnum.AssignedToTask,
  }

  await context.effects.sendNotification(input)
}

export async function sendUnassignedToTaskNotificationAction(context: Context, task: Task) {
  const input: NotificationInput = {
    fromUserId: context.state.authenticatedUser.id,
    projectId: context.state.selectedProject.id,
    sectionId: task.sectionId,
    taskId: task.id,
    tasklistId: getTaskListIdBySectionId(context, task.sectionId),
    toUserId: task.assignedUser!.id,
    type: NotificationsTypeEnum.UnassignedToTask,
  }

  await context.effects.sendNotification(input)
}

function getTaskListIdBySectionId(context: Context, sectionId: string): string | undefined {
  let taskListId: string | undefined

  context.state.selectedProjectTaskLists.sort((a, b) =>
    (+a.isArchived) - (+b.isArchived) || a.name.localeCompare(b.name)
  ).map(projectTaskLists => {
    projectTaskLists.sections.map(section => {
      if (section.id === sectionId) {
        taskListId = section.tasklistId
      }
    })
  })

  return taskListId
}

export async function sendAssignedToTaskListNotificationAction(context: Context, tasklist: UpdateTasklistInput) {
  if (tasklist.assignedUserIds) {
    for (const userId of tasklist.assignedUserIds) {
      const input: NotificationInput = {
        fromUserId: context.state.authenticatedUser.id,
        projectId: context.state.selectedProject.id,
        tasklistId: tasklist.id,
        toUserId: userId,
        type: NotificationsTypeEnum.AssignedToTaskList,
      }

      await context.effects.sendNotification(input)
    }
  }
}

export async function sendUnassignedToTaskListNotificationAction(context: Context, tasklist: UpdateTasklistInput) {
  if (tasklist.removedUserIds) {
    for (const userId of tasklist.removedUserIds) {
      const input: NotificationInput = {
        fromUserId: context.state.authenticatedUser.id,
        projectId: context.state.selectedProject.id,
        tasklistId: tasklist.id,
        toUserId: userId,
        type: NotificationsTypeEnum.UnassignedToTaskList,
      }

      await context.effects.sendNotification(input)
    }
  }
}

export async function dismissNotificationAction(context: Context, id: string) {
  await context.effects.deleteNotification(id)
  if (context.state.notifications) {
    context.state.notifications = context.state.notifications.filter(notifications => notifications.id !== id)
  }
}
