{
  "--*": {
    "syntax": "<declaration-value>",
    "media": "all",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Variables"
    ],
    "initial": "seeProse",
    "appliesto": "allElements",
    "computed": "asSpecifiedWithVarsSubstituted",
    "order": "perGrammar",
    "status": "experimental",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/--*"
  },
  "-ms-accelerator": {
    "syntax": "false | true",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Microsoft Extensions"
    ],
    "initial": "false",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-ms-accelerator"
  },
  "-ms-block-progression": {
    "syntax": "tb | rl | bt | lr",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Microsoft Extensions"
    ],
    "initial": "tb",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-ms-block-progression"
  },
  "-ms-content-zoom-chaining": {
    "syntax": "none | chained",
    "media": "interactive",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Microsoft Extensions"
    ],
    "initial": "none",
    "appliesto": "nonReplacedBlockAndInlineBlockElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-ms-content-zoom-chaining"
  },
  "-ms-content-zooming": {
    "syntax": "none | zoom",
    "media": "interactive",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Microsoft Extensions"
    ],
    "initial": "zoomForTheTopLevelNoneForTheRest",
    "appliesto": "nonReplacedBlockAndInlineBlockElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-ms-content-zooming"
  },
  "-ms-content-zoom-limit": {
    "syntax": "<'-ms-content-zoom-limit-min'> <'-ms-content-zoom-limit-max'>",
    "media": "interactive",
    "inherited": false,
    "animationType": "discrete",
    "percentages": [
      "-ms-content-zoom-limit-max",
      "-ms-content-zoom-limit-min"
    ],
    "groups": [
      "Microsoft Extensions"
    ],
    "initial": [
      "-ms-content-zoom-limit-max",
      "-ms-content-zoom-limit-min"
    ],
    "appliesto": "nonReplacedBlockAndInlineBlockElements",
    "computed": [
      "-ms-content-zoom-limit-max",
      "-ms-content-zoom-limit-min"
    ],
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-ms-content-zoom-limit"
  },
  "-ms-content-zoom-limit-max": {
    "syntax": "<percentage>",
    "media": "interactive",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "maxZoomFactor",
    "groups": [
      "Microsoft Extensions"
    ],
    "initial": "400%",
    "appliesto": "nonReplacedBlockAndInlineBlockElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-ms-content-zoom-limit-max"
  },
  "-ms-content-zoom-limit-min": {
    "syntax": "<percentage>",
    "media": "interactive",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "minZoomFactor",
    "groups": [
      "Microsoft Extensions"
    ],
    "initial": "100%",
    "appliesto": "nonReplacedBlockAndInlineBlockElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-ms-content-zoom-limit-min"
  },
  "-ms-content-zoom-snap": {
    "syntax": "<'-ms-content-zoom-snap-type'> || <'-ms-content-zoom-snap-points'>",
    "media": "interactive",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Microsoft Extensions"
    ],
    "initial": [
      "-ms-content-zoom-snap-type",
      "-ms-content-zoom-snap-points"
    ],
    "appliesto": "nonReplacedBlockAndInlineBlockElements",
    "computed": [
      "-ms-content-zoom-snap-type",
      "-ms-content-zoom-snap-points"
    ],
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-ms-content-zoom-snap"
  },
  "-ms-content-zoom-snap-points": {
    "syntax": "snapInterval( <percentage>, <percentage> ) | snapList( <percentage># )",
    "media": "interactive",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Microsoft Extensions"
    ],
    "initial": "snapInterval(0%, 100%)",
    "appliesto": "nonReplacedBlockAndInlineBlockElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-ms-content-zoom-snap-points"
  },
  "-ms-content-zoom-snap-type": {
    "syntax": "none | proximity | mandatory",
    "media": "interactive",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Microsoft Extensions"
    ],
    "initial": "none",
    "appliesto": "nonReplacedBlockAndInlineBlockElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-ms-content-zoom-snap-type"
  },
  "-ms-filter": {
    "syntax": "<string>",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Microsoft Extensions"
    ],
    "initial": "\"\"",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-ms-filter"
  },
  "-ms-flow-from": {
    "syntax": "[ none | <custom-ident> ]#",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Microsoft Extensions"
    ],
    "initial": "none",
    "appliesto": "nonReplacedElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-ms-flow-from"
  },
  "-ms-flow-into": {
    "syntax": "[ none | <custom-ident> ]#",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Microsoft Extensions"
    ],
    "initial": "none",
    "appliesto": "iframeElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-ms-flow-into"
  },
  "-ms-grid-columns": {
    "syntax": "none | <track-list> | <auto-track-list>",
    "media": "visual",
    "inherited": false,
    "animationType": "simpleListOfLpcDifferenceLpc",
    "percentages": "referToDimensionOfContentArea",
    "groups": [
      "CSS Grid Layout"
    ],
    "initial": "none",
    "appliesto": "gridContainers",
    "computed": "asSpecifiedRelativeToAbsoluteLengths",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-ms-grid-columns"
  },
  "-ms-grid-rows": {
    "syntax": "none | <track-list> | <auto-track-list>",
    "media": "visual",
    "inherited": false,
    "animationType": "simpleListOfLpcDifferenceLpc",
    "percentages": "referToDimensionOfContentArea",
    "groups": [
      "CSS Grid Layout"
    ],
    "initial": "none",
    "appliesto": "gridContainers",
    "computed": "asSpecifiedRelativeToAbsoluteLengths",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-ms-grid-rows"
  },
  "-ms-high-contrast-adjust": {
    "syntax": "auto | none",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Microsoft Extensions"
    ],
    "initial": "auto",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-ms-high-contrast-adjust"
  },
  "-ms-hyphenate-limit-chars": {
    "syntax": "auto | <integer>{1,3}",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Microsoft Extensions"
    ],
    "initial": "auto",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-ms-hyphenate-limit-chars"
  },
  "-ms-hyphenate-limit-lines": {
    "syntax": "no-limit | <integer>",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Microsoft Extensions"
    ],
    "initial": "no-limit",
    "appliesto": "blockContainerElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-ms-hyphenate-limit-lines"
  },
  "-ms-hyphenate-limit-zone": {
    "syntax": "<percentage> | <length>",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "referToLineBoxWidth",
    "groups": [
      "Microsoft Extensions"
    ],
    "initial": "0",
    "appliesto": "blockContainerElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-ms-hyphenate-limit-zone"
  },
  "-ms-ime-align": {
    "syntax": "auto | after",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Microsoft Extensions"
    ],
    "initial": "auto",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-ms-ime-align"
  },
  "-ms-overflow-style": {
    "syntax": "auto | none | scrollbar | -ms-autohiding-scrollbar",
    "media": "interactive",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Microsoft Extensions"
    ],
    "initial": "auto",
    "appliesto": "nonReplacedBlockAndInlineBlockElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-ms-overflow-style"
  },
  "-ms-scrollbar-3dlight-color": {
    "syntax": "<color>",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Microsoft Extensions"
    ],
    "initial": "dependsOnUserAgent",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-ms-scrollbar-3dlight-color"
  },
  "-ms-scrollbar-arrow-color": {
    "syntax": "<color>",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Microsoft Extensions"
    ],
    "initial": "ButtonText",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-ms-scrollbar-arrow-color"
  },
  "-ms-scrollbar-base-color": {
    "syntax": "<color>",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Microsoft Extensions"
    ],
    "initial": "dependsOnUserAgent",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-ms-scrollbar-base-color"
  },
  "-ms-scrollbar-darkshadow-color": {
    "syntax": "<color>",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Microsoft Extensions"
    ],
    "initial": "ThreeDDarkShadow",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-ms-scrollbar-darkshadow-color"
  },
  "-ms-scrollbar-face-color": {
    "syntax": "<color>",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Microsoft Extensions"
    ],
    "initial": "ThreeDFace",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-ms-scrollbar-face-color"
  },
  "-ms-scrollbar-highlight-color": {
    "syntax": "<color>",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Microsoft Extensions"
    ],
    "initial": "ThreeDHighlight",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-ms-scrollbar-highlight-color"
  },
  "-ms-scrollbar-shadow-color": {
    "syntax": "<color>",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Microsoft Extensions"
    ],
    "initial": "ThreeDDarkShadow",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-ms-scrollbar-shadow-color"
  },
  "-ms-scrollbar-track-color": {
    "syntax": "<color>",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Microsoft Extensions"
    ],
    "initial": "Scrollbar",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-ms-scrollbar-track-color"
  },
  "-ms-scroll-chaining": {
    "syntax": "chained | none",
    "media": "interactive",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Microsoft Extensions"
    ],
    "initial": "chained",
    "appliesto": "nonReplacedBlockAndInlineBlockElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-ms-scroll-chaining"
  },
  "-ms-scroll-limit": {
    "syntax": "<'-ms-scroll-limit-x-min'> <'-ms-scroll-limit-y-min'> <'-ms-scroll-limit-x-max'> <'-ms-scroll-limit-y-max'>",
    "media": "interactive",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Microsoft Extensions"
    ],
    "initial": [
      "-ms-scroll-limit-x-min",
      "-ms-scroll-limit-y-min",
      "-ms-scroll-limit-x-max",
      "-ms-scroll-limit-y-max"
    ],
    "appliesto": "nonReplacedBlockAndInlineBlockElements",
    "computed": [
      "-ms-scroll-limit-x-min",
      "-ms-scroll-limit-y-min",
      "-ms-scroll-limit-x-max",
      "-ms-scroll-limit-y-max"
    ],
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-ms-scroll-limit"
  },
  "-ms-scroll-limit-x-max": {
    "syntax": "auto | <length>",
    "media": "interactive",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Microsoft Extensions"
    ],
    "initial": "auto",
    "appliesto": "nonReplacedBlockAndInlineBlockElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-ms-scroll-limit-x-max"
  },
  "-ms-scroll-limit-x-min": {
    "syntax": "<length>",
    "media": "interactive",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Microsoft Extensions"
    ],
    "initial": "0",
    "appliesto": "nonReplacedBlockAndInlineBlockElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-ms-scroll-limit-x-min"
  },
  "-ms-scroll-limit-y-max": {
    "syntax": "auto | <length>",
    "media": "interactive",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Microsoft Extensions"
    ],
    "initial": "auto",
    "appliesto": "nonReplacedBlockAndInlineBlockElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-ms-scroll-limit-y-max"
  },
  "-ms-scroll-limit-y-min": {
    "syntax": "<length>",
    "media": "interactive",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Microsoft Extensions"
    ],
    "initial": "0",
    "appliesto": "nonReplacedBlockAndInlineBlockElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-ms-scroll-limit-y-min"
  },
  "-ms-scroll-rails": {
    "syntax": "none | railed",
    "media": "interactive",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Microsoft Extensions"
    ],
    "initial": "railed",
    "appliesto": "nonReplacedBlockAndInlineBlockElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-ms-scroll-rails"
  },
  "-ms-scroll-snap-points-x": {
    "syntax": "snapInterval( <length-percentage>, <length-percentage> ) | snapList( <length-percentage># )",
    "media": "interactive",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Microsoft Extensions"
    ],
    "initial": "snapInterval(0px, 100%)",
    "appliesto": "nonReplacedBlockAndInlineBlockElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-ms-scroll-snap-points-x"
  },
  "-ms-scroll-snap-points-y": {
    "syntax": "snapInterval( <length-percentage>, <length-percentage> ) | snapList( <length-percentage># )",
    "media": "interactive",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Microsoft Extensions"
    ],
    "initial": "snapInterval(0px, 100%)",
    "appliesto": "nonReplacedBlockAndInlineBlockElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-ms-scroll-snap-points-y"
  },
  "-ms-scroll-snap-type": {
    "syntax": "none | proximity | mandatory",
    "media": "interactive",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Microsoft Extensions"
    ],
    "initial": "none",
    "appliesto": "nonReplacedBlockAndInlineBlockElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-ms-scroll-snap-type"
  },
  "-ms-scroll-snap-x": {
    "syntax": "<'-ms-scroll-snap-type'> <'-ms-scroll-snap-points-x'>",
    "media": "interactive",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Microsoft Extensions"
    ],
    "initial": [
      "-ms-scroll-snap-type",
      "-ms-scroll-snap-points-x"
    ],
    "appliesto": "nonReplacedBlockAndInlineBlockElements",
    "computed": [
      "-ms-scroll-snap-type",
      "-ms-scroll-snap-points-x"
    ],
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-ms-scroll-snap-x"
  },
  "-ms-scroll-snap-y": {
    "syntax": "<'-ms-scroll-snap-type'> <'-ms-scroll-snap-points-y'>",
    "media": "interactive",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Microsoft Extensions"
    ],
    "initial": [
      "-ms-scroll-snap-type",
      "-ms-scroll-snap-points-y"
    ],
    "appliesto": "nonReplacedBlockAndInlineBlockElements",
    "computed": [
      "-ms-scroll-snap-type",
      "-ms-scroll-snap-points-y"
    ],
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-ms-scroll-snap-y"
  },
  "-ms-scroll-translation": {
    "syntax": "none | vertical-to-horizontal",
    "media": "interactive",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Microsoft Extensions"
    ],
    "initial": "none",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-ms-scroll-translation"
  },
  "-ms-text-autospace": {
    "syntax": "none | ideograph-alpha | ideograph-numeric | ideograph-parenthesis | ideograph-space",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Microsoft Extensions"
    ],
    "initial": "none",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-ms-text-autospace"
  },
  "-ms-touch-select": {
    "syntax": "grippers | none",
    "media": "interactive",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Microsoft Extensions"
    ],
    "initial": "grippers",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-ms-touch-select"
  },
  "-ms-user-select": {
    "syntax": "none | element | text",
    "media": "interactive",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Microsoft Extensions"
    ],
    "initial": "text",
    "appliesto": "nonReplacedElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-ms-user-select"
  },
  "-ms-wrap-flow": {
    "syntax": "auto | both | start | end | maximum | clear",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Microsoft Extensions"
    ],
    "initial": "auto",
    "appliesto": "blockLevelElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-ms-wrap-flow"
  },
  "-ms-wrap-margin": {
    "syntax": "<length>",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Microsoft Extensions"
    ],
    "initial": "0",
    "appliesto": "exclusionElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-ms-wrap-margin"
  },
  "-ms-wrap-through": {
    "syntax": "wrap | none",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Microsoft Extensions"
    ],
    "initial": "wrap",
    "appliesto": "blockLevelElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-ms-wrap-through"
  },
  "-moz-appearance": {
    "syntax": "none | button | button-arrow-down | button-arrow-next | button-arrow-previous | button-arrow-up | button-bevel | button-focus | caret | checkbox | checkbox-container | checkbox-label | checkmenuitem | dualbutton | groupbox | listbox | listitem | menuarrow | menubar | menucheckbox | menuimage | menuitem | menuitemtext | menulist | menulist-button | menulist-text | menulist-textfield | menupopup | menuradio | menuseparator | meterbar | meterchunk | progressbar | progressbar-vertical | progresschunk | progresschunk-vertical | radio | radio-container | radio-label | radiomenuitem | range | range-thumb | resizer | resizerpanel | scale-horizontal | scalethumbend | scalethumb-horizontal | scalethumbstart | scalethumbtick | scalethumb-vertical | scale-vertical | scrollbarbutton-down | scrollbarbutton-left | scrollbarbutton-right | scrollbarbutton-up | scrollbarthumb-horizontal | scrollbarthumb-vertical | scrollbartrack-horizontal | scrollbartrack-vertical | searchfield | separator | sheet | spinner | spinner-downbutton | spinner-textfield | spinner-upbutton | splitter | statusbar | statusbarpanel | tab | tabpanel | tabpanels | tab-scroll-arrow-back | tab-scroll-arrow-forward | textfield | textfield-multiline | toolbar | toolbarbutton | toolbarbutton-dropdown | toolbargripper | toolbox | tooltip | treeheader | treeheadercell | treeheadersortarrow | treeitem | treeline | treetwisty | treetwistyopen | treeview | -moz-mac-unified-toolbar | -moz-win-borderless-glass | -moz-win-browsertabbar-toolbox | -moz-win-communicationstext | -moz-win-communications-toolbox | -moz-win-exclude-glass | -moz-win-glass | -moz-win-mediatext | -moz-win-media-toolbox | -moz-window-button-box | -moz-window-button-box-maximized | -moz-window-button-close | -moz-window-button-maximize | -moz-window-button-minimize | -moz-window-button-restore | -moz-window-frame-bottom | -moz-window-frame-left | -moz-window-frame-right | -moz-window-titlebar | -moz-window-titlebar-maximized",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Mozilla Extensions",
      "WebKit Extensions"
    ],
    "initial": "noneButOverriddenInUserAgentCSS",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/appearance"
  },
  "-moz-binding": {
    "syntax": "<url> | none",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Mozilla Extensions"
    ],
    "initial": "none",
    "appliesto": "allElementsExceptGeneratedContentOrPseudoElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-moz-binding"
  },
  "-moz-border-bottom-colors": {
    "syntax": "<color>+ | none",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Mozilla Extensions"
    ],
    "initial": "none",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-moz-border-bottom-colors"
  },
  "-moz-border-left-colors": {
    "syntax": "<color>+ | none",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Mozilla Extensions"
    ],
    "initial": "none",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-moz-border-left-colors"
  },
  "-moz-border-right-colors": {
    "syntax": "<color>+ | none",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Mozilla Extensions"
    ],
    "initial": "none",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-moz-border-right-colors"
  },
  "-moz-border-top-colors": {
    "syntax": "<color>+ | none",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Mozilla Extensions"
    ],
    "initial": "none",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-moz-border-top-colors"
  },
  "-moz-context-properties": {
    "syntax": "none | [ fill | fill-opacity | stroke | stroke-opacity ]#",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Mozilla Extensions"
    ],
    "initial": "none",
    "appliesto": "allElementsThatCanReferenceImages",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-moz-context-properties"
  },
  "-moz-float-edge": {
    "syntax": "border-box | content-box | margin-box | padding-box",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Mozilla Extensions"
    ],
    "initial": "content-box",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-moz-float-edge"
  },
  "-moz-force-broken-image-icon": {
    "syntax": "<integer [0,1]>",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Mozilla Extensions"
    ],
    "initial": "0",
    "appliesto": "images",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-moz-force-broken-image-icon"
  },
  "-moz-image-region": {
    "syntax": "<shape> | auto",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Mozilla Extensions"
    ],
    "initial": "auto",
    "appliesto": "xulImageElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-moz-image-region"
  },
  "-moz-orient": {
    "syntax": "inline | block | horizontal | vertical",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Mozilla Extensions"
    ],
    "initial": "inline",
    "appliesto": "anyElementEffectOnProgressAndMeter",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-moz-orient"
  },
  "-moz-outline-radius": {
    "syntax": "<outline-radius>{1,4} [ / <outline-radius>{1,4} ]?",
    "media": "visual",
    "inherited": false,
    "animationType": [
      "-moz-outline-radius-topleft",
      "-moz-outline-radius-topright",
      "-moz-outline-radius-bottomright",
      "-moz-outline-radius-bottomleft"
    ],
    "percentages": [
      "-moz-outline-radius-topleft",
      "-moz-outline-radius-topright",
      "-moz-outline-radius-bottomright",
      "-moz-outline-radius-bottomleft"
    ],
    "groups": [
      "Mozilla Extensions"
    ],
    "initial": [
      "-moz-outline-radius-topleft",
      "-moz-outline-radius-topright",
      "-moz-outline-radius-bottomright",
      "-moz-outline-radius-bottomleft"
    ],
    "appliesto": "allElements",
    "computed": [
      "-moz-outline-radius-topleft",
      "-moz-outline-radius-topright",
      "-moz-outline-radius-bottomright",
      "-moz-outline-radius-bottomleft"
    ],
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-moz-outline-radius"
  },
  "-moz-outline-radius-bottomleft": {
    "syntax": "<outline-radius>",
    "media": "visual",
    "inherited": false,
    "animationType": "lpc",
    "percentages": "referToDimensionOfBorderBox",
    "groups": [
      "Mozilla Extensions"
    ],
    "initial": "0",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-moz-outline-radius-bottomleft"
  },
  "-moz-outline-radius-bottomright": {
    "syntax": "<outline-radius>",
    "media": "visual",
    "inherited": false,
    "animationType": "lpc",
    "percentages": "referToDimensionOfBorderBox",
    "groups": [
      "Mozilla Extensions"
    ],
    "initial": "0",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-moz-outline-radius-bottomright"
  },
  "-moz-outline-radius-topleft": {
    "syntax": "<outline-radius>",
    "media": "visual",
    "inherited": false,
    "animationType": "lpc",
    "percentages": "referToDimensionOfBorderBox",
    "groups": [
      "Mozilla Extensions"
    ],
    "initial": "0",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-moz-outline-radius-topleft"
  },
  "-moz-outline-radius-topright": {
    "syntax": "<outline-radius>",
    "media": "visual",
    "inherited": false,
    "animationType": "lpc",
    "percentages": "referToDimensionOfBorderBox",
    "groups": [
      "Mozilla Extensions"
    ],
    "initial": "0",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-moz-outline-radius-topright"
  },
  "-moz-stack-sizing": {
    "syntax": "ignore | stretch-to-fit",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Mozilla Extensions"
    ],
    "initial": "stretch-to-fit",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-moz-stack-sizing"
  },
  "-moz-text-blink": {
    "syntax": "none | blink",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Mozilla Extensions"
    ],
    "initial": "none",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-moz-text-blink"
  },
  "-moz-user-focus": {
    "syntax": "ignore | normal | select-after | select-before | select-menu | select-same | select-all | none",
    "media": "interactive",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Mozilla Extensions"
    ],
    "initial": "none",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-moz-user-focus"
  },
  "-moz-user-input": {
    "syntax": "auto | none | enabled | disabled",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Mozilla Extensions"
    ],
    "initial": "auto",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-moz-user-input"
  },
  "-moz-user-modify": {
    "syntax": "read-only | read-write | write-only",
    "media": "interactive",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Mozilla Extensions"
    ],
    "initial": "read-only",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-moz-user-modify"
  },
  "-moz-window-dragging": {
    "syntax": "drag | no-drag",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Mozilla Extensions"
    ],
    "initial": "drag",
    "appliesto": "allElementsCreatingNativeWindows",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-moz-window-dragging"
  },
  "-moz-window-shadow": {
    "syntax": "default | menu | tooltip | sheet | none",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Mozilla Extensions"
    ],
    "initial": "default",
    "appliesto": "allElementsCreatingNativeWindows",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-moz-window-shadow"
  },
  "-webkit-appearance": {
    "syntax": "none | button | button-bevel | caret | checkbox | default-button | inner-spin-button | listbox | listitem | media-controls-background | media-controls-fullscreen-background | media-current-time-display | media-enter-fullscreen-button | media-exit-fullscreen-button | media-fullscreen-button | media-mute-button | media-overlay-play-button | media-play-button | media-seek-back-button | media-seek-forward-button | media-slider | media-sliderthumb | media-time-remaining-display | media-toggle-closed-captions-button | media-volume-slider | media-volume-slider-container | media-volume-sliderthumb | menulist | menulist-button | menulist-text | menulist-textfield | meter | progress-bar | progress-bar-value | push-button | radio | searchfield | searchfield-cancel-button | searchfield-decoration | searchfield-results-button | searchfield-results-decoration | slider-horizontal | slider-vertical | sliderthumb-horizontal | sliderthumb-vertical | square-button | textarea | textfield | -apple-pay-button",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "WebKit Extensions"
    ],
    "initial": "noneButOverriddenInUserAgentCSS",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/appearance"
  },
  "-webkit-border-before": {
    "syntax": "<'border-width'> || <'border-style'> || <'color'>",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": [
      "-webkit-border-before-width"
    ],
    "groups": [
      "WebKit Extensions"
    ],
    "initial": [
      "border-width",
      "border-style",
      "color"
    ],
    "appliesto": "allElements",
    "computed": [
      "border-width",
      "border-style",
      "color"
    ],
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-webkit-border-before"
  },
  "-webkit-border-before-color": {
    "syntax": "<'color'>",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "WebKit Extensions"
    ],
    "initial": "currentcolor",
    "appliesto": "allElements",
    "computed": "computedColor",
    "order": "uniqueOrder",
    "status": "nonstandard"
  },
  "-webkit-border-before-style": {
    "syntax": "<'border-style'>",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "WebKit Extensions"
    ],
    "initial": "none",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard"
  },
  "-webkit-border-before-width": {
    "syntax": "<'border-width'>",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "logicalWidthOfContainingBlock",
    "groups": [
      "WebKit Extensions"
    ],
    "initial": "medium",
    "appliesto": "allElements",
    "computed": "absoluteLengthZeroIfBorderStyleNoneOrHidden",
    "order": "uniqueOrder",
    "status": "nonstandard"
  },
  "-webkit-box-reflect": {
    "syntax": "[ above | below | right | left ]? <length>? <image>?",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "WebKit Extensions"
    ],
    "initial": "none",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-webkit-box-reflect"
  },
  "-webkit-line-clamp": {
    "syntax": "none | <integer>",
    "media": "visual",
    "inherited": false,
    "animationType": "byComputedValueType",
    "percentages": "no",
    "groups": [
      "WebKit Extensions",
      "CSS Overflow"
    ],
    "initial": "none",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-webkit-line-clamp"
  },
  "-webkit-mask": {
    "syntax": "[ <mask-reference> || <position> [ / <bg-size> ]? || <repeat-style> || [ <box> | border | padding | content | text ] || [ <box> | border | padding | content ] ]#",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "WebKit Extensions"
    ],
    "initial": [
      "-webkit-mask-image",
      "-webkit-mask-repeat",
      "-webkit-mask-attachment",
      "-webkit-mask-position",
      "-webkit-mask-origin",
      "-webkit-mask-clip"
    ],
    "appliesto": "allElements",
    "computed": [
      "-webkit-mask-image",
      "-webkit-mask-repeat",
      "-webkit-mask-attachment",
      "-webkit-mask-position",
      "-webkit-mask-origin",
      "-webkit-mask-clip"
    ],
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/mask"
  },
  "-webkit-mask-attachment": {
    "syntax": "<attachment>#",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "WebKit Extensions"
    ],
    "initial": "scroll",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "orderOfAppearance",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-webkit-mask-attachment"
  },
  "-webkit-mask-clip": {
    "syntax": "[ <box> | border | padding | content | text ]#",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "WebKit Extensions"
    ],
    "initial": "border",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "orderOfAppearance",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/mask-clip"
  },
  "-webkit-mask-composite": {
    "syntax": "<composite-style>#",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "WebKit Extensions"
    ],
    "initial": "source-over",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "orderOfAppearance",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-webkit-mask-composite"
  },
  "-webkit-mask-image": {
    "syntax": "<mask-reference>#",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "WebKit Extensions"
    ],
    "initial": "none",
    "appliesto": "allElements",
    "computed": "absoluteURIOrNone",
    "order": "orderOfAppearance",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/mask-image"
  },
  "-webkit-mask-origin": {
    "syntax": "[ <box> | border | padding | content ]#",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "WebKit Extensions"
    ],
    "initial": "padding",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "orderOfAppearance",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/mask-origin"
  },
  "-webkit-mask-position": {
    "syntax": "<position>#",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "referToSizeOfElement",
    "groups": [
      "WebKit Extensions"
    ],
    "initial": "0% 0%",
    "appliesto": "allElements",
    "computed": "absoluteLengthOrPercentage",
    "order": "orderOfAppearance",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/mask-position"
  },
  "-webkit-mask-position-x": {
    "syntax": "[ <length-percentage> | left | center | right ]#",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "referToSizeOfElement",
    "groups": [
      "WebKit Extensions"
    ],
    "initial": "0%",
    "appliesto": "allElements",
    "computed": "absoluteLengthOrPercentage",
    "order": "orderOfAppearance",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-webkit-mask-position-x"
  },
  "-webkit-mask-position-y": {
    "syntax": "[ <length-percentage> | top | center | bottom ]#",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "referToSizeOfElement",
    "groups": [
      "WebKit Extensions"
    ],
    "initial": "0%",
    "appliesto": "allElements",
    "computed": "absoluteLengthOrPercentage",
    "order": "orderOfAppearance",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-webkit-mask-position-y"
  },
  "-webkit-mask-repeat": {
    "syntax": "<repeat-style>#",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "WebKit Extensions"
    ],
    "initial": "repeat",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "orderOfAppearance",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/mask-repeat"
  },
  "-webkit-mask-repeat-x": {
    "syntax": "repeat | no-repeat | space | round",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "WebKit Extensions"
    ],
    "initial": "repeat",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "orderOfAppearance",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-webkit-mask-repeat-x"
  },
  "-webkit-mask-repeat-y": {
    "syntax": "repeat | no-repeat | space | round",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "WebKit Extensions"
    ],
    "initial": "repeat",
    "appliesto": "allElements",
    "computed": "absoluteLengthOrPercentage",
    "order": "orderOfAppearance",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-webkit-mask-repeat-y"
  },
  "-webkit-mask-size": {
    "syntax": "<bg-size>#",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "relativeToBackgroundPositioningArea",
    "groups": [
      "WebKit Extensions"
    ],
    "initial": "auto auto",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "orderOfAppearance",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/mask-size"
  },
  "-webkit-overflow-scrolling": {
    "syntax": "auto | touch",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "WebKit Extensions"
    ],
    "initial": "auto",
    "appliesto": "scrollingBoxes",
    "computed": "asSpecified",
    "order": "orderOfAppearance",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-webkit-overflow-scrolling"
  },
  "-webkit-tap-highlight-color": {
    "syntax": "<color>",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "WebKit Extensions"
    ],
    "initial": "black",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-webkit-tap-highlight-color"
  },
  "-webkit-text-fill-color": {
    "syntax": "<color>",
    "media": "visual",
    "inherited": true,
    "animationType": "color",
    "percentages": "no",
    "groups": [
      "WebKit Extensions"
    ],
    "initial": "currentcolor",
    "appliesto": "allElements",
    "computed": "computedColor",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-webkit-text-fill-color"
  },
  "-webkit-text-stroke": {
    "syntax": "<length> || <color>",
    "media": "visual",
    "inherited": true,
    "animationType": [
      "-webkit-text-stroke-width",
      "-webkit-text-stroke-color"
    ],
    "percentages": "no",
    "groups": [
      "WebKit Extensions"
    ],
    "initial": [
      "-webkit-text-stroke-width",
      "-webkit-text-stroke-color"
    ],
    "appliesto": "allElements",
    "computed": [
      "-webkit-text-stroke-width",
      "-webkit-text-stroke-color"
    ],
    "order": "canonicalOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-webkit-text-stroke"
  },
  "-webkit-text-stroke-color": {
    "syntax": "<color>",
    "media": "visual",
    "inherited": true,
    "animationType": "color",
    "percentages": "no",
    "groups": [
      "WebKit Extensions"
    ],
    "initial": "currentcolor",
    "appliesto": "allElements",
    "computed": "computedColor",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-webkit-text-stroke-color"
  },
  "-webkit-text-stroke-width": {
    "syntax": "<length>",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "WebKit Extensions"
    ],
    "initial": "0",
    "appliesto": "allElements",
    "computed": "absoluteLength",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-webkit-text-stroke-width"
  },
  "-webkit-touch-callout": {
    "syntax": "default | none",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "WebKit Extensions"
    ],
    "initial": "default",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/-webkit-touch-callout"
  },
  "-webkit-user-modify": {
    "syntax": "read-only | read-write | read-write-plaintext-only",
    "media": "interactive",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "WebKit Extensions"
    ],
    "initial": "read-only",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard"
  },
  "align-content": {
    "syntax": "normal | <baseline-position> | <content-distribution> | <overflow-position>? <content-position>",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Box Alignment"
    ],
    "initial": "normal",
    "appliesto": "multilineFlexContainers",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/align-content"
  },
  "align-items": {
    "syntax": "normal | stretch | <baseline-position> | [ <overflow-position>? <self-position> ]",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Box Alignment"
    ],
    "initial": "normal",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/align-items"
  },
  "align-self": {
    "syntax": "auto | normal | stretch | <baseline-position> | <overflow-position>? <self-position>",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Box Alignment"
    ],
    "initial": "auto",
    "appliesto": "flexItemsGridItemsAndAbsolutelyPositionedBoxes",
    "computed": "autoOnAbsolutelyPositionedElementsValueOfAlignItemsOnParent",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/align-self"
  },
  "align-tracks": {
    "syntax": "[ normal | <baseline-position> | <content-distribution> | <overflow-position>? <content-position> ]#",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Grid Layout"
    ],
    "initial": "normal",
    "appliesto": "gridContainersWithMasonryLayoutInTheirBlockAxis",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "experimental",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/align-tracks"
  },
  "all": {
    "syntax": "initial | inherit | unset | revert",
    "media": "noPracticalMedia",
    "inherited": false,
    "animationType": "eachOfShorthandPropertiesExceptUnicodeBiDiAndDirection",
    "percentages": "no",
    "groups": [
      "CSS Miscellaneous"
    ],
    "initial": "noPracticalInitialValue",
    "appliesto": "allElements",
    "computed": "asSpecifiedAppliesToEachProperty",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/all"
  },
  "animation": {
    "syntax": "<single-animation>#",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Animations"
    ],
    "initial": [
      "animation-name",
      "animation-duration",
      "animation-timing-function",
      "animation-delay",
      "animation-iteration-count",
      "animation-direction",
      "animation-fill-mode",
      "animation-play-state"
    ],
    "appliesto": "allElementsAndPseudos",
    "computed": [
      "animation-name",
      "animation-duration",
      "animation-timing-function",
      "animation-delay",
      "animation-direction",
      "animation-iteration-count",
      "animation-fill-mode",
      "animation-play-state"
    ],
    "order": "orderOfAppearance",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/animation"
  },
  "animation-delay": {
    "syntax": "<time>#",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Animations"
    ],
    "initial": "0s",
    "appliesto": "allElementsAndPseudos",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/animation-delay"
  },
  "animation-direction": {
    "syntax": "<single-animation-direction>#",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Animations"
    ],
    "initial": "normal",
    "appliesto": "allElementsAndPseudos",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/animation-direction"
  },
  "animation-duration": {
    "syntax": "<time>#",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Animations"
    ],
    "initial": "0s",
    "appliesto": "allElementsAndPseudos",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/animation-duration"
  },
  "animation-fill-mode": {
    "syntax": "<single-animation-fill-mode>#",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Animations"
    ],
    "initial": "none",
    "appliesto": "allElementsAndPseudos",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/animation-fill-mode"
  },
  "animation-iteration-count": {
    "syntax": "<single-animation-iteration-count>#",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Animations"
    ],
    "initial": "1",
    "appliesto": "allElementsAndPseudos",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/animation-iteration-count"
  },
  "animation-name": {
    "syntax": "[ none | <keyframes-name> ]#",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Animations"
    ],
    "initial": "none",
    "appliesto": "allElementsAndPseudos",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/animation-name"
  },
  "animation-play-state": {
    "syntax": "<single-animation-play-state>#",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Animations"
    ],
    "initial": "running",
    "appliesto": "allElementsAndPseudos",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/animation-play-state"
  },
  "animation-timing-function": {
    "syntax": "<timing-function>#",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Animations"
    ],
    "initial": "ease",
    "appliesto": "allElementsAndPseudos",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/animation-timing-function"
  },
  "appearance": {
    "syntax": "none | auto | textfield | menulist-button | <compat-auto>",
    "media": "all",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Basic User Interface"
    ],
    "initial": "auto",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "perGrammar",
    "status": "experimental",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/appearance"
  },
  "aspect-ratio": {
    "syntax": "auto | <ratio>",
    "media": "all",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Basic User Interface"
    ],
    "initial": "auto",
    "appliesto": "allElementsExceptInlineBoxesAndInternalRubyOrTableBoxes",
    "computed": "asSpecified",
    "order": "perGrammar",
    "status": "experimental",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/aspect-ratio"
  },
  "azimuth": {
    "syntax": "<angle> | [ [ left-side | far-left | left | center-left | center | center-right | right | far-right | right-side ] || behind ] | leftwards | rightwards",
    "media": "aural",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Speech"
    ],
    "initial": "center",
    "appliesto": "allElements",
    "computed": "normalizedAngle",
    "order": "orderOfAppearance",
    "status": "obsolete",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/azimuth"
  },
  "backdrop-filter": {
    "syntax": "none | <filter-function-list>",
    "media": "visual",
    "inherited": false,
    "animationType": "filterList",
    "percentages": "no",
    "groups": [
      "Filter Effects"
    ],
    "initial": "none",
    "appliesto": "allElementsSVGContainerElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/backdrop-filter"
  },
  "backface-visibility": {
    "syntax": "visible | hidden",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Transforms"
    ],
    "initial": "visible",
    "appliesto": "transformableElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/backface-visibility"
  },
  "background": {
    "syntax": "[ <bg-layer> , ]* <final-bg-layer>",
    "media": "visual",
    "inherited": false,
    "animationType": [
      "background-color",
      "background-image",
      "background-clip",
      "background-position",
      "background-size",
      "background-repeat",
      "background-attachment"
    ],
    "percentages": [
      "background-position",
      "background-size"
    ],
    "groups": [
      "CSS Backgrounds and Borders"
    ],
    "initial": [
      "background-image",
      "background-position",
      "background-size",
      "background-repeat",
      "background-origin",
      "background-clip",
      "background-attachment",
      "background-color"
    ],
    "appliesto": "allElements",
    "computed": [
      "background-image",
      "background-position",
      "background-size",
      "background-repeat",
      "background-origin",
      "background-clip",
      "background-attachment",
      "background-color"
    ],
    "order": "orderOfAppearance",
    "alsoAppliesTo": [
      "::first-letter",
      "::first-line",
      "::placeholder"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/background"
  },
  "background-attachment": {
    "syntax": "<attachment>#",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Backgrounds and Borders"
    ],
    "initial": "scroll",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter",
      "::first-line",
      "::placeholder"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/background-attachment"
  },
  "background-blend-mode": {
    "syntax": "<blend-mode>#",
    "media": "none",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Compositing and Blending"
    ],
    "initial": "normal",
    "appliesto": "allElementsSVGContainerGraphicsAndGraphicsReferencingElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter",
      "::first-line",
      "::placeholder"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/background-blend-mode"
  },
  "background-clip": {
    "syntax": "<box>#",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Backgrounds and Borders"
    ],
    "initial": "border-box",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter",
      "::first-line",
      "::placeholder"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/background-clip"
  },
  "background-color": {
    "syntax": "<color>",
    "media": "visual",
    "inherited": false,
    "animationType": "color",
    "percentages": "no",
    "groups": [
      "CSS Backgrounds and Borders"
    ],
    "initial": "transparent",
    "appliesto": "allElements",
    "computed": "computedColor",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter",
      "::first-line",
      "::placeholder"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/background-color"
  },
  "background-image": {
    "syntax": "<bg-image>#",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Backgrounds and Borders"
    ],
    "initial": "none",
    "appliesto": "allElements",
    "computed": "asSpecifiedURLsAbsolute",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter",
      "::first-line",
      "::placeholder"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/background-image"
  },
  "background-origin": {
    "syntax": "<box>#",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Backgrounds and Borders"
    ],
    "initial": "padding-box",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter",
      "::first-line",
      "::placeholder"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/background-origin"
  },
  "background-position": {
    "syntax": "<bg-position>#",
    "media": "visual",
    "inherited": false,
    "animationType": "repeatableListOfSimpleListOfLpc",
    "percentages": "referToSizeOfBackgroundPositioningAreaMinusBackgroundImageSize",
    "groups": [
      "CSS Backgrounds and Borders"
    ],
    "initial": "0% 0%",
    "appliesto": "allElements",
    "computed": "listEachItemTwoKeywordsOriginOffsets",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter",
      "::first-line",
      "::placeholder"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/background-position"
  },
  "background-position-x": {
    "syntax": "[ center | [ [ left | right | x-start | x-end ]? <length-percentage>? ]! ]#",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "referToWidthOfBackgroundPositioningAreaMinusBackgroundImageHeight",
    "groups": [
      "CSS Backgrounds and Borders"
    ],
    "initial": "left",
    "appliesto": "allElements",
    "computed": "listEachItemConsistingOfAbsoluteLengthPercentageAndOrigin",
    "order": "uniqueOrder",
    "status": "experimental",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/background-position-x"
  },
  "background-position-y": {
    "syntax": "[ center | [ [ top | bottom | y-start | y-end ]? <length-percentage>? ]! ]#",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "referToHeightOfBackgroundPositioningAreaMinusBackgroundImageHeight",
    "groups": [
      "CSS Backgrounds and Borders"
    ],
    "initial": "top",
    "appliesto": "allElements",
    "computed": "listEachItemConsistingOfAbsoluteLengthPercentageAndOrigin",
    "order": "uniqueOrder",
    "status": "experimental",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/background-position-y"
  },
  "background-repeat": {
    "syntax": "<repeat-style>#",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Backgrounds and Borders"
    ],
    "initial": "repeat",
    "appliesto": "allElements",
    "computed": "listEachItemHasTwoKeywordsOnePerDimension",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter",
      "::first-line",
      "::placeholder"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/background-repeat"
  },
  "background-size": {
    "syntax": "<bg-size>#",
    "media": "visual",
    "inherited": false,
    "animationType": "repeatableListOfSimpleListOfLpc",
    "percentages": "relativeToBackgroundPositioningArea",
    "groups": [
      "CSS Backgrounds and Borders"
    ],
    "initial": "auto auto",
    "appliesto": "allElements",
    "computed": "asSpecifiedRelativeToAbsoluteLengths",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter",
      "::first-line",
      "::placeholder"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/background-size"
  },
  "block-overflow": {
    "syntax": "clip | ellipsis | <string>",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Overflow"
    ],
    "initial": "clip",
    "appliesto": "blockContainers",
    "computed": "asSpecified",
    "order": "perGrammar",
    "status": "experimental"
  },
  "block-size": {
    "syntax": "<'width'>",
    "media": "visual",
    "inherited": false,
    "animationType": "lpc",
    "percentages": "blockSizeOfContainingBlock",
    "groups": [
      "CSS Logical Properties"
    ],
    "initial": "auto",
    "appliesto": "sameAsWidthAndHeight",
    "computed": "sameAsWidthAndHeight",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/block-size"
  },
  "border": {
    "syntax": "<line-width> || <line-style> || <color>",
    "media": "visual",
    "inherited": false,
    "animationType": [
      "border-color",
      "border-style",
      "border-width"
    ],
    "percentages": "no",
    "groups": [
      "CSS Backgrounds and Borders"
    ],
    "initial": [
      "border-width",
      "border-style",
      "border-color"
    ],
    "appliesto": "allElements",
    "computed": [
      "border-width",
      "border-style",
      "border-color"
    ],
    "order": "orderOfAppearance",
    "alsoAppliesTo": [
      "::first-letter"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/border"
  },
  "border-block": {
    "syntax": "<'border-top-width'> || <'border-top-style'> || <'color'>",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Logical Properties"
    ],
    "initial": [
      "border-top-width",
      "border-top-style",
      "border-top-color"
    ],
    "appliesto": "allElements",
    "computed": [
      "border-top-width",
      "border-top-style",
      "border-top-color"
    ],
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/border-block"
  },
  "border-block-color": {
    "syntax": "<'border-top-color'>{1,2}",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Logical Properties"
    ],
    "initial": "currentcolor",
    "appliesto": "allElements",
    "computed": "computedColor",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/border-block-color"
  },
  "border-block-style": {
    "syntax": "<'border-top-style'>",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Logical Properties"
    ],
    "initial": "none",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/border-block-style"
  },
  "border-block-width": {
    "syntax": "<'border-top-width'>",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "logicalWidthOfContainingBlock",
    "groups": [
      "CSS Logical Properties"
    ],
    "initial": "medium",
    "appliesto": "allElements",
    "computed": "absoluteLengthZeroIfBorderStyleNoneOrHidden",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/border-block-width"
  },
  "border-block-end": {
    "syntax": "<'border-top-width'> || <'border-top-style'> || <'color'>",
    "media": "visual",
    "inherited": false,
    "animationType": [
      "border-block-end-color",
      "border-block-end-style",
      "border-block-end-width"
    ],
    "percentages": "no",
    "groups": [
      "CSS Logical Properties"
    ],
    "initial": [
      "border-top-width",
      "border-top-style",
      "border-top-color"
    ],
    "appliesto": "allElements",
    "computed": [
      "border-top-width",
      "border-top-style",
      "border-top-color"
    ],
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/border-block-end"
  },
  "border-block-end-color": {
    "syntax": "<'border-top-color'>",
    "media": "visual",
    "inherited": false,
    "animationType": "color",
    "percentages": "no",
    "groups": [
      "CSS Logical Properties"
    ],
    "initial": "currentcolor",
    "appliesto": "allElements",
    "computed": "computedColor",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/border-block-end-color"
  },
  "border-block-end-style": {
    "syntax": "<'border-top-style'>",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Logical Properties"
    ],
    "initial": "none",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/border-block-end-style"
  },
  "border-block-end-width": {
    "syntax": "<'border-top-width'>",
    "media": "visual",
    "inherited": false,
    "animationType": "length",
    "percentages": "logicalWidthOfContainingBlock",
    "groups": [
      "CSS Logical Properties"
    ],
    "initial": "medium",
    "appliesto": "allElements",
    "computed": "absoluteLengthZeroIfBorderStyleNoneOrHidden",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/border-block-end-width"
  },
  "border-block-start": {
    "syntax": "<'border-top-width'> || <'border-top-style'> || <'color'>",
    "media": "visual",
    "inherited": false,
    "animationType": [
      "border-block-start-color",
      "border-block-start-style",
      "border-block-start-width"
    ],
    "percentages": "no",
    "groups": [
      "CSS Logical Properties"
    ],
    "initial": [
      "border-width",
      "border-style",
      "color"
    ],
    "appliesto": "allElements",
    "computed": [
      "border-width",
      "border-style",
      "border-block-start-color"
    ],
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/border-block-start"
  },
  "border-block-start-color": {
    "syntax": "<'border-top-color'>",
    "media": "visual",
    "inherited": false,
    "animationType": "color",
    "percentages": "no",
    "groups": [
      "CSS Logical Properties"
    ],
    "initial": "currentcolor",
    "appliesto": "allElements",
    "computed": "computedColor",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/border-block-start-color"
  },
  "border-block-start-style": {
    "syntax": "<'border-top-style'>",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Logical Properties"
    ],
    "initial": "none",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/border-block-start-style"
  },
  "border-block-start-width": {
    "syntax": "<'border-top-width'>",
    "media": "visual",
    "inherited": false,
    "animationType": "length",
    "percentages": "logicalWidthOfContainingBlock",
    "groups": [
      "CSS Logical Properties"
    ],
    "initial": "medium",
    "appliesto": "allElements",
    "computed": "absoluteLengthZeroIfBorderStyleNoneOrHidden",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/border-block-start-width"
  },
  "border-bottom": {
    "syntax": "<line-width> || <line-style> || <color>",
    "media": "visual",
    "inherited": false,
    "animationType": [
      "border-bottom-color",
      "border-bottom-style",
      "border-bottom-width"
    ],
    "percentages": "no",
    "groups": [
      "CSS Backgrounds and Borders"
    ],
    "initial": [
      "border-bottom-width",
      "border-bottom-style",
      "border-bottom-color"
    ],
    "appliesto": "allElements",
    "computed": [
      "border-bottom-width",
      "border-bottom-style",
      "border-bottom-color"
    ],
    "order": "orderOfAppearance",
    "alsoAppliesTo": [
      "::first-letter"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/border-bottom"
  },
  "border-bottom-color": {
    "syntax": "<'border-top-color'>",
    "media": "visual",
    "inherited": false,
    "animationType": "color",
    "percentages": "no",
    "groups": [
      "CSS Backgrounds and Borders"
    ],
    "initial": "currentcolor",
    "appliesto": "allElements",
    "computed": "computedColor",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/border-bottom-color"
  },
  "border-bottom-left-radius": {
    "syntax": "<length-percentage>{1,2}",
    "media": "visual",
    "inherited": false,
    "animationType": "lpc",
    "percentages": "referToDimensionOfBorderBox",
    "groups": [
      "CSS Backgrounds and Borders"
    ],
    "initial": "0",
    "appliesto": "allElementsUAsNotRequiredWhenCollapse",
    "computed": "twoAbsoluteLengthOrPercentages",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/border-bottom-left-radius"
  },
  "border-bottom-right-radius": {
    "syntax": "<length-percentage>{1,2}",
    "media": "visual",
    "inherited": false,
    "animationType": "lpc",
    "percentages": "referToDimensionOfBorderBox",
    "groups": [
      "CSS Backgrounds and Borders"
    ],
    "initial": "0",
    "appliesto": "allElementsUAsNotRequiredWhenCollapse",
    "computed": "twoAbsoluteLengthOrPercentages",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/border-bottom-right-radius"
  },
  "border-bottom-style": {
    "syntax": "<line-style>",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Backgrounds and Borders"
    ],
    "initial": "none",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/border-bottom-style"
  },
  "border-bottom-width": {
    "syntax": "<line-width>",
    "media": "visual",
    "inherited": false,
    "animationType": "length",
    "percentages": "no",
    "groups": [
      "CSS Backgrounds and Borders"
    ],
    "initial": "medium",
    "appliesto": "allElements",
    "computed": "absoluteLengthOr0IfBorderBottomStyleNoneOrHidden",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/border-bottom-width"
  },
  "border-collapse": {
    "syntax": "collapse | separate",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Table"
    ],
    "initial": "separate",
    "appliesto": "tableElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/border-collapse"
  },
  "border-color": {
    "syntax": "<color>{1,4}",
    "media": "visual",
    "inherited": false,
    "animationType": [
      "border-bottom-color",
      "border-left-color",
      "border-right-color",
      "border-top-color"
    ],
    "percentages": "no",
    "groups": [
      "CSS Backgrounds and Borders"
    ],
    "initial": [
      "border-top-color",
      "border-right-color",
      "border-bottom-color",
      "border-left-color"
    ],
    "appliesto": "allElements",
    "computed": [
      "border-bottom-color",
      "border-left-color",
      "border-right-color",
      "border-top-color"
    ],
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/border-color"
  },
  "border-end-end-radius": {
    "syntax": "<length-percentage>{1,2}",
    "media": "visual",
    "inherited": false,
    "animationType": "lpc",
    "percentages": "referToDimensionOfBorderBox",
    "groups": [
      "CSS Logical Properties"
    ],
    "initial": "0",
    "appliesto": "allElementsUAsNotRequiredWhenCollapse",
    "computed": "twoAbsoluteLengthOrPercentages",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/border-end-end-radius"
  },
  "border-end-start-radius": {
    "syntax": "<length-percentage>{1,2}",
    "media": "visual",
    "inherited": false,
    "animationType": "lpc",
    "percentages": "referToDimensionOfBorderBox",
    "groups": [
      "CSS Logical Properties"
    ],
    "initial": "0",
    "appliesto": "allElementsUAsNotRequiredWhenCollapse",
    "computed": "twoAbsoluteLengthOrPercentages",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/border-end-start-radius"
  },
  "border-image": {
    "syntax": "<'border-image-source'> || <'border-image-slice'> [ / <'border-image-width'> | / <'border-image-width'>? / <'border-image-outset'> ]? || <'border-image-repeat'>",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": [
      "border-image-slice",
      "border-image-width"
    ],
    "groups": [
      "CSS Backgrounds and Borders"
    ],
    "initial": [
      "border-image-source",
      "border-image-slice",
      "border-image-width",
      "border-image-outset",
      "border-image-repeat"
    ],
    "appliesto": "allElementsExceptTableElementsWhenCollapse",
    "computed": [
      "border-image-outset",
      "border-image-repeat",
      "border-image-slice",
      "border-image-source",
      "border-image-width"
    ],
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/border-image"
  },
  "border-image-outset": {
    "syntax": "[ <length> | <number> ]{1,4}",
    "media": "visual",
    "inherited": false,
    "animationType": "byComputedValueType",
    "percentages": "no",
    "groups": [
      "CSS Backgrounds and Borders"
    ],
    "initial": "0",
    "appliesto": "allElementsExceptTableElementsWhenCollapse",
    "computed": "asSpecifiedRelativeToAbsoluteLengths",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/border-image-outset"
  },
  "border-image-repeat": {
    "syntax": "[ stretch | repeat | round | space ]{1,2}",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Backgrounds and Borders"
    ],
    "initial": "stretch",
    "appliesto": "allElementsExceptTableElementsWhenCollapse",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/border-image-repeat"
  },
  "border-image-slice": {
    "syntax": "<number-percentage>{1,4} && fill?",
    "media": "visual",
    "inherited": false,
    "animationType": "byComputedValueType",
    "percentages": "referToSizeOfBorderImage",
    "groups": [
      "CSS Backgrounds and Borders"
    ],
    "initial": "100%",
    "appliesto": "allElementsExceptTableElementsWhenCollapse",
    "computed": "oneToFourPercentagesOrAbsoluteLengthsPlusFill",
    "order": "percentagesOrLengthsFollowedByFill",
    "alsoAppliesTo": [
      "::first-letter"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/border-image-slice"
  },
  "border-image-source": {
    "syntax": "none | <image>",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Backgrounds and Borders"
    ],
    "initial": "none",
    "appliesto": "allElementsExceptTableElementsWhenCollapse",
    "computed": "noneOrImageWithAbsoluteURI",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/border-image-source"
  },
  "border-image-width": {
    "syntax": "[ <length-percentage> | <number> | auto ]{1,4}",
    "media": "visual",
    "inherited": false,
    "animationType": "byComputedValueType",
    "percentages": "referToWidthOrHeightOfBorderImageArea",
    "groups": [
      "CSS Backgrounds and Borders"
    ],
    "initial": "1",
    "appliesto": "allElementsExceptTableElementsWhenCollapse",
    "computed": "asSpecifiedRelativeToAbsoluteLengths",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/border-image-width"
  },
  "border-inline": {
    "syntax": "<'border-top-width'> || <'border-top-style'> || <'color'>",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Logical Properties"
    ],
    "initial": [
      "border-top-width",
      "border-top-style",
      "border-top-color"
    ],
    "appliesto": "allElements",
    "computed": [
      "border-top-width",
      "border-top-style",
      "border-top-color"
    ],
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/border-inline"
  },
  "border-inline-end": {
    "syntax": "<'border-top-width'> || <'border-top-style'> || <'color'>",
    "media": "visual",
    "inherited": false,
    "animationType": [
      "border-inline-end-color",
      "border-inline-end-style",
      "border-inline-end-width"
    ],
    "percentages": "no",
    "groups": [
      "CSS Logical Properties"
    ],
    "initial": [
      "border-width",
      "border-style",
      "color"
    ],
    "appliesto": "allElements",
    "computed": [
      "border-width",
      "border-style",
      "border-inline-end-color"
    ],
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/border-inline-end"
  },
  "border-inline-color": {
    "syntax": "<'border-top-color'>{1,2}",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Logical Properties"
    ],
    "initial": "currentcolor",
    "appliesto": "allElements",
    "computed": "computedColor",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/border-inline-color"
  },
  "border-inline-style": {
    "syntax": "<'border-top-style'>",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Logical Properties"
    ],
    "initial": "none",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/border-inline-style"
  },
  "border-inline-width": {
    "syntax": "<'border-top-width'>",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "logicalWidthOfContainingBlock",
    "groups": [
      "CSS Logical Properties"
    ],
    "initial": "medium",
    "appliesto": "allElements",
    "computed": "absoluteLengthZeroIfBorderStyleNoneOrHidden",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/border-inline-width"
  },
  "border-inline-end-color": {
    "syntax": "<'border-top-color'>",
    "media": "visual",
    "inherited": false,
    "animationType": "color",
    "percentages": "no",
    "groups": [
      "CSS Logical Properties"
    ],
    "initial": "currentcolor",
    "appliesto": "allElements",
    "computed": "computedColor",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/border-inline-end-color"
  },
  "border-inline-end-style": {
    "syntax": "<'border-top-style'>",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Logical Properties"
    ],
    "initial": "none",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/border-inline-end-style"
  },
  "border-inline-end-width": {
    "syntax": "<'border-top-width'>",
    "media": "visual",
    "inherited": false,
    "animationType": "length",
    "percentages": "logicalWidthOfContainingBlock",
    "groups": [
      "CSS Logical Properties"
    ],
    "initial": "medium",
    "appliesto": "allElements",
    "computed": "absoluteLengthZeroIfBorderStyleNoneOrHidden",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/border-inline-end-width"
  },
  "border-inline-start": {
    "syntax": "<'border-top-width'> || <'border-top-style'> || <'color'>",
    "media": "visual",
    "inherited": false,
    "animationType": [
      "border-inline-start-color",
      "border-inline-start-style",
      "border-inline-start-width"
    ],
    "percentages": "no",
    "groups": [
      "CSS Logical Properties"
    ],
    "initial": [
      "border-width",
      "border-style",
      "color"
    ],
    "appliesto": "allElements",
    "computed": [
      "border-width",
      "border-style",
      "border-inline-start-color"
    ],
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/border-inline-start"
  },
  "border-inline-start-color": {
    "syntax": "<'border-top-color'>",
    "media": "visual",
    "inherited": false,
    "animationType": "color",
    "percentages": "no",
    "groups": [
      "CSS Logical Properties"
    ],
    "initial": "currentcolor",
    "appliesto": "allElements",
    "computed": "computedColor",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/border-inline-start-color"
  },
  "border-inline-start-style": {
    "syntax": "<'border-top-style'>",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Logical Properties"
    ],
    "initial": "none",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/border-inline-start-style"
  },
  "border-inline-start-width": {
    "syntax": "<'border-top-width'>",
    "media": "visual",
    "inherited": false,
    "animationType": "length",
    "percentages": "logicalWidthOfContainingBlock",
    "groups": [
      "CSS Logical Properties"
    ],
    "initial": "medium",
    "appliesto": "allElements",
    "computed": "absoluteLengthZeroIfBorderStyleNoneOrHidden",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/border-inline-start-width"
  },
  "border-left": {
    "syntax": "<line-width> || <line-style> || <color>",
    "media": "visual",
    "inherited": false,
    "animationType": [
      "border-left-color",
      "border-left-style",
      "border-left-width"
    ],
    "percentages": "no",
    "groups": [
      "CSS Backgrounds and Borders"
    ],
    "initial": [
      "border-left-width",
      "border-left-style",
      "border-left-color"
    ],
    "appliesto": "allElements",
    "computed": [
      "border-left-width",
      "border-left-style",
      "border-left-color"
    ],
    "order": "orderOfAppearance",
    "alsoAppliesTo": [
      "::first-letter"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/border-left"
  },
  "border-left-color": {
    "syntax": "<color>",
    "media": "visual",
    "inherited": false,
    "animationType": "color",
    "percentages": "no",
    "groups": [
      "CSS Backgrounds and Borders"
    ],
    "initial": "currentcolor",
    "appliesto": "allElements",
    "computed": "computedColor",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/border-left-color"
  },
  "border-left-style": {
    "syntax": "<line-style>",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Backgrounds and Borders"
    ],
    "initial": "none",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/border-left-style"
  },
  "border-left-width": {
    "syntax": "<line-width>",
    "media": "visual",
    "inherited": false,
    "animationType": "length",
    "percentages": "no",
    "groups": [
      "CSS Backgrounds and Borders"
    ],
    "initial": "medium",
    "appliesto": "allElements",
    "computed": "absoluteLengthOr0IfBorderLeftStyleNoneOrHidden",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/border-left-width"
  },
  "border-radius": {
    "syntax": "<length-percentage>{1,4} [ / <length-percentage>{1,4} ]?",
    "media": "visual",
    "inherited": false,
    "animationType": [
      "border-top-left-radius",
      "border-top-right-radius",
      "border-bottom-right-radius",
      "border-bottom-left-radius"
    ],
    "percentages": "referToDimensionOfBorderBox",
    "groups": [
      "CSS Backgrounds and Borders"
    ],
    "initial": [
      "border-top-left-radius",
      "border-top-right-radius",
      "border-bottom-right-radius",
      "border-bottom-left-radius"
    ],
    "appliesto": "allElementsUAsNotRequiredWhenCollapse",
    "computed": [
      "border-bottom-left-radius",
      "border-bottom-right-radius",
      "border-top-left-radius",
      "border-top-right-radius"
    ],
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/border-radius"
  },
  "border-right": {
    "syntax": "<line-width> || <line-style> || <color>",
    "media": "visual",
    "inherited": false,
    "animationType": [
      "border-right-color",
      "border-right-style",
      "border-right-width"
    ],
    "percentages": "no",
    "groups": [
      "CSS Backgrounds and Borders"
    ],
    "initial": [
      "border-right-width",
      "border-right-style",
      "border-right-color"
    ],
    "appliesto": "allElements",
    "computed": [
      "border-right-width",
      "border-right-style",
      "border-right-color"
    ],
    "order": "orderOfAppearance",
    "alsoAppliesTo": [
      "::first-letter"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/border-right"
  },
  "border-right-color": {
    "syntax": "<color>",
    "media": "visual",
    "inherited": false,
    "animationType": "color",
    "percentages": "no",
    "groups": [
      "CSS Backgrounds and Borders"
    ],
    "initial": "currentcolor",
    "appliesto": "allElements",
    "computed": "computedColor",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/border-right-color"
  },
  "border-right-style": {
    "syntax": "<line-style>",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Backgrounds and Borders"
    ],
    "initial": "none",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/border-right-style"
  },
  "border-right-width": {
    "syntax": "<line-width>",
    "media": "visual",
    "inherited": false,
    "animationType": "length",
    "percentages": "no",
    "groups": [
      "CSS Backgrounds and Borders"
    ],
    "initial": "medium",
    "appliesto": "allElements",
    "computed": "absoluteLengthOr0IfBorderRightStyleNoneOrHidden",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/border-right-width"
  },
  "border-spacing": {
    "syntax": "<length> <length>?",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Table"
    ],
    "initial": "0",
    "appliesto": "tableElements",
    "computed": "twoAbsoluteLengths",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/border-spacing"
  },
  "border-start-end-radius": {
    "syntax": "<length-percentage>{1,2}",
    "media": "visual",
    "inherited": false,
    "animationType": "lpc",
    "percentages": "referToDimensionOfBorderBox",
    "groups": [
      "CSS Logical Properties"
    ],
    "initial": "0",
    "appliesto": "allElementsUAsNotRequiredWhenCollapse",
    "computed": "twoAbsoluteLengthOrPercentages",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/border-start-end-radius"
  },
  "border-start-start-radius": {
    "syntax": "<length-percentage>{1,2}",
    "media": "visual",
    "inherited": false,
    "animationType": "lpc",
    "percentages": "referToDimensionOfBorderBox",
    "groups": [
      "CSS Logical Properties"
    ],
    "initial": "0",
    "appliesto": "allElementsUAsNotRequiredWhenCollapse",
    "computed": "twoAbsoluteLengthOrPercentages",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/border-start-start-radius"
  },
  "border-style": {
    "syntax": "<line-style>{1,4}",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Backgrounds and Borders"
    ],
    "initial": [
      "border-top-style",
      "border-right-style",
      "border-bottom-style",
      "border-left-style"
    ],
    "appliesto": "allElements",
    "computed": [
      "border-bottom-style",
      "border-left-style",
      "border-right-style",
      "border-top-style"
    ],
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/border-style"
  },
  "border-top": {
    "syntax": "<line-width> || <line-style> || <color>",
    "media": "visual",
    "inherited": false,
    "animationType": [
      "border-top-color",
      "border-top-style",
      "border-top-width"
    ],
    "percentages": "no",
    "groups": [
      "CSS Backgrounds and Borders"
    ],
    "initial": [
      "border-top-width",
      "border-top-style",
      "border-top-color"
    ],
    "appliesto": "allElements",
    "computed": [
      "border-top-width",
      "border-top-style",
      "border-top-color"
    ],
    "order": "orderOfAppearance",
    "alsoAppliesTo": [
      "::first-letter"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/border-top"
  },
  "border-top-color": {
    "syntax": "<color>",
    "media": "visual",
    "inherited": false,
    "animationType": "color",
    "percentages": "no",
    "groups": [
      "CSS Backgrounds and Borders"
    ],
    "initial": "currentcolor",
    "appliesto": "allElements",
    "computed": "computedColor",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/border-top-color"
  },
  "border-top-left-radius": {
    "syntax": "<length-percentage>{1,2}",
    "media": "visual",
    "inherited": false,
    "animationType": "lpc",
    "percentages": "referToDimensionOfBorderBox",
    "groups": [
      "CSS Backgrounds and Borders"
    ],
    "initial": "0",
    "appliesto": "allElementsUAsNotRequiredWhenCollapse",
    "computed": "twoAbsoluteLengthOrPercentages",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/border-top-left-radius"
  },
  "border-top-right-radius": {
    "syntax": "<length-percentage>{1,2}",
    "media": "visual",
    "inherited": false,
    "animationType": "lpc",
    "percentages": "referToDimensionOfBorderBox",
    "groups": [
      "CSS Backgrounds and Borders"
    ],
    "initial": "0",
    "appliesto": "allElementsUAsNotRequiredWhenCollapse",
    "computed": "twoAbsoluteLengthOrPercentages",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/border-top-right-radius"
  },
  "border-top-style": {
    "syntax": "<line-style>",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Backgrounds and Borders"
    ],
    "initial": "none",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/border-top-style"
  },
  "border-top-width": {
    "syntax": "<line-width>",
    "media": "visual",
    "inherited": false,
    "animationType": "length",
    "percentages": "no",
    "groups": [
      "CSS Backgrounds and Borders"
    ],
    "initial": "medium",
    "appliesto": "allElements",
    "computed": "absoluteLengthOr0IfBorderTopStyleNoneOrHidden",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/border-top-width"
  },
  "border-width": {
    "syntax": "<line-width>{1,4}",
    "media": "visual",
    "inherited": false,
    "animationType": [
      "border-bottom-width",
      "border-left-width",
      "border-right-width",
      "border-top-width"
    ],
    "percentages": "no",
    "groups": [
      "CSS Backgrounds and Borders"
    ],
    "initial": [
      "border-top-width",
      "border-right-width",
      "border-bottom-width",
      "border-left-width"
    ],
    "appliesto": "allElements",
    "computed": [
      "border-bottom-width",
      "border-left-width",
      "border-right-width",
      "border-top-width"
    ],
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/border-width"
  },
  "bottom": {
    "syntax": "<length> | <percentage> | auto",
    "media": "visual",
    "inherited": false,
    "animationType": "lpc",
    "percentages": "referToContainingBlockHeight",
    "groups": [
      "CSS Positioning"
    ],
    "initial": "auto",
    "appliesto": "positionedElements",
    "computed": "lengthAbsolutePercentageAsSpecifiedOtherwiseAuto",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/bottom"
  },
  "box-align": {
    "syntax": "start | center | end | baseline | stretch",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Mozilla Extensions",
      "WebKit Extensions"
    ],
    "initial": "stretch",
    "appliesto": "elementsWithDisplayBoxOrInlineBox",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/box-align"
  },
  "box-decoration-break": {
    "syntax": "slice | clone",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Fragmentation"
    ],
    "initial": "slice",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/box-decoration-break"
  },
  "box-direction": {
    "syntax": "normal | reverse | inherit",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Mozilla Extensions",
      "WebKit Extensions"
    ],
    "initial": "normal",
    "appliesto": "elementsWithDisplayBoxOrInlineBox",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/box-direction"
  },
  "box-flex": {
    "syntax": "<number>",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Mozilla Extensions",
      "WebKit Extensions"
    ],
    "initial": "0",
    "appliesto": "directChildrenOfElementsWithDisplayMozBoxMozInlineBox",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/box-flex"
  },
  "box-flex-group": {
    "syntax": "<integer>",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Mozilla Extensions",
      "WebKit Extensions"
    ],
    "initial": "1",
    "appliesto": "inFlowChildrenOfBoxElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/box-flex-group"
  },
  "box-lines": {
    "syntax": "single | multiple",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Mozilla Extensions",
      "WebKit Extensions"
    ],
    "initial": "single",
    "appliesto": "boxElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/box-lines"
  },
  "box-ordinal-group": {
    "syntax": "<integer>",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Mozilla Extensions",
      "WebKit Extensions"
    ],
    "initial": "1",
    "appliesto": "childrenOfBoxElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/box-ordinal-group"
  },
  "box-orient": {
    "syntax": "horizontal | vertical | inline-axis | block-axis | inherit",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Mozilla Extensions",
      "WebKit Extensions"
    ],
    "initial": "inlineAxisHorizontalInXUL",
    "appliesto": "elementsWithDisplayBoxOrInlineBox",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/box-orient"
  },
  "box-pack": {
    "syntax": "start | center | end | justify",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Mozilla Extensions",
      "WebKit Extensions"
    ],
    "initial": "start",
    "appliesto": "elementsWithDisplayMozBoxMozInlineBox",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/box-pack"
  },
  "box-shadow": {
    "syntax": "none | <shadow>#",
    "media": "visual",
    "inherited": false,
    "animationType": "shadowList",
    "percentages": "no",
    "groups": [
      "CSS Backgrounds and Borders"
    ],
    "initial": "none",
    "appliesto": "allElements",
    "computed": "absoluteLengthsSpecifiedColorAsSpecified",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/box-shadow"
  },
  "box-sizing": {
    "syntax": "content-box | border-box",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Basic User Interface"
    ],
    "initial": "content-box",
    "appliesto": "allElementsAcceptingWidthOrHeight",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/box-sizing"
  },
  "break-after": {
    "syntax": "auto | avoid | always | all | avoid-page | page | left | right | recto | verso | avoid-column | column | avoid-region | region",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Fragmentation"
    ],
    "initial": "auto",
    "appliesto": "blockLevelElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/break-after"
  },
  "break-before": {
    "syntax": "auto | avoid | always | all | avoid-page | page | left | right | recto | verso | avoid-column | column | avoid-region | region",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Fragmentation"
    ],
    "initial": "auto",
    "appliesto": "blockLevelElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/break-before"
  },
  "break-inside": {
    "syntax": "auto | avoid | avoid-page | avoid-column | avoid-region",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Fragmentation"
    ],
    "initial": "auto",
    "appliesto": "blockLevelElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/break-inside"
  },
  "caption-side": {
    "syntax": "top | bottom | block-start | block-end | inline-start | inline-end",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Table"
    ],
    "initial": "top",
    "appliesto": "tableCaptionElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/caption-side"
  },
  "caret-color": {
    "syntax": "auto | <color>",
    "media": "interactive",
    "inherited": true,
    "animationType": "color",
    "percentages": "no",
    "groups": [
      "CSS Basic User Interface"
    ],
    "initial": "auto",
    "appliesto": "allElements",
    "computed": "asAutoOrColor",
    "order": "perGrammar",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/caret-color"
  },
  "clear": {
    "syntax": "none | left | right | both | inline-start | inline-end",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Positioning"
    ],
    "initial": "none",
    "appliesto": "blockLevelElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/clear"
  },
  "clip": {
    "syntax": "<shape> | auto",
    "media": "visual",
    "inherited": false,
    "animationType": "rectangle",
    "percentages": "no",
    "groups": [
      "CSS Masking"
    ],
    "initial": "auto",
    "appliesto": "absolutelyPositionedElements",
    "computed": "autoOrRectangle",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/clip"
  },
  "clip-path": {
    "syntax": "<clip-source> | [ <basic-shape> || <geometry-box> ] | none",
    "media": "visual",
    "inherited": false,
    "animationType": "basicShapeOtherwiseNo",
    "percentages": "referToReferenceBoxWhenSpecifiedOtherwiseBorderBox",
    "groups": [
      "CSS Masking"
    ],
    "initial": "none",
    "appliesto": "allElementsSVGContainerElements",
    "computed": "asSpecifiedURLsAbsolute",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/clip-path"
  },
  "color": {
    "syntax": "<color>",
    "media": "visual",
    "inherited": true,
    "animationType": "color",
    "percentages": "no",
    "groups": [
      "CSS Color"
    ],
    "initial": "variesFromBrowserToBrowser",
    "appliesto": "allElements",
    "computed": "translucentValuesRGBAOtherwiseRGB",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter",
      "::first-line",
      "::placeholder"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/color"
  },
  "color-adjust": {
    "syntax": "economy | exact",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Color"
    ],
    "initial": "economy",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "perGrammar",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/color-adjust"
  },
  "column-count": {
    "syntax": "<integer> | auto",
    "media": "visual",
    "inherited": false,
    "animationType": "integer",
    "percentages": "no",
    "groups": [
      "CSS Columns"
    ],
    "initial": "auto",
    "appliesto": "blockContainersExceptTableWrappers",
    "computed": "asSpecified",
    "order": "perGrammar",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/column-count"
  },
  "column-fill": {
    "syntax": "auto | balance | balance-all",
    "media": "visualInContinuousMediaNoEffectInOverflowColumns",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Columns"
    ],
    "initial": "balance",
    "appliesto": "multicolElements",
    "computed": "asSpecified",
    "order": "perGrammar",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/column-fill"
  },
  "column-gap": {
    "syntax": "normal | <length-percentage>",
    "media": "visual",
    "inherited": false,
    "animationType": "lpc",
    "percentages": "referToDimensionOfContentArea",
    "groups": [
      "CSS Box Alignment"
    ],
    "initial": "normal",
    "appliesto": "multiColumnElementsFlexContainersGridContainers",
    "computed": "asSpecifiedWithLengthsAbsoluteAndNormalComputingToZeroExceptMultiColumn",
    "order": "perGrammar",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/column-gap"
  },
  "column-rule": {
    "syntax": "<'column-rule-width'> || <'column-rule-style'> || <'column-rule-color'>",
    "media": "visual",
    "inherited": false,
    "animationType": [
      "column-rule-color",
      "column-rule-style",
      "column-rule-width"
    ],
    "percentages": "no",
    "groups": [
      "CSS Columns"
    ],
    "initial": [
      "column-rule-width",
      "column-rule-style",
      "column-rule-color"
    ],
    "appliesto": "multicolElements",
    "computed": [
      "column-rule-color",
      "column-rule-style",
      "column-rule-width"
    ],
    "order": "perGrammar",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/column-rule"
  },
  "column-rule-color": {
    "syntax": "<color>",
    "media": "visual",
    "inherited": false,
    "animationType": "color",
    "percentages": "no",
    "groups": [
      "CSS Columns"
    ],
    "initial": "currentcolor",
    "appliesto": "multicolElements",
    "computed": "computedColor",
    "order": "perGrammar",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/column-rule-color"
  },
  "column-rule-style": {
    "syntax": "<'border-style'>",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Columns"
    ],
    "initial": "none",
    "appliesto": "multicolElements",
    "computed": "asSpecified",
    "order": "perGrammar",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/column-rule-style"
  },
  "column-rule-width": {
    "syntax": "<'border-width'>",
    "media": "visual",
    "inherited": false,
    "animationType": "length",
    "percentages": "no",
    "groups": [
      "CSS Columns"
    ],
    "initial": "medium",
    "appliesto": "multicolElements",
    "computed": "absoluteLength0IfColumnRuleStyleNoneOrHidden",
    "order": "perGrammar",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/column-rule-width"
  },
  "column-span": {
    "syntax": "none | all",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Columns"
    ],
    "initial": "none",
    "appliesto": "inFlowBlockLevelElements",
    "computed": "asSpecified",
    "order": "perGrammar",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/column-span"
  },
  "column-width": {
    "syntax": "<length> | auto",
    "media": "visual",
    "inherited": false,
    "animationType": "length",
    "percentages": "no",
    "groups": [
      "CSS Columns"
    ],
    "initial": "auto",
    "appliesto": "blockContainersExceptTableWrappers",
    "computed": "absoluteLengthZeroOrLarger",
    "order": "perGrammar",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/column-width"
  },
  "columns": {
    "syntax": "<'column-width'> || <'column-count'>",
    "media": "visual",
    "inherited": false,
    "animationType": [
      "column-width",
      "column-count"
    ],
    "percentages": "no",
    "groups": [
      "CSS Columns"
    ],
    "initial": [
      "column-width",
      "column-count"
    ],
    "appliesto": "blockContainersExceptTableWrappers",
    "computed": [
      "column-width",
      "column-count"
    ],
    "order": "perGrammar",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/columns"
  },
  "contain": {
    "syntax": "none | strict | content | [ size || layout || style || paint ]",
    "media": "all",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Containment"
    ],
    "initial": "none",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "perGrammar",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/contain"
  },
  "content": {
    "syntax": "normal | none | [ <content-replacement> | <content-list> ] [/ <string> ]?",
    "media": "all",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Generated Content"
    ],
    "initial": "normal",
    "appliesto": "beforeAndAfterPseudos",
    "computed": "normalOnElementsForPseudosNoneAbsoluteURIStringOrAsSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/content"
  },
  "counter-increment": {
    "syntax": "[ <custom-ident> <integer>? ]+ | none",
    "media": "all",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Counter Styles"
    ],
    "initial": "none",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/counter-increment"
  },
  "counter-reset": {
    "syntax": "[ <custom-ident> <integer>? ]+ | none",
    "media": "all",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Counter Styles"
    ],
    "initial": "none",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/counter-reset"
  },
  "counter-set": {
    "syntax": "[ <custom-ident> <integer>? ]+ | none",
    "media": "all",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Counter Styles"
    ],
    "initial": "none",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/counter-set"
  },
  "cursor": {
    "syntax": "[ [ <url> [ <x> <y> ]? , ]* [ auto | default | none | context-menu | help | pointer | progress | wait | cell | crosshair | text | vertical-text | alias | copy | move | no-drop | not-allowed | e-resize | n-resize | ne-resize | nw-resize | s-resize | se-resize | sw-resize | w-resize | ew-resize | ns-resize | nesw-resize | nwse-resize | col-resize | row-resize | all-scroll | zoom-in | zoom-out | grab | grabbing ] ]",
    "media": [
      "visual",
      "interactive"
    ],
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Basic User Interface"
    ],
    "initial": "auto",
    "appliesto": "allElements",
    "computed": "asSpecifiedURLsAbsolute",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/cursor"
  },
  "direction": {
    "syntax": "ltr | rtl",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Writing Modes"
    ],
    "initial": "ltr",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/direction"
  },
  "display": {
    "syntax": "[ <display-outside> || <display-inside> ] | <display-listitem> | <display-internal> | <display-box> | <display-legacy>",
    "media": "all",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Display"
    ],
    "initial": "inline",
    "appliesto": "allElements",
    "computed": "asSpecifiedExceptPositionedFloatingAndRootElementsKeywordMaybeDifferent",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/display"
  },
  "empty-cells": {
    "syntax": "show | hide",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Table"
    ],
    "initial": "show",
    "appliesto": "tableCellElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/empty-cells"
  },
  "filter": {
    "syntax": "none | <filter-function-list>",
    "media": "visual",
    "inherited": false,
    "animationType": "filterList",
    "percentages": "no",
    "groups": [
      "Filter Effects"
    ],
    "initial": "none",
    "appliesto": "allElementsSVGContainerElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/filter"
  },
  "flex": {
    "syntax": "none | [ <'flex-grow'> <'flex-shrink'>? || <'flex-basis'> ]",
    "media": "visual",
    "inherited": false,
    "animationType": [
      "flex-grow",
      "flex-shrink",
      "flex-basis"
    ],
    "percentages": "no",
    "groups": [
      "CSS Flexible Box Layout"
    ],
    "initial": [
      "flex-grow",
      "flex-shrink",
      "flex-basis"
    ],
    "appliesto": "flexItemsAndInFlowPseudos",
    "computed": [
      "flex-grow",
      "flex-shrink",
      "flex-basis"
    ],
    "order": "orderOfAppearance",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/flex"
  },
  "flex-basis": {
    "syntax": "content | <'width'>",
    "media": "visual",
    "inherited": false,
    "animationType": "lpc",
    "percentages": "referToFlexContainersInnerMainSize",
    "groups": [
      "CSS Flexible Box Layout"
    ],
    "initial": "auto",
    "appliesto": "flexItemsAndInFlowPseudos",
    "computed": "asSpecifiedRelativeToAbsoluteLengths",
    "order": "lengthOrPercentageBeforeKeywordIfBothPresent",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/flex-basis"
  },
  "flex-direction": {
    "syntax": "row | row-reverse | column | column-reverse",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Flexible Box Layout"
    ],
    "initial": "row",
    "appliesto": "flexContainers",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/flex-direction"
  },
  "flex-flow": {
    "syntax": "<'flex-direction'> || <'flex-wrap'>",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Flexible Box Layout"
    ],
    "initial": [
      "flex-direction",
      "flex-wrap"
    ],
    "appliesto": "flexContainers",
    "computed": [
      "flex-direction",
      "flex-wrap"
    ],
    "order": "orderOfAppearance",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/flex-flow"
  },
  "flex-grow": {
    "syntax": "<number>",
    "media": "visual",
    "inherited": false,
    "animationType": "number",
    "percentages": "no",
    "groups": [
      "CSS Flexible Box Layout"
    ],
    "initial": "0",
    "appliesto": "flexItemsAndInFlowPseudos",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/flex-grow"
  },
  "flex-shrink": {
    "syntax": "<number>",
    "media": "visual",
    "inherited": false,
    "animationType": "number",
    "percentages": "no",
    "groups": [
      "CSS Flexible Box Layout"
    ],
    "initial": "1",
    "appliesto": "flexItemsAndInFlowPseudos",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/flex-shrink"
  },
  "flex-wrap": {
    "syntax": "nowrap | wrap | wrap-reverse",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Flexible Box Layout"
    ],
    "initial": "nowrap",
    "appliesto": "flexContainers",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/flex-wrap"
  },
  "float": {
    "syntax": "left | right | none | inline-start | inline-end",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Positioning"
    ],
    "initial": "none",
    "appliesto": "allElementsNoEffectIfDisplayNone",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/float"
  },
  "font": {
    "syntax": "[ [ <'font-style'> || <font-variant-css21> || <'font-weight'> || <'font-stretch'> ]? <'font-size'> [ / <'line-height'> ]? <'font-family'> ] | caption | icon | menu | message-box | small-caption | status-bar",
    "media": "visual",
    "inherited": true,
    "animationType": [
      "font-style",
      "font-variant",
      "font-weight",
      "font-stretch",
      "font-size",
      "line-height",
      "font-family"
    ],
    "percentages": [
      "font-size",
      "line-height"
    ],
    "groups": [
      "CSS Fonts"
    ],
    "initial": [
      "font-style",
      "font-variant",
      "font-weight",
      "font-stretch",
      "font-size",
      "line-height",
      "font-family"
    ],
    "appliesto": "allElements",
    "computed": [
      "font-style",
      "font-variant",
      "font-weight",
      "font-stretch",
      "font-size",
      "line-height",
      "font-family"
    ],
    "order": "orderOfAppearance",
    "alsoAppliesTo": [
      "::first-letter",
      "::first-line",
      "::placeholder"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/font"
  },
  "font-family": {
    "syntax": "[ <family-name> | <generic-family> ]#",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Fonts"
    ],
    "initial": "dependsOnUserAgent",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter",
      "::first-line",
      "::placeholder"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/font-family"
  },
  "font-feature-settings": {
    "syntax": "normal | <feature-tag-value>#",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Fonts"
    ],
    "initial": "normal",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter",
      "::first-line",
      "::placeholder"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/font-feature-settings"
  },
  "font-kerning": {
    "syntax": "auto | normal | none",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Fonts"
    ],
    "initial": "auto",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter",
      "::first-line",
      "::placeholder"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/font-kerning"
  },
  "font-language-override": {
    "syntax": "normal | <string>",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Fonts"
    ],
    "initial": "normal",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter",
      "::first-line",
      "::placeholder"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/font-language-override"
  },
  "font-optical-sizing": {
    "syntax": "auto | none",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Fonts"
    ],
    "initial": "auto",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "perGrammar",
    "alsoAppliesTo": [
      "::first-letter",
      "::first-line",
      "::placeholder"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/font-optical-sizing"
  },
  "font-variation-settings": {
    "syntax": "normal | [ <string> <number> ]#",
    "media": "visual",
    "inherited": true,
    "animationType": "transform",
    "percentages": "no",
    "groups": [
      "CSS Fonts"
    ],
    "initial": "normal",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "perGrammar",
    "alsoAppliesTo": [
      "::first-letter",
      "::first-line",
      "::placeholder"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/font-variation-settings"
  },
  "font-size": {
    "syntax": "<absolute-size> | <relative-size> | <length-percentage>",
    "media": "visual",
    "inherited": true,
    "animationType": "length",
    "percentages": "referToParentElementsFontSize",
    "groups": [
      "CSS Fonts"
    ],
    "initial": "medium",
    "appliesto": "allElements",
    "computed": "asSpecifiedRelativeToAbsoluteLengths",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter",
      "::first-line",
      "::placeholder"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/font-size"
  },
  "font-size-adjust": {
    "syntax": "none | <number>",
    "media": "visual",
    "inherited": true,
    "animationType": "number",
    "percentages": "no",
    "groups": [
      "CSS Fonts"
    ],
    "initial": "none",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter",
      "::first-line",
      "::placeholder"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/font-size-adjust"
  },
  "font-smooth": {
    "syntax": "auto | never | always | <absolute-size> | <length>",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Fonts"
    ],
    "initial": "auto",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/font-smooth"
  },
  "font-stretch": {
    "syntax": "<font-stretch-absolute>",
    "media": "visual",
    "inherited": true,
    "animationType": "fontStretch",
    "percentages": "no",
    "groups": [
      "CSS Fonts"
    ],
    "initial": "normal",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter",
      "::first-line",
      "::placeholder"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/font-stretch"
  },
  "font-style": {
    "syntax": "normal | italic | oblique <angle>?",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Fonts"
    ],
    "initial": "normal",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter",
      "::first-line",
      "::placeholder"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/font-style"
  },
  "font-synthesis": {
    "syntax": "none | [ weight || style ]",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Fonts"
    ],
    "initial": "weight style",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "orderOfAppearance",
    "alsoAppliesTo": [
      "::first-letter",
      "::first-line",
      "::placeholder"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/font-synthesis"
  },
  "font-variant": {
    "syntax": "normal | none | [ <common-lig-values> || <discretionary-lig-values> || <historical-lig-values> || <contextual-alt-values> || stylistic( <feature-value-name> ) || historical-forms || styleset( <feature-value-name># ) || character-variant( <feature-value-name># ) || swash( <feature-value-name> ) || ornaments( <feature-value-name> ) || annotation( <feature-value-name> ) || [ small-caps | all-small-caps | petite-caps | all-petite-caps | unicase | titling-caps ] || <numeric-figure-values> || <numeric-spacing-values> || <numeric-fraction-values> || ordinal || slashed-zero || <east-asian-variant-values> || <east-asian-width-values> || ruby ]",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Fonts"
    ],
    "initial": "normal",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter",
      "::first-line",
      "::placeholder"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/font-variant"
  },
  "font-variant-alternates": {
    "syntax": "normal | [ stylistic( <feature-value-name> ) || historical-forms || styleset( <feature-value-name># ) || character-variant( <feature-value-name># ) || swash( <feature-value-name> ) || ornaments( <feature-value-name> ) || annotation( <feature-value-name> ) ]",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Fonts"
    ],
    "initial": "normal",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "orderOfAppearance",
    "alsoAppliesTo": [
      "::first-letter",
      "::first-line",
      "::placeholder"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/font-variant-alternates"
  },
  "font-variant-caps": {
    "syntax": "normal | small-caps | all-small-caps | petite-caps | all-petite-caps | unicase | titling-caps",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Fonts"
    ],
    "initial": "normal",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter",
      "::first-line",
      "::placeholder"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/font-variant-caps"
  },
  "font-variant-east-asian": {
    "syntax": "normal | [ <east-asian-variant-values> || <east-asian-width-values> || ruby ]",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Fonts"
    ],
    "initial": "normal",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "orderOfAppearance",
    "alsoAppliesTo": [
      "::first-letter",
      "::first-line",
      "::placeholder"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/font-variant-east-asian"
  },
  "font-variant-ligatures": {
    "syntax": "normal | none | [ <common-lig-values> || <discretionary-lig-values> || <historical-lig-values> || <contextual-alt-values> ]",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Fonts"
    ],
    "initial": "normal",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "orderOfAppearance",
    "alsoAppliesTo": [
      "::first-letter",
      "::first-line",
      "::placeholder"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/font-variant-ligatures"
  },
  "font-variant-numeric": {
    "syntax": "normal | [ <numeric-figure-values> || <numeric-spacing-values> || <numeric-fraction-values> || ordinal || slashed-zero ]",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Fonts"
    ],
    "initial": "normal",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "orderOfAppearance",
    "alsoAppliesTo": [
      "::first-letter",
      "::first-line",
      "::placeholder"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/font-variant-numeric"
  },
  "font-variant-position": {
    "syntax": "normal | sub | super",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Fonts"
    ],
    "initial": "normal",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter",
      "::first-line",
      "::placeholder"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/font-variant-position"
  },
  "font-weight": {
    "syntax": "<font-weight-absolute> | bolder | lighter",
    "media": "visual",
    "inherited": true,
    "animationType": "fontWeight",
    "percentages": "no",
    "groups": [
      "CSS Fonts"
    ],
    "initial": "normal",
    "appliesto": "allElements",
    "computed": "keywordOrNumericalValueBolderLighterTransformedToRealValue",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter",
      "::first-line",
      "::placeholder"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/font-weight"
  },
  "gap": {
    "syntax": "<'row-gap'> <'column-gap'>?",
    "media": "visual",
    "inherited": false,
    "animationType": [
      "row-gap",
      "column-gap"
    ],
    "percentages": "no",
    "groups": [
      "CSS Box Alignment"
    ],
    "initial": [
      "row-gap",
      "column-gap"
    ],
    "appliesto": "multiColumnElementsFlexContainersGridContainers",
    "computed": [
      "row-gap",
      "column-gap"
    ],
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/gap"
  },
  "grid": {
    "syntax": "<'grid-template'> | <'grid-template-rows'> / [ auto-flow && dense? ] <'grid-auto-columns'>? | [ auto-flow && dense? ] <'grid-auto-rows'>? / <'grid-template-columns'>",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": [
      "grid-template-rows",
      "grid-template-columns",
      "grid-auto-rows",
      "grid-auto-columns"
    ],
    "groups": [
      "CSS Grid Layout"
    ],
    "initial": [
      "grid-template-rows",
      "grid-template-columns",
      "grid-template-areas",
      "grid-auto-rows",
      "grid-auto-columns",
      "grid-auto-flow",
      "grid-column-gap",
      "grid-row-gap",
      "column-gap",
      "row-gap"
    ],
    "appliesto": "gridContainers",
    "computed": [
      "grid-template-rows",
      "grid-template-columns",
      "grid-template-areas",
      "grid-auto-rows",
      "grid-auto-columns",
      "grid-auto-flow",
      "grid-column-gap",
      "grid-row-gap",
      "column-gap",
      "row-gap"
    ],
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/grid"
  },
  "grid-area": {
    "syntax": "<grid-line> [ / <grid-line> ]{0,3}",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Grid Layout"
    ],
    "initial": [
      "grid-row-start",
      "grid-column-start",
      "grid-row-end",
      "grid-column-end"
    ],
    "appliesto": "gridItemsAndBoxesWithinGridContainer",
    "computed": [
      "grid-row-start",
      "grid-column-start",
      "grid-row-end",
      "grid-column-end"
    ],
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/grid-area"
  },
  "grid-auto-columns": {
    "syntax": "<track-size>+",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "referToDimensionOfContentArea",
    "groups": [
      "CSS Grid Layout"
    ],
    "initial": "auto",
    "appliesto": "gridContainers",
    "computed": "percentageAsSpecifiedOrAbsoluteLength",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/grid-auto-columns"
  },
  "grid-auto-flow": {
    "syntax": "[ row | column ] || dense",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Grid Layout"
    ],
    "initial": "row",
    "appliesto": "gridContainers",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/grid-auto-flow"
  },
  "grid-auto-rows": {
    "syntax": "<track-size>+",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "referToDimensionOfContentArea",
    "groups": [
      "CSS Grid Layout"
    ],
    "initial": "auto",
    "appliesto": "gridContainers",
    "computed": "percentageAsSpecifiedOrAbsoluteLength",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/grid-auto-rows"
  },
  "grid-column": {
    "syntax": "<grid-line> [ / <grid-line> ]?",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Grid Layout"
    ],
    "initial": [
      "grid-column-start",
      "grid-column-end"
    ],
    "appliesto": "gridItemsAndBoxesWithinGridContainer",
    "computed": [
      "grid-column-start",
      "grid-column-end"
    ],
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/grid-column"
  },
  "grid-column-end": {
    "syntax": "<grid-line>",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Grid Layout"
    ],
    "initial": "auto",
    "appliesto": "gridItemsAndBoxesWithinGridContainer",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/grid-column-end"
  },
  "grid-column-gap": {
    "syntax": "<length-percentage>",
    "media": "visual",
    "inherited": false,
    "animationType": "length",
    "percentages": "referToDimensionOfContentArea",
    "groups": [
      "CSS Grid Layout"
    ],
    "initial": "0",
    "appliesto": "gridContainers",
    "computed": "percentageAsSpecifiedOrAbsoluteLength",
    "order": "uniqueOrder",
    "status": "obsolete",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/column-gap"
  },
  "grid-column-start": {
    "syntax": "<grid-line>",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Grid Layout"
    ],
    "initial": "auto",
    "appliesto": "gridItemsAndBoxesWithinGridContainer",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/grid-column-start"
  },
  "grid-gap": {
    "syntax": "<'grid-row-gap'> <'grid-column-gap'>?",
    "media": "visual",
    "inherited": false,
    "animationType": [
      "grid-row-gap",
      "grid-column-gap"
    ],
    "percentages": "no",
    "groups": [
      "CSS Grid Layout"
    ],
    "initial": [
      "grid-row-gap",
      "grid-column-gap"
    ],
    "appliesto": "gridContainers",
    "computed": [
      "grid-row-gap",
      "grid-column-gap"
    ],
    "order": "uniqueOrder",
    "status": "obsolete",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/gap"
  },
  "grid-row": {
    "syntax": "<grid-line> [ / <grid-line> ]?",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Grid Layout"
    ],
    "initial": [
      "grid-row-start",
      "grid-row-end"
    ],
    "appliesto": "gridItemsAndBoxesWithinGridContainer",
    "computed": [
      "grid-row-start",
      "grid-row-end"
    ],
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/grid-row"
  },
  "grid-row-end": {
    "syntax": "<grid-line>",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Grid Layout"
    ],
    "initial": "auto",
    "appliesto": "gridItemsAndBoxesWithinGridContainer",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/grid-row-end"
  },
  "grid-row-gap": {
    "syntax": "<length-percentage>",
    "media": "visual",
    "inherited": false,
    "animationType": "length",
    "percentages": "referToDimensionOfContentArea",
    "groups": [
      "CSS Grid Layout"
    ],
    "initial": "0",
    "appliesto": "gridContainers",
    "computed": "percentageAsSpecifiedOrAbsoluteLength",
    "order": "uniqueOrder",
    "status": "obsolete",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/row-gap"
  },
  "grid-row-start": {
    "syntax": "<grid-line>",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Grid Layout"
    ],
    "initial": "auto",
    "appliesto": "gridItemsAndBoxesWithinGridContainer",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/grid-row-start"
  },
  "grid-template": {
    "syntax": "none | [ <'grid-template-rows'> / <'grid-template-columns'> ] | [ <line-names>? <string> <track-size>? <line-names>? ]+ [ / <explicit-track-list> ]?",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": [
      "grid-template-columns",
      "grid-template-rows"
    ],
    "groups": [
      "CSS Grid Layout"
    ],
    "initial": [
      "grid-template-columns",
      "grid-template-rows",
      "grid-template-areas"
    ],
    "appliesto": "gridContainers",
    "computed": [
      "grid-template-columns",
      "grid-template-rows",
      "grid-template-areas"
    ],
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/grid-template"
  },
  "grid-template-areas": {
    "syntax": "none | <string>+",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Grid Layout"
    ],
    "initial": "none",
    "appliesto": "gridContainers",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/grid-template-areas"
  },
  "grid-template-columns": {
    "syntax": "none | <track-list> | <auto-track-list> | subgrid <line-name-list>?",
    "media": "visual",
    "inherited": false,
    "animationType": "simpleListOfLpcDifferenceLpc",
    "percentages": "referToDimensionOfContentArea",
    "groups": [
      "CSS Grid Layout"
    ],
    "initial": "none",
    "appliesto": "gridContainers",
    "computed": "asSpecifiedRelativeToAbsoluteLengths",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/grid-template-columns"
  },
  "grid-template-rows": {
    "syntax": "none | <track-list> | <auto-track-list> | subgrid <line-name-list>?",
    "media": "visual",
    "inherited": false,
    "animationType": "simpleListOfLpcDifferenceLpc",
    "percentages": "referToDimensionOfContentArea",
    "groups": [
      "CSS Grid Layout"
    ],
    "initial": "none",
    "appliesto": "gridContainers",
    "computed": "asSpecifiedRelativeToAbsoluteLengths",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/grid-template-rows"
  },
  "hanging-punctuation": {
    "syntax": "none | [ first || [ force-end | allow-end ] || last ]",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Text"
    ],
    "initial": "none",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/hanging-punctuation"
  },
  "height": {
    "syntax": "auto | <length> | <percentage> | min-content | max-content | fit-content(<length-percentage>)",
    "media": "visual",
    "inherited": false,
    "animationType": "lpc",
    "percentages": "regardingHeightOfGeneratedBoxContainingBlockPercentagesRelativeToContainingBlock",
    "groups": [
      "CSS Box Model"
    ],
    "initial": "auto",
    "appliesto": "allElementsButNonReplacedAndTableColumns",
    "computed": "percentageAutoOrAbsoluteLength",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/height"
  },
  "hyphens": {
    "syntax": "none | manual | auto",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Text"
    ],
    "initial": "manual",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/hyphens"
  },
  "image-orientation": {
    "syntax": "from-image | <angle> | [ <angle>? flip ]",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Images"
    ],
    "initial": "from-image",
    "appliesto": "allElements",
    "computed": "angleRoundedToNextQuarter",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/image-orientation"
  },
  "image-rendering": {
    "syntax": "auto | crisp-edges | pixelated",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Images"
    ],
    "initial": "auto",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/image-rendering"
  },
  "image-resolution": {
    "syntax": "[ from-image || <resolution> ] && snap?",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Images"
    ],
    "initial": "1dppx",
    "appliesto": "allElements",
    "computed": "asSpecifiedWithExceptionOfResolution",
    "order": "uniqueOrder",
    "status": "experimental"
  },
  "ime-mode": {
    "syntax": "auto | normal | active | inactive | disabled",
    "media": "interactive",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Basic User Interface"
    ],
    "initial": "auto",
    "appliesto": "textFields",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "obsolete",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/ime-mode"
  },
  "initial-letter": {
    "syntax": "normal | [ <number> <integer>? ]",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Inline"
    ],
    "initial": "normal",
    "appliesto": "firstLetterPseudoElementsAndInlineLevelFirstChildren",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "experimental",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/initial-letter"
  },
  "initial-letter-align": {
    "syntax": "[ auto | alphabetic | hanging | ideographic ]",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Inline"
    ],
    "initial": "auto",
    "appliesto": "firstLetterPseudoElementsAndInlineLevelFirstChildren",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "experimental",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/initial-letter-align"
  },
  "inline-size": {
    "syntax": "<'width'>",
    "media": "visual",
    "inherited": false,
    "animationType": "lpc",
    "percentages": "inlineSizeOfContainingBlock",
    "groups": [
      "CSS Logical Properties"
    ],
    "initial": "auto",
    "appliesto": "sameAsWidthAndHeight",
    "computed": "sameAsWidthAndHeight",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/inline-size"
  },
  "inset": {
    "syntax": "<'top'>{1,4}",
    "media": "visual",
    "inherited": false,
    "animationType": "lpc",
    "percentages": "logicalHeightOfContainingBlock",
    "groups": [
      "CSS Logical Properties"
    ],
    "initial": "auto",
    "appliesto": "positionedElements",
    "computed": "sameAsBoxOffsets",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/inset"
  },
  "inset-block": {
    "syntax": "<'top'>{1,2}",
    "media": "visual",
    "inherited": false,
    "animationType": "lpc",
    "percentages": "logicalHeightOfContainingBlock",
    "groups": [
      "CSS Logical Properties"
    ],
    "initial": "auto",
    "appliesto": "positionedElements",
    "computed": "sameAsBoxOffsets",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/inset-block"
  },
  "inset-block-end": {
    "syntax": "<'top'>",
    "media": "visual",
    "inherited": false,
    "animationType": "lpc",
    "percentages": "logicalHeightOfContainingBlock",
    "groups": [
      "CSS Logical Properties"
    ],
    "initial": "auto",
    "appliesto": "positionedElements",
    "computed": "sameAsBoxOffsets",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/inset-block-end"
  },
  "inset-block-start": {
    "syntax": "<'top'>",
    "media": "visual",
    "inherited": false,
    "animationType": "lpc",
    "percentages": "logicalHeightOfContainingBlock",
    "groups": [
      "CSS Logical Properties"
    ],
    "initial": "auto",
    "appliesto": "positionedElements",
    "computed": "sameAsBoxOffsets",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/inset-block-start"
  },
  "inset-inline": {
    "syntax": "<'top'>{1,2}",
    "media": "visual",
    "inherited": false,
    "animationType": "lpc",
    "percentages": "logicalWidthOfContainingBlock",
    "groups": [
      "CSS Logical Properties"
    ],
    "initial": "auto",
    "appliesto": "positionedElements",
    "computed": "sameAsBoxOffsets",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/inset-inline"
  },
  "inset-inline-end": {
    "syntax": "<'top'>",
    "media": "visual",
    "inherited": false,
    "animationType": "lpc",
    "percentages": "logicalWidthOfContainingBlock",
    "groups": [
      "CSS Logical Properties"
    ],
    "initial": "auto",
    "appliesto": "positionedElements",
    "computed": "sameAsBoxOffsets",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/inset-inline-end"
  },
  "inset-inline-start": {
    "syntax": "<'top'>",
    "media": "visual",
    "inherited": false,
    "animationType": "lpc",
    "percentages": "logicalWidthOfContainingBlock",
    "groups": [
      "CSS Logical Properties"
    ],
    "initial": "auto",
    "appliesto": "positionedElements",
    "computed": "sameAsBoxOffsets",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/inset-inline-start"
  },
  "isolation": {
    "syntax": "auto | isolate",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Compositing and Blending"
    ],
    "initial": "auto",
    "appliesto": "allElementsSVGContainerGraphicsAndGraphicsReferencingElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/isolation"
  },
  "justify-content": {
    "syntax": "normal | <content-distribution> | <overflow-position>? [ <content-position> | left | right ]",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Box Alignment"
    ],
    "initial": "normal",
    "appliesto": "flexContainers",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/justify-content"
  },
  "justify-items": {
    "syntax": "normal | stretch | <baseline-position> | <overflow-position>? [ <self-position> | left | right ] | legacy | legacy && [ left | right | center ]",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Box Alignment"
    ],
    "initial": "legacy",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "perGrammar",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/justify-items"
  },
  "justify-self": {
    "syntax": "auto | normal | stretch | <baseline-position> | <overflow-position>? [ <self-position> | left | right ]",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Box Alignment"
    ],
    "initial": "auto",
    "appliesto": "blockLevelBoxesAndAbsolutelyPositionedBoxesAndGridItems",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/justify-self"
  },
  "justify-tracks": {
    "syntax": "[ normal | <content-distribution> | <overflow-position>? [ <content-position> | left | right ] ]#",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Grid Layout"
    ],
    "initial": "normal",
    "appliesto": "gridContainersWithMasonryLayoutInTheirInlineAxis",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "experimental",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/justify-tracks"
  },
  "left": {
    "syntax": "<length> | <percentage> | auto",
    "media": "visual",
    "inherited": false,
    "animationType": "lpc",
    "percentages": "referToWidthOfContainingBlock",
    "groups": [
      "CSS Positioning"
    ],
    "initial": "auto",
    "appliesto": "positionedElements",
    "computed": "lengthAbsolutePercentageAsSpecifiedOtherwiseAuto",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/left"
  },
  "letter-spacing": {
    "syntax": "normal | <length>",
    "media": "visual",
    "inherited": true,
    "animationType": "length",
    "percentages": "no",
    "groups": [
      "CSS Text"
    ],
    "initial": "normal",
    "appliesto": "allElements",
    "computed": "optimumValueOfAbsoluteLengthOrNormal",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter",
      "::first-line"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/letter-spacing"
  },
  "line-break": {
    "syntax": "auto | loose | normal | strict | anywhere",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Text"
    ],
    "initial": "auto",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/line-break"
  },
  "line-clamp": {
    "syntax": "none | <integer>",
    "media": "visual",
    "inherited": false,
    "animationType": "integer",
    "percentages": "no",
    "groups": [
      "CSS Overflow"
    ],
    "initial": "none",
    "appliesto": "blockContainersExceptMultiColumnContainers",
    "computed": "asSpecified",
    "order": "perGrammar",
    "status": "experimental"
  },
  "line-height": {
    "syntax": "normal | <number> | <length> | <percentage>",
    "media": "visual",
    "inherited": true,
    "animationType": "numberOrLength",
    "percentages": "referToElementFontSize",
    "groups": [
      "CSS Fonts"
    ],
    "initial": "normal",
    "appliesto": "allElements",
    "computed": "absoluteLengthOrAsSpecified",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter",
      "::first-line",
      "::placeholder"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/line-height"
  },
  "line-height-step": {
    "syntax": "<length>",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Fonts"
    ],
    "initial": "0",
    "appliesto": "blockContainers",
    "computed": "absoluteLength",
    "order": "perGrammar",
    "status": "experimental",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/line-height-step"
  },
  "list-style": {
    "syntax": "<'list-style-type'> || <'list-style-position'> || <'list-style-image'>",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Lists and Counters"
    ],
    "initial": [
      "list-style-type",
      "list-style-position",
      "list-style-image"
    ],
    "appliesto": "listItems",
    "computed": [
      "list-style-image",
      "list-style-position",
      "list-style-type"
    ],
    "order": "orderOfAppearance",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/list-style"
  },
  "list-style-image": {
    "syntax": "<url> | none",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Lists and Counters"
    ],
    "initial": "none",
    "appliesto": "listItems",
    "computed": "noneOrImageWithAbsoluteURI",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/list-style-image"
  },
  "list-style-position": {
    "syntax": "inside | outside",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Lists and Counters"
    ],
    "initial": "outside",
    "appliesto": "listItems",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/list-style-position"
  },
  "list-style-type": {
    "syntax": "<counter-style> | <string> | none",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Lists and Counters"
    ],
    "initial": "disc",
    "appliesto": "listItems",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/list-style-type"
  },
  "margin": {
    "syntax": "[ <length> | <percentage> | auto ]{1,4}",
    "media": "visual",
    "inherited": false,
    "animationType": "length",
    "percentages": "referToWidthOfContainingBlock",
    "groups": [
      "CSS Box Model"
    ],
    "initial": [
      "margin-bottom",
      "margin-left",
      "margin-right",
      "margin-top"
    ],
    "appliesto": "allElementsExceptTableDisplayTypes",
    "computed": [
      "margin-bottom",
      "margin-left",
      "margin-right",
      "margin-top"
    ],
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter",
      "::first-line"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/margin"
  },
  "margin-block": {
    "syntax": "<'margin-left'>{1,2}",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "dependsOnLayoutModel",
    "groups": [
      "CSS Logical Properties"
    ],
    "initial": "0",
    "appliesto": "sameAsMargin",
    "computed": "lengthAbsolutePercentageAsSpecifiedOtherwiseAuto",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/margin-block"
  },
  "margin-block-end": {
    "syntax": "<'margin-left'>",
    "media": "visual",
    "inherited": false,
    "animationType": "length",
    "percentages": "dependsOnLayoutModel",
    "groups": [
      "CSS Logical Properties"
    ],
    "initial": "0",
    "appliesto": "sameAsMargin",
    "computed": "lengthAbsolutePercentageAsSpecifiedOtherwiseAuto",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/margin-block-end"
  },
  "margin-block-start": {
    "syntax": "<'margin-left'>",
    "media": "visual",
    "inherited": false,
    "animationType": "length",
    "percentages": "dependsOnLayoutModel",
    "groups": [
      "CSS Logical Properties"
    ],
    "initial": "0",
    "appliesto": "sameAsMargin",
    "computed": "lengthAbsolutePercentageAsSpecifiedOtherwiseAuto",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/margin-block-start"
  },
  "margin-bottom": {
    "syntax": "<length> | <percentage> | auto",
    "media": "visual",
    "inherited": false,
    "animationType": "length",
    "percentages": "referToWidthOfContainingBlock",
    "groups": [
      "CSS Box Model"
    ],
    "initial": "0",
    "appliesto": "allElementsExceptTableDisplayTypes",
    "computed": "percentageAsSpecifiedOrAbsoluteLength",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter",
      "::first-line"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/margin-bottom"
  },
  "margin-inline": {
    "syntax": "<'margin-left'>{1,2}",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "dependsOnLayoutModel",
    "groups": [
      "CSS Logical Properties"
    ],
    "initial": "0",
    "appliesto": "sameAsMargin",
    "computed": "lengthAbsolutePercentageAsSpecifiedOtherwiseAuto",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/margin-inline"
  },
  "margin-inline-end": {
    "syntax": "<'margin-left'>",
    "media": "visual",
    "inherited": false,
    "animationType": "length",
    "percentages": "dependsOnLayoutModel",
    "groups": [
      "CSS Logical Properties"
    ],
    "initial": "0",
    "appliesto": "sameAsMargin",
    "computed": "lengthAbsolutePercentageAsSpecifiedOtherwiseAuto",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/margin-inline-end"
  },
  "margin-inline-start": {
    "syntax": "<'margin-left'>",
    "media": "visual",
    "inherited": false,
    "animationType": "length",
    "percentages": "dependsOnLayoutModel",
    "groups": [
      "CSS Logical Properties"
    ],
    "initial": "0",
    "appliesto": "sameAsMargin",
    "computed": "lengthAbsolutePercentageAsSpecifiedOtherwiseAuto",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/margin-inline-start"
  },
  "margin-left": {
    "syntax": "<length> | <percentage> | auto",
    "media": "visual",
    "inherited": false,
    "animationType": "length",
    "percentages": "referToWidthOfContainingBlock",
    "groups": [
      "CSS Box Model"
    ],
    "initial": "0",
    "appliesto": "allElementsExceptTableDisplayTypes",
    "computed": "percentageAsSpecifiedOrAbsoluteLength",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter",
      "::first-line"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/margin-left"
  },
  "margin-right": {
    "syntax": "<length> | <percentage> | auto",
    "media": "visual",
    "inherited": false,
    "animationType": "length",
    "percentages": "referToWidthOfContainingBlock",
    "groups": [
      "CSS Box Model"
    ],
    "initial": "0",
    "appliesto": "allElementsExceptTableDisplayTypes",
    "computed": "percentageAsSpecifiedOrAbsoluteLength",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter",
      "::first-line"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/margin-right"
  },
  "margin-top": {
    "syntax": "<length> | <percentage> | auto",
    "media": "visual",
    "inherited": false,
    "animationType": "length",
    "percentages": "referToWidthOfContainingBlock",
    "groups": [
      "CSS Box Model"
    ],
    "initial": "0",
    "appliesto": "allElementsExceptTableDisplayTypes",
    "computed": "percentageAsSpecifiedOrAbsoluteLength",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter",
      "::first-line"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/margin-top"
  },
  "margin-trim": {
    "syntax": "none | in-flow | all",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Box Model"
    ],
    "initial": "none",
    "appliesto": "blockContainersAndMultiColumnContainers",
    "computed": "asSpecified",
    "order": "perGrammar",
    "alsoAppliesTo": [
      "::first-letter",
      "::first-line"
    ],
    "status": "experimental",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/margin-trim"
  },
  "mask": {
    "syntax": "<mask-layer>#",
    "media": "visual",
    "inherited": false,
    "animationType": [
      "mask-image",
      "mask-mode",
      "mask-repeat",
      "mask-position",
      "mask-clip",
      "mask-origin",
      "mask-size",
      "mask-composite"
    ],
    "percentages": [
      "mask-position"
    ],
    "groups": [
      "CSS Masking"
    ],
    "initial": [
      "mask-image",
      "mask-mode",
      "mask-repeat",
      "mask-position",
      "mask-clip",
      "mask-origin",
      "mask-size",
      "mask-composite"
    ],
    "appliesto": "allElementsSVGContainerElements",
    "computed": [
      "mask-image",
      "mask-mode",
      "mask-repeat",
      "mask-position",
      "mask-clip",
      "mask-origin",
      "mask-size",
      "mask-composite"
    ],
    "order": "perGrammar",
    "stacking": true,
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/mask"
  },
  "mask-border": {
    "syntax": "<'mask-border-source'> || <'mask-border-slice'> [ / <'mask-border-width'>? [ / <'mask-border-outset'> ]? ]? || <'mask-border-repeat'> || <'mask-border-mode'>",
    "media": "visual",
    "inherited": false,
    "animationType": [
      "mask-border-mode",
      "mask-border-outset",
      "mask-border-repeat",
      "mask-border-slice",
      "mask-border-source",
      "mask-border-width"
    ],
    "percentages": [
      "mask-border-slice",
      "mask-border-width"
    ],
    "groups": [
      "CSS Masking"
    ],
    "initial": [
      "mask-border-mode",
      "mask-border-outset",
      "mask-border-repeat",
      "mask-border-slice",
      "mask-border-source",
      "mask-border-width"
    ],
    "appliesto": "allElementsSVGContainerElements",
    "computed": [
      "mask-border-mode",
      "mask-border-outset",
      "mask-border-repeat",
      "mask-border-slice",
      "mask-border-source",
      "mask-border-width"
    ],
    "order": "perGrammar",
    "stacking": true,
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/mask-border"
  },
  "mask-border-mode": {
    "syntax": "luminance | alpha",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Masking"
    ],
    "initial": "alpha",
    "appliesto": "allElementsSVGContainerElements",
    "computed": "asSpecified",
    "order": "perGrammar",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/mask-border-mode"
  },
  "mask-border-outset": {
    "syntax": "[ <length> | <number> ]{1,4}",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Masking"
    ],
    "initial": "0",
    "appliesto": "allElementsSVGContainerElements",
    "computed": "asSpecifiedRelativeToAbsoluteLengths",
    "order": "perGrammar",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/mask-border-outset"
  },
  "mask-border-repeat": {
    "syntax": "[ stretch | repeat | round | space ]{1,2}",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Masking"
    ],
    "initial": "stretch",
    "appliesto": "allElementsSVGContainerElements",
    "computed": "asSpecified",
    "order": "perGrammar",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/mask-border-repeat"
  },
  "mask-border-slice": {
    "syntax": "<number-percentage>{1,4} fill?",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "referToSizeOfMaskBorderImage",
    "groups": [
      "CSS Masking"
    ],
    "initial": "0",
    "appliesto": "allElementsSVGContainerElements",
    "computed": "asSpecified",
    "order": "perGrammar",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/mask-border-slice"
  },
  "mask-border-source": {
    "syntax": "none | <image>",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Masking"
    ],
    "initial": "none",
    "appliesto": "allElementsSVGContainerElements",
    "computed": "asSpecifiedURLsAbsolute",
    "order": "perGrammar",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/mask-border-source"
  },
  "mask-border-width": {
    "syntax": "[ <length-percentage> | <number> | auto ]{1,4}",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "relativeToMaskBorderImageArea",
    "groups": [
      "CSS Masking"
    ],
    "initial": "auto",
    "appliesto": "allElementsSVGContainerElements",
    "computed": "asSpecifiedRelativeToAbsoluteLengths",
    "order": "perGrammar",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/mask-border-width"
  },
  "mask-clip": {
    "syntax": "[ <geometry-box> | no-clip ]#",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Masking"
    ],
    "initial": "border-box",
    "appliesto": "allElementsSVGContainerElements",
    "computed": "asSpecified",
    "order": "perGrammar",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/mask-clip"
  },
  "mask-composite": {
    "syntax": "<compositing-operator>#",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Masking"
    ],
    "initial": "add",
    "appliesto": "allElementsSVGContainerElements",
    "computed": "asSpecified",
    "order": "perGrammar",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/mask-composite"
  },
  "mask-image": {
    "syntax": "<mask-reference>#",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Masking"
    ],
    "initial": "none",
    "appliesto": "allElementsSVGContainerElements",
    "computed": "asSpecifiedURLsAbsolute",
    "order": "perGrammar",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/mask-image"
  },
  "mask-mode": {
    "syntax": "<masking-mode>#",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Masking"
    ],
    "initial": "match-source",
    "appliesto": "allElementsSVGContainerElements",
    "computed": "asSpecified",
    "order": "perGrammar",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/mask-mode"
  },
  "mask-origin": {
    "syntax": "<geometry-box>#",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Masking"
    ],
    "initial": "border-box",
    "appliesto": "allElementsSVGContainerElements",
    "computed": "asSpecified",
    "order": "perGrammar",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/mask-origin"
  },
  "mask-position": {
    "syntax": "<position>#",
    "media": "visual",
    "inherited": false,
    "animationType": "repeatableListOfSimpleListOfLpc",
    "percentages": "referToSizeOfMaskPaintingArea",
    "groups": [
      "CSS Masking"
    ],
    "initial": "center",
    "appliesto": "allElementsSVGContainerElements",
    "computed": "consistsOfTwoKeywordsForOriginAndOffsets",
    "order": "perGrammar",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/mask-position"
  },
  "mask-repeat": {
    "syntax": "<repeat-style>#",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Masking"
    ],
    "initial": "no-repeat",
    "appliesto": "allElementsSVGContainerElements",
    "computed": "consistsOfTwoDimensionKeywords",
    "order": "perGrammar",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/mask-repeat"
  },
  "mask-size": {
    "syntax": "<bg-size>#",
    "media": "visual",
    "inherited": false,
    "animationType": "repeatableListOfSimpleListOfLpc",
    "percentages": "no",
    "groups": [
      "CSS Masking"
    ],
    "initial": "auto",
    "appliesto": "allElementsSVGContainerElements",
    "computed": "asSpecifiedRelativeToAbsoluteLengths",
    "order": "perGrammar",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/mask-size"
  },
  "mask-type": {
    "syntax": "luminance | alpha",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Masking"
    ],
    "initial": "luminance",
    "appliesto": "maskElements",
    "computed": "asSpecified",
    "order": "perGrammar",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/mask-type"
  },
  "masonry-auto-flow": {
    "syntax": "[ pack | next ] || [ definite-first | ordered ]",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Grid Layout"
    ],
    "initial": "pack",
    "appliesto": "gridContainersWithMasonryLayout",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "experimental",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/masonry-auto-flow"
  },
  "math-style": {
    "syntax": "normal | compact",
    "media": "visual",
    "inherited": true,
    "animationType": "notAnimatable",
    "percentages": "no",
    "groups": [
      "MathML"
    ],
    "initial": "normal",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "perGrammar",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/math-style"
  },
  "max-block-size": {
    "syntax": "<'max-width'>",
    "media": "visual",
    "inherited": false,
    "animationType": "lpc",
    "percentages": "blockSizeOfContainingBlock",
    "groups": [
      "CSS Logical Properties"
    ],
    "initial": "0",
    "appliesto": "sameAsWidthAndHeight",
    "computed": "sameAsMaxWidthAndMaxHeight",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/max-block-size"
  },
  "max-height": {
    "syntax": "none | <length-percentage> | min-content | max-content | fit-content(<length-percentage>)",
    "media": "visual",
    "inherited": false,
    "animationType": "lpc",
    "percentages": "regardingHeightOfGeneratedBoxContainingBlockPercentagesNone",
    "groups": [
      "CSS Box Model"
    ],
    "initial": "none",
    "appliesto": "allElementsButNonReplacedAndTableColumns",
    "computed": "percentageAsSpecifiedAbsoluteLengthOrNone",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/max-height"
  },
  "max-inline-size": {
    "syntax": "<'max-width'>",
    "media": "visual",
    "inherited": false,
    "animationType": "lpc",
    "percentages": "inlineSizeOfContainingBlock",
    "groups": [
      "CSS Logical Properties"
    ],
    "initial": "0",
    "appliesto": "sameAsWidthAndHeight",
    "computed": "sameAsMaxWidthAndMaxHeight",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/max-inline-size"
  },
  "max-lines": {
    "syntax": "none | <integer>",
    "media": "visual",
    "inherited": false,
    "animationType": "integer",
    "percentages": "no",
    "groups": [
      "CSS Overflow"
    ],
    "initial": "none",
    "appliesto": "blockContainersExceptMultiColumnContainers",
    "computed": "asSpecified",
    "order": "perGrammar",
    "status": "experimental"
  },
  "max-width": {
    "syntax": "none | <length-percentage> | min-content | max-content | fit-content(<length-percentage>)",
    "media": "visual",
    "inherited": false,
    "animationType": "lpc",
    "percentages": "referToWidthOfContainingBlock",
    "groups": [
      "CSS Box Model"
    ],
    "initial": "none",
    "appliesto": "allElementsButNonReplacedAndTableRows",
    "computed": "percentageAsSpecifiedAbsoluteLengthOrNone",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/max-width"
  },
  "min-block-size": {
    "syntax": "<'min-width'>",
    "media": "visual",
    "inherited": false,
    "animationType": "lpc",
    "percentages": "blockSizeOfContainingBlock",
    "groups": [
      "CSS Logical Properties"
    ],
    "initial": "0",
    "appliesto": "sameAsWidthAndHeight",
    "computed": "sameAsMinWidthAndMinHeight",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/min-block-size"
  },
  "min-height": {
    "syntax": "auto | <length> | <percentage> | min-content | max-content | fit-content(<length-percentage>)",
    "media": "visual",
    "inherited": false,
    "animationType": "lpc",
    "percentages": "regardingHeightOfGeneratedBoxContainingBlockPercentages0",
    "groups": [
      "CSS Box Model"
    ],
    "initial": "auto",
    "appliesto": "allElementsButNonReplacedAndTableColumns",
    "computed": "percentageAsSpecifiedOrAbsoluteLength",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/min-height"
  },
  "min-inline-size": {
    "syntax": "<'min-width'>",
    "media": "visual",
    "inherited": false,
    "animationType": "lpc",
    "percentages": "inlineSizeOfContainingBlock",
    "groups": [
      "CSS Logical Properties"
    ],
    "initial": "0",
    "appliesto": "sameAsWidthAndHeight",
    "computed": "sameAsMinWidthAndMinHeight",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/min-inline-size"
  },
  "min-width": {
    "syntax": "auto | <length> | <percentage> | min-content | max-content | fit-content(<length-percentage>)",
    "media": "visual",
    "inherited": false,
    "animationType": "lpc",
    "percentages": "referToWidthOfContainingBlock",
    "groups": [
      "CSS Box Model"
    ],
    "initial": "auto",
    "appliesto": "allElementsButNonReplacedAndTableRows",
    "computed": "percentageAsSpecifiedOrAbsoluteLength",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/min-width"
  },
  "mix-blend-mode": {
    "syntax": "<blend-mode>",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Compositing and Blending"
    ],
    "initial": "normal",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "stacking": true,
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/mix-blend-mode"
  },
  "object-fit": {
    "syntax": "fill | contain | cover | none | scale-down",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Images"
    ],
    "initial": "fill",
    "appliesto": "replacedElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/object-fit"
  },
  "object-position": {
    "syntax": "<position>",
    "media": "visual",
    "inherited": true,
    "animationType": "repeatableListOfSimpleListOfLpc",
    "percentages": "referToWidthAndHeightOfElement",
    "groups": [
      "CSS Images"
    ],
    "initial": "50% 50%",
    "appliesto": "replacedElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/object-position"
  },
  "offset": {
    "syntax": "[ <'offset-position'>? [ <'offset-path'> [ <'offset-distance'> || <'offset-rotate'> ]? ]? ]! [ / <'offset-anchor'> ]?",
    "media": "visual",
    "inherited": false,
    "animationType": [
      "offset-position",
      "offset-path",
      "offset-distance",
      "offset-anchor",
      "offset-rotate"
    ],
    "percentages": [
      "offset-position",
      "offset-distance",
      "offset-anchor"
    ],
    "groups": [
      "CSS Motion Path"
    ],
    "initial": [
      "offset-position",
      "offset-path",
      "offset-distance",
      "offset-anchor",
      "offset-rotate"
    ],
    "appliesto": "transformableElements",
    "computed": [
      "offset-position",
      "offset-path",
      "offset-distance",
      "offset-anchor",
      "offset-rotate"
    ],
    "order": "perGrammar",
    "stacking": true,
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/offset"
  },
  "offset-anchor": {
    "syntax": "auto | <position>",
    "media": "visual",
    "inherited": false,
    "animationType": "position",
    "percentages": "relativeToWidthAndHeight",
    "groups": [
      "CSS Motion Path"
    ],
    "initial": "auto",
    "appliesto": "transformableElements",
    "computed": "forLengthAbsoluteValueOtherwisePercentage",
    "order": "perGrammar",
    "status": "standard"
  },
  "offset-distance": {
    "syntax": "<length-percentage>",
    "media": "visual",
    "inherited": false,
    "animationType": "lpc",
    "percentages": "referToTotalPathLength",
    "groups": [
      "CSS Motion Path"
    ],
    "initial": "0",
    "appliesto": "transformableElements",
    "computed": "forLengthAbsoluteValueOtherwisePercentage",
    "order": "perGrammar",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/offset-distance"
  },
  "offset-path": {
    "syntax": "none | ray( [ <angle> && <size> && contain? ] ) | <path()> | <url> | [ <basic-shape> || <geometry-box> ]",
    "media": "visual",
    "inherited": false,
    "animationType": "angleOrBasicShapeOrPath",
    "percentages": "no",
    "groups": [
      "CSS Motion Path"
    ],
    "initial": "none",
    "appliesto": "transformableElements",
    "computed": "asSpecified",
    "order": "perGrammar",
    "stacking": true,
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/offset-path"
  },
  "offset-position": {
    "syntax": "auto | <position>",
    "media": "visual",
    "inherited": false,
    "animationType": "position",
    "percentages": "referToSizeOfContainingBlock",
    "groups": [
      "CSS Motion Path"
    ],
    "initial": "auto",
    "appliesto": "transformableElements",
    "computed": "forLengthAbsoluteValueOtherwisePercentage",
    "order": "perGrammar",
    "status": "experimental"
  },
  "offset-rotate": {
    "syntax": "[ auto | reverse ] || <angle>",
    "media": "visual",
    "inherited": false,
    "animationType": "angleOrBasicShapeOrPath",
    "percentages": "no",
    "groups": [
      "CSS Motion Path"
    ],
    "initial": "auto",
    "appliesto": "transformableElements",
    "computed": "asSpecified",
    "order": "perGrammar",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/offset-rotate"
  },
  "opacity": {
    "syntax": "<alpha-value>",
    "media": "visual",
    "inherited": false,
    "animationType": "number",
    "percentages": "no",
    "groups": [
      "CSS Color"
    ],
    "initial": "1.0",
    "appliesto": "allElements",
    "computed": "specifiedValueClipped0To1",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::placeholder"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/opacity"
  },
  "order": {
    "syntax": "<integer>",
    "media": "visual",
    "inherited": false,
    "animationType": "integer",
    "percentages": "no",
    "groups": [
      "CSS Flexible Box Layout"
    ],
    "initial": "0",
    "appliesto": "flexItemsGridItemsAbsolutelyPositionedContainerChildren",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/order"
  },
  "orphans": {
    "syntax": "<integer>",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Fragmentation"
    ],
    "initial": "2",
    "appliesto": "blockContainerElements",
    "computed": "asSpecified",
    "order": "perGrammar",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/orphans"
  },
  "outline": {
    "syntax": "[ <'outline-color'> || <'outline-style'> || <'outline-width'> ]",
    "media": [
      "visual",
      "interactive"
    ],
    "inherited": false,
    "animationType": [
      "outline-color",
      "outline-width",
      "outline-style"
    ],
    "percentages": "no",
    "groups": [
      "CSS Basic User Interface"
    ],
    "initial": [
      "outline-color",
      "outline-style",
      "outline-width"
    ],
    "appliesto": "allElements",
    "computed": [
      "outline-color",
      "outline-width",
      "outline-style"
    ],
    "order": "orderOfAppearance",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/outline"
  },
  "outline-color": {
    "syntax": "<color> | invert",
    "media": [
      "visual",
      "interactive"
    ],
    "inherited": false,
    "animationType": "color",
    "percentages": "no",
    "groups": [
      "CSS Basic User Interface"
    ],
    "initial": "invertOrCurrentColor",
    "appliesto": "allElements",
    "computed": "invertForTranslucentColorRGBAOtherwiseRGB",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/outline-color"
  },
  "outline-offset": {
    "syntax": "<length>",
    "media": [
      "visual",
      "interactive"
    ],
    "inherited": false,
    "animationType": "length",
    "percentages": "no",
    "groups": [
      "CSS Basic User Interface"
    ],
    "initial": "0",
    "appliesto": "allElements",
    "computed": "asSpecifiedRelativeToAbsoluteLengths",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/outline-offset"
  },
  "outline-style": {
    "syntax": "auto | <'border-style'>",
    "media": [
      "visual",
      "interactive"
    ],
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Basic User Interface"
    ],
    "initial": "none",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/outline-style"
  },
  "outline-width": {
    "syntax": "<line-width>",
    "media": [
      "visual",
      "interactive"
    ],
    "inherited": false,
    "animationType": "length",
    "percentages": "no",
    "groups": [
      "CSS Basic User Interface"
    ],
    "initial": "medium",
    "appliesto": "allElements",
    "computed": "absoluteLength0ForNone",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/outline-width"
  },
  "overflow": {
    "syntax": "[ visible | hidden | clip | scroll | auto ]{1,2}",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Overflow"
    ],
    "initial": "visible",
    "appliesto": "blockContainersFlexContainersGridContainers",
    "computed": [
      "overflow-x",
      "overflow-y"
    ],
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/overflow"
  },
  "overflow-anchor": {
    "syntax": "auto | none",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Scroll Anchoring"
    ],
    "initial": "auto",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "perGrammar",
    "status": "standard"
  },
  "overflow-block": {
    "syntax": "visible | hidden | clip | scroll | auto",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Overflow"
    ],
    "initial": "auto",
    "appliesto": "blockContainersFlexContainersGridContainers",
    "computed": "asSpecifiedButVisibleOrClipReplacedToAutoOrHiddenIfOtherValueDifferent",
    "order": "perGrammar",
    "status": "standard"
  },
  "overflow-clip-box": {
    "syntax": "padding-box | content-box",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Mozilla Extensions"
    ],
    "initial": "padding-box",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Mozilla/CSS/overflow-clip-box"
  },
  "overflow-inline": {
    "syntax": "visible | hidden | clip | scroll | auto",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Overflow"
    ],
    "initial": "auto",
    "appliesto": "blockContainersFlexContainersGridContainers",
    "computed": "asSpecifiedButVisibleOrClipReplacedToAutoOrHiddenIfOtherValueDifferent",
    "order": "perGrammar",
    "status": "standard"
  },
  "overflow-wrap": {
    "syntax": "normal | break-word | anywhere",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Text"
    ],
    "initial": "normal",
    "appliesto": "nonReplacedInlineElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/overflow-wrap"
  },
  "overflow-x": {
    "syntax": "visible | hidden | clip | scroll | auto",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Overflow"
    ],
    "initial": "visible",
    "appliesto": "blockContainersFlexContainersGridContainers",
    "computed": "asSpecifiedButVisibleOrClipReplacedToAutoOrHiddenIfOtherValueDifferent",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/overflow-x"
  },
  "overflow-y": {
    "syntax": "visible | hidden | clip | scroll | auto",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Overflow"
    ],
    "initial": "visible",
    "appliesto": "blockContainersFlexContainersGridContainers",
    "computed": "asSpecifiedButVisibleOrClipReplacedToAutoOrHiddenIfOtherValueDifferent",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/overflow-y"
  },
  "overscroll-behavior": {
    "syntax": "[ contain | none | auto ]{1,2}",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Box Model"
    ],
    "initial": "auto",
    "appliesto": "nonReplacedBlockAndInlineBlockElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/overscroll-behavior"
  },
  "overscroll-behavior-block": {
    "syntax": "contain | none | auto",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Box Model"
    ],
    "initial": "auto",
    "appliesto": "nonReplacedBlockAndInlineBlockElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/overscroll-behavior-block"
  },
  "overscroll-behavior-inline": {
    "syntax": "contain | none | auto",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Box Model"
    ],
    "initial": "auto",
    "appliesto": "nonReplacedBlockAndInlineBlockElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/overscroll-behavior-inline"
  },
  "overscroll-behavior-x": {
    "syntax": "contain | none | auto",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Box Model"
    ],
    "initial": "auto",
    "appliesto": "nonReplacedBlockAndInlineBlockElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/overscroll-behavior-x"
  },
  "overscroll-behavior-y": {
    "syntax": "contain | none | auto",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Box Model"
    ],
    "initial": "auto",
    "appliesto": "nonReplacedBlockAndInlineBlockElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/overscroll-behavior-y"
  },
  "padding": {
    "syntax": "[ <length> | <percentage> ]{1,4}",
    "media": "visual",
    "inherited": false,
    "animationType": "length",
    "percentages": "referToWidthOfContainingBlock",
    "groups": [
      "CSS Box Model"
    ],
    "initial": [
      "padding-bottom",
      "padding-left",
      "padding-right",
      "padding-top"
    ],
    "appliesto": "allElementsExceptInternalTableDisplayTypes",
    "computed": [
      "padding-bottom",
      "padding-left",
      "padding-right",
      "padding-top"
    ],
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter",
      "::first-line"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/padding"
  },
  "padding-block": {
    "syntax": "<'padding-left'>{1,2}",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "logicalWidthOfContainingBlock",
    "groups": [
      "CSS Logical Properties"
    ],
    "initial": "0",
    "appliesto": "allElements",
    "computed": "asLength",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/padding-block"
  },
  "padding-block-end": {
    "syntax": "<'padding-left'>",
    "media": "visual",
    "inherited": false,
    "animationType": "length",
    "percentages": "logicalWidthOfContainingBlock",
    "groups": [
      "CSS Logical Properties"
    ],
    "initial": "0",
    "appliesto": "allElements",
    "computed": "asLength",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/padding-block-end"
  },
  "padding-block-start": {
    "syntax": "<'padding-left'>",
    "media": "visual",
    "inherited": false,
    "animationType": "length",
    "percentages": "logicalWidthOfContainingBlock",
    "groups": [
      "CSS Logical Properties"
    ],
    "initial": "0",
    "appliesto": "allElements",
    "computed": "asLength",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/padding-block-start"
  },
  "padding-bottom": {
    "syntax": "<length> | <percentage>",
    "media": "visual",
    "inherited": false,
    "animationType": "length",
    "percentages": "referToWidthOfContainingBlock",
    "groups": [
      "CSS Box Model"
    ],
    "initial": "0",
    "appliesto": "allElementsExceptInternalTableDisplayTypes",
    "computed": "percentageAsSpecifiedOrAbsoluteLength",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter",
      "::first-line"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/padding-bottom"
  },
  "padding-inline": {
    "syntax": "<'padding-left'>{1,2}",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "logicalWidthOfContainingBlock",
    "groups": [
      "CSS Logical Properties"
    ],
    "initial": "0",
    "appliesto": "allElements",
    "computed": "asLength",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/padding-inline"
  },
  "padding-inline-end": {
    "syntax": "<'padding-left'>",
    "media": "visual",
    "inherited": false,
    "animationType": "length",
    "percentages": "logicalWidthOfContainingBlock",
    "groups": [
      "CSS Logical Properties"
    ],
    "initial": "0",
    "appliesto": "allElements",
    "computed": "asLength",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/padding-inline-end"
  },
  "padding-inline-start": {
    "syntax": "<'padding-left'>",
    "media": "visual",
    "inherited": false,
    "animationType": "length",
    "percentages": "logicalWidthOfContainingBlock",
    "groups": [
      "CSS Logical Properties"
    ],
    "initial": "0",
    "appliesto": "allElements",
    "computed": "asLength",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/padding-inline-start"
  },
  "padding-left": {
    "syntax": "<length> | <percentage>",
    "media": "visual",
    "inherited": false,
    "animationType": "length",
    "percentages": "referToWidthOfContainingBlock",
    "groups": [
      "CSS Box Model"
    ],
    "initial": "0",
    "appliesto": "allElementsExceptInternalTableDisplayTypes",
    "computed": "percentageAsSpecifiedOrAbsoluteLength",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter",
      "::first-line"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/padding-left"
  },
  "padding-right": {
    "syntax": "<length> | <percentage>",
    "media": "visual",
    "inherited": false,
    "animationType": "length",
    "percentages": "referToWidthOfContainingBlock",
    "groups": [
      "CSS Box Model"
    ],
    "initial": "0",
    "appliesto": "allElementsExceptInternalTableDisplayTypes",
    "computed": "percentageAsSpecifiedOrAbsoluteLength",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter",
      "::first-line"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/padding-right"
  },
  "padding-top": {
    "syntax": "<length> | <percentage>",
    "media": "visual",
    "inherited": false,
    "animationType": "length",
    "percentages": "referToWidthOfContainingBlock",
    "groups": [
      "CSS Box Model"
    ],
    "initial": "0",
    "appliesto": "allElementsExceptInternalTableDisplayTypes",
    "computed": "percentageAsSpecifiedOrAbsoluteLength",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter",
      "::first-line"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/padding-top"
  },
  "page-break-after": {
    "syntax": "auto | always | avoid | left | right | recto | verso",
    "media": [
      "visual",
      "paged"
    ],
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Pages"
    ],
    "initial": "auto",
    "appliesto": "blockElementsInNormalFlow",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/page-break-after"
  },
  "page-break-before": {
    "syntax": "auto | always | avoid | left | right | recto | verso",
    "media": [
      "visual",
      "paged"
    ],
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Pages"
    ],
    "initial": "auto",
    "appliesto": "blockElementsInNormalFlow",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/page-break-before"
  },
  "page-break-inside": {
    "syntax": "auto | avoid",
    "media": [
      "visual",
      "paged"
    ],
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Pages"
    ],
    "initial": "auto",
    "appliesto": "blockElementsInNormalFlow",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/page-break-inside"
  },
  "paint-order": {
    "syntax": "normal | [ fill || stroke || markers ]",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Text"
    ],
    "initial": "normal",
    "appliesto": "textElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/paint-order"
  },
  "perspective": {
    "syntax": "none | <length>",
    "media": "visual",
    "inherited": false,
    "animationType": "length",
    "percentages": "no",
    "groups": [
      "CSS Transforms"
    ],
    "initial": "none",
    "appliesto": "transformableElements",
    "computed": "absoluteLengthOrNone",
    "order": "uniqueOrder",
    "stacking": true,
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/perspective"
  },
  "perspective-origin": {
    "syntax": "<position>",
    "media": "visual",
    "inherited": false,
    "animationType": "simpleListOfLpc",
    "percentages": "referToSizeOfBoundingBox",
    "groups": [
      "CSS Transforms"
    ],
    "initial": "50% 50%",
    "appliesto": "transformableElements",
    "computed": "forLengthAbsoluteValueOtherwisePercentage",
    "order": "oneOrTwoValuesLengthAbsoluteKeywordsPercentages",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/perspective-origin"
  },
  "place-content": {
    "syntax": "<'align-content'> <'justify-content'>?",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Box Alignment"
    ],
    "initial": "normal",
    "appliesto": "multilineFlexContainers",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/place-content"
  },
  "place-items": {
    "syntax": "<'align-items'> <'justify-items'>?",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Box Alignment"
    ],
    "initial": [
      "align-items",
      "justify-items"
    ],
    "appliesto": "allElements",
    "computed": [
      "align-items",
      "justify-items"
    ],
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/place-items"
  },
  "place-self": {
    "syntax": "<'align-self'> <'justify-self'>?",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Box Alignment"
    ],
    "initial": [
      "align-self",
      "justify-self"
    ],
    "appliesto": "blockLevelBoxesAndAbsolutelyPositionedBoxesAndGridItems",
    "computed": [
      "align-self",
      "justify-self"
    ],
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/place-self"
  },
  "pointer-events": {
    "syntax": "auto | none | visiblePainted | visibleFill | visibleStroke | visible | painted | fill | stroke | all | inherit",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Pointer Events"
    ],
    "initial": "auto",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/pointer-events"
  },
  "position": {
    "syntax": "static | relative | absolute | sticky | fixed",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Positioning"
    ],
    "initial": "static",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "stacking": true,
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/position"
  },
  "quotes": {
    "syntax": "none | auto | [ <string> <string> ]+",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Generated Content"
    ],
    "initial": "dependsOnUserAgent",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/quotes"
  },
  "resize": {
    "syntax": "none | both | horizontal | vertical | block | inline",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Basic User Interface"
    ],
    "initial": "none",
    "appliesto": "elementsWithOverflowNotVisibleAndReplacedElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/resize"
  },
  "right": {
    "syntax": "<length> | <percentage> | auto",
    "media": "visual",
    "inherited": false,
    "animationType": "lpc",
    "percentages": "referToWidthOfContainingBlock",
    "groups": [
      "CSS Positioning"
    ],
    "initial": "auto",
    "appliesto": "positionedElements",
    "computed": "lengthAbsolutePercentageAsSpecifiedOtherwiseAuto",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/right"
  },
  "rotate": {
    "syntax": "none | <angle> | [ x | y | z | <number>{3} ] && <angle>",
    "media": "visual",
    "inherited": false,
    "animationType": "transform",
    "percentages": "no",
    "groups": [
      "CSS Transforms"
    ],
    "initial": "none",
    "appliesto": "transformableElements",
    "computed": "asSpecified",
    "order": "perGrammar",
    "stacking": true,
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/rotate"
  },
  "row-gap": {
    "syntax": "normal | <length-percentage>",
    "media": "visual",
    "inherited": false,
    "animationType": "lpc",
    "percentages": "referToDimensionOfContentArea",
    "groups": [
      "CSS Box Alignment"
    ],
    "initial": "normal",
    "appliesto": "multiColumnElementsFlexContainersGridContainers",
    "computed": "asSpecifiedWithLengthsAbsoluteAndNormalComputingToZeroExceptMultiColumn",
    "order": "perGrammar",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/row-gap"
  },
  "ruby-align": {
    "syntax": "start | center | space-between | space-around",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Ruby"
    ],
    "initial": "space-around",
    "appliesto": "rubyBasesAnnotationsBaseAnnotationContainers",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "experimental",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/ruby-align"
  },
  "ruby-merge": {
    "syntax": "separate | collapse | auto",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Ruby"
    ],
    "initial": "separate",
    "appliesto": "rubyAnnotationsContainers",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "experimental"
  },
  "ruby-position": {
    "syntax": "over | under | inter-character",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Ruby"
    ],
    "initial": "over",
    "appliesto": "rubyAnnotationsContainers",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "experimental",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/ruby-position"
  },
  "scale": {
    "syntax": "none | <number>{1,3}",
    "media": "visual",
    "inherited": false,
    "animationType": "transform",
    "percentages": "no",
    "groups": [
      "CSS Transforms"
    ],
    "initial": "none",
    "appliesto": "transformableElements",
    "computed": "asSpecified",
    "order": "perGrammar",
    "stacking": true,
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/scale"
  },
  "scrollbar-color": {
    "syntax": "auto | dark | light | <color>{2}",
    "media": "visual",
    "inherited": true,
    "animationType": "color",
    "percentages": "no",
    "groups": [
      "CSS Scrollbars"
    ],
    "initial": "auto",
    "appliesto": "scrollingBoxes",
    "computed": "asSpecified",
    "order": "perGrammar",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/scrollbar-color"
  },
  "scrollbar-gutter": {
    "syntax": "auto | [ stable | always ] && both? && force?",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Overflow"
    ],
    "initial": "auto",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "perGrammar",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/scrollbar-gutter"
  },
  "scrollbar-width": {
    "syntax": "auto | thin | none",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Scrollbars"
    ],
    "initial": "auto",
    "appliesto": "scrollingBoxes",
    "computed": "asSpecified",
    "order": "perGrammar",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/scrollbar-width"
  },
  "scroll-behavior": {
    "syntax": "auto | smooth",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSSOM View"
    ],
    "initial": "auto",
    "appliesto": "scrollingBoxes",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/scroll-behavior"
  },
  "scroll-margin": {
    "syntax": "<length>{1,4}",
    "media": "visual",
    "inherited": false,
    "animationType": "byComputedValueType",
    "percentages": "no",
    "groups": [
      "CSS Scroll Snap"
    ],
    "initial": "0",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "perGrammar",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/scroll-margin"
  },
  "scroll-margin-block": {
    "syntax": "<length>{1,2}",
    "media": "visual",
    "inherited": false,
    "animationType": "byComputedValueType",
    "percentages": "no",
    "groups": [
      "CSS Scroll Snap"
    ],
    "initial": "0",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "perGrammar",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/scroll-margin-block"
  },
  "scroll-margin-block-start": {
    "syntax": "<length>",
    "media": "visual",
    "inherited": false,
    "animationType": "byComputedValueType",
    "percentages": "no",
    "groups": [
      "CSS Scroll Snap"
    ],
    "initial": "0",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "perGrammar",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/scroll-margin-block-start"
  },
  "scroll-margin-block-end": {
    "syntax": "<length>",
    "media": "visual",
    "inherited": false,
    "animationType": "byComputedValueType",
    "percentages": "no",
    "groups": [
      "CSS Scroll Snap"
    ],
    "initial": "0",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "perGrammar",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/scroll-margin-block-end"
  },
  "scroll-margin-bottom": {
    "syntax": "<length>",
    "media": "visual",
    "inherited": false,
    "animationType": "byComputedValueType",
    "percentages": "no",
    "groups": [
      "CSS Scroll Snap"
    ],
    "initial": "0",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "perGrammar",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/scroll-margin-bottom"
  },
  "scroll-margin-inline": {
    "syntax": "<length>{1,2}",
    "media": "visual",
    "inherited": false,
    "animationType": "byComputedValueType",
    "percentages": "no",
    "groups": [
      "CSS Scroll Snap"
    ],
    "initial": "0",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "perGrammar",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/scroll-margin-inline"
  },
  "scroll-margin-inline-start": {
    "syntax": "<length>",
    "media": "visual",
    "inherited": false,
    "animationType": "byComputedValueType",
    "percentages": "no",
    "groups": [
      "CSS Scroll Snap"
    ],
    "initial": "0",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "perGrammar",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/scroll-margin-inline-start"
  },
  "scroll-margin-inline-end": {
    "syntax": "<length>",
    "media": "visual",
    "inherited": false,
    "animationType": "byComputedValueType",
    "percentages": "no",
    "groups": [
      "CSS Scroll Snap"
    ],
    "initial": "0",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "perGrammar",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/scroll-margin-inline-end"
  },
  "scroll-margin-left": {
    "syntax": "<length>",
    "media": "visual",
    "inherited": false,
    "animationType": "byComputedValueType",
    "percentages": "no",
    "groups": [
      "CSS Scroll Snap"
    ],
    "initial": "0",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "perGrammar",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/scroll-margin-left"
  },
  "scroll-margin-right": {
    "syntax": "<length>",
    "media": "visual",
    "inherited": false,
    "animationType": "byComputedValueType",
    "percentages": "no",
    "groups": [
      "CSS Scroll Snap"
    ],
    "initial": "0",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "perGrammar",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/scroll-margin-right"
  },
  "scroll-margin-top": {
    "syntax": "<length>",
    "media": "visual",
    "inherited": false,
    "animationType": "byComputedValueType",
    "percentages": "no",
    "groups": [
      "CSS Scroll Snap"
    ],
    "initial": "0",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "perGrammar",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/scroll-margin-top"
  },
  "scroll-padding": {
    "syntax": "[ auto | <length-percentage> ]{1,4}",
    "media": "visual",
    "inherited": false,
    "animationType": "byComputedValueType",
    "percentages": "relativeToTheScrollContainersScrollport",
    "groups": [
      "CSS Scroll Snap"
    ],
    "initial": "auto",
    "appliesto": "scrollContainers",
    "computed": "asSpecified",
    "order": "perGrammar",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/scroll-padding"
  },
  "scroll-padding-block": {
    "syntax": "[ auto | <length-percentage> ]{1,2}",
    "media": "visual",
    "inherited": false,
    "animationType": "byComputedValueType",
    "percentages": "relativeToTheScrollContainersScrollport",
    "groups": [
      "CSS Scroll Snap"
    ],
    "initial": "auto",
    "appliesto": "scrollContainers",
    "computed": "asSpecified",
    "order": "perGrammar",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/scroll-padding-block"
  },
  "scroll-padding-block-start": {
    "syntax": "auto | <length-percentage>",
    "media": "visual",
    "inherited": false,
    "animationType": "byComputedValueType",
    "percentages": "relativeToTheScrollContainersScrollport",
    "groups": [
      "CSS Scroll Snap"
    ],
    "initial": "auto",
    "appliesto": "scrollContainers",
    "computed": "asSpecified",
    "order": "perGrammar",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/scroll-padding-block-start"
  },
  "scroll-padding-block-end": {
    "syntax": "auto | <length-percentage>",
    "media": "visual",
    "inherited": false,
    "animationType": "byComputedValueType",
    "percentages": "relativeToTheScrollContainersScrollport",
    "groups": [
      "CSS Scroll Snap"
    ],
    "initial": "auto",
    "appliesto": "scrollContainers",
    "computed": "asSpecified",
    "order": "perGrammar",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/scroll-padding-block-end"
  },
  "scroll-padding-bottom": {
    "syntax": "auto | <length-percentage>",
    "media": "visual",
    "inherited": false,
    "animationType": "byComputedValueType",
    "percentages": "relativeToTheScrollContainersScrollport",
    "groups": [
      "CSS Scroll Snap"
    ],
    "initial": "auto",
    "appliesto": "scrollContainers",
    "computed": "asSpecified",
    "order": "perGrammar",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/scroll-padding-bottom"
  },
  "scroll-padding-inline": {
    "syntax": "[ auto | <length-percentage> ]{1,2}",
    "media": "visual",
    "inherited": false,
    "animationType": "byComputedValueType",
    "percentages": "relativeToTheScrollContainersScrollport",
    "groups": [
      "CSS Scroll Snap"
    ],
    "initial": "auto",
    "appliesto": "scrollContainers",
    "computed": "asSpecified",
    "order": "perGrammar",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/scroll-padding-inline"
  },
  "scroll-padding-inline-start": {
    "syntax": "auto | <length-percentage>",
    "media": "visual",
    "inherited": false,
    "animationType": "byComputedValueType",
    "percentages": "relativeToTheScrollContainersScrollport",
    "groups": [
      "CSS Scroll Snap"
    ],
    "initial": "auto",
    "appliesto": "scrollContainers",
    "computed": "asSpecified",
    "order": "perGrammar",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/scroll-padding-inline-start"
  },
  "scroll-padding-inline-end": {
    "syntax": "auto | <length-percentage>",
    "media": "visual",
    "inherited": false,
    "animationType": "byComputedValueType",
    "percentages": "relativeToTheScrollContainersScrollport",
    "groups": [
      "CSS Scroll Snap"
    ],
    "initial": "auto",
    "appliesto": "scrollContainers",
    "computed": "asSpecified",
    "order": "perGrammar",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/scroll-padding-inline-end"
  },
  "scroll-padding-left": {
    "syntax": "auto | <length-percentage>",
    "media": "visual",
    "inherited": false,
    "animationType": "byComputedValueType",
    "percentages": "relativeToTheScrollContainersScrollport",
    "groups": [
      "CSS Scroll Snap"
    ],
    "initial": "auto",
    "appliesto": "scrollContainers",
    "computed": "asSpecified",
    "order": "perGrammar",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/scroll-padding-left"
  },
  "scroll-padding-right": {
    "syntax": "auto | <length-percentage>",
    "media": "visual",
    "inherited": false,
    "animationType": "byComputedValueType",
    "percentages": "relativeToTheScrollContainersScrollport",
    "groups": [
      "CSS Scroll Snap"
    ],
    "initial": "auto",
    "appliesto": "scrollContainers",
    "computed": "asSpecified",
    "order": "perGrammar",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/scroll-padding-right"
  },
  "scroll-padding-top": {
    "syntax": "auto | <length-percentage>",
    "media": "visual",
    "inherited": false,
    "animationType": "byComputedValueType",
    "percentages": "relativeToTheScrollContainersScrollport",
    "groups": [
      "CSS Scroll Snap"
    ],
    "initial": "auto",
    "appliesto": "scrollContainers",
    "computed": "asSpecified",
    "order": "perGrammar",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/scroll-padding-top"
  },
  "scroll-snap-align": {
    "syntax": "[ none | start | end | center ]{1,2}",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Scroll Snap"
    ],
    "initial": "none",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "perGrammar",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/scroll-snap-align"
  },
  "scroll-snap-coordinate": {
    "syntax": "none | <position>#",
    "media": "interactive",
    "inherited": false,
    "animationType": "position",
    "percentages": "referToBorderBox",
    "groups": [
      "CSS Scroll Snap"
    ],
    "initial": "none",
    "appliesto": "allElements",
    "computed": "asSpecifiedRelativeToAbsoluteLengths",
    "order": "uniqueOrder",
    "status": "obsolete",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/scroll-snap-coordinate"
  },
  "scroll-snap-destination": {
    "syntax": "<position>",
    "media": "interactive",
    "inherited": false,
    "animationType": "position",
    "percentages": "relativeToScrollContainerPaddingBoxAxis",
    "groups": [
      "CSS Scroll Snap"
    ],
    "initial": "0px 0px",
    "appliesto": "scrollContainers",
    "computed": "asSpecifiedRelativeToAbsoluteLengths",
    "order": "uniqueOrder",
    "status": "obsolete",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/scroll-snap-destination"
  },
  "scroll-snap-points-x": {
    "syntax": "none | repeat( <length-percentage> )",
    "media": "interactive",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "relativeToScrollContainerPaddingBoxAxis",
    "groups": [
      "CSS Scroll Snap"
    ],
    "initial": "none",
    "appliesto": "scrollContainers",
    "computed": "asSpecifiedRelativeToAbsoluteLengths",
    "order": "uniqueOrder",
    "status": "obsolete",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/scroll-snap-points-x"
  },
  "scroll-snap-points-y": {
    "syntax": "none | repeat( <length-percentage> )",
    "media": "interactive",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "relativeToScrollContainerPaddingBoxAxis",
    "groups": [
      "CSS Scroll Snap"
    ],
    "initial": "none",
    "appliesto": "scrollContainers",
    "computed": "asSpecifiedRelativeToAbsoluteLengths",
    "order": "uniqueOrder",
    "status": "obsolete",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/scroll-snap-points-y"
  },
  "scroll-snap-stop": {
    "syntax": "normal | always",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Scroll Snap"
    ],
    "initial": "normal",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "perGrammar",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/scroll-snap-stop"
  },
  "scroll-snap-type": {
    "syntax": "none | [ x | y | block | inline | both ] [ mandatory | proximity ]?",
    "media": "interactive",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Scroll Snap"
    ],
    "initial": "none",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/scroll-snap-type"
  },
  "scroll-snap-type-x": {
    "syntax": "none | mandatory | proximity",
    "media": "interactive",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Scroll Snap"
    ],
    "initial": "none",
    "appliesto": "scrollContainers",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "obsolete",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/scroll-snap-type-x"
  },
  "scroll-snap-type-y": {
    "syntax": "none | mandatory | proximity",
    "media": "interactive",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Scroll Snap"
    ],
    "initial": "none",
    "appliesto": "scrollContainers",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "obsolete",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/scroll-snap-type-y"
  },
  "shape-image-threshold": {
    "syntax": "<alpha-value>",
    "media": "visual",
    "inherited": false,
    "animationType": "number",
    "percentages": "no",
    "groups": [
      "CSS Shapes"
    ],
    "initial": "0.0",
    "appliesto": "floats",
    "computed": "specifiedValueNumberClipped0To1",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/shape-image-threshold"
  },
  "shape-margin": {
    "syntax": "<length-percentage>",
    "media": "visual",
    "inherited": false,
    "animationType": "lpc",
    "percentages": "referToWidthOfContainingBlock",
    "groups": [
      "CSS Shapes"
    ],
    "initial": "0",
    "appliesto": "floats",
    "computed": "asSpecifiedRelativeToAbsoluteLengths",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/shape-margin"
  },
  "shape-outside": {
    "syntax": "none | <shape-box> || <basic-shape> | <image>",
    "media": "visual",
    "inherited": false,
    "animationType": "basicShapeOtherwiseNo",
    "percentages": "no",
    "groups": [
      "CSS Shapes"
    ],
    "initial": "none",
    "appliesto": "floats",
    "computed": "asDefinedForBasicShapeWithAbsoluteURIOtherwiseAsSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/shape-outside"
  },
  "tab-size": {
    "syntax": "<integer> | <length>",
    "media": "visual",
    "inherited": true,
    "animationType": "length",
    "percentages": "no",
    "groups": [
      "CSS Text"
    ],
    "initial": "8",
    "appliesto": "blockContainers",
    "computed": "specifiedIntegerOrAbsoluteLength",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/tab-size"
  },
  "table-layout": {
    "syntax": "auto | fixed",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Table"
    ],
    "initial": "auto",
    "appliesto": "tableElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/table-layout"
  },
  "text-align": {
    "syntax": "start | end | left | right | center | justify | match-parent",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Text"
    ],
    "initial": "startOrNamelessValueIfLTRRightIfRTL",
    "appliesto": "blockContainers",
    "computed": "asSpecifiedExceptMatchParent",
    "order": "orderOfAppearance",
    "alsoAppliesTo": [
      "::placeholder"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/text-align"
  },
  "text-align-last": {
    "syntax": "auto | start | end | left | right | center | justify",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Text"
    ],
    "initial": "auto",
    "appliesto": "blockContainers",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/text-align-last"
  },
  "text-combine-upright": {
    "syntax": "none | all | [ digits <integer>? ]",
    "media": "visual",
    "inherited": true,
    "animationType": "notAnimatable",
    "percentages": "no",
    "groups": [
      "CSS Writing Modes"
    ],
    "initial": "none",
    "appliesto": "nonReplacedInlineElements",
    "computed": "keywordPlusIntegerIfDigits",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/text-combine-upright"
  },
  "text-decoration": {
    "syntax": "<'text-decoration-line'> || <'text-decoration-style'> || <'text-decoration-color'> || <'text-decoration-thickness'>",
    "media": "visual",
    "inherited": false,
    "animationType": [
      "text-decoration-color",
      "text-decoration-style",
      "text-decoration-line",
      "text-decoration-thickness"
    ],
    "percentages": "no",
    "groups": [
      "CSS Text Decoration"
    ],
    "initial": [
      "text-decoration-color",
      "text-decoration-style",
      "text-decoration-line"
    ],
    "appliesto": "allElements",
    "computed": [
      "text-decoration-line",
      "text-decoration-style",
      "text-decoration-color",
      "text-decoration-thickness"
    ],
    "order": "orderOfAppearance",
    "alsoAppliesTo": [
      "::first-letter",
      "::first-line",
      "::placeholder"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/text-decoration"
  },
  "text-decoration-color": {
    "syntax": "<color>",
    "media": "visual",
    "inherited": false,
    "animationType": "color",
    "percentages": "no",
    "groups": [
      "CSS Text Decoration"
    ],
    "initial": "currentcolor",
    "appliesto": "allElements",
    "computed": "computedColor",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter",
      "::first-line",
      "::placeholder"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/text-decoration-color"
  },
  "text-decoration-line": {
    "syntax": "none | [ underline || overline || line-through || blink ] | spelling-error | grammar-error",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Text Decoration"
    ],
    "initial": "none",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "orderOfAppearance",
    "alsoAppliesTo": [
      "::first-letter",
      "::first-line",
      "::placeholder"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/text-decoration-line"
  },
  "text-decoration-skip": {
    "syntax": "none | [ objects || [ spaces | [ leading-spaces || trailing-spaces ] ] || edges || box-decoration ]",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Text Decoration"
    ],
    "initial": "objects",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "orderOfAppearance",
    "status": "experimental",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/text-decoration-skip"
  },
  "text-decoration-skip-ink": {
    "syntax": "auto | all | none",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Text Decoration"
    ],
    "initial": "auto",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "orderOfAppearance",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/text-decoration-skip-ink"
  },
  "text-decoration-style": {
    "syntax": "solid | double | dotted | dashed | wavy",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Text Decoration"
    ],
    "initial": "solid",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter",
      "::first-line",
      "::placeholder"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/text-decoration-style"
  },
  "text-decoration-thickness": {
    "syntax": "auto | from-font | <length> | <percentage> ",
    "media": "visual",
    "inherited": false,
    "animationType": "byComputedValueType",
    "percentages": "referToElementFontSize",
    "groups": [
      "CSS Text Decoration"
    ],
    "initial": "auto",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter",
      "::first-line",
      "::placeholder"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/text-decoration-thickness"
  },
  "text-emphasis": {
    "syntax": "<'text-emphasis-style'> || <'text-emphasis-color'>",
    "media": "visual",
    "inherited": false,
    "animationType": [
      "text-emphasis-color",
      "text-emphasis-style"
    ],
    "percentages": "no",
    "groups": [
      "CSS Text Decoration"
    ],
    "initial": [
      "text-emphasis-style",
      "text-emphasis-color"
    ],
    "appliesto": "allElements",
    "computed": [
      "text-emphasis-style",
      "text-emphasis-color"
    ],
    "order": "orderOfAppearance",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/text-emphasis"
  },
  "text-emphasis-color": {
    "syntax": "<color>",
    "media": "visual",
    "inherited": false,
    "animationType": "color",
    "percentages": "no",
    "groups": [
      "CSS Text Decoration"
    ],
    "initial": "currentcolor",
    "appliesto": "allElements",
    "computed": "computedColor",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/text-emphasis-color"
  },
  "text-emphasis-position": {
    "syntax": "[ over | under ] && [ right | left ]",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Text Decoration"
    ],
    "initial": "over right",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/text-emphasis-position"
  },
  "text-emphasis-style": {
    "syntax": "none | [ [ filled | open ] || [ dot | circle | double-circle | triangle | sesame ] ] | <string>",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Text Decoration"
    ],
    "initial": "none",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/text-emphasis-style"
  },
  "text-indent": {
    "syntax": "<length-percentage> && hanging? && each-line?",
    "media": "visual",
    "inherited": true,
    "animationType": "lpc",
    "percentages": "referToWidthOfContainingBlock",
    "groups": [
      "CSS Text"
    ],
    "initial": "0",
    "appliesto": "blockContainers",
    "computed": "percentageOrAbsoluteLengthPlusKeywords",
    "order": "lengthOrPercentageBeforeKeywords",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/text-indent"
  },
  "text-justify": {
    "syntax": "auto | inter-character | inter-word | none",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Text"
    ],
    "initial": "auto",
    "appliesto": "inlineLevelAndTableCellElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/text-justify"
  },
  "text-orientation": {
    "syntax": "mixed | upright | sideways",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Writing Modes"
    ],
    "initial": "mixed",
    "appliesto": "allElementsExceptTableRowGroupsRowsColumnGroupsAndColumns",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/text-orientation"
  },
  "text-overflow": {
    "syntax": "[ clip | ellipsis | <string> ]{1,2}",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Basic User Interface"
    ],
    "initial": "clip",
    "appliesto": "blockContainerElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::placeholder"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/text-overflow"
  },
  "text-rendering": {
    "syntax": "auto | optimizeSpeed | optimizeLegibility | geometricPrecision",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Miscellaneous"
    ],
    "initial": "auto",
    "appliesto": "textElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/text-rendering"
  },
  "text-shadow": {
    "syntax": "none | <shadow-t>#",
    "media": "visual",
    "inherited": true,
    "animationType": "shadowList",
    "percentages": "no",
    "groups": [
      "CSS Text Decoration"
    ],
    "initial": "none",
    "appliesto": "allElements",
    "computed": "colorPlusThreeAbsoluteLengths",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter",
      "::first-line",
      "::placeholder"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/text-shadow"
  },
  "text-size-adjust": {
    "syntax": "none | auto | <percentage>",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "referToSizeOfFont",
    "groups": [
      "CSS Text"
    ],
    "initial": "autoForSmartphoneBrowsersSupportingInflation",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "experimental",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/text-size-adjust"
  },
  "text-transform": {
    "syntax": "none | capitalize | uppercase | lowercase | full-width | full-size-kana",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Text"
    ],
    "initial": "none",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter",
      "::first-line",
      "::placeholder"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/text-transform"
  },
  "text-underline-offset": {
    "syntax": "auto | <length> | <percentage> ",
    "media": "visual",
    "inherited": true,
    "animationType": "byComputedValueType",
    "percentages": "referToElementFontSize",
    "groups": [
      "CSS Text Decoration"
    ],
    "initial": "auto",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter",
      "::first-line",
      "::placeholder"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/text-underline-offset"
  },
  "text-underline-position": {
    "syntax": "auto | from-font | [ under || [ left | right ] ]",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Text Decoration"
    ],
    "initial": "auto",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "orderOfAppearance",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/text-underline-position"
  },
  "top": {
    "syntax": "<length> | <percentage> | auto",
    "media": "visual",
    "inherited": false,
    "animationType": "lpc",
    "percentages": "referToContainingBlockHeight",
    "groups": [
      "CSS Positioning"
    ],
    "initial": "auto",
    "appliesto": "positionedElements",
    "computed": "lengthAbsolutePercentageAsSpecifiedOtherwiseAuto",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/top"
  },
  "touch-action": {
    "syntax": "auto | none | [ [ pan-x | pan-left | pan-right ] || [ pan-y | pan-up | pan-down ] || pinch-zoom ] | manipulation",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "Pointer Events"
    ],
    "initial": "auto",
    "appliesto": "allElementsExceptNonReplacedInlineElementsTableRowsColumnsRowColumnGroups",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/touch-action"
  },
  "transform": {
    "syntax": "none | <transform-list>",
    "media": "visual",
    "inherited": false,
    "animationType": "transform",
    "percentages": "referToSizeOfBoundingBox",
    "groups": [
      "CSS Transforms"
    ],
    "initial": "none",
    "appliesto": "transformableElements",
    "computed": "asSpecifiedRelativeToAbsoluteLengths",
    "order": "uniqueOrder",
    "stacking": true,
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/transform"
  },
  "transform-box": {
    "syntax": "content-box | border-box | fill-box | stroke-box | view-box",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Transforms"
    ],
    "initial": "view-box",
    "appliesto": "transformableElements",
    "computed": "asSpecified",
    "order": "perGrammar",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/transform-box"
  },
  "transform-origin": {
    "syntax": "[ <length-percentage> | left | center | right | top | bottom ] | [ [ <length-percentage> | left | center | right ] && [ <length-percentage> | top | center | bottom ] ] <length>?",
    "media": "visual",
    "inherited": false,
    "animationType": "simpleListOfLpc",
    "percentages": "referToSizeOfBoundingBox",
    "groups": [
      "CSS Transforms"
    ],
    "initial": "50% 50% 0",
    "appliesto": "transformableElements",
    "computed": "forLengthAbsoluteValueOtherwisePercentage",
    "order": "oneOrTwoValuesLengthAbsoluteKeywordsPercentages",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/transform-origin"
  },
  "transform-style": {
    "syntax": "flat | preserve-3d",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Transforms"
    ],
    "initial": "flat",
    "appliesto": "transformableElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "stacking": true,
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/transform-style"
  },
  "transition": {
    "syntax": "<single-transition>#",
    "media": "interactive",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Transitions"
    ],
    "initial": [
      "transition-delay",
      "transition-duration",
      "transition-property",
      "transition-timing-function"
    ],
    "appliesto": "allElementsAndPseudos",
    "computed": [
      "transition-delay",
      "transition-duration",
      "transition-property",
      "transition-timing-function"
    ],
    "order": "orderOfAppearance",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/transition"
  },
  "transition-delay": {
    "syntax": "<time>#",
    "media": "interactive",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Transitions"
    ],
    "initial": "0s",
    "appliesto": "allElementsAndPseudos",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/transition-delay"
  },
  "transition-duration": {
    "syntax": "<time>#",
    "media": "interactive",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Transitions"
    ],
    "initial": "0s",
    "appliesto": "allElementsAndPseudos",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/transition-duration"
  },
  "transition-property": {
    "syntax": "none | <single-transition-property>#",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Transitions"
    ],
    "initial": "all",
    "appliesto": "allElementsAndPseudos",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/transition-property"
  },
  "transition-timing-function": {
    "syntax": "<timing-function>#",
    "media": "interactive",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Transitions"
    ],
    "initial": "ease",
    "appliesto": "allElementsAndPseudos",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/transition-timing-function"
  },
  "translate": {
    "syntax": "none | <length-percentage> [ <length-percentage> <length>? ]?",
    "media": "visual",
    "inherited": false,
    "animationType": "transform",
    "percentages": "referToSizeOfBoundingBox",
    "groups": [
      "CSS Transforms"
    ],
    "initial": "none",
    "appliesto": "transformableElements",
    "computed": "asSpecifiedRelativeToAbsoluteLengths",
    "order": "perGrammar",
    "stacking": true,
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/translate"
  },
  "unicode-bidi": {
    "syntax": "normal | embed | isolate | bidi-override | isolate-override | plaintext",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Writing Modes"
    ],
    "initial": "normal",
    "appliesto": "allElementsSomeValuesNoEffectOnNonInlineElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/unicode-bidi"
  },
  "user-select": {
    "syntax": "auto | text | none | contain | all",
    "media": "visual",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Basic User Interface"
    ],
    "initial": "auto",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/user-select"
  },
  "vertical-align": {
    "syntax": "baseline | sub | super | text-top | text-bottom | middle | top | bottom | <percentage> | <length>",
    "media": "visual",
    "inherited": false,
    "animationType": "length",
    "percentages": "referToLineHeight",
    "groups": [
      "CSS Table"
    ],
    "initial": "baseline",
    "appliesto": "inlineLevelAndTableCellElements",
    "computed": "absoluteLengthOrKeyword",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter",
      "::first-line",
      "::placeholder"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/vertical-align"
  },
  "visibility": {
    "syntax": "visible | hidden | collapse",
    "media": "visual",
    "inherited": true,
    "animationType": "visibility",
    "percentages": "no",
    "groups": [
      "CSS Box Model"
    ],
    "initial": "visible",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/visibility"
  },
  "white-space": {
    "syntax": "normal | pre | nowrap | pre-wrap | pre-line | break-spaces",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Text"
    ],
    "initial": "normal",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/white-space"
  },
  "widows": {
    "syntax": "<integer>",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Fragmentation"
    ],
    "initial": "2",
    "appliesto": "blockContainerElements",
    "computed": "asSpecified",
    "order": "perGrammar",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/widows"
  },
  "width": {
    "syntax": "auto | <length> | <percentage> | min-content | max-content | fit-content(<length-percentage>)",
    "media": "visual",
    "inherited": false,
    "animationType": "lpc",
    "percentages": "referToWidthOfContainingBlock",
    "groups": [
      "CSS Box Model"
    ],
    "initial": "auto",
    "appliesto": "allElementsButNonReplacedAndTableRows",
    "computed": "percentageAutoOrAbsoluteLength",
    "order": "lengthOrPercentageBeforeKeywordIfBothPresent",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/width"
  },
  "will-change": {
    "syntax": "auto | <animateable-feature>#",
    "media": "all",
    "inherited": false,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Will Change"
    ],
    "initial": "auto",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/will-change"
  },
  "word-break": {
    "syntax": "normal | break-all | keep-all | break-word",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Text"
    ],
    "initial": "normal",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/word-break"
  },
  "word-spacing": {
    "syntax": "normal | <length-percentage>",
    "media": "visual",
    "inherited": true,
    "animationType": "length",
    "percentages": "referToWidthOfAffectedGlyph",
    "groups": [
      "CSS Text"
    ],
    "initial": "normal",
    "appliesto": "allElements",
    "computed": "optimumMinAndMaxValueOfAbsoluteLengthPercentageOrNormal",
    "order": "uniqueOrder",
    "alsoAppliesTo": [
      "::first-letter",
      "::first-line",
      "::placeholder"
    ],
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/word-spacing"
  },
  "word-wrap": {
    "syntax": "normal | break-word",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Text"
    ],
    "initial": "normal",
    "appliesto": "nonReplacedInlineElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/overflow-wrap"
  },
  "writing-mode": {
    "syntax": "horizontal-tb | vertical-rl | vertical-lr | sideways-rl | sideways-lr",
    "media": "visual",
    "inherited": true,
    "animationType": "discrete",
    "percentages": "no",
    "groups": [
      "CSS Writing Modes"
    ],
    "initial": "horizontal-tb",
    "appliesto": "allElementsExceptTableRowColumnGroupsTableRowsColumns",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/writing-mode"
  },
  "z-index": {
    "syntax": "auto | <integer>",
    "media": "visual",
    "inherited": false,
    "animationType": "integer",
    "percentages": "no",
    "groups": [
      "CSS Positioning"
    ],
    "initial": "auto",
    "appliesto": "positionedElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "stacking": true,
    "status": "standard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/z-index"
  },
  "zoom": {
    "syntax": "normal | reset | <number> | <percentage>",
    "media": "visual",
    "inherited": false,
    "animationType": "integer",
    "percentages": "no",
    "groups": [
      "Microsoft Extensions"
    ],
    "initial": "normal",
    "appliesto": "allElements",
    "computed": "asSpecified",
    "order": "uniqueOrder",
    "status": "nonstandard",
    "mdn_url": "https://developer.mozilla.org/docs/Web/CSS/zoom"
  }
}
