import { Service } from '../../../../app/client/src/generated/api'

export const byName = (a: Service, b: Service) =>
  (a.servicesTemplates.name?.toLowerCase() ?? '').localeCompare(b.servicesTemplates.name?.toLowerCase() ?? '')

export const orderServices = (services: Service[]) => {
  const sortedServices = [...services]
  sortedServices.sort(byName)

  return [...sortedServices]
}
