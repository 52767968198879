import { Tasklist } from '../../../../app/client/src/generated/api'

const isBeforeToday = (date: string): boolean => {
  const now = new Date()
  now.setUTCHours(0)
  now.setUTCMinutes(0)
  now.setUTCSeconds(0)
  now.setUTCMilliseconds(0)
  const nowTime = now.getTime()
  const input = new Date(date).getTime()
  return input < nowTime
}

export const taskOverdue = (dueDate?: string, completedDate?: string): boolean => {
  if (!dueDate || completedDate) return false
  return isBeforeToday(dueDate)
}

// a list is overdue if the list due date is in the past
// and the list contains tasks which are not completed
export const listOverdue = (list?: Tasklist) => {
  let overdue = false
  if (list && list.dueDate && list.sections) {
    if (isBeforeToday(list.dueDate)) {
      for (const section of list.sections) {
        for (const task of section.tasks) {
          if (!task.completedDate) {
            overdue = true
            break
          }
        }
      }
    }
  }
  return overdue
}
