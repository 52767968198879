import { CloudUploadIcon } from '@heroicons/react/solid'
import { yupResolver } from '@hookform/resolvers/yup'
import { sortBy } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import LogoutIcon from 'theorem-lib/src/assets/icons/logout.svg'
import TrashIcon from 'theorem-lib/src/assets/icons/trash.svg'
import { ImageCropper } from 'theorem-lib/src/components/atoms/ImageCropper/ImageCropper'
import { Modal } from 'theorem-lib/src/components/molecules/Modal/Modal'
import { BusinessRolesEnum } from 'theorem-lib/src/entities/enums/BusinessRoles'
import { getBusinessRolesBySecurityRole } from 'theorem-lib/src/helpers/businessRolesHelper'
import config from '../../../config'
import { User } from '../../../generated/api'
import { useActions, useAppState } from '../../../presenter'
import { ProfileFormValidator } from '../../../validators/profileFormValidator'
import Select from '../../atoms/Select/Select'
import TextInput from '../../atoms/TextInput/TextInput'

export function UserEdit() {
  const { userEdit } = useAppState()
  const [confirmModal, setConfirmModal] = useState(false)

  const { submitUserEditAction, uploadAvatarImageAction, userRemoveAvatarAction } = useActions()
  const { formState: { errors, isDirty }, handleSubmit, register, reset, setValue } = useForm({
    defaultValues: userEdit,
    resolver: yupResolver(ProfileFormValidator),
  })
  const logoutURL = `${config.identityUrl}/logout`

  useEffect(() => {
    reset(userEdit)
  }, [userEdit])

  const getOptions = () => {
    const unsorted = getBusinessRolesBySecurityRole(userEdit.securityRoles[0])
      .map(businessRole => {
        return {
          key: Object.keys(
            BusinessRolesEnum,
          )[Object.values(BusinessRolesEnum).indexOf(businessRole as unknown as BusinessRolesEnum)],
          value: businessRole,
        }
      })
    return sortBy(unsorted, (opt) => opt.value)
  }
  const bizOptions = getOptions()

  const getBizOptionKey = (input: string) => {
    for (const opt of bizOptions) {
      if (opt.value === input) {
        return opt.key
      }
    }
    return ''
  }

  const handleSelectChange = (val: string) => {
    setValue('businessRole', val)
  }

  const handleSave = (payload: User) => {
    submitUserEditAction(payload)
  }

  const handleLogout = () => {
    if (isDirty) {
      setConfirmModal(true)
    } else {
      window.location.href = logoutURL
    }
  }

  return (
    <div className='relative'>
      <h2 className='text-xl font-bold mb-5'>My Profile</h2>
      <div className='absolute top-0 right-0'>
        <a
          href={`${config.identityUrl}/settings`}
          className='bg-cta-200 text-white font-medium rounded py-2 px-4 text-sm mr-4 hover:text-white hover:no-underline'
        >
          Update Password
        </a>
        <button className='button text-black hover:text-black hover:no-underline' onClick={handleLogout}>
          <LogoutIcon className='mr-1 -mt-1 inline scale-75' />
          Log out
        </button>
      </div>
      <form
        onSubmit={handleSubmit(handleSave)}
      >
        <div>
          <h2 className='text-black mb-6 text-lg'>Photo</h2>
          <div
            className='inline-block h-20 w-20 align-middle rounded-full bg-cta-200 text-white text-3xl text-center leading-[80px] font-bold bg-center bg-cover'
            style={{ backgroundImage: `url(${userEdit.avatarUrl || ''})` }}
          >
            {!userEdit.avatarUrl && (
              <span>
                {userEdit.firstName?.charAt(0)}
                {userEdit.lastName?.charAt(0)}
              </span>
            )}
          </div>
          <div className='ml-8 inline-block align-middle'>
            <label htmlFor='cropper' className='text-primary-200 cursor-pointer font-normal'>
              <CloudUploadIcon className='w-5 inline-block mr-1' />Upload new
            </label>
            {userEdit.avatarUrl && (
              <button
                type='button'
                className=' text-primary-300 mt-2'
                onClick={() => {
                  userRemoveAvatarAction()
                }}
              >
                <TrashIcon className='w-5 inline-block mr-1' />Remove Image
              </button>
            )}
          </div>
        </div>

        <ImageCropper
          size={300}
          setCroppedImageFile={(file) => uploadAvatarImageAction({ avatarFile: file, user: userEdit })}
        />
        <div className='mb-4 mt-10'>
          <h2 className='text-black mb-6 text-lg'>Personal Info</h2>
          <div className='inline-block w-2/5 align-top mr-4 h-16'>
            <TextInput
              label='First Name'
              type='text'
              {...register('firstName', { required: true })}
            />
            <div className='text-right text-error font-medium text-xs'>{errors.firstName?.message || ' '}</div>
          </div>
          <div className='inline-block w-2/5 align-top h-16'>
            <TextInput
              label='Last Name'
              type='text'
              {...register('lastName', { required: true })}
            />
            <div className='text-right text-error font-medium text-xs'>{errors.lastName?.message || ' '}</div>
          </div>
        </div>
        <div className='mb-10'>
          <div className='inline-block w-2/5 align-top mr-4 h-16'>
            <Select
              data={bizOptions}
              label='Role'
              default={userEdit.businessRole ? { key: getBizOptionKey(userEdit.businessRole), value: '' } : undefined}
              onChange={(val) => {
                handleSelectChange(val.value)
              }}
            />
            <div className='text-right text-error font-medium text-xs'>{errors.businessRole?.message || ' '}</div>
          </div>
        </div>
        <div className='mb-4'>
          <h2 className='text-black mb-6 text-lg'>Contact Info</h2>
          <div className='inline-block w-2/5 align-top mr-4 h-16'>
            <TextInput
              label='Email'
              type='text'
              {...register('email')}
            />
            <div className='text-right text-error font-medium text-xs'>{errors.email?.message || ' '}</div>
          </div>
          <div className='inline-block w-2/5 align-top h-16'>
            <TextInput
              label='Phone Number (Optional)'
              type='tel'
              {...register('phone')}
            />
            <div className='text-right text-error font-medium text-xs'>{errors.phone?.message || ' '}</div>
          </div>
        </div>
        <button type='submit' className='button cta fixed right-4 bottom-4'>Save</button>
      </form>
      {confirmModal && (
        <Modal
          isOpen={confirmModal}
          onClose={() => setConfirmModal(false)}
        >
          <div className='flex flex-col justify-center items-center w-[80vw] sm:w-[558px]'>
            <div className='text-primary-100 text-xl font-semibold mb-2 text-center'>
              Are you sure you want to leave without saving?
            </div>
            <div className='text-primary-300 text-base font-normal text-center mb-6'>
              Changes you have made so far will not be saved.
            </div>
            <div className='flex flex-row'>
              <button type='button' className='button mx-2' onClick={() => setConfirmModal(false)}>Keep Editing</button>
              <button type='button' className='button cta mx-2' onClick={() => window.location.href = logoutURL}>
                Confirm
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  )
}
