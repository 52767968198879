import * as Yup from 'yup'

const errorMessageName = 'Please enter a valid First Name.'
const errorMessageEmail = 'Please enter a valid email address.'
const errorMessageRole = 'Please select a Role.'

export const InviteUserFormValidator = Yup.object().shape({
  values: Yup.array().of(
    Yup.object().shape({
      firstName: Yup.string().required(errorMessageName),
      email: Yup.string().email().required(errorMessageEmail),
      role: Yup.string().required(errorMessageRole),
    }),
  ),
})

export const InviteCollaboratorFormValidator = Yup.object().shape({
  values: Yup.array().of(
    Yup.object().shape({
      firstName: Yup.string().required(errorMessageName),
      email: Yup.string().email().required(errorMessageEmail),
    }),
  ),
})

export const InviteCollaboratorToFolioFormValidator = Yup.object().shape({
  values: Yup.array().of(
    Yup.object().shape({
      email: Yup.string().email().required(errorMessageEmail),
      firstName: Yup.string().required(errorMessageName),
    }),
  ),
})
