import { CheckIcon, ChevronDownIcon, ChevronLeftIcon, DotsVerticalIcon } from '@heroicons/react/solid'
import { truncate } from 'lodash'
import React, { useState } from 'react'
import TrashIcon from 'theorem-lib/src/assets/icons/trash.svg'
import { useActions, useAppState } from '../../presenter'
import Dropdown from '../atoms/Dropdown/Dropdown'

type ProjectStage = 'Discover' | 'Evaluate' | 'Implement'

type ProjectHeaderProps = {
  activeTab: 'dashboard' | 'details' | 'budget' | 'imagery' | 'workbook' | 'team'
  hideDropDown?: boolean
  hideDeleteOption?: boolean
  readOnlyProjectStage?: boolean
}

const ActiveTabLine = ({ activeTab, tab }: { tab: string; activeTab: string }) => (
  <div
    className={`h-1 w-[80%] mt-1 rounded-t-lg ${tab === activeTab && 'bg-cta-200'}`}
  />
)

export function ProjectHeader({
  activeTab = 'dashboard',
  hideDeleteOption = false,
  hideDropDown = false,
  readOnlyProjectStage = false,
}: ProjectHeaderProps) {
  const { selectedProject } = useAppState()
  const {
    displayModalAction,
    redirectAction,
    submitUpdateProjectStageAction,
    updateProjectDeleteIdAction,
  } = useActions()
  const [isProjectDropdownOpen, setIsProjectDropdownOpen] = useState(false)
  const [isStageDropdownOpen, setIsStageDropdownOpen] = useState(false)

  const getActiveStageColor = (stage: ProjectStage) =>
    stage === selectedProject.stage ? 'text-cta-200' : 'text-grey-400'
  const goToTab = (tab: string) => redirectAction(`/project/${selectedProject.id}/${tab}`)
  const onDelete = () => {
    updateProjectDeleteIdAction(selectedProject.id)
    displayModalAction('ProjectDeleteModal')
  }
  const onStageChange = (stage: ProjectStage) => {
    submitUpdateProjectStageAction({ ...selectedProject, stage })
  }

  return (
    <div className='flex flex-col sm:flex-row justify-between items-center'>
      <div className='flex flex-col'>
        <nav
          className='text-primary-400 font-medium text-base cursor-pointer flex flex-row items-center'
          onClick={() => redirectAction('/projects')}
        >
          <ChevronLeftIcon className='w-5 mr-2' />
          <span>Projects</span>
        </nav>
        <div className='flex flex-row items-center mt-2'>
          <h1 className='font-semibold text-black text-2xl'>
            {truncate(selectedProject.name, { length: 100 })}
          </h1>
          {!hideDropDown
            && (
              <Dropdown
                shouldOpenOnHover
                openTo='right'
                openButton={
                  <DotsVerticalIcon
                    className='text-black w-5 cursor-pointer rotate-90 ml-3'
                    onClick={() => setIsProjectDropdownOpen(!isProjectDropdownOpen)}
                  />
                }
                isOpen={isProjectDropdownOpen}
                onClose={() => setIsProjectDropdownOpen(false)}
              >
                <div className='w-40'>
                  {!hideDeleteOption
                    && (
                      <div
                        className='text-primary-300 cursor-pointer font-normal text-base flex flex-row'
                        onClick={onDelete}
                      >
                        <TrashIcon />
                        <span className='ml-2'>Delete Project</span>
                      </div>
                    )}
                </div>
              </Dropdown>
            )}
        </div>
        <div className='flex flex-row text-primary-400 font-semibold text-sm mt-2'>
          <span className='py-1 pr-3 border-r'>
            {selectedProject.client.name}
          </span>
          <span className='py-1 px-3 border-r'>
            {selectedProject.industry.name}
          </span>
          {readOnlyProjectStage
            ? (
              <span className='py-1 pl-3 pr-3 border-r'>
                {selectedProject.stage}
              </span>
            )
            : (
              <Dropdown
                isOpen={isStageDropdownOpen}
                onClose={() => setIsStageDropdownOpen(false)}
                shouldOpenOnHover
                openButton={
                  <div
                    className='py-1 px-3 text-cta-200 cursor-pointer flex flex-row'
                    onClick={() => setIsStageDropdownOpen(true)}
                  >
                    {selectedProject.stage}
                    <ChevronDownIcon
                      className={`w-5 h-5 mr-2 ${isStageDropdownOpen && 'rotate-180'}`}
                    />
                  </div>
                }
              >
                <div className='flex flex-col w-40'>
                  <div
                    className='text-primary-200 p-2 cursor-pointer flex flex-row'
                    onClick={() => {
                      setIsStageDropdownOpen(false)
                      onStageChange('Discover')
                    }}
                  >
                    <CheckIcon
                      className={`w-5 h-5 mr-2 ${getActiveStageColor('Discover')}`}
                    />
                    Discover
                  </div>
                  <div
                    className='text-primary-200 p-2 cursor-pointer flex flex-row'
                    onClick={() => {
                      setIsStageDropdownOpen(false)
                      onStageChange('Evaluate')
                    }}
                  >
                    <CheckIcon
                      className={`w-5 h-5 mr-2 ${getActiveStageColor('Evaluate')}`}
                    />
                    Evaluate
                  </div>
                  <div
                    className='text-primary-200 p-2 cursor-pointer flex flex-row'
                    onClick={() => {
                      setIsStageDropdownOpen(false)
                      onStageChange('Implement')
                    }}
                  >
                    <CheckIcon
                      className={`w-5 h-5 mr-2 ${getActiveStageColor('Implement')}`}
                    />
                    Implement
                  </div>
                </div>
              </Dropdown>
            )}
        </div>
      </div>
      <nav className='flex flex-row text-primary-200 font-semibold text-base border-b my-4'>
        <div
          className={`mx-3 cursor-pointer flex flex-col items-center ${activeTab === 'dashboard' && 'text-cta-200'}`}
          onClick={() => goToTab('dashboard')}
        >
          <span>Dashboard</span>
          <ActiveTabLine tab='dashboard' activeTab={activeTab} />
        </div>
        <div
          className={`mx-3 cursor-pointer flex flex-col items-center ${activeTab === 'details' && 'text-cta-200'}`}
          onClick={() => goToTab('details')}
        >
          <span>Details</span>
          <ActiveTabLine tab='details' activeTab={activeTab} />
        </div>
        <div
          className={`mx-3 cursor-pointer flex flex-col items-center ${activeTab === 'budget' && 'text-cta-200'}`}
          onClick={() => goToTab('budget')}
        >
          <span>Budget</span>
          <ActiveTabLine tab='budget' activeTab={activeTab} />
        </div>
        <div
          className={`mx-3 cursor-pointer flex flex-col items-center ${activeTab === 'imagery' && 'text-cta-200'}`}
          onClick={() => goToTab('imagery')}
        >
          <span>Imagery</span>
          <ActiveTabLine tab='imagery' activeTab={activeTab} />
        </div>
        <div
          className={`mx-3 cursor-pointer flex flex-col items-center ${activeTab === 'workbook' && 'text-cta-200'}`}
          onClick={() => goToTab('workbook')}
        >
          <span>Workbook</span>
          <ActiveTabLine tab='workbook' activeTab={activeTab} />
        </div>
        <div
          className={`mx-3 cursor-pointer flex flex-col items-center ${activeTab === 'team' && 'text-cta-200'}`}
          onClick={() => goToTab('team')}
        >
          <span>Team</span>
          <ActiveTabLine tab='team' activeTab={activeTab} />
        </div>
      </nav>
    </div>
  )
}
