import { XIcon } from '@heroicons/react/outline'
import React, { useRef, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { OtherSpacesEnum, QuantValueSchemaInput } from '../../../generated/api'
import { getSpacesEnumByIndustry } from '../../../helpers/industryHelper'
import { getNameWithoutUnderScores } from '../../../helpers/projectHelper'
import { useActions, useAppState } from '../../../presenter'
import { Checkbox } from '../../atoms/Checkbox/Checkbox'
import { SlideIndicators } from '../../atoms/SlideIndicators/SlideIndicators'
import TextInput from '../../atoms/TextInput/TextInput'
import { Wizard } from '../../atoms/Wizard/Wizard'
import { IndustriesChip } from '../../molecules/IndustriesChip/IndustriesChip'
import { IndustriesEnum } from '../../molecules/NewProjectCreation/IndustriesSelect'
import { CancelProjectWizardModal } from '../Modals/CancelProjectWizardModal'

type EditSpacesFormType = {
  industryTypesOfSpacesQuantValues: QuantValueSchemaInput[] | []
}

export const SpacesWizard = () => {
  const { currentModal, wizardProjectCreation, wizardProjectSelectedIndustry } = useAppState()
  const { cleanProjectCreation, displayModalAction, setProjectCreationSpaces } = useActions()
  const [industry] = useState<IndustriesEnum | undefined>(wizardProjectSelectedIndustry)
  const [spaces, setspaces] = useState<OtherSpacesEnum[]>(
    wizardProjectCreation.industryDetails?.otherSpaces?.values ?? [],
  )
  const [showOtherbg, setShowOtherbg] = useState<boolean>(
    wizardProjectCreation.industryDetails?.otherSpaces?.other ? true : false,
  )
  const [otherSpaces, setOtherSpaces] = useState<string>(
    wizardProjectCreation.industryDetails?.otherSpaces?.other ?? '',
  )
  const selectedSpaces = wizardProjectCreation.industryDetails?.otherSpaces
  const [showCancelProjectWizardModal, setShowCancelProjectWizardModal] = useState<boolean>(false)
  const [otherIndex, setOtherIndex] = useState<number | null>(null)

  const initialFieldArrayValues: EditSpacesFormType = {
    industryTypesOfSpacesQuantValues: getSpacesEnumByIndustry(industry as string).map((el, index) => {
      return {
        amount: 0,
        other: '',
        values: el,
      }
    }),
  }

  const { control, formState: { errors }, getValues, handleSubmit, register } = useForm({
    defaultValues: initialFieldArrayValues,
  })

  const valuesFields = useFieldArray({ control, name: 'industryTypesOfSpacesQuantValues' })

  const fields = valuesFields.fields

  const goBack = () => {
    SaveProject()
    displayModalAction(
      wizardProjectSelectedIndustry === IndustriesEnum.Healthcare
        ? 'ClinicalGoalsWizard'
        : 'BusinessGoalsWizard',
    )
  }

  const onClose = () => cleanProjectCreation()

  const onClickNext = () => {
    const fieldArrayValues = getValues().industryTypesOfSpacesQuantValues
    const quantValuesToSave = fieldArrayValues.filter(element => spaces.includes(element.values))
    let valueError = false

    quantValuesToSave.forEach((el) => {
      if (el.amount === 0) {
        valueError = true
      }
    })

    if (valueError) {
      toast('Select a value to continue', { type: 'error' })
      return
    }

    if (spaces.length < 1) {
      toast('Select an option to continue', { type: 'error' })
      return
    }

    SaveProject()
    displayModalAction('ProjectSizeWizard')
  }

  const SaveProject = () => {
    const fieldArrayValues = getValues().industryTypesOfSpacesQuantValues
    const quantValuesToSave = fieldArrayValues.filter(element => spaces.includes(element.values))

    setProjectCreationSpaces({
      other: otherSpaces,
      quantValues: quantValuesToSave,
      quantities: [],
      values: spaces,
    })
  }

  const onClickExit = () => {
    setShowCancelProjectWizardModal(true)
  }

  const onConfirmLeaveWizardModal = () => {
    cleanProjectCreation()
    displayModalAction('None')
  }

  const onKeepEditingWizardModal = () => {
    setShowCancelProjectWizardModal(false)
  }

  const Header = () => {
    return (
      <>
        <button
          type='button'
          className='rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none'
          onClick={onClickExit}
        >
          <XIcon className='h-6 w-6' aria-hidden='true' />
        </button>
      </>
    )
  }

  const Footer = () => {
    return (
      <>
        <button
          className='bg-[#F1F1F1] items-center text-[#35697D] px-3 rounded-lg font-semibold mr-2 h-10'
          onClick={() => goBack()}
        >
          {'<-'} Back
        </button>
        <button
          className='bg-cta-200 items-center text-white px-3 rounded-lg font-semibold mr-2 h-10'
          onClick={onClickNext}
        >
          Next
        </button>
      </>
    )
  }

  const handleOnChecked = (checked: boolean, value: OtherSpacesEnum, index: number) => {
    if (!checked) {
      setspaces(prevState => [...prevState, value])
    } else {
      const newGoals = spaces.filter(f => f !== value)
      setspaces(newGoals)
    }

    if (value === OtherSpacesEnum.Other && !checked) {
      setShowOtherbg(true)
      setOtherIndex(index)
    } else if (value === OtherSpacesEnum.Other && checked) {
      setShowOtherbg(false)
    }
  }

  return (
    <Wizard
      isOpen={currentModal === 'SpacesWizard'}
      onClose={onClose}
      header={<Header />}
      footer={<Footer />}
      exitModal={
        <CancelProjectWizardModal
          showCancelModal={showCancelProjectWizardModal}
          onConfirmLeave={onConfirmLeaveWizardModal}
          onKeepEditing={onKeepEditingWizardModal}
        />
      }
    >
      {industry
        && (
          <>
            <form>
              <div className='flex flex-col justify-center items-center w-full sm:w-full'>
                <div className='text-primary-100 text-xl font-bold mb-2 text-center'>
                  What types of spaces do we need to consider?
                </div>
                <div className='text-primary-200 text-base font-semibold text-center mb-6'>
                  Select all that apply
                </div>
                <div className='text-primary-200 text-base font-semibold text-center mb-6'>
                  <IndustriesChip industry={industry} />
                </div>
                <ul>
                  {fields.map((field, index) => {
                    return (
                      <>
                        <div className='overflow-x-visible grid grid-cols-2 gap-4 scroll-smooth mb-4'>
                          <>
                            <Checkbox
                              {...register(`industryTypesOfSpacesQuantValues.${index}.values`)}
                              key={index}
                              checked={selectedSpaces?.values.find(f => f === field.values) ? true : false}
                              text={getNameWithoutUnderScores(field.values)}
                              value={field.values}
                              onChange={(checked) => handleOnChecked(checked, field.values, index)}
                            />
                            <TextInput
                              {...register(`industryTypesOfSpacesQuantValues.${index}.amount`, {
                                min: spaces.includes(getValues().industryTypesOfSpacesQuantValues[index].values)
                                  ? 1
                                  : 0,
                                valueAsNumber: true,
                              })}
                              type='number'
                              className='border-none focus:ring-0 grow w-10 mb-8'
                            />
                          </>
                        </div>
                      </>
                    )
                  })}
                </ul>

                {showOtherbg
                  && (
                    <div className='my-6 w-18'>
                      <div className='text-primary-100 text-lg font-bold mb-2 text-center'>
                        What other spaces do we need to consider?
                      </div>
                      <TextInput
                        {
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore: industryTypesOfSpacesQuantValues type is correct
                          ...register(`industryTypesOfSpacesQuantValues.${otherIndex}.other`)
                        }
                        label='Other Spaces*'
                        hasAutoFocus
                        maxLength={52}
                        defaultValue={selectedSpaces?.other ?? ''}
                        onChange={(e) => {
                          setOtherSpaces(e.target.value)
                        }}
                      />
                    </div>
                  )}
              </div>
            </form>
          </>
        )}
      <SlideIndicators />
    </Wizard>
  )
}
