import { DotsVerticalIcon } from '@heroicons/react/solid'
import { truncate } from 'lodash'
import React, { Fragment, useEffect, useState } from 'react'
import { Mention, MentionsInput } from 'react-mentions'
import AirplaneIcon from 'theorem-lib/src/assets/icons/airplane.svg'
import CoinOneIcon from 'theorem-lib/src/assets/icons/coin-one.svg'
import CoinThreeIcon from 'theorem-lib/src/assets/icons/coin-three.svg'
import CoinTwoIcon from 'theorem-lib/src/assets/icons/coin-two.svg'
import Copy from 'theorem-lib/src/assets/icons/copy.svg'
import CopyV1 from 'theorem-lib/src/assets/icons/copyV1.svg'
import SettingsIcon from 'theorem-lib/src/assets/icons/settings.svg'
import TrashIcon from 'theorem-lib/src/assets/icons/trash.svg'
import { formatDate } from 'theorem-lib/src/helpers/format'
import { ProjectMemberStatusEnum, Space, SpaceCommentInput } from '../../../generated/api'
import { useActions, useAppState } from '../../../presenter'
import defaultMentionStyle from '../../../schemas/styles/defaultMentionStyle'
import defaultStyle from '../../../schemas/styles/defaultStyle'
import Card from '../../atoms/Card/Card'
import Dropdown from '../../atoms/Dropdown/Dropdown'

export interface SpaceCardProps {
  space: Space
  shouldShowDropDown?: boolean
  shouldShowDropDownDeleteButton?: boolean
}

interface Mention {
  id: string
  display: string
}

interface Comment {
  comment: string
  createdOn: string
  id: string
  spaceId: string
  userFirstName: string
  userLastName: string
}

export const SpaceCard = (props: SpaceCardProps) => {
  const {
    copySpaceAction,
    createSpaceCommentAction,
    displayModalAction,
    displaySlideOutAction,
    getAllItemTemplatesAction,
    getAllSpaceCommentsAction,
    updateSelectedSpaceAction,
    updateSpaceDeleteIdAction,
  } = useActions()
  const { authenticatedUser, selectedProject } = useAppState()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const [comData, setComData] = useState<any>()
  const [comment, setComment] = useState('')
  const { id } = props.space

  const handleSaveComment = async () => {
    const data: SpaceCommentInput = {
      comment: comment,
      spaceId: props.space.id,
    }

    const local = {
      createdOn: formatDate(Date()),
      userFirstName: authenticatedUser.firstName,
      userLastName: authenticatedUser.lastName,
    }

    await createSpaceCommentAction(data)

    const stateData = { ...data, ...local }
    setComData([stateData, ...comData])
    setComment('')
  }

  useEffect(() => {
    const fetchComments = async () => {
      const data = await getAllSpaceCommentsAction(props.space.id)
      setComData(data)
    }

    fetchComments()
  }, [])

  const userData = (): Mention[] => {
    const d: Mention[] = []
    if (selectedProject && selectedProject.members) {
      for (const member of selectedProject.members) {
        if (member.status === ProjectMemberStatusEnum.Accepted) {
          d.push({
            display: member.firstName + ' ' + member.lastName,
            id: member.id,
          })
        }
      }
    }
    return d
  }

  const handleCommentChange = (e: { target: { value: string } }) => {
    setComment(e.target.value)
  }

  const handleCommentKeyUp = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      handleSaveComment()
    }
  }

  const refreshComments = async () => {
    await getAllSpaceCommentsAction(props.space.id)
  }

  const submitCopySpace = async () => {
    copySpaceAction(props.space.id)
  }

  const OptionsDropdown = ({ button }: { button: React.ReactNode }) => (
    <Dropdown
      isOpen={isMenuOpen}
      onClose={() => setIsMenuOpen(false)}
      shouldOpenOnHover
      openButton={button}
    >
      <div className='flex flex-col text-primary-300 text-base w-44'>
        <div
          className='flex flex-row cursor-pointer'
          onClick={async () => {
            await getAllItemTemplatesAction()
            setIsMenuOpen(false)
            updateSelectedSpaceAction(props.space)
            displaySlideOutAction('ManageSpaceSlideOut')
          }}
        >
          <SettingsIcon />
          <div className='ml-3 mb-3'>Manage</div>
        </div>
        <div
          className='flex flex-row cursor-pointer'
          onClick={submitCopySpace}
        >
          <Copy />
          <div className='ml-3 mb-3'>Copy</div>
        </div>
        {props.shouldShowDropDownDeleteButton && (
          <div
            className='flex flex-row cursor-pointer'
            onClick={() => {
              setIsMenuOpen(false)
              updateSpaceDeleteIdAction(id)
              displayModalAction('DeleteSpaceModal')
            }}
          >
            <TrashIcon />
            <div className='ml-3'>Delete</div>
          </div>
        )}
      </div>
    </Dropdown>
  )

  return (
    <Card
      className='p-4 w-full break-inside-avoid mb-4'
      onExpand={() => setIsExpanded(!isExpanded)}
      isExpanded={isExpanded}
      isSpaceCard={true}
    >
      <h1 className='font-semibold text-xl'>{props.space.name}</h1>

      <div className='flex flex-row mt-3 items-start justify-between'>
        <div className='flex flex-row text-primary-400 font-semibold'>
          <div className='flex flex-row pr-3'>
            <CoinOneIcon />
            <span className='text-primary-200 ml-3'>
              ${props.space.items?.length
                ? props.space.items.map(item => item.lowPrice * item.quantity).reduce((a, b) => a + b).toLocaleString()
                : '0'}
            </span>
          </div>
          <div className='flex flex-row px-3 border-l'>
            <CoinTwoIcon />
            <span className='text-primary-200 ml-3'>
              ${props.space.items?.length
                ? props.space.items.map(item => item.midPrice * item.quantity).reduce((a, b) => a + b).toLocaleString()
                : '0'}
            </span>
          </div>
          <div className='flex flex-row pl-3 border-l'>
            <CoinThreeIcon />
            <span className='text-primary-200 ml-3'>
              ${props.space.items?.length
                ? props.space.items.map(item => item.highPrice * item.quantity).reduce((a, b) => a + b).toLocaleString()
                : '0'}
            </span>
          </div>
        </div>
        <div className='flex flex-row'>
          <div className='w-14 border-b flex flex-col justify-center items-center pb-2'>
            <span className='text-primary-400 text-xs'>Qty</span>
            <span className='text-primary-100 text-base'>{props.space.quantity}</span>
          </div>
          {!props.shouldShowDropDown
            && (
              <OptionsDropdown
                button={
                  <DotsVerticalIcon
                    onClick={() => setIsMenuOpen(true)}
                    className='w-5 cursor-pointer rotate-90 ml-3 text-primary-300'
                  />
                }
              />
            )}
        </div>
      </div>
      {isExpanded && (
        <Fragment>
          {refreshComments}
          <div className='flex flex-row my-6'>
            {props.space.image && <img className='w-28 h-[72px] rounded-md mr-4' src={props.space.image} />}
            <p className='text-primary-400 text-base'>{truncate(props.space.description ?? '', { length: 115 })}</p>
          </div>
          <div className='relative mb-6'>
            <MentionsInput
              style={defaultStyle}
              placeholder='Enter a Comment'
              value={comment}
              onChange={handleCommentChange}
              onKeyUp={handleCommentKeyUp}
            >
              <Mention
                style={defaultMentionStyle}
                data={userData}
                trigger='@'
                displayTransform={(id, display) => '@' + display}
                markup={'<span style="color: pink">@__display__</span>'}
                renderSuggestion={(suggestion, search, highlightedDisplay) => (
                  <div className='user'>{highlightedDisplay}</div>
                )}
              >
              </Mention>
            </MentionsInput>
            <button className='absolute right-4 top-5 text-primary-400' onClick={handleSaveComment}>
              <AirplaneIcon />
            </button>
            {comData?.length === 0 && <div className='text-center text-primary-400 py-8'>No comments yet.</div>}
            {comData.map((comment: Comment) => (
              <div key={comment.id} className='mb-6'>
                <div className='text-black text-base'>{comment.comment}</div>
                <div className='text-primary-400 text-sm mt-1'>
                  {comment.userFirstName} {comment.userLastName} | {formatDate(comment.createdOn)}
                </div>
              </div>
            ))}
          </div>
          {props.space?.items?.map(item => (
            <>
              <div className='flex flex-row flex-wrap mt-5'>
                <div key={item.id} className='flex flex-row w-1/2 mb-4'>
                  {item.image
                    ? <img className='bg-primary-500 w-10 h-10 rounded-lg mr-4' src={item.image} />
                    : <span className='bg-primary-500 w-10 h-10 rounded-lg mr-4' />}

                  <div className='flex flex-col'>
                    <span className='text-primary-200 text-base text-semibold'>{item.name}</span>
                    <div className='flex flex-row text-xs'>
                      <span className='mr-2 text-primary-400'>Qty</span>
                      <span className='text-primary-200'>{item.quantity}</span>
                      <div className='flex flex-row ml-3'>
                        <div className='text-primary-200 text-xs pr-3 font-semibold'>
                          <span>
                            L:
                          </span>
                          <span className='ml-1'>
                            ${item.lowPrice}
                          </span>
                        </div>
                        <div className='text-primary-200 text-xs pr-3 font-semibold'>
                          <span>
                            M:
                          </span>
                          <span className='ml-1'>
                            ${item.midPrice}
                          </span>
                        </div>
                        <div className='text-primary-200 text-xs pr-3 font-semibold'>
                          <span>
                            H:
                          </span>
                          <span className='ml-1'>
                            ${item.highPrice}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ))}
        </Fragment>
      )}
    </Card>
  )
}
