import React from 'react'

interface RadioButtonProps {
  text: string
  value: string
  name: string
  checked?: boolean
  onChange: (checked: string) => void
}

export const RadioButton = (props: RadioButtonProps) => {
  return (
    <label className='flex w-80 h-20 items-center pl-4 rounded border border-[#CFDBE0] cursor-pointer'>
      <input
        type='radio'
        checked={props.checked}
        name={props.name}
        value={props.value}
        className='form-check-input mr-3'
        onChange={(e) => props.onChange(e.target.value)}
      />
      <span className='ml-6 text-primary-200 font-semibold'>{props.text}</span>
    </label>
  )
}
