import moment from 'moment'
import React, { useCallback, useState } from 'react'
import BellIcon from 'theorem-lib/src/assets/icons/bell.svg'
import TabNav, { TabLink } from 'theorem-lib/src/components/atoms/TabNav/TabNav'
import { NotificationDetail, NotificationsTypeEnum } from '../../../generated/api'
import { NotificationList, withDueDates } from './NotificationList'
import { FilterAccessors, FilterLink } from './NotificationList'

const FilterTitles: Record<FilterAccessors, string> = {
  DateReceived: 'View By Date Received',
  DueDate: 'View By Due Date',
  Project: 'View By Project',
}

enum TabsAccessors {
  all = 'all',
  mentions = 'mentions',
  tasks = 'tasks',
}

const TabTitles: Record<TabsAccessors, string> = {
  all: 'All',
  mentions: 'Mentions',
  tasks: 'Tasks',
}

type NotificationWidgetProps = {
  className?: string
  notifications: Array<NotificationDetail>
}

const sortByDueDate = (dueDate1: string, dueDate2: string): number => {
  if (moment.utc(dueDate1).isBefore(moment.utc(dueDate2))) {
    return -1
  } else if (moment.utc(dueDate1).isAfter(moment.utc(dueDate2))) {
    return 1
  } else {
    return 0
  }
}

export const NotificationWidget = ({
  notifications,
}: NotificationWidgetProps) => {
  const filters: FilterLink[] = [
    {
      accessor: FilterAccessors.Project,
      label: FilterTitles.Project,
    },
    {
      accessor: FilterAccessors.DueDate,
      label: FilterTitles.DueDate,
    },
    {
      accessor: FilterAccessors.DateReceived,
      label: FilterTitles.DateReceived,
    },
  ]

  const [selectedFilter, setSelectedFilter] = useState<FilterLink>(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const selectedFilter = searchParams.get('selectedFilter')
    if (selectedFilter !== undefined) {
      for (const filter of filters) {
        if (filter.accessor === selectedFilter) {
          return filter
        }
      }
    }
    return filters[0]
  })

  const tabs: TabLink[] = [
    {
      accessor: TabsAccessors.all,
      label: TabTitles.all,
    },
    {
      accessor: TabsAccessors.mentions,
      label: TabTitles.mentions,
    },
    {
      accessor: TabsAccessors.tasks,
      label: TabTitles.tasks,
    },
  ]

  const [selectedTab, setSelectedTab] = useState<TabLink>(() => {
    const queryParams = new URL(window.location.href)
    const selectedTab = queryParams.searchParams.get('selectedTab')
    if (selectedTab !== undefined) {
      for (const tab of tabs) {
        if (tab.accessor === selectedTab) {
          return tab
        }
      }
    }
    return tabs[0]
  })

  const handleTabSelection = useCallback((selectedTab: TabLink) => {
    setSelectedTab(selectedTab)
  }, [setSelectedTab])

  const handleFilterSelection = useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedFilter(filters.filter(f => f.accessor === event.target.value)[0])
  }, [setSelectedFilter])

  return (
    <div className='my-8 border border-gray-300 rounded-md'>
      <div className='m-8'>
        <h2 className='text-xl font-bold mb-5 text-primary-200'>
          <BellIcon className='inline-block mr-2' /> Notifications
        </h2>
        {notifications.length <= 0 && (
          <NotificationList
            filter=''
            emptyMessage1="You don't have any notifications"
            emptyMessage2='All notifications you have will be displayed here'
            notifications={[]}
          />
        )}
        {notifications.length > 0 && (
          <>
            <div className='flex flex-row'>
              <div className='flex flex-col grow'>
                <select
                  id='select'
                  placeholder=' '
                  className='border-none'
                  onChange={event => handleFilterSelection(event)}
                >
                  <option value={filters[0].accessor}>
                    {filters[0].label}
                  </option>
                  <option value={filters[1].accessor}>
                    {filters[1].label}
                  </option>
                  <option value={filters[2].accessor}>
                    {filters[2].label}
                  </option>
                </select>
                <label htmlFor='select'>
                  {selectedFilter.label}
                </label>
              </div>
              <div className='flex flex-col'>
                <TabNav
                  defaultTab={selectedTab.accessor}
                  tabs={tabs}
                  onTabSelection={handleTabSelection}
                  color='pink'
                  className='mb-5'
                />
              </div>
            </div>

            {
              /*
              Putting these in TabNav.tabs doesn't work because it takes the components out
              of the render block, so they don't rerender when filters are changed since filters
              are outside of TabNav. Also can't move TabNav.tabs into the component in any way
              due to variable delcaration order. This way is actually quite clean.
            */
            }
            {selectedTab.accessor === TabsAccessors.all && (
              <NotificationList
                filter={selectedFilter.accessor}
                emptyMessage1="You don't have any notifications"
                emptyMessage2='All notifications you have will be displayed here'
                notifications={[...notifications]
                  .sort((n1, n2) => withDueDates(n1, n2, sortByDueDate))}
              />
            )}
            {selectedTab.accessor === TabsAccessors.mentions && (
              <NotificationList
                filter={selectedFilter.accessor}
                emptyMessage1="You don't have any mentions"
                emptyMessage2='All mentions you have will be displayed here'
                notifications={[...notifications]
                  .filter((notification: NotificationDetail) => (
                    notification.type === NotificationsTypeEnum.MentionedInTask
                  ))
                  .sort((n1, n2) => withDueDates(n1, n2, sortByDueDate))}
              />
            )}
            {selectedTab.accessor === TabsAccessors.tasks && (
              <NotificationList
                filter={selectedFilter.accessor}
                emptyMessage1="You don't have any task or list assignments"
                emptyMessage2='All task or list assignments you have will be displayed here'
                notifications={[...notifications]
                  .filter((notification: NotificationDetail) => (
                    notification.type === NotificationsTypeEnum.AssignedToTaskList
                    || notification.type === NotificationsTypeEnum.UnassignedToTaskList
                    || notification.type === NotificationsTypeEnum.AssignedToTask
                    || notification.type === NotificationsTypeEnum.UnassignedToTask
                  ))
                  .sort((n1, n2) => withDueDates(n1, n2, sortByDueDate))}
              />
            )}
          </>
        )}
      </div>
    </div>
  )
}
