import React from 'react'
import { components } from 'react-select'

const { Option, SingleValue } = components

export const IconSingleValue = (props: any) => (
  <SingleValue {...props}>
    <div>
      <div className='flex flex-row'>
        <img
          src={props.data.image}
          style={{ borderRadius: '50%', height: '30px', marginRight: '10px', width: '30px' }}
        />
        {props.data.label}
      </div>
    </div>
  </SingleValue>
)

export const IconOption = (props: any) => (
  <Option {...props}>
    <div>
      <div className='flex flex-row'>
        <img
          src={props.data.image}
          style={{ borderRadius: '50%', height: '30px', marginRight: '10px', width: '30px' }}
        />
        {props.data.label}
      </div>
      {props.data.description.length > 1
        ? (
          <div className='ml-10 mb-2 text-xs text-gray-500'>
            ( L $ {props.data.description.reduce((a: any, b: any) => (a + b.totalLowPrice), 0)}
            / M $ {props.data.description.reduce((a: any, b: any) => (a + b.totalMidPrice), 0)}
            / H $ {props.data.description.reduce((a: any, b: any) => (a + b.totalHighPrice), 0)})
          </div>
        )
        : (
          <div className='ml-10 mb-2 text-xs text-gray-500'>
            ( L $ {props.data.description[0].totalLowPrice}
            / M $ {props.data.description[0].totalMidPrice}
            / H $ {props.data.description[0].totalHighPrice})
          </div>
        )}
    </div>
  </Option>
)

export const customStyles = {
  control: (base: any, state: { isFocused: any }) => ({
    ...base,
    border: state.isFocused ? 0 : 0,
    boxShadow: state.isFocused ? 0 : 0,
    marginTop: '25px',
    width: 300,
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  }),
}

export const serviceStyles = {
  control: (base: any, state: { isFocused: any }) => ({
    ...base,
    border: state.isFocused ? 0 : 0,
    boxShadow: state.isFocused ? 0 : 0,
    marginTop: '25px',
    width: 300,
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  }),
}
