import React from 'react'
import CaseIcon from 'theorem-lib/src/assets/icons/case.svg'

type ClientEmptyStateProps = {
  isAuthorized: boolean
  onAction: () => void
}

export const ClientEmptyState = (
  props: ClientEmptyStateProps,
): React.ReactElement => (
  <div className='flex flex-col justify-center items-center w-full mt-48'>
    <div className='rounded-full border-primary-100 border-2 border-solid p-3 mb-4'>
      <CaseIcon />
    </div>
    <div className='text-primary-100 text-xl font-semibold mb-1'>
      You don&apos;t have any projects yet
    </div>
    <div className='text-primary-300 text-base font-normal'>
      All projects you have will be displayed here.
    </div>

    <div className='flex flex-col'>
      <div className='text-primary-300 text-base font-normal mb-7'>
        Click the button below to get started.
      </div>
      <button type='button' className='button cta' onClick={props.onAction}>
        Create New Client
      </button>
    </div>
  </div>
)
