import React, { Fragment } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import ReactTooltip from 'react-tooltip'
import BudgetIcon from 'theorem-lib/src/assets/icons/budget-card.svg'
import CoinOneIcon from 'theorem-lib/src/assets/icons/coin-one.svg'
import CoinThreeIcon from 'theorem-lib/src/assets/icons/coin-three.svg'
import CoinTwoIcon from 'theorem-lib/src/assets/icons/coin-two.svg'
import DownloadIcon from 'theorem-lib/src/assets/icons/download.svg'
import PlusIcon from 'theorem-lib/src/assets/icons/plus.svg'
import { Space } from '../../../generated/api'
import { isProjectCollaborator } from '../../../helpers/projectHelper'
import { useActions, useAppState } from '../../../presenter'
import { SpaceEmptyState } from '../../atoms/EmptyStates/SpaceEmptyState'
import { PriceCard } from '../../atoms/PriceCard/PriceCard'
import { FinanceCard } from '../../molecules/FinanceCard/FinanceCard'
import { ProjectHeader } from '../../molecules/ProjectHeader'
import { SpaceCard } from '../../molecules/SpaceCard/SpaceCard'

export interface SpacesProps {
  hideAddNewSpaceButton?: boolean
  hideHeaderDropDown?: boolean
  hideHeaderDeleteOption?: boolean
  hideProjectSpacesDropDown?: boolean
  showSpaceCardDeleteButton?: boolean
  hideAddSpaceButton?: boolean
  hideExportDataButton?: boolean
  readOnlyProjectStage?: boolean
}

enum columnId {
  One = 1,
  Two = 2,
}

export const SpacesTemplates = (props: SpacesProps) => {
  const {
    displaySlideOutAction,
    excelGenerator,
    getAllSpacesItemTemplatesAction,
    updateItemIndexAction,
    updateSpaceListAction,
  } = useActions()
  const {
    authenticatedUser,
    selectedProject,
    selectedProjectEstimates,
    selectedProjectServices,
    selectedProjectSpaces,
  } = useAppState()

  const isCollaborator = isProjectCollaborator(selectedProject, authenticatedUser)
  const monthlyPercentage = 0.02
  const shouldShowEmptyState = selectedProjectSpaces.length === 0
  const toolTip =
    'The budget export will order spaces based on <br /> how they are ordered in the budget screen. <br /> Services display at the bottom of the export.'

  let listOne = selectedProjectSpaces.filter((space) => (space.list?.listNumber === 1 || space.list === null)).sort((
    a,
    b,
    // @ts-expect-error: indices can not be undefined
  ) => a.list?.index - b.list?.index)
  let listTwo = selectedProjectSpaces.filter((space) => space.list?.listNumber === 2).sort((a, b) =>
    // @ts-expect-error: indices can not be undefined
    a.list?.index - b.list?.index
  )

  const updateBE = async () => {
    const dataOne = listOne.map((el, index) => {
      return {
        id: el.id,
        list: {
          index: index,
          listNumber: el.list?.listNumber ? el.list.listNumber : columnId.One,
        },
      }
    })

    const dataTwo = listTwo.map((el, index) => {
      return {
        id: el.id,
        list: {
          index: index,
          listNumber: el.list?.listNumber ? el.list.listNumber : columnId.Two,
        },
      }
    })

    await updateSpaceListAction(dataOne)
    await updateSpaceListAction(dataTwo)
  }

  const handleExportClick = async () => {
    const exportSpaces: Space[] = []
    const l = Math.min(listOne.length, listTwo.length)

    for (let i = 0; i < l; i++) {
      exportSpaces.push(listOne[i], listTwo[i])
    }
    exportSpaces.push(...listOne.slice(l), ...listTwo.slice(l))

    excelGenerator(
      {
        project: selectedProject,
        projectEstimate: selectedProjectEstimates,
        services: selectedProjectServices,
        spaces: exportSpaces,
      },
    )
  }

  const onDragEnd = async (results: any) => {
    const { destination, source } = results

    if (!destination) return

    if (
      source.droppableId === destination.droppableId
      && source.index === destination.index
    ) {
      return
    }

    if (source.droppableId != destination.droppableId && source.droppableId === 'col-1') {
      const reorderedListOne = [...listOne]
      const reorderedListTwo = [...listTwo]
      const sourceIndex = source.index
      const destinationIndex = destination.index
      const destinationListNum = destination.droppableId === 'col-2' ? columnId.Two : columnId.One

      const [removedSpace] = reorderedListOne.splice(sourceIndex, 1)
      const updatedRemovedSpace = { ...removedSpace, list: { index: destinationIndex, listNumber: destinationListNum } }
      reorderedListTwo.splice(destinationIndex, 0, updatedRemovedSpace)

      listOne = reorderedListOne
      listTwo = reorderedListTwo
    } else if (source.droppableId != destination.droppableId && source.droppableId === 'col-2') {
      const reorderedListOne = [...listOne]
      const reorderedListTwo = [...listTwo]
      const sourceIndex = source.index
      const destinationIndex = destination.index
      const destinationListNum = destination.droppableId === 'col-2' ? columnId.Two : columnId.One

      const [removedSpace] = reorderedListTwo.splice(sourceIndex, 1)
      const updatedRemovedSpace = { ...removedSpace, list: { index: destinationIndex, listNumber: destinationListNum } }
      reorderedListOne.splice(destinationIndex, 0, updatedRemovedSpace)

      listOne = reorderedListOne
      listTwo = reorderedListTwo
    } else if (source.droppableId === destination.droppableId && source.droppableId === 'col-1') {
      const reorderedListOne = [...listOne]

      const sourceIndex = source.index
      const destinationIndex = destination.index
      const destinationListNum = destination.droppableId === 'col-2' ? columnId.Two : columnId.One

      const [removedSpace] = reorderedListOne.splice(sourceIndex, 1)
      const updatedRemovedSpace = { ...removedSpace, list: { index: destinationIndex, listNumber: destinationListNum } }
      reorderedListOne.splice(destinationIndex, 0, updatedRemovedSpace)

      listOne = reorderedListOne
    } else if (source.droppableId === destination.droppableId && source.droppableId === 'col-2') {
      const reorderedListTwo = [...listTwo]

      const sourceIndex = source.index
      const destinationIndex = destination.index
      const destinationListNum = destination.droppableId === 'col-2' ? columnId.Two : columnId.One

      const [removedSpace] = reorderedListTwo.splice(sourceIndex, 1)
      const updatedRemovedSpace = { ...removedSpace, list: { index: destinationIndex, listNumber: destinationListNum } }
      reorderedListTwo.splice(destinationIndex, 0, updatedRemovedSpace)

      listTwo = reorderedListTwo
    }

    updateBE()
  }

  const onBtnClick = async () => {
    await getAllSpacesItemTemplatesAction()
    displaySlideOutAction('AddSpaceSlideOut')
  }

  const percentageTotalLow = selectedProjectEstimates?.lowPrice
    + selectedProjectEstimates?.lowServiceEstimate

  const percentageTotalMid = selectedProjectEstimates?.midPrice
    + selectedProjectEstimates?.midServiceEstimate

  const percentageTotalHigh = selectedProjectEstimates?.highPrice
    + selectedProjectEstimates?.highServiceEstimate

  const financeTotalLow = percentageTotalLow * monthlyPercentage
  const financeTotalMid = percentageTotalMid * monthlyPercentage
  const financeTotalHigh = percentageTotalHigh * monthlyPercentage

  return (
    <div className='flex flex-col'>
      <ProjectHeader
        activeTab='budget'
        hideDropDown={props.hideHeaderDropDown}
        hideDeleteOption={props.hideHeaderDeleteOption}
        readOnlyProjectStage={props.readOnlyProjectStage}
      />
      <div className='flex flex-row justify-end'>
        {!props.hideAddSpaceButton
          && (
            <div className='flex flex-row justify-end'>
              {selectedProject.discountPercentage != null
                ? (
                  <button
                    id='discIncBanner'
                    className='button w-42 inline-block mr-4'
                  >
                    <div className='flex flex-row justify-center'>
                      {selectedProject.discountPercentage}% Discount Applied
                    </div>
                  </button>
                )
                : selectedProject.increasePercentage != null
                ? (
                  <button
                    id='discIncBanner'
                    className='button w-42 inline-block mr-4'
                  >
                    <div className='flex flex-row justify-center'>
                      {selectedProject.increasePercentage}% Increase Applied
                    </div>
                  </button>
                )
                : (selectedProject.increasePercentage === null && selectedProject.discountPercentage === null)
                ? undefined
                : undefined}

              <button
                id='addSpace'
                onClick={() => onBtnClick()}
                className='button cta w-36'
              >
                <div className='flex flex-row justify-center'>
                  <PlusIcon /> Add Spaces
                </div>
              </button>
            </div>
          )}

        {selectedProjectSpaces.length > 0 && (
          !props.hideExportDataButton && (
            <button
              className='w-10 h-10 bg-cta-200 rounded-md text-primary-100 flex justify-center items-center ml-3'
              onClick={handleExportClick}
            >
              <ReactTooltip id='spaceTooltip' place='top' effect='float' multiline={true} />
              <DownloadIcon data-for='spaceTooltip' data-tip={toolTip} className='text-white' />
            </button>
          )
        )}
      </div>
      <div className='flex flex-row mt-4'>
        <div className='w-100'>
          <PriceCard
            icon={BudgetIcon}
            name='Client budget'
            value={selectedProject.budget}
            backgroundImage={selectedProject.coverImageUrl}
            className='mr-6 h-[237px]'
          />
        </div>
        <div className='w-100'>
          <section className='flex flex-row block'>
            <PriceCard className='mr-6 h-[152px]' icon={CoinOneIcon} name='Low price' value={percentageTotalLow} />
            <PriceCard className='mr-6 h-[152px]' icon={CoinTwoIcon} name='Mid price' value={percentageTotalMid} />
            <PriceCard className='h-[152px]' icon={CoinThreeIcon} name='High price' value={percentageTotalHigh} />
          </section>
          <section className='mt-4 flex flex-row'>
            <FinanceCard className='mr-6' name='low' value={financeTotalLow} icon={CoinOneIcon} />
            <FinanceCard className='mr-6' name='mid' value={financeTotalMid} />
            <FinanceCard name='high' value={financeTotalHigh} icon={CoinOneIcon} />
          </section>
        </div>
      </div>

      <section className='pb-2 mb-12'>
        {!shouldShowEmptyState && (
          <Fragment>
            <div className='flex w-full justify-between columns-2 h-auto'>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId='col-1' direction='vertical' type='spaceList'>
                  {(
                    provided: any,
                  ) => (
                    <div
                      className='mt-16 pr-3 w-1/2'
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {listOne.map((space, index) => (
                        <Draggable draggableId={space.id} key={space.id} index={index}>
                          {(
                            provided: any,
                          ) => (
                            <div
                              {...provided.dragHandleProps}
                              {...provided.draggableProps}
                              ref={provided.innerRef}
                            >
                              <SpaceCard
                                key={space.id}
                                space={space}
                                shouldShowDropDown={props.hideProjectSpacesDropDown}
                                shouldShowDropDownDeleteButton={props.showSpaceCardDeleteButton}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
                <Droppable droppableId='col-2' direction='vertical' type='spaceList'>
                  {(
                    provided: any,
                  ) => (
                    <div
                      className='mt-16 pl-4 w-1/2'
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {listTwo.map((space, index) => (
                        <Draggable draggableId={space.id} key={space.id} index={index}>
                          {(
                            provided: any,
                          ) => (
                            <div
                              {...provided.dragHandleProps}
                              {...provided.draggableProps}
                              ref={provided.innerRef}
                            >
                              <SpaceCard
                                key={space.id}
                                space={space}
                                shouldShowDropDown={props.hideProjectSpacesDropDown}
                                shouldShowDropDownDeleteButton={props.showSpaceCardDeleteButton}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
            {!isCollaborator
              ? (
                <>
                  <div className='flex flex-row justify-end pt-16'>
                    <button
                      id='discountIncrease'
                      onClick={() => displaySlideOutAction('ManageSpaceDiscountSlideOut')}
                      className='button cta w-42 block'
                    >
                      <div className='flex flex-row justify-center'>
                        {selectedProject.discountPercentage != null || selectedProject.increasePercentage != null
                          ? 'Manage Discount or Increase'
                          : 'Apply Discount or Increase'}
                      </div>
                    </button>
                  </div>
                  <div className='flex flex-col'>
                    <p className='text-primary-200 font-normal text-base text-right'>
                      Discounts and increases are applied to spaces only.
                    </p>
                  </div>
                </>
              )
              : <></>}

            <section className='pb-2 mb-12'>
              <table className='w-full'>
                <thead>
                  <tr>
                    <th className='p-4'></th>
                    <th className='p-4'>LOW ESTIMATE</th>
                    <th className='p-4'>MID ESTIMATE</th>
                    <th className='p-4'>HIGH ESTIMATE</th>
                  </tr>
                </thead>
                <tfoot>
                  <tr className='border-t-2 border-slate-400'>
                    <td className='p-4'>
                      <span className='inline-block'>Total (Spaces)</span>
                    </td>
                    <td className='p-4'>
                      <span className='inline-block'>
                        ${selectedProjectEstimates.lowPrice.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                      </span>
                    </td>
                    <td className='p-4'>
                      <span className='inline-block'>
                        ${selectedProjectEstimates.midPrice.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                      </span>
                    </td>
                    <td className='p-4'>
                      <span className='inline-block'>
                        ${selectedProjectEstimates.highPrice.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                      </span>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </section>
          </Fragment>
        )}
        {shouldShowEmptyState
          && (
            <SpaceEmptyState
              onAction={() => displaySlideOutAction('AddSpaceSlideOut')}
              hideAddSpaceButton={props.hideAddSpaceButton}
            />
          )}
      </section>
    </div>
  )
}
